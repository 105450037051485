import React, { useEffect, useState } from "react";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

const useTwitterWeb3Auth = () => {
  const [twitterWeb3auth, setTwitterWeb3auth] =
    useState<Web3AuthNoModal | null>(null);

  useEffect(() => {
    const init = async () => {
      try {
        const chainConfig = {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: "0x5",
          rpcTarget: `https://eth-goerli.g.alchemy.com/v2/${process.env.ALCHEMY_GOERLI_API_KEY}`,
          displayName: "Ethereum Goerlo",
          blockExplorer: "https://goerli.etherscan.io",
          ticker: "ETH",
          tickerName: "Ethereum",
        };

        const web3auth = new Web3AuthNoModal({
          clientId: process.env.WEB3AUTH_CLIENT_ID!,
          chainConfig,
          web3AuthNetwork: "testnet",
        });

        const privateKeyProvider = new EthereumPrivateKeyProvider({
          config: { chainConfig },
        });

        const openloginAdapter = new OpenloginAdapter({
          privateKeyProvider,
          adapterSettings: {
            uxMode: "popup",
            loginConfig: {
              jwt: {
                verifier: "stashed-twitter-latest",
                typeOfLogin: "jwt",
                clientId: "d7NMyjEtTMxJgAPn77IsdNKxRIF1HOso",
              },
            },
          },
        });
        web3auth.configureAdapter(openloginAdapter);
        setTwitterWeb3auth(web3auth);

        // adding wallet connect v2 adapter

        await web3auth.init();
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);
  return { twitterWeb3auth };
};

export default useTwitterWeb3Auth;
