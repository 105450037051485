export const tokensData = {
  btc: {
    id: "bitcoin",
    symbol: "btc",
    name: "Bitcoin",
    image:
      "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579",
    current_price: 30335,
    market_cap: 589259139634,
    market_cap_rank: 1,
    fully_diluted_valuation: 637390269659,
    total_volume: 11949264605,
    high_24h: 30834,
    low_24h: 30179,
    price_change_24h: -345.2596110300983,
    price_change_percentage_24h: -1.12533,
    market_cap_change_24h: -6196869747.844116,
    market_cap_change_percentage_24h: -1.04069,
    circulating_supply: 19414231,
    total_supply: 21000000,
    max_supply: 21000000,
    ath: 69045,
    ath_change_percentage: -56.04024,
    ath_date: "2021-11-10T14:24:11.849Z",
    atl: 67.81,
    atl_change_percentage: 44660.89841,
    atl_date: "2013-07-06T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:02:01.792Z",
  },
  eth: {
    id: "ethereum",
    symbol: "eth",
    name: "Ethereum",
    image:
      "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
    current_price: 1862.22,
    market_cap: 223885659967,
    market_cap_rank: 2,
    fully_diluted_valuation: 223890128572,
    total_volume: 8573974125,
    high_24h: 1908.47,
    low_24h: 1851,
    price_change_24h: -17.533584877726298,
    price_change_percentage_24h: -0.93276,
    market_cap_change_24h: -2141538137.1826477,
    market_cap_change_percentage_24h: -0.94747,
    circulating_supply: 120184000.294193,
    total_supply: 120186399.084691,
    max_supply: null,
    ath: 4878.26,
    ath_change_percentage: -61.81306,
    ath_date: "2021-11-10T14:24:19.604Z",
    atl: 0.432979,
    atl_change_percentage: 430142.38409,
    atl_date: "2015-10-20T00:00:00.000Z",
    roi: {
      times: 81.05331139972232,
      currency: "btc",
      percentage: 8105.3311399722315,
    },
    last_updated: "2023-06-28T12:02:01.184Z",
  },
  usdt: {
    id: "tether",
    symbol: "usdt",
    name: "Tether",
    image:
      "https://assets.coingecko.com/coins/images/325/large/Tether.png?1668148663",
    current_price: 1.001,
    market_cap: 83285174521,
    market_cap_rank: 3,
    fully_diluted_valuation: 83285174521,
    total_volume: 15392364662,
    high_24h: 1.003,
    low_24h: 0.995923,
    price_change_24h: 0.00125056,
    price_change_percentage_24h: 0.12515,
    market_cap_change_24h: -44883449.729003906,
    market_cap_change_percentage_24h: -0.05386,
    circulating_supply: 83240489455.1504,
    total_supply: 83240489455.1504,
    max_supply: null,
    ath: 1.32,
    ath_change_percentage: -24.37908,
    ath_date: "2018-07-24T00:00:00.000Z",
    atl: 0.572521,
    atl_change_percentage: 74.75985,
    atl_date: "2015-03-02T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:00:00.441Z",
  },
  bnb: {
    id: "binancecoin",
    symbol: "bnb",
    name: "BNB",
    image:
      "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1644979850",
    current_price: 233.53,
    market_cap: 36394422252,
    market_cap_rank: 4,
    fully_diluted_valuation: 46702866746,
    total_volume: 438293934,
    high_24h: 239.78,
    low_24h: 232.01,
    price_change_24h: -5.793919563566249,
    price_change_percentage_24h: -2.42097,
    market_cap_change_24h: -934726032.2066803,
    market_cap_change_percentage_24h: -2.50401,
    circulating_supply: 155855196,
    total_supply: 157900174,
    max_supply: 200000000,
    ath: 686.31,
    ath_change_percentage: -65.97522,
    ath_date: "2021-05-10T07:24:17.097Z",
    atl: 0.0398177,
    atl_change_percentage: 586358.59603,
    atl_date: "2017-10-19T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:53.552Z",
  },
  usdc: {
    id: "usd-coin",
    symbol: "usdc",
    name: "USD Coin",
    image:
      "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389",
    current_price: 1,
    market_cap: 28180278449,
    market_cap_rank: 5,
    fully_diluted_valuation: 28181378394,
    total_volume: 3233848261,
    high_24h: 1.003,
    low_24h: 0.996671,
    price_change_24h: 0.00083506,
    price_change_percentage_24h: 0.08356,
    market_cap_change_24h: -167846737.96614456,
    market_cap_change_percentage_24h: -0.59209,
    circulating_supply: 28173555340.2867,
    total_supply: 28174655022.3164,
    max_supply: null,
    ath: 1.17,
    ath_change_percentage: -14.72568,
    ath_date: "2019-05-08T00:40:28.300Z",
    atl: 0.877647,
    atl_change_percentage: 13.94303,
    atl_date: "2023-03-11T08:02:13.981Z",
    roi: null,
    last_updated: "2023-06-28T12:01:58.693Z",
  },
  xrp: {
    id: "ripple",
    symbol: "xrp",
    name: "XRP",
    image:
      "https://assets.coingecko.com/coins/images/44/large/xrp-symbol-white-128.png?1605778731",
    current_price: 0.475652,
    market_cap: 24858010091,
    market_cap_rank: 6,
    fully_diluted_valuation: 47571233400,
    total_volume: 654607308,
    high_24h: 0.484686,
    low_24h: 0.473033,
    price_change_24h: -0.004688275581001601,
    price_change_percentage_24h: -0.97603,
    market_cap_change_24h: -253914434.78297043,
    market_cap_change_percentage_24h: -1.01113,
    circulating_supply: 52254289650,
    total_supply: 99988655562,
    max_supply: 100000000000,
    ath: 3.4,
    ath_change_percentage: -85.99965,
    ath_date: "2018-01-07T00:00:00.000Z",
    atl: 0.00268621,
    atl_change_percentage: 17612.51832,
    atl_date: "2014-05-22T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:57.087Z",
  },
  steth: {
    id: "staked-ether",
    symbol: "steth",
    name: "Lido Staked Ether",
    image:
      "https://assets.coingecko.com/coins/images/13442/large/steth_logo.png?1608607546",
    current_price: 1861.14,
    market_cap: 13842327814,
    market_cap_rank: 7,
    fully_diluted_valuation: 13844461470,
    total_volume: 1505945,
    high_24h: 1907.7,
    low_24h: 1849.7,
    price_change_24h: -18.267695325393333,
    price_change_percentage_24h: -0.97199,
    market_cap_change_24h: -123594126.10524178,
    market_cap_change_percentage_24h: -0.88497,
    circulating_supply: 7433840.11017341,
    total_supply: 7434985.9618713,
    max_supply: 7434985.9618713,
    ath: 4829.57,
    ath_change_percentage: -61.43326,
    ath_date: "2021-11-10T14:40:47.256Z",
    atl: 482.9,
    atl_change_percentage: 285.716,
    atl_date: "2020-12-22T04:08:21.854Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.335Z",
  },
  ada: {
    id: "cardano",
    symbol: "ada",
    name: "Cardano",
    image:
      "https://assets.coingecko.com/coins/images/975/large/cardano.png?1547034860",
    current_price: 0.274551,
    market_cap: 9625177446,
    market_cap_rank: 8,
    fully_diluted_valuation: 12359330221,
    total_volume: 191761289,
    high_24h: 0.288902,
    low_24h: 0.274131,
    price_change_24h: -0.009286943131350424,
    price_change_percentage_24h: -3.27192,
    market_cap_change_24h: -318882631.9399891,
    market_cap_change_percentage_24h: -3.20676,
    circulating_supply: 35045020830.3234,
    total_supply: 45000000000,
    max_supply: 45000000000,
    ath: 3.09,
    ath_change_percentage: -91.10259,
    ath_date: "2021-09-02T06:00:10.474Z",
    atl: 0.01925275,
    atl_change_percentage: 1326.5769,
    atl_date: "2020-03-13T02:22:55.044Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.257Z",
  },
  doge: {
    id: "dogecoin",
    symbol: "doge",
    name: "Dogecoin",
    image:
      "https://assets.coingecko.com/coins/images/5/large/dogecoin.png?1547792256",
    current_price: 0.064489,
    market_cap: 9022415633,
    market_cap_rank: 9,
    fully_diluted_valuation: 9022406607,
    total_volume: 243230897,
    high_24h: 0.065716,
    low_24h: 0.06394,
    price_change_24h: -0.000871177516763064,
    price_change_percentage_24h: -1.3329,
    market_cap_change_24h: -134013439.50093079,
    market_cap_change_percentage_24h: -1.4636,
    circulating_supply: 139952756383.705,
    total_supply: 139952616383.705,
    max_supply: null,
    ath: 0.731578,
    ath_change_percentage: -91.18637,
    ath_date: "2021-05-08T05:08:23.458Z",
    atl: 0.0000869,
    atl_change_percentage: 74095.42441,
    atl_date: "2015-05-06T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.019Z",
  },
  trx: {
    id: "tron",
    symbol: "trx",
    name: "TRON",
    image:
      "https://assets.coingecko.com/coins/images/1094/large/tron-logo.png?1547035066",
    current_price: 0.074231,
    market_cap: 6680911742,
    market_cap_rank: 10,
    fully_diluted_valuation: 6680932311,
    total_volume: 225175237,
    high_24h: 0.075118,
    low_24h: 0.073723,
    price_change_24h: -0.00065338801652759,
    price_change_percentage_24h: -0.87253,
    market_cap_change_24h: -61165715.97419453,
    market_cap_change_percentage_24h: -0.90722,
    circulating_supply: 89949196078.3714,
    total_supply: 89949473008.5565,
    max_supply: null,
    ath: 0.231673,
    ath_change_percentage: -67.94002,
    ath_date: "2018-01-05T00:00:00.000Z",
    atl: 0.00180434,
    atl_change_percentage: 4016.41733,
    atl_date: "2017-11-12T00:00:00.000Z",
    roi: {
      times: 38.068919877387366,
      currency: "usd",
      percentage: 3806.891987738737,
    },
    last_updated: "2023-06-28T12:01:54.444Z",
  },
  sol: {
    id: "solana",
    symbol: "sol",
    name: "Solana",
    image:
      "https://assets.coingecko.com/coins/images/4128/large/solana.png?1640133422",
    current_price: 16.21,
    market_cap: 6484125383,
    market_cap_rank: 11,
    fully_diluted_valuation: 8933728212,
    total_volume: 283583871,
    high_24h: 16.92,
    low_24h: 15.94,
    price_change_24h: -0.43780985548619356,
    price_change_percentage_24h: -2.62972,
    market_cap_change_24h: -182874615.07249165,
    market_cap_change_percentage_24h: -2.74298,
    circulating_supply: 400054601.360775,
    total_supply: 551189075.998009,
    max_supply: null,
    ath: 259.96,
    ath_change_percentage: -93.76577,
    ath_date: "2021-11-06T21:54:35.825Z",
    atl: 0.500801,
    atl_change_percentage: 3136.10767,
    atl_date: "2020-05-11T19:35:23.449Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.896Z",
  },
  ltc: {
    id: "litecoin",
    symbol: "ltc",
    name: "Litecoin",
    image:
      "https://assets.coingecko.com/coins/images/2/large/litecoin.png?1547033580",
    current_price: 85.77,
    market_cap: 6281678189,
    market_cap_rank: 12,
    fully_diluted_valuation: 7204111421,
    total_volume: 424730413,
    high_24h: 89.25,
    low_24h: 84.7,
    price_change_24h: -3.1076215824064803,
    price_change_percentage_24h: -3.49659,
    market_cap_change_24h: -238197576.4910574,
    market_cap_change_percentage_24h: -3.65341,
    circulating_supply: 73244420.7334713,
    total_supply: 84000000,
    max_supply: 84000000,
    ath: 410.26,
    ath_change_percentage: -79.09177,
    ath_date: "2021-05-10T03:13:07.904Z",
    atl: 1.15,
    atl_change_percentage: 7366.47683,
    atl_date: "2015-01-14T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.137Z",
  },
  dot: {
    id: "polkadot",
    symbol: "dot",
    name: "Polkadot",
    image:
      "https://assets.coingecko.com/coins/images/12171/large/polkadot.png?1639712644",
    current_price: 4.99,
    market_cap: 6232363219,
    market_cap_rank: 13,
    fully_diluted_valuation: 6636696148,
    total_volume: 95086506,
    high_24h: 5.14,
    low_24h: 4.97,
    price_change_24h: -0.12552475050890521,
    price_change_percentage_24h: -2.45441,
    market_cap_change_24h: -141728915.70053673,
    market_cap_change_percentage_24h: -2.22352,
    circulating_supply: 1247745016.20444,
    total_supply: 1328694149.01238,
    max_supply: null,
    ath: 54.98,
    ath_change_percentage: -90.79897,
    ath_date: "2021-11-04T14:10:09.301Z",
    atl: 2.7,
    atl_change_percentage: 87.54013,
    atl_date: "2020-08-20T05:48:11.359Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.711Z",
  },
  matic: {
    id: "matic-network",
    symbol: "matic",
    name: "Polygon",
    image:
      "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912",
    current_price: 0.631677,
    market_cap: 5886755756,
    market_cap_rank: 14,
    fully_diluted_valuation: 6316621378,
    total_volume: 213182147,
    high_24h: 0.671396,
    low_24h: 0.628723,
    price_change_24h: -0.034880513695092485,
    price_change_percentage_24h: -5.23293,
    market_cap_change_24h: -325552958.6849394,
    market_cap_change_percentage_24h: -5.24045,
    circulating_supply: 9319469069.28493,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 2.92,
    ath_change_percentage: -78.33424,
    ath_date: "2021-12-27T02:08:34.307Z",
    atl: 0.00314376,
    atl_change_percentage: 19997.92778,
    atl_date: "2019-05-10T00:00:00.000Z",
    roi: {
      times: 239.18133611555484,
      currency: "usd",
      percentage: 23918.133611555484,
    },
    last_updated: "2023-06-28T12:01:58.473Z",
  },
  wbtc: {
    id: "wrapped-bitcoin",
    symbol: "wbtc",
    name: "Wrapped Bitcoin",
    image:
      "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1548822744",
    current_price: 30347,
    market_cap: 4774300913,
    market_cap_rank: 15,
    fully_diluted_valuation: 4774300913,
    total_volume: 151790674,
    high_24h: 30799,
    low_24h: 30196,
    price_change_24h: -282.253133035083,
    price_change_percentage_24h: -0.92152,
    market_cap_change_24h: -39190852.49259853,
    market_cap_change_percentage_24h: -0.81419,
    circulating_supply: 157326.56582586,
    total_supply: 157326.56582586,
    max_supply: 157326.56582586,
    ath: 70643,
    ath_change_percentage: -57.04257,
    ath_date: "2021-11-10T14:40:19.650Z",
    atl: 3139.17,
    atl_change_percentage: 866.70652,
    atl_date: "2019-04-02T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:59.633Z",
  },
  bch: {
    id: "bitcoin-cash",
    symbol: "bch",
    name: "Bitcoin Cash",
    image:
      "https://assets.coingecko.com/coins/images/780/large/bitcoin-cash-circle.png?1594689492",
    current_price: 234.89,
    market_cap: 4568188038,
    market_cap_rank: 16,
    fully_diluted_valuation: 4936404144,
    total_volume: 1208035101,
    high_24h: 236.18,
    low_24h: 223.54,
    price_change_24h: -0.1829226844729419,
    price_change_percentage_24h: -0.07781,
    market_cap_change_24h: 25998786,
    market_cap_change_percentage_24h: 0.57238,
    circulating_supply: 19433568.6466508,
    total_supply: 21000000,
    max_supply: 21000000,
    ath: 3785.82,
    ath_change_percentage: -93.79086,
    ath_date: "2017-12-20T00:00:00.000Z",
    atl: 76.93,
    atl_change_percentage: 205.54038,
    atl_date: "2018-12-16T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:53.465Z",
  },
  avax: {
    id: "avalanche-2",
    symbol: "avax",
    name: "Avalanche",
    image:
      "https://assets.coingecko.com/coins/images/12559/large/Avalanche_Circle_RedWhite_Trans.png?1670992574",
    current_price: 12.8,
    market_cap: 4424947452,
    market_cap_rank: 17,
    fully_diluted_valuation: 9228867687,
    total_volume: 105137794,
    high_24h: 13.41,
    low_24h: 12.69,
    price_change_24h: -0.5600997241101595,
    price_change_percentage_24h: -4.19174,
    market_cap_change_24h: -183489528.68339443,
    market_cap_change_percentage_24h: -3.9816,
    circulating_supply: 345217016.185913,
    total_supply: 431936525.53112,
    max_supply: 720000000,
    ath: 144.96,
    ath_change_percentage: -91.04633,
    ath_date: "2021-11-21T14:18:56.538Z",
    atl: 2.8,
    atl_change_percentage: 363.37199,
    atl_date: "2020-12-31T13:15:21.540Z",
    roi: null,
    last_updated: "2023-06-28T12:01:54.577Z",
  },
  dai: {
    id: "dai",
    symbol: "dai",
    name: "Dai",
    image:
      "https://assets.coingecko.com/coins/images/9956/large/Badge_Dai.png?1687143508",
    current_price: 0.999965,
    market_cap: 4406622112,
    market_cap_rank: 18,
    fully_diluted_valuation: 4405579825,
    total_volume: 136849416,
    high_24h: 1.002,
    low_24h: 0.998415,
    price_change_24h: -0.000939394398852156,
    price_change_percentage_24h: -0.09385,
    market_cap_change_24h: -20165336.483839035,
    market_cap_change_percentage_24h: -0.45553,
    circulating_supply: 4406751637.35513,
    total_supply: 4405709319.88528,
    max_supply: 4405709319.88528,
    ath: 1.22,
    ath_change_percentage: -17.96374,
    ath_date: "2020-03-13T03:02:50.373Z",
    atl: 0.88196,
    atl_change_percentage: 13.37987,
    atl_date: "2023-03-11T07:50:50.514Z",
    roi: null,
    last_updated: "2023-06-28T12:00:00.260Z",
  },
  shib: {
    id: "shiba-inu",
    symbol: "shib",
    name: "Shiba Inu",
    image:
      "https://assets.coingecko.com/coins/images/11939/large/shiba.png?1622619446",
    current_price: 0.00000735,
    market_cap: 4340212361,
    market_cap_rank: 19,
    fully_diluted_valuation: 7364459066,
    total_volume: 89771928,
    high_24h: 0.00000764,
    low_24h: 0.00000726,
    price_change_24h: -2.61369550922e-7,
    price_change_percentage_24h: -3.43275,
    market_cap_change_24h: -146872619.94618702,
    market_cap_change_percentage_24h: -3.27323,
    circulating_supply: 589339192041590,
    total_supply: 999988939430485,
    max_supply: null,
    ath: 0.00008616,
    ath_change_percentage: -91.27565,
    ath_date: "2021-10-28T03:54:55.568Z",
    atl: 5.6366e-11,
    atl_change_percentage: 13335490.7049,
    atl_date: "2020-11-28T11:26:25.838Z",
    roi: null,
    last_updated: "2023-06-28T12:02:00.109Z",
  },
  busd: {
    id: "binance-usd",
    symbol: "busd",
    name: "Binance USD",
    image:
      "https://assets.coingecko.com/coins/images/9576/large/BUSD.png?1568947766",
    current_price: 1.001,
    market_cap: 4189516625,
    market_cap_rank: 20,
    fully_diluted_valuation: 4189516625,
    total_volume: 2060899973,
    high_24h: 1.008,
    low_24h: 0.996435,
    price_change_24h: -0.000271565955265674,
    price_change_percentage_24h: -0.02713,
    market_cap_change_24h: -25750483.862311363,
    market_cap_change_percentage_24h: -0.61089,
    circulating_supply: 4188534283.66,
    total_supply: 4188534283.66,
    max_supply: null,
    ath: 1.15,
    ath_change_percentage: -13.30876,
    ath_date: "2020-03-13T02:35:42.953Z",
    atl: 0.901127,
    atl_change_percentage: 11.03801,
    atl_date: "2021-05-19T13:04:37.445Z",
    roi: null,
    last_updated: "2023-06-28T12:01:57.056Z",
  },
  uni: {
    id: "uniswap",
    symbol: "uni",
    name: "Uniswap",
    image:
      "https://assets.coingecko.com/coins/images/12504/large/uni.jpg?1687143398",
    current_price: 5.08,
    market_cap: 3839840700,
    market_cap_rank: 21,
    fully_diluted_valuation: 5094203376,
    total_volume: 45250032,
    high_24h: 5.35,
    low_24h: 5.07,
    price_change_24h: -0.23406052948428613,
    price_change_percentage_24h: -4.40372,
    market_cap_change_24h: -168037343.29049873,
    market_cap_change_percentage_24h: -4.19268,
    circulating_supply: 753766667,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 44.92,
    ath_change_percentage: -88.53252,
    ath_date: "2021-05-03T05:25:04.822Z",
    atl: 1.03,
    atl_change_percentage: 400.01109,
    atl_date: "2020-09-17T01:20:38.214Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.494Z",
  },
  leo: {
    id: "leo-token",
    symbol: "leo",
    name: "LEO Token",
    image:
      "https://assets.coingecko.com/coins/images/8418/large/leo-token.png?1558326215",
    current_price: 3.96,
    market_cap: 3687882450,
    market_cap_rank: 22,
    fully_diluted_valuation: 3907105246,
    total_volume: 778850,
    high_24h: 3.97,
    low_24h: 3.8,
    price_change_24h: 0.051963,
    price_change_percentage_24h: 1.32929,
    market_cap_change_24h: 48728700,
    market_cap_change_percentage_24h: 1.33901,
    circulating_supply: 929958945.9,
    total_supply: 985239504,
    max_supply: null,
    ath: 8.14,
    ath_change_percentage: -51.36867,
    ath_date: "2022-02-08T17:40:10.285Z",
    atl: 0.799859,
    atl_change_percentage: 394.62782,
    atl_date: "2019-12-24T15:14:35.376Z",
    roi: null,
    last_updated: "2023-06-28T12:01:58.317Z",
  },
  tusd: {
    id: "true-usd",
    symbol: "tusd",
    name: "TrueUSD",
    image:
      "https://assets.coingecko.com/coins/images/3449/large/tusd.png?1618395665",
    current_price: 0.998347,
    market_cap: 3134255023,
    market_cap_rank: 23,
    fully_diluted_valuation: 3134255023,
    total_volume: 431253958,
    high_24h: 1.004,
    low_24h: 0.994928,
    price_change_24h: -0.000559566389345734,
    price_change_percentage_24h: -0.05602,
    market_cap_change_24h: -2509235.7024731636,
    market_cap_change_percentage_24h: -0.07999,
    circulating_supply: 3139448995.87,
    total_supply: 3139448995.87,
    max_supply: null,
    ath: 1.62,
    ath_change_percentage: -38.29642,
    ath_date: "2018-08-26T20:41:09.375Z",
    atl: 0.88355,
    atl_change_percentage: 13.03044,
    atl_date: "2020-03-12T10:47:51.380Z",
    roi: null,
    last_updated: "2023-06-28T12:01:59.095Z",
  },
  link: {
    id: "chainlink",
    symbol: "link",
    name: "Chainlink",
    image:
      "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700",
    current_price: 6,
    market_cap: 3104788947,
    market_cap_rank: 24,
    fully_diluted_valuation: 6004233444,
    total_volume: 149506223,
    high_24h: 6.24,
    low_24h: 5.97,
    price_change_24h: -0.22483956005694772,
    price_change_percentage_24h: -3.6106,
    market_cap_change_24h: -117220255.6508522,
    market_cap_change_percentage_24h: -3.63811,
    circulating_supply: 517099972.2305644,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 52.7,
    ath_change_percentage: -88.60597,
    ath_date: "2021-05-10T00:13:57.214Z",
    atl: 0.148183,
    atl_change_percentage: 3951.92135,
    atl_date: "2017-11-29T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:57.624Z",
  },
  xmr: {
    id: "monero",
    symbol: "xmr",
    name: "Monero",
    image:
      "https://assets.coingecko.com/coins/images/69/large/monero_logo.png?1547033729",
    current_price: 166,
    market_cap: 3013224113,
    market_cap_rank: 25,
    fully_diluted_valuation: null,
    total_volume: 78072998,
    high_24h: 170.59,
    low_24h: 165.99,
    price_change_24h: -1.0322572629526974,
    price_change_percentage_24h: -0.61801,
    market_cap_change_24h: -26628063.063114166,
    market_cap_change_percentage_24h: -0.87597,
    circulating_supply: 18147820.3764146,
    total_supply: null,
    max_supply: null,
    ath: 542.33,
    ath_change_percentage: -69.37858,
    ath_date: "2018-01-09T00:00:00.000Z",
    atl: 0.216177,
    atl_change_percentage: 76720.38695,
    atl_date: "2015-01-14T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.153Z",
  },
  atom: {
    id: "cosmos",
    symbol: "atom",
    name: "Cosmos Hub",
    image:
      "https://assets.coingecko.com/coins/images/1481/large/cosmos_hub.png?1555657960",
    current_price: 9.29,
    market_cap: 2717018588,
    market_cap_rank: 26,
    fully_diluted_valuation: null,
    total_volume: 55408769,
    high_24h: 9.42,
    low_24h: 9.17,
    price_change_24h: -0.04282673901883527,
    price_change_percentage_24h: -0.45906,
    market_cap_change_24h: -12760307.479881287,
    market_cap_change_percentage_24h: -0.46745,
    circulating_supply: 292586163.827428,
    total_supply: null,
    max_supply: null,
    ath: 44.45,
    ath_change_percentage: -79.10797,
    ath_date: "2022-01-17T00:34:41.497Z",
    atl: 1.16,
    atl_change_percentage: 700.485,
    atl_date: "2020-03-13T02:27:44.591Z",
    roi: {
      times: 91.86384087521397,
      currency: "usd",
      percentage: 9186.384087521395,
    },
    last_updated: "2023-06-28T12:01:57.047Z",
  },
  xlm: {
    id: "stellar",
    symbol: "xlm",
    name: "Stellar",
    image:
      "https://assets.coingecko.com/coins/images/100/large/Stellar_symbol_black_RGB.png?1552356157",
    current_price: 0.100458,
    market_cap: 2709017358,
    market_cap_rank: 27,
    fully_diluted_valuation: 5021851217,
    total_volume: 182435510,
    high_24h: 0.105578,
    low_24h: 0.095346,
    price_change_24h: 0.00458085,
    price_change_percentage_24h: 4.77785,
    market_cap_change_24h: 131117514,
    market_cap_change_percentage_24h: 5.08621,
    circulating_supply: 26973262215.7958,
    total_supply: 50001787293.4738,
    max_supply: 50001787293.4738,
    ath: 0.875563,
    ath_change_percentage: -88.52927,
    ath_date: "2018-01-03T00:00:00.000Z",
    atl: 0.00047612,
    atl_change_percentage: 20993.99285,
    atl_date: "2015-03-05T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.436Z",
  },
  okb: {
    id: "okb",
    symbol: "okb",
    name: "OKB",
    image:
      "https://assets.coingecko.com/coins/images/4463/large/WeChat_Image_20220118095654.png?1642471050",
    current_price: 43.87,
    market_cap: 2632452025,
    market_cap_rank: 28,
    fully_diluted_valuation: 13162260123,
    total_volume: 4289929,
    high_24h: 44.59,
    low_24h: 43.59,
    price_change_24h: -0.14173979739504716,
    price_change_percentage_24h: -0.32204,
    market_cap_change_24h: -31587060.159231186,
    market_cap_change_percentage_24h: -1.18568,
    circulating_supply: 60000000,
    total_supply: 235957685.3,
    max_supply: 300000000,
    ath: 58.66,
    ath_change_percentage: -25.17876,
    ath_date: "2023-02-18T01:21:37.582Z",
    atl: 0.580608,
    atl_change_percentage: 7459.16436,
    atl_date: "2019-01-14T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:02:03.668Z",
  },
  etc: {
    id: "ethereum-classic",
    symbol: "etc",
    name: "Ethereum Classic",
    image:
      "https://assets.coingecko.com/coins/images/453/large/ethereum-classic-logo.png?1547034169",
    current_price: 17.87,
    market_cap: 2531804763,
    market_cap_rank: 29,
    fully_diluted_valuation: 3765225611,
    total_volume: 106430178,
    high_24h: 18.92,
    low_24h: 17.67,
    price_change_24h: -0.900663924608228,
    price_change_percentage_24h: -4.79809,
    market_cap_change_24h: -131492752.81849432,
    market_cap_change_percentage_24h: -4.93722,
    circulating_supply: 141678432.738398,
    total_supply: 210700000,
    max_supply: 210700000,
    ath: 167.09,
    ath_change_percentage: -89.30482,
    ath_date: "2021-05-06T18:34:22.133Z",
    atl: 0.615038,
    atl_change_percentage: 2805.52435,
    atl_date: "2016-07-25T00:00:00.000Z",
    roi: {
      times: 38.7125660964118,
      currency: "usd",
      percentage: 3871.25660964118,
    },
    last_updated: "2023-06-28T12:01:58.592Z",
  },
  ton: {
    id: "tokamak-network",
    symbol: "ton",
    name: "Tokamak Network",
    image:
      "https://assets.coingecko.com/coins/images/12260/large/D919x5-s_400x400.png?1598568068",
    current_price: 1.49,
    market_cap: 70771858,
    market_cap_rank: 319,
    fully_diluted_valuation: 74981636,
    total_volume: 1759385,
    high_24h: 1.58,
    low_24h: 1.47,
    price_change_24h: -0.05341811565706944,
    price_change_percentage_24h: -3.45726,
    market_cap_change_24h: -2292620.8980855197,
    market_cap_change_percentage_24h: -3.13781,
    circulating_supply: 47192794.00458346,
    total_supply: 50000000,
    max_supply: null,
    ath: 30.51,
    ath_change_percentage: -94.96847,
    ath_date: "2021-04-02T17:44:40.065Z",
    atl: 0.999246,
    atl_change_percentage: 53.63149,
    atl_date: "2022-12-29T02:31:58.533Z",
    roi: null,
    last_updated: "2023-06-28T12:04:46.177Z",
  },
  icp: {
    id: "internet-computer",
    symbol: "icp",
    name: "Internet Computer",
    image:
      "https://assets.coingecko.com/coins/images/14495/large/Internet_Computer_logo.png?1620703073",
    current_price: 4.21,
    market_cap: 1843317067,
    market_cap_rank: 31,
    fully_diluted_valuation: 1976688487,
    total_volume: 12438836,
    high_24h: 4.39,
    low_24h: 4.19,
    price_change_24h: -0.121503490670408,
    price_change_percentage_24h: -2.80508,
    market_cap_change_24h: -48440569.533222914,
    market_cap_change_percentage_24h: -2.56061,
    circulating_supply: 437554852.809529,
    total_supply: 500024492.287403,
    max_supply: 469213710,
    ath: 700.65,
    ath_change_percentage: -99.39886,
    ath_date: "2021-05-10T16:05:53.653Z",
    atl: 3.4,
    atl_change_percentage: 23.91527,
    atl_date: "2022-12-19T22:45:01.729Z",
    roi: null,
    last_updated: "2023-06-28T12:01:58.138Z",
  },
  fil: {
    id: "filecoin",
    symbol: "fil",
    name: "Filecoin",
    image:
      "https://assets.coingecko.com/coins/images/12817/large/filecoin.png?1602753933",
    current_price: 3.93,
    market_cap: 1701313122,
    market_cap_rank: 32,
    fully_diluted_valuation: 7713601358,
    total_volume: 77442049,
    high_24h: 4.07,
    low_24h: 3.88,
    price_change_24h: -0.11515582796758439,
    price_change_percentage_24h: -2.84813,
    market_cap_change_24h: -49990278.14764142,
    market_cap_change_percentage_24h: -2.85446,
    circulating_supply: 432801112,
    total_supply: 1962281489,
    max_supply: 1962281489,
    ath: 236.84,
    ath_change_percentage: -98.34189,
    ath_date: "2021-04-01T13:29:41.564Z",
    atl: 2.64,
    atl_change_percentage: 48.69835,
    atl_date: "2022-12-16T22:45:28.552Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.049Z",
  },
  ldo: {
    id: "lido-dao",
    symbol: "ldo",
    name: "Lido DAO",
    image:
      "https://assets.coingecko.com/coins/images/13573/large/Lido_DAO.png?1609873644",
    current_price: 1.86,
    market_cap: 1640680898,
    market_cap_rank: 33,
    fully_diluted_valuation: 1865336668,
    total_volume: 27780763,
    high_24h: 1.97,
    low_24h: 1.85,
    price_change_24h: -0.09639557613189131,
    price_change_percentage_24h: -4.91468,
    market_cap_change_24h: -83038196.00904989,
    market_cap_change_percentage_24h: -4.81739,
    circulating_supply: 879562883.427804,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 7.3,
    ath_change_percentage: -74.48258,
    ath_date: "2021-08-20T08:35:20.158Z",
    atl: 0.40615,
    atl_change_percentage: 358.82191,
    atl_date: "2022-06-18T20:55:12.035Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.392Z",
  },
  hbar: {
    id: "hedera-hashgraph",
    symbol: "hbar",
    name: "Hedera",
    image:
      "https://assets.coingecko.com/coins/images/3688/large/hbar.png?1637045634",
    current_price: 0.04906804,
    market_cap: 1558180523,
    market_cap_rank: 34,
    fully_diluted_valuation: 2453429432,
    total_volume: 16636593,
    high_24h: 0.051217,
    low_24h: 0.04871857,
    price_change_24h: -0.001340493913250418,
    price_change_percentage_24h: -2.65926,
    market_cap_change_24h: -42550076.08002663,
    market_cap_change_percentage_24h: -2.65817,
    circulating_supply: 31755152663.7226,
    total_supply: 50000000000,
    max_supply: 50000000000,
    ath: 0.569229,
    ath_change_percentage: -91.37772,
    ath_date: "2021-09-15T10:40:28.318Z",
    atl: 0.00986111,
    atl_change_percentage: 397.71801,
    atl_date: "2020-01-02T17:30:24.852Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.699Z",
  },
  qnt: {
    id: "quant-network",
    symbol: "qnt",
    name: "Quant",
    image:
      "https://assets.coingecko.com/coins/images/3370/large/5ZOu7brX_400x400.jpg?1612437252",
    current_price: 105.89,
    market_cap: 1540208698,
    market_cap_rank: 35,
    fully_diluted_valuation: 1547443359,
    total_volume: 12323858,
    high_24h: 107.38,
    low_24h: 105.11,
    price_change_24h: 0.02418299,
    price_change_percentage_24h: 0.02284,
    market_cap_change_24h: -2397714.458174944,
    market_cap_change_percentage_24h: -0.15543,
    circulating_supply: 14544176.164091088,
    total_supply: 14612493,
    max_supply: 14612493,
    ath: 427.42,
    ath_change_percentage: -75.21804,
    ath_date: "2021-09-11T09:15:00.668Z",
    atl: 0.215773,
    atl_change_percentage: 48990.24885,
    atl_date: "2018-08-23T00:00:00.000Z",
    roi: {
      times: 23.450811694951284,
      currency: "eth",
      percentage: 2345.0811694951285,
    },
    last_updated: "2023-06-28T12:01:58.137Z",
  },
  apt: {
    id: "aptos",
    symbol: "apt",
    name: "Aptos",
    image:
      "https://assets.coingecko.com/coins/images/26455/large/aptos_round.png?1666839629",
    current_price: 7.06,
    market_cap: 1472290967,
    market_cap_rank: 36,
    fully_diluted_valuation: 7367715472,
    total_volume: 56825238,
    high_24h: 7.5,
    low_24h: 7.01,
    price_change_24h: -0.43596658198739213,
    price_change_percentage_24h: -5.81344,
    market_cap_change_24h: -88020749.01161122,
    market_cap_change_percentage_24h: -5.64123,
    circulating_supply: 208191719.791212,
    total_supply: 1041843894.56903,
    max_supply: null,
    ath: 19.92,
    ath_change_percentage: -64.53593,
    ath_date: "2023-01-26T14:25:17.390Z",
    atl: 3.08,
    atl_change_percentage: 129.38483,
    atl_date: "2022-12-29T21:35:14.796Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.737Z",
  },
  cro: {
    id: "crypto-com-chain",
    symbol: "cro",
    name: "Cronos",
    image:
      "https://assets.coingecko.com/coins/images/7310/large/cro_token_logo.png?1669699773",
    current_price: 0.056123,
    market_cap: 1466639143,
    market_cap_rank: 37,
    fully_diluted_valuation: 1683696521,
    total_volume: 7415533,
    high_24h: 0.057282,
    low_24h: 0.055859,
    price_change_24h: -0.000304175049910364,
    price_change_percentage_24h: -0.53906,
    market_cap_change_24h: -8920271.833804369,
    market_cap_change_percentage_24h: -0.60453,
    circulating_supply: 26132485120.1869,
    total_supply: 30000000000,
    max_supply: null,
    ath: 0.965407,
    ath_change_percentage: -94.18661,
    ath_date: "2021-11-24T15:53:54.855Z",
    atl: 0.0121196,
    atl_change_percentage: 363.07549,
    atl_date: "2019-02-08T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.520Z",
  },
  arb: {
    id: "arbitrum",
    symbol: "arb",
    name: "Arbitrum",
    image:
      "https://assets.coingecko.com/coins/images/16547/large/photo_2023-03-29_21.47.00.jpeg?1680097630",
    current_price: 1.15,
    market_cap: 1462376579,
    market_cap_rank: 38,
    fully_diluted_valuation: 11469620231,
    total_volume: 260296290,
    high_24h: 1.24,
    low_24h: 1.14,
    price_change_24h: -0.06652732422529994,
    price_change_percentage_24h: -5.48779,
    market_cap_change_24h: -87348219.44341254,
    market_cap_change_percentage_24h: -5.63637,
    circulating_supply: 1275000000,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 8.67,
    ath_change_percentage: -86.76669,
    ath_date: "2023-03-23T13:10:03.106Z",
    atl: 0.912886,
    atl_change_percentage: 25.72869,
    atl_date: "2023-06-15T07:25:14.752Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.261Z",
  },
  vet: {
    id: "vechain",
    symbol: "vet",
    name: "VeChain",
    image:
      "https://assets.coingecko.com/coins/images/1167/large/VET_Token_Icon.png?1680067517",
    current_price: 0.01907721,
    market_cap: 1386840386,
    market_cap_rank: 39,
    fully_diluted_valuation: 1653818091,
    total_volume: 79211609,
    high_24h: 0.01976487,
    low_24h: 0.01846396,
    price_change_24h: 0.0004788,
    price_change_percentage_24h: 2.5744,
    market_cap_change_24h: 40920121,
    market_cap_change_percentage_24h: 3.04031,
    circulating_supply: 72714516834,
    total_supply: 85985041177,
    max_supply: 86712634466,
    ath: 0.280991,
    ath_change_percentage: -93.21218,
    ath_date: "2021-04-19T01:08:21.675Z",
    atl: 0.00191713,
    atl_change_percentage: 894.87933,
    atl_date: "2020-03-13T02:29:59.652Z",
    roi: {
      times: 2.5854624414887173,
      currency: "eth",
      percentage: 258.5462441488717,
    },
    last_updated: "2023-06-28T12:01:56.883Z",
  },
  near: {
    id: "near",
    symbol: "near",
    name: "NEAR Protocol",
    image:
      "https://assets.coingecko.com/coins/images/10365/large/near.jpg?1683515160",
    current_price: 1.4,
    market_cap: 1300707801,
    market_cap_rank: 40,
    fully_diluted_valuation: 1404261841,
    total_volume: 47572365,
    high_24h: 1.5,
    low_24h: 1.39,
    price_change_24h: -0.08820876521172583,
    price_change_percentage_24h: -5.92106,
    market_cap_change_24h: -88835375.27012086,
    market_cap_change_percentage_24h: -6.39314,
    circulating_supply: 926257313.955089,
    total_supply: 1000000000,
    max_supply: null,
    ath: 20.44,
    ath_change_percentage: -93.13099,
    ath_date: "2022-01-16T22:09:45.873Z",
    atl: 0.526762,
    atl_change_percentage: 166.51409,
    atl_date: "2020-11-04T16:09:15.137Z",
    roi: null,
    last_updated: "2023-06-28T12:01:59.605Z",
  },
  frax: {
    id: "frax",
    symbol: "frax",
    name: "Frax",
    image:
      "https://assets.coingecko.com/coins/images/13422/large/FRAX_icon.png?1679886922",
    current_price: 1.001,
    market_cap: 1003491662,
    market_cap_rank: 41,
    fully_diluted_valuation: 1003491662,
    total_volume: 8043110,
    high_24h: 1.002,
    low_24h: 0.996203,
    price_change_24h: 0.00151827,
    price_change_percentage_24h: 0.15195,
    market_cap_change_24h: -611553.0562397242,
    market_cap_change_percentage_24h: -0.06091,
    circulating_supply: 1004141409.40878,
    total_supply: 1004141409.40878,
    max_supply: 1004141409.40878,
    ath: 1.14,
    ath_change_percentage: -12.17138,
    ath_date: "2021-02-07T12:55:35.766Z",
    atl: 0.874536,
    atl_change_percentage: 14.53413,
    atl_date: "2023-03-11T07:50:39.316Z",
    roi: null,
    last_updated: "2023-06-28T12:01:59.375Z",
  },
  usdp: {
    id: "paxos-standard",
    symbol: "usdp",
    name: "Pax Dollar",
    image:
      "https://assets.coingecko.com/coins/images/6013/large/Pax_Dollar.png?1629877204",
    current_price: 0.999409,
    market_cap: 994552041,
    market_cap_rank: 42,
    fully_diluted_valuation: 994552041,
    total_volume: 4173263,
    high_24h: 1.002,
    low_24h: 0.991624,
    price_change_24h: 0.00020923,
    price_change_percentage_24h: 0.02094,
    market_cap_change_24h: 397739,
    market_cap_change_percentage_24h: 0.04001,
    circulating_supply: 995140452.730683,
    total_supply: 995140452.730683,
    max_supply: null,
    ath: 1.13,
    ath_change_percentage: -11.37931,
    ath_date: "2018-10-15T07:09:12.459Z",
    atl: 0.863529,
    atl_change_percentage: 15.72896,
    atl_date: "2021-05-19T13:14:42.046Z",
    roi: {
      times: -0.0005912849587648,
      currency: "usd",
      percentage: -0.05912849587648,
    },
    last_updated: "2023-06-28T12:01:51.702Z",
  },
  stx: {
    id: "blockstack",
    symbol: "stx",
    name: "Stacks",
    image:
      "https://assets.coingecko.com/coins/images/2069/large/Stacks_logo_full.png?1604112510",
    current_price: 0.68248,
    market_cap: 948023571,
    market_cap_rank: 44,
    fully_diluted_valuation: 1240782867,
    total_volume: 32141393,
    high_24h: 0.739423,
    low_24h: 0.676108,
    price_change_24h: -0.039361204142441264,
    price_change_percentage_24h: -5.45289,
    market_cap_change_24h: -54827165.21179271,
    market_cap_change_percentage_24h: -5.46713,
    circulating_supply: 1389047912.50708,
    total_supply: 1818000000,
    max_supply: 1818000000,
    ath: 3.39,
    ath_change_percentage: -79.85535,
    ath_date: "2021-12-01T01:32:34.725Z",
    atl: 0.04559639,
    atl_change_percentage: 1397.55352,
    atl_date: "2020-03-13T02:29:26.415Z",
    roi: {
      times: 4.68733575442808,
      currency: "usd",
      percentage: 468.733575442808,
    },
    last_updated: "2023-06-28T12:01:52.925Z",
  },
  grt: {
    id: "the-graph",
    symbol: "grt",
    name: "The Graph",
    image:
      "https://assets.coingecko.com/coins/images/13397/large/Graph_Token.png?1608145566",
    current_price: 0.104607,
    market_cap: 947975135,
    market_cap_rank: 43,
    fully_diluted_valuation: 1046668925,
    total_volume: 21401125,
    high_24h: 0.110545,
    low_24h: 0.104042,
    price_change_24h: -0.005596760342390401,
    price_change_percentage_24h: -5.07857,
    market_cap_change_24h: -49157124.508046985,
    market_cap_change_percentage_24h: -4.92985,
    circulating_supply: 9057067738.09017,
    total_supply: 10000000000,
    max_supply: null,
    ath: 2.84,
    ath_change_percentage: -96.31507,
    ath_date: "2021-02-12T07:28:45.775Z",
    atl: 0.052051,
    atl_change_percentage: 101.16304,
    atl_date: "2022-11-22T10:05:03.503Z",
    roi: null,
    last_updated: "2023-06-28T12:01:58.470Z",
  },
  reth: {
    id: "rocket-pool-eth",
    symbol: "reth",
    name: "Rocket Pool ETH",
    image:
      "https://assets.coingecko.com/coins/images/20764/large/reth.png?1637652366",
    current_price: 2002.09,
    market_cap: 923755235,
    market_cap_rank: 45,
    fully_diluted_valuation: 923833949,
    total_volume: 1907727,
    high_24h: 2053.41,
    low_24h: 1990.78,
    price_change_24h: -19.243854970904977,
    price_change_percentage_24h: -0.95204,
    market_cap_change_24h: -7644215.923818946,
    market_cap_change_percentage_24h: -0.82072,
    circulating_supply: 460772.245634566,
    total_supply: 460811.508301977,
    max_supply: null,
    ath: 4814.31,
    ath_change_percentage: -58.35934,
    ath_date: "2021-12-01T08:03:50.749Z",
    atl: 887.26,
    atl_change_percentage: 125.94308,
    atl_date: "2022-06-18T20:55:45.957Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.365Z",
  },
  aave: {
    id: "aave",
    symbol: "aave",
    name: "Aave",
    image:
      "https://assets.coingecko.com/coins/images/12645/large/AAVE.png?1601374110",
    current_price: 61.09,
    market_cap: 883342332,
    market_cap_rank: 47,
    fully_diluted_valuation: 978824949,
    total_volume: 97257130,
    high_24h: 65.05,
    low_24h: 60.92,
    price_change_24h: -3.278287311420108,
    price_change_percentage_24h: -5.09296,
    market_cap_change_24h: -47215741.76364267,
    market_cap_change_percentage_24h: -5.07392,
    circulating_supply: 14439228.714428958,
    total_supply: 16000000,
    max_supply: 16000000,
    ath: 661.69,
    ath_change_percentage: -90.75117,
    ath_date: "2021-05-18T21:19:59.514Z",
    atl: 26.02,
    atl_change_percentage: 135.16989,
    atl_date: "2020-11-05T09:20:11.928Z",
    roi: null,
    last_updated: "2023-06-28T12:01:57.416Z",
  },
  algo: {
    id: "algorand",
    symbol: "algo",
    name: "Algorand",
    image:
      "https://assets.coingecko.com/coins/images/4380/large/download.png?1547039725",
    current_price: 0.120492,
    market_cap: 882808948,
    market_cap_rank: 46,
    fully_diluted_valuation: 1204911753,
    total_volume: 37914558,
    high_24h: 0.129725,
    low_24h: 0.119911,
    price_change_24h: -0.008532207376833164,
    price_change_percentage_24h: -6.61286,
    market_cap_change_24h: -58848780.815891385,
    market_cap_change_percentage_24h: -6.24949,
    circulating_supply: 7326751903.83754,
    total_supply: 7326751907.22155,
    max_supply: 10000000000,
    ath: 3.56,
    ath_change_percentage: -96.61559,
    ath_date: "2019-06-20T14:51:19.480Z",
    atl: 0.098399,
    atl_change_percentage: 22.4854,
    atl_date: "2023-06-10T04:31:46.997Z",
    roi: {
      times: -0.9497949051469894,
      currency: "usd",
      percentage: -94.97949051469894,
    },
    last_updated: "2023-06-28T12:01:55.111Z",
  },
  egld: {
    id: "elrond-erd-2",
    symbol: "egld",
    name: "MultiversX",
    image:
      "https://assets.coingecko.com/coins/images/12335/large/egld-token-logo.png?1673490885",
    current_price: 33.02,
    market_cap: 847557348,
    market_cap_rank: 48,
    fully_diluted_valuation: 1039257102,
    total_volume: 8481252,
    high_24h: 34.12,
    low_24h: 32.82,
    price_change_24h: -0.8463046398845293,
    price_change_percentage_24h: -2.49879,
    market_cap_change_24h: -20483719.195623755,
    market_cap_change_percentage_24h: -2.35976,
    circulating_supply: 25620993,
    total_supply: 25626145,
    max_supply: 31415926,
    ath: 545.64,
    ath_change_percentage: -93.84124,
    ath_date: "2021-11-23T10:33:26.737Z",
    atl: 6.51,
    atl_change_percentage: 416.46471,
    atl_date: "2020-10-07T01:44:53.554Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.271Z",
  },
  ftm: {
    id: "fantom",
    symbol: "ftm",
    name: "Fantom",
    image:
      "https://assets.coingecko.com/coins/images/4001/large/Fantom_round.png?1669652346",
    current_price: 0.299963,
    market_cap: 839201252,
    market_cap_rank: 49,
    fully_diluted_valuation: 952298834,
    total_volume: 70390295,
    high_24h: 0.314764,
    low_24h: 0.296744,
    price_change_24h: -0.01103816169926708,
    price_change_percentage_24h: -3.54924,
    market_cap_change_24h: -31235931.983739734,
    market_cap_change_percentage_24h: -3.58853,
    circulating_supply: 2797928422.38279,
    total_supply: 3175000000,
    max_supply: 3175000000,
    ath: 3.46,
    ath_change_percentage: -91.32572,
    ath_date: "2021-10-28T05:19:39.845Z",
    atl: 0.00190227,
    atl_change_percentage: 15670.96606,
    atl_date: "2020-03-13T02:25:38.280Z",
    roi: {
      times: 8.998754981443417,
      currency: "usd",
      percentage: 899.8754981443417,
    },
    last_updated: "2023-06-28T12:02:03.520Z",
  },
  ape: {
    id: "apecoin",
    symbol: "ape",
    name: "ApeCoin",
    image:
      "https://assets.coingecko.com/coins/images/24383/large/apecoin.jpg?1647476455",
    current_price: 2.23,
    market_cap: 819542502,
    market_cap_rank: 50,
    fully_diluted_valuation: 2223430273,
    total_volume: 72299464,
    high_24h: 2.37,
    low_24h: 2.2,
    price_change_24h: -0.13743924651553563,
    price_change_percentage_24h: -5.80381,
    market_cap_change_24h: -46229831.195847034,
    market_cap_change_percentage_24h: -5.33972,
    circulating_supply: 368593750,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 26.7,
    ath_change_percentage: -91.66881,
    ath_date: "2022-04-28T21:44:21.164Z",
    atl: 1.96,
    atl_change_percentage: 13.38602,
    atl_date: "2023-06-19T04:02:16.512Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.191Z",
  },
  op: {
    id: "optimism",
    symbol: "op",
    name: "Optimism",
    image:
      "https://assets.coingecko.com/coins/images/25244/large/Optimism.png?1660904599",
    current_price: 1.25,
    market_cap: 808670073,
    market_cap_rank: 51,
    fully_diluted_valuation: 5388209173,
    total_volume: 86839694,
    high_24h: 1.35,
    low_24h: 1.23,
    price_change_24h: -0.09070328275832894,
    price_change_percentage_24h: -6.76097,
    market_cap_change_24h: -56479005.25543463,
    market_cap_change_percentage_24h: -6.52824,
    circulating_supply: 644594782,
    total_supply: 4294967296,
    max_supply: 4294967296,
    ath: 3.22,
    ath_change_percentage: -61.05296,
    ath_date: "2023-02-24T20:20:00.509Z",
    atl: 0.402159,
    atl_change_percentage: 212.14918,
    atl_date: "2022-06-18T20:54:52.178Z",
    roi: null,
    last_updated: "2023-06-28T12:02:01.196Z",
  },
  xrd: {
    id: "radix",
    symbol: "xrd",
    name: "Radix",
    image:
      "https://assets.coingecko.com/coins/images/4374/large/Radix.png?1629701658",
    current_price: 0.076765,
    market_cap: 783059341,
    market_cap_rank: 52,
    fully_diluted_valuation: 1842342226,
    total_volume: 5214535,
    high_24h: 0.086257,
    low_24h: 0.072343,
    price_change_24h: -0.008957622587095995,
    price_change_percentage_24h: -10.44956,
    market_cap_change_24h: -91287948.68192899,
    market_cap_change_percentage_24h: -10.4407,
    circulating_supply: 10200832346.7759,
    total_supply: 12600814378.4388,
    max_supply: 24000000000,
    ath: 0.651264,
    ath_change_percentage: -88.20955,
    ath_date: "2021-11-14T16:09:27.130Z",
    atl: 0.03145312,
    atl_change_percentage: 144.13129,
    atl_date: "2022-11-29T23:20:09.754Z",
    roi: null,
    last_updated: "2023-06-28T12:01:52.887Z",
  },
  eos: {
    id: "eos",
    symbol: "eos",
    name: "EOS",
    image:
      "https://assets.coingecko.com/coins/images/738/large/eos-eos-logo.png?1547034481",
    current_price: 0.699876,
    market_cap: 777957031,
    market_cap_rank: 53,
    fully_diluted_valuation: null,
    total_volume: 82844218,
    high_24h: 0.732106,
    low_24h: 0.691565,
    price_change_24h: -0.024502240841781653,
    price_change_percentage_24h: -3.38252,
    market_cap_change_24h: -26469426.97430086,
    market_cap_change_percentage_24h: -3.29047,
    circulating_supply: 1110827121.3307,
    total_supply: null,
    max_supply: null,
    ath: 22.71,
    ath_change_percentage: -96.91633,
    ath_date: "2018-04-29T07:50:33.540Z",
    atl: 0.5024,
    atl_change_percentage: 39.39882,
    atl_date: "2017-10-23T00:00:00.000Z",
    roi: {
      times: -0.29305435909686517,
      currency: "usd",
      percentage: -29.305435909686516,
    },
    last_updated: "2023-06-28T12:01:54.852Z",
  },
  sand: {
    id: "the-sandbox",
    symbol: "sand",
    name: "The Sandbox",
    image:
      "https://assets.coingecko.com/coins/images/12129/large/sandbox_logo.jpg?1597397942",
    current_price: 0.409862,
    market_cap: 767758712,
    market_cap_rank: 54,
    fully_diluted_valuation: 1231875063,
    total_volume: 42511559,
    high_24h: 0.431719,
    low_24h: 0.407809,
    price_change_24h: -0.01906019173079959,
    price_change_percentage_24h: -4.44374,
    market_cap_change_24h: -34269058.578065634,
    market_cap_change_percentage_24h: -4.2728,
    circulating_supply: 1869731926.2233226,
    total_supply: 3000000000,
    max_supply: 3000000000,
    ath: 8.4,
    ath_change_percentage: -94.9966,
    ath_date: "2021-11-25T06:04:40.957Z",
    atl: 0.02897764,
    atl_change_percentage: 1349.82547,
    atl_date: "2020-11-04T15:59:14.441Z",
    roi: null,
    last_updated: "2023-06-28T12:02:02.770Z",
  },
  imx: {
    id: "immutable-x",
    symbol: "imx",
    name: "ImmutableX",
    image:
      "https://assets.coingecko.com/coins/images/17233/large/immutableX-symbol-BLK-RGB.png?1665110648",
    current_price: 0.722726,
    market_cap: 753887359,
    market_cap_rank: 55,
    fully_diluted_valuation: 1449246168,
    total_volume: 18760704,
    high_24h: 0.760916,
    low_24h: 0.722492,
    price_change_24h: -0.029523852067676493,
    price_change_percentage_24h: -3.92474,
    market_cap_change_24h: -27005051.36710167,
    market_cap_change_percentage_24h: -3.45823,
    circulating_supply: 1040385513.4100242,
    total_supply: 2000000000,
    max_supply: 2000000000,
    ath: 9.52,
    ath_change_percentage: -92.39221,
    ath_date: "2021-11-26T01:03:01.536Z",
    atl: 0.378055,
    atl_change_percentage: 91.57467,
    atl_date: "2022-12-31T07:36:37.649Z",
    roi: null,
    last_updated: "2023-06-28T12:02:03.523Z",
  },
  usdd: {
    id: "usdd",
    symbol: "usdd",
    name: "USDD",
    image:
      "https://assets.coingecko.com/coins/images/25380/large/UUSD.jpg?1651823371",
    current_price: 0.998677,
    market_cap: 742245483,
    market_cap_rank: 56,
    fully_diluted_valuation: null,
    total_volume: 15041464,
    high_24h: 1.007,
    low_24h: 0.996055,
    price_change_24h: 0.00098063,
    price_change_percentage_24h: 0.09829,
    market_cap_change_24h: 4596224,
    market_cap_change_percentage_24h: 0.62309,
    circulating_supply: 742950851.857105,
    total_supply: null,
    max_supply: null,
    ath: 1.044,
    ath_change_percentage: -4.30044,
    ath_date: "2022-11-09T09:56:06.131Z",
    atl: 0.928067,
    atl_change_percentage: 7.64512,
    atl_date: "2022-06-19T16:15:11.558Z",
    roi: null,
    last_updated: "2023-06-28T12:02:00.443Z",
  },
  rndr: {
    id: "render-token",
    symbol: "rndr",
    name: "Render",
    image:
      "https://assets.coingecko.com/coins/images/11636/large/rndr.png?1638840934",
    current_price: 1.98,
    market_cap: 731227765,
    market_cap_rank: 59,
    fully_diluted_valuation: 1052722357,
    total_volume: 34079679,
    high_24h: 2.11,
    low_24h: 1.97,
    price_change_24h: -0.1045095958999791,
    price_change_percentage_24h: -5.01355,
    market_cap_change_24h: -37450279.42712128,
    market_cap_change_percentage_24h: -4.87204,
    circulating_supply: 368852897.108796,
    total_supply: 531024271.388796,
    max_supply: 531024271.388796,
    ath: 8.78,
    ath_change_percentage: -77.43959,
    ath_date: "2021-11-21T10:03:19.097Z",
    atl: 0.03665669,
    atl_change_percentage: 5305.51397,
    atl_date: "2020-06-16T13:22:25.900Z",
    roi: null,
    last_updated: "2023-06-28T12:02:01.357Z",
  },
  xtz: {
    id: "tezos",
    symbol: "xtz",
    name: "Tezos",
    image:
      "https://assets.coingecko.com/coins/images/976/large/Tezos-logo.png?1547034862",
    current_price: 0.781816,
    market_cap: 731058202,
    market_cap_rank: 57,
    fully_diluted_valuation: null,
    total_volume: 13446147,
    high_24h: 0.809953,
    low_24h: 0.777822,
    price_change_24h: -0.023027524408162803,
    price_change_percentage_24h: -2.86112,
    market_cap_change_24h: -21224481.96861863,
    market_cap_change_percentage_24h: -2.82134,
    circulating_supply: 935034367.902481,
    total_supply: null,
    max_supply: null,
    ath: 9.12,
    ath_change_percentage: -91.4232,
    ath_date: "2021-10-04T00:41:18.025Z",
    atl: 0.350476,
    atl_change_percentage: 123.08706,
    atl_date: "2018-12-07T00:00:00.000Z",
    roi: {
      times: 0.6634374671165649,
      currency: "usd",
      percentage: 66.3437467116565,
    },
    last_updated: "2023-06-28T12:01:55.924Z",
  },
  rpl: {
    id: "rocket-pool",
    symbol: "rpl",
    name: "Rocket Pool",
    image:
      "https://assets.coingecko.com/coins/images/2090/large/rocket_pool_%28RPL%29.png?1637662441",
    current_price: 37.39,
    market_cap: 728418250,
    market_cap_rank: 60,
    fully_diluted_valuation: 728418250,
    total_volume: 4795482,
    high_24h: 38.67,
    low_24h: 37.04,
    price_change_24h: -0.6023494176723858,
    price_change_percentage_24h: -1.5855,
    market_cap_change_24h: -10825015.685998201,
    market_cap_change_percentage_24h: -1.46434,
    circulating_supply: 19474470.0383936,
    total_supply: 19474470.0383936,
    max_supply: null,
    ath: 61.9,
    ath_change_percentage: -39.57198,
    ath_date: "2023-04-16T19:20:19.534Z",
    atl: 0.00884718,
    atl_change_percentage: 422675.38152,
    atl_date: "2018-08-28T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:58.186Z",
  },
  bsv: {
    id: "bitcoin-cash-sv",
    symbol: "bsv",
    name: "Bitcoin SV",
    image:
      "https://assets.coingecko.com/coins/images/6799/large/BSV.png?1558947902",
    current_price: 37.75,
    market_cap: 727198531,
    market_cap_rank: 58,
    fully_diluted_valuation: 792645510,
    total_volume: 88002626,
    high_24h: 39.49,
    low_24h: 36.51,
    price_change_24h: -1.5876491854639667,
    price_change_percentage_24h: -4.03646,
    market_cap_change_24h: -31829690.310513377,
    market_cap_change_percentage_24h: -4.19348,
    circulating_supply: 19266076.644239,
    total_supply: 21000000,
    max_supply: 21000000,
    ath: 489.75,
    ath_change_percentage: -92.29313,
    ath_date: "2021-04-16T17:09:04.630Z",
    atl: 21.43,
    atl_change_percentage: 76.08928,
    atl_date: "2023-06-10T04:32:12.266Z",
    roi: null,
    last_updated: "2023-06-28T12:01:48.164Z",
  },
  wbt: {
    id: "whitebit",
    symbol: "wbt",
    name: "WhiteBIT Token",
    image:
      "https://assets.coingecko.com/coins/images/27045/large/wbt_token.png?1667923752",
    current_price: 5.05,
    market_cap: 727177297,
    market_cap_rank: 61,
    fully_diluted_valuation: 2018275825,
    total_volume: 631168,
    high_24h: 5.07,
    low_24h: 4.97,
    price_change_24h: 0.055029,
    price_change_percentage_24h: 1.10134,
    market_cap_change_24h: 5112687,
    market_cap_change_percentage_24h: 0.70807,
    circulating_supply: 144118517.10815412,
    total_supply: 371187858,
    max_supply: 400000000,
    ath: 14.64,
    ath_change_percentage: -65.51881,
    ath_date: "2022-10-28T12:32:18.119Z",
    atl: 3.06,
    atl_change_percentage: 64.92837,
    atl_date: "2023-02-13T19:01:21.899Z",
    roi: null,
    last_updated: "2023-06-28T12:01:58.573Z",
  },
  theta: {
    id: "theta-token",
    symbol: "theta",
    name: "Theta Network",
    image:
      "https://assets.coingecko.com/coins/images/2538/large/theta-token-logo.png?1548387191",
    current_price: 0.718598,
    market_cap: 717954596,
    market_cap_rank: 62,
    fully_diluted_valuation: 717954596,
    total_volume: 7517014,
    high_24h: 0.750512,
    low_24h: 0.710525,
    price_change_24h: -0.025250362168021012,
    price_change_percentage_24h: -3.39456,
    market_cap_change_24h: -26709592.370976686,
    market_cap_change_percentage_24h: -3.5868,
    circulating_supply: 1000000000,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 15.72,
    ath_change_percentage: -95.43225,
    ath_date: "2021-04-16T13:15:11.190Z",
    atl: 0.04039979,
    atl_change_percentage: 1677.32823,
    atl_date: "2020-03-13T02:24:16.483Z",
    roi: {
      times: 3.790655050739098,
      currency: "usd",
      percentage: 379.0655050739098,
    },
    last_updated: "2023-06-28T12:01:56.569Z",
  },
  bgb: {
    id: "bitget-token",
    symbol: "bgb",
    name: "Bitget Token",
    image:
      "https://assets.coingecko.com/coins/images/11610/large/photo_2022-01-24_14-08-03.jpg?1643019457",
    current_price: 0.493366,
    market_cap: 685649079,
    market_cap_rank: 63,
    fully_diluted_valuation: 979497985,
    total_volume: 16432913,
    high_24h: 0.492637,
    low_24h: 0.480131,
    price_change_24h: 0.01075401,
    price_change_percentage_24h: 2.2283,
    market_cap_change_24h: 9127947,
    market_cap_change_percentage_24h: 1.34925,
    circulating_supply: 1400001000,
    total_supply: 2000000000,
    max_supply: 2000000000,
    ath: 0.515471,
    ath_change_percentage: -5.67675,
    ath_date: "2023-02-16T08:52:49.445Z",
    atl: 0.0142795,
    atl_change_percentage: 3304.94521,
    atl_date: "2020-06-25T04:17:05.895Z",
    roi: null,
    last_updated: "2023-06-28T12:01:22.936Z",
  },
  mana: {
    id: "decentraland",
    symbol: "mana",
    name: "Decentraland",
    image:
      "https://assets.coingecko.com/coins/images/878/large/decentraland-mana.png?1550108745",
    current_price: 0.369312,
    market_cap: 679311930,
    market_cap_rank: 64,
    fully_diluted_valuation: 810079528,
    total_volume: 39130133,
    high_24h: 0.39068,
    low_24h: 0.368541,
    price_change_24h: -0.019524205808420125,
    price_change_percentage_24h: -5.02119,
    market_cap_change_24h: -35488748.33789384,
    market_cap_change_percentage_24h: -4.96485,
    circulating_supply: 1839143973.91432,
    total_supply: 2193179327.32015,
    max_supply: 2193179327.32015,
    ath: 5.85,
    ath_change_percentage: -93.68699,
    ath_date: "2021-11-25T10:04:18.534Z",
    atl: 0.00923681,
    atl_change_percentage: 3898.81764,
    atl_date: "2017-10-31T00:00:00.000Z",
    roi: {
      times: 17.46561533088245,
      currency: "usd",
      percentage: 1746.561533088245,
    },
    last_updated: "2023-06-28T12:02:00.436Z",
  },
  axs: {
    id: "axie-infinity",
    symbol: "axs",
    name: "Axie Infinity",
    image:
      "https://assets.coingecko.com/coins/images/13029/large/axie_infinity_logo.png?1604471082",
    current_price: 5.71,
    market_cap: 661186932,
    market_cap_rank: 65,
    fully_diluted_valuation: 1543475485,
    total_volume: 27749593,
    high_24h: 5.94,
    low_24h: 5.67,
    price_change_24h: -0.18389199372962572,
    price_change_percentage_24h: -3.11782,
    market_cap_change_24h: -22981133.34302795,
    market_cap_change_percentage_24h: -3.35899,
    circulating_supply: 115661358.61362292,
    total_supply: 270000000,
    max_supply: 270000000,
    ath: 164.9,
    ath_change_percentage: -96.53257,
    ath_date: "2021-11-06T19:29:29.482Z",
    atl: 0.123718,
    atl_change_percentage: 4521.56413,
    atl_date: "2020-11-06T08:05:43.662Z",
    roi: null,
    last_updated: "2023-06-28T12:02:01.286Z",
  },
  snx: {
    id: "havven",
    symbol: "snx",
    name: "Synthetix Network",
    image:
      "https://assets.coingecko.com/coins/images/3406/large/SNX.png?1598631139",
    current_price: 2.05,
    market_cap: 654618998,
    market_cap_rank: 66,
    fully_diluted_valuation: 656102071,
    total_volume: 21989573,
    high_24h: 2.16,
    low_24h: 2.01,
    price_change_24h: -0.08568859084304714,
    price_change_percentage_24h: -4.01226,
    market_cap_change_24h: -27980647.654429793,
    market_cap_change_percentage_24h: -4.09913,
    circulating_supply: 319528338.13952357,
    total_supply: 320252246.088774,
    max_supply: 320252246.088774,
    ath: 28.53,
    ath_change_percentage: -92.81915,
    ath_date: "2021-02-14T01:12:38.505Z",
    atl: 0.0347864,
    atl_change_percentage: 5789.8355,
    atl_date: "2019-01-06T00:00:00.000Z",
    roi: {
      times: 3.099956032524866,
      currency: "usd",
      percentage: 309.9956032524866,
    },
    last_updated: "2023-06-28T12:02:00.141Z",
  },
  pepe: {
    id: "pepe",
    symbol: "pepe",
    name: "Pepe",
    image:
      "https://assets.coingecko.com/coins/images/29850/large/pepe-token.jpeg?1682922725",
    current_price: 0.00000153,
    market_cap: 646977607,
    market_cap_rank: 67,
    fully_diluted_valuation: 646977607,
    total_volume: 149044887,
    high_24h: 0.00000165,
    low_24h: 0.00000152,
    price_change_24h: -1.06711895895e-7,
    price_change_percentage_24h: -6.50683,
    market_cap_change_24h: -41073501.59900141,
    market_cap_change_percentage_24h: -5.96954,
    circulating_supply: 420690000000000,
    total_supply: 420690000000000,
    max_supply: 420690000000000,
    ath: 0.00000431,
    ath_change_percentage: -64.3013,
    ath_date: "2023-05-05T16:50:45.302Z",
    atl: 5.5142e-8,
    atl_change_percentage: 2688.3727,
    atl_date: "2023-04-18T02:14:41.591Z",
    roi: null,
    last_updated: "2023-06-28T12:02:01.668Z",
  },
  kcs: {
    id: "kucoin-shares",
    symbol: "kcs",
    name: "KuCoin",
    image:
      "https://assets.coingecko.com/coins/images/1047/large/sa9z79.png?1610678720",
    current_price: 6.54,
    market_cap: 632495413,
    market_cap_rank: 68,
    fully_diluted_valuation: 942881093,
    total_volume: 1136379,
    high_24h: 6.59,
    low_24h: 6.5,
    price_change_24h: -0.009769488119472316,
    price_change_percentage_24h: -0.14925,
    market_cap_change_24h: -1091391.7966436148,
    market_cap_change_percentage_24h: -0.17226,
    circulating_supply: 96794195.4569757,
    total_supply: 144294195.456892,
    max_supply: null,
    ath: 28.83,
    ath_change_percentage: -77.33451,
    ath_date: "2021-12-01T15:09:35.541Z",
    atl: 0.342863,
    atl_change_percentage: 1805.84398,
    atl_date: "2019-02-07T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:59.111Z",
  },
  inj: {
    id: "injective-protocol",
    symbol: "inj",
    name: "Injective",
    image:
      "https://assets.coingecko.com/coins/images/12882/large/Secondary_Symbol.png?1628233237",
    current_price: 7.65,
    market_cap: 616697938,
    market_cap_rank: 69,
    fully_diluted_valuation: 770818896,
    total_volume: 43218102,
    high_24h: 7.79,
    low_24h: 7.43,
    price_change_24h: -0.1067169876219829,
    price_change_percentage_24h: -1.37535,
    market_cap_change_24h: -3555346.767322898,
    market_cap_change_percentage_24h: -0.57321,
    circulating_supply: 80005555.33,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 24.89,
    ath_change_percentage: -68.99978,
    ath_date: "2021-04-30T00:33:02.969Z",
    atl: 0.657401,
    atl_change_percentage: 1073.7002,
    atl_date: "2020-11-03T16:19:30.576Z",
    roi: null,
    last_updated: "2023-06-28T12:01:57.739Z",
  },
  neo: {
    id: "neo",
    symbol: "neo",
    name: "NEO",
    image:
      "https://assets.coingecko.com/coins/images/480/large/NEO_512_512.png?1594357361",
    current_price: 8.71,
    market_cap: 614385733,
    market_cap_rank: 70,
    fully_diluted_valuation: 871098444,
    total_volume: 31758600,
    high_24h: 9.24,
    low_24h: 8.62,
    price_change_24h: -0.2830378287585127,
    price_change_percentage_24h: -3.14697,
    market_cap_change_24h: -17202065.846289515,
    market_cap_change_percentage_24h: -2.72362,
    circulating_supply: 70530000,
    total_supply: 100000000,
    max_supply: null,
    ath: 198.38,
    ath_change_percentage: -95.60976,
    ath_date: "2018-01-15T00:00:00.000Z",
    atl: 0.078349,
    atl_change_percentage: 11016.12103,
    atl_date: "2016-10-21T00:00:00.000Z",
    roi: {
      times: 240.97034356254045,
      currency: "usd",
      percentage: 24097.034356254044,
    },
    last_updated: "2023-06-28T12:01:56.816Z",
  },
  mkr: {
    id: "maker",
    symbol: "mkr",
    name: "Maker",
    image:
      "https://assets.coingecko.com/coins/images/1364/large/Mark_Maker.png?1585191826",
    current_price: 678.61,
    market_cap: 611713317,
    market_cap_rank: 71,
    fully_diluted_valuation: 682477944,
    total_volume: 18692838,
    high_24h: 692.68,
    low_24h: 671.9,
    price_change_24h: -12.953229393395759,
    price_change_percentage_24h: -1.87303,
    market_cap_change_24h: -10820343.056396842,
    market_cap_change_percentage_24h: -1.73811,
    circulating_supply: 901310.9472893132,
    total_supply: 977631.036950888,
    max_supply: 1005577,
    ath: 6292.31,
    ath_change_percentage: -89.21448,
    ath_date: "2021-05-03T21:54:29.333Z",
    atl: 168.36,
    atl_change_percentage: 303.10558,
    atl_date: "2020-03-16T20:52:36.527Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.024Z",
  },
  gala: {
    id: "gala",
    symbol: "gala",
    name: "GALA",
    image:
      "https://assets.coingecko.com/coins/images/12493/large/GALA-COINGECKO.png?1600233435",
    current_price: 0.02464587,
    market_cap: 601118796,
    market_cap_rank: 72,
    fully_diluted_valuation: 1236571775,
    total_volume: 42921830,
    high_24h: 0.02608943,
    low_24h: 0.02445711,
    price_change_24h: -0.001319677017978371,
    price_change_percentage_24h: -5.08242,
    market_cap_change_24h: -30603656.907019973,
    market_cap_change_percentage_24h: -4.84448,
    circulating_supply: 24305859457.3481,
    total_supply: 24305862898.033,
    max_supply: 50000000000,
    ath: 0.824837,
    ath_change_percentage: -96.97271,
    ath_date: "2021-11-26T01:03:48.731Z",
    atl: 0.00013475,
    atl_change_percentage: 18430.81834,
    atl_date: "2020-12-28T08:46:48.367Z",
    roi: null,
    last_updated: "2023-06-28T12:02:01.272Z",
  },
  gt: {
    id: "gatechain-token",
    symbol: "gt",
    name: "Gate",
    image:
      "https://assets.coingecko.com/coins/images/8183/large/gate.png?1687143308",
    current_price: 4.22,
    market_cap: 586341146,
    market_cap_rank: 73,
    fully_diluted_valuation: 1265312394,
    total_volume: 422986,
    high_24h: 4.26,
    low_24h: 4.2,
    price_change_24h: -0.015770801804838364,
    price_change_percentage_24h: -0.37253,
    market_cap_change_24h: -3190745.734821439,
    market_cap_change_percentage_24h: -0.54123,
    circulating_supply: 139018905.1789118,
    total_supply: 300000000,
    max_supply: null,
    ath: 12.94,
    ath_change_percentage: -67.42552,
    ath_date: "2021-05-12T11:39:16.531Z",
    atl: 0.25754,
    atl_change_percentage: 1536.92647,
    atl_date: "2020-03-13T02:18:02.481Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.812Z",
  },
  crv: {
    id: "curve-dao-token",
    symbol: "crv",
    name: "Curve DAO",
    image:
      "https://assets.coingecko.com/coins/images/12124/large/Curve.png?1597369484",
    current_price: 0.678834,
    market_cap: 585105588,
    market_cap_rank: 74,
    fully_diluted_valuation: 2249011796,
    total_volume: 32314162,
    high_24h: 0.699967,
    low_24h: 0.67523,
    price_change_24h: -0.007718584267913387,
    price_change_percentage_24h: -1.12425,
    market_cap_change_24h: -4459526.937600017,
    market_cap_change_percentage_24h: -0.75641,
    circulating_supply: 859320298,
    total_supply: 1971667950.15546,
    max_supply: 3303030299,
    ath: 15.37,
    ath_change_percentage: -95.5186,
    ath_date: "2020-08-14T00:00:00.000Z",
    atl: 0.331577,
    atl_change_percentage: 107.76014,
    atl_date: "2020-11-05T13:09:50.181Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.318Z",
  },
  bit: {
    id: "bitdao",
    symbol: "bit",
    name: "BitDAO",
    image:
      "https://assets.coingecko.com/coins/images/17627/large/rI_YptK8.png?1653983088",
    current_price: 0.396344,
    market_cap: 575524673,
    market_cap_rank: 75,
    fully_diluted_valuation: 3804178039,
    total_volume: 31066772,
    high_24h: 0.398973,
    low_24h: 0.36039,
    price_change_24h: 0.00094713,
    price_change_percentage_24h: 0.23954,
    market_cap_change_24h: -3938405.958847165,
    market_cap_change_percentage_24h: -0.67966,
    circulating_supply: 1453909315.2107098,
    total_supply: 9610239403,
    max_supply: 9610239403,
    ath: 3.1,
    ath_change_percentage: -87.23375,
    ath_date: "2021-11-12T04:54:56.433Z",
    atl: 0.270167,
    atl_change_percentage: 46.58832,
    atl_date: "2022-11-22T08:40:21.230Z",
    roi: null,
    last_updated: "2023-06-28T12:02:00.854Z",
  },
  gusd: {
    id: "gemini-dollar",
    symbol: "gusd",
    name: "Gemini Dollar",
    image:
      "https://assets.coingecko.com/coins/images/5992/large/gemini-dollar-gusd.png?1536745278",
    current_price: 1,
    market_cap: 564586502,
    market_cap_rank: 76,
    fully_diluted_valuation: 564586502,
    total_volume: 2565474,
    high_24h: 1.004,
    low_24h: 0.986273,
    price_change_24h: -0.000170120473829805,
    price_change_percentage_24h: -0.01701,
    market_cap_change_24h: -1433781.5375379324,
    market_cap_change_percentage_24h: -0.25331,
    circulating_supply: 564468592.16,
    total_supply: 564468592.16,
    max_supply: null,
    ath: 3.3,
    ath_change_percentage: -69.72608,
    ath_date: "2018-10-11T17:36:21.529Z",
    atl: 0.78261,
    atl_change_percentage: 27.80429,
    atl_date: "2018-09-29T00:00:00.000Z",
    roi: {
      times: 0.000219491811298,
      currency: "usd",
      percentage: 0.0219491811298,
    },
    last_updated: "2023-06-28T12:01:55.389Z",
  },
  kava: {
    id: "kava",
    symbol: "kava",
    name: "Kava",
    image:
      "https://assets.coingecko.com/coins/images/9761/large/kava.png?1663638871",
    current_price: 0.934469,
    market_cap: 557574064,
    market_cap_rank: 77,
    fully_diluted_valuation: 557589049,
    total_volume: 22140353,
    high_24h: 1.011,
    low_24h: 0.933249,
    price_change_24h: -0.05501287909371399,
    price_change_percentage_24h: -5.55977,
    market_cap_change_24h: -29319854.187140465,
    market_cap_change_percentage_24h: -4.99577,
    circulating_supply: 596689004,
    total_supply: 596705040,
    max_supply: null,
    ath: 9.12,
    ath_change_percentage: -89.75389,
    ath_date: "2021-08-30T11:10:02.948Z",
    atl: 0.287137,
    atl_change_percentage: 225.44383,
    atl_date: "2020-03-13T02:24:16.835Z",
    roi: null,
    last_updated: "2023-06-28T12:01:53.131Z",
  },
  flow: {
    id: "flow",
    symbol: "flow",
    name: "Flow",
    image:
      "https://assets.coingecko.com/coins/images/13446/large/5f6294c0c7a8cda55cb1c936_Flow_Wordmark.png?1631696776",
    current_price: 0.522074,
    market_cap: 541798313,
    market_cap_rank: 78,
    fully_diluted_valuation: 752169939,
    total_volume: 19869187,
    high_24h: 0.567405,
    low_24h: 0.52117,
    price_change_24h: -0.02296105242107216,
    price_change_percentage_24h: -4.21276,
    market_cap_change_24h: -23411988.817483306,
    market_cap_change_percentage_24h: -4.14217,
    circulating_supply: 1036200000,
    total_supply: 1438539899.44975,
    max_supply: 1438539899.44975,
    ath: 42.4,
    ath_change_percentage: -98.76685,
    ath_date: "2021-04-05T13:49:10.098Z",
    atl: 0.441327,
    atl_change_percentage: 18.46614,
    atl_date: "2023-06-15T11:15:32.710Z",
    roi: null,
    last_updated: "2023-06-28T12:01:57.505Z",
  },
  lunc: {
    id: "terra-luna",
    symbol: "lunc",
    name: "Terra Luna Classic",
    image:
      "https://assets.coingecko.com/coins/images/8284/large/01_LunaClassic_color.png?1653547790",
    current_price: 0.00008805,
    market_cap: 512929132,
    market_cap_rank: 79,
    fully_diluted_valuation: 602743758,
    total_volume: 31291792,
    high_24h: 0.00009212,
    low_24h: 0.00008774,
    price_change_24h: -0.000003804462621344,
    price_change_percentage_24h: -4.14206,
    market_cap_change_24h: -21938893.022873223,
    market_cap_change_percentage_24h: -4.10174,
    circulating_supply: 5825755300113.08,
    total_supply: 6845853402476.36,
    max_supply: null,
    ath: 119.18,
    ath_change_percentage: -99.99993,
    ath_date: "2022-04-05T12:24:58.854Z",
    atl: 9.99967e-7,
    atl_change_percentage: 8704.75914,
    atl_date: "2022-05-13T02:34:40.340Z",
    roi: null,
    last_updated: "2023-06-28T12:01:58.181Z",
  },
  klay: {
    id: "klay-token",
    symbol: "klay",
    name: "Klaytn",
    image:
      "https://assets.coingecko.com/coins/images/9672/large/klaytn.png?1660288824",
    current_price: 0.163015,
    market_cap: 511994481,
    market_cap_rank: 80,
    fully_diluted_valuation: null,
    total_volume: 9967373,
    high_24h: 0.169724,
    low_24h: 0.16255,
    price_change_24h: -0.006378828412722359,
    price_change_percentage_24h: -3.76568,
    market_cap_change_24h: -19143519.63011551,
    market_cap_change_percentage_24h: -3.60425,
    circulating_supply: 3134024123.90319,
    total_supply: null,
    max_supply: null,
    ath: 4.34,
    ath_change_percentage: -96.24292,
    ath_date: "2021-03-30T03:44:28.828Z",
    atl: 0.06044,
    atl_change_percentage: 169.71791,
    atl_date: "2020-04-29T08:19:34.574Z",
    roi: null,
    last_updated: "2023-06-28T12:01:58.514Z",
  },
  kas: {
    id: "kaspa",
    symbol: "kas",
    name: "Kaspa",
    image:
      "https://assets.coingecko.com/coins/images/25751/large/kaspa-icon-exchanges.png?1653891958",
    current_price: 0.02635563,
    market_cap: 509119790,
    market_cap_rank: 81,
    fully_diluted_valuation: 755284007,
    total_volume: 12009697,
    high_24h: 0.02757274,
    low_24h: 0.02496666,
    price_change_24h: 0.00133995,
    price_change_percentage_24h: 5.35645,
    market_cap_change_24h: 27611925,
    market_cap_change_percentage_24h: 5.73447,
    circulating_supply: 19348732195.4711,
    total_supply: 19348870284.2831,
    max_supply: 28704026601,
    ath: 0.04279457,
    ath_change_percentage: -38.49183,
    ath_date: "2023-04-02T12:55:39.616Z",
    atl: 0.00017105,
    atl_change_percentage: 15288.6103,
    atl_date: "2022-05-26T14:42:59.316Z",
    roi: null,
    last_updated: "2023-06-28T12:01:47.683Z",
  },
  miota: {
    id: "iota",
    symbol: "miota",
    name: "IOTA",
    image:
      "https://assets.coingecko.com/coins/images/692/large/IOTA_Swirl.png?1604238557",
    current_price: 0.180418,
    market_cap: 501536741,
    market_cap_rank: 82,
    fully_diluted_valuation: 501536741,
    total_volume: 15051406,
    high_24h: 0.198266,
    low_24h: 0.176292,
    price_change_24h: -0.01041243677889814,
    price_change_percentage_24h: -5.45637,
    market_cap_change_24h: -29443681.722096145,
    market_cap_change_percentage_24h: -5.54515,
    circulating_supply: 2779530283.277761,
    total_supply: 2779530283,
    max_supply: null,
    ath: 5.25,
    ath_change_percentage: -96.56226,
    ath_date: "2017-12-19T00:00:00.000Z",
    atl: 0.081637,
    atl_change_percentage: 121.02547,
    atl_date: "2020-03-13T02:22:41.168Z",
    roi: {
      times: 3.4371893970922835,
      currency: "btc",
      percentage: 343.7189397092284,
    },
    last_updated: "2023-06-28T12:02:00.801Z",
  },
  ceth: {
    id: "compound-ether",
    symbol: "ceth",
    name: "cETH",
    image:
      "https://assets.coingecko.com/coins/images/10643/large/ceth.png?1687143191",
    current_price: 37.42,
    market_cap: 484531695,
    market_cap_rank: 83,
    fully_diluted_valuation: 484531695,
    total_volume: 228.24,
    high_24h: 38.29,
    low_24h: 37.18,
    price_change_24h: -0.3742599351170739,
    price_change_percentage_24h: -0.99057,
    market_cap_change_24h: -3482710.5297056437,
    market_cap_change_percentage_24h: -0.71365,
    circulating_supply: 12952655.6455841,
    total_supply: 12952655.6455841,
    max_supply: null,
    ath: 97.75,
    ath_change_percentage: -61.73126,
    ath_date: "2021-11-21T12:39:57.508Z",
    atl: 1.89,
    atl_change_percentage: 1883.14895,
    atl_date: "2020-03-13T02:22:33.711Z",
    roi: null,
    last_updated: "2023-06-28T12:00:08.534Z",
  },
  paxg: {
    id: "pax-gold",
    symbol: "paxg",
    name: "PAX Gold",
    image:
      "https://assets.coingecko.com/coins/images/9519/large/paxgold.png?1687143101",
    current_price: 1897.51,
    market_cap: 482158057,
    market_cap_rank: 84,
    fully_diluted_valuation: 482158057,
    total_volume: 5054044,
    high_24h: 1914.2,
    low_24h: 1895.31,
    price_change_24h: -15.340778172758519,
    price_change_percentage_24h: -0.80199,
    market_cap_change_24h: -5007612.354126811,
    market_cap_change_percentage_24h: -1.02791,
    circulating_supply: 254217.924,
    total_supply: 254217.924,
    max_supply: null,
    ath: 2241.37,
    ath_change_percentage: -15.3461,
    ath_date: "2021-05-17T19:02:29.657Z",
    atl: 1399.64,
    atl_change_percentage: 35.56375,
    atl_date: "2019-11-18T03:09:35.959Z",
    roi: null,
    last_updated: "2023-06-28T12:02:00.320Z",
  },
  gmx: {
    id: "gmx",
    symbol: "gmx",
    name: "GMX",
    image:
      "https://assets.coingecko.com/coins/images/18323/large/arbit.png?1631532468",
    current_price: 53.32,
    market_cap: 471699022,
    market_cap_rank: 85,
    fully_diluted_valuation: 707547679,
    total_volume: 10837100,
    high_24h: 55.1,
    low_24h: 52.93,
    price_change_24h: -1.1623790227911144,
    price_change_percentage_24h: -2.13348,
    market_cap_change_24h: -9984310.435329854,
    market_cap_change_percentage_24h: -2.0728,
    circulating_supply: 8833343.98534118,
    total_supply: 8833352.80984904,
    max_supply: 13250000,
    ath: 91.07,
    ath_change_percentage: -41.37515,
    ath_date: "2023-04-18T10:00:25.680Z",
    atl: 11.53,
    atl_change_percentage: 362.90229,
    atl_date: "2022-06-15T09:30:22.146Z",
    roi: null,
    last_updated: "2023-06-28T12:01:59.266Z",
  },
  xaut: {
    id: "tether-gold",
    symbol: "xaut",
    name: "Tether Gold",
    image:
      "https://assets.coingecko.com/coins/images/10481/large/Tether_Gold.png?1668148656",
    current_price: 1909.18,
    market_cap: 470055571,
    market_cap_rank: 86,
    fully_diluted_valuation: 470055571,
    total_volume: 6711092,
    high_24h: 1930.99,
    low_24h: 1905.02,
    price_change_24h: -15.555455539212971,
    price_change_percentage_24h: -0.80819,
    market_cap_change_24h: -4202871.065644801,
    market_cap_change_percentage_24h: -0.8862,
    circulating_supply: 246524.33,
    total_supply: 246524.33,
    max_supply: null,
    ath: 2169.74,
    ath_change_percentage: -12.1094,
    ath_date: "2023-03-15T20:36:21.941Z",
    atl: 1447.84,
    atl_change_percentage: 31.7132,
    atl_date: "2020-03-19T13:45:41.821Z",
    roi: null,
    last_updated: "2023-06-28T12:01:55.405Z",
  },
  tkx: {
    id: "tokenize-xchange",
    symbol: "tkx",
    name: "Tokenize Xchange",
    image:
      "https://assets.coingecko.com/coins/images/4984/large/TKX_-_Logo_-_RGB-15.png?1672912391",
    current_price: 5.76,
    market_cap: 461049320,
    market_cap_rank: 87,
    fully_diluted_valuation: 576340468,
    total_volume: 7871074,
    high_24h: 5.91,
    low_24h: 5.75,
    price_change_24h: -0.06698622116960085,
    price_change_percentage_24h: -1.14891,
    market_cap_change_24h: -5665660.355572939,
    market_cap_change_percentage_24h: -1.21394,
    circulating_supply: 79995999.94518414,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 22.3,
    ath_change_percentage: -74.15077,
    ath_date: "2022-10-31T10:23:59.455Z",
    atl: 0.111255,
    atl_change_percentage: 5080.3704,
    atl_date: "2019-04-28T00:00:00.000Z",
    roi: {
      times: 18.839603020160837,
      currency: "usd",
      percentage: 1883.9603020160837,
    },
    last_updated: "2023-06-28T11:59:26.743Z",
  },
  cfx: {
    id: "conflux-token",
    symbol: "cfx",
    name: "Conflux",
    image:
      "https://assets.coingecko.com/coins/images/13079/large/3vuYMbjN.png?1631512305",
    current_price: 0.214567,
    market_cap: 449545469,
    market_cap_rank: 88,
    fully_diluted_valuation: 1132678366,
    total_volume: 79298854,
    high_24h: 0.232953,
    low_24h: 0.213768,
    price_change_24h: -0.015714215905674594,
    price_change_percentage_24h: -6.82392,
    market_cap_change_24h: -33055011.70248866,
    market_cap_change_percentage_24h: -6.84935,
    circulating_supply: 2097547687.26523,
    total_supply: 5284997962.62513,
    max_supply: null,
    ath: 1.7,
    ath_change_percentage: -87.3849,
    ath_date: "2021-03-27T03:43:35.178Z",
    atl: 0.02199898,
    atl_change_percentage: 875.33385,
    atl_date: "2022-12-30T08:16:30.345Z",
    roi: null,
    last_updated: "2023-06-28T12:01:54.258Z",
  },
  xec: {
    id: "ecash",
    symbol: "xec",
    name: "eCash",
    image:
      "https://assets.coingecko.com/coins/images/16646/large/Logo_final-22.png?1628239446",
    current_price: 0.00002308,
    market_cap: 444892619,
    market_cap_rank: 89,
    fully_diluted_valuation: 480910411,
    total_volume: 3598323,
    high_24h: 0.00002427,
    low_24h: 0.00002274,
    price_change_24h: -0.000001088102367274,
    price_change_percentage_24h: -4.50289,
    market_cap_change_24h: -22797644.211950064,
    market_cap_change_percentage_24h: -4.87452,
    circulating_supply: 19427204673293,
    total_supply: 21000000000000,
    max_supply: 21000000000000,
    ath: 0.00038001,
    ath_change_percentage: -93.96306,
    ath_date: "2021-09-04T17:09:31.137Z",
    atl: 0.00001847,
    atl_change_percentage: 24.20827,
    atl_date: "2021-07-20T20:49:46.212Z",
    roi: null,
    last_updated: "2023-06-28T12:01:51.255Z",
  },
  btt: {
    id: "bittorrent",
    symbol: "btt",
    name: "BitTorrent",
    image:
      "https://assets.coingecko.com/coins/images/22457/large/btt_logo.png?1643857231",
    current_price: 4.67039e-7,
    market_cap: 444773853,
    market_cap_rank: 90,
    fully_diluted_valuation: 462808561,
    total_volume: 12364065,
    high_24h: 4.79257e-7,
    low_24h: 4.65836e-7,
    price_change_24h: -9.753519731e-9,
    price_change_percentage_24h: -2.04565,
    market_cap_change_24h: -8611866.73506695,
    market_cap_change_percentage_24h: -1.89946,
    circulating_supply: 951421714286000,
    total_supply: 990000000000000,
    max_supply: 990000000000000,
    ath: 0.00000343,
    ath_change_percentage: -86.38304,
    ath_date: "2022-01-21T04:00:31.909Z",
    atl: 4.5532e-7,
    atl_change_percentage: 2.61884,
    atl_date: "2023-06-20T15:46:26.171Z",
    roi: null,
    last_updated: "2023-06-28T12:02:03.264Z",
  },
  ht: {
    id: "huobi-token",
    symbol: "ht",
    name: "Huobi",
    image:
      "https://assets.coingecko.com/coins/images/2822/large/huobi-token-logo.png?1547036992",
    current_price: 2.7,
    market_cap: 438341051,
    market_cap_rank: 91,
    fully_diluted_valuation: 550787965,
    total_volume: 10420673,
    high_24h: 2.72,
    low_24h: 2.67,
    price_change_24h: -0.006764061221861351,
    price_change_percentage_24h: -0.2499,
    market_cap_change_24h: -1100528.6833785176,
    market_cap_change_percentage_24h: -0.25044,
    circulating_supply: 162336522.3,
    total_supply: 203980445.3,
    max_supply: 203980445.3,
    ath: 39.66,
    ath_change_percentage: -93.19092,
    ath_date: "2021-05-12T14:42:21.586Z",
    atl: 0.3138,
    atl_change_percentage: 760.59018,
    atl_date: "2023-03-10T05:05:00+08:00",
    roi: {
      times: 1.727266778582105,
      currency: "usd",
      percentage: 172.7266778582105,
    },
    last_updated: "2023-06-28T12:02:02.293Z",
  },
  mina: {
    id: "mina-protocol",
    symbol: "mina",
    name: "Mina Protocol",
    image:
      "https://assets.coingecko.com/coins/images/15628/large/JM4_vQ34_400x400.png?1621394004",
    current_price: 0.47252,
    market_cap: 437486317,
    market_cap_rank: 92,
    fully_diluted_valuation: 495419355,
    total_volume: 8701270,
    high_24h: 0.503776,
    low_24h: 0.470188,
    price_change_24h: -0.02577553635670271,
    price_change_percentage_24h: -5.17274,
    market_cap_change_24h: -21980482.911200225,
    market_cap_change_percentage_24h: -4.78391,
    circulating_supply: 924826979.840039,
    total_supply: 1047294892.84004,
    max_supply: null,
    ath: 9.09,
    ath_change_percentage: -94.78521,
    ath_date: "2021-06-01T01:42:37.064Z",
    atl: 0.380741,
    atl_change_percentage: 24.50525,
    atl_date: "2023-06-10T06:00:03.859Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.187Z",
  },
  xdc: {
    id: "xdce-crowd-sale",
    symbol: "xdc",
    name: "XDC Network",
    image:
      "https://assets.coingecko.com/coins/images/2912/large/xdc-icon.png?1633700890",
    current_price: 0.03128726,
    market_cap: 433200895,
    market_cap_rank: 93,
    fully_diluted_valuation: 1184087529,
    total_volume: 2270440,
    high_24h: 0.03253672,
    low_24h: 0.03115636,
    price_change_24h: -0.001166433975198805,
    price_change_percentage_24h: -3.59415,
    market_cap_change_24h: -16192861.20086193,
    market_cap_change_percentage_24h: -3.60327,
    circulating_supply: 13846058650.5,
    total_supply: 37846056097.5,
    max_supply: null,
    ath: 0.192754,
    ath_change_percentage: -83.78312,
    ath_date: "2021-08-21T04:39:48.324Z",
    atl: 0.00039532,
    atl_change_percentage: 7807.22819,
    atl_date: "2019-07-22T00:00:00.000Z",
    roi: {
      times: 1.2341486957955679,
      currency: "eth",
      percentage: 123.41486957955678,
    },
    last_updated: "2023-06-28T12:01:50.706Z",
  },
  frxeth: {
    id: "frax-ether",
    symbol: "frxeth",
    name: "Frax Ether",
    image:
      "https://assets.coingecko.com/coins/images/28284/large/frxETH_icon.png?1679886981",
    current_price: 1860.42,
    market_cap: 432591072,
    market_cap_rank: 94,
    fully_diluted_valuation: 432591081,
    total_volume: 1167321,
    high_24h: 1906.94,
    low_24h: 1848.86,
    price_change_24h: -16.85534288320241,
    price_change_percentage_24h: -0.89786,
    market_cap_change_24h: -3051945.7845508456,
    market_cap_change_percentage_24h: -0.70056,
    circulating_supply: 232378.440786304,
    total_supply: 232378.445786304,
    max_supply: null,
    ath: 2134.61,
    ath_change_percentage: -12.8037,
    ath_date: "2023-04-16T19:24:52.171Z",
    atl: 1137.25,
    atl_change_percentage: 63.66728,
    atl_date: "2022-11-23T02:25:33.267Z",
    roi: null,
    last_updated: "2023-06-28T12:02:00.555Z",
  },
  cspr: {
    id: "casper-network",
    symbol: "cspr",
    name: "Casper Network",
    image:
      "https://assets.coingecko.com/coins/images/15279/large/casper.PNG?1620341020",
    current_price: 0.03881786,
    market_cap: 431084051,
    market_cap_rank: 95,
    fully_diluted_valuation: 457703411,
    total_volume: 2790257,
    high_24h: 0.04085969,
    low_24h: 0.03850092,
    price_change_24h: -0.001804548279922214,
    price_change_percentage_24h: -4.44225,
    market_cap_change_24h: -20258838.782622218,
    market_cap_change_percentage_24h: -4.48857,
    circulating_supply: 11133317122,
    total_supply: 11820797371,
    max_supply: null,
    ath: 1.33,
    ath_change_percentage: -97.08183,
    ath_date: "2021-05-12T00:00:00.000Z",
    atl: 0.02241297,
    atl_change_percentage: 72.57321,
    atl_date: "2022-06-18T20:14:44.694Z",
    roi: null,
    last_updated: "2023-06-28T12:01:53.902Z",
  },
  fxs: {
    id: "frax-share",
    symbol: "fxs",
    name: "Frax Share",
    image:
      "https://assets.coingecko.com/coins/images/13423/large/Frax_Shares_icon.png?1679886947",
    current_price: 5.57,
    market_cap: 405236329,
    market_cap_rank: 96,
    fully_diluted_valuation: 555547809,
    total_volume: 16532934,
    high_24h: 5.84,
    low_24h: 5.51,
    price_change_24h: -0.24396658612297983,
    price_change_percentage_24h: -4.19645,
    market_cap_change_24h: -17513278.64660251,
    market_cap_change_percentage_24h: -4.14271,
    circulating_supply: 72730380.6746556,
    total_supply: 99707752.7128909,
    max_supply: 99707752.7128909,
    ath: 42.8,
    ath_change_percentage: -86.99976,
    ath_date: "2022-01-12T15:22:27.465Z",
    atl: 1.5,
    atl_change_percentage: 270.15837,
    atl_date: "2021-06-25T16:50:51.447Z",
    roi: null,
    last_updated: "2023-06-28T12:02:03.708Z",
  },
  chz: {
    id: "chiliz",
    symbol: "chz",
    name: "Chiliz",
    image:
      "https://assets.coingecko.com/coins/images/8834/large/CHZ_Token_updated.png?1675848257",
    current_price: 0.074376,
    market_cap: 398278523,
    market_cap_rank: 97,
    fully_diluted_valuation: 662464587,
    total_volume: 19935504,
    high_24h: 0.078007,
    low_24h: 0.074032,
    price_change_24h: -0.003599009952114457,
    price_change_percentage_24h: -4.61562,
    market_cap_change_24h: -18243343.82496518,
    market_cap_change_percentage_24h: -4.37992,
    circulating_supply: 5344064580,
    total_supply: 8888888888,
    max_supply: 8888888888,
    ath: 0.878633,
    ath_change_percentage: -91.53488,
    ath_date: "2021-03-13T08:04:21.200Z",
    atl: 0.00410887,
    atl_change_percentage: 1710.16353,
    atl_date: "2019-09-28T00:00:00.000Z",
    roi: {
      times: 2.3807090150799093,
      currency: "usd",
      percentage: 238.0709015079909,
    },
    last_updated: "2023-06-28T12:01:55.802Z",
  },
  dash: {
    id: "dash",
    symbol: "dash",
    name: "Dash",
    image:
      "https://assets.coingecko.com/coins/images/19/large/dash-logo.png?1548385930",
    current_price: 34.77,
    market_cap: 394176132,
    market_cap_rank: 98,
    fully_diluted_valuation: 657383246,
    total_volume: 41782998,
    high_24h: 36.64,
    low_24h: 34.31,
    price_change_24h: -1.5085600038606302,
    price_change_percentage_24h: -4.15855,
    market_cap_change_24h: -16950577.467218816,
    market_cap_change_percentage_24h: -4.12296,
    circulating_supply: 11344694.9909687,
    total_supply: 18920000,
    max_supply: null,
    ath: 1493.59,
    ath_change_percentage: -97.67328,
    ath_date: "2017-12-20T00:00:00.000Z",
    atl: 0.213899,
    atl_change_percentage: 16146.77012,
    atl_date: "2014-02-14T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:01:57.843Z",
  },
  twt: {
    id: "trust-wallet-token",
    symbol: "twt",
    name: "Trust Wallet",
    image:
      "https://assets.coingecko.com/coins/images/11085/large/Trust.png?1588062702",
    current_price: 0.902473,
    market_cap: 376487037,
    market_cap_rank: 99,
    fully_diluted_valuation: 903605251,
    total_volume: 6000552,
    high_24h: 0.923003,
    low_24h: 0.896228,
    price_change_24h: -0.016661691114438937,
    price_change_percentage_24h: -1.81276,
    market_cap_change_24h: -5344890.238558829,
    market_cap_change_percentage_24h: -1.3998,
    circulating_supply: 416649900,
    total_supply: 1000000000,
    max_supply: null,
    ath: 2.72,
    ath_change_percentage: -66.78751,
    ath_date: "2022-12-11T23:25:46.205Z",
    atl: 0.00277641,
    atl_change_percentage: 32448.54366,
    atl_date: "2020-06-09T09:15:06.983Z",
    roi: null,
    last_updated: "2023-06-28T12:01:56.738Z",
  },
  woo: {
    id: "woo-network",
    symbol: "woo",
    name: "WOO Network",
    image:
      "https://assets.coingecko.com/coins/images/12921/large/w2UiemF__400x400.jpg?1603670367",
    current_price: 0.216375,
    market_cap: 370873960,
    market_cap_rank: 100,
    fully_diluted_valuation: 487269587,
    total_volume: 24550483,
    high_24h: 0.229535,
    low_24h: 0.216103,
    price_change_24h: -0.005019179797604829,
    price_change_percentage_24h: -2.26708,
    market_cap_change_24h: -8298049.723178208,
    market_cap_change_percentage_24h: -2.18847,
    circulating_supply: 1713036857.12031,
    total_supply: 2250658857.90186,
    max_supply: 2250658857.90186,
    ath: 1.78,
    ath_change_percentage: -87.78674,
    ath_date: "2021-11-15T13:44:18.437Z",
    atl: 0.02211546,
    atl_change_percentage: 882.06229,
    atl_date: "2021-01-04T10:19:13.803Z",
    roi: null,
    last_updated: "2023-06-28T12:02:00.289Z",
  },
  sui: {
    id: "sui",
    symbol: "sui",
    name: "Sui",
    image:
      "https://assets.coingecko.com/coins/images/26375/large/sui_asset.jpeg?1683114182",
    current_price: 0.677185,
    market_cap: 357739104,
    market_cap_rank: 101,
    fully_diluted_valuation: 6771851263,
    total_volume: 119518611,
    high_24h: 0.730489,
    low_24h: 0.673098,
    price_change_24h: -0.048896168679109064,
    price_change_percentage_24h: -6.73426,
    market_cap_change_24h: -25833220.00137782,
    market_cap_change_percentage_24h: -6.7349,
    circulating_supply: 528273718,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 2.16,
    ath_change_percentage: -68.65648,
    ath_date: "2023-05-03T12:00:26.430Z",
    atl: 0.557963,
    atl_change_percentage: 21.4792,
    atl_date: "2023-06-10T06:00:47.648Z",
    roi: null,
    last_updated: "2023-06-28T12:03:46.382Z",
  },
  nxm: {
    id: "nxm",
    symbol: "nxm",
    name: "Nexus Mutual",
    image:
      "https://assets.coingecko.com/coins/images/11810/large/NXMmain.png?1674799570",
    current_price: 52.71,
    market_cap: 356304252,
    market_cap_rank: 102,
    fully_diluted_valuation: 356304252,
    total_volume: 13520.53,
    high_24h: 53.92,
    low_24h: 52.38,
    price_change_24h: -0.5898465622919034,
    price_change_percentage_24h: -1.1066,
    market_cap_change_24h: -3368150.5073099732,
    market_cap_change_percentage_24h: -0.93645,
    circulating_supply: 6759355.60325843,
    total_supply: 6759355.60325843,
    max_supply: 6759355.60325843,
    ath: 185.97,
    ath_change_percentage: -71.66928,
    ath_date: "2021-11-10T15:14:14.418Z",
    atl: 6.96,
    atl_change_percentage: 656.69056,
    atl_date: "2020-07-22T19:54:06.406Z",
    roi: null,
    last_updated: "2023-06-28T12:00:06.760Z",
  },
  nexo: {
    id: "nexo",
    symbol: "nexo",
    name: "NEXO",
    image:
      "https://assets.coingecko.com/coins/images/3695/large/nexo.png?1548086057",
    current_price: 0.632439,
    market_cap: 354290528,
    market_cap_rank: 103,
    fully_diluted_valuation: 632661646,
    total_volume: 2037114,
    high_24h: 0.644608,
    low_24h: 0.629967,
    price_change_24h: -0.004518512387462481,
    price_change_percentage_24h: -0.70939,
    market_cap_change_24h: -2986174.591461897,
    market_cap_change_percentage_24h: -0.83582,
    circulating_supply: 560000010.0064,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 4.07,
    ath_change_percentage: -84.4487,
    ath_date: "2021-05-12T14:37:47.535Z",
    atl: 0.04515276,
    atl_change_percentage: 1301.07066,
    atl_date: "2018-09-13T00:00:00.000Z",
    roi: {
      times: 5.324385154960814,
      currency: "usd",
      percentage: 532.4385154960814,
    },
    last_updated: "2023-06-28T12:04:02.328Z",
  },
  flex: {
    id: "flex-coin",
    symbol: "flex",
    name: "FLEX Coin",
    image:
      "https://assets.coingecko.com/coins/images/9108/large/coinflex_logo.png?1628750583",
    current_price: 3.5,
    market_cap: 349029736,
    market_cap_rank: 104,
    fully_diluted_valuation: 349280269,
    total_volume: 348867,
    high_24h: 4.44,
    low_24h: 3.54,
    price_change_24h: -0.7348346931988439,
    price_change_percentage_24h: -17.34017,
    market_cap_change_24h: -63225019.16563207,
    market_cap_change_percentage_24h: -15.3364,
    circulating_supply: 98664943.1992496,
    total_supply: 98735764.894,
    max_supply: 98735764.894,
    ath: 7.56,
    ath_change_percentage: -53.23112,
    ath_date: "2021-12-22T02:44:42.437Z",
    atl: 0.00047299,
    atl_change_percentage: 747804.67223,
    atl_date: "2020-08-04T11:18:39.614Z",
    roi: null,
    last_updated: "2023-06-28T12:04:05.715Z",
  },
  ar: {
    id: "arweave",
    symbol: "ar",
    name: "Arweave",
    image:
      "https://assets.coingecko.com/coins/images/4343/large/oRt6SiEN_400x400.jpg?1591059616",
    current_price: 5.3,
    market_cap: 347338144,
    market_cap_rank: 105,
    fully_diluted_valuation: 350234554,
    total_volume: 5702645,
    high_24h: 5.6,
    low_24h: 5.29,
    price_change_24h: -0.27742281321193296,
    price_change_percentage_24h: -4.97529,
    market_cap_change_24h: -16834678.847779512,
    market_cap_change_percentage_24h: -4.62272,
    circulating_supply: 65454185.5381511,
    total_supply: 65454185.5381511,
    max_supply: 66000000,
    ath: 89.24,
    ath_change_percentage: -94.04596,
    ath_date: "2021-11-05T04:14:42.689Z",
    atl: 0.298788,
    atl_change_percentage: 1678.21726,
    atl_date: "2020-01-31T06:47:36.543Z",
    roi: {
      times: 6.160254503826178,
      currency: "usd",
      percentage: 616.0254503826178,
    },
    last_updated: "2023-06-28T12:04:03.370Z",
  },
  cusdc: {
    id: "compound-usd-coin",
    symbol: "cusdc",
    name: "cUSDC",
    image:
      "https://assets.coingecko.com/coins/images/9442/large/Compound_USDC.png?1567581577",
    current_price: 0.02291089,
    market_cap: 332351197,
    market_cap_rank: 106,
    fully_diluted_valuation: 332351197,
    total_volume: 2.88,
    high_24h: 0.02293496,
    low_24h: 0.02287281,
    price_change_24h: 0.0000224,
    price_change_percentage_24h: 0.09788,
    market_cap_change_24h: -836838.3125658631,
    market_cap_change_percentage_24h: -0.25116,
    circulating_supply: 14506252452.7406,
    total_supply: 14506252452.7406,
    max_supply: null,
    ath: 0.058843,
    ath_change_percentage: -61.06464,
    ath_date: "2020-10-21T11:24:27.820Z",
    atl: 0.00620705,
    atl_change_percentage: 269.11056,
    atl_date: "2021-03-04T08:11:37.438Z",
    roi: null,
    last_updated: "2023-06-28T12:02:50.553Z",
  },
  zil: {
    id: "zilliqa",
    symbol: "zil",
    name: "Zilliqa",
    image:
      "https://assets.coingecko.com/coins/images/2687/large/Zilliqa-logo.png?1547036894",
    current_price: 0.01957764,
    market_cap: 326348288,
    market_cap_rank: 107,
    fully_diluted_valuation: 411521975,
    total_volume: 12836928,
    high_24h: 0.02062897,
    low_24h: 0.01947282,
    price_change_24h: -0.000820612228840507,
    price_change_percentage_24h: -4.02295,
    market_cap_change_24h: -13853741.843494952,
    market_cap_change_percentage_24h: -4.07221,
    circulating_supply: 16653579808.4729,
    total_supply: 17702585997.8229,
    max_supply: 21000000000,
    ath: 0.255376,
    ath_change_percentage: -92.32175,
    ath_date: "2021-05-06T17:33:45.940Z",
    atl: 0.00239616,
    atl_change_percentage: 718.32563,
    atl_date: "2020-03-13T02:22:55.161Z",
    roi: {
      times: 0.2415071246061523,
      currency: "eth",
      percentage: 24.15071246061523,
    },
    last_updated: "2023-06-28T12:04:02.487Z",
  },
  dydx: {
    id: "dydx",
    symbol: "dydx",
    name: "dYdX",
    image:
      "https://assets.coingecko.com/coins/images/17500/large/hjnIm9bV.jpg?1628009360",
    current_price: 1.91,
    market_cap: 322401654,
    market_cap_rank: 108,
    fully_diluted_valuation: 1914483017,
    total_volume: 34486776,
    high_24h: 2.01,
    low_24h: 1.91,
    price_change_24h: -0.09396443112317153,
    price_change_percentage_24h: -4.67879,
    market_cap_change_24h: -15807431.889814913,
    market_cap_change_percentage_24h: -4.67386,
    circulating_supply: 168401417.63880643,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 27.86,
    ath_change_percentage: -93.12871,
    ath_date: "2021-09-30T01:23:43.570Z",
    atl: 1.011,
    atl_change_percentage: 89.42891,
    atl_date: "2022-06-18T20:55:50.603Z",
    roi: null,
    last_updated: "2023-06-28T12:03:54.248Z",
  },
  cake: {
    id: "pancakeswap-token",
    symbol: "cake",
    name: "PancakeSwap",
    image:
      "https://assets.coingecko.com/coins/images/12632/large/pancakeswap-cake-logo_%281%29.png?1629359065",
    current_price: 1.48,
    market_cap: 310851670,
    market_cap_rank: 109,
    fully_diluted_valuation: 1111702477,
    total_volume: 31082346,
    high_24h: 1.56,
    low_24h: 1.47,
    price_change_24h: -0.07290344650926506,
    price_change_percentage_24h: -4.69275,
    market_cap_change_24h: -9713070.712077916,
    market_cap_change_percentage_24h: -3.02999,
    circulating_supply: 209713261.510471,
    total_supply: 391129211.048447,
    max_supply: 750000000,
    ath: 43.96,
    ath_change_percentage: -96.62851,
    ath_date: "2021-04-30T10:08:22.934Z",
    atl: 0.194441,
    atl_change_percentage: 662.32227,
    atl_date: "2020-11-03T14:29:34.165Z",
    roi: null,
    last_updated: "2023-06-28T12:04:07.464Z",
  },
  gno: {
    id: "gnosis",
    symbol: "gno",
    name: "Gnosis",
    image:
      "https://assets.coingecko.com/coins/images/662/large/logo_square_simple_300px.png?1609402668",
    current_price: 115.52,
    market_cap: 299273800,
    market_cap_rank: 111,
    fully_diluted_valuation: 346704207,
    total_volume: 1151909,
    high_24h: 117.62,
    low_24h: 114.6,
    price_change_24h: -0.03682247262086946,
    price_change_percentage_24h: -0.03186,
    market_cap_change_24h: -510494.12259221077,
    market_cap_change_percentage_24h: -0.17029,
    circulating_supply: 2589589,
    total_supply: 3000000,
    max_supply: 3000000,
    ath: 644.2,
    ath_change_percentage: -82.06027,
    ath_date: "2021-11-08T17:33:29.302Z",
    atl: 6.88,
    atl_change_percentage: 1578.76963,
    atl_date: "2020-03-13T02:24:11.825Z",
    roi: null,
    last_updated: "2023-06-28T12:03:55.793Z",
  },
  osmo: {
    id: "osmosis",
    symbol: "osmo",
    name: "Osmosis",
    image:
      "https://assets.coingecko.com/coins/images/16724/large/osmo.png?1632763885",
    current_price: 0.495883,
    market_cap: 298514325,
    market_cap_rank: 110,
    fully_diluted_valuation: 496030539,
    total_volume: 6906821,
    high_24h: 0.506348,
    low_24h: 0.493405,
    price_change_24h: -0.007260347353753639,
    price_change_percentage_24h: -1.443,
    market_cap_change_24h: -3892718.392261982,
    market_cap_change_percentage_24h: -1.28724,
    circulating_supply: 601806344,
    total_supply: 325000000,
    max_supply: 1000000000,
    ath: 11.25,
    ath_change_percentage: -95.59067,
    ath_date: "2022-03-04T05:12:18.707Z",
    atl: 0.447396,
    atl_change_percentage: 10.86602,
    atl_date: "2023-06-15T11:39:44.282Z",
    roi: null,
    last_updated: "2023-06-28T12:03:50.041Z",
  },
  comp: {
    id: "compound-governance-token",
    symbol: "comp",
    name: "Compound",
    image:
      "https://assets.coingecko.com/coins/images/10775/large/COMP.png?1592625425",
    current_price: 42.9,
    market_cap: 294473236,
    market_cap_rank: 112,
    fully_diluted_valuation: 429506349,
    total_volume: 97529751,
    high_24h: 44.44,
    low_24h: 40.32,
    price_change_24h: 2.27,
    price_change_percentage_24h: 5.59008,
    market_cap_change_24h: 16425880,
    market_cap_change_percentage_24h: 5.90758,
    circulating_supply: 6856085.76329154,
    total_supply: 10000000,
    max_supply: 10000000,
    ath: 910.54,
    ath_change_percentage: -95.28296,
    ath_date: "2021-05-12T02:29:08.794Z",
    atl: 25.74,
    atl_change_percentage: 66.86126,
    atl_date: "2023-06-10T16:15:05.289Z",
    roi: null,
    last_updated: "2023-06-28T12:04:04.485Z",
  },
  rune: {
    id: "thorchain",
    symbol: "rune",
    name: "THORChain",
    image:
      "https://assets.coingecko.com/coins/images/6595/large/Rune200x200.png?1671179394",
    current_price: 0.971335,
    market_cap: 292744990,
    market_cap_rank: 113,
    fully_diluted_valuation: 486771607,
    total_volume: 30616857,
    high_24h: 1.021,
    low_24h: 0.962475,
    price_change_24h: -0.03724109751051652,
    price_change_percentage_24h: -3.69244,
    market_cap_change_24h: -10167235.662692606,
    market_cap_change_percentage_24h: -3.3565,
    circulating_supply: 300700561,
    total_supply: 500000000,
    max_supply: 500000000,
    ath: 20.87,
    ath_change_percentage: -95.33509,
    ath_date: "2021-05-19T00:30:09.436Z",
    atl: 0.00851264,
    atl_change_percentage: 11335.39364,
    atl_date: "2019-09-28T00:00:00.000Z",
    roi: {
      times: 24.561456813635562,
      currency: "usd",
      percentage: 2456.1456813635564,
    },
    last_updated: "2023-06-28T12:04:04.583Z",
  },
  "1inch": {
    id: "1inch",
    symbol: "1inch",
    name: "1inch",
    image:
      "https://assets.coingecko.com/coins/images/13469/large/1inch-token.png?1608803028",
    current_price: 0.307033,
    market_cap: 288953441,
    market_cap_rank: 114,
    fully_diluted_valuation: 460786562,
    total_volume: 12564216,
    high_24h: 0.325525,
    low_24h: 0.305286,
    price_change_24h: -0.01674291198562694,
    price_change_percentage_24h: -5.17113,
    market_cap_change_24h: -15313668.527483582,
    market_cap_change_percentage_24h: -5.03297,
    circulating_supply: 940631079.863992,
    total_supply: 1500000000,
    max_supply: 1500000000,
    ath: 8.65,
    ath_change_percentage: -96.4493,
    ath_date: "2021-10-27T08:24:54.808Z",
    atl: 0.254171,
    atl_change_percentage: 20.8607,
    atl_date: "2023-06-15T17:41:38.825Z",
    roi: null,
    last_updated: "2023-06-28T12:04:06.623Z",
  },
  bdx: {
    id: "beldex",
    symbol: "bdx",
    name: "Beldex",
    image:
      "https://assets.coingecko.com/coins/images/5111/large/Beldex.png?1559189036",
    current_price: 0.054018,
    market_cap: 287703266,
    market_cap_rank: 116,
    fully_diluted_valuation: 535533743,
    total_volume: 5545367,
    high_24h: 0.055046,
    low_24h: 0.03647521,
    price_change_24h: 0.00009653,
    price_change_percentage_24h: 0.17902,
    market_cap_change_24h: 483542,
    market_cap_change_percentage_24h: 0.16835,
    circulating_supply: 5328008360.18964,
    total_supply: 9917608154.22131,
    max_supply: null,
    ath: 137242055,
    ath_change_percentage: -100,
    ath_date: "2020-09-02T09:13:36.882Z",
    atl: 0.00027519,
    atl_change_percentage: 19526.28853,
    atl_date: "2019-11-01T11:05:12.090Z",
    roi: {
      times: -0.7839297456787592,
      currency: "usd",
      percentage: -78.39297456787591,
    },
    last_updated: "2023-06-28T12:03:51.511Z",
  },
  tomi: {
    id: "tominet",
    symbol: "tomi",
    name: "tomiNet",
    image:
      "https://assets.coingecko.com/coins/images/28730/large/logo_for_token.png?1673690005",
    current_price: 4.36,
    market_cap: 286818852,
    market_cap_rank: 115,
    fully_diluted_valuation: 489818946,
    total_volume: 17931920,
    high_24h: 4.56,
    low_24h: 4.27,
    price_change_24h: -0.15978121053310712,
    price_change_percentage_24h: -3.53659,
    market_cap_change_24h: -12188936.540886343,
    market_cap_change_percentage_24h: -4.07646,
    circulating_supply: 66146338.4491826,
    total_supply: 112962343.921142,
    max_supply: null,
    ath: 6.59,
    ath_change_percentage: -34.23351,
    ath_date: "2023-06-06T14:54:34.753Z",
    atl: 0.154561,
    atl_change_percentage: 2703.1923,
    atl_date: "2023-01-16T10:51:50.114Z",
    roi: null,
    last_updated: "2023-06-28T12:03:59.636Z",
  },
  lusd: {
    id: "liquity-usd",
    symbol: "lusd",
    name: "Liquity USD",
    image:
      "https://assets.coingecko.com/coins/images/14666/large/Group_3.png?1617631327",
    current_price: 1.002,
    market_cap: 283309023,
    market_cap_rank: 117,
    fully_diluted_valuation: 283309023,
    total_volume: 5993709,
    high_24h: 1.005,
    low_24h: 0.998285,
    price_change_24h: 0.00112639,
    price_change_percentage_24h: 0.11251,
    market_cap_change_24h: 361465,
    market_cap_change_percentage_24h: 0.12775,
    circulating_supply: 282766136.108724,
    total_supply: 282766136.108724,
    max_supply: null,
    ath: 1.16,
    ath_change_percentage: -13.48231,
    ath_date: "2021-04-05T14:47:52.665Z",
    atl: 0.896722,
    atl_change_percentage: 11.75766,
    atl_date: "2022-01-27T09:42:06.510Z",
    roi: null,
    last_updated: "2023-06-28T12:03:05.105Z",
  },
  flr: {
    id: "flare-networks",
    symbol: "flr",
    name: "Flare",
    image:
      "https://assets.coingecko.com/coins/images/28624/large/FLR-icon200x200.png?1673325704",
    current_price: 0.01511902,
    market_cap: 282602493,
    market_cap_rank: 118,
    fully_diluted_valuation: 1526595881,
    total_volume: 2692435,
    high_24h: 0.01576535,
    low_24h: 0.01509855,
    price_change_24h: -0.000617446401669435,
    price_change_percentage_24h: -3.92367,
    market_cap_change_24h: -11725475.51765424,
    market_cap_change_percentage_24h: -3.98381,
    circulating_supply: 18705569031.6174,
    total_supply: 101045975510.48,
    max_supply: null,
    ath: 0.150073,
    ath_change_percentage: -89.93121,
    ath_date: "2023-01-10T03:14:05.921Z",
    atl: 0.01360273,
    atl_change_percentage: 11.08453,
    atl_date: "2023-06-15T12:55:19.178Z",
    roi: null,
    last_updated: "2023-06-28T12:04:03.344Z",
  },
  enj: {
    id: "enjincoin",
    symbol: "enj",
    name: "Enjin Coin",
    image:
      "https://assets.coingecko.com/coins/images/1102/large/enjin-coin-logo.png?1547035078",
    current_price: 0.282126,
    market_cap: 282184122,
    market_cap_rank: 120,
    fully_diluted_valuation: 282184122,
    total_volume: 14928754,
    high_24h: 0.295963,
    low_24h: 0.280022,
    price_change_24h: -0.011166205098212367,
    price_change_percentage_24h: -3.8072,
    market_cap_change_24h: -11195311.504421473,
    market_cap_change_percentage_24h: -3.81598,
    circulating_supply: 1000000000,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 4.82,
    ath_change_percentage: -94.14477,
    ath_date: "2021-11-25T09:53:41.955Z",
    atl: 0.01865964,
    atl_change_percentage: 1412.26526,
    atl_date: "2017-11-12T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:03:59.290Z",
  },
  bat: {
    id: "basic-attention-token",
    symbol: "bat",
    name: "Basic Attention",
    image:
      "https://assets.coingecko.com/coins/images/677/large/basic-attention-token.png?1547034427",
    current_price: 0.189122,
    market_cap: 281898021,
    market_cap_rank: 119,
    fully_diluted_valuation: 283921493,
    total_volume: 10817056,
    high_24h: 0.197569,
    low_24h: 0.187637,
    price_change_24h: -0.006502592077243825,
    price_change_percentage_24h: -3.32402,
    market_cap_change_24h: -9373374.435977042,
    market_cap_change_percentage_24h: -3.21809,
    circulating_supply: 1489309693.7326057,
    total_supply: 1500000000,
    max_supply: 1500000000,
    ath: 1.9,
    ath_change_percentage: -90.04076,
    ath_date: "2021-11-28T00:23:51.354Z",
    atl: 0.072394,
    atl_change_percentage: 161.46021,
    atl_date: "2017-07-16T00:00:00.000Z",
    roi: {
      times: -0.3498793056634304,
      currency: "eth",
      percentage: -34.98793056634304,
    },
    last_updated: "2023-06-28T12:04:02.677Z",
  },
  lrc: {
    id: "loopring",
    symbol: "lrc",
    name: "Loopring",
    image:
      "https://assets.coingecko.com/coins/images/913/large/LRC.png?1572852344",
    current_price: 0.226018,
    market_cap: 281825783,
    market_cap_rank: 121,
    fully_diluted_valuation: 310895753,
    total_volume: 21515990,
    high_24h: 0.235142,
    low_24h: 0.223969,
    price_change_24h: -0.008499384426276069,
    price_change_percentage_24h: -3.6242,
    market_cap_change_24h: -10481272.923400998,
    market_cap_change_percentage_24h: -3.58571,
    circulating_supply: 1245991468.94246,
    total_supply: 1373873397.44246,
    max_supply: 1374513896,
    ath: 3.75,
    ath_change_percentage: -93.96814,
    ath_date: "2021-11-10T14:59:53.164Z",
    atl: 0.01963893,
    atl_change_percentage: 1051.71933,
    atl_date: "2019-12-18T13:14:41.553Z",
    roi: {
      times: 2.76697064461326,
      currency: "usd",
      percentage: 276.697064461326,
    },
    last_updated: "2023-06-28T12:04:01.740Z",
  },
  sfrxeth: {
    id: "staked-frax-ether",
    symbol: "sfrxeth",
    name: "Staked Frax Ether",
    image:
      "https://assets.coingecko.com/coins/images/28285/large/sfrxETH_icon.png?1679886768",
    current_price: 1949.66,
    market_cap: 279826725,
    market_cap_rank: 123,
    fully_diluted_valuation: 279827014,
    total_volume: 34855,
    high_24h: 1996.15,
    low_24h: 1936.73,
    price_change_24h: -14.838301076336847,
    price_change_percentage_24h: -0.75532,
    market_cap_change_24h: -2229055.976912558,
    market_cap_change_percentage_24h: -0.79029,
    circulating_supply: 143459.412805414,
    total_supply: 143459.56094438,
    max_supply: null,
    ath: 7545.09,
    ath_change_percentage: -74.15275,
    ath_date: "2023-02-16T07:51:36.849Z",
    atl: 1147.58,
    atl_change_percentage: 69.93953,
    atl_date: "2022-12-23T11:50:57.675Z",
    roi: null,
    last_updated: "2023-06-28T12:03:19.747Z",
  },
  okt: {
    id: "oec-token",
    symbol: "okt",
    name: "OKT Chain",
    image:
      "https://assets.coingecko.com/coins/images/13708/large/WeChat_Image_20220118095654.png?1642471094",
    current_price: 15.66,
    market_cap: 279496558,
    market_cap_rank: 124,
    fully_diluted_valuation: 328865908,
    total_volume: 333170,
    high_24h: 15.99,
    low_24h: 14.95,
    price_change_24h: 0.562301,
    price_change_percentage_24h: 3.72433,
    market_cap_change_24h: 10034810,
    market_cap_change_percentage_24h: 3.72402,
    circulating_supply: 17847480,
    total_supply: 17847259.5,
    max_supply: 21000000,
    ath: 250.53,
    ath_change_percentage: -93.74923,
    ath_date: "2021-05-10T03:09:56.045Z",
    atl: 13.02,
    atl_change_percentage: 20.24577,
    atl_date: "2022-07-13T00:38:46.306Z",
    roi: null,
    last_updated: "2023-06-28T12:03:36.415Z",
  },
  agix: {
    id: "singularitynet",
    symbol: "agix",
    name: "SingularityNET",
    image:
      "https://assets.coingecko.com/coins/images/2138/large/singularitynet.png?1548609559",
    current_price: 0.228373,
    market_cap: 279414322,
    market_cap_rank: 122,
    fully_diluted_valuation: 457032883,
    total_volume: 29239702,
    high_24h: 0.240966,
    low_24h: 0.225843,
    price_change_24h: -0.010408360450678417,
    price_change_percentage_24h: -4.35896,
    market_cap_change_24h: -12211527.862576008,
    market_cap_change_percentage_24h: -4.1874,
    circulating_supply: 1222731810.88493,
    total_supply: 1303100454.47526,
    max_supply: 2000000000,
    ath: 0.95023,
    ath_change_percentage: -75.94897,
    ath_date: "2018-01-28T00:00:00.000Z",
    atl: 0.00747159,
    atl_change_percentage: 2958.78772,
    atl_date: "2020-03-13T02:24:16.528Z",
    roi: {
      times: 1.283725591589231,
      currency: "usd",
      percentage: 128.3725591589231,
    },
    last_updated: "2023-06-28T12:04:02.544Z",
  },
  safemoon: {
    id: "safemoon",
    symbol: "safemoon",
    name: "SafeMoon [OLD]",
    image:
      "https://assets.coingecko.com/coins/images/14362/large/174x174-white.png?1617174846",
    current_price: 4.863e-9,
    market_cap: 277800683,
    market_cap_rank: 125,
    fully_diluted_valuation: null,
    total_volume: 0.257721,
    high_24h: 4.981e-9,
    low_24h: 4.847e-9,
    price_change_24h: -8.8443841e-11,
    price_change_percentage_24h: -1.78635,
    market_cap_change_24h: -14241412.357248962,
    market_cap_change_percentage_24h: -4.87649,
    circulating_supply: 0,
    total_supply: 1000000000000000,
    max_supply: null,
    ath: 0.00001094,
    ath_change_percentage: -99.95557,
    ath_date: "2021-05-11T20:32:53.513Z",
    atl: 3.3e-9,
    atl_change_percentage: 47.34689,
    atl_date: "2022-06-18T20:49:56.139Z",
    roi: null,
    last_updated: "2023-06-28T03:43:55.414Z",
  },
  cvx: {
    id: "convex-finance",
    symbol: "cvx",
    name: "Convex Finance",
    image:
      "https://assets.coingecko.com/coins/images/15585/large/convex.png?1621256328",
    current_price: 3.54,
    market_cap: 277478760,
    market_cap_rank: 126,
    fully_diluted_valuation: 354165094,
    total_volume: 2647750,
    high_24h: 3.68,
    low_24h: 3.52,
    price_change_24h: -0.10696711361739464,
    price_change_percentage_24h: -2.93157,
    market_cap_change_24h: -8298935.992921531,
    market_cap_change_percentage_24h: -2.90398,
    circulating_supply: 78347291.68726721,
    total_supply: 98463340.0880891,
    max_supply: 100000000,
    ath: 60.09,
    ath_change_percentage: -94.10545,
    ath_date: "2022-01-01T18:04:03.030Z",
    atl: 1.9,
    atl_change_percentage: 86.13356,
    atl_date: "2021-07-20T13:17:29.183Z",
    roi: null,
    last_updated: "2023-06-28T12:03:59.838Z",
  },
  mask: {
    id: "mask-network",
    symbol: "mask",
    name: "Mask Network",
    image:
      "https://assets.coingecko.com/coins/images/14051/large/Mask_Network.jpg?1614050316",
    current_price: 3.37,
    market_cap: 276758882,
    market_cap_rank: 127,
    fully_diluted_valuation: 337048417,
    total_volume: 33334820,
    high_24h: 3.56,
    low_24h: 3.34,
    price_change_24h: -0.16102210378496107,
    price_change_percentage_24h: -4.56045,
    market_cap_change_24h: -13501734.377869606,
    market_cap_change_percentage_24h: -4.65159,
    circulating_supply: 82112500,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 41.45,
    ath_change_percentage: -91.86837,
    ath_date: "2021-02-24T17:42:44.637Z",
    atl: 0.976528,
    atl_change_percentage: 245.12002,
    atl_date: "2022-10-13T10:36:10.009Z",
    roi: null,
    last_updated: "2023-06-28T12:03:50.224Z",
  },
  hbtc: {
    id: "huobi-btc",
    symbol: "hbtc",
    name: "Huobi BTC",
    image:
      "https://assets.coingecko.com/coins/images/12407/large/hbtc.png?1687143034",
    current_price: 30348,
    market_cap: 272206773,
    market_cap_rank: 128,
    fully_diluted_valuation: 272206773,
    total_volume: 81709,
    high_24h: 30932,
    low_24h: 30155,
    price_change_24h: -261.6007296681091,
    price_change_percentage_24h: -0.85463,
    market_cap_change_24h: -2373569.324762702,
    market_cap_change_percentage_24h: -0.86444,
    circulating_supply: 8969.497004742,
    total_supply: 8969.497004742,
    max_supply: 8969.497004742,
    ath: 166135,
    ath_change_percentage: -81.6391,
    ath_date: "2022-04-21T18:59:43.859Z",
    atl: 10704.55,
    atl_change_percentage: 184.9615,
    atl_date: "2023-03-10T01:30:46.660Z",
    roi: null,
    last_updated: "2023-06-28T12:02:13.305Z",
  },
  xch: {
    id: "chia",
    symbol: "xch",
    name: "Chia",
    image:
      "https://assets.coingecko.com/coins/images/15174/large/zV5K5y9f_400x400.png?1620024414",
    current_price: 34.21,
    market_cap: 264593380,
    market_cap_rank: 129,
    fully_diluted_valuation: 982708827,
    total_volume: 1822835,
    high_24h: 35.05,
    low_24h: 34,
    price_change_24h: 0.0832,
    price_change_percentage_24h: 0.24383,
    market_cap_change_24h: 693159,
    market_cap_change_percentage_24h: 0.26266,
    circulating_supply: 7737510.10147785,
    total_supply: 28737376.0913249,
    max_supply: null,
    ath: 1645.12,
    ath_change_percentage: -97.92096,
    ath_date: "2021-05-15T14:24:45.107Z",
    atl: 26.27,
    atl_change_percentage: 30.19011,
    atl_date: "2022-06-13T09:12:42.846Z",
    roi: null,
    last_updated: "2023-06-28T12:03:51.006Z",
  },
  mx: {
    id: "mx-token",
    symbol: "mx",
    name: "MX",
    image:
      "https://assets.coingecko.com/coins/images/8545/large/MEXC_GLOBAL_LOGO.jpeg?1670213280",
    current_price: 2.65,
    market_cap: 263816756,
    market_cap_rank: 130,
    fully_diluted_valuation: 1147826696,
    total_volume: 222319,
    high_24h: 2.67,
    low_24h: 2.61,
    price_change_24h: 0.01841809,
    price_change_percentage_24h: 0.69969,
    market_cap_change_24h: 583945,
    market_cap_change_percentage_24h: 0.22184,
    circulating_supply: 99999999.9997,
    total_supply: 435084834,
    max_supply: null,
    ath: 3.7,
    ath_change_percentage: -28.65151,
    ath_date: "2021-12-02T00:59:40.216Z",
    atl: 0.04218457,
    atl_change_percentage: 6156.27476,
    atl_date: "2019-11-25T05:59:45.392Z",
    roi: null,
    last_updated: "2023-06-28T12:03:18.414Z",
  },
  dfi: {
    id: "defichain",
    symbol: "dfi",
    name: "DeFiChain",
    image:
      "https://assets.coingecko.com/coins/images/11757/large/symbol-defi-blockchain_200.png?1597306538",
    current_price: 0.359797,
    market_cap: 258578288,
    market_cap_rank: 131,
    fully_diluted_valuation: 431946717,
    total_volume: 3664451,
    high_24h: 0.36401,
    low_24h: 0.359414,
    price_change_24h: -0.00095441314344924,
    price_change_percentage_24h: -0.26456,
    market_cap_change_24h: -344360.83058154583,
    market_cap_change_percentage_24h: -0.133,
    circulating_supply: 718361626.147049,
    total_supply: 1200000000,
    max_supply: null,
    ath: 5.61,
    ath_change_percentage: -93.59018,
    ath_date: "2021-12-06T03:05:01.038Z",
    atl: 0.162998,
    atl_change_percentage: 120.73748,
    atl_date: "2020-07-16T10:24:06.014Z",
    roi: null,
    last_updated: "2023-06-28T12:03:58.468Z",
  },
  blur: {
    id: "blur",
    symbol: "blur",
    name: "Blur",
    image:
      "https://assets.coingecko.com/coins/images/28453/large/blur.png?1670745921",
    current_price: 0.355222,
    market_cap: 257991596,
    market_cap_rank: 132,
    fully_diluted_valuation: 1070899549,
    total_volume: 127152620,
    high_24h: 0.397087,
    low_24h: 0.35098,
    price_change_24h: -0.03626399544206233,
    price_change_percentage_24h: -9.26317,
    market_cap_change_24h: -24323072.470872104,
    market_cap_change_percentage_24h: -8.61559,
    circulating_supply: 722733321.917808,
    total_supply: 3000000000,
    max_supply: 3000000000,
    ath: 5.02,
    ath_change_percentage: -92.92312,
    ath_date: "2023-02-14T17:33:55.928Z",
    atl: 0.295472,
    atl_change_percentage: 20.14476,
    atl_date: "2023-06-12T02:50:46.638Z",
    roi: null,
    last_updated: "2023-06-28T12:04:01.069Z",
  },
  xem: {
    id: "nem",
    symbol: "xem",
    name: "NEM",
    image:
      "https://assets.coingecko.com/coins/images/242/large/NEM_WC_Logo_200px.png?1642668663",
    current_price: 0.02806202,
    market_cap: 252462423,
    market_cap_rank: 133,
    fully_diluted_valuation: 252462423,
    total_volume: 8456402,
    high_24h: 0.02948648,
    low_24h: 0.02766306,
    price_change_24h: -0.00088472401136197,
    price_change_percentage_24h: -3.05638,
    market_cap_change_24h: -8367646.278666019,
    market_cap_change_percentage_24h: -3.20808,
    circulating_supply: 8999999999,
    total_supply: 8999999999,
    max_supply: null,
    ath: 1.87,
    ath_change_percentage: -98.49875,
    ath_date: "2018-01-07T00:00:00.000Z",
    atl: 0.00008482,
    atl_change_percentage: 32972.94437,
    atl_date: "2015-09-05T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:04:00.767Z",
  },
  ilv: {
    id: "illuvium",
    symbol: "ilv",
    name: "Illuvium",
    image:
      "https://assets.coingecko.com/coins/images/14468/large/logo-200x200.png?1682415398",
    current_price: 45.18,
    market_cap: 251041668,
    market_cap_rank: 134,
    fully_diluted_valuation: 434975889,
    total_volume: 3743996,
    high_24h: 47.21,
    low_24h: 45.04,
    price_change_24h: -1.3559184557956812,
    price_change_percentage_24h: -2.91366,
    market_cap_change_24h: -7254648.001692951,
    market_cap_change_percentage_24h: -2.80865,
    circulating_supply: 5555845.0484614,
    total_supply: 9626523.97263356,
    max_supply: null,
    ath: 1911.26,
    ath_change_percentage: -97.63584,
    ath_date: "2021-11-30T15:18:57.382Z",
    atl: 29.23,
    atl_change_percentage: 54.60512,
    atl_date: "2021-06-22T14:11:59.151Z",
    roi: null,
    last_updated: "2023-06-28T12:03:50.696Z",
  },
  zec: {
    id: "zcash",
    symbol: "zec",
    name: "Zcash",
    image:
      "https://assets.coingecko.com/coins/images/486/large/circle-zcash-color.png?1547034197",
    current_price: 29.25,
    market_cap: 248374575,
    market_cap_rank: 135,
    fully_diluted_valuation: 613968459,
    total_volume: 18661012,
    high_24h: 30.62,
    low_24h: 29.15,
    price_change_24h: -1.186809763757676,
    price_change_percentage_24h: -3.89955,
    market_cap_change_24h: -8697192.133931488,
    market_cap_change_percentage_24h: -3.38318,
    circulating_supply: 8495332.30609775,
    total_supply: 21000000,
    max_supply: 21000000,
    ath: 3191.93,
    ath_change_percentage: -99.08405,
    ath_date: "2016-10-29T00:00:00.000Z",
    atl: 19.75,
    atl_change_percentage: 48.00602,
    atl_date: "2020-03-13T02:20:55.002Z",
    roi: null,
    last_updated: "2023-06-28T12:04:03.941Z",
  },
  uw3s: {
    id: "utility-web3shot",
    symbol: "uw3s",
    name: "Utility Web3Shot",
    image:
      "https://assets.coingecko.com/coins/images/29445/large/uw3s.png?1679159052",
    current_price: 0.472571,
    market_cap: 248336270,
    market_cap_rank: 136,
    fully_diluted_valuation: 4725713990,
    total_volume: 106.03,
    high_24h: null,
    low_24h: null,
    price_change_24h: null,
    price_change_percentage_24h: null,
    market_cap_change_24h: null,
    market_cap_change_percentage_24h: null,
    circulating_supply: 525500000,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 1.26,
    ath_change_percentage: -62.43176,
    ath_date: "2023-06-20T18:59:01.429Z",
    atl: 0.02451666,
    atl_change_percentage: 1827.55208,
    atl_date: "2023-05-05T11:31:05.513Z",
    roi: null,
    last_updated: "2023-06-27T11:00:13.274Z",
  },
  floki: {
    id: "floki",
    symbol: "floki",
    name: "FLOKI",
    image:
      "https://assets.coingecko.com/coins/images/16746/large/PNG_image.png?1643184642",
    current_price: 0.00002475,
    market_cap: 246329198,
    market_cap_rank: 137,
    fully_diluted_valuation: 247697979,
    total_volume: 12749788,
    high_24h: 0.00002638,
    low_24h: 0.00002456,
    price_change_24h: -0.000001333238623828,
    price_change_percentage_24h: -5.11117,
    market_cap_change_24h: -13248918.812001675,
    market_cap_change_percentage_24h: -5.10402,
    circulating_supply: 9944739904971,
    total_supply: 10000000000000,
    max_supply: null,
    ath: 0.00033651,
    ath_change_percentage: -92.64047,
    ath_date: "2021-11-04T06:58:35.285Z",
    atl: 8.428e-8,
    atl_change_percentage: 29284.59531,
    atl_date: "2021-07-06T01:11:20.438Z",
    roi: null,
    last_updated: "2023-06-28T12:04:05.364Z",
  },
  qtum: {
    id: "qtum",
    symbol: "qtum",
    name: "Qtum",
    image:
      "https://assets.coingecko.com/coins/images/684/large/Qtum_Logo_blue_CG.png?1637155875",
    current_price: 2.35,
    market_cap: 246101465,
    market_cap_rank: 138,
    fully_diluted_valuation: 253400982,
    total_volume: 21090211,
    high_24h: 2.51,
    low_24h: 2.35,
    price_change_24h: -0.12620116907683165,
    price_change_percentage_24h: -5.09173,
    market_cap_change_24h: -14320030.059368879,
    market_cap_change_percentage_24h: -5.49879,
    circulating_supply: 104716453,
    total_supply: 107822406,
    max_supply: null,
    ath: 100.22,
    ath_change_percentage: -97.65508,
    ath_date: "2018-01-06T00:00:00.000Z",
    atl: 0.783142,
    atl_change_percentage: 200.09494,
    atl_date: "2020-03-13T02:22:57.149Z",
    roi: {
      times: 6.8411770653015,
      currency: "usd",
      percentage: 684.11770653015,
    },
    last_updated: "2023-06-28T12:03:59.220Z",
  },
  btg: {
    id: "bitcoin-gold",
    symbol: "btg",
    name: "Bitcoin Gold",
    image:
      "https://assets.coingecko.com/coins/images/1043/large/bitcoin-gold-logo.png?1547034978",
    current_price: 13.81,
    market_cap: 242859570,
    market_cap_rank: 139,
    fully_diluted_valuation: 291199789,
    total_volume: 7317753,
    high_24h: 14.47,
    low_24h: 13.56,
    price_change_24h: -0.547280677988315,
    price_change_percentage_24h: -3.81114,
    market_cap_change_24h: -12773807.95221731,
    market_cap_change_percentage_24h: -4.99692,
    circulating_supply: 17513924,
    total_supply: 21000000,
    max_supply: null,
    ath: 456.25,
    ath_change_percentage: -96.96071,
    ath_date: "2017-12-20T00:00:00.000Z",
    atl: 4.25,
    atl_change_percentage: 225.93318,
    atl_date: "2020-03-13T02:22:52.902Z",
    roi: null,
    last_updated: "2023-06-28T12:03:52.304Z",
  },
  cdai: {
    id: "cdai",
    symbol: "cdai",
    name: "cDAI",
    image:
      "https://assets.coingecko.com/coins/images/9281/large/cDAI.png?1576467585",
    current_price: 0.02228576,
    market_cap: 242614746,
    market_cap_rank: 141,
    fully_diluted_valuation: 242614746,
    total_volume: 7939.75,
    high_24h: 0.02232722,
    low_24h: 0.02223429,
    price_change_24h: -0.000014669601774736,
    price_change_percentage_24h: -0.06578,
    market_cap_change_24h: -3249134.117477715,
    market_cap_change_percentage_24h: -1.32152,
    circulating_supply: 10886535411.0059,
    total_supply: 10886535411.0059,
    max_supply: null,
    ath: 0.03159087,
    ath_change_percentage: -29.45504,
    ath_date: "2020-10-12T21:29:00.007Z",
    atl: 0.00728854,
    atl_change_percentage: 205.76427,
    atl_date: "2020-03-18T04:29:40.819Z",
    roi: null,
    last_updated: "2023-06-28T12:02:42.213Z",
  },
  rose: {
    id: "oasis-network",
    symbol: "rose",
    name: "Oasis Network",
    image:
      "https://assets.coingecko.com/coins/images/13162/large/rose.png?1605772906",
    current_price: 0.04807484,
    market_cap: 241834114,
    market_cap_rank: 140,
    fully_diluted_valuation: 480844977,
    total_volume: 12802963,
    high_24h: 0.050409,
    low_24h: 0.04764549,
    price_change_24h: -0.001730199466335312,
    price_change_percentage_24h: -3.47394,
    market_cap_change_24h: -8079255.613387734,
    market_cap_change_percentage_24h: -3.23282,
    circulating_supply: 5029357184,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 0.597347,
    ath_change_percentage: -91.95027,
    ath_date: "2022-01-15T03:31:11.062Z",
    atl: 0.03200566,
    atl_change_percentage: 50.23833,
    atl_date: "2020-11-26T17:09:12.182Z",
    roi: null,
    last_updated: "2023-06-28T12:03:49.986Z",
  },
  hot: {
    id: "holotoken",
    symbol: "hot",
    name: "Holo",
    image:
      "https://assets.coingecko.com/coins/images/3348/large/Holologo_Profile.png?1547037966",
    current_price: 0.00135934,
    market_cap: 241209060,
    market_cap_rank: 142,
    fully_diluted_valuation: 241209060,
    total_volume: 6158803,
    high_24h: 0.00141444,
    low_24h: 0.00134889,
    price_change_24h: -0.000053251130982785,
    price_change_percentage_24h: -3.76976,
    market_cap_change_24h: -9715272.032676518,
    market_cap_change_percentage_24h: -3.87179,
    circulating_supply: 177619433541.141,
    total_supply: 177619433541.141,
    max_supply: null,
    ath: 0.03126682,
    ath_change_percentage: -95.65856,
    ath_date: "2021-04-05T16:04:52.608Z",
    atl: 0.0002202,
    atl_change_percentage: 516.45714,
    atl_date: "2020-03-13T02:23:01.612Z",
    roi: {
      times: 2.219415323111651,
      currency: "eth",
      percentage: 221.94153231116508,
    },
    last_updated: "2023-06-28T12:03:54.238Z",
  },
  azero: {
    id: "aleph-zero",
    symbol: "azero",
    name: "Aleph Zero",
    image:
      "https://assets.coingecko.com/coins/images/17212/large/gtmuTVa.png?1626853180",
    current_price: 0.991737,
    market_cap: 230817827,
    market_cap_rank: 144,
    fully_diluted_valuation: 332034945,
    total_volume: 996422,
    high_24h: 1.019,
    low_24h: 0.9815,
    price_change_24h: -0.02634294441928331,
    price_change_percentage_24h: -2.58751,
    market_cap_change_24h: -6066119.429809779,
    market_cap_change_percentage_24h: -2.5608,
    circulating_supply: 232876887.828105,
    total_supply: 334996935.498267,
    max_supply: null,
    ath: 3.09,
    ath_change_percentage: -67.95329,
    ath_date: "2022-04-15T00:09:30.642Z",
    atl: 0.592052,
    atl_change_percentage: 67.50836,
    atl_date: "2022-06-18T20:17:51.413Z",
    roi: null,
    last_updated: "2023-06-28T12:03:19.771Z",
  },
  fet: {
    id: "fetch-ai",
    symbol: "fet",
    name: "Fetch.ai",
    image:
      "https://assets.coingecko.com/coins/images/5681/large/Fetch.jpg?1572098136",
    current_price: 0.220506,
    market_cap: 230165073,
    market_cap_rank: 143,
    fully_diluted_valuation: 254326047,
    total_volume: 52492980,
    high_24h: 0.236515,
    low_24h: 0.219802,
    price_change_24h: -0.010653215186176653,
    price_change_percentage_24h: -4.60861,
    market_cap_change_24h: -10932941.670932889,
    market_cap_change_percentage_24h: -4.53465,
    circulating_supply: 1043462805,
    total_supply: 1152997575,
    max_supply: 1152997575,
    ath: 1.17,
    ath_change_percentage: -81.17951,
    ath_date: "2021-09-08T02:24:24.858Z",
    atl: 0.00816959,
    atl_change_percentage: 2599.95518,
    atl_date: "2020-03-13T02:24:18.347Z",
    roi: {
      times: 1.543319571010888,
      currency: "usd",
      percentage: 154.33195710108882,
    },
    last_updated: "2023-06-28T12:04:07.229Z",
  },
  elg: {
    id: "escoin-token",
    symbol: "elg",
    name: "Escoin",
    image:
      "https://assets.coingecko.com/coins/images/13566/large/escoin-200.png?1609833886",
    current_price: 2.82,
    market_cap: 228752259,
    market_cap_rank: 145,
    fully_diluted_valuation: null,
    total_volume: 423914,
    high_24h: 2.88,
    low_24h: 2.8,
    price_change_24h: -0.05141160723736915,
    price_change_percentage_24h: -1.78964,
    market_cap_change_24h: 10734340,
    market_cap_change_percentage_24h: 4.9236,
    circulating_supply: 0,
    total_supply: 250000000,
    max_supply: null,
    ath: 3.61,
    ath_change_percentage: -21.90547,
    ath_date: "2022-06-24T02:40:08.448Z",
    atl: 0.00064644,
    atl_change_percentage: 436021.29885,
    atl_date: "2021-01-10T16:54:16.852Z",
    roi: null,
    last_updated: "2023-06-28T12:03:33.509Z",
  },
  babydoge: {
    id: "baby-doge-coin",
    symbol: "babydoge",
    name: "Baby Doge Coin",
    image:
      "https://assets.coingecko.com/coins/images/16125/large/babydoge.jpg?1676303229",
    current_price: 1.454e-9,
    market_cap: 226974076,
    market_cap_rank: 146,
    fully_diluted_valuation: 610595354,
    total_volume: 1038903,
    high_24h: 1.51e-9,
    low_24h: 1.449e-9,
    price_change_24h: -5.1487523e-11,
    price_change_percentage_24h: -3.42002,
    market_cap_change_24h: -7383782.125276923,
    market_cap_change_percentage_24h: -3.15064,
    circulating_supply: 156124856092293500,
    total_supply: 420000000000000000,
    max_supply: 420000000000000000,
    ath: 6.345e-9,
    ath_change_percentage: -77.09137,
    ath_date: "2022-01-16T20:24:43.758Z",
    atl: 9.4771e-11,
    atl_change_percentage: 1433.69087,
    atl_date: "2021-06-09T02:48:21.787Z",
    roi: null,
    last_updated: "2023-06-28T12:03:51.841Z",
  },
  ens: {
    id: "ethereum-name-service",
    symbol: "ens",
    name: "Ethereum Name Service",
    image:
      "https://assets.coingecko.com/coins/images/19785/large/acatxTm8_400x400.jpg?1635850140",
    current_price: 8.75,
    market_cap: 225769488,
    market_cap_rank: 147,
    fully_diluted_valuation: 875644588,
    total_volume: 7754545,
    high_24h: 9.03,
    low_24h: 8.73,
    price_change_24h: -0.22409713859374314,
    price_change_percentage_24h: -2.49578,
    market_cap_change_24h: -5756302.490118176,
    market_cap_change_percentage_24h: -2.48625,
    circulating_supply: 25783233.34309683,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 83.4,
    ath_change_percentage: -89.503,
    ath_date: "2021-11-11T02:20:01.099Z",
    atl: 7.05,
    atl_change_percentage: 24.17959,
    atl_date: "2023-06-15T17:25:58.700Z",
    roi: null,
    last_updated: "2023-06-28T12:03:58.619Z",
  },
  eurt: {
    id: "tether-eurt",
    symbol: "eurt",
    name: "Euro Tether",
    image:
      "https://assets.coingecko.com/coins/images/17385/large/Tether_new.png?1683650223",
    current_price: 1.094,
    market_cap: 225387067,
    market_cap_rank: 148,
    fully_diluted_valuation: 437647331,
    total_volume: 626170,
    high_24h: 1.099,
    low_24h: 1.09,
    price_change_24h: 0.0009791,
    price_change_percentage_24h: 0.08954,
    market_cap_change_24h: -60739.45400121808,
    market_cap_change_percentage_24h: -0.02694,
    circulating_supply: 205998829.98,
    total_supply: 400000050,
    max_supply: 400000050,
    ath: 1.31,
    ath_change_percentage: -16.2774,
    ath_date: "2021-09-02T00:28:44.121Z",
    atl: 0.944541,
    atl_change_percentage: 15.86558,
    atl_date: "2022-09-27T16:30:27.333Z",
    roi: null,
    last_updated: "2023-06-28T12:04:07.572Z",
  },
  dcr: {
    id: "decred",
    symbol: "dcr",
    name: "Decred",
    image:
      "https://assets.coingecko.com/coins/images/329/large/decred.png?1547034093",
    current_price: 14.67,
    market_cap: 224241884,
    market_cap_rank: 151,
    fully_diluted_valuation: 307957889,
    total_volume: 3157853,
    high_24h: 15.65,
    low_24h: 14.28,
    price_change_24h: -0.9752205033907284,
    price_change_percentage_24h: -6.23329,
    market_cap_change_24h: -31034585.319733262,
    market_cap_change_percentage_24h: -12.15724,
    circulating_supply: 15291310.0594025,
    total_supply: 21000000,
    max_supply: null,
    ath: 247.35,
    ath_change_percentage: -94.07131,
    ath_date: "2021-04-17T00:41:14.790Z",
    atl: 0.43154,
    atl_change_percentage: 3298.21993,
    atl_date: "2016-12-26T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:03:36.732Z",
  },
  celo: {
    id: "celo",
    symbol: "celo",
    name: "Celo",
    image:
      "https://assets.coingecko.com/coins/images/11090/large/InjXBNx9_400x400.jpg?1674707499",
    current_price: 0.446262,
    market_cap: 223909310,
    market_cap_rank: 149,
    fully_diluted_valuation: 446273883,
    total_volume: 5938750,
    high_24h: 0.468252,
    low_24h: 0.441566,
    price_change_24h: -0.02194653856687767,
    price_change_percentage_24h: -4.68735,
    market_cap_change_24h: -11265912.807396293,
    market_cap_change_percentage_24h: -4.79043,
    circulating_supply: 501730705,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 9.82,
    ath_change_percentage: -95.45606,
    ath_date: "2021-08-30T14:25:09.929Z",
    atl: 0.376843,
    atl_change_percentage: 18.42439,
    atl_date: "2023-06-10T04:31:15.287Z",
    roi: null,
    last_updated: "2023-06-28T12:03:45.690Z",
  },
  rvn: {
    id: "ravencoin",
    symbol: "rvn",
    name: "Ravencoin",
    image:
      "https://assets.coingecko.com/coins/images/3412/large/ravencoin.png?1548386057",
    current_price: 0.01871128,
    market_cap: 222423650,
    market_cap_rank: 150,
    fully_diluted_valuation: 222420149,
    total_volume: 6437378,
    high_24h: 0.01933777,
    low_24h: 0.01843727,
    price_change_24h: -0.000555014361215477,
    price_change_percentage_24h: -2.88075,
    market_cap_change_24h: -7097068.1973900795,
    market_cap_change_percentage_24h: -3.09213,
    circulating_supply: 11907968088.8129,
    total_supply: 11907780693.8129,
    max_supply: null,
    ath: 0.285218,
    ath_change_percentage: -93.46141,
    ath_date: "2021-02-20T11:19:07.791Z",
    atl: 0.00893232,
    atl_change_percentage: 108.78359,
    atl_date: "2020-03-13T02:30:00.004Z",
    roi: null,
    last_updated: "2023-06-28T12:04:05.541Z",
  },
  tfuel: {
    id: "theta-fuel",
    symbol: "tfuel",
    name: "Theta Fuel",
    image:
      "https://assets.coingecko.com/coins/images/8029/large/1_0YusgngOrriVg4ZYx4wOFQ.png?1553483622",
    current_price: 0.03593581,
    market_cap: 222093420,
    market_cap_rank: 152,
    fully_diluted_valuation: 222092803,
    total_volume: 2577822,
    high_24h: 0.03742287,
    low_24h: 0.03561029,
    price_change_24h: -0.000666992213827711,
    price_change_percentage_24h: -1.82224,
    market_cap_change_24h: -4269182.014178127,
    market_cap_change_percentage_24h: -1.88599,
    circulating_supply: 6189291715,
    total_supply: 6189274539,
    max_supply: null,
    ath: 0.68159,
    ath_change_percentage: -94.73518,
    ath_date: "2021-06-09T06:50:55.818Z",
    atl: 0.00090804,
    atl_change_percentage: 3851.86825,
    atl_date: "2020-03-13T02:30:37.972Z",
    roi: null,
    last_updated: "2023-06-28T12:04:00.294Z",
  },
  ksm: {
    id: "kusama",
    symbol: "ksm",
    name: "Kusama",
    image:
      "https://assets.coingecko.com/coins/images/9568/large/m4zRhP5e_400x400.jpg?1576190080",
    current_price: 24.64,
    market_cap: 221349248,
    market_cap_rank: 153,
    fully_diluted_valuation: 246488675,
    total_volume: 8924131,
    high_24h: 26,
    low_24h: 24.62,
    price_change_24h: -0.8927951443803259,
    price_change_percentage_24h: -3.49643,
    market_cap_change_24h: -7956120.66613099,
    market_cap_change_percentage_24h: -3.46966,
    circulating_supply: 8980098.074112339,
    total_supply: 10000000,
    max_supply: 10000000,
    ath: 621.71,
    ath_change_percentage: -96.0352,
    ath_date: "2021-05-18T05:04:41.858Z",
    atl: 0.876572,
    atl_change_percentage: 2712.01525,
    atl_date: "2020-01-14T19:20:20.768Z",
    roi: null,
    last_updated: "2023-06-28T12:04:04.162Z",
  },
  gmt: {
    id: "gmt-token",
    symbol: "gmt",
    name: "Gomining Token",
    image:
      "https://assets.coingecko.com/coins/images/15662/large/gmt.png?1640069038",
    current_price: 0.103112,
    market_cap: 45085810,
    market_cap_rank: 420,
    fully_diluted_valuation: 45085810,
    total_volume: 570166,
    high_24h: 0.103374,
    low_24h: 0.098456,
    price_change_24h: 0.00173565,
    price_change_percentage_24h: 1.71209,
    market_cap_change_24h: 814487,
    market_cap_change_percentage_24h: 1.83976,
    circulating_supply: 436915240,
    total_supply: 436915240,
    max_supply: 436915240,
    ath: 0.52718,
    ath_change_percentage: -80.41954,
    ath_date: "2021-10-16T12:30:04.077Z",
    atl: 0.069487,
    atl_change_percentage: 48.5523,
    atl_date: "2022-11-10T00:18:31.110Z",
    roi: null,
    last_updated: "2023-06-28T12:05:59.240Z",
  },
  wemix: {
    id: "wemix-token",
    symbol: "wemix",
    name: "WEMIX",
    image:
      "https://assets.coingecko.com/coins/images/12998/large/wemixcoin_color_200.png?1666768971",
    current_price: 0.698574,
    market_cap: 218211701,
    market_cap_rank: 155,
    fully_diluted_valuation: 673026389,
    total_volume: 4252139,
    high_24h: 0.726339,
    low_24h: 0.691877,
    price_change_24h: -0.021831556785439465,
    price_change_percentage_24h: -3.03045,
    market_cap_change_24h: -8286343.952465177,
    market_cap_change_percentage_24h: -3.65846,
    circulating_supply: 313720093.7055,
    total_supply: 967601189.144073,
    max_supply: null,
    ath: 24.71,
    ath_change_percentage: -97.18132,
    ath_date: "2021-11-21T17:33:44.178Z",
    atl: 0.128398,
    atl_change_percentage: 442.48849,
    atl_date: "2021-06-22T12:52:49.089Z",
    roi: null,
    last_updated: "2023-06-28T12:03:48.455Z",
  },
  bone: {
    id: "bone-shibaswap",
    symbol: "bone",
    name: "Bone ShibaSwap",
    image:
      "https://assets.coingecko.com/coins/images/16916/large/bone_icon.png?1625625505",
    current_price: 0.924923,
    market_cap: 212705704,
    market_cap_rank: 157,
    fully_diluted_valuation: 231278928,
    total_volume: 6319860,
    high_24h: 0.964512,
    low_24h: 0.915727,
    price_change_24h: -0.006315899430861749,
    price_change_percentage_24h: -0.67823,
    market_cap_change_24h: -1084854.3275034726,
    market_cap_change_percentage_24h: -0.50744,
    circulating_supply: 229923350.62288004,
    total_supply: 230003022.824847,
    max_supply: 250000000,
    ath: 15.5,
    ath_change_percentage: -94.02958,
    ath_date: "2021-07-07T02:52:33.198Z",
    atl: 0,
    atl_change_percentage: 1.0417787894654481e37,
    atl_date: "2021-08-12T23:02:53.007Z",
    roi: null,
    last_updated: "2023-06-28T12:04:01.181Z",
  },
  ethw: {
    id: "ethereum-pow-iou",
    symbol: "ethw",
    name: "EthereumPoW",
    image:
      "https://assets.coingecko.com/coins/images/26997/large/logo-clear.png?1661311105",
    current_price: 1.97,
    market_cap: 212641015,
    market_cap_rank: 156,
    fully_diluted_valuation: 212640569,
    total_volume: 21051385,
    high_24h: 2.13,
    low_24h: 1.93,
    price_change_24h: -0.13843104818591057,
    price_change_percentage_24h: -6.56035,
    market_cap_change_24h: -15050096.77785337,
    market_cap_change_percentage_24h: -6.60987,
    circulating_supply: 107818943.04993,
    total_supply: 107818717.04993,
    max_supply: null,
    ath: 58.54,
    ath_change_percentage: -96.5985,
    ath_date: "2022-09-03T00:10:33.722Z",
    atl: 1.27,
    atl_change_percentage: 57.19737,
    atl_date: "2023-06-10T04:30:54.783Z",
    roi: null,
    last_updated: "2023-06-28T12:04:03.140Z",
  },
  sxp: {
    id: "swipe",
    symbol: "sxp",
    name: "SXP",
    image:
      "https://assets.coingecko.com/coins/images/9368/large/swipe.png?1566792311",
    current_price: 0.364411,
    market_cap: 207764669,
    market_cap_rank: 158,
    fully_diluted_valuation: 207764238,
    total_volume: 31601040,
    high_24h: 0.391583,
    low_24h: 0.364921,
    price_change_24h: -0.00974074517212298,
    price_change_percentage_24h: -2.60342,
    market_cap_change_24h: -4920329.000495404,
    market_cap_change_percentage_24h: -2.31343,
    circulating_supply: 569212524.764335,
    total_supply: 569211343.89725,
    max_supply: null,
    ath: 5.79,
    ath_change_percentage: -93.69544,
    ath_date: "2021-05-03T15:29:39.372Z",
    atl: 0.161303,
    atl_change_percentage: 126.30209,
    atl_date: "2020-03-18T13:24:48.021Z",
    roi: null,
    last_updated: "2023-06-28T12:04:00.669Z",
  },
  jasmy: {
    id: "jasmycoin",
    symbol: "jasmy",
    name: "JasmyCoin",
    image:
      "https://assets.coingecko.com/coins/images/13876/large/JASMY200x200.jpg?1612473259",
    current_price: 0.00427769,
    market_cap: 207216136,
    market_cap_rank: 159,
    fully_diluted_valuation: 213977836,
    total_volume: 15702896,
    high_24h: 0.00458757,
    low_24h: 0.00427896,
    price_change_24h: -0.000253045553021703,
    price_change_percentage_24h: -5.58509,
    market_cap_change_24h: -11752884.571061373,
    market_cap_change_percentage_24h: -5.36737,
    circulating_supply: 48419999999.3058,
    total_supply: 50000000000,
    max_supply: 50000000000,
    ath: 4.79,
    ath_change_percentage: -99.91066,
    ath_date: "2021-02-16T03:53:32.207Z",
    atl: 0.00275026,
    atl_change_percentage: 55.60315,
    atl_date: "2022-12-29T20:41:09.113Z",
    roi: null,
    last_updated: "2023-06-28T12:04:02.572Z",
  },
  yfi: {
    id: "yearn-finance",
    symbol: "yfi",
    name: "yearn.finance",
    image:
      "https://assets.coingecko.com/coins/images/11849/large/yearn.jpg?1687142705",
    current_price: 6246.29,
    market_cap: 206533872,
    market_cap_rank: 160,
    fully_diluted_valuation: 229406181,
    total_volume: 13790930,
    high_24h: 6462.65,
    low_24h: 6224.8,
    price_change_24h: -141.57752688080382,
    price_change_percentage_24h: -2.21635,
    market_cap_change_24h: -4002046.8295116127,
    market_cap_change_percentage_24h: -1.90089,
    circulating_supply: 33010.31794125378,
    total_supply: 36666,
    max_supply: 36666,
    ath: 90787,
    ath_change_percentage: -93.00975,
    ath_date: "2021-05-12T00:29:37.713Z",
    atl: 31.65,
    atl_change_percentage: 19954.36779,
    atl_date: "2020-07-18T12:26:27.150Z",
    roi: null,
    last_updated: "2023-06-28T12:04:01.185Z",
  },
  astr: {
    id: "astar",
    symbol: "astr",
    name: "Astar",
    image:
      "https://assets.coingecko.com/coins/images/22617/large/astr.png?1642314057",
    current_price: 0.04611077,
    market_cap: 205518641,
    market_cap_rank: 161,
    fully_diluted_valuation: 369051340,
    total_volume: 4559129,
    high_24h: 0.04777707,
    low_24h: 0.04605937,
    price_change_24h: -0.001386469083456209,
    price_change_percentage_24h: -2.91905,
    market_cap_change_24h: -6384966.604869157,
    market_cap_change_percentage_24h: -3.01315,
    circulating_supply: 4457063958,
    total_supply: 8003582626,
    max_supply: null,
    ath: 0.421574,
    ath_change_percentage: -89.00675,
    ath_date: "2022-01-17T13:34:36.289Z",
    atl: 0.03269521,
    atl_change_percentage: 41.7475,
    atl_date: "2022-10-13T13:05:41.982Z",
    roi: null,
    last_updated: "2023-06-28T12:03:42.162Z",
  },
  fnsa: {
    id: "link",
    symbol: "fnsa",
    name: "FINSCHIA",
    image:
      "https://assets.coingecko.com/coins/images/6450/large/FINSCHIA_coin_icon%28black%29.png?1685058330",
    current_price: 29.48,
    market_cap: 204476877,
    market_cap_rank: 162,
    fully_diluted_valuation: 29487760338,
    total_volume: 182279,
    high_24h: 30.15,
    low_24h: 28.85,
    price_change_24h: -0.5293848461798021,
    price_change_percentage_24h: -1.7643,
    market_cap_change_24h: -3572449.26394552,
    market_cap_change_percentage_24h: -1.71712,
    circulating_supply: 6934296.63806,
    total_supply: 6934296.63806,
    max_supply: 1000000000,
    ath: 345.88,
    ath_change_percentage: -91.46779,
    ath_date: "2021-04-01T22:19:00.034Z",
    atl: 1.055,
    atl_change_percentage: 2697.31078,
    atl_date: "2018-12-26T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:04:02.609Z",
  },
  ron: {
    id: "ronin",
    symbol: "ron",
    name: "Ronin",
    image:
      "https://assets.coingecko.com/coins/images/20009/large/Ronin_Mark_Blue.png?1686729719",
    current_price: 0.888757,
    market_cap: 202123487,
    market_cap_rank: 163,
    fully_diluted_valuation: 888748655,
    total_volume: 1366298,
    high_24h: 0.912231,
    low_24h: 0.883017,
    price_change_24h: -0.021269480774551597,
    price_change_percentage_24h: -2.33724,
    market_cap_change_24h: -4693048.507697463,
    market_cap_change_percentage_24h: -2.26918,
    circulating_supply: 227424802.426598,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 4.29,
    ath_change_percentage: -79.30412,
    ath_date: "2022-01-27T13:14:36.500Z",
    atl: 0.196601,
    atl_change_percentage: 352.06179,
    atl_date: "2022-11-21T19:36:31.487Z",
    roi: null,
    last_updated: "2023-06-28T12:03:41.699Z",
  },
  waves: {
    id: "waves",
    symbol: "waves",
    name: "Waves",
    image:
      "https://assets.coingecko.com/coins/images/425/large/waves.png?1548386117",
    current_price: 2.01,
    market_cap: 200142063,
    market_cap_rank: 164,
    fully_diluted_valuation: 200142063,
    total_volume: 371558,
    high_24h: 2.22,
    low_24h: 1.99,
    price_change_24h: -0.12635478573220782,
    price_change_percentage_24h: -5.9089,
    market_cap_change_24h: -13642358.632403493,
    market_cap_change_percentage_24h: -6.38136,
    circulating_supply: 100000000,
    total_supply: 100000000,
    max_supply: null,
    ath: 61.3,
    ath_change_percentage: -96.73516,
    ath_date: "2022-03-31T14:34:44.545Z",
    atl: 0.130878,
    atl_change_percentage: 1429.22617,
    atl_date: "2016-08-02T00:00:00.000Z",
    roi: {
      times: 9.70226085869376,
      currency: "usd",
      percentage: 970.2260858693761,
    },
    last_updated: "2023-06-28T12:04:04.991Z",
  },
  icx: {
    id: "icon",
    symbol: "icx",
    name: "ICON",
    image:
      "https://assets.coingecko.com/coins/images/1060/large/icon-icx-logo.png?1547035003",
    current_price: 0.208225,
    market_cap: 199247218,
    market_cap_rank: 165,
    fully_diluted_valuation: 201470413,
    total_volume: 5079814,
    high_24h: 0.21654,
    low_24h: 0.205108,
    price_change_24h: -0.006527503755840963,
    price_change_percentage_24h: -3.03955,
    market_cap_change_24h: -7190397.607157737,
    market_cap_change_percentage_24h: -3.48308,
    circulating_supply: 960321837.719732,
    total_supply: 971037081.432516,
    max_supply: null,
    ath: 13.16,
    ath_change_percentage: -98.42399,
    ath_date: "2018-01-09T00:00:00.000Z",
    atl: 0.107465,
    atl_change_percentage: 93.06169,
    atl_date: "2020-01-03T02:14:53.139Z",
    roi: {
      times: 0.8929550944302437,
      currency: "usd",
      percentage: 89.29550944302436,
    },
    last_updated: "2023-06-28T12:04:07.385Z",
  },
  btse: {
    id: "btse-token",
    symbol: "btse",
    name: "BTSE Token",
    image:
      "https://assets.coingecko.com/coins/images/10807/large/BTSE_logo_Square.jpeg?1583965964",
    current_price: 1.22,
    market_cap: 197028521,
    market_cap_rank: 166,
    fully_diluted_valuation: 243152779,
    total_volume: 20081,
    high_24h: 1.38,
    low_24h: 1.21,
    price_change_24h: -0.14823655340944852,
    price_change_percentage_24h: -10.8659,
    market_cap_change_24h: -24140985.528070748,
    market_cap_change_percentage_24h: -10.91515,
    circulating_supply: 162061501,
    total_supply: 200000000,
    max_supply: 200000000,
    ath: 9.21,
    ath_change_percentage: -86.80611,
    ath_date: "2022-03-23T09:11:07.549Z",
    atl: 0.631269,
    atl_change_percentage: 92.59059,
    atl_date: "2020-12-13T14:22:21.597Z",
    roi: { times: -0.392, currency: "usd", percentage: -39.2 },
    last_updated: "2023-06-28T12:03:54.057Z",
  },
  cvxcrv: {
    id: "convex-crv",
    symbol: "cvxcrv",
    name: "Convex CRV",
    image:
      "https://assets.coingecko.com/coins/images/15586/large/convex-crv.png?1621255952",
    current_price: 0.666384,
    market_cap: 196159550,
    market_cap_rank: 167,
    fully_diluted_valuation: 196159550,
    total_volume: 146770,
    high_24h: 0.682348,
    low_24h: 0.662628,
    price_change_24h: -0.002617974582711291,
    price_change_percentage_24h: -0.39133,
    market_cap_change_24h: -746350.7410778403,
    market_cap_change_percentage_24h: -0.37904,
    circulating_supply: 294364101.093905,
    total_supply: 294364101.093905,
    max_supply: null,
    ath: 6.48,
    ath_change_percentage: -89.71046,
    ath_date: "2022-01-03T14:01:45.955Z",
    atl: 0.393069,
    atl_change_percentage: 69.53344,
    atl_date: "2022-11-22T11:23:02.517Z",
    roi: null,
    last_updated: "2023-06-28T12:02:38.679Z",
  },
  bal: {
    id: "balancer",
    symbol: "bal",
    name: "Balancer",
    image:
      "https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958",
    current_price: 4.66,
    market_cap: 195777466,
    market_cap_rank: 168,
    fully_diluted_valuation: 450306227,
    total_volume: 2633424,
    high_24h: 4.81,
    low_24h: 4.62,
    price_change_24h: -0.028677483446514884,
    price_change_percentage_24h: -0.6111,
    market_cap_change_24h: -270559.38888543844,
    market_cap_change_percentage_24h: -0.13801,
    circulating_supply: 41802977.8245895,
    total_supply: 57773697.3082515,
    max_supply: 96150704,
    ath: 74.45,
    ath_change_percentage: -93.70953,
    ath_date: "2021-05-04T13:35:02.939Z",
    atl: 3.66,
    atl_change_percentage: 28.1073,
    atl_date: "2022-06-18T21:00:00.231Z",
    roi: null,
    last_updated: "2023-06-28T12:03:59.966Z",
  },
  jst: {
    id: "just",
    symbol: "jst",
    name: "JUST",
    image:
      "https://assets.coingecko.com/coins/images/11095/large/JUST.jpg?1588175394",
    current_price: 0.02186516,
    market_cap: 194606166,
    market_cap_rank: 169,
    fully_diluted_valuation: 216421448,
    total_volume: 7896112,
    high_24h: 0.02214759,
    low_24h: 0.02164436,
    price_change_24h: 0.00002508,
    price_change_percentage_24h: 0.11484,
    market_cap_change_24h: 15571.6,
    market_cap_change_percentage_24h: 0.008,
    circulating_supply: 8902080000,
    total_supply: 9900000000,
    max_supply: 9900000000,
    ath: 0.193254,
    ath_change_percentage: -88.68808,
    ath_date: "2021-04-05T04:34:23.454Z",
    atl: 0.00476275,
    atl_change_percentage: 358.99459,
    atl_date: "2020-05-09T06:08:54.872Z",
    roi: null,
    last_updated: "2023-06-28T12:04:05.377Z",
  },
  ohm: {
    id: "olympus",
    symbol: "ohm",
    name: "Olympus",
    image:
      "https://assets.coingecko.com/coins/images/14483/large/token_OHM_%281%29.png?1628311611",
    current_price: 10.55,
    market_cap: 193710481,
    market_cap_rank: 170,
    fully_diluted_valuation: 280781089,
    total_volume: 385088,
    high_24h: 10.66,
    low_24h: 10.5,
    price_change_24h: -0.019065638989415486,
    price_change_percentage_24h: -0.18032,
    market_cap_change_24h: -519728.44177109003,
    market_cap_change_percentage_24h: -0.26758,
    circulating_supply: 18369445.3062715,
    total_supply: 26626297.3075772,
    max_supply: 26626297.3075772,
    ath: 1415.26,
    ath_change_percentage: -99.25449,
    ath_date: "2021-04-25T00:00:00.000Z",
    atl: 7.54,
    atl_change_percentage: 39.86484,
    atl_date: "2022-11-26T16:36:26.296Z",
    roi: null,
    last_updated: "2023-06-28T12:03:19.671Z",
  },
  luna: {
    id: "terra-luna-2",
    symbol: "luna",
    name: "Terra",
    image:
      "https://assets.coingecko.com/coins/images/25767/large/01_Luna_color.png?1653556122",
    current_price: 0.622127,
    market_cap: 188706807,
    market_cap_rank: 171,
    fully_diluted_valuation: 671205563,
    total_volume: 29625227,
    high_24h: 0.660236,
    low_24h: 0.617521,
    price_change_24h: -0.0337511830891114,
    price_change_percentage_24h: -5.14595,
    market_cap_change_24h: -10067807.4560166,
    market_cap_change_percentage_24h: -5.06494,
    circulating_supply: 303221227.422473,
    total_supply: 1078518456.25778,
    max_supply: null,
    ath: 18.87,
    ath_change_percentage: -96.69716,
    ath_date: "2022-05-28T09:18:14.847Z",
    atl: 0.517989,
    atl_change_percentage: 20.32304,
    atl_date: "2023-06-10T04:30:35.864Z",
    roi: null,
    last_updated: "2023-06-28T12:04:07.598Z",
  },
  ankr: {
    id: "ankr",
    symbol: "ankr",
    name: "Ankr Network",
    image:
      "https://assets.coingecko.com/coins/images/4324/large/U85xTl2.png?1608111978",
    current_price: 0.0228975,
    market_cap: 186493390,
    market_cap_rank: 172,
    fully_diluted_valuation: 228464645,
    total_volume: 19744560,
    high_24h: 0.02494674,
    low_24h: 0.02285756,
    price_change_24h: -0.001752537481667385,
    price_change_percentage_24h: -7.10967,
    market_cap_change_24h: -14902492.144616514,
    market_cap_change_percentage_24h: -7.3996,
    circulating_supply: 8162899377.791667,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 0.213513,
    ath_change_percentage: -89.29448,
    ath_date: "2021-04-16T19:44:04.474Z",
    atl: 0.00070728,
    atl_change_percentage: 3131.76455,
    atl_date: "2020-03-13T02:24:03.563Z",
    roi: {
      times: 2.469318376035544,
      currency: "usd",
      percentage: 246.93183760355438,
    },
    last_updated: "2023-06-28T12:03:59.185Z",
  },
  magic: {
    id: "magic",
    symbol: "magic",
    name: "Magic",
    image:
      "https://assets.coingecko.com/coins/images/18623/large/magic.png?1656052146",
    current_price: 0.855802,
    market_cap: 185959107,
    market_cap_rank: 173,
    fully_diluted_valuation: 297665947,
    total_volume: 37224336,
    high_24h: 0.938345,
    low_24h: 0.848448,
    price_change_24h: -0.05855789987159099,
    price_change_percentage_24h: -6.40425,
    market_cap_change_24h: -12598958.243140101,
    market_cap_change_percentage_24h: -6.34523,
    circulating_supply: 217225338.95266,
    total_supply: 339645510.317976,
    max_supply: 347714007,
    ath: 6.32,
    ath_change_percentage: -86.463,
    ath_date: "2022-02-19T16:24:53.615Z",
    atl: 0.01999252,
    atl_change_percentage: 4181.72365,
    atl_date: "2021-10-07T01:57:51.729Z",
    roi: null,
    last_updated: "2023-06-28T12:03:59.232Z",
  },
  audio: {
    id: "audius",
    symbol: "audio",
    name: "Audius",
    image:
      "https://assets.coingecko.com/coins/images/12913/large/AudiusCoinLogo_2x.png?1603425727",
    current_price: 0.17613,
    market_cap: 185295708,
    market_cap_rank: 174,
    fully_diluted_valuation: 206395527,
    total_volume: 10005889,
    high_24h: 0.191544,
    low_24h: 0.17518,
    price_change_24h: -0.014872228099465784,
    price_change_percentage_24h: -7.78643,
    market_cap_change_24h: -15794387.030797988,
    market_cap_change_percentage_24h: -7.85438,
    circulating_supply: 1051967676,
    total_supply: 1171756356.3474,
    max_supply: 1171756356.3474,
    ath: 4.95,
    ath_change_percentage: -96.44497,
    ath_date: "2021-03-27T15:45:35.137Z",
    atl: 0.0437371,
    atl_change_percentage: 302.73426,
    atl_date: "2020-10-23T14:56:23.999Z",
    roi: null,
    last_updated: "2023-06-28T12:04:05.017Z",
  },
  iotx: {
    id: "iotex",
    symbol: "iotx",
    name: "IoTeX",
    image:
      "https://assets.coingecko.com/coins/images/3334/large/iotex-logo.png?1547037941",
    current_price: 0.01918736,
    market_cap: 181103326,
    market_cap_rank: 175,
    fully_diluted_valuation: 182061321,
    total_volume: 2963289,
    high_24h: 0.02012624,
    low_24h: 0.0190263,
    price_change_24h: -0.000680398482233453,
    price_change_percentage_24h: -3.42464,
    market_cap_change_24h: -6832439.297944188,
    market_cap_change_percentage_24h: -3.63552,
    circulating_supply: 9446298072,
    total_supply: 9496266827.32,
    max_supply: 9496266827.32,
    ath: 0.255593,
    ath_change_percentage: -92.49951,
    ath_date: "2021-11-13T16:54:18.643Z",
    atl: 0.00121576,
    atl_change_percentage: 1476.85112,
    atl_date: "2020-03-13T02:29:47.597Z",
    roi: null,
    last_updated: "2023-06-28T12:03:55.837Z",
  },
  glm: {
    id: "golem",
    symbol: "glm",
    name: "Golem",
    image:
      "https://assets.coingecko.com/coins/images/542/large/Golem_Submark_Positive_RGB.png?1606392013",
    current_price: 0.180969,
    market_cap: 180985092,
    market_cap_rank: 176,
    fully_diluted_valuation: 180985092,
    total_volume: 4108949,
    high_24h: 0.194483,
    low_24h: 0.179486,
    price_change_24h: -0.005352054739735991,
    price_change_percentage_24h: -2.87249,
    market_cap_change_24h: -7227023.874856204,
    market_cap_change_percentage_24h: -3.83983,
    circulating_supply: 1000000000,
    total_supply: 1000000000,
    max_supply: null,
    ath: 1.32,
    ath_change_percentage: -86.33359,
    ath_date: "2018-04-13T09:13:22.739Z",
    atl: 0.00913753,
    atl_change_percentage: 1880.6785,
    atl_date: "2016-12-12T00:00:00.000Z",
    roi: {
      times: 17.09692418623305,
      currency: "usd",
      percentage: 1709.692418623305,
    },
    last_updated: "2023-06-28T12:03:55.155Z",
  },
  "btc.b": {
    id: "bitcoin-avalanche-bridged-btc-b",
    symbol: "btc.b",
    name: "Bitcoin Avalanche Bridged (BTC.b)",
    image:
      "https://assets.coingecko.com/coins/images/26115/large/btcb.png?1655921693",
    current_price: 30347,
    market_cap: 179412170,
    market_cap_rank: 177,
    fully_diluted_valuation: 179412170,
    total_volume: 7918119,
    high_24h: 30840,
    low_24h: 29995,
    price_change_24h: -331.9773764414422,
    price_change_percentage_24h: -1.08209,
    market_cap_change_24h: -2841830.067873448,
    market_cap_change_percentage_24h: -1.55927,
    circulating_supply: 5911.94301349,
    total_supply: 5911.94301349,
    max_supply: null,
    ath: 42442,
    ath_change_percentage: -28.52122,
    ath_date: "2023-01-16T22:36:24.558Z",
    atl: 7806.11,
    atl_change_percentage: 288.62765,
    atl_date: "2023-03-03T05:01:42.603Z",
    roi: null,
    last_updated: "2023-06-28T12:03:26.386Z",
  },
  ont: {
    id: "ontology",
    symbol: "ont",
    name: "Ontology",
    image:
      "https://assets.coingecko.com/coins/images/3447/large/ONT.png?1583481820",
    current_price: 0.195279,
    market_cap: 173584498,
    market_cap_rank: 178,
    fully_diluted_valuation: 196122314,
    total_volume: 44123692,
    high_24h: 0.212933,
    low_24h: 0.191628,
    price_change_24h: 0.00365119,
    price_change_percentage_24h: 1.90535,
    market_cap_change_24h: 3938125,
    market_cap_change_percentage_24h: 2.32137,
    circulating_supply: 885082857,
    total_supply: 1000000000,
    max_supply: null,
    ath: 10.92,
    ath_change_percentage: -98.20339,
    ath_date: "2018-05-03T01:43:05.844Z",
    atl: 0.145208,
    atl_change_percentage: 35.11527,
    atl_date: "2022-12-30T07:46:17.182Z",
    roi: null,
    last_updated: "2023-06-28T12:04:07.264Z",
  },
  omi: {
    id: "ecomi",
    symbol: "omi",
    name: "ECOMI",
    image:
      "https://assets.coingecko.com/coins/images/4428/large/ECOMI.png?1557928886",
    current_price: 0.00064169,
    market_cap: 173176025,
    market_cap_rank: 179,
    fully_diluted_valuation: 480534209,
    total_volume: 754203,
    high_24h: 0.00064884,
    low_24h: 0.00062196,
    price_change_24h: 0.00001973,
    price_change_percentage_24h: 3.17233,
    market_cap_change_24h: 5107514,
    market_cap_change_percentage_24h: 3.03895,
    circulating_supply: 270286727462.842,
    total_supply: 310991740480.432,
    max_supply: 750000000000,
    ath: 0.01343572,
    ath_change_percentage: -95.22963,
    ath_date: "2021-03-19T17:20:53.999Z",
    atl: 0.00000337,
    atl_change_percentage: 18913.43383,
    atl_date: "2019-11-04T10:52:42.729Z",
    roi: {
      times: 1.114720745480971,
      currency: "btc",
      percentage: 111.4720745480971,
    },
    last_updated: "2023-06-28T12:03:38.547Z",
  },
  zrx: {
    id: "0x",
    symbol: "zrx",
    name: "0x Protocol",
    image:
      "https://assets.coingecko.com/coins/images/863/large/0x.png?1547034672",
    current_price: 0.203713,
    market_cap: 172693787,
    market_cap_rank: 180,
    fully_diluted_valuation: 203769429,
    total_volume: 12965109,
    high_24h: 0.218661,
    low_24h: 0.20335,
    price_change_24h: -0.008000896244927669,
    price_change_percentage_24h: -3.7791,
    market_cap_change_24h: -6549804.3160686195,
    market_cap_change_percentage_24h: -3.65414,
    circulating_supply: 847496054.8122635,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 2.5,
    ath_change_percentage: -91.83955,
    ath_date: "2018-01-13T00:00:00.000Z",
    atl: 0.120667,
    atl_change_percentage: 68.86329,
    atl_date: "2020-03-13T02:27:49.563Z",
    roi: {
      times: 3.244031190034906,
      currency: "usd",
      percentage: 324.40311900349064,
    },
    last_updated: "2023-06-28T12:04:01.392Z",
  },
  hnt: {
    id: "helium",
    symbol: "hnt",
    name: "Helium",
    image:
      "https://assets.coingecko.com/coins/images/4284/large/Helium_HNT.png?1612620071",
    current_price: 1.19,
    market_cap: 171786322,
    market_cap_rank: 181,
    fully_diluted_valuation: 266150676,
    total_volume: 505896,
    high_24h: 1.24,
    low_24h: 1.19,
    price_change_24h: -0.029549931928743556,
    price_change_percentage_24h: -2.41621,
    market_cap_change_24h: -4318325.639988989,
    market_cap_change_percentage_24h: -2.45214,
    circulating_supply: 143934820.830308,
    total_supply: 223000000,
    max_supply: 223000000,
    ath: 54.88,
    ath_change_percentage: -97.82474,
    ath_date: "2021-11-12T23:08:25.301Z",
    atl: 0.113248,
    atl_change_percentage: 954.0786,
    atl_date: "2020-04-18T00:19:10.902Z",
    roi: null,
    last_updated: "2023-06-28T12:03:39.840Z",
  },
  glmr: {
    id: "moonbeam",
    symbol: "glmr",
    name: "Moonbeam",
    image:
      "https://assets.coingecko.com/coins/images/22459/large/glmr.png?1641880985",
    current_price: 0.255022,
    market_cap: 170714225,
    market_cap_rank: 182,
    fully_diluted_valuation: 273808007,
    total_volume: 2562911,
    high_24h: 0.266145,
    low_24h: 0.254543,
    price_change_24h: -0.008610218318016505,
    price_change_percentage_24h: -3.266,
    market_cap_change_24h: -5716158.529216945,
    market_cap_change_percentage_24h: -3.23989,
    circulating_supply: 669298279,
    total_supply: 1073485401,
    max_supply: null,
    ath: 19.5,
    ath_change_percentage: -98.69194,
    ath_date: "2022-01-11T14:57:46.115Z",
    atl: 0.207702,
    atl_change_percentage: 22.80437,
    atl_date: "2023-06-15T17:41:01.587Z",
    roi: null,
    last_updated: "2023-06-28T12:04:07.225Z",
  },
  alusd: {
    id: "alchemix-usd",
    symbol: "alusd",
    name: "Alchemix USD",
    image:
      "https://assets.coingecko.com/coins/images/14114/large/Alchemix_USD.png?1614410406",
    current_price: 0.99494,
    market_cap: 170611767,
    market_cap_rank: 183,
    fully_diluted_valuation: 170611767,
    total_volume: 9335.25,
    high_24h: 0.999135,
    low_24h: 0.992409,
    price_change_24h: -0.001464974608007541,
    price_change_percentage_24h: -0.14703,
    market_cap_change_24h: -218821.28320991993,
    market_cap_change_percentage_24h: -0.12809,
    circulating_supply: 171534991.556008,
    total_supply: 171534991.556008,
    max_supply: null,
    ath: 2.13,
    ath_change_percentage: -53.23113,
    ath_date: "2021-03-21T19:53:32.860Z",
    atl: 0.060239,
    atl_change_percentage: 1551.6425,
    atl_date: "2021-03-19T22:40:28.697Z",
    roi: null,
    last_updated: "2023-06-28T12:02:38.080Z",
  },
  sfp: {
    id: "safepal",
    symbol: "sfp",
    name: "SafePal",
    image:
      "https://assets.coingecko.com/coins/images/13905/large/sfp.png?1660381192",
    current_price: 0.460121,
    market_cap: 170130495,
    market_cap_rank: 184,
    fully_diluted_valuation: 230035598,
    total_volume: 2538944,
    high_24h: 0.467934,
    low_24h: 0.453066,
    price_change_24h: 0.00013469,
    price_change_percentage_24h: 0.02928,
    market_cap_change_24h: 31541,
    market_cap_change_percentage_24h: 0.01854,
    circulating_supply: 369791669,
    total_supply: 500000000,
    max_supply: 500000000,
    ath: 4.19,
    ath_change_percentage: -89.0131,
    ath_date: "2021-02-09T18:30:34.250Z",
    atl: 0.269235,
    atl_change_percentage: 70.88245,
    atl_date: "2022-06-14T02:06:50.102Z",
    roi: null,
    last_updated: "2023-06-28T12:03:31.778Z",
  },
  gfarm2: {
    id: "gains-farm",
    symbol: "gfarm2",
    name: "Gains Farm",
    image:
      "https://assets.coingecko.com/coins/images/13703/large/gfarm_v2.png?1611035398",
    current_price: 4481.43,
    market_cap: 160586526,
    market_cap_rank: 185,
    fully_diluted_valuation: 160586526,
    total_volume: 448.02,
    high_24h: null,
    low_24h: null,
    price_change_24h: null,
    price_change_percentage_24h: null,
    market_cap_change_24h: null,
    market_cap_change_percentage_24h: null,
    circulating_supply: 35833.7662150699,
    total_supply: 35833.7662150699,
    max_supply: 35833.7662150699,
    ath: 11444.33,
    ath_change_percentage: -60.84147,
    ath_date: "2023-02-17T08:10:37.065Z",
    atl: 18.04,
    atl_change_percentage: 24748.38266,
    atl_date: "2021-01-26T16:14:36.262Z",
    roi: null,
    last_updated: "2023-06-26T08:48:24.505Z",
  },
  ant: {
    id: "aragon",
    symbol: "ant",
    name: "Aragon",
    image:
      "https://assets.coingecko.com/coins/images/681/large/Avatar_Circle_x6.png?1685684270",
    current_price: 3.98,
    market_cap: 159692208,
    market_cap_rank: 186,
    fully_diluted_valuation: 179894312,
    total_volume: 9186289,
    high_24h: 4.07,
    low_24h: 3.88,
    price_change_24h: 0.0112625,
    price_change_percentage_24h: 0.2835,
    market_cap_change_24h: 403284,
    market_cap_change_percentage_24h: 0.25318,
    circulating_supply: 40030814.330505036,
    total_supply: 43167647.3965443,
    max_supply: 45094973,
    ath: 14.64,
    ath_change_percentage: -72.74696,
    ath_date: "2021-04-06T09:35:37.795Z",
    atl: 0.301888,
    atl_change_percentage: 1221.42968,
    atl_date: "2018-11-27T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:04:06.400Z",
  },
  krd: {
    id: "krypton-dao",
    symbol: "krd",
    name: "Krypton DAO",
    image:
      "https://assets.coingecko.com/coins/images/25467/large/krd.png?1651915442",
    current_price: 1.53,
    market_cap: 156474199,
    market_cap_rank: 192,
    fully_diluted_valuation: 15272163711,
    total_volume: 56685,
    high_24h: 2.02,
    low_24h: 1.33,
    price_change_24h: -0.005495198989051265,
    price_change_percentage_24h: -0.35853,
    market_cap_change_24h: -561278.1551297605,
    market_cap_change_percentage_24h: -0.35742,
    circulating_supply: 102457125,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 4.04,
    ath_change_percentage: -63.64539,
    ath_date: "2023-05-31T00:21:03.622Z",
    atl: 0.153898,
    atl_change_percentage: 853.70029,
    atl_date: "2022-08-18T07:19:22.184Z",
    roi: null,
    last_updated: "2023-06-28T12:03:21.956Z",
  },
  iost: {
    id: "iostoken",
    symbol: "iost",
    name: "IOST",
    image:
      "https://assets.coingecko.com/coins/images/2523/large/IOST.png?1557555183",
    current_price: 0.00833228,
    market_cap: 156112277,
    market_cap_rank: 187,
    fully_diluted_valuation: 750985322,
    total_volume: 6526531,
    high_24h: 0.00879988,
    low_24h: 0.00827791,
    price_change_24h: -0.000326266025954501,
    price_change_percentage_24h: -3.76814,
    market_cap_change_24h: -5810867.229417175,
    market_cap_change_percentage_24h: -3.58866,
    circulating_supply: 18708894189,
    total_supply: 18708894189,
    max_supply: 90000000000,
    ath: 0.129829,
    ath_change_percentage: -93.57102,
    ath_date: "2018-01-24T00:00:00.000Z",
    atl: 0.00160309,
    atl_change_percentage: 420.6608,
    atl_date: "2020-03-13T02:25:35.257Z",
    roi: null,
    last_updated: "2023-06-28T12:03:55.695Z",
  },
  sc: {
    id: "siacoin",
    symbol: "sc",
    name: "Siacoin",
    image:
      "https://assets.coingecko.com/coins/images/289/large/siacoin.png?1548386465",
    current_price: 0.00302436,
    market_cap: 155822807,
    market_cap_rank: 189,
    fully_diluted_valuation: null,
    total_volume: 2141294,
    high_24h: 0.00312697,
    low_24h: 0.00299446,
    price_change_24h: -0.000039913074455945,
    price_change_percentage_24h: -1.30253,
    market_cap_change_24h: -1865407.877419144,
    market_cap_change_percentage_24h: -1.18297,
    circulating_supply: 51477015000,
    total_supply: null,
    max_supply: null,
    ath: 0.092868,
    ath_change_percentage: -96.74049,
    ath_date: "2018-01-06T00:00:00.000Z",
    atl: 0.00001262,
    atl_change_percentage: 23883.67014,
    atl_date: "2015-12-28T00:00:00.000Z",
    roi: {
      times: -0.5253809335269194,
      currency: "btc",
      percentage: -52.538093352691945,
    },
    last_updated: "2023-06-28T12:03:56.553Z",
  },
  band: {
    id: "band-protocol",
    symbol: "band",
    name: "Band Protocol",
    image:
      "https://assets.coingecko.com/coins/images/9545/large/Band_token_blue_violet_token.png?1625881431",
    current_price: 1.22,
    market_cap: 155587919,
    market_cap_rank: 188,
    fully_diluted_valuation: 166365798,
    total_volume: 5242108,
    high_24h: 1.3,
    low_24h: 1.21,
    price_change_24h: -0.06453663996457859,
    price_change_percentage_24h: -5.01092,
    market_cap_change_24h: -7962188.520192087,
    market_cap_change_percentage_24h: -4.86835,
    circulating_supply: 127028660.643916,
    total_supply: 135828184.151215,
    max_supply: null,
    ath: 22.83,
    ath_change_percentage: -94.63438,
    ath_date: "2021-04-15T12:04:11.270Z",
    atl: 0.203625,
    atl_change_percentage: 501.56146,
    atl_date: "2019-11-25T05:49:22.765Z",
    roi: {
      times: 1.5864350200201416,
      currency: "usd",
      percentage: 158.64350200201417,
    },
    last_updated: "2023-06-28T12:04:01.052Z",
  },
  one: {
    id: "harmony",
    symbol: "one",
    name: "Harmony",
    image:
      "https://assets.coingecko.com/coins/images/4344/large/Y88JAze.png?1565065793",
    current_price: 0.01267558,
    market_cap: 155080403,
    market_cap_rank: 190,
    fully_diluted_valuation: 165800414,
    total_volume: 9125764,
    high_24h: 0.01325996,
    low_24h: 0.01254669,
    price_change_24h: -0.000478153770807168,
    price_change_percentage_24h: -3.63512,
    market_cap_change_24h: -6700210.273992956,
    market_cap_change_percentage_24h: -4.14154,
    circulating_supply: 12305426115.0007,
    total_supply: 13156044839.793139,
    max_supply: 13156044839.793139,
    ath: 0.37902,
    ath_change_percentage: -96.67483,
    ath_date: "2021-10-26T09:28:49.782Z",
    atl: 0.00127355,
    atl_change_percentage: 889.60144,
    atl_date: "2020-03-13T02:24:14.005Z",
    roi: {
      times: 2.992307495614863,
      currency: "usd",
      percentage: 299.2307495614863,
    },
    last_updated: "2023-06-28T12:03:49.567Z",
  },
  waxp: {
    id: "wax",
    symbol: "waxp",
    name: "WAX",
    image:
      "https://assets.coingecko.com/coins/images/1372/large/WAX_Coin_Tickers_P_512px.png?1602812260",
    current_price: 0.04618459,
    market_cap: 154722907,
    market_cap_rank: 191,
    fully_diluted_valuation: 175652219,
    total_volume: 785267,
    high_24h: 0.04867391,
    low_24h: 0.04622409,
    price_change_24h: -0.001530909921712648,
    price_change_percentage_24h: -3.20841,
    market_cap_change_24h: -3734547.6692625284,
    market_cap_change_percentage_24h: -2.35681,
    circulating_supply: 3321064165.28468,
    total_supply: 3770303327,
    max_supply: 3770303327,
    ath: 2.77,
    ath_change_percentage: -98.3191,
    ath_date: "2018-01-09T00:00:00.000Z",
    atl: 0.01594711,
    atl_change_percentage: 192.14774,
    atl_date: "2019-12-30T16:39:58.981Z",
    roi: {
      times: -0.8556731485793738,
      currency: "usd",
      percentage: -85.56731485793738,
    },
    last_updated: "2023-06-28T12:03:49.186Z",
  },
  cdt: {
    id: "blox",
    symbol: "cdt",
    name: "Blox",
    image:
      "https://assets.coingecko.com/coins/images/1231/large/Blox_Staking_Logo_2.png?1609117544",
    current_price: 0.227299,
    market_cap: 153339920,
    market_cap_rank: 209,
    fully_diluted_valuation: 227311965,
    total_volume: 171372,
    high_24h: 0.228967,
    low_24h: 0.184244,
    price_change_24h: 0.02101211,
    price_change_percentage_24h: 10.18587,
    market_cap_change_24h: 14340609,
    market_cap_change_percentage_24h: 10.31704,
    circulating_supply: 674579184.17,
    total_supply: 1000000000,
    max_supply: null,
    ath: 0.502216,
    ath_change_percentage: -54.73817,
    ath_date: "2023-03-21T14:15:03.845Z",
    atl: 0.00164788,
    atl_change_percentage: 13694.18779,
    atl_date: "2020-03-13T02:24:15.284Z",
    roi: {
      times: -0.2561106020331934,
      currency: "eth",
      percentage: -25.61106020331934,
    },
    last_updated: "2023-06-28T12:04:01.653Z",
  },
  lyxe: {
    id: "lukso-token",
    symbol: "lyxe",
    name: "LUKSO",
    image:
      "https://assets.coingecko.com/coins/images/11423/large/1_QAHTciwVhD7SqVmfRW70Pw.png?1590110612",
    current_price: 9.66,
    market_cap: 150311312,
    market_cap_rank: 193,
    fully_diluted_valuation: 965023917,
    total_volume: 509181,
    high_24h: 10.02,
    low_24h: 9.56,
    price_change_24h: -0.2589841220369582,
    price_change_percentage_24h: -2.61125,
    market_cap_change_24h: -4162772.042702645,
    market_cap_change_percentage_24h: -2.6948,
    circulating_supply: 15575915.708856644,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 41.18,
    ath_change_percentage: -76.56468,
    ath_date: "2021-11-04T07:31:04.430Z",
    atl: 0.203056,
    atl_change_percentage: 4652.50504,
    atl_date: "2020-07-18T10:14:36.612Z",
    roi: null,
    last_updated: "2023-06-28T12:03:51.228Z",
  },
  tomo: {
    id: "tomochain",
    symbol: "tomo",
    name: "TomoChain",
    image:
      "https://assets.coingecko.com/coins/images/3416/large/Asset_59_2x.png?1582948925",
    current_price: 1.53,
    market_cap: 148206351,
    market_cap_rank: 194,
    fully_diluted_valuation: 153474385,
    total_volume: 13372493,
    high_24h: 1.58,
    low_24h: 1.44,
    price_change_24h: -0.03136921689168015,
    price_change_percentage_24h: -2.00521,
    market_cap_change_24h: -2455095.881955683,
    market_cap_change_percentage_24h: -1.62954,
    circulating_supply: 96567483.7777778,
    total_supply: 100000000,
    max_supply: null,
    ath: 3.88,
    ath_change_percentage: -60.41941,
    ath_date: "2021-09-06T10:14:46.160Z",
    atl: 0.139955,
    atl_change_percentage: 996.9501,
    atl_date: "2020-03-13T02:24:15.954Z",
    roi: {
      times: 5.132070889900808,
      currency: "usd",
      percentage: 513.2070889900808,
    },
    last_updated: "2023-06-28T12:03:49.712Z",
  },
  seth2: {
    id: "seth2",
    symbol: "seth2",
    name: "sETH2",
    image:
      "https://assets.coingecko.com/coins/images/16569/large/emerald256.png?1624494960",
    current_price: 1849.71,
    market_cap: 146573841,
    market_cap_rank: 195,
    fully_diluted_valuation: 146573841,
    total_volume: 82775,
    high_24h: 1896.86,
    low_24h: 1838.61,
    price_change_24h: -27.27271244123176,
    price_change_percentage_24h: -1.45301,
    market_cap_change_24h: -2116821.6001265645,
    market_cap_change_percentage_24h: -1.42364,
    circulating_supply: 79241.422023302,
    total_supply: 79241.422023302,
    max_supply: null,
    ath: 4821.87,
    ath_change_percentage: -61.63911,
    ath_date: "2021-11-10T14:23:55.647Z",
    atl: 887.7,
    atl_change_percentage: 108.3718,
    atl_date: "2022-06-18T20:57:32.629Z",
    roi: null,
    last_updated: "2023-06-28T12:02:43.685Z",
  },
  dao: {
    id: "dao-maker",
    symbol: "dao",
    name: "DAO Maker",
    image:
      "https://assets.coingecko.com/coins/images/13915/large/4.png?1612838831",
    current_price: 0.870005,
    market_cap: 144977511,
    market_cap_rank: 196,
    fully_diluted_valuation: 240779516,
    total_volume: 554623,
    high_24h: 0.883464,
    low_24h: 0.86261,
    price_change_24h: -0.0091906160430445,
    price_change_percentage_24h: -1.04534,
    market_cap_change_24h: -2221627.0258176923,
    market_cap_change_percentage_24h: -1.50927,
    circulating_supply: 167102000,
    total_supply: 277524000,
    max_supply: 277524000,
    ath: 8.71,
    ath_change_percentage: -90.04044,
    ath_date: "2021-04-21T17:13:06.858Z",
    atl: 0.764723,
    atl_change_percentage: 13.45275,
    atl_date: "2022-11-14T03:16:24.229Z",
    roi: null,
    last_updated: "2023-06-28T12:03:47.348Z",
  },
  ordi: {
    id: "ordinals",
    symbol: "ordi",
    name: "ORDI",
    image:
      "https://assets.coingecko.com/coins/images/30162/large/ordi.png?1683527689",
    current_price: 6.81,
    market_cap: 143366454,
    market_cap_rank: 197,
    fully_diluted_valuation: 143366454,
    total_volume: 17509069,
    high_24h: 7.56,
    low_24h: 6.75,
    price_change_24h: -0.7353881197528311,
    price_change_percentage_24h: -9.75016,
    market_cap_change_24h: -13875013.359817177,
    market_cap_change_percentage_24h: -8.82402,
    circulating_supply: 21000000,
    total_supply: 21000000,
    max_supply: null,
    ath: 28.52,
    ath_change_percentage: -76.08475,
    ath_date: "2023-05-08T10:00:30.880Z",
    atl: 4.67,
    atl_change_percentage: 45.97331,
    atl_date: "2023-06-12T04:00:24.633Z",
    roi: null,
    last_updated: "2023-06-28T12:03:40.952Z",
  },
  ocean: {
    id: "ocean-protocol",
    symbol: "ocean",
    name: "Ocean Protocol",
    image:
      "https://assets.coingecko.com/coins/images/3687/large/ocean-protocol-logo.jpg?1547038686",
    current_price: 0.326303,
    market_cap: 141800705,
    market_cap_rank: 198,
    fully_diluted_valuation: 460660441,
    total_volume: 13165598,
    high_24h: 0.343688,
    low_24h: 0.324313,
    price_change_24h: -0.012381784675032427,
    price_change_percentage_24h: -3.65585,
    market_cap_change_24h: -5162522.850188434,
    market_cap_change_percentage_24h: -3.5128,
    circulating_supply: 434026836.76781356,
    total_supply: 1410000000,
    max_supply: 1410000000,
    ath: 1.93,
    ath_change_percentage: -83.06346,
    ath_date: "2021-04-10T09:24:58.915Z",
    atl: 0.01284832,
    atl_change_percentage: 2444.06755,
    atl_date: "2019-08-11T00:00:00.000Z",
    roi: {
      times: 1.7191885922971641,
      currency: "usd",
      percentage: 171.91885922971642,
    },
    last_updated: "2023-06-28T12:03:59.223Z",
  },
  kda: {
    id: "kadena",
    symbol: "kda",
    name: "Kadena",
    image:
      "https://assets.coingecko.com/coins/images/3693/large/Logo.png?1686042789",
    current_price: 0.593645,
    market_cap: 139922256,
    market_cap_rank: 199,
    fully_diluted_valuation: 593568624,
    total_volume: 2908250,
    high_24h: 0.633792,
    low_24h: 0.588256,
    price_change_24h: -0.03445519079058312,
    price_change_percentage_24h: -5.48562,
    market_cap_change_24h: -8114261.838452995,
    market_cap_change_percentage_24h: -5.48126,
    circulating_supply: 235730545.60852,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 27.64,
    ath_change_percentage: -97.85155,
    ath_date: "2021-11-11T13:22:45.731Z",
    atl: 0.065811,
    atl_change_percentage: 802.1843,
    atl_date: "2020-03-13T02:00:28.582Z",
    roi: {
      times: -0.4063547157547378,
      currency: "usd",
      percentage: -40.63547157547378,
    },
    last_updated: "2023-06-28T12:04:06.300Z",
  },
  cusdt: {
    id: "compound-usdt",
    symbol: "cusdt",
    name: "cUSDT",
    image:
      "https://assets.coingecko.com/coins/images/11621/large/cUSDT.png?1592113270",
    current_price: 0.01404119,
    market_cap: 138894541,
    market_cap_rank: 201,
    fully_diluted_valuation: 138894541,
    total_volume: 3.01,
    high_24h: 0.01407416,
    low_24h: 0.01400774,
    price_change_24h: 0.00000303,
    price_change_percentage_24h: 0.02159,
    market_cap_change_24h: -39198699.02605459,
    market_cap_change_percentage_24h: -22.01021,
    circulating_supply: 9896348304.15472,
    total_supply: 9896348304.15472,
    max_supply: null,
    ath: 0.02468599,
    ath_change_percentage: -43.14618,
    ath_date: "2022-11-10T12:22:53.410Z",
    atl: 0.00209672,
    atl_change_percentage: 569.37501,
    atl_date: "2022-11-21T00:54:17.922Z",
    roi: null,
    last_updated: "2023-06-28T12:03:23.666Z",
  },
  bico: {
    id: "biconomy",
    symbol: "bico",
    name: "Biconomy",
    image:
      "https://assets.coingecko.com/coins/images/21061/large/biconomy_logo.jpg?1638269749",
    current_price: 0.239729,
    market_cap: 136651897,
    market_cap_rank: 202,
    fully_diluted_valuation: 240174625,
    total_volume: 1277832,
    high_24h: 0.253448,
    low_24h: 0.240112,
    price_change_24h: -0.010775320677371247,
    price_change_percentage_24h: -4.30145,
    market_cap_change_24h: -5932733.663882077,
    market_cap_change_percentage_24h: -4.16085,
    circulating_supply: 568968921.353889,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 21.45,
    ath_change_percentage: -98.88044,
    ath_date: "2021-12-02T02:18:45.254Z",
    atl: 0.203797,
    atl_change_percentage: 17.81833,
    atl_date: "2023-06-15T11:41:48.028Z",
    roi: null,
    last_updated: "2023-06-28T12:05:27.073Z",
  },
  flux: {
    id: "zelcash",
    symbol: "flux",
    name: "Flux",
    image:
      "https://assets.coingecko.com/coins/images/5163/large/Flux_symbol_blue-white.png?1617192144",
    current_price: 0.457321,
    market_cap: 136606363,
    market_cap_rank: 203,
    fully_diluted_valuation: 201433609,
    total_volume: 5475200,
    high_24h: 0.479345,
    low_24h: 0.454752,
    price_change_24h: -0.015732728136195695,
    price_change_percentage_24h: -3.32578,
    market_cap_change_24h: -4113877.7119695544,
    market_cap_change_percentage_24h: -2.92344,
    circulating_supply: 298395090.099319,
    total_supply: 440000000,
    max_supply: null,
    ath: 3.33,
    ath_change_percentage: -86.23128,
    ath_date: "2022-01-03T10:18:02.680Z",
    atl: 0.01640374,
    atl_change_percentage: 2691.60997,
    atl_date: "2021-01-13T05:29:57.987Z",
    roi: null,
    last_updated: "2023-06-28T12:05:27.493Z",
  },
  eurs: {
    id: "stasis-eurs",
    symbol: "eurs",
    name: "STASIS EURO",
    image:
      "https://assets.coingecko.com/coins/images/5164/large/EURS_300x300.png?1550571779",
    current_price: 1.094,
    market_cap: 135848631,
    market_cap_rank: 204,
    fully_diluted_valuation: 135848631,
    total_volume: 5169896,
    high_24h: 1.1,
    low_24h: 1.084,
    price_change_24h: -0.00139687166403113,
    price_change_percentage_24h: -0.12748,
    market_cap_change_24h: -163677.54959446192,
    market_cap_change_percentage_24h: -0.12034,
    circulating_supply: 124125940,
    total_supply: 124125940,
    max_supply: 124125940,
    ath: 1.79,
    ath_change_percentage: -38.94338,
    ath_date: "2023-03-14T12:00:06.279Z",
    atl: 0.929636,
    atl_change_percentage: 17.77209,
    atl_date: "2022-11-10T13:44:54.149Z",
    roi: null,
    last_updated: "2023-06-28T12:05:07.145Z",
  },
  bora: {
    id: "bora",
    symbol: "bora",
    name: "BORA",
    image:
      "https://assets.coingecko.com/coins/images/7646/large/mqFw8hxm_400x400.jpeg?1656657343",
    current_price: 0.134157,
    market_cap: 134077949,
    market_cap_rank: 205,
    fully_diluted_valuation: 162681244,
    total_volume: 1963319,
    high_24h: 0.141673,
    low_24h: 0.134283,
    price_change_24h: -0.006301623066564149,
    price_change_percentage_24h: -4.48646,
    market_cap_change_24h: -5430691.824110746,
    market_cap_change_percentage_24h: -3.89273,
    circulating_supply: 993750000,
    total_supply: 1205750000,
    max_supply: 1205750000,
    ath: 1.61,
    ath_change_percentage: -91.618,
    ath_date: "2021-11-25T00:10:02.143Z",
    atl: 0.00482732,
    atl_change_percentage: 2694.9567,
    atl_date: "2020-02-15T17:49:05.317Z",
    roi: null,
    last_updated: "2023-06-28T12:05:09.114Z",
  },
  mav: {
    id: "maverick-protocol",
    symbol: "mav",
    name: "Maverick Protocol",
    image:
      "https://assets.coingecko.com/coins/images/30850/large/MAV_Logo.png?1687945366",
    current_price: 0.533724,
    market_cap: 133522234,
    market_cap_rank: 206,
    fully_diluted_valuation: 1068177875,
    total_volume: 95148821,
    high_24h: 0.578711,
    low_24h: 0.521837,
    price_change_24h: 0.01130486,
    price_change_percentage_24h: 2.16394,
    market_cap_change_24h: 133522234,
    market_cap_change_percentage_24h: 0,
    circulating_supply: 250000000,
    total_supply: 2000000000,
    max_supply: 2000000000,
    ath: 0.578711,
    ath_change_percentage: -7.94921,
    ath_date: "2023-06-28T10:34:47.137Z",
    atl: 0.521837,
    atl_change_percentage: 2.08338,
    atl_date: "2023-06-28T09:55:02.985Z",
    roi: null,
    last_updated: "2023-06-28T12:05:13.854Z",
  },
  wbeth: {
    id: "wrapped-beacon-eth",
    symbol: "wbeth",
    name: "Wrapped Beacon ETH",
    image:
      "https://assets.coingecko.com/coins/images/30061/large/wbeth-icon.png?1683001548",
    current_price: 1871.15,
    market_cap: 132908700,
    market_cap_rank: 207,
    fully_diluted_valuation: 132908700,
    total_volume: 118334,
    high_24h: 1918.62,
    low_24h: 1861.74,
    price_change_24h: -23.555818826118184,
    price_change_percentage_24h: -1.24324,
    market_cap_change_24h: -1624795.6536313593,
    market_cap_change_percentage_24h: -1.20773,
    circulating_supply: 70938,
    total_supply: 70938,
    max_supply: null,
    ath: 2019.71,
    ath_change_percentage: -7.24291,
    ath_date: "2023-05-06T00:49:20.627Z",
    atl: 1627.6,
    atl_change_percentage: 15.10354,
    atl_date: "2023-06-15T11:45:00.716Z",
    roi: null,
    last_updated: "2023-06-28T12:05:06.681Z",
  },
  rbn: {
    id: "ribbon-finance",
    symbol: "rbn",
    name: "Ribbon Finance",
    image:
      "https://assets.coingecko.com/coins/images/15823/large/RBN_64x64.png?1633529723",
    current_price: 0.18166,
    market_cap: 132842571,
    market_cap_rank: 208,
    fully_diluted_valuation: 181810165,
    total_volume: 230289,
    high_24h: 0.184762,
    low_24h: 0.177225,
    price_change_24h: 0.00057948,
    price_change_percentage_24h: 0.32001,
    market_cap_change_24h: 655465,
    market_cap_change_percentage_24h: 0.49586,
    circulating_supply: 730666355.166739,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 5.54,
    ath_change_percentage: -96.7193,
    ath_date: "2021-10-07T22:30:49.258Z",
    atl: 0.12452,
    atl_change_percentage: 46.00836,
    atl_date: "2023-05-18T09:35:07.716Z",
    roi: null,
    last_updated: "2023-06-28T12:05:26.209Z",
  },
  joe: {
    id: "joe",
    symbol: "joe",
    name: "JOE",
    image:
      "https://assets.coingecko.com/coins/images/17569/large/traderjoe.png?1685690062",
    current_price: 0.388145,
    market_cap: 132687230,
    market_cap_rank: 209,
    fully_diluted_valuation: 194268624,
    total_volume: 9392403,
    high_24h: 0.407658,
    low_24h: 0.384527,
    price_change_24h: -0.017019217683453547,
    price_change_percentage_24h: -4.20057,
    market_cap_change_24h: -5632466.271589011,
    market_cap_change_percentage_24h: -4.07206,
    circulating_supply: 341504529.753269,
    total_supply: 476437634.356928,
    max_supply: 500000000,
    ath: 5.09,
    ath_change_percentage: -92.36081,
    ath_date: "2021-11-21T14:27:00.202Z",
    atl: 0.02658814,
    atl_change_percentage: 1361.60425,
    atl_date: "2021-08-11T14:01:35.223Z",
    roi: null,
    last_updated: "2023-06-28T12:05:20.893Z",
  },
  tribe: {
    id: "tribe-2",
    symbol: "tribe",
    name: "Tribe",
    image:
      "https://assets.coingecko.com/coins/images/14575/large/tribe.PNG?1617487954",
    current_price: 0.285914,
    market_cap: 130255046,
    market_cap_rank: 210,
    fully_diluted_valuation: 286265055,
    total_volume: 63672,
    high_24h: 0.288235,
    low_24h: 0.282382,
    price_change_24h: -0.001524960727984914,
    price_change_percentage_24h: -0.53053,
    market_cap_change_24h: 336312,
    market_cap_change_percentage_24h: 0.25886,
    circulating_supply: 455015530.80485964,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 2.45,
    ath_change_percentage: -88.31464,
    ath_date: "2021-04-03T23:33:40.147Z",
    atl: 0.14412,
    atl_change_percentage: 98.63098,
    atl_date: "2022-08-19T07:45:15.996Z",
    roi: null,
    last_updated: "2023-06-28T12:05:17.200Z",
  },
  syn: {
    id: "synapse-2",
    symbol: "syn",
    name: "Synapse",
    image:
      "https://assets.coingecko.com/coins/images/18024/large/synapse_social_icon.png?1663921797",
    current_price: 0.666201,
    market_cap: 126805078,
    market_cap_rank: 211,
    fully_diluted_valuation: 166745589,
    total_volume: 7222101,
    high_24h: 0.719698,
    low_24h: 0.665327,
    price_change_24h: -0.003556548368283896,
    price_change_percentage_24h: -0.53102,
    market_cap_change_24h: -104005.44449944794,
    market_cap_change_percentage_24h: -0.08195,
    circulating_supply: 190117588.881215,
    total_supply: 184907850,
    max_supply: 250000000,
    ath: 4.92,
    ath_change_percentage: -86.42968,
    ath_date: "2021-10-24T00:24:41.805Z",
    atl: 0.3896,
    atl_change_percentage: 71.21096,
    atl_date: "2021-09-21T21:19:58.123Z",
    roi: null,
    last_updated: "2023-06-28T12:05:33.950Z",
  },
  elf: {
    id: "aelf",
    symbol: "elf",
    name: "aelf",
    image:
      "https://assets.coingecko.com/coins/images/1371/large/aelf-logo.png?1547035397",
    current_price: 0.272416,
    market_cap: 125796112,
    market_cap_rank: 212,
    fully_diluted_valuation: 239959636,
    total_volume: 3098144,
    high_24h: 0.278026,
    low_24h: 0.27186,
    price_change_24h: -0.002512967878611439,
    price_change_percentage_24h: -0.91404,
    market_cap_change_24h: -857082.0563004017,
    market_cap_change_percentage_24h: -0.67672,
    circulating_supply: 461329999,
    total_supply: 879999999.986466,
    max_supply: null,
    ath: 2.6,
    ath_change_percentage: -89.52882,
    ath_date: "2018-01-09T00:00:00.000Z",
    atl: 0.03545756,
    atl_change_percentage: 669.03607,
    atl_date: "2020-03-13T02:22:35.431Z",
    roi: null,
    last_updated: "2023-06-28T12:05:37.820Z",
  },
  kub: {
    id: "bitkub-coin",
    symbol: "kub",
    name: "Bitkub Coin",
    image:
      "https://assets.coingecko.com/coins/images/15760/large/KUB.png?1621825161",
    current_price: 1.41,
    market_cap: 125747479,
    market_cap_rank: 213,
    fully_diluted_valuation: 155680616,
    total_volume: 319223,
    high_24h: 1.45,
    low_24h: 1.41,
    price_change_24h: -0.02102273106316299,
    price_change_percentage_24h: -1.47039,
    market_cap_change_24h: -1498435.1113363057,
    market_cap_change_percentage_24h: -1.17759,
    circulating_supply: 88850000,
    total_supply: 110000000,
    max_supply: 110000000,
    ath: 17.24,
    ath_change_percentage: -91.79059,
    ath_date: "2021-12-28T01:16:46.693Z",
    atl: 0.381968,
    atl_change_percentage: 270.52303,
    atl_date: "2021-07-20T14:22:47.966Z",
    roi: null,
    last_updated: "2023-06-28T12:04:55.479Z",
  },
  sushi: {
    id: "sushi",
    symbol: "sushi",
    name: "Sushi",
    image:
      "https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png?1606986688",
    current_price: 0.644407,
    market_cap: 124472336,
    market_cap_rank: 214,
    fully_diluted_valuation: 161409846,
    total_volume: 17075697,
    high_24h: 0.683215,
    low_24h: 0.644407,
    price_change_24h: -0.03316178986304008,
    price_change_percentage_24h: -4.89423,
    market_cap_change_24h: -6249329.340915397,
    market_cap_change_percentage_24h: -4.78064,
    circulating_supply: 192789255.8554817,
    total_supply: 249217550.114574,
    max_supply: 250000000,
    ath: 23.38,
    ath_change_percentage: -97.13523,
    ath_date: "2021-03-13T23:44:36.774Z",
    atl: 0.475381,
    atl_change_percentage: 40.87275,
    atl_date: "2020-11-04T14:53:53.560Z",
    roi: null,
    last_updated: "2023-06-28T12:05:37.655Z",
  },
  skl: {
    id: "skale",
    symbol: "skl",
    name: "SKALE",
    image:
      "https://assets.coingecko.com/coins/images/13245/large/SKALE_token_300x300.png?1606789574",
    current_price: 0.02719052,
    market_cap: 123504826,
    market_cap_rank: 215,
    fully_diluted_valuation: 190635082,
    total_volume: 6186716,
    high_24h: 0.02858757,
    low_24h: 0.02710624,
    price_change_24h: -0.001095102370921697,
    price_change_percentage_24h: -3.87159,
    market_cap_change_24h: -4801775.996789649,
    market_cap_change_percentage_24h: -3.74242,
    circulating_supply: 4535019337,
    total_supply: 5081911179.33668,
    max_supply: 7000000000,
    ath: 1.22,
    ath_change_percentage: -97.76087,
    ath_date: "2021-03-12T05:14:18.439Z",
    atl: 0.0196692,
    atl_change_percentage: 38.45936,
    atl_date: "2022-12-31T07:01:52.837Z",
    roi: null,
    last_updated: "2023-06-28T12:05:40.110Z",
  },
  ewt: {
    id: "energy-web-token",
    symbol: "ewt",
    name: "Energy Web",
    image:
      "https://assets.coingecko.com/coins/images/10886/large/R9gQTJV__400x400.png?1585604557",
    current_price: 2.62,
    market_cap: 122772542,
    market_cap_rank: 216,
    fully_diluted_valuation: 262164946,
    total_volume: 1037538,
    high_24h: 2.82,
    low_24h: 2.61,
    price_change_24h: -0.17260651296466545,
    price_change_percentage_24h: -6.17041,
    market_cap_change_24h: -8417003.098983094,
    market_cap_change_percentage_24h: -6.41591,
    circulating_supply: 46830266.1503243,
    total_supply: 69017450.9455633,
    max_supply: 100000000,
    ath: 22.67,
    ath_change_percentage: -88.43717,
    ath_date: "2021-04-17T03:10:00.960Z",
    atl: 0.543628,
    atl_change_percentage: 382.25077,
    atl_date: "2020-04-03T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:33.207Z",
  },
  axl: {
    id: "axelar",
    symbol: "axl",
    name: "Axelar",
    image:
      "https://assets.coingecko.com/coins/images/27277/large/V-65_xQ1_400x400.jpeg?1663121730",
    current_price: 0.397244,
    market_cap: 122301788,
    market_cap_rank: 217,
    fully_diluted_valuation: 426077978,
    total_volume: 1979196,
    high_24h: 0.413369,
    low_24h: 0.396798,
    price_change_24h: -0.012512788050445256,
    price_change_percentage_24h: -3.05371,
    market_cap_change_24h: -2905333.0141634643,
    market_cap_change_percentage_24h: -2.32042,
    circulating_supply: 307547263.523714,
    total_supply: 1071440720.48356,
    max_supply: null,
    ath: 1.15,
    ath_change_percentage: -65.55212,
    ath_date: "2022-09-27T22:19:02.643Z",
    atl: 0.373434,
    atl_change_percentage: 6.54017,
    atl_date: "2023-06-10T20:35:30.033Z",
    roi: null,
    last_updated: "2023-06-28T12:05:29.756Z",
  },
  chsb: {
    id: "swissborg",
    symbol: "chsb",
    name: "SwissBorg",
    image:
      "https://assets.coingecko.com/coins/images/2117/large/YJUrRy7r_400x400.png?1589794215",
    current_price: 0.128105,
    market_cap: 121916776,
    market_cap_rank: 218,
    fully_diluted_valuation: 128096860,
    total_volume: 418496,
    high_24h: 0.132188,
    low_24h: 0.127646,
    price_change_24h: -0.002841410638964565,
    price_change_percentage_24h: -2.1699,
    market_cap_change_24h: -2383378.217253715,
    market_cap_change_percentage_24h: -1.91744,
    circulating_supply: 951754603.4742761,
    total_supply: 1000000000,
    max_supply: null,
    ath: 1.64,
    ath_change_percentage: -92.18571,
    ath_date: "2021-05-04T17:14:50.120Z",
    atl: 0.00502687,
    atl_change_percentage: 2449.19592,
    atl_date: "2019-07-28T00:00:00.000Z",
    roi: {
      times: 0.281050249378088,
      currency: "usd",
      percentage: 28.1050249378088,
    },
    last_updated: "2023-06-28T12:04:53.726Z",
  },
  ssv: {
    id: "ssv-network",
    symbol: "ssv",
    name: "SSV Network",
    image:
      "https://assets.coingecko.com/coins/images/19155/large/ssv.png?1638181902",
    current_price: 17.15,
    market_cap: 121696186,
    market_cap_rank: 219,
    fully_diluted_valuation: 190244881,
    total_volume: 6271546,
    high_24h: 18.29,
    low_24h: 17.05,
    price_change_24h: -1.060966518362676,
    price_change_percentage_24h: -5.8273,
    market_cap_change_24h: -7311123.442714617,
    market_cap_change_percentage_24h: -5.66722,
    circulating_supply: 7085672.647228521,
    total_supply: 11076871,
    max_supply: null,
    ath: 49.52,
    ath_change_percentage: -65.33261,
    ath_date: "2023-02-27T23:15:10.196Z",
    atl: 3.67,
    atl_change_percentage: 367.63375,
    atl_date: "2022-06-18T20:56:57.599Z",
    roi: null,
    last_updated: "2023-06-28T12:05:39.387Z",
  },
  ustc: {
    id: "terrausd",
    symbol: "ustc",
    name: "TerraClassicUSD",
    image:
      "https://assets.coingecko.com/coins/images/12681/large/UST.png?1653548090",
    current_price: 0.01231485,
    market_cap: 120653963,
    market_cap_rank: 220,
    fully_diluted_valuation: 120708483,
    total_volume: 6485586,
    high_24h: 0.01334543,
    low_24h: 0.01222827,
    price_change_24h: -0.000628804705036807,
    price_change_percentage_24h: -4.85801,
    market_cap_change_24h: -6096967.582640097,
    market_cap_change_percentage_24h: -4.8102,
    circulating_supply: 9794183438.46456,
    total_supply: 9798609129.2219,
    max_supply: null,
    ath: 1.092,
    ath_change_percentage: -98.87188,
    ath_date: "2021-01-11T22:30:57.984Z",
    atl: 0.00601921,
    atl_change_percentage: 104.64438,
    atl_date: "2022-06-18T20:35:39.169Z",
    roi: null,
    last_updated: "2023-06-28T12:05:19.675Z",
  },
  akt: {
    id: "akash-network",
    symbol: "akt",
    name: "Akash Network",
    image:
      "https://assets.coingecko.com/coins/images/12785/large/akash-logo.png?1615447676",
    current_price: 0.560285,
    market_cap: 120435748,
    market_cap_rank: 221,
    fully_diluted_valuation: 217901390,
    total_volume: 1633192,
    high_24h: 0.59482,
    low_24h: 0.557317,
    price_change_24h: -0.025106938544073643,
    price_change_percentage_24h: -4.28891,
    market_cap_change_24h: -4935510.740080476,
    market_cap_change_percentage_24h: -3.93672,
    circulating_supply: 214748452.200634,
    total_supply: 214747816.852513,
    max_supply: 388539008,
    ath: 8.07,
    ath_change_percentage: -93.0548,
    ath_date: "2021-04-06T18:59:48.484Z",
    atl: 0.164994,
    atl_change_percentage: 239.90516,
    atl_date: "2022-11-21T20:30:53.727Z",
    roi: null,
    last_updated: "2023-06-28T12:05:31.231Z",
  },
  lpt: {
    id: "livepeer",
    symbol: "lpt",
    name: "Livepeer",
    image:
      "https://assets.coingecko.com/coins/images/7137/large/logo-circle-green.png?1619593365",
    current_price: 4.25,
    market_cap: 120254613,
    market_cap_rank: 222,
    fully_diluted_valuation: 120254613,
    total_volume: 6105782,
    high_24h: 4.47,
    low_24h: 4.22,
    price_change_24h: -0.17323210818865586,
    price_change_percentage_24h: -3.92073,
    market_cap_change_24h: -4229432.65163824,
    market_cap_change_percentage_24h: -3.39757,
    circulating_supply: 28262979.2971131,
    total_supply: 28262979.2971131,
    max_supply: 28262979.2971131,
    ath: 99.03,
    ath_change_percentage: -95.70325,
    ath_date: "2021-11-09T16:58:44.554Z",
    atl: 0.354051,
    atl_change_percentage: 1101.79744,
    atl_date: "2019-10-26T22:59:09.500Z",
    roi: null,
    last_updated: "2023-06-28T12:05:21.747Z",
  },
  edu: {
    id: "edu-coin",
    symbol: "edu",
    name: "Open Campus",
    image:
      "https://assets.coingecko.com/coins/images/29948/large/EDU_Logo.png?1682327357",
    current_price: 0.718475,
    market_cap: 119811383,
    market_cap_rank: 223,
    fully_diluted_valuation: 720895802,
    total_volume: 17811757,
    high_24h: 0.775189,
    low_24h: 0.716541,
    price_change_24h: -0.05298042313076756,
    price_change_percentage_24h: -6.86759,
    market_cap_change_24h: -8394948.766751587,
    market_cap_change_percentage_24h: -6.548,
    circulating_supply: 166197919.67,
    total_supply: 1000000000,
    max_supply: null,
    ath: 1.67,
    ath_change_percentage: -56.87433,
    ath_date: "2023-04-29T08:05:57.583Z",
    atl: 0.713068,
    atl_change_percentage: 1.26482,
    atl_date: "2023-06-16T14:51:16.746Z",
    roi: null,
    last_updated: "2023-06-28T12:05:20.958Z",
  },
  core: {
    id: "coredaoorg",
    symbol: "core",
    name: "Core",
    image:
      "https://assets.coingecko.com/coins/images/28938/large/coredao.png?1675503751",
    current_price: 0.828509,
    market_cap: 119716224,
    market_cap_rank: 224,
    fully_diluted_valuation: 1741746691,
    total_volume: 5726091,
    high_24h: 0.878366,
    low_24h: 0.82397,
    price_change_24h: -0.016417896236914475,
    price_change_percentage_24h: -1.94311,
    market_cap_change_24h: -2116226.6556956023,
    market_cap_change_percentage_24h: -1.737,
    circulating_supply: 144340202.227432,
    total_supply: 2100000000,
    max_supply: 2100000000,
    ath: 6.14,
    ath_change_percentage: -86.49407,
    ath_date: "2023-02-08T12:55:39.828Z",
    atl: 0.731395,
    atl_change_percentage: 13.44843,
    atl_date: "2023-06-14T21:01:27.136Z",
    roi: null,
    last_updated: "2023-06-28T12:05:22.414Z",
  },
  hive: {
    id: "hive",
    symbol: "hive",
    name: "Hive",
    image:
      "https://assets.coingecko.com/coins/images/10840/large/logo_transparent_4x.png?1584623184",
    current_price: 0.322944,
    market_cap: 119192830,
    market_cap_rank: 225,
    fully_diluted_valuation: null,
    total_volume: 2736509,
    high_24h: 0.342226,
    low_24h: 0.319967,
    price_change_24h: -0.010163564860009137,
    price_change_percentage_24h: -3.05113,
    market_cap_change_24h: -5723200.256874412,
    market_cap_change_percentage_24h: -4.58164,
    circulating_supply: 371261453.714,
    total_supply: null,
    max_supply: null,
    ath: 3.41,
    ath_change_percentage: -90.57977,
    ath_date: "2021-11-26T01:46:11.175Z",
    atl: 0.087309,
    atl_change_percentage: 267.68414,
    atl_date: "2020-04-06T07:35:48.099Z",
    roi: null,
    last_updated: "2023-06-28T12:05:16.482Z",
  },
  exrd: {
    id: "e-radix",
    symbol: "exrd",
    name: "e-Radix",
    image:
      "https://assets.coingecko.com/coins/images/13145/large/exrd_logo.png?1605662677",
    current_price: 0.077529,
    market_cap: 117652220,
    market_cap_rank: 226,
    fully_diluted_valuation: 1859205887,
    total_volume: 531664,
    high_24h: 0.086116,
    low_24h: 0.073201,
    price_change_24h: -0.00794599120238171,
    price_change_percentage_24h: -9.29632,
    market_cap_change_24h: -12251659.646593317,
    market_cap_change_percentage_24h: -9.43133,
    circulating_supply: 1518741581.57487,
    total_supply: 1518741581.57487,
    max_supply: 24000000000,
    ath: 0.660211,
    ath_change_percentage: -88.26728,
    ath_date: "2021-11-14T16:24:07.330Z",
    atl: 0.03134183,
    atl_change_percentage: 147.14781,
    atl_date: "2022-11-30T00:16:57.409Z",
    roi: null,
    last_updated: "2023-06-28T12:04:19.107Z",
  },
  amp: {
    id: "amp-token",
    symbol: "amp",
    name: "Amp",
    image:
      "https://assets.coingecko.com/coins/images/12409/large/amp-200x200.png?1599625397",
    current_price: 0.00207799,
    market_cap: 116863199,
    market_cap_rank: 227,
    fully_diluted_valuation: 207055223,
    total_volume: 2883668,
    high_24h: 0.00214697,
    low_24h: 0.00206554,
    price_change_24h: -0.000066198122005903,
    price_change_percentage_24h: -3.08732,
    market_cap_change_24h: -3447362.1079107076,
    market_cap_change_percentage_24h: -2.86539,
    circulating_supply: 56127938240.441696,
    total_supply: 99446043447.1583,
    max_supply: 99446043447.1583,
    ath: 0.120813,
    ath_change_percentage: -98.26958,
    ath_date: "2021-06-16T16:03:41.147Z",
    atl: 0.00185806,
    atl_change_percentage: 12.51375,
    atl_date: "2023-06-10T06:55:55.878Z",
    roi: null,
    last_updated: "2023-06-28T12:05:37.611Z",
  },
  gns: {
    id: "gains-network",
    symbol: "gns",
    name: "Gains Network",
    image:
      "https://assets.coingecko.com/coins/images/19737/large/logo.png?1635909203",
    current_price: 3.84,
    market_cap: 116775041,
    market_cap_rank: 228,
    fully_diluted_valuation: 116775041,
    total_volume: 5167885,
    high_24h: 4.04,
    low_24h: 3.8,
    price_change_24h: -0.19407547120107305,
    price_change_percentage_24h: -4.81478,
    market_cap_change_24h: -5781390.596348941,
    market_cap_change_percentage_24h: -4.71733,
    circulating_supply: 30393806,
    total_supply: 30393806,
    max_supply: 30393806,
    ath: 12.48,
    ath_change_percentage: -69.20955,
    ath_date: "2023-02-17T07:55:07.998Z",
    atl: 0.265841,
    atl_change_percentage: 1345.36619,
    atl_date: "2021-11-29T05:56:42.899Z",
    roi: null,
    last_updated: "2023-06-28T12:05:20.156Z",
  },
  rsr: {
    id: "reserve-rights-token",
    symbol: "rsr",
    name: "Reserve Rights",
    image:
      "https://assets.coingecko.com/coins/images/8365/large/rsr.png?1637983320",
    current_price: 0.00230312,
    market_cap: 116714590,
    market_cap_rank: 229,
    fully_diluted_valuation: 230661246,
    total_volume: 5676919,
    high_24h: 0.00238487,
    low_24h: 0.00226738,
    price_change_24h: -0.000067277144194391,
    price_change_percentage_24h: -2.83822,
    market_cap_change_24h: -3285697.763596043,
    market_cap_change_percentage_24h: -2.73807,
    circulating_supply: 50600000000,
    total_supply: 100000000000,
    max_supply: 100000000000,
    ath: 0.117424,
    ath_change_percentage: -98.03575,
    ath_date: "2021-04-16T17:45:02.062Z",
    atl: 0.00121354,
    atl_change_percentage: 90.06286,
    atl_date: "2020-03-16T11:09:08.151Z",
    roi: {
      times: 1.0937496358256236,
      currency: "usd",
      percentage: 109.37496358256236,
    },
    last_updated: "2023-06-28T12:05:25.641Z",
  },
  lsk: {
    id: "lisk",
    symbol: "lsk",
    name: "Lisk",
    image:
      "https://assets.coingecko.com/coins/images/385/large/Lisk_Symbol_-_Blue.png?1573444104",
    current_price: 0.793819,
    market_cap: 116044641,
    market_cap_rank: 230,
    fully_diluted_valuation: null,
    total_volume: 1956853,
    high_24h: 0.845231,
    low_24h: 0.793845,
    price_change_24h: -0.04208128192005878,
    price_change_percentage_24h: -5.03425,
    market_cap_change_24h: -5011989.25220716,
    market_cap_change_percentage_24h: -4.1402,
    circulating_supply: 144818773,
    total_supply: null,
    max_supply: null,
    ath: 34.92,
    ath_change_percentage: -97.70556,
    ath_date: "2018-01-07T00:00:00.000Z",
    atl: 0.101672,
    atl_change_percentage: 688.13494,
    atl_date: "2017-03-01T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:38.437Z",
  },
  stg: {
    id: "stargate-finance",
    symbol: "stg",
    name: "Stargate Finance",
    image:
      "https://assets.coingecko.com/coins/images/24413/large/STG_LOGO.png?1647654518",
    current_price: 0.566628,
    market_cap: 115938521,
    market_cap_rank: 231,
    fully_diluted_valuation: 567356807,
    total_volume: 17372684,
    high_24h: 0.609192,
    low_24h: 0.566576,
    price_change_24h: -0.03266958861331193,
    price_change_percentage_24h: -5.45131,
    market_cap_change_24h: -6236766.428821728,
    market_cap_change_percentage_24h: -5.10477,
    circulating_supply: 204348515.727817,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 4.14,
    ath_change_percentage: -86.30452,
    ath_date: "2022-04-02T00:00:00.000Z",
    atl: 0.337788,
    atl_change_percentage: 67.91405,
    atl_date: "2022-08-19T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:22.355Z",
  },
  ever: {
    id: "everscale",
    symbol: "ever",
    name: "Everscale",
    image:
      "https://assets.coingecko.com/coins/images/12783/large/everscale_badge_main_round_1x.png?1640050196",
    current_price: 0.064114,
    market_cap: 115387850,
    market_cap_rank: 232,
    fully_diluted_valuation: 134003185,
    total_volume: 3177823,
    high_24h: 0.064731,
    low_24h: 0.063623,
    price_change_24h: -0.000363666502178173,
    price_change_percentage_24h: -0.56402,
    market_cap_change_24h: -147426.98556159437,
    market_cap_change_percentage_24h: -0.1276,
    circulating_supply: 1798667246,
    total_supply: 2088843308,
    max_supply: null,
    ath: 2.56,
    ath_change_percentage: -97.49642,
    ath_date: "2020-10-13T21:23:14.299Z",
    atl: 0.02451169,
    atl_change_percentage: 161.71946,
    atl_date: "2022-12-29T07:05:05.994Z",
    roi: null,
    last_updated: "2023-06-28T12:05:31.011Z",
  },
  astrafer: {
    id: "astrafer",
    symbol: "astrafer",
    name: "Astrafer",
    image:
      "https://assets.coingecko.com/coins/images/26246/large/ATSRA_Token.png?1657276411",
    current_price: 0.733347,
    market_cap: 114838531,
    market_cap_rank: 233,
    fully_diluted_valuation: 651440308,
    total_volume: 113454,
    high_24h: 0.735817,
    low_24h: 0.653124,
    price_change_24h: 0.02095984,
    price_change_percentage_24h: 2.9422,
    market_cap_change_24h: 3564020,
    market_cap_change_percentage_24h: 3.20291,
    circulating_supply: 156553960.9,
    total_supply: 888077888,
    max_supply: 888077888,
    ath: 4.88,
    ath_change_percentage: -84.96555,
    ath_date: "2022-09-11T19:47:43.631Z",
    atl: 0.653124,
    atl_change_percentage: 12.28284,
    atl_date: "2023-06-28T01:10:21.511Z",
    roi: null,
    last_updated: "2023-06-28T12:04:03.316Z",
  },
  eusd: {
    id: "eusd-27a558b0-8b5b-4225-a614-63539da936f4",
    symbol: "eusd",
    name: "eUSD",
    image:
      "https://assets.coingecko.com/coins/images/30047/large/image_5.png?1683016861",
    current_price: 1.018,
    market_cap: 112265774,
    market_cap_rank: 234,
    fully_diluted_valuation: 112265774,
    total_volume: 1016707,
    high_24h: 1.03,
    low_24h: 1.003,
    price_change_24h: -0.006843841236228165,
    price_change_percentage_24h: -0.66768,
    market_cap_change_24h: -659213.9474740922,
    market_cap_change_percentage_24h: -0.58376,
    circulating_supply: 110159238.430149,
    total_supply: 110159238.430149,
    max_supply: null,
    ath: 1.33,
    ath_change_percentage: -23.67262,
    ath_date: "2023-06-16T22:52:47.254Z",
    atl: 0.961065,
    atl_change_percentage: 5.98918,
    atl_date: "2023-05-22T06:09:15.623Z",
    roi: null,
    last_updated: "2023-06-28T12:05:08.507Z",
  },
  dgb: {
    id: "digibyte",
    symbol: "dgb",
    name: "DigiByte",
    image:
      "https://assets.coingecko.com/coins/images/63/large/digibyte.png?1547033717",
    current_price: 0.00683759,
    market_cap: 111733810,
    market_cap_rank: 235,
    fully_diluted_valuation: 143709568,
    total_volume: 1789756,
    high_24h: 0.00703757,
    low_24h: 0.00676035,
    price_change_24h: -0.000082024958434231,
    price_change_percentage_24h: -1.1854,
    market_cap_change_24h: -1108556.4519759268,
    market_cap_change_percentage_24h: -0.98239,
    circulating_supply: 16327444632.9945,
    total_supply: 21000000000,
    max_supply: null,
    ath: 0.178084,
    ath_change_percentage: -96.15657,
    ath_date: "2021-05-01T00:19:01.967Z",
    atl: 0.00003099,
    atl_change_percentage: 21988.71505,
    atl_date: "2015-01-14T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:39.967Z",
  },
  uma: {
    id: "uma",
    symbol: "uma",
    name: "UMA",
    image:
      "https://assets.coingecko.com/coins/images/10951/large/UMA.png?1586307916",
    current_price: 1.54,
    market_cap: 111572761,
    market_cap_rank: 236,
    fully_diluted_valuation: 176664196,
    total_volume: 3085940,
    high_24h: 1.64,
    low_24h: 1.54,
    price_change_24h: -0.08842986440771594,
    price_change_percentage_24h: -5.42819,
    market_cap_change_24h: -6189354.163911477,
    market_cap_change_percentage_24h: -5.25581,
    circulating_supply: 72344020.68088429,
    total_supply: 114549448.34749,
    max_supply: 114549448.34749,
    ath: 41.56,
    ath_change_percentage: -96.28872,
    ath_date: "2021-02-04T11:27:11.433Z",
    atl: 0.303625,
    atl_change_percentage: 407.94702,
    atl_date: "2020-04-29T17:00:41.760Z",
    roi: null,
    last_updated: "2023-06-28T12:05:34.388Z",
  },
  poly: {
    id: "polymath",
    symbol: "poly",
    name: "Polymath",
    image:
      "https://assets.coingecko.com/coins/images/2784/large/inKkF01.png?1605007034",
    current_price: 0.123069,
    market_cap: 110624518,
    market_cap_rank: 237,
    fully_diluted_valuation: 123114368,
    total_volume: 74065,
    high_24h: 0.129338,
    low_24h: 0.122784,
    price_change_24h: -0.002389278209565873,
    price_change_percentage_24h: -1.90445,
    market_cap_change_24h: -2110267.473607987,
    market_cap_change_percentage_24h: -1.87189,
    circulating_supply: 898550829.6745886,
    total_supply: 1000000000,
    max_supply: null,
    ath: 1.59,
    ath_change_percentage: -92.26576,
    ath_date: "2018-02-20T00:00:00.000Z",
    atl: 0.01100606,
    atl_change_percentage: 1018.60542,
    atl_date: "2020-03-13T02:22:30.088Z",
    roi: null,
    last_updated: "2023-06-28T12:03:31.911Z",
  },
  ctsi: {
    id: "cartesi",
    symbol: "ctsi",
    name: "Cartesi",
    image:
      "https://assets.coingecko.com/coins/images/11038/large/cartesi.png?1592288021",
    current_price: 0.144951,
    market_cap: 107368084,
    market_cap_rank: 238,
    fully_diluted_valuation: 145181581,
    total_volume: 6094941,
    high_24h: 0.153024,
    low_24h: 0.144404,
    price_change_24h: -0.006937221596214155,
    price_change_percentage_24h: -4.56731,
    market_cap_change_24h: -4896419.335245445,
    market_cap_change_percentage_24h: -4.3615,
    circulating_supply: 739543426.4452276,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 1.74,
    ath_change_percentage: -91.6762,
    ath_date: "2021-05-09T00:43:41.306Z",
    atl: 0.02670608,
    atl_change_percentage: 443.6275,
    atl_date: "2020-05-10T04:49:27.744Z",
    roi: null,
    last_updated: "2023-06-28T12:05:23.888Z",
  },
  dag: {
    id: "constellation-labs",
    symbol: "dag",
    name: "Constellation",
    image:
      "https://assets.coingecko.com/coins/images/4645/large/Constellation_Network_Logo.png?1662543939",
    current_price: 0.03729936,
    market_cap: 107230929,
    market_cap_rank: 239,
    fully_diluted_valuation: 132070142,
    total_volume: 360432,
    high_24h: 0.03913322,
    low_24h: 0.03591438,
    price_change_24h: 0.00074907,
    price_change_percentage_24h: 2.04941,
    market_cap_change_24h: 2410664,
    market_cap_change_percentage_24h: 2.29981,
    circulating_supply: 2882330492.76211,
    total_supply: 3550000000,
    max_supply: null,
    ath: 0.451761,
    ath_change_percentage: -91.7637,
    ath_date: "2021-08-25T23:30:00.311Z",
    atl: 0.00110189,
    atl_change_percentage: 3276.77746,
    atl_date: "2019-03-09T00:00:00.000Z",
    roi: {
      times: -0.6992138133403029,
      currency: "eth",
      percentage: -69.92138133403029,
    },
    last_updated: "2023-06-28T12:05:41.073Z",
  },
  ox: {
    id: "open-exchange-token",
    symbol: "ox",
    name: "Open Exchange Token",
    image:
      "https://assets.coingecko.com/coins/images/30604/large/Logo2.png?1685522119",
    current_price: 0.0346245,
    market_cap: 106032786,
    market_cap_rank: 240,
    fully_diluted_valuation: 341752279,
    total_volume: 3942959,
    high_24h: 0.04432783,
    low_24h: 0.03470391,
    price_change_24h: -0.007644482918560125,
    price_change_percentage_24h: -18.08533,
    market_cap_change_24h: -19333018.124577507,
    market_cap_change_percentage_24h: -15.42129,
    circulating_supply: 3059184479.85,
    total_supply: 3057101972.76,
    max_supply: 9860000000,
    ath: 0.04432783,
    ath_change_percentage: -20.44049,
    ath_date: "2023-06-27T14:48:59.386Z",
    atl: 0.01024732,
    atl_change_percentage: 244.15819,
    atl_date: "2023-05-31T12:41:50.689Z",
    roi: null,
    last_updated: "2023-06-28T12:05:18.266Z",
  },
  acs: {
    id: "access-protocol",
    symbol: "acs",
    name: "Access Protocol",
    image:
      "https://assets.coingecko.com/coins/images/28747/large/dR4FovX4_400x400.jpg?1673860839",
    current_price: 0.00347584,
    market_cap: 104926263,
    market_cap_rank: 241,
    fully_diluted_valuation: 348848546,
    total_volume: 1039905,
    high_24h: 0.00362901,
    low_24h: 0.00347432,
    price_change_24h: -0.000153173032762417,
    price_change_percentage_24h: -4.22079,
    market_cap_change_24h: -4356083.957936421,
    market_cap_change_percentage_24h: -3.98608,
    circulating_supply: 30160650094.2865,
    total_supply: 100275170321.797,
    max_supply: null,
    ath: 0.02687893,
    ath_change_percentage: -87.06045,
    ath_date: "2023-02-16T01:23:46.316Z",
    atl: 0.00279355,
    atl_change_percentage: 24.50157,
    atl_date: "2023-06-15T17:40:58.930Z",
    roi: null,
    last_updated: "2023-06-28T12:05:33.785Z",
  },
  ach: {
    id: "alchemy-pay",
    symbol: "ach",
    name: "Alchemy Pay",
    image:
      "https://assets.coingecko.com/coins/images/12390/large/ACH_%281%29.png?1599691266",
    current_price: 0.02109029,
    market_cap: 104754261,
    market_cap_rank: 242,
    fully_diluted_valuation: 211894836,
    total_volume: 9534275,
    high_24h: 0.02286534,
    low_24h: 0.02118911,
    price_change_24h: -0.001606973542234054,
    price_change_percentage_24h: -7.08003,
    market_cap_change_24h: -7453201.00577198,
    market_cap_change_percentage_24h: -6.64234,
    circulating_supply: 4943691067.145604,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 0.198666,
    ath_change_percentage: -88.90434,
    ath_date: "2021-08-06T16:39:58.934Z",
    atl: 0.00135537,
    atl_change_percentage: 1526.3702,
    atl_date: "2021-07-21T00:39:36.177Z",
    roi: null,
    last_updated: "2023-06-28T12:05:29.421Z",
  },
  brise: {
    id: "bitrise-token",
    symbol: "brise",
    name: "Bitgert",
    image:
      "https://assets.coingecko.com/coins/images/17388/large/bitgert.png?1660272192",
    current_price: 2.59383e-7,
    market_cap: 103359292,
    market_cap_rank: 243,
    fully_diluted_valuation: 261213975,
    total_volume: 946006,
    high_24h: 2.62715e-7,
    low_24h: 2.55526e-7,
    price_change_24h: -5.18214864e-10,
    price_change_percentage_24h: -0.19939,
    market_cap_change_24h: 116434,
    market_cap_change_percentage_24h: 0.11278,
    circulating_supply: 395688215721653.25,
    total_supply: 427063549263988,
    max_supply: 1000000000000000,
    ath: 0.00000184,
    ath_change_percentage: -85.82109,
    ath_date: "2022-03-07T08:25:04.177Z",
    atl: 6.16584e-10,
    atl_change_percentage: 42262.51708,
    atl_date: "2021-09-09T21:36:32.800Z",
    roi: null,
    last_updated: "2023-06-28T12:05:29.368Z",
  },
  msol: {
    id: "msol",
    symbol: "msol",
    name: "Marinade staked SOL",
    image:
      "https://assets.coingecko.com/coins/images/17752/large/mSOL.png?1644541955",
    current_price: 18.14,
    market_cap: 102382120,
    market_cap_rank: 244,
    fully_diluted_valuation: 102382120,
    total_volume: 8611180,
    high_24h: 18.95,
    low_24h: 17.87,
    price_change_24h: -0.5226819716048077,
    price_change_percentage_24h: -2.80085,
    market_cap_change_24h: -1960783.5506588668,
    market_cap_change_percentage_24h: -1.87917,
    circulating_supply: 5637980,
    total_supply: 5637980,
    max_supply: 5637980,
    ath: 3541.84,
    ath_change_percentage: -99.4872,
    ath_date: "2021-08-23T22:16:35.076Z",
    atl: 8.93,
    atl_change_percentage: 103.38381,
    atl_date: "2022-12-29T20:45:33.039Z",
    roi: null,
    last_updated: "2023-06-28T12:05:19.872Z",
  },
  sure: {
    id: "insure",
    symbol: "sure",
    name: "inSure DeFi",
    image:
      "https://assets.coingecko.com/coins/images/10354/large/logo-grey-circle.png?1614910406",
    current_price: 0.00383707,
    market_cap: 101684866,
    market_cap_rank: 245,
    fully_diluted_valuation: 338132600,
    total_volume: 217388,
    high_24h: 0.00416306,
    low_24h: 0.00364316,
    price_change_24h: -0.000253452427266777,
    price_change_percentage_24h: -6.19609,
    market_cap_change_24h: -6411731.875178829,
    market_cap_change_percentage_24h: -5.93148,
    circulating_supply: 26463784397.466843,
    total_supply: 88000000000,
    max_supply: 88000000000,
    ath: 0.11397,
    ath_change_percentage: -96.62858,
    ath_date: "2021-01-06T21:22:32.766Z",
    atl: 0.00015606,
    atl_change_percentage: 2362.12491,
    atl_date: "2021-06-28T04:52:56.935Z",
    roi: null,
    last_updated: "2023-06-28T12:05:32.563Z",
  },
  mrs: {
    id: "metars-genesis",
    symbol: "mrs",
    name: "Metars Genesis",
    image:
      "https://assets.coingecko.com/coins/images/26625/large/PRmUU8O1_400x400.jpeg?1659322669",
    current_price: 1.6,
    market_cap: 100743460,
    market_cap_rank: 246,
    fully_diluted_valuation: 1602919007,
    total_volume: 105190,
    high_24h: 1.67,
    low_24h: 1.56,
    price_change_24h: -0.019350177549129155,
    price_change_percentage_24h: -1.19278,
    market_cap_change_24h: -1182382.151163146,
    market_cap_change_percentage_24h: -1.16004,
    circulating_supply: 62850000,
    total_supply: 1000000000,
    max_supply: null,
    ath: 4.3,
    ath_change_percentage: -62.70988,
    ath_date: "2022-08-06T23:23:06.656Z",
    atl: 0.584931,
    atl_change_percentage: 174.10615,
    atl_date: "2022-09-16T23:23:54.565Z",
    roi: null,
    last_updated: "2023-06-28T12:03:16.039Z",
  },
  mlk: {
    id: "milk-alliance",
    symbol: "mlk",
    name: "MiL.k Alliance",
    image:
      "https://assets.coingecko.com/coins/images/11978/large/2.png?1617602076",
    current_price: 0.340175,
    market_cap: 99323084,
    market_cap_rank: 247,
    fully_diluted_valuation: 442206669,
    total_volume: 14602234,
    high_24h: 0.353651,
    low_24h: 0.340311,
    price_change_24h: -0.01088442170943388,
    price_change_percentage_24h: -3.10045,
    market_cap_change_24h: -3146142.6163118333,
    market_cap_change_percentage_24h: -3.07033,
    circulating_supply: 291990190,
    total_supply: 986245419,
    max_supply: 1300000000,
    ath: 3.59,
    ath_change_percentage: -90.40429,
    ath_date: "2021-04-05T15:10:26.250Z",
    atl: 0.199076,
    atl_change_percentage: 73.03522,
    atl_date: "2022-12-30T12:35:50.585Z",
    roi: null,
    last_updated: "2023-06-28T12:04:44.922Z",
  },
  tel: {
    id: "telcoin",
    symbol: "tel",
    name: "Telcoin",
    image:
      "https://assets.coingecko.com/coins/images/1899/large/tel.png?1547036203",
    current_price: 0.00142571,
    market_cap: 99089133,
    market_cap_rank: 248,
    fully_diluted_valuation: 142575012,
    total_volume: 718164,
    high_24h: 0.00151141,
    low_24h: 0.00141497,
    price_change_24h: -0.000055057173920423,
    price_change_percentage_24h: -3.71816,
    market_cap_change_24h: -3384318.3583308607,
    market_cap_change_percentage_24h: -3.30263,
    circulating_supply: 69499648954.73,
    total_supply: 100000000000,
    max_supply: null,
    ath: 0.064483,
    ath_change_percentage: -97.78894,
    ath_date: "2021-05-11T00:32:24.043Z",
    atl: 0.00006474,
    atl_change_percentage: 2102.39782,
    atl_date: "2020-03-13T02:24:47.992Z",
    roi: {
      times: 0.425708007515605,
      currency: "usd",
      percentage: 42.5708007515605,
    },
    last_updated: "2023-06-28T12:05:29.833Z",
  },
  knc: {
    id: "kyber-network-crystal",
    symbol: "knc",
    name: "Kyber Network Crystal",
    image:
      "https://assets.coingecko.com/coins/images/14899/large/RwdVsGcw_400x400.jpg?1618923851",
    current_price: 0.545033,
    market_cap: 98908676,
    market_cap_rank: 249,
    fully_diluted_valuation: 137363404,
    total_volume: 7842604,
    high_24h: 0.575843,
    low_24h: 0.538263,
    price_change_24h: -0.01828118635102516,
    price_change_percentage_24h: -3.24529,
    market_cap_change_24h: -3488676.5151403993,
    market_cap_change_percentage_24h: -3.407,
    circulating_supply: 181670018.75741822,
    total_supply: 227807226.463374,
    max_supply: 252301550,
    ath: 5.7,
    ath_change_percentage: -90.43958,
    ath_date: "2022-04-29T00:29:36.976Z",
    atl: 0.447874,
    atl_change_percentage: 21.56893,
    atl_date: "2023-06-10T04:31:08.299Z",
    roi: null,
    last_updated: "2023-06-28T12:05:37.992Z",
  },
  trac: {
    id: "origintrail",
    symbol: "trac",
    name: "OriginTrail",
    image:
      "https://assets.coingecko.com/coins/images/1877/large/TRAC.jpg?1635134367",
    current_price: 0.25769,
    market_cap: 98734868,
    market_cap_rank: 250,
    fully_diluted_valuation: 128964544,
    total_volume: 1085667,
    high_24h: 0.266224,
    low_24h: 0.25589,
    price_change_24h: -0.005161871699213949,
    price_change_percentage_24h: -1.9638,
    market_cap_change_24h: -1881951.7058713734,
    market_cap_change_percentage_24h: -1.87041,
    circulating_supply: 382798499.36395836,
    total_supply: 500000000,
    max_supply: 500000000,
    ath: 3.5,
    ath_change_percentage: -92.64099,
    ath_date: "2021-11-03T18:09:57.627Z",
    atl: 0.00385262,
    atl_change_percentage: 6594.90315,
    atl_date: "2020-03-13T02:24:12.031Z",
    roi: {
      times: 1.576895574616173,
      currency: "usd",
      percentage: 157.6895574616173,
    },
    last_updated: "2023-06-28T12:05:30.193Z",
  },
  polyx: {
    id: "polymesh",
    symbol: "polyx",
    name: "Polymesh",
    image:
      "https://assets.coingecko.com/coins/images/23496/large/Polymesh-symbol.png?1683256883",
    current_price: 0.124552,
    market_cap: 98680704,
    market_cap_rank: 251,
    fully_diluted_valuation: 98680704,
    total_volume: 6559343,
    high_24h: 0.130254,
    low_24h: 0.122321,
    price_change_24h: -0.001627634985938764,
    price_change_percentage_24h: -1.28994,
    market_cap_change_24h: -1825940.2712596953,
    market_cap_change_percentage_24h: -1.81674,
    circulating_supply: 798340016.696864,
    total_supply: 798340016.696864,
    max_supply: null,
    ath: 0.494208,
    ath_change_percentage: -74.99535,
    ath_date: "2022-10-17T11:43:16.638Z",
    atl: 0.100357,
    atl_change_percentage: 23.13493,
    atl_date: "2023-06-15T11:29:34.993Z",
    roi: null,
    last_updated: "2023-06-28T12:05:29.559Z",
  },
  cet: {
    id: "coinex-token",
    symbol: "cet",
    name: "CoinEx",
    image:
      "https://assets.coingecko.com/coins/images/4817/large/coinex-token.png?1547040183",
    current_price: 0.03313701,
    market_cap: 98398555,
    market_cap_rank: 252,
    fully_diluted_valuation: 326325030,
    total_volume: 906861,
    high_24h: 0.03335672,
    low_24h: 0.03245406,
    price_change_24h: 0.00051789,
    price_change_percentage_24h: 1.5877,
    market_cap_change_24h: -560776.5776838511,
    market_cap_change_percentage_24h: -0.56667,
    circulating_supply: 3015354215.9,
    total_supply: 5868849037.61317,
    max_supply: 10000000000,
    ath: 0.150293,
    ath_change_percentage: -78.28322,
    ath_date: "2018-07-03T22:04:23.838Z",
    atl: 0.00410696,
    atl_change_percentage: 694.71774,
    atl_date: "2018-12-15T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:30.367Z",
  },
  flexusd: {
    id: "flex-usd",
    symbol: "flexusd",
    name: "flexUSD",
    image:
      "https://assets.coingecko.com/coins/images/13323/large/flexUSD_2x.png?1607480702",
    current_price: 0.49825,
    market_cap: 97831497,
    market_cap_rank: 253,
    fully_diluted_valuation: 97831497,
    total_volume: 379.81,
    high_24h: 0.510277,
    low_24h: 0.479022,
    price_change_24h: -0.011369877394760088,
    price_change_percentage_24h: -2.23105,
    market_cap_change_24h: -2220339.829855144,
    market_cap_change_percentage_24h: -2.21919,
    circulating_supply: 196350222.538575,
    total_supply: 196350222.538575,
    max_supply: null,
    ath: 1.031,
    ath_change_percentage: -51.66761,
    ath_date: "2022-01-01T00:00:00.000Z",
    atl: 0.178949,
    atl_change_percentage: 178.43127,
    atl_date: "2023-06-10T04:30:33.652Z",
    roi: null,
    last_updated: "2023-06-28T12:02:07.445Z",
  },
  zen: {
    id: "zencash",
    symbol: "zen",
    name: "Horizen",
    image:
      "https://assets.coingecko.com/coins/images/691/large/horizen.png?1555052241",
    current_price: 7.06,
    market_cap: 97768764,
    market_cap_rank: 254,
    fully_diluted_valuation: 148311185,
    total_volume: 3752181,
    high_24h: 7.27,
    low_24h: 6.96,
    price_change_24h: -0.14817359587289314,
    price_change_percentage_24h: -2.05687,
    market_cap_change_24h: -1595923.8875870705,
    market_cap_change_percentage_24h: -1.60613,
    circulating_supply: 13843487.5,
    total_supply: 13843443.75,
    max_supply: 21000000,
    ath: 165.92,
    ath_change_percentage: -95.74426,
    ath_date: "2021-05-08T06:00:30.087Z",
    atl: 3.26,
    atl_change_percentage: 116.29628,
    atl_date: "2019-10-17T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:38.314Z",
  },
  cfg: {
    id: "centrifuge",
    symbol: "cfg",
    name: "Centrifuge",
    image:
      "https://assets.coingecko.com/coins/images/15380/large/centrifuge.PNG?1620695821",
    current_price: 0.269135,
    market_cap: 97085290,
    market_cap_rank: 255,
    fully_diluted_valuation: 120461425,
    total_volume: 867143,
    high_24h: 0.30674,
    low_24h: 0.264911,
    price_change_24h: -0.010323203953193196,
    price_change_percentage_24h: -3.69401,
    market_cap_change_24h: -3209475.6149464846,
    market_cap_change_percentage_24h: -3.20004,
    circulating_supply: 360263043,
    total_supply: 447006951,
    max_supply: null,
    ath: 15.98,
    ath_change_percentage: -98.31398,
    ath_date: "2021-05-30T00:00:00.000Z",
    atl: 0.139815,
    atl_change_percentage: 92.74374,
    atl_date: "2023-01-04T19:31:23.475Z",
    roi: null,
    last_updated: "2023-06-28T12:05:07.882Z",
  },
  rlc: {
    id: "iexec-rlc",
    symbol: "rlc",
    name: "iExec RLC",
    image:
      "https://assets.coingecko.com/coins/images/646/large/pL1VuXm.png?1604543202",
    current_price: 1.34,
    market_cap: 97001217,
    market_cap_rank: 256,
    fully_diluted_valuation: 116590052,
    total_volume: 4281195,
    high_24h: 1.4,
    low_24h: 1.32,
    price_change_24h: -0.05175423099000165,
    price_change_percentage_24h: -3.72907,
    market_cap_change_24h: -3644707.3238743395,
    market_cap_change_percentage_24h: -3.62132,
    circulating_supply: 72382548.06525737,
    total_supply: 86999784.9868455,
    max_supply: 86999784.9868455,
    ath: 15.51,
    ath_change_percentage: -91.36002,
    ath_date: "2021-05-10T17:59:51.557Z",
    atl: 0.153815,
    atl_change_percentage: 771.25607,
    atl_date: "2018-12-15T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:30.152Z",
  },
  wcfg: {
    id: "wrapped-centrifuge",
    symbol: "wcfg",
    name: "Wrapped Centrifuge",
    image:
      "https://assets.coingecko.com/coins/images/17106/large/WCFG.jpg?1626266462",
    current_price: 0.269098,
    market_cap: 96696948,
    market_cap_rank: 257,
    fully_diluted_valuation: 0,
    total_volume: 256621,
    high_24h: 0.303921,
    low_24h: 0.266181,
    price_change_24h: -0.00871915969454462,
    price_change_percentage_24h: -3.13846,
    market_cap_change_24h: -3290196.553970769,
    market_cap_change_percentage_24h: -3.29062,
    circulating_supply: 360263043,
    total_supply: 0,
    max_supply: 0,
    ath: 2.55,
    ath_change_percentage: -89.43675,
    ath_date: "2021-10-14T01:50:21.389Z",
    atl: 0.14244,
    atl_change_percentage: 89.10873,
    atl_date: "2023-01-04T18:19:37.018Z",
    roi: null,
    last_updated: "2023-06-28T12:05:08.839Z",
  },
  vvs: {
    id: "vvs-finance",
    symbol: "vvs",
    name: "VVS Finance",
    image:
      "https://assets.coingecko.com/coins/images/20210/large/8glAYOTM_400x400.jpg?1636667919",
    current_price: 0.00000267,
    market_cap: 96121374,
    market_cap_rank: 258,
    fully_diluted_valuation: 267672137,
    total_volume: 282302,
    high_24h: 0.00000272,
    low_24h: 0.00000267,
    price_change_24h: -3.3058904219e-8,
    price_change_percentage_24h: -1.22131,
    market_cap_change_24h: -626587.1973516643,
    market_cap_change_percentage_24h: -0.64765,
    circulating_supply: 35910115628293.3,
    total_supply: 73111457405680.3,
    max_supply: 100000000000000,
    ath: 0.00033093,
    ath_change_percentage: -99.19115,
    ath_date: "2021-11-15T10:53:45.925Z",
    atl: 0.00000256,
    atl_change_percentage: 4.52494,
    atl_date: "2023-06-15T11:48:43.317Z",
    roi: null,
    last_updated: "2023-06-28T12:05:09.068Z",
  },
  nu: {
    id: "nucypher",
    symbol: "nu",
    name: "NuCypher",
    image:
      "https://assets.coingecko.com/coins/images/3318/large/photo1198982838879365035.jpg?1547037916",
    current_price: 0.073675,
    market_cap: 95837488,
    market_cap_rank: 259,
    fully_diluted_valuation: 286107472,
    total_volume: 4402.87,
    high_24h: 0.079948,
    low_24h: 0.073637,
    price_change_24h: -0.002330878695712857,
    price_change_percentage_24h: -3.06671,
    market_cap_change_24h: -3037873.6756775826,
    market_cap_change_percentage_24h: -3.07243,
    circulating_supply: 1301490045.6367505,
    total_supply: 3885390081.74825,
    max_supply: 3885390081.74825,
    ath: 2.61,
    ath_change_percentage: -97.17807,
    ath_date: "2021-10-15T08:50:14.799Z",
    atl: 0.04999592,
    atl_change_percentage: 47.28551,
    atl_date: "2023-05-14T08:51:18.780Z",
    roi: null,
    last_updated: "2023-06-28T12:02:09.965Z",
  },
  kuji: {
    id: "kujira",
    symbol: "kuji",
    name: "Kujira",
    image:
      "https://assets.coingecko.com/coins/images/20685/large/kuji-200x200.png?1637557201",
    current_price: 0.828947,
    market_cap: 95702893,
    market_cap_rank: 260,
    fully_diluted_valuation: 101591978,
    total_volume: 152924,
    high_24h: 0.874747,
    low_24h: 0.829196,
    price_change_24h: -0.03221940960274672,
    price_change_percentage_24h: -3.74137,
    market_cap_change_24h: -3669865.8018509746,
    market_cap_change_percentage_24h: -3.69303,
    circulating_supply: 115302990.995054,
    total_supply: 122374481.990696,
    max_supply: 122398170,
    ath: 4.87,
    ath_change_percentage: -82.94752,
    ath_date: "2021-11-29T04:52:34.711Z",
    atl: 0.00178701,
    atl_change_percentage: 46346.92139,
    atl_date: "2022-05-20T01:46:32.095Z",
    roi: null,
    last_updated: "2023-06-28T12:05:30.602Z",
  },
  elon: {
    id: "dogelon-mars",
    symbol: "elon",
    name: "Dogelon Mars",
    image:
      "https://assets.coingecko.com/coins/images/14962/large/6GxcPRo3_400x400.jpg?1619157413",
    current_price: 1.73621e-7,
    market_cap: 95489643,
    market_cap_rank: 261,
    fully_diluted_valuation: 173728096,
    total_volume: 837214,
    high_24h: 1.81674e-7,
    low_24h: 1.72777e-7,
    price_change_24h: -4.528467364e-9,
    price_change_percentage_24h: -2.54194,
    market_cap_change_24h: -2444022.960105732,
    market_cap_change_percentage_24h: -2.49559,
    circulating_supply: 549649971723242.3,
    total_supply: 1000000000000000,
    max_supply: 1000000000000000,
    ath: 0.00000259,
    ath_change_percentage: -93.30194,
    ath_date: "2021-10-30T20:39:32.727Z",
    atl: 1.7563e-8,
    atl_change_percentage: 889.15035,
    atl_date: "2021-04-23T06:09:26.872Z",
    roi: null,
    last_updated: "2023-06-28T12:05:34.683Z",
  },
  wild: {
    id: "wilder-world",
    symbol: "wild",
    name: "Wilder World",
    image:
      "https://assets.coingecko.com/coins/images/15407/large/WWLogo_Gradient_Cirlce.png?1620743969",
    current_price: 0.378358,
    market_cap: 95331192,
    market_cap_rank: 262,
    fully_diluted_valuation: 189340963,
    total_volume: 491243,
    high_24h: 0.394146,
    low_24h: 0.378317,
    price_change_24h: -0.00060707918616737,
    price_change_percentage_24h: -0.16019,
    market_cap_change_24h: -63684.24553048611,
    market_cap_change_percentage_24h: -0.06676,
    circulating_supply: 251744764.9481594,
    total_supply: 500000000,
    max_supply: 500000000,
    ath: 7.44,
    ath_change_percentage: -94.9071,
    ath_date: "2021-11-27T15:44:06.182Z",
    atl: 0.0008609,
    atl_change_percentage: 43901.37479,
    atl_date: "2021-07-10T21:13:48.532Z",
    roi: null,
    last_updated: "2023-06-28T12:05:24.364Z",
  },
  pundix: {
    id: "pundi-x-2",
    symbol: "pundix",
    name: "Pundi X",
    image:
      "https://assets.coingecko.com/coins/images/14571/large/vDyefsXq_400x400.jpg?1617085003",
    current_price: 0.366723,
    market_cap: 95138657,
    market_cap_rank: 263,
    fully_diluted_valuation: 95138657,
    total_volume: 11011488,
    high_24h: 0.387633,
    low_24h: 0.361772,
    price_change_24h: -0.004278766391370204,
    price_change_percentage_24h: -1.1533,
    market_cap_change_24h: -792474.9649439007,
    market_cap_change_percentage_24h: -0.82609,
    circulating_supply: 258433177.099924,
    total_supply: 258433177.099924,
    max_supply: 258433177.099924,
    ath: 10.07,
    ath_change_percentage: -96.34367,
    ath_date: "2021-03-31T06:39:32.892Z",
    atl: 0.301639,
    atl_change_percentage: 22.04594,
    atl_date: "2022-12-30T15:12:34.853Z",
    roi: null,
    last_updated: "2023-06-28T12:05:39.386Z",
  },
  fx: {
    id: "fx-coin",
    symbol: "fx",
    name: "Function X",
    image:
      "https://assets.coingecko.com/coins/images/8186/large/47271330_590071468072434_707260356350705664_n.jpg?1556096683",
    current_price: 0.152092,
    market_cap: 94894670,
    market_cap_rank: 264,
    fully_diluted_valuation: 288197655,
    total_volume: 137416,
    high_24h: 0.155143,
    low_24h: 0.148592,
    price_change_24h: -0.002665951531640343,
    price_change_percentage_24h: -1.72266,
    market_cap_change_24h: -1430616.737868458,
    market_cap_change_percentage_24h: -1.48519,
    circulating_supply: 623314429.013896,
    total_supply: 623314429.013896,
    max_supply: 1893022625,
    ath: 2.2,
    ath_change_percentage: -93.08461,
    ath_date: "2021-08-30T03:08:34.715Z",
    atl: 0.03475487,
    atl_change_percentage: 338.03479,
    atl_date: "2020-03-13T02:29:11.980Z",
    roi: null,
    last_updated: "2023-06-28T12:05:34.304Z",
  },
  sfm: {
    id: "safemoon-2",
    symbol: "sfm",
    name: "SafeMoon",
    image:
      "https://assets.coingecko.com/coins/images/21863/large/photo_2021-12-22_14.43.36.jpeg?1640155440",
    current_price: 0.0001697,
    market_cap: 94192504,
    market_cap_rank: 265,
    fully_diluted_valuation: 168561832,
    total_volume: 1241406,
    high_24h: 0.00017322,
    low_24h: 0.00014052,
    price_change_24h: 0.00000395,
    price_change_percentage_24h: 2.38036,
    market_cap_change_24h: 1280175,
    market_cap_change_percentage_24h: 1.37783,
    circulating_supply: 558800905854.0665,
    total_supply: 1000000000000,
    max_supply: null,
    ath: 0.00338272,
    ath_change_percentage: -95.01846,
    ath_date: "2022-01-05T17:56:02.772Z",
    atl: 0.00010967,
    atl_change_percentage: 53.65017,
    atl_date: "2023-05-08T03:40:44.320Z",
    roi: null,
    last_updated: "2023-06-28T12:05:33.539Z",
  },
  celr: {
    id: "celer-network",
    symbol: "celr",
    name: "Celer Network",
    image:
      "https://assets.coingecko.com/coins/images/4379/large/Celr.png?1554705437",
    current_price: 0.016662,
    market_cap: 94141506,
    market_cap_rank: 266,
    fully_diluted_valuation: 166756280,
    total_volume: 5269608,
    high_24h: 0.01746633,
    low_24h: 0.01645331,
    price_change_24h: -0.000671794515034237,
    price_change_percentage_24h: -3.87563,
    market_cap_change_24h: -3767081.4672253877,
    market_cap_change_percentage_24h: -3.84755,
    circulating_supply: 5645454935.82688,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 0.194843,
    ath_change_percentage: -91.44168,
    ath_date: "2021-09-26T06:49:04.741Z",
    atl: 0.00095575,
    atl_change_percentage: 1644.74406,
    atl_date: "2020-03-13T02:24:17.287Z",
    roi: {
      times: 1.4868663315255866,
      currency: "usd",
      percentage: 148.68663315255867,
    },
    last_updated: "2023-06-28T12:05:20.448Z",
  },
  ckb: {
    id: "nervos-network",
    symbol: "ckb",
    name: "Nervos Network",
    image:
      "https://assets.coingecko.com/coins/images/9566/large/Nervos_White.png?1608280856",
    current_price: 0.00281065,
    market_cap: 93769106,
    market_cap_rank: 267,
    fully_diluted_valuation: null,
    total_volume: 3234569,
    high_24h: 0.00296712,
    low_24h: 0.00280064,
    price_change_24h: -0.000137799764571377,
    price_change_percentage_24h: -4.67363,
    market_cap_change_24h: -4373473.956887975,
    market_cap_change_percentage_24h: -4.45625,
    circulating_supply: 33340636263.0312,
    total_supply: null,
    max_supply: null,
    ath: 0.04370633,
    ath_change_percentage: -93.56632,
    ath_date: "2021-03-31T17:08:59.155Z",
    atl: 0.00197892,
    atl_change_percentage: 42.09388,
    atl_date: "2022-12-17T14:25:57.243Z",
    roi: {
      times: -0.7189346530771386,
      currency: "usd",
      percentage: -71.89346530771385,
    },
    last_updated: "2023-06-28T12:05:31.008Z",
  },
  strd: {
    id: "stride",
    symbol: "strd",
    name: "Stride",
    image:
      "https://assets.coingecko.com/coins/images/27275/large/STRD_800.png?1663116708",
    current_price: 1.083,
    market_cap: 92985393,
    market_cap_rank: 268,
    fully_diluted_valuation: 108431033,
    total_volume: 173706,
    high_24h: 1.19,
    low_24h: 1.039,
    price_change_24h: -0.04871301225276525,
    price_change_percentage_24h: -4.30465,
    market_cap_change_24h: -3222605.817431748,
    market_cap_change_percentage_24h: -3.34962,
    circulating_supply: 85755332.459262,
    total_supply: 85755332.459262,
    max_supply: 100000000,
    ath: 50,
    ath_change_percentage: -97.83346,
    ath_date: "2022-12-26T20:29:01.294Z",
    atl: 0.178275,
    atl_change_percentage: 507.6518,
    atl_date: "2023-01-04T00:19:07.141Z",
    roi: null,
    last_updated: "2023-06-28T12:05:31.385Z",
  },
  nft: {
    id: "apenft",
    symbol: "nft",
    name: "APENFT",
    image:
      "https://assets.coingecko.com/coins/images/15687/large/apenft.jpg?1621562368",
    current_price: 3.33709e-7,
    market_cap: 92542202,
    market_cap_rank: 269,
    fully_diluted_valuation: 333986893,
    total_volume: 8477370,
    high_24h: 3.40156e-7,
    low_24h: 3.30607e-7,
    price_change_24h: -5.198908617e-9,
    price_change_percentage_24h: -1.53402,
    market_cap_change_24h: -1309626.106309712,
    market_cap_change_percentage_24h: -1.39542,
    circulating_supply: 277080562500000,
    total_supply: 999990000000000,
    max_supply: 999990000000000,
    ath: 0.00000753,
    ath_change_percentage: -95.56435,
    ath_date: "2021-09-05T00:10:47.823Z",
    atl: 2.98737e-7,
    atl_change_percentage: 11.80614,
    atl_date: "2023-06-10T04:30:05.717Z",
    roi: null,
    last_updated: "2023-06-28T12:05:24.406Z",
  },
  rif: {
    id: "rif-token",
    symbol: "rif",
    name: "RSK Infrastructure Framework",
    image:
      "https://assets.coingecko.com/coins/images/7460/large/RIF.png?1558011767",
    current_price: 0.094289,
    market_cap: 91966184,
    market_cap_rank: 270,
    fully_diluted_valuation: 94483217,
    total_volume: 1389043,
    high_24h: 0.100152,
    low_24h: 0.093743,
    price_change_24h: -0.004828225512772927,
    price_change_percentage_24h: -4.87123,
    market_cap_change_24h: -4510528.651309133,
    market_cap_change_percentage_24h: -4.67525,
    circulating_supply: 973360001.262817,
    total_supply: 1000000000,
    max_supply: null,
    ath: 0.455938,
    ath_change_percentage: -79.27717,
    ath_date: "2021-04-12T21:44:18.713Z",
    atl: 0.0091475,
    atl_change_percentage: 932.88569,
    atl_date: "2019-06-12T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:29.084Z",
  },
  sys: {
    id: "syscoin",
    symbol: "sys",
    name: "Syscoin",
    image:
      "https://assets.coingecko.com/coins/images/119/large/Syscoin.png?1560401261",
    current_price: 0.128729,
    market_cap: 91798274,
    market_cap_rank: 271,
    fully_diluted_valuation: 96292881,
    total_volume: 2291320,
    high_24h: 0.134502,
    low_24h: 0.128235,
    price_change_24h: -0.004764280755224421,
    price_change_percentage_24h: -3.56892,
    market_cap_change_24h: -3402406.0024000257,
    market_cap_change_percentage_24h: -3.57393,
    circulating_supply: 713161636.961537,
    total_supply: 748079305.209992,
    max_supply: null,
    ath: 1.3,
    ath_change_percentage: -90.11493,
    ath_date: "2022-01-02T04:08:51.334Z",
    atl: 0.00020971,
    atl_change_percentage: 61280.76006,
    atl_date: "2015-02-22T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:37.059Z",
  },
  snt: {
    id: "status",
    symbol: "snt",
    name: "Status",
    image:
      "https://assets.coingecko.com/coins/images/779/large/status.png?1548610778",
    current_price: 0.02300812,
    market_cap: 91454291,
    market_cap_rank: 272,
    fully_diluted_valuation: 157135999,
    total_volume: 1767327,
    high_24h: 0.02409051,
    low_24h: 0.02288615,
    price_change_24h: -0.000317820710978443,
    price_change_percentage_24h: -1.36252,
    market_cap_change_24h: -946482.7059376538,
    market_cap_change_percentage_24h: -1.02432,
    circulating_supply: 3960483788.3096976,
    total_supply: 6804870174,
    max_supply: null,
    ath: 0.684918,
    ath_change_percentage: -96.62855,
    ath_date: "2018-01-03T00:00:00.000Z",
    atl: 0.00592935,
    atl_change_percentage: 289.44765,
    atl_date: "2020-03-13T02:10:36.877Z",
    roi: null,
    last_updated: "2023-06-28T12:05:32.516Z",
  },
  xno: {
    id: "nano",
    symbol: "xno",
    name: "Nano",
    image:
      "https://assets.coingecko.com/coins/images/756/large/nano.png?1637232468",
    current_price: 0.684757,
    market_cap: 91401206,
    market_cap_rank: 273,
    fully_diluted_valuation: 91401206,
    total_volume: 1455642,
    high_24h: 0.715777,
    low_24h: 0.679839,
    price_change_24h: -0.02023447526847555,
    price_change_percentage_24h: -2.87017,
    market_cap_change_24h: -1726246.5381400734,
    market_cap_change_percentage_24h: -1.85364,
    circulating_supply: 133248290,
    total_supply: 133248290,
    max_supply: 133248290,
    ath: 33.69,
    ath_change_percentage: -97.96417,
    ath_date: "2018-01-02T00:00:00.000Z",
    atl: 0.026179,
    atl_change_percentage: 2520.21682,
    atl_date: "2017-07-16T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:23.472Z",
  },
  metis: {
    id: "metis-token",
    symbol: "metis",
    name: "Metis",
    image:
      "https://assets.coingecko.com/coins/images/15595/large/metis.jpeg?1660285312",
    current_price: 20.47,
    market_cap: 89290389,
    market_cap_rank: 274,
    fully_diluted_valuation: 205061552,
    total_volume: 1073503,
    high_24h: 20.97,
    low_24h: 20.47,
    price_change_24h: -0.3599057065489184,
    price_change_percentage_24h: -1.7276,
    market_cap_change_24h: -1347064.0952025652,
    market_cap_change_percentage_24h: -1.48621,
    circulating_supply: 4354321.352,
    total_supply: 5410000.51,
    max_supply: 10000000,
    ath: 323.54,
    ath_change_percentage: -93.66199,
    ath_date: "2022-01-16T20:14:31.709Z",
    atl: 3.45,
    atl_change_percentage: 494.12384,
    atl_date: "2021-05-23T17:07:45.384Z",
    roi: null,
    last_updated: "2023-06-28T12:05:41.143Z",
  },
  omg: {
    id: "omisego",
    symbol: "omg",
    name: "OMG Network",
    image:
      "https://assets.coingecko.com/coins/images/776/large/OMG_Network.jpg?1591167168",
    current_price: 0.629352,
    market_cap: 88427485,
    market_cap_rank: 275,
    fully_diluted_valuation: 88427485,
    total_volume: 14955730,
    high_24h: 0.665421,
    low_24h: 0.624016,
    price_change_24h: -0.03053364056816399,
    price_change_percentage_24h: -4.62711,
    market_cap_change_24h: -4147605.916657284,
    market_cap_change_percentage_24h: -4.48026,
    circulating_supply: 140245398.245133,
    total_supply: 140245398.245133,
    max_supply: 140245398.245133,
    ath: 25.62,
    ath_change_percentage: -97.53959,
    ath_date: "2018-01-13T00:00:00.000Z",
    atl: 0.350917,
    atl_change_percentage: 79.63588,
    atl_date: "2020-03-13T02:30:10.072Z",
    roi: null,
    last_updated: "2023-06-28T12:05:38.861Z",
  },
  deso: {
    id: "deso",
    symbol: "deso",
    name: "Decentralized Social",
    image:
      "https://assets.coingecko.com/coins/images/16310/large/deso-logo-refresh-white.png?1666430590",
    current_price: 8.39,
    market_cap: 88418907,
    market_cap_rank: 276,
    fully_diluted_valuation: 90737949,
    total_volume: 1788785,
    high_24h: 8.8,
    low_24h: 8.38,
    price_change_24h: -0.19192554220530944,
    price_change_percentage_24h: -2.2367,
    market_cap_change_24h: -1719987.6593507528,
    market_cap_change_percentage_24h: -1.90815,
    circulating_supply: 10532253.8854,
    total_supply: 10808492.6854,
    max_supply: 10808492.6854,
    ath: 198.68,
    ath_change_percentage: -95.77466,
    ath_date: "2021-06-19T20:53:25.052Z",
    atl: 5.33,
    atl_change_percentage: 57.53986,
    atl_date: "2022-09-01T18:05:41.566Z",
    roi: null,
    last_updated: "2023-06-28T12:05:29.940Z",
  },
  erg: {
    id: "ergo",
    symbol: "erg",
    name: "Ergo",
    image:
      "https://assets.coingecko.com/coins/images/2484/large/Ergo.png?1574682618",
    current_price: 1.25,
    market_cap: 88365964,
    market_cap_rank: 277,
    fully_diluted_valuation: 121872859,
    total_volume: 301345,
    high_24h: 1.31,
    low_24h: 1.24,
    price_change_24h: -0.002925398551367753,
    price_change_percentage_24h: -0.23391,
    market_cap_change_24h: -328552.51180027425,
    market_cap_change_percentage_24h: -0.37043,
    circulating_supply: 70867974,
    total_supply: 97739924,
    max_supply: null,
    ath: 18.72,
    ath_change_percentage: -93.34002,
    ath_date: "2021-09-03T00:00:00.000Z",
    atl: 0.094191,
    atl_change_percentage: 1223.80744,
    atl_date: "2020-03-13T02:24:59.299Z",
    roi: null,
    last_updated: "2023-06-28T12:05:22.789Z",
  },
  nym: {
    id: "nym",
    symbol: "nym",
    name: "Nym",
    image:
      "https://assets.coingecko.com/coins/images/24488/large/NYM_Token.png?1649926353",
    current_price: 0.185895,
    market_cap: 88059538,
    market_cap_rank: 278,
    fully_diluted_valuation: 186040310,
    total_volume: 270240,
    high_24h: 0.187801,
    low_24h: 0.184475,
    price_change_24h: 0.00064558,
    price_change_percentage_24h: 0.34849,
    market_cap_change_24h: 380247,
    market_cap_change_percentage_24h: 0.43368,
    circulating_supply: 473335795.708321,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 5.76,
    ath_change_percentage: -96.77289,
    ath_date: "2022-04-15T05:09:27.468Z",
    atl: 0.155147,
    atl_change_percentage: 19.88363,
    atl_date: "2022-12-12T16:16:32.857Z",
    roi: null,
    last_updated: "2023-06-28T12:05:17.986Z",
  },
  savax: {
    id: "benqi-liquid-staked-avax",
    symbol: "savax",
    name: "BENQI Liquid Staked AVAX",
    image:
      "https://assets.coingecko.com/coins/images/23657/large/savax_blue.png?1644989825",
    current_price: 13.89,
    market_cap: 87981790,
    market_cap_rank: 279,
    fully_diluted_valuation: 87981790,
    total_volume: 288760,
    high_24h: 14.57,
    low_24h: 13.79,
    price_change_24h: -0.6317234950042483,
    price_change_percentage_24h: -4.34881,
    market_cap_change_24h: -3867029.9173231125,
    market_cap_change_percentage_24h: -4.21021,
    circulating_supply: 6323862.01942838,
    total_supply: 6323862.01942838,
    max_supply: null,
    ath: 103.55,
    ath_change_percentage: -86.56381,
    ath_date: "2022-04-02T13:44:54.612Z",
    atl: 11.27,
    atl_change_percentage: 23.39551,
    atl_date: "2022-12-30T15:13:12.976Z",
    roi: null,
    last_updated: "2023-06-28T12:05:12.160Z",
  },
  api3: {
    id: "api3",
    symbol: "api3",
    name: "API3",
    image:
      "https://assets.coingecko.com/coins/images/13256/large/api3.jpg?1606751424",
    current_price: 1.008,
    market_cap: 87278014,
    market_cap_rank: 280,
    fully_diluted_valuation: 126564236,
    total_volume: 2431563,
    high_24h: 1.049,
    low_24h: 0.989393,
    price_change_24h: -0.02347110423694665,
    price_change_percentage_24h: -2.27571,
    market_cap_change_24h: -1806538.313631922,
    market_cap_change_percentage_24h: -2.02789,
    circulating_supply: 86423159.9339623,
    total_supply: 125324588.47027,
    max_supply: 125324588.47027,
    ath: 10.3,
    ath_change_percentage: -90.19269,
    ath_date: "2021-04-07T06:54:35.507Z",
    atl: 0.870097,
    atl_change_percentage: 16.06604,
    atl_date: "2023-06-14T21:01:37.790Z",
    roi: null,
    last_updated: "2023-06-28T12:05:32.317Z",
  },
  ankreth: {
    id: "ankreth",
    symbol: "ankreth",
    name: "Ankr Staked ETH",
    image:
      "https://assets.coingecko.com/coins/images/13403/large/aETHc.png?1625756490",
    current_price: 2080.02,
    market_cap: 87007206,
    market_cap_rank: 281,
    fully_diluted_valuation: 87007206,
    total_volume: 66799,
    high_24h: 2128.69,
    low_24h: 2064.81,
    price_change_24h: -20.02205192163956,
    price_change_percentage_24h: -0.95341,
    market_cap_change_24h: -862917.2188583314,
    market_cap_change_percentage_24h: -0.98204,
    circulating_supply: 41783.4285492884,
    total_supply: 41783.4285492884,
    max_supply: null,
    ath: 4733.13,
    ath_change_percentage: -56.00431,
    ath_date: "2021-11-26T04:33:49.980Z",
    atl: 534.32,
    atl_change_percentage: 289.72691,
    atl_date: "2020-12-24T06:45:06.825Z",
    roi: null,
    last_updated: "2023-06-28T12:05:38.411Z",
  },
  glr: {
    id: "canvas-n-glr",
    symbol: "glr",
    name: "GalleryCoin",
    image:
      "https://assets.coingecko.com/coins/images/29372/large/%EA%B0%A4%EB%9F%AC%EB%A6%AC%EC%BD%94%EC%9D%B8_%EB%A1%9C%EA%B3%A0%28200_200%29.png?1678350274",
    current_price: 0.361451,
    market_cap: 86795445,
    market_cap_rank: 282,
    fully_diluted_valuation: 722628450,
    total_volume: 50165,
    high_24h: 0.373602,
    low_24h: 0.347537,
    price_change_24h: 0.0025343,
    price_change_percentage_24h: 0.7061,
    market_cap_change_24h: 576043,
    market_cap_change_percentage_24h: 0.66811,
    circulating_supply: 240221500,
    total_supply: 2000000000,
    max_supply: 2000000000,
    ath: 0.537019,
    ath_change_percentage: -32.78915,
    ath_date: "2023-03-13T13:02:57.592Z",
    atl: 0.223501,
    atl_change_percentage: 61.49127,
    atl_date: "2023-04-01T22:57:51.207Z",
    roi: null,
    last_updated: "2023-06-28T12:04:59.307Z",
  },
  alpha: {
    id: "alpha-finance",
    symbol: "alpha",
    name: "Stella",
    image:
      "https://assets.coingecko.com/coins/images/12738/large/Stella200x200-06.png?1684988292",
    current_price: 0.108342,
    market_cap: 86612389,
    market_cap_rank: 283,
    fully_diluted_valuation: 108536829,
    total_volume: 5093910,
    high_24h: 0.117461,
    low_24h: 0.108035,
    price_change_24h: -0.008536074938996455,
    price_change_percentage_24h: -7.30342,
    market_cap_change_24h: -6314976.327551708,
    market_cap_change_percentage_24h: -6.7956,
    circulating_supply: 798000000,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 2.93,
    ath_change_percentage: -96.2918,
    ath_date: "2021-02-05T16:37:55.153Z",
    atl: 0.02635756,
    atl_change_percentage: 311.78625,
    atl_date: "2020-11-03T04:33:55.723Z",
    roi: null,
    last_updated: "2023-06-28T12:05:31.273Z",
  },
  mim: {
    id: "magic-internet-money",
    symbol: "mim",
    name: "Magic Internet Money",
    image:
      "https://assets.coingecko.com/coins/images/16786/large/mimlogopng.png?1624979612",
    current_price: 0.996845,
    market_cap: 86135926,
    market_cap_rank: 284,
    fully_diluted_valuation: 134474983,
    total_volume: 628811,
    high_24h: 1,
    low_24h: 0.993524,
    price_change_24h: -0.001350838818164335,
    price_change_percentage_24h: -0.13533,
    market_cap_change_24h: 29206,
    market_cap_change_percentage_24h: 0.03392,
    circulating_supply: 86299906.1421461,
    total_supply: 134730988.524743,
    max_supply: null,
    ath: 1.041,
    ath_change_percentage: -4.12276,
    ath_date: "2021-10-06T13:25:17.751Z",
    atl: 0.876409,
    atl_change_percentage: 13.88913,
    atl_date: "2023-03-11T08:02:51.714Z",
    roi: null,
    last_updated: "2023-06-28T12:05:14.831Z",
  },
  pla: {
    id: "playdapp",
    symbol: "pla",
    name: "PlayDapp",
    image:
      "https://assets.coingecko.com/coins/images/14316/large/54023228.png?1615366911",
    current_price: 0.152208,
    market_cap: 86001796,
    market_cap_rank: 285,
    fully_diluted_valuation: 107477551,
    total_volume: 1418266,
    high_24h: 0.162,
    low_24h: 0.151954,
    price_change_24h: -0.005083010687200185,
    price_change_percentage_24h: -3.2316,
    market_cap_change_24h: -2041670.6224129647,
    market_cap_change_percentage_24h: -2.31893,
    circulating_supply: 560128667,
    total_supply: 700000000,
    max_supply: null,
    ath: 3.74,
    ath_change_percentage: -95.90253,
    ath_date: "2021-10-31T01:43:56.805Z",
    atl: 0.093206,
    atl_change_percentage: 64.60706,
    atl_date: "2021-06-22T13:53:57.916Z",
    roi: null,
    last_updated: "2023-06-28T12:05:25.919Z",
  },
  dka: {
    id: "dkargo",
    symbol: "dka",
    name: "dKargo",
    image:
      "https://assets.coingecko.com/coins/images/11875/large/bVD0g0dlmrEOPIkt943KZIBZ086eCshyY0jIQFti4zxYdOlFltU8tKa6uJlcA14HvNjX4bc7dxdMvlpoW5NFMND85oG4aiiCbFRhI6eowDfKEBY3BoSVY0IrBbA9SFGIxh_IYrkNC5uNdG-roZ0_TlGO3098now6Tbzga0p4IDqVk6lnaX3TuRC7pgnAYWZM15RD-uEIHr3O_3OoIIWP-.jpg?1595563347",
    current_price: 0.02789408,
    market_cap: 83656080,
    market_cap_rank: 286,
    fully_diluted_valuation: 139581890,
    total_volume: 12000317,
    high_24h: 0.03030897,
    low_24h: 0.02764772,
    price_change_24h: -0.000687396107414421,
    price_change_percentage_24h: -2.40504,
    market_cap_change_24h: -2000504.2866618782,
    market_cap_change_percentage_24h: -2.33549,
    circulating_supply: 2996666667,
    total_supply: 5000000000,
    max_supply: 5000000000,
    ath: 0.703797,
    ath_change_percentage: -95.96417,
    ath_date: "2021-03-16T03:34:23.945Z",
    atl: 0.02067481,
    atl_change_percentage: 37.38492,
    atl_date: "2021-02-01T14:18:25.997Z",
    roi: null,
    last_updated: "2023-06-28T12:05:26.155Z",
  },
  lqty: {
    id: "liquity",
    symbol: "lqty",
    name: "Liquity",
    image:
      "https://assets.coingecko.com/coins/images/14665/large/200-lqty-icon.png?1617631180",
    current_price: 0.89437,
    market_cap: 82965787,
    market_cap_rank: 287,
    fully_diluted_valuation: 89586879,
    total_volume: 9933619,
    high_24h: 0.96129,
    low_24h: 0.890554,
    price_change_24h: -0.054743747515992085,
    price_change_percentage_24h: -5.76788,
    market_cap_change_24h: -4873088.618235543,
    market_cap_change_percentage_24h: -5.54776,
    circulating_supply: 92609305.9406151,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 146.94,
    ath_change_percentage: -99.39033,
    ath_date: "2021-04-05T21:48:37.754Z",
    atl: 0.54399,
    atl_change_percentage: 64.6847,
    atl_date: "2022-11-14T07:05:51.360Z",
    roi: null,
    last_updated: "2023-06-28T12:05:40.663Z",
  },
  id: {
    id: "space-id",
    symbol: "id",
    name: "SPACE ID",
    image:
      "https://assets.coingecko.com/coins/images/29468/large/sid_token_logo_%28green2%29.png?1679454702",
    current_price: 0.288085,
    market_cap: 82474462,
    market_cap_rank: 288,
    fully_diluted_valuation: 576744488,
    total_volume: 30491465,
    high_24h: 0.315157,
    low_24h: 0.28647,
    price_change_24h: -0.02546513037310466,
    price_change_percentage_24h: -8.12155,
    market_cap_change_24h: -7149134.875564277,
    market_cap_change_percentage_24h: -7.97684,
    circulating_supply: 286000000,
    total_supply: 2000000000,
    max_supply: 2000000000,
    ath: 1.034,
    ath_change_percentage: -72.0163,
    ath_date: "2023-04-17T19:41:12.226Z",
    atl: 0.249841,
    atl_change_percentage: 15.77168,
    atl_date: "2023-06-12T02:51:28.702Z",
    roi: null,
    last_updated: "2023-06-28T12:05:35.061Z",
  },
  vtho: {
    id: "vethor-token",
    symbol: "vtho",
    name: "VeThor",
    image:
      "https://assets.coingecko.com/coins/images/5230/large/VTHO_Token_Icon.png?1680056937",
    current_price: 0.0012521,
    market_cap: 80907262,
    market_cap_rank: 289,
    fully_diluted_valuation: null,
    total_volume: 5125316,
    high_24h: 0.0013798,
    low_24h: 0.00121473,
    price_change_24h: -0.00004504063586816,
    price_change_percentage_24h: -3.4723,
    market_cap_change_24h: -432141.4376553744,
    market_cap_change_percentage_24h: -0.53128,
    circulating_supply: 64116759026,
    total_supply: null,
    max_supply: null,
    ath: 0.04671227,
    ath_change_percentage: -97.30369,
    ath_date: "2018-08-29T02:40:46.956Z",
    atl: 0.00015238,
    atl_change_percentage: 726.58296,
    atl_date: "2020-03-16T10:14:24.544Z",
    roi: null,
    last_updated: "2023-06-28T12:05:31.737Z",
  },
  mtl: {
    id: "metal",
    symbol: "mtl",
    name: "Metal DAO",
    image:
      "https://assets.coingecko.com/coins/images/763/large/Metal.png?1592195010",
    current_price: 1.25,
    market_cap: 80640832,
    market_cap_rank: 290,
    fully_diluted_valuation: 83080030,
    total_volume: 18536428,
    high_24h: 1.31,
    low_24h: 1.22,
    price_change_24h: -0.04261490682817337,
    price_change_percentage_24h: -3.29641,
    market_cap_change_24h: -3000115.0177108943,
    market_cap_change_percentage_24h: -3.5869,
    circulating_supply: 64633864.147,
    total_supply: 66588888,
    max_supply: null,
    ath: 17.03,
    ath_change_percentage: -92.67269,
    ath_date: "2018-06-21T04:04:27.124Z",
    atl: 0.117252,
    atl_change_percentage: 964.08023,
    atl_date: "2020-03-13T02:29:54.468Z",
    roi: {
      times: 5.94528332853755,
      currency: "usd",
      percentage: 594.528332853755,
    },
    last_updated: "2023-06-28T12:05:26.936Z",
  },
  hez: {
    id: "hermez-network-token",
    symbol: "hez",
    name: "Hermez Network",
    image:
      "https://assets.coingecko.com/coins/images/12826/large/hermez_logo.png?1602826556",
    current_price: 4.83,
    market_cap: 80439381,
    market_cap_rank: 291,
    fully_diluted_valuation: 91283768,
    total_volume: 15409.53,
    high_24h: 4.88,
    low_24h: 4.79,
    price_change_24h: -0.007621082371772837,
    price_change_percentage_24h: -0.15757,
    market_cap_change_24h: -361881.3116506338,
    market_cap_change_percentage_24h: -0.44787,
    circulating_supply: 16706072.3885572,
    total_supply: 18958291.6021449,
    max_supply: 18958291.6021449,
    ath: 10.3,
    ath_change_percentage: -53.25989,
    ath_date: "2021-12-26T21:51:13.032Z",
    atl: 1.81,
    atl_change_percentage: 166.14274,
    atl_date: "2020-10-16T09:35:16.795Z",
    roi: null,
    last_updated: "2023-06-28T12:04:26.885Z",
  },
  c98: {
    id: "coin98",
    symbol: "c98",
    name: "Coin98",
    image:
      "https://assets.coingecko.com/coins/images/17117/large/logo.png?1626412904",
    current_price: 0.150816,
    market_cap: 80382801,
    market_cap_rank: 292,
    fully_diluted_valuation: 151269258,
    total_volume: 5299702,
    high_24h: 0.159824,
    low_24h: 0.151023,
    price_change_24h: -0.006589522159339001,
    price_change_percentage_24h: -4.18635,
    market_cap_change_24h: -3273923.9441260844,
    market_cap_change_percentage_24h: -3.91352,
    circulating_supply: 531388879,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 6.42,
    ath_change_percentage: -97.64386,
    ath_date: "2021-08-25T12:26:13.409Z",
    atl: 0.123233,
    atl_change_percentage: 22.73663,
    atl_date: "2023-06-12T03:05:42.280Z",
    roi: null,
    last_updated: "2023-06-28T12:05:19.348Z",
  },
  nmr: {
    id: "numeraire",
    symbol: "nmr",
    name: "Numeraire",
    image:
      "https://assets.coingecko.com/coins/images/752/large/numeraire.png?1592538976",
    current_price: 12.67,
    market_cap: 79454607,
    market_cap_rank: 293,
    fully_diluted_valuation: 139460037,
    total_volume: 2989084,
    high_24h: 13.59,
    low_24h: 12.67,
    price_change_24h: -0.5986398850238235,
    price_change_percentage_24h: -4.51106,
    market_cap_change_24h: -3825913.8842151165,
    market_cap_change_percentage_24h: -4.59401,
    circulating_supply: 6267033.14952819,
    total_supply: 10868813.1687349,
    max_supply: 11000000,
    ath: 93.15,
    ath_change_percentage: -86.38966,
    ath_date: "2021-05-16T10:29:06.362Z",
    atl: 2.06,
    atl_change_percentage: 514.17971,
    atl_date: "2018-11-27T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:34.686Z",
  },
  rdnt: {
    id: "radiant-capital",
    symbol: "rdnt",
    name: "Radiant Capital",
    image:
      "https://assets.coingecko.com/coins/images/26536/large/Radiant-Logo-200x200.png?1658715865",
    current_price: 0.285687,
    market_cap: 78746087,
    market_cap_rank: 294,
    fully_diluted_valuation: 286365712,
    total_volume: 26558768,
    high_24h: 0.313658,
    low_24h: 0.282313,
    price_change_24h: -0.022780855080911544,
    price_change_percentage_24h: -7.38516,
    market_cap_change_24h: -6676018.464050785,
    market_cap_change_percentage_24h: -7.81533,
    circulating_supply: 274984342.856,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 0.585268,
    ath_change_percentage: -51.18693,
    ath_date: "2022-09-16T14:56:05.640Z",
    atl: 0.011166,
    atl_change_percentage: 2458.54449,
    atl_date: "2022-10-20T16:54:09.115Z",
    roi: null,
    last_updated: "2023-06-28T12:05:40.908Z",
  },
  lina: {
    id: "linear",
    symbol: "lina",
    name: "Linear",
    image:
      "https://assets.coingecko.com/coins/images/12509/large/1649227343-linalogo200px.png?1649229117",
    current_price: 0.01434543,
    market_cap: 78169192,
    market_cap_rank: 295,
    fully_diluted_valuation: 143483294,
    total_volume: 66094226,
    high_24h: 0.01594088,
    low_24h: 0.0142825,
    price_change_24h: -0.000441011907375536,
    price_change_percentage_24h: -2.98254,
    market_cap_change_24h: -2242918.622656733,
    market_cap_change_percentage_24h: -2.78928,
    circulating_supply: 5447964674.59296,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 0.298899,
    ath_change_percentage: -94.73888,
    ath_date: "2021-03-18T11:19:58.139Z",
    atl: 0.00471791,
    atl_change_percentage: 233.31414,
    atl_date: "2022-12-30T07:40:18.516Z",
    roi: null,
    last_updated: "2023-06-28T12:05:33.707Z",
  },
  rad: {
    id: "radicle",
    symbol: "rad",
    name: "Radicle",
    image:
      "https://assets.coingecko.com/coins/images/14013/large/radicle.png?1614402918",
    current_price: 1.57,
    market_cap: 78161959,
    market_cap_rank: 296,
    fully_diluted_valuation: 156860033,
    total_volume: 5514026,
    high_24h: 1.63,
    low_24h: 1.55,
    price_change_24h: -0.04894855338512438,
    price_change_percentage_24h: -3.03212,
    market_cap_change_24h: -2277248.8474767506,
    market_cap_change_percentage_24h: -2.83102,
    circulating_supply: 49829110.566345654,
    total_supply: 99998580,
    max_supply: 100000000,
    ath: 27.61,
    ath_change_percentage: -94.31766,
    ath_date: "2021-04-15T23:53:08.210Z",
    atl: 1.35,
    atl_change_percentage: 15.9261,
    atl_date: "2022-12-17T06:31:27.578Z",
    roi: null,
    last_updated: "2023-06-28T12:05:31.126Z",
  },
  nrv: {
    id: "nerve-finance",
    symbol: "nrv",
    name: "Nerve Finance",
    image:
      "https://assets.coingecko.com/coins/images/14233/large/nerve_finance_logo.png?1615176392",
    current_price: 0.00347231,
    market_cap: 77747624,
    market_cap_rank: 297,
    fully_diluted_valuation: 156256967,
    total_volume: 6669.62,
    high_24h: 0.00354815,
    low_24h: 0.0034141,
    price_change_24h: -0.00001421330107987,
    price_change_percentage_24h: -0.40766,
    market_cap_change_24h: -438368.18498294055,
    market_cap_change_percentage_24h: -0.56067,
    circulating_supply: 0,
    total_supply: 135916026.442548,
    max_supply: 100000000,
    ath: 6.65,
    ath_change_percentage: -99.94774,
    ath_date: "2021-05-03T09:18:55.106Z",
    atl: 0.00331039,
    atl_change_percentage: 5.04704,
    atl_date: "2023-06-20T19:22:49.661Z",
    roi: null,
    last_updated: "2023-06-28T12:05:21.290Z",
  },
  cweb: {
    id: "coinweb",
    symbol: "cweb",
    name: "Coinweb",
    image:
      "https://assets.coingecko.com/coins/images/21607/large/Logo_200x200.png?1641566717",
    current_price: 0.02430551,
    market_cap: 77128336,
    market_cap_rank: 298,
    fully_diluted_valuation: 186709458,
    total_volume: 443411,
    high_24h: 0.02452451,
    low_24h: 0.02422352,
    price_change_24h: -0.00016803914438665,
    price_change_percentage_24h: -0.68662,
    market_cap_change_24h: -277879.1292435825,
    market_cap_change_percentage_24h: -0.35899,
    circulating_supply: 3172552829,
    total_supply: 7647493628.83741,
    max_supply: 7680000000,
    ath: 0.661693,
    ath_change_percentage: -96.32581,
    ath_date: "2021-12-30T10:05:04.543Z",
    atl: 0.01039959,
    atl_change_percentage: 133.77711,
    atl_date: "2022-12-26T22:06:36.422Z",
    roi: null,
    last_updated: "2023-06-28T12:04:53.731Z",
  },
  kau: {
    id: "kinesis-gold",
    symbol: "kau",
    name: "Kinesis Gold",
    image:
      "https://assets.coingecko.com/coins/images/29788/large/kau-currency-ticker.png?1681290839",
    current_price: 61.33,
    market_cap: 76907791,
    market_cap_rank: 299,
    fully_diluted_valuation: null,
    total_volume: 76732,
    high_24h: 62.1,
    low_24h: 61.33,
    price_change_24h: -0.6351808741465916,
    price_change_percentage_24h: -1.025,
    market_cap_change_24h: -547890.4964413643,
    market_cap_change_percentage_24h: -0.70736,
    circulating_supply: 1252458.5042,
    total_supply: 1214963,
    max_supply: null,
    ath: 99.98,
    ath_change_percentage: -38.58266,
    ath_date: "2023-04-16T21:00:27.925Z",
    atl: 44.06,
    atl_change_percentage: 39.37933,
    atl_date: "2023-04-21T21:02:50.248Z",
    roi: null,
    last_updated: "2023-06-28T12:05:25.820Z",
  },
  pyr: {
    id: "vulcan-forged",
    symbol: "pyr",
    name: "Vulcan Forged",
    image:
      "https://assets.coingecko.com/coins/images/14770/large/1617088937196.png?1619414736",
    current_price: 3.21,
    market_cap: 76905285,
    market_cap_rank: 300,
    fully_diluted_valuation: 160905203,
    total_volume: 2514878,
    high_24h: 3.32,
    low_24h: 3.19,
    price_change_24h: -0.08773481459277255,
    price_change_percentage_24h: -2.65976,
    market_cap_change_24h: -1979054.2391866744,
    market_cap_change_percentage_24h: -2.5088,
    circulating_supply: 23897700,
    total_supply: 50000000,
    max_supply: 50000000,
    ath: 49.24,
    ath_change_percentage: -93.46426,
    ath_date: "2021-12-01T08:38:57.006Z",
    atl: 1.16,
    atl_change_percentage: 176.71653,
    atl_date: "2021-06-22T12:55:49.177Z",
    roi: null,
    last_updated: "2023-06-28T12:05:28.561Z",
  },
  steem: {
    id: "steem",
    symbol: "steem",
    name: "Steem",
    image:
      "https://assets.coingecko.com/coins/images/398/large/steem.png?1547034126",
    current_price: 0.175039,
    market_cap: 76708773,
    market_cap_rank: 301,
    fully_diluted_valuation: null,
    total_volume: 773929,
    high_24h: 0.185096,
    low_24h: 0.175114,
    price_change_24h: -0.005358124656443281,
    price_change_percentage_24h: -2.97019,
    market_cap_change_24h: -2190057.287816614,
    market_cap_change_percentage_24h: -2.77578,
    circulating_supply: 437549854.758,
    total_supply: null,
    max_supply: null,
    ath: 8.19,
    ath_change_percentage: -97.85941,
    ath_date: "2018-01-03T00:00:00.000Z",
    atl: 0.07199,
    atl_change_percentage: 143.56849,
    atl_date: "2017-03-11T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:53.107Z",
  },
  dust: {
    id: "dust-protocol",
    symbol: "dust",
    name: "Dust Protocol",
    image:
      "https://assets.coingecko.com/coins/images/24289/large/6388d49d-1f00-448d-92bc-f2db1441bbce.?1685976066",
    current_price: 2.3,
    market_cap: 76591448,
    market_cap_rank: 302,
    fully_diluted_valuation: 76596465,
    total_volume: 151742,
    high_24h: 2.3,
    low_24h: 2.17,
    price_change_24h: 0.06697,
    price_change_percentage_24h: 3.00279,
    market_cap_change_24h: 2051397,
    market_cap_change_percentage_24h: 2.75207,
    circulating_supply: 33297819,
    total_supply: 33297819,
    max_supply: 33300000,
    ath: 7.58,
    ath_change_percentage: -69.67883,
    ath_date: "2022-05-29T15:11:01.548Z",
    atl: 0.0008758,
    atl_change_percentage: 262473.94238,
    atl_date: "2022-03-25T22:50:08.024Z",
    roi: null,
    last_updated: "2023-06-28T12:05:19.477Z",
  },
  emaid: {
    id: "maidsafecoin",
    symbol: "emaid",
    name: "MaidSafeCoin",
    image:
      "https://assets.coingecko.com/coins/images/80/large/maidsafecoin.png?1547033750",
    current_price: 0.23204,
    market_cap: 76302113,
    market_cap_rank: 303,
    fully_diluted_valuation: null,
    total_volume: 5054.25,
    high_24h: 0.234209,
    low_24h: 0.17741,
    price_change_24h: -0.001125827690158038,
    price_change_percentage_24h: -0.48284,
    market_cap_change_24h: -1855493.4307305813,
    market_cap_change_percentage_24h: -2.37404,
    circulating_supply: 0,
    total_supply: 2304971,
    max_supply: null,
    ath: 1.37,
    ath_change_percentage: -83.11259,
    ath_date: "2021-04-12T14:04:59.247Z",
    atl: 0.00503241,
    atl_change_percentage: 4510.91813,
    atl_date: "2022-12-09T03:15:34.654Z",
    roi: {
      times: 6.734680357436167,
      currency: "usd",
      percentage: 673.4680357436166,
    },
    last_updated: "2023-06-27T22:30:11.832Z",
  },
  orbs: {
    id: "orbs",
    symbol: "orbs",
    name: "Orbs",
    image:
      "https://assets.coingecko.com/coins/images/4630/large/Orbs.jpg?1547039896",
    current_price: 0.0236304,
    market_cap: 76094508,
    market_cap_rank: 304,
    fully_diluted_valuation: 236502964,
    total_volume: 2778340,
    high_24h: 0.02452701,
    low_24h: 0.02342693,
    price_change_24h: -0.000022304674518688,
    price_change_percentage_24h: -0.0943,
    market_cap_change_24h: -58191.28224310279,
    market_cap_change_percentage_24h: -0.07641,
    circulating_supply: 3217486459.3889,
    total_supply: 10000000000,
    max_supply: null,
    ath: 0.360443,
    ath_change_percentage: -93.43609,
    ath_date: "2021-03-16T10:14:34.928Z",
    atl: 0.00469039,
    atl_change_percentage: 404.41764,
    atl_date: "2019-12-20T09:02:40.254Z",
    roi: null,
    last_updated: "2023-06-28T12:05:58.476Z",
  },
  kag: {
    id: "kinesis-silver",
    symbol: "kag",
    name: "Kinesis Silver",
    image:
      "https://assets.coingecko.com/coins/images/29789/large/kag-currency-ticker.png?1681290889",
    current_price: 22.74,
    market_cap: 75937872,
    market_cap_rank: 305,
    fully_diluted_valuation: null,
    total_volume: 82065,
    high_24h: 23.26,
    low_24h: 22.74,
    price_change_24h: -0.4107198992611103,
    price_change_percentage_24h: -1.7738,
    market_cap_change_24h: -1201925.0975438356,
    market_cap_change_percentage_24h: -1.55811,
    circulating_supply: 3334507.1746,
    total_supply: 3046325,
    max_supply: null,
    ath: 27.75,
    ath_change_percentage: -17.97324,
    ath_date: "2023-04-16T21:03:32.945Z",
    atl: 22.32,
    atl_change_percentage: 1.99973,
    atl_date: "2023-06-23T16:28:22.306Z",
    roi: null,
    last_updated: "2023-06-28T12:05:28.432Z",
  },
  vbg: {
    id: "vibing",
    symbol: "vbg",
    name: "Vibing",
    image:
      "https://assets.coingecko.com/coins/images/27549/large/DfQRpDCm_400x400.jpeg?1664442575",
    current_price: 0.52679,
    market_cap: 75373058,
    market_cap_rank: 306,
    fully_diluted_valuation: 526789612,
    total_volume: 525.99,
    high_24h: 0.545444,
    low_24h: 0.52548,
    price_change_24h: -0.006327455834386321,
    price_change_percentage_24h: -1.18688,
    market_cap_change_24h: -917030.4127842784,
    market_cap_change_percentage_24h: -1.20203,
    circulating_supply: 143080000,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 1.83,
    ath_change_percentage: -71.16499,
    ath_date: "2023-04-08T02:44:02.806Z",
    atl: 0.03711035,
    atl_change_percentage: 1319.5219,
    atl_date: "2022-09-30T12:32:03.608Z",
    roi: null,
    last_updated: "2023-06-28T12:03:22.145Z",
  },
  mc: {
    id: "merit-circle",
    symbol: "mc",
    name: "Merit Circle",
    image:
      "https://assets.coingecko.com/coins/images/19304/large/Db4XqML.png?1634972154",
    current_price: 0.189511,
    market_cap: 74915695,
    market_cap_rank: 307,
    fully_diluted_valuation: 122727986,
    total_volume: 1185600,
    high_24h: 0.194486,
    low_24h: 0.186999,
    price_change_24h: -0.004295020676038264,
    price_change_percentage_24h: -2.21615,
    market_cap_change_24h: -1595905.9311492592,
    market_cap_change_percentage_24h: -2.08584,
    circulating_supply: 394619262.01188964,
    total_supply: 646471043.192212,
    max_supply: 646471043.192212,
    ath: 11.7,
    ath_change_percentage: -98.3779,
    ath_date: "2021-12-02T05:11:33.337Z",
    atl: 0.160326,
    atl_change_percentage: 18.40141,
    atl_date: "2023-06-15T11:20:26.674Z",
    roi: null,
    last_updated: "2023-06-28T12:05:24.856Z",
  },
  ali: {
    id: "alethea-artificial-liquid-intelligence-token",
    symbol: "ali",
    name: "Artificial Liquid Intelligence",
    image:
      "https://assets.coingecko.com/coins/images/22062/large/alethea-logo-transparent-colored.png?1642748848",
    current_price: 0.02084078,
    market_cap: 74863191,
    market_cap_rank: 308,
    fully_diluted_valuation: 208639288,
    total_volume: 1311955,
    high_24h: 0.02130946,
    low_24h: 0.02074503,
    price_change_24h: -0.000399324950474643,
    price_change_percentage_24h: -1.88005,
    market_cap_change_24h: -1309401.8113123327,
    market_cap_change_percentage_24h: -1.71899,
    circulating_supply: 3588163659.855413,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 0.201312,
    ath_change_percentage: -89.63829,
    ath_date: "2022-04-02T01:23:27.379Z",
    atl: 0.00565078,
    atl_change_percentage: 269.14121,
    atl_date: "2022-11-13T21:26:06.429Z",
    roi: null,
    last_updated: "2023-06-28T12:05:09.742Z",
  },
  dent: {
    id: "dent",
    symbol: "dent",
    name: "Dent",
    image:
      "https://assets.coingecko.com/coins/images/1152/large/gLCEA2G.png?1604543239",
    current_price: 0.00078004,
    market_cap: 74729920,
    market_cap_rank: 309,
    fully_diluted_valuation: 78124459,
    total_volume: 1707965,
    high_24h: 0.00081421,
    low_24h: 0.00076277,
    price_change_24h: -0.000029856141788588,
    price_change_percentage_24h: -3.6864,
    market_cap_change_24h: -2402155.8687775135,
    market_cap_change_percentage_24h: -3.11434,
    circulating_supply: 95654960452.73587,
    total_supply: 100000000000,
    max_supply: null,
    ath: 0.1006,
    ath_change_percentage: -99.22342,
    ath_date: "2018-01-09T00:00:00.000Z",
    atl: 0.00007065,
    atl_change_percentage: 1005.80519,
    atl_date: "2020-03-13T02:29:09.754Z",
    roi: {
      times: 0.677511529142538,
      currency: "eth",
      percentage: 67.7511529142538,
    },
    last_updated: "2023-06-28T12:05:58.985Z",
  },
  med: {
    id: "medibloc",
    symbol: "med",
    name: "Medibloc",
    image:
      "https://assets.coingecko.com/coins/images/1374/large/medibloc_basic.png?1570607623",
    current_price: 0.01089978,
    market_cap: 74579526,
    market_cap_rank: 310,
    fully_diluted_valuation: 109159998,
    total_volume: 2565743,
    high_24h: 0.01157288,
    low_24h: 0.01083392,
    price_change_24h: -0.000477397173854088,
    price_change_percentage_24h: -4.1961,
    market_cap_change_24h: -3114445.3722918183,
    market_cap_change_percentage_24h: -4.00861,
    circulating_supply: 6832129723,
    total_supply: 8872457162.2,
    max_supply: 10000000000,
    ath: 0.351852,
    ath_change_percentage: -96.89756,
    ath_date: "2021-04-02T04:29:08.791Z",
    atl: 0.00161437,
    atl_change_percentage: 576.17681,
    atl_date: "2020-03-13T02:20:18.722Z",
    roi: {
      times: -0.4550110833168215,
      currency: "usd",
      percentage: -45.50110833168215,
    },
    last_updated: "2023-06-28T12:05:07.994Z",
  },
  stpt: {
    id: "stp-network",
    symbol: "stpt",
    name: "STP",
    image:
      "https://assets.coingecko.com/coins/images/8713/large/STP.png?1560262664",
    current_price: 0.03801989,
    market_cap: 74396066,
    market_cap_rank: 311,
    fully_diluted_valuation: 74396066,
    total_volume: 464723,
    high_24h: 0.03986487,
    low_24h: 0.03789721,
    price_change_24h: -0.00095657449764739,
    price_change_percentage_24h: -2.45424,
    market_cap_change_24h: -1229981.0728911906,
    market_cap_change_percentage_24h: -1.6264,
    circulating_supply: 1942420283.02707,
    total_supply: 1942420283.02707,
    max_supply: 1942420283.02707,
    ath: 0.270085,
    ath_change_percentage: -85.9137,
    ath_date: "2021-10-06T08:03:37.704Z",
    atl: 0.00646736,
    atl_change_percentage: 488.26183,
    atl_date: "2019-10-01T00:00:00.000Z",
    roi: {
      times: 2.801988953757562,
      currency: "usd",
      percentage: 280.1988953757562,
    },
    last_updated: "2023-06-28T12:06:01.271Z",
  },
  dexe: {
    id: "dexe",
    symbol: "dexe",
    name: "DeXe",
    image:
      "https://assets.coingecko.com/coins/images/12713/large/logo_%2814%29.png?1601952779",
    current_price: 2.03,
    market_cap: 74246631,
    market_cap_rank: 312,
    fully_diluted_valuation: 196306233,
    total_volume: 1071851,
    high_24h: 2.16,
    low_24h: 2.03,
    price_change_24h: -0.10419518447299358,
    price_change_percentage_24h: -4.87784,
    market_cap_change_24h: -3742529.94676432,
    market_cap_change_percentage_24h: -4.79878,
    circulating_supply: 36499815.81162295,
    total_supply: 96504599.3360945,
    max_supply: null,
    ath: 32.38,
    ath_change_percentage: -93.71802,
    ath_date: "2021-03-08T13:29:57.935Z",
    atl: 0.671563,
    atl_change_percentage: 202.90018,
    atl_date: "2020-11-06T19:31:40.144Z",
    roi: null,
    last_updated: "2023-06-28T12:06:01.642Z",
  },
  del: {
    id: "decimal",
    symbol: "del",
    name: "Decimal",
    image:
      "https://assets.coingecko.com/coins/images/17133/large/logo_-_2021-07-16T133613.232.png?1626413833",
    current_price: 0.01780495,
    market_cap: 73933617,
    market_cap_rank: 313,
    fully_diluted_valuation: 1639852257,
    total_volume: 22486,
    high_24h: 0.01816916,
    low_24h: 0.01746894,
    price_change_24h: -0.000142111170481754,
    price_change_percentage_24h: -0.79184,
    market_cap_change_24h: -210384.78224155307,
    market_cap_change_percentage_24h: -0.28375,
    circulating_supply: 4151278440.13737,
    total_supply: 4151101871.76097,
    max_supply: 92075616000,
    ath: 2.76,
    ath_change_percentage: -99.35432,
    ath_date: "2022-05-11T21:29:08.384Z",
    atl: 0.01221086,
    atl_change_percentage: 45.8525,
    atl_date: "2023-06-25T08:04:28.143Z",
    roi: null,
    last_updated: "2023-06-28T12:04:47.230Z",
  },
  alice: {
    id: "my-neighbor-alice",
    symbol: "alice",
    name: "My Neighbor Alice",
    image:
      "https://assets.coingecko.com/coins/images/14375/large/alice_logo.jpg?1615782968",
    current_price: 0.998853,
    market_cap: 73681803,
    market_cap_rank: 314,
    fully_diluted_valuation: 100033479,
    total_volume: 8226612,
    high_24h: 1.065,
    low_24h: 0.989465,
    price_change_24h: -0.06382648278923642,
    price_change_percentage_24h: -6.00618,
    market_cap_change_24h: -4543456.502079532,
    market_cap_change_percentage_24h: -5.80817,
    circulating_supply: 73657143,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 40.93,
    ath_change_percentage: -97.55599,
    ath_date: "2021-03-15T08:54:21.451Z",
    atl: 0.827832,
    atl_change_percentage: 20.83792,
    atl_date: "2023-06-12T02:51:06.749Z",
    roi: null,
    last_updated: "2023-06-28T12:05:59.500Z",
  },
  slp: {
    id: "smooth-love-potion",
    symbol: "slp",
    name: "Smooth Love Potion",
    image:
      "https://assets.coingecko.com/coins/images/10366/large/SLP.png?1578640057",
    current_price: 0.00172378,
    market_cap: 72536327,
    market_cap_rank: 315,
    fully_diluted_valuation: null,
    total_volume: 8781296,
    high_24h: 0.0017995,
    low_24h: 0.00169402,
    price_change_24h: 0.00000304,
    price_change_percentage_24h: 0.1764,
    market_cap_change_24h: 487254,
    market_cap_change_percentage_24h: 0.67628,
    circulating_supply: 41883420207,
    total_supply: null,
    max_supply: null,
    ath: 0.399727,
    ath_change_percentage: -99.56674,
    ath_date: "2021-07-13T10:04:32.975Z",
    atl: 0.00144374,
    atl_change_percentage: 19.95671,
    atl_date: "2023-06-15T07:21:01.377Z",
    roi: null,
    last_updated: "2023-06-28T12:05:58.865Z",
  },
  prom: {
    id: "prometeus",
    symbol: "prom",
    name: "Prom",
    image:
      "https://assets.coingecko.com/coins/images/8825/large/Ticker.png?1657632943",
    current_price: 3.96,
    market_cap: 72439934,
    market_cap_rank: 316,
    fully_diluted_valuation: 76409245,
    total_volume: 1951834,
    high_24h: 4.25,
    low_24h: 3.96,
    price_change_24h: -0.24361818766871002,
    price_change_percentage_24h: -5.79277,
    market_cap_change_24h: -4212261.7097462565,
    market_cap_change_percentage_24h: -5.49529,
    circulating_supply: 18250000,
    total_supply: 19250000,
    max_supply: 19250000,
    ath: 105.94,
    ath_change_percentage: -96.25301,
    ath_date: "2021-04-30T01:09:49.503Z",
    atl: 0.088747,
    atl_change_percentage: 4372.76013,
    atl_date: "2019-11-08T13:37:42.508Z",
    roi: {
      times: 2.961935941017183,
      currency: "usd",
      percentage: 296.1935941017183,
    },
    last_updated: "2023-06-28T12:05:57.232Z",
  },
  chr: {
    id: "chromaway",
    symbol: "chr",
    name: "Chromia",
    image:
      "https://assets.coingecko.com/coins/images/5000/large/Chromia.png?1559038018",
    current_price: 0.125817,
    market_cap: 72084493,
    market_cap_rank: 317,
    fully_diluted_valuation: 125956571,
    total_volume: 6096757,
    high_24h: 0.134185,
    low_24h: 0.125345,
    price_change_24h: -0.008098383625364887,
    price_change_percentage_24h: -6.04741,
    market_cap_change_24h: -4464896.382188022,
    market_cap_change_percentage_24h: -5.8327,
    circulating_supply: 572296409.423021,
    total_supply: 572296409.423021,
    max_supply: 1000000000,
    ath: 1.49,
    ath_change_percentage: -91.54637,
    ath_date: "2021-11-20T09:59:59.379Z",
    atl: 0.00874003,
    atl_change_percentage: 1341.72617,
    atl_date: "2020-03-13T02:25:03.256Z",
    roi: {
      times: 1.516331820692766,
      currency: "usd",
      percentage: 151.6331820692766,
    },
    last_updated: "2023-06-28T12:05:54.114Z",
  },
  cqt: {
    id: "covalent",
    symbol: "cqt",
    name: "Covalent",
    image:
      "https://assets.coingecko.com/coins/images/14168/large/covalent-cqt.png?1624545218",
    current_price: 0.118706,
    market_cap: 72045585,
    market_cap_rank: 318,
    fully_diluted_valuation: 118511613,
    total_volume: 678394,
    high_24h: 0.12306,
    low_24h: 0.117313,
    price_change_24h: -0.002008382259614444,
    price_change_percentage_24h: -1.66375,
    market_cap_change_24h: -1246900.9837276042,
    market_cap_change_percentage_24h: -1.70127,
    circulating_supply: 607920038.141746,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 2.08,
    ath_change_percentage: -94.27635,
    ath_date: "2021-08-14T05:30:40.858Z",
    atl: 0.051932,
    atl_change_percentage: 128.85635,
    atl_date: "2022-08-01T23:38:54.301Z",
    roi: null,
    last_updated: "2023-06-28T12:05:48.847Z",
  },
  bld: {
    id: "agoric",
    symbol: "bld",
    name: "Agoric",
    image:
      "https://assets.coingecko.com/coins/images/24487/large/agoric_bld_logo.png?1675187507",
    current_price: 0.129123,
    market_cap: 70672584,
    market_cap_rank: 320,
    fully_diluted_valuation: 161613279,
    total_volume: 233757,
    high_24h: 0.140112,
    low_24h: 0.128536,
    price_change_24h: -0.010952574424170776,
    price_change_percentage_24h: -7.81905,
    market_cap_change_24h: -5596876.709759995,
    market_cap_change_percentage_24h: -7.33829,
    circulating_supply: 546618015.266262,
    total_supply: 1040348278.85717,
    max_supply: 1250000000,
    ath: 0.751177,
    ath_change_percentage: -82.78827,
    ath_date: "2022-08-30T10:50:09.443Z",
    atl: 0.118136,
    atl_change_percentage: 9.44231,
    atl_date: "2023-06-20T21:13:55.821Z",
    roi: null,
    last_updated: "2023-06-28T12:05:58.808Z",
  },
  mvl: {
    id: "mass-vehicle-ledger",
    symbol: "mvl",
    name: "MVL",
    image:
      "https://assets.coingecko.com/coins/images/3476/large/mass-vehicle-ledger.png?1547978299",
    current_price: 0.00304715,
    market_cap: 70634263,
    market_cap_rank: 321,
    fully_diluted_valuation: 91522984,
    total_volume: 3537916,
    high_24h: 0.00315106,
    low_24h: 0.00302289,
    price_change_24h: -0.000022292677090954,
    price_change_percentage_24h: -0.72628,
    market_cap_change_24h: 314145,
    market_cap_change_percentage_24h: 0.44674,
    circulating_supply: 23152958863,
    total_supply: 27802958863,
    max_supply: 30000000000,
    ath: 0.06973,
    ath_change_percentage: -95.62492,
    ath_date: "2021-04-02T23:40:00.738Z",
    atl: 0.0001466,
    atl_change_percentage: 1980.95721,
    atl_date: "2020-03-13T02:04:01.608Z",
    roi: {
      times: 0.26964784933128666,
      currency: "usd",
      percentage: 26.964784933128666,
    },
    last_updated: "2023-06-28T12:06:02.318Z",
  },
  dock: {
    id: "dock",
    symbol: "dock",
    name: "Dock",
    image:
      "https://assets.coingecko.com/coins/images/3978/large/dock-icon-dark-large.png?1623764407",
    current_price: 0.01594609,
    market_cap: 70448413,
    market_cap_rank: 322,
    fully_diluted_valuation: null,
    total_volume: 2825129,
    high_24h: 0.01817845,
    low_24h: 0.01594609,
    price_change_24h: -0.000084379038523728,
    price_change_percentage_24h: -0.52637,
    market_cap_change_24h: -2344574.834913254,
    market_cap_change_percentage_24h: -3.22088,
    circulating_supply: 0,
    total_supply: 1000000000,
    max_supply: null,
    ath: 0.241848,
    ath_change_percentage: -93.3974,
    ath_date: "2018-05-04T05:29:09.155Z",
    atl: 0.00259319,
    atl_change_percentage: 515.77589,
    atl_date: "2020-03-13T02:24:35.312Z",
    roi: {
      times: -0.8958808907976272,
      currency: "eth",
      percentage: -89.58808907976272,
    },
    last_updated: "2023-06-28T12:05:50.791Z",
  },
  stsol: {
    id: "lido-staked-sol",
    symbol: "stsol",
    name: "Lido Staked SOL",
    image:
      "https://assets.coingecko.com/coins/images/18369/large/logo_-_2021-09-15T100934.765.png?1631671781",
    current_price: 17.98,
    market_cap: 70232804,
    market_cap_rank: 323,
    fully_diluted_valuation: 70232716,
    total_volume: 1095882,
    high_24h: 18.78,
    low_24h: 17.74,
    price_change_24h: -0.5720313126881287,
    price_change_percentage_24h: -3.08281,
    market_cap_change_24h: -1767874.3279605657,
    market_cap_change_percentage_24h: -2.45536,
    circulating_supply: 3900411.7359,
    total_supply: 3900406.846,
    max_supply: 3900406.846,
    ath: 263.24,
    ath_change_percentage: -93.15972,
    ath_date: "2021-11-07T01:28:34.051Z",
    atl: 8.65,
    atl_change_percentage: 108.05141,
    atl_date: "2022-12-29T20:46:29.701Z",
    roi: null,
    last_updated: "2023-06-28T12:05:20.211Z",
  },
  sgb: {
    id: "songbird",
    symbol: "sgb",
    name: "Songbird",
    image:
      "https://assets.coingecko.com/coins/images/18663/large/SGB_512x512.png?1645088006",
    current_price: 0.00605316,
    market_cap: 69875577,
    market_cap_rank: 324,
    fully_diluted_valuation: 105157497,
    total_volume: 392483,
    high_24h: 0.00616131,
    low_24h: 0.00593524,
    price_change_24h: 0.00000747,
    price_change_percentage_24h: 0.12355,
    market_cap_change_24h: 117004,
    market_cap_change_percentage_24h: 0.16773,
    circulating_supply: 11533502164.0455,
    total_supply: 17357054764.1662,
    max_supply: null,
    ath: 0.71334,
    ath_change_percentage: -99.1508,
    ath_date: "2021-09-30T06:32:35.621Z",
    atl: 0.0052183,
    atl_change_percentage: 16.086,
    atl_date: "2023-06-06T09:00:07.283Z",
    roi: null,
    last_updated: "2023-06-28T12:06:01.357Z",
  },
  iq: {
    id: "everipedia",
    symbol: "iq",
    name: "IQ",
    image:
      "https://assets.coingecko.com/coins/images/5010/large/YAIS3fUh.png?1626267646",
    current_price: 0.0044527,
    market_cap: 69785458,
    market_cap_rank: 325,
    fully_diluted_valuation: 93611165,
    total_volume: 258093,
    high_24h: 0.00470117,
    low_24h: 0.00444775,
    price_change_24h: -0.000086038152694094,
    price_change_percentage_24h: -1.89564,
    market_cap_change_24h: -1121372.910928741,
    market_cap_change_percentage_24h: -1.58147,
    circulating_supply: 15655126406.929,
    total_supply: 15655126406.929,
    max_supply: 21000000000,
    ath: 0.072382,
    ath_change_percentage: -93.8392,
    ath_date: "2018-07-16T07:35:38.970Z",
    atl: 0.00063047,
    atl_change_percentage: 607.29499,
    atl_date: "2020-03-13T02:29:15.457Z",
    roi: null,
    last_updated: "2023-06-28T12:05:58.002Z",
  },
  avinoc: {
    id: "avinoc",
    symbol: "avinoc",
    name: "AVINOC",
    image:
      "https://assets.coingecko.com/coins/images/4659/large/qX7fh69Q_400x400-1.jpg?1636680685",
    current_price: 0.41775,
    market_cap: 69761341,
    market_cap_rank: 326,
    fully_diluted_valuation: 417686150,
    total_volume: 93163,
    high_24h: 0.460629,
    low_24h: 0.408141,
    price_change_24h: -0.03527119516207594,
    price_change_percentage_24h: -7.78578,
    market_cap_change_24h: -5796054.513097212,
    market_cap_change_percentage_24h: -7.67106,
    circulating_supply: 167018564.1614316,
    total_supply: 1000000000,
    max_supply: null,
    ath: 3.29,
    ath_change_percentage: -87.30734,
    ath_date: "2023-04-26T06:10:17.483Z",
    atl: 0.00002988,
    atl_change_percentage: 1397599.29057,
    atl_date: "2020-02-18T12:24:30.841Z",
    roi: {
      times: 7.354991668510164,
      currency: "usd",
      percentage: 735.4991668510164,
    },
    last_updated: "2023-06-28T12:05:22.636Z",
  },
  scrt: {
    id: "secret",
    symbol: "scrt",
    name: "Secret",
    image:
      "https://assets.coingecko.com/coins/images/11871/large/secret_logo.png?1677312924",
    current_price: 0.330271,
    market_cap: 69738227,
    market_cap_rank: 327,
    fully_diluted_valuation: null,
    total_volume: 1071465,
    high_24h: 0.34438,
    low_24h: 0.330481,
    price_change_24h: -0.011621156669798904,
    price_change_percentage_24h: -3.39907,
    market_cap_change_24h: -2318033.134397924,
    market_cap_change_percentage_24h: -3.21698,
    circulating_supply: 211078254,
    total_supply: 190198234,
    max_supply: null,
    ath: 10.38,
    ath_change_percentage: -96.74071,
    ath_date: "2021-10-28T12:44:49.785Z",
    atl: 0.255219,
    atl_change_percentage: 32.61953,
    atl_date: "2020-11-03T06:12:33.048Z",
    roi: null,
    last_updated: "2023-06-28T12:06:02.413Z",
  },
  strax: {
    id: "stratis",
    symbol: "strax",
    name: "Stratis",
    image:
      "https://assets.coingecko.com/coins/images/531/large/stratis.png?1547034209",
    current_price: 0.461864,
    market_cap: 69617265,
    market_cap_rank: 328,
    fully_diluted_valuation: null,
    total_volume: 1643679,
    high_24h: 0.480344,
    low_24h: 0.450452,
    price_change_24h: 0.00974589,
    price_change_percentage_24h: 2.15561,
    market_cap_change_24h: 1702148,
    market_cap_change_percentage_24h: 2.50629,
    circulating_supply: 150597729.40598,
    total_supply: null,
    max_supply: null,
    ath: 22.77,
    ath_change_percentage: -97.96938,
    ath_date: "2018-01-08T00:00:00.000Z",
    atl: 0.0114071,
    atl_change_percentage: 3952.50246,
    atl_date: "2016-08-12T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:06:03.518Z",
  },
  shr: {
    id: "sharering",
    symbol: "shr",
    name: "Share",
    image:
      "https://assets.coingecko.com/coins/images/3609/large/74586729_2443914875881351_2785018663453851648_n.png?1574898410",
    current_price: 0.00174584,
    market_cap: 69453171,
    market_cap_rank: 329,
    fully_diluted_valuation: null,
    total_volume: 40468,
    high_24h: 0.0017597,
    low_24h: 0.00167384,
    price_change_24h: 0.00005258,
    price_change_percentage_24h: 3.10507,
    market_cap_change_24h: -462452.01236376166,
    market_cap_change_percentage_24h: -0.66144,
    circulating_supply: 0,
    total_supply: 4396000000,
    max_supply: null,
    ath: 0.098334,
    ath_change_percentage: -98.22405,
    ath_date: "2021-03-31T21:37:17.160Z",
    atl: 0.00008787,
    atl_change_percentage: 1887.54883,
    atl_date: "2020-02-28T05:20:11.672Z",
    roi: {
      times: -0.9127079348100128,
      currency: "usd",
      percentage: -91.27079348100128,
    },
    last_updated: "2023-06-28T12:05:42.363Z",
  },
  nest: {
    id: "nest",
    symbol: "nest",
    name: "Nest Protocol",
    image:
      "https://assets.coingecko.com/coins/images/11284/large/52954052.png?1589868539",
    current_price: 0.0115853,
    market_cap: 69203924,
    market_cap_rank: 330,
    fully_diluted_valuation: 116708115,
    total_volume: 1871334,
    high_24h: 0.01186634,
    low_24h: 0.0112056,
    price_change_24h: -0.000272589917027553,
    price_change_percentage_24h: -2.29881,
    market_cap_change_24h: -771778.5172866285,
    market_cap_change_percentage_24h: -1.10292,
    circulating_supply: 5929658302.90742,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 0.237647,
    ath_change_percentage: -95.08901,
    ath_date: "2020-08-10T01:30:18.287Z",
    atl: 0.00490848,
    atl_change_percentage: 137.76831,
    atl_date: "2021-12-14T00:24:41.641Z",
    roi: null,
    last_updated: "2023-06-28T12:05:21.157Z",
  },
  ecoin: {
    id: "ecoin-2",
    symbol: "ecoin",
    name: "Ecoin",
    image:
      "https://assets.coingecko.com/coins/images/10849/large/IMG_4307.jpg?1585628922",
    current_price: 0.00014991,
    market_cap: 69126113,
    market_cap_rank: 331,
    fully_diluted_valuation: 149908597,
    total_volume: 6,
    high_24h: null,
    low_24h: null,
    price_change_24h: null,
    price_change_percentage_24h: null,
    market_cap_change_24h: null,
    market_cap_change_percentage_24h: null,
    circulating_supply: 461121735617,
    total_supply: 1000000000000,
    max_supply: null,
    ath: 0.01276469,
    ath_change_percentage: -98.8256,
    ath_date: "2021-09-15T01:40:25.808Z",
    atl: 0.00000497,
    atl_change_percentage: 2919.03446,
    atl_date: "2022-10-24T03:23:34.581Z",
    roi: null,
    last_updated: "2023-06-27T01:48:50.807Z",
  },
  rlb: {
    id: "rollbit-coin",
    symbol: "rlb",
    name: "Rollbit Coin",
    image:
      "https://assets.coingecko.com/coins/images/24552/large/unziL6wO_400x400.jpg?1648134494",
    current_price: 0.0208599,
    market_cap: 68863644,
    market_cap_rank: 332,
    fully_diluted_valuation: 104435327,
    total_volume: 265600,
    high_24h: 0.02161419,
    low_24h: 0.02064884,
    price_change_24h: 0.00010547,
    price_change_percentage_24h: 0.50818,
    market_cap_change_24h: 437295,
    market_cap_change_percentage_24h: 0.63907,
    circulating_supply: 3296951621.59,
    total_supply: 3296951622,
    max_supply: 5000000000,
    ath: 0.063895,
    ath_change_percentage: -67.31035,
    ath_date: "2023-02-19T01:09:19.637Z",
    atl: 0.00093506,
    atl_change_percentage: 2133.76881,
    atl_date: "2022-07-02T02:46:34.730Z",
    roi: null,
    last_updated: "2023-06-28T12:05:20.657Z",
  },
  pcx: {
    id: "chainx",
    symbol: "pcx",
    name: "ChainX",
    image:
      "https://assets.coingecko.com/coins/images/9133/large/chainx.png?1572094352",
    current_price: 0.306571,
    market_cap: 68530128,
    market_cap_rank: 333,
    fully_diluted_valuation: null,
    total_volume: 18126.59,
    high_24h: 0.326325,
    low_24h: 0.294504,
    price_change_24h: 0.00944778,
    price_change_percentage_24h: 3.17975,
    market_cap_change_24h: -4578599.375680059,
    market_cap_change_percentage_24h: -6.26273,
    circulating_supply: 0,
    total_supply: 21000000,
    max_supply: null,
    ath: 17.54,
    ath_change_percentage: -98.25001,
    ath_date: "2021-03-29T19:44:08.082Z",
    atl: 0.215571,
    atl_change_percentage: 42.38419,
    atl_date: "2023-06-20T06:25:32.306Z",
    roi: null,
    last_updated: "2023-06-28T12:05:45.016Z",
  },
  wnxm: {
    id: "wrapped-nxm",
    symbol: "wnxm",
    name: "Wrapped NXM",
    image:
      "https://assets.coingecko.com/coins/images/11890/large/wNXM.png?1674799594",
    current_price: 28.76,
    market_cap: 68407141,
    market_cap_rank: 334,
    fully_diluted_valuation: 68407141,
    total_volume: 363556,
    high_24h: 29.74,
    low_24h: 28.64,
    price_change_24h: -0.6922934774153724,
    price_change_percentage_24h: -2.35077,
    market_cap_change_24h: -1578703.148229003,
    market_cap_change_percentage_24h: -2.25575,
    circulating_supply: 2373889.62153371,
    total_supply: 2373889.62153371,
    max_supply: 2373889.62153371,
    ath: 130.82,
    ath_change_percentage: -77.85951,
    ath_date: "2021-05-12T18:40:13.935Z",
    atl: 7.78,
    atl_change_percentage: 272.31902,
    atl_date: "2022-12-30T15:13:07.616Z",
    roi: null,
    last_updated: "2023-06-28T12:05:20.569Z",
  },
  ardr: {
    id: "ardor",
    symbol: "ardr",
    name: "Ardor",
    image:
      "https://assets.coingecko.com/coins/images/525/large/Ardor_Vertical_1.png?1606797362",
    current_price: 0.068409,
    market_cap: 68389770,
    market_cap_rank: 335,
    fully_diluted_valuation: 68389770,
    total_volume: 733218,
    high_24h: 0.070774,
    low_24h: 0.068243,
    price_change_24h: -0.000448304981697317,
    price_change_percentage_24h: -0.65107,
    market_cap_change_24h: -316372.4102598876,
    market_cap_change_percentage_24h: -0.46047,
    circulating_supply: 998466231,
    total_supply: 998466231,
    max_supply: null,
    ath: 2.04,
    ath_change_percentage: -96.64829,
    ath_date: "2018-01-13T00:00:00.000Z",
    atl: 0.008745,
    atl_change_percentage: 683.24557,
    atl_date: "2016-12-06T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:05:54.380Z",
  },
  pendle: {
    id: "pendle",
    symbol: "pendle",
    name: "Pendle",
    image:
      "https://assets.coingecko.com/coins/images/15069/large/Pendle_Logo_Normal-03.png?1634196276",
    current_price: 0.702598,
    market_cap: 68198591,
    market_cap_rank: 336,
    fully_diluted_valuation: 163003852,
    total_volume: 1810718,
    high_24h: 0.778121,
    low_24h: 0.681608,
    price_change_24h: -0.05791352848469344,
    price_change_percentage_24h: -7.61507,
    market_cap_change_24h: -5530035.594834492,
    market_cap_change_percentage_24h: -7.50053,
    circulating_supply: 96950723.3581583,
    total_supply: 231725335.029112,
    max_supply: 231725335.029112,
    ath: 2.45,
    ath_change_percentage: -71.28956,
    ath_date: "2021-05-05T22:24:39.415Z",
    atl: 0.03377729,
    atl_change_percentage: 1983.42192,
    atl_date: "2022-11-14T20:15:58.336Z",
    roi: null,
    last_updated: "2023-06-28T12:05:21.334Z",
  },
  vega: {
    id: "vega-protocol",
    symbol: "vega",
    name: "Vega Protocol",
    image:
      "https://assets.coingecko.com/coins/images/15870/large/vega.PNG?1622178218",
    current_price: 1.32,
    market_cap: 67176235,
    market_cap_rank: 337,
    fully_diluted_valuation: 85482616,
    total_volume: 185140,
    high_24h: 1.43,
    low_24h: 1.3,
    price_change_24h: -0.11060991025877054,
    price_change_percentage_24h: -7.74699,
    market_cap_change_24h: -5915067.821371168,
    market_cap_change_percentage_24h: -8.09271,
    circulating_supply: 51079820.8084177,
    total_supply: 64999723,
    max_supply: 64999723,
    ath: 23.93,
    ath_change_percentage: -94.50528,
    ath_date: "2021-09-03T13:02:36.189Z",
    atl: 0.76079,
    atl_change_percentage: 72.86267,
    atl_date: "2022-12-20T23:12:39.250Z",
    roi: null,
    last_updated: "2023-06-28T12:05:51.423Z",
  },
  people: {
    id: "constitutiondao",
    symbol: "people",
    name: "ConstitutionDAO",
    image:
      "https://assets.coingecko.com/coins/images/20612/large/GN_UVm3d_400x400.jpg?1637294355",
    current_price: 0.01321453,
    market_cap: 67086082,
    market_cap_rank: 338,
    fully_diluted_valuation: 67086082,
    total_volume: 9101673,
    high_24h: 0.01385981,
    low_24h: 0.01296113,
    price_change_24h: -0.00053963107390087,
    price_change_percentage_24h: -3.9234,
    market_cap_change_24h: -2556988.5789356753,
    market_cap_change_percentage_24h: -3.67156,
    circulating_supply: 5067194794.30456,
    total_supply: 5067194794.30456,
    max_supply: 5067194794.30456,
    ath: 0.185022,
    ath_change_percentage: -92.84488,
    ath_date: "2021-12-23T05:59:19.861Z",
    atl: 0.0009194,
    atl_change_percentage: 1339.91314,
    atl_date: "2021-11-21T11:33:21.508Z",
    roi: null,
    last_updated: "2023-06-28T12:05:59.349Z",
  },
  ult: {
    id: "shardus",
    symbol: "ult",
    name: "Shardus",
    image:
      "https://assets.coingecko.com/coins/images/8383/large/final_logo_photoshop.png?1557890272",
    current_price: 0.152255,
    market_cap: 66823643,
    market_cap_rank: 339,
    fully_diluted_valuation: 152025059,
    total_volume: 1353.36,
    high_24h: 0.158359,
    low_24h: 0.150595,
    price_change_24h: -0.001658375569692283,
    price_change_percentage_24h: -1.07747,
    market_cap_change_24h: -829920.6631284282,
    market_cap_change_percentage_24h: -1.22672,
    circulating_supply: 439556760.33010703,
    total_supply: 1000000000,
    max_supply: null,
    ath: 2.19,
    ath_change_percentage: -93.06968,
    ath_date: "2021-05-03T23:24:40.764Z",
    atl: 0.02304276,
    atl_change_percentage: 559.75194,
    atl_date: "2020-02-03T01:57:18.293Z",
    roi: null,
    last_updated: "2023-06-28T12:03:39.532Z",
  },
  multi: {
    id: "multichain",
    symbol: "multi",
    name: "Multichain",
    image:
      "https://assets.coingecko.com/coins/images/22087/large/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238",
    current_price: 3.42,
    market_cap: 66329355,
    market_cap_rank: 340,
    fully_diluted_valuation: 342552046,
    total_volume: 3348152,
    high_24h: 3.68,
    low_24h: 3.39,
    price_change_24h: -0.25627549121177484,
    price_change_percentage_24h: -6.97277,
    market_cap_change_24h: -4804367.678919636,
    market_cap_change_percentage_24h: -6.75399,
    circulating_supply: 19363292.6,
    total_supply: 100000000,
    max_supply: null,
    ath: 33.64,
    ath_change_percentage: -89.82129,
    ath_date: "2022-01-14T12:30:34.074Z",
    atl: 2.91,
    atl_change_percentage: 17.70133,
    atl_date: "2022-06-19T02:34:57.530Z",
    roi: null,
    last_updated: "2023-06-28T12:06:01.766Z",
  },
  gtc: {
    id: "gitcoin",
    symbol: "gtc",
    name: "Gitcoin",
    image:
      "https://assets.coingecko.com/coins/images/15810/large/gitcoin.png?1621992929",
    current_price: 1.088,
    market_cap: 66154024,
    market_cap_rank: 341,
    fully_diluted_valuation: 108692671,
    total_volume: 2540223,
    high_24h: 1.16,
    low_24h: 1.072,
    price_change_24h: -0.055162481687109466,
    price_change_percentage_24h: -4.82539,
    market_cap_change_24h: -3135337.3423704356,
    market_cap_change_percentage_24h: -4.52499,
    circulating_supply: 60863371.560695864,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 89.62,
    ath_change_percentage: -98.78735,
    ath_date: "2021-05-25T15:33:23.602Z",
    atl: 0.841165,
    atl_change_percentage: 29.20589,
    atl_date: "2023-06-10T04:31:37.518Z",
    roi: null,
    last_updated: "2023-06-28T12:05:57.909Z",
  },
  mura: {
    id: "murasaki",
    symbol: "mura",
    name: "Murasaki",
    image:
      "https://assets.coingecko.com/coins/images/28570/large/mura.png?1671795851",
    current_price: 1.15,
    market_cap: 65842366,
    market_cap_rank: 342,
    fully_diluted_valuation: 1147079545,
    total_volume: 2424.68,
    high_24h: 1.2,
    low_24h: 1.14,
    price_change_24h: -0.0485687588305912,
    price_change_percentage_24h: -4.06213,
    market_cap_change_24h: -2787084.5151144713,
    market_cap_change_percentage_24h: -4.06106,
    circulating_supply: 57400000,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 2.71,
    ath_change_percentage: -57.94979,
    ath_date: "2023-05-17T07:35:16.180Z",
    atl: 0.234193,
    atl_change_percentage: 386.39079,
    atl_date: "2023-03-21T20:58:36.232Z",
    roi: null,
    last_updated: "2023-06-28T12:03:22.181Z",
  },
  hft: {
    id: "hashflow",
    symbol: "hft",
    name: "Hashflow",
    image:
      "https://assets.coingecko.com/coins/images/26136/large/hashflow-icon-cmc.png?1668345672",
    current_price: 0.373404,
    market_cap: 65546847,
    market_cap_rank: 343,
    fully_diluted_valuation: 374063591,
    total_volume: 6125226,
    high_24h: 0.401171,
    low_24h: 0.373342,
    price_change_24h: -0.025697308541869242,
    price_change_percentage_24h: -6.4388,
    market_cap_change_24h: -4305204.143337838,
    market_cap_change_percentage_24h: -6.16332,
    circulating_supply: 175229156,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 3.61,
    ath_change_percentage: -89.65602,
    ath_date: "2022-11-07T13:04:41.540Z",
    atl: 0.199986,
    atl_change_percentage: 86.80088,
    atl_date: "2022-11-07T12:54:58.666Z",
    roi: null,
    last_updated: "2023-06-28T12:06:01.958Z",
  },
  cvc: {
    id: "civic",
    symbol: "cvc",
    name: "Civic",
    image:
      "https://assets.coingecko.com/coins/images/788/large/civic-orange.png?1657246016",
    current_price: 0.081475,
    market_cap: 65415028,
    market_cap_rank: 344,
    fully_diluted_valuation: 81564871,
    total_volume: 2807575,
    high_24h: 0.086482,
    low_24h: 0.081413,
    price_change_24h: -0.002325747761448949,
    price_change_percentage_24h: -2.77534,
    market_cap_change_24h: -1429440.1443248764,
    market_cap_change_percentage_24h: -2.13846,
    circulating_supply: 802000010,
    total_supply: 1000000000,
    max_supply: null,
    ath: 1.35,
    ath_change_percentage: -93.95246,
    ath_date: "2018-01-03T00:00:00.000Z",
    atl: 0.01102541,
    atl_change_percentage: 639.78998,
    atl_date: "2020-03-13T02:15:34.328Z",
    roi: {
      times: -0.1852537620551292,
      currency: "usd",
      percentage: -18.52537620551292,
    },
    last_updated: "2023-06-28T12:05:57.636Z",
  },
  xsgd: {
    id: "xsgd",
    symbol: "xsgd",
    name: "XSGD",
    image:
      "https://assets.coingecko.com/coins/images/12832/large/StraitsX_Singapore_Dollar_%28XSGD%29_Token_Logo.png?1633936813",
    current_price: 0.740172,
    market_cap: 65392591,
    market_cap_rank: 345,
    fully_diluted_valuation: 65392591,
    total_volume: 13499699,
    high_24h: 0.744033,
    low_24h: 0.738073,
    price_change_24h: -0.000596972282104313,
    price_change_percentage_24h: -0.08059,
    market_cap_change_24h: -78228.23775524646,
    market_cap_change_percentage_24h: -0.11949,
    circulating_supply: 88391259.501568,
    total_supply: 88391259.501568,
    max_supply: null,
    ath: 1.36,
    ath_change_percentage: -45.45531,
    ath_date: "2021-11-14T11:25:59.333Z",
    atl: 0.620483,
    atl_change_percentage: 19.23104,
    atl_date: "2021-05-19T13:21:15.750Z",
    roi: null,
    last_updated: "2023-06-28T12:05:54.319Z",
  },
  col: {
    id: "clash-of-lilliput",
    symbol: "col",
    name: "Clash of Lilliput",
    image:
      "https://assets.coingecko.com/coins/images/26070/large/col.png?1655540086",
    current_price: 0.792252,
    market_cap: 65023574,
    market_cap_rank: 346,
    fully_diluted_valuation: 792251593,
    total_volume: 98070,
    high_24h: 0.803579,
    low_24h: 0.787721,
    price_change_24h: -0.005209721267292355,
    price_change_percentage_24h: -0.65329,
    market_cap_change_24h: -452415.77058689296,
    market_cap_change_percentage_24h: -0.69096,
    circulating_supply: 82074400,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 1.9,
    ath_change_percentage: -58.23206,
    ath_date: "2022-08-16T20:57:32.272Z",
    atl: 0.256577,
    atl_change_percentage: 208.77766,
    atl_date: "2022-12-22T03:29:51.051Z",
    roi: null,
    last_updated: "2023-06-28T12:03:32.245Z",
  },
  xido: {
    id: "xido-finance",
    symbol: "xido",
    name: "Xido Finance",
    image:
      "https://assets.coingecko.com/coins/images/16161/large/KJw4clj.png?1623141959",
    current_price: 2.3,
    market_cap: 64574433,
    market_cap_rank: 347,
    fully_diluted_valuation: 228061651,
    total_volume: 16951.69,
    high_24h: 2.44,
    low_24h: 2.26,
    price_change_24h: -0.11979508596493593,
    price_change_percentage_24h: -4.95736,
    market_cap_change_24h: -3210884.996484585,
    market_cap_change_percentage_24h: -4.73684,
    circulating_supply: 28314463.6406,
    total_supply: 100000000,
    max_supply: null,
    ath: 481.05,
    ath_change_percentage: -99.52591,
    ath_date: "2021-06-09T18:56:55.804Z",
    atl: 0.528867,
    atl_change_percentage: 331.22651,
    atl_date: "2022-12-22T02:50:34.398Z",
    roi: null,
    last_updated: "2023-06-28T12:04:41.025Z",
  },
  win: {
    id: "wink",
    symbol: "win",
    name: "WINkLink",
    image:
      "https://assets.coingecko.com/coins/images/9129/large/WinK.png?1564624891",
    current_price: 0.0000671,
    market_cap: 64552482,
    market_cap_rank: 348,
    fully_diluted_valuation: 67053581,
    total_volume: 4007951,
    high_24h: 0.00006827,
    low_24h: 0.00006653,
    price_change_24h: -0.000001031761995915,
    price_change_percentage_24h: -1.51429,
    market_cap_change_24h: -946603.6477776095,
    market_cap_change_percentage_24h: -1.44522,
    circulating_supply: 961737300000,
    total_supply: 999000000000,
    max_supply: null,
    ath: 0.00289424,
    ath_change_percentage: -97.68096,
    ath_date: "2021-04-05T20:24:01.448Z",
    atl: 0.00004185,
    atl_change_percentage: 60.39724,
    atl_date: "2020-03-13T02:23:00.686Z",
    roi: {
      times: -0.4412719928287993,
      currency: "usd",
      percentage: -44.127199282879936,
    },
    last_updated: "2023-06-28T12:06:07.029Z",
  },
  vita: {
    id: "vitadao",
    symbol: "vita",
    name: "VitaDAO",
    image:
      "https://assets.coingecko.com/coins/images/16580/large/QmRjwywiAfpzSfQTuWM3zCTghSHN7G6ohQaar7Ht6WANUp.png?1624506420",
    current_price: 2.69,
    market_cap: 64094328,
    market_cap_rank: 349,
    fully_diluted_valuation: 173033019,
    total_volume: 191041,
    high_24h: 2.81,
    low_24h: 2.61,
    price_change_24h: 0.04201519,
    price_change_percentage_24h: 1.58791,
    market_cap_change_24h: 1112175,
    market_cap_change_percentage_24h: 1.76586,
    circulating_supply: 23817382.035323124,
    total_supply: 25719552,
    max_supply: 64298880,
    ath: 4.34,
    ath_change_percentage: -38.01038,
    ath_date: "2021-12-25T21:44:55.764Z",
    atl: 0,
    atl_change_percentage: 9.187923510526672e28,
    atl_date: "2021-12-13T10:32:39.011Z",
    roi: null,
    last_updated: "2023-06-28T12:05:32.713Z",
  },
  ccd: {
    id: "concordium",
    symbol: "ccd",
    name: "Concordium",
    image:
      "https://assets.coingecko.com/coins/images/23547/large/Sf_cldmL_400x400.jpg?1644398109",
    current_price: 0.00831445,
    market_cap: 62864079,
    market_cap_rank: 350,
    fully_diluted_valuation: 101232816,
    total_volume: 401439,
    high_24h: 0.00857587,
    low_24h: 0.00825146,
    price_change_24h: -0.000028967209198101,
    price_change_percentage_24h: -0.34719,
    market_cap_change_24h: -24.197311900556087,
    market_cap_change_percentage_24h: -0.00004,
    circulating_supply: 7551456864.65755,
    total_supply: 12160446129.5287,
    max_supply: null,
    ath: 0.149407,
    ath_change_percentage: -94.42814,
    ath_date: "2022-02-10T12:22:15.949Z",
    atl: 0.00622183,
    atl_change_percentage: 33.79936,
    atl_date: "2022-12-30T19:12:11.441Z",
    roi: null,
    last_updated: "2023-06-28T12:05:58.646Z",
  },
  heart: {
    id: "humans-ai",
    symbol: "heart",
    name: "Humans.ai",
    image:
      "https://assets.coingecko.com/coins/images/21273/large/h_logo_1x.png?1638858402",
    current_price: 0.01444694,
    market_cap: 62443138,
    market_cap_rank: 351,
    fully_diluted_valuation: 112815064,
    total_volume: 421366,
    high_24h: 0.01531763,
    low_24h: 0.01437215,
    price_change_24h: -0.000870683235224209,
    price_change_percentage_24h: -5.68419,
    market_cap_change_24h: -3642711.1518428326,
    market_cap_change_percentage_24h: -5.51209,
    circulating_supply: 4317300000,
    total_supply: 7800000000,
    max_supply: 7800000000,
    ath: 0.278557,
    ath_change_percentage: -94.80781,
    ath_date: "2021-12-26T18:32:54.961Z",
    atl: 0.00370636,
    atl_change_percentage: 290.22736,
    atl_date: "2022-12-18T06:41:04.832Z",
    roi: null,
    last_updated: "2023-06-28T12:06:04.425Z",
  },
  fidu: {
    id: "fidu",
    symbol: "fidu",
    name: "Fidu",
    image:
      "https://assets.coingecko.com/coins/images/25944/large/GFI-asset-icon.png?1654827482",
    current_price: 0.911415,
    market_cap: 62413918,
    market_cap_rank: 352,
    fully_diluted_valuation: 62413918,
    total_volume: 13495.46,
    high_24h: 0.914353,
    low_24h: 0.910586,
    price_change_24h: 0.00016418,
    price_change_percentage_24h: 0.01802,
    market_cap_change_24h: 46176,
    market_cap_change_percentage_24h: 0.07404,
    circulating_supply: 68480264.6325275,
    total_supply: 68480264.6325275,
    max_supply: null,
    ath: 1.11,
    ath_change_percentage: -18.15842,
    ath_date: "2022-11-08T04:22:06.310Z",
    atl: 0.761908,
    atl_change_percentage: 19.62265,
    atl_date: "2022-12-15T12:02:47.544Z",
    roi: null,
    last_updated: "2023-06-28T10:20:55.832Z",
  },
  mbx: {
    id: "marblex",
    symbol: "mbx",
    name: "Marblex",
    image:
      "https://assets.coingecko.com/coins/images/24423/large/AgYzKzLt_400x400.jpg?1647654911",
    current_price: 1.11,
    market_cap: 62215507,
    market_cap_rank: 353,
    fully_diluted_valuation: 1113058919,
    total_volume: 949111,
    high_24h: 1.19,
    low_24h: 1.099,
    price_change_24h: -0.07669650858233568,
    price_change_percentage_24h: -6.46166,
    market_cap_change_24h: -4027783.467762828,
    market_cap_change_percentage_24h: -6.08029,
    circulating_supply: 55895968.735621,
    total_supply: 999798906,
    max_supply: 1000000000,
    ath: 20.74,
    ath_change_percentage: -94.63256,
    ath_date: "2022-06-30T23:43:10.298Z",
    atl: 0.741924,
    atl_change_percentage: 50.02332,
    atl_date: "2023-06-15T05:59:40.578Z",
    roi: null,
    last_updated: "2023-06-28T12:06:04.347Z",
  },
  keep: {
    id: "keep-network",
    symbol: "keep",
    name: "Keep Network",
    image:
      "https://assets.coingecko.com/coins/images/3373/large/IuNzUb5b_400x400.jpg?1589526336",
    current_price: 0.112773,
    market_cap: 62066517,
    market_cap_rank: 354,
    fully_diluted_valuation: 112906452,
    total_volume: 353261,
    high_24h: 0.115524,
    low_24h: 0.112477,
    price_change_24h: -0.001616369085211497,
    price_change_percentage_24h: -1.41304,
    market_cap_change_24h: -1184724.3204657957,
    market_cap_change_percentage_24h: -1.87305,
    circulating_supply: 549716300.2685891,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 6.1,
    ath_change_percentage: -98.14881,
    ath_date: "2020-05-15T00:00:00.000Z",
    atl: 0.064431,
    atl_change_percentage: 75.26627,
    atl_date: "2023-02-28T19:02:38.676Z",
    roi: null,
    last_updated: "2023-06-28T12:05:01.723Z",
  },
  nkn: {
    id: "nkn",
    symbol: "nkn",
    name: "NKN",
    image:
      "https://assets.coingecko.com/coins/images/3375/large/nkn.png?1548329212",
    current_price: 0.094843,
    market_cap: 61805838,
    market_cap_rank: 355,
    fully_diluted_valuation: 95085905,
    total_volume: 3338743,
    high_24h: 0.100736,
    low_24h: 0.093817,
    price_change_24h: -0.004923113061388069,
    price_change_percentage_24h: -4.93464,
    market_cap_change_24h: -2673049.587321952,
    market_cap_change_percentage_24h: -4.14562,
    circulating_supply: 650000000,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 1.44,
    ath_change_percentage: -93.38606,
    ath_date: "2021-04-09T21:54:46.868Z",
    atl: 0.00667819,
    atl_change_percentage: 1322.10709,
    atl_date: "2020-03-13T02:22:29.583Z",
    roi: {
      times: -0.4730926757569293,
      currency: "usd",
      percentage: -47.309267575692935,
    },
    last_updated: "2023-06-28T12:06:00.517Z",
  },
  combo: {
    id: "cocos-bcx",
    symbol: "combo",
    name: "COMBO",
    image:
      "https://assets.coingecko.com/coins/images/4932/large/COMBO.jpg?1685695824",
    current_price: 0.868116,
    market_cap: 61784636,
    market_cap_rank: 356,
    fully_diluted_valuation: 86957235,
    total_volume: 3669289,
    high_24h: 0.948878,
    low_24h: 0.865444,
    price_change_24h: -0.0698934408457691,
    price_change_percentage_24h: -7.45125,
    market_cap_change_24h: -4810576.149284415,
    market_cap_change_percentage_24h: -7.22361,
    circulating_supply: 71051748,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 5.44,
    ath_change_percentage: -84.02615,
    ath_date: "2021-11-20T18:43:23.013Z",
    atl: 0.00018783,
    atl_change_percentage: 462907.07379,
    atl_date: "2020-03-13T02:22:35.503Z",
    roi: null,
    last_updated: "2023-06-28T12:06:06.367Z",
  },
  pond: {
    id: "marlin",
    symbol: "pond",
    name: "Marlin",
    image:
      "https://assets.coingecko.com/coins/images/8903/large/POND_200x200.png?1622515451",
    current_price: 0.00760146,
    market_cap: 61699097,
    market_cap_rank: 357,
    fully_diluted_valuation: 76290625,
    total_volume: 878874,
    high_24h: 0.00783412,
    low_24h: 0.00762371,
    price_change_24h: -0.000170733903180098,
    price_change_percentage_24h: -2.19673,
    market_cap_change_24h: -1183457.4178067893,
    market_cap_change_percentage_24h: -1.88201,
    circulating_supply: 8087375977,
    total_supply: 5033753055,
    max_supply: 10000000000,
    ath: 0.323362,
    ath_change_percentage: -97.64041,
    ath_date: "2020-12-21T23:37:33.267Z",
    atl: 0.00653027,
    atl_change_percentage: 16.84077,
    atl_date: "2022-05-12T07:20:26.962Z",
    roi: null,
    last_updated: "2023-06-28T12:05:54.426Z",
  },
  dero: {
    id: "dero",
    symbol: "dero",
    name: "Dero",
    image:
      "https://assets.coingecko.com/coins/images/3522/large/dero_logo_png.png?1582180390",
    current_price: 4.86,
    market_cap: 61698557,
    market_cap_rank: 358,
    fully_diluted_valuation: 102203499,
    total_volume: 79156,
    high_24h: 5.11,
    low_24h: 4.85,
    price_change_24h: -0.07955316831824798,
    price_change_percentage_24h: -1.60938,
    market_cap_change_24h: -833586.3698303774,
    market_cap_change_percentage_24h: -1.33305,
    circulating_supply: 12677351,
    total_supply: 18400000,
    max_supply: 20999999,
    ath: 27.29,
    ath_change_percentage: -82.16943,
    ath_date: "2021-10-10T06:37:31.666Z",
    atl: 0.248577,
    atl_change_percentage: 1857.87824,
    atl_date: "2020-03-13T02:22:55.215Z",
    roi: null,
    last_updated: "2023-06-28T12:05:32.597Z",
  },
  powr: {
    id: "power-ledger",
    symbol: "powr",
    name: "Power Ledger",
    image:
      "https://assets.coingecko.com/coins/images/1104/large/power-ledger.png?1547035082",
    current_price: 0.141764,
    market_cap: 60988064,
    market_cap_rank: 359,
    fully_diluted_valuation: 141927472,
    total_volume: 2274025,
    high_24h: 0.149076,
    low_24h: 0.140854,
    price_change_24h: -0.003080074239440206,
    price_change_percentage_24h: -2.12648,
    market_cap_change_24h: -1836065.5338927358,
    market_cap_change_percentage_24h: -2.92255,
    circulating_supply: 429712891.919905,
    total_supply: 1000000000,
    max_supply: null,
    ath: 1.89,
    ath_change_percentage: -92.49468,
    ath_date: "2018-01-07T00:00:00.000Z",
    atl: 0.03262537,
    atl_change_percentage: 335.01772,
    atl_date: "2020-03-13T02:22:32.160Z",
    roi: null,
    last_updated: "2023-06-28T12:06:04.622Z",
  },
  mbox: {
    id: "mobox",
    symbol: "mbox",
    name: "Mobox",
    image:
      "https://assets.coingecko.com/coins/images/14751/large/mobox.PNG?1618146979",
    current_price: 0.301665,
    market_cap: 60772962,
    market_cap_rank: 360,
    fully_diluted_valuation: 302016075,
    total_volume: 2144630,
    high_24h: 0.316589,
    low_24h: 0.300007,
    price_change_24h: -0.007736286806339143,
    price_change_percentage_24h: -2.5004,
    market_cap_change_24h: -1489462.5142744854,
    market_cap_change_percentage_24h: -2.39223,
    circulating_supply: 201224264,
    total_supply: 401296663.34627,
    max_supply: 1000000000,
    ath: 15.44,
    ath_change_percentage: -98.04522,
    ath_date: "2021-12-01T05:07:30.486Z",
    atl: 0.288979,
    atl_change_percentage: 4.46726,
    atl_date: "2023-06-15T11:31:07.604Z",
    roi: null,
    last_updated: "2023-06-28T12:06:00.749Z",
  },
  hunt: {
    id: "hunt-token",
    symbol: "hunt",
    name: "Hunt",
    image:
      "https://assets.coingecko.com/coins/images/7989/large/HUNT.png?1571024256",
    current_price: 0.30467,
    market_cap: 60592159,
    market_cap_rank: 361,
    fully_diluted_valuation: 60592159,
    total_volume: 3453232,
    high_24h: 0.323749,
    low_24h: 0.301925,
    price_change_24h: -0.004078246867503799,
    price_change_percentage_24h: -1.3209,
    market_cap_change_24h: -827539.2858779058,
    market_cap_change_percentage_24h: -1.34735,
    circulating_supply: 198912688,
    total_supply: 198912688,
    max_supply: 198912688,
    ath: 1.46,
    ath_change_percentage: -79.16015,
    ath_date: "2021-11-28T00:34:47.091Z",
    atl: 0.00039817,
    atl_change_percentage: 76430.31982,
    atl_date: "2020-04-25T10:36:47.549Z",
    roi: {
      times: 14.23348271892332,
      currency: "usd",
      percentage: 1423.348271892332,
    },
    last_updated: "2023-06-28T12:04:47.517Z",
  },
  ren: {
    id: "republic-protocol",
    symbol: "ren",
    name: "REN",
    image:
      "https://assets.coingecko.com/coins/images/3139/large/REN.png?1589985807",
    current_price: 0.059822,
    market_cap: 59911647,
    market_cap_rank: 362,
    fully_diluted_valuation: 59911647,
    total_volume: 5476884,
    high_24h: 0.063926,
    low_24h: 0.05985,
    price_change_24h: -0.003071018016197157,
    price_change_percentage_24h: -4.88293,
    market_cap_change_24h: -3098783.713034585,
    market_cap_change_percentage_24h: -4.91789,
    circulating_supply: 1000000000,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 1.8,
    ath_change_percentage: -96.67085,
    ath_date: "2021-02-20T00:17:32.664Z",
    atl: 0.01548,
    atl_change_percentage: 287.07808,
    atl_date: "2019-02-25T00:00:00.000Z",
    roi: {
      times: -0.44703475796963416,
      currency: "eth",
      percentage: -44.70347579696342,
    },
    last_updated: "2023-06-28T12:06:05.382Z",
  },
  pha: {
    id: "pha",
    symbol: "pha",
    name: "Phala",
    image:
      "https://assets.coingecko.com/coins/images/12451/large/phala.png?1600061318",
    current_price: 0.103057,
    market_cap: 59776735,
    market_cap_rank: 363,
    fully_diluted_valuation: 103312901,
    total_volume: 3021729,
    high_24h: 0.109139,
    low_24h: 0.102886,
    price_change_24h: -0.001666038593282931,
    price_change_percentage_24h: -1.5909,
    market_cap_change_24h: -651086.4477086216,
    market_cap_change_percentage_24h: -1.07746,
    circulating_supply: 578598940.372188,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 1.39,
    ath_change_percentage: -92.22861,
    ath_date: "2021-05-15T04:20:32.741Z",
    atl: 0.064594,
    atl_change_percentage: 67.3126,
    atl_date: "2020-09-12T12:15:05.468Z",
    roi: null,
    last_updated: "2023-06-28T12:05:54.867Z",
  },
  dacxi: {
    id: "dacxi",
    symbol: "dacxi",
    name: "Dacxi",
    image:
      "https://assets.coingecko.com/coins/images/4466/large/dacxi.png?1639042471",
    current_price: 0.00662244,
    market_cap: 59641862,
    market_cap_rank: 364,
    fully_diluted_valuation: 66268735,
    total_volume: 89327,
    high_24h: 0.00683964,
    low_24h: 0.00662281,
    price_change_24h: -0.000147859322463618,
    price_change_percentage_24h: -2.18394,
    market_cap_change_24h: -910074.9995075911,
    market_cap_change_percentage_24h: -1.50297,
    circulating_supply: 9000000000,
    total_supply: 10000000000,
    max_supply: 10000000000,
    ath: 0.067245,
    ath_change_percentage: -90.14692,
    ath_date: "2021-06-15T16:21:53.200Z",
    atl: 0.00001494,
    atl_change_percentage: 44243.34531,
    atl_date: "2023-05-10T13:14:45.706Z",
    roi: {
      times: -0.9084305808344549,
      currency: "btc",
      percentage: -90.8430580834455,
    },
    last_updated: "2023-06-28T12:05:05.010Z",
  },
  coti: {
    id: "coti",
    symbol: "coti",
    name: "COTI",
    image:
      "https://assets.coingecko.com/coins/images/2962/large/Coti.png?1559653863",
    current_price: 0.0490703,
    market_cap: 59609987,
    market_cap_rank: 365,
    fully_diluted_valuation: 98276676,
    total_volume: 2729551,
    high_24h: 0.050988,
    low_24h: 0.0482821,
    price_change_24h: -0.001737481209736401,
    price_change_percentage_24h: -3.41971,
    market_cap_change_24h: -1971070.6689184606,
    market_cap_change_percentage_24h: -3.20077,
    circulating_supply: 1213105477.22,
    total_supply: 2000000000,
    max_supply: 2000000000,
    ath: 0.668634,
    ath_change_percentage: -92.65242,
    ath_date: "2021-09-29T11:39:21.397Z",
    atl: 0.00556342,
    atl_change_percentage: 783.06172,
    atl_date: "2019-11-07T10:29:26.372Z",
    roi: {
      times: -0.24507229908654724,
      currency: "usd",
      percentage: -24.507229908654722,
    },
    last_updated: "2023-06-28T12:05:58.457Z",
  },
  bfc: {
    id: "bifrost",
    symbol: "bfc",
    name: "Bifrost",
    image:
      "https://assets.coingecko.com/coins/images/4639/large/bifrost_32.png?1608520677",
    current_price: 0.04681377,
    market_cap: 59422464,
    market_cap_rank: 366,
    fully_diluted_valuation: 110333047,
    total_volume: 554933,
    high_24h: 0.04860579,
    low_24h: 0.04565464,
    price_change_24h: -0.000468931775019936,
    price_change_percentage_24h: -0.99176,
    market_cap_change_24h: -1279974.506450273,
    market_cap_change_percentage_24h: -2.1086,
    circulating_supply: 1275656798.47739,
    total_supply: 2368584074,
    max_supply: 2368584074,
    ath: 0.778815,
    ath_change_percentage: -94.01664,
    ath_date: "2021-08-19T00:15:06.996Z",
    atl: 0.01634183,
    atl_change_percentage: 185.15378,
    atl_date: "2021-01-03T05:20:35.561Z",
    roi: null,
    last_updated: "2023-06-28T12:05:59.448Z",
  },
  gel: {
    id: "gelato",
    symbol: "gel",
    name: "Gelato",
    image:
      "https://assets.coingecko.com/coins/images/15026/large/Gelato_Icon_Logo_1024x1024.png?1619491717",
    current_price: 0.270472,
    market_cap: 59109885,
    market_cap_rank: 367,
    fully_diluted_valuation: 113751365,
    total_volume: 35344,
    high_24h: 0.277542,
    low_24h: 0.265954,
    price_change_24h: -0.003596118119084502,
    price_change_percentage_24h: -1.31212,
    market_cap_change_24h: -710898.7840865329,
    market_cap_change_percentage_24h: -1.18838,
    circulating_supply: 218607817.00817665,
    total_supply: 420690000,
    max_supply: 420690000,
    ath: 4.21,
    ath_change_percentage: -93.56977,
    ath_date: "2021-11-30T04:32:06.012Z",
    atl: 0.118108,
    atl_change_percentage: 128.9366,
    atl_date: "2023-01-07T00:40:50.196Z",
    roi: null,
    last_updated: "2023-06-28T12:05:25.224Z",
  },
  req: {
    id: "request-network",
    symbol: "req",
    name: "Request",
    image:
      "https://assets.coingecko.com/coins/images/1031/large/Request_icon_green.png?1643250951",
    current_price: 0.076459,
    market_cap: 58722006,
    market_cap_rank: 368,
    fully_diluted_valuation: 76375324,
    total_volume: 353016,
    high_24h: 0.078431,
    low_24h: 0.076026,
    price_change_24h: -0.001579296420135712,
    price_change_percentage_24h: -2.02375,
    market_cap_change_24h: -1184026.6121875197,
    market_cap_change_percentage_24h: -1.97647,
    circulating_supply: 768860978.7498047,
    total_supply: 999755995.505962,
    max_supply: 1000000000,
    ath: 1.059,
    ath_change_percentage: -92.78977,
    ath_date: "2018-01-06T00:00:00.000Z",
    atl: 0.00454707,
    atl_change_percentage: 1579.65721,
    atl_date: "2020-03-13T02:24:34.541Z",
    roi: {
      times: 0.27431244487917766,
      currency: "usd",
      percentage: 27.431244487917766,
    },
    last_updated: "2023-06-28T12:05:58.910Z",
  },
  mdx: {
    id: "mdex",
    symbol: "mdx",
    name: "Mdex (HECO)",
    image:
      "https://assets.coingecko.com/coins/images/13775/large/mdex.png?1611739676",
    current_price: 0.061731,
    market_cap: 58669785,
    market_cap_rank: 369,
    fully_diluted_valuation: 65446134,
    total_volume: 71857,
    high_24h: 0.063518,
    low_24h: 0.061029,
    price_change_24h: -0.000416892276923701,
    price_change_percentage_24h: -0.67081,
    market_cap_change_24h: -350638.2583550438,
    market_cap_change_percentage_24h: -0.5941,
    circulating_supply: 950246686.55354,
    total_supply: 1060000000,
    max_supply: null,
    ath: 10.11,
    ath_change_percentage: -99.38957,
    ath_date: "2021-02-22T07:08:38.957Z",
    atl: 0.054817,
    atl_change_percentage: 12.63255,
    atl_date: "2023-06-13T15:49:11.416Z",
    roi: null,
    last_updated: "2023-06-28T12:05:59.418Z",
  },
  tlos: {
    id: "telos",
    symbol: "tlos",
    name: "Telos",
    image:
      "https://assets.coingecko.com/coins/images/7588/large/TLOS_200.png?1629277084",
    current_price: 0.168622,
    market_cap: 58199153,
    market_cap_rank: 370,
    fully_diluted_valuation: 59928172,
    total_volume: 906734,
    high_24h: 0.171546,
    low_24h: 0.168328,
    price_change_24h: -0.001450258120061443,
    price_change_percentage_24h: -0.85273,
    market_cap_change_24h: -392249.903297551,
    market_cap_change_percentage_24h: -0.66947,
    circulating_supply: 344960067.77,
    total_supply: 355208370.6674,
    max_supply: null,
    ath: 1.43,
    ath_change_percentage: -88.16817,
    ath_date: "2022-02-07T22:19:46.965Z",
    atl: 0.00119321,
    atl_change_percentage: 14039.40341,
    atl_date: "2020-12-04T16:07:02.145Z",
    roi: null,
    last_updated: "2023-06-28T12:06:06.993Z",
  },
  gal: {
    id: "project-galaxy",
    symbol: "gal",
    name: "Galxe",
    image:
      "https://assets.coingecko.com/coins/images/24530/large/galaxy.jpg?1662517258",
    current_price: 1.25,
    market_cap: 58149075,
    market_cap_rank: 371,
    fully_diluted_valuation: 249859979,
    total_volume: 7922510,
    high_24h: 1.3,
    low_24h: 1.24,
    price_change_24h: -0.045985231645601754,
    price_change_percentage_24h: -3.55443,
    market_cap_change_24h: -2085150.1327952445,
    market_cap_change_percentage_24h: -3.46174,
    circulating_supply: 46545329.516565956,
    total_supply: 200000000,
    max_supply: null,
    ath: 18.32,
    ath_change_percentage: -93.18315,
    ath_date: "2022-05-06T01:14:11.342Z",
    atl: 0.897786,
    atl_change_percentage: 39.10011,
    atl_date: "2023-06-10T06:49:52.957Z",
    roi: null,
    last_updated: "2023-06-28T12:05:59.246Z",
  },
  qkc: {
    id: "quark-chain",
    symbol: "qkc",
    name: "QuarkChain",
    image:
      "https://assets.coingecko.com/coins/images/3849/large/quarkchain.png?1548387524",
    current_price: 0.00840572,
    market_cap: 57691293,
    market_cap_rank: 372,
    fully_diluted_valuation: 84090829,
    total_volume: 5222083,
    high_24h: 0.00945844,
    low_24h: 0.00840553,
    price_change_24h: -0.000449844896251815,
    price_change_percentage_24h: -5.0798,
    market_cap_change_24h: -3200978.0719601363,
    market_cap_change_percentage_24h: -5.25679,
    circulating_supply: 6860592702,
    total_supply: 10000000000,
    max_supply: null,
    ath: 0.338765,
    ath_change_percentage: -97.51657,
    ath_date: "2018-06-05T02:58:00.874Z",
    atl: 0.00137714,
    atl_change_percentage: 510.90191,
    atl_date: "2020-03-13T02:29:16.944Z",
    roi: {
      times: -0.5733137695500975,
      currency: "usd",
      percentage: -57.331376955009745,
    },
    last_updated: "2023-06-28T12:06:03.803Z",
  },
  bnt: {
    id: "bancor",
    symbol: "bnt",
    name: "Bancor Network",
    image:
      "https://assets.coingecko.com/coins/images/736/large/bancor-bnt.png?1628822309",
    current_price: 0.38004,
    market_cap: 57395188,
    market_cap_rank: 373,
    fully_diluted_valuation: 57395188,
    total_volume: 200835,
    high_24h: 0.393589,
    low_24h: 0.37985,
    price_change_24h: -0.008117913749370986,
    price_change_percentage_24h: -2.09139,
    market_cap_change_24h: -1268274.9716799334,
    market_cap_change_percentage_24h: -2.16195,
    circulating_supply: 150768627.088973,
    total_supply: 150768627.088973,
    max_supply: 150768627.088973,
    ath: 10.72,
    ath_change_percentage: -96.44733,
    ath_date: "2018-01-09T00:00:00.000Z",
    atl: 0.120935,
    atl_change_percentage: 214.78337,
    atl_date: "2020-03-13T02:29:21.245Z",
    roi: null,
    last_updated: "2023-06-28T12:06:06.585Z",
  },
  nxra: {
    id: "allianceblock-nexera",
    symbol: "nxra",
    name: "AllianceBlock Nexera",
    image:
      "https://assets.coingecko.com/coins/images/29181/large/nxra.png?1677148772",
    current_price: 0.077541,
    market_cap: 57119796,
    market_cap_rank: 374,
    fully_diluted_valuation: 65988656,
    total_volume: 806094,
    high_24h: 0.079027,
    low_24h: 0.066866,
    price_change_24h: 0.01067543,
    price_change_percentage_24h: 15.96547,
    market_cap_change_24h: 7743868,
    market_cap_change_percentage_24h: 15.68349,
    circulating_supply: 735760195.1854,
    total_supply: 850000000,
    max_supply: 850000000,
    ath: 0.113172,
    ath_change_percentage: -31.293,
    ath_date: "2023-04-18T14:14:56.998Z",
    atl: 0.04233459,
    atl_change_percentage: 83.67257,
    atl_date: "2023-03-10T02:04:48.047Z",
    roi: null,
    last_updated: "2023-06-28T12:06:03.446Z",
  },
  arpa: {
    id: "arpa",
    symbol: "arpa",
    name: "ARPA",
    image:
      "https://assets.coingecko.com/coins/images/8506/large/9u0a23XY_400x400.jpg?1559027357",
    current_price: 0.057835,
    market_cap: 57077466,
    market_cap_rank: 375,
    fully_diluted_valuation: 116226720,
    total_volume: 24825799,
    high_24h: 0.059271,
    low_24h: 0.055461,
    price_change_24h: 0.00049904,
    price_change_percentage_24h: 0.87038,
    market_cap_change_24h: 1038084,
    market_cap_change_percentage_24h: 1.85242,
    circulating_supply: 982174603.2857143,
    total_supply: 2000000000,
    max_supply: 2000000000,
    ath: 0.268622,
    ath_change_percentage: -78.36571,
    ath_date: "2021-11-03T13:31:39.876Z",
    atl: 0.00339441,
    atl_change_percentage: 1612.06482,
    atl_date: "2020-03-13T02:24:16.336Z",
    roi: null,
    last_updated: "2023-06-28T12:06:00.075Z",
  },
  mpl: {
    id: "maple",
    symbol: "mpl",
    name: "Maple",
    image:
      "https://assets.coingecko.com/coins/images/14097/large/Maple_Logo_Mark_Maple_Orange.png?1653381382",
    current_price: 7.07,
    market_cap: 56425082,
    market_cap_rank: 376,
    fully_diluted_valuation: 70804387,
    total_volume: 628528,
    high_24h: 7.17,
    low_24h: 6.89,
    price_change_24h: 0.126145,
    price_change_percentage_24h: 1.81606,
    market_cap_change_24h: 923045,
    market_cap_change_percentage_24h: 1.66308,
    circulating_supply: 7969150.4853483075,
    total_supply: 10000000,
    max_supply: 10000000,
    ath: 68.2,
    ath_change_percentage: -89.61859,
    ath_date: "2022-04-10T13:21:44.274Z",
    atl: 2.65,
    atl_change_percentage: 167.13564,
    atl_date: "2023-01-01T05:01:53.644Z",
    roi: null,
    last_updated: "2023-06-28T12:05:37.202Z",
  },
  lcx: {
    id: "lcx",
    symbol: "lcx",
    name: "LCX",
    image:
      "https://assets.coingecko.com/coins/images/9985/large/zRPSu_0o_400x400.jpg?1574327008",
    current_price: 0.072387,
    market_cap: 56104231,
    market_cap_rank: 377,
    fully_diluted_valuation: 68770039,
    total_volume: 7342074,
    high_24h: 0.080704,
    low_24h: 0.071605,
    price_change_24h: -0.003690665217869038,
    price_change_percentage_24h: -4.85119,
    market_cap_change_24h: -2765924.293251328,
    market_cap_change_percentage_24h: -4.69835,
    circulating_supply: 775032563.659999,
    total_supply: 950000000,
    max_supply: null,
    ath: 0.563966,
    ath_change_percentage: -87.1642,
    ath_date: "2021-11-03T20:35:37.124Z",
    atl: 0.00007085,
    atl_change_percentage: 102070.26515,
    atl_date: "2019-11-26T14:14:11.897Z",
    roi: null,
    last_updated: "2023-06-28T12:05:59.651Z",
  },
  beta: {
    id: "beta-finance",
    symbol: "beta",
    name: "Beta Finance",
    image:
      "https://assets.coingecko.com/coins/images/18715/large/beta_finance.jpg?1633087053",
    current_price: 0.081993,
    market_cap: 55490571,
    market_cap_rank: 378,
    fully_diluted_valuation: 81932387,
    total_volume: 2504625,
    high_24h: 0.086599,
    low_24h: 0.081325,
    price_change_24h: -0.003143969702765245,
    price_change_percentage_24h: -3.69284,
    market_cap_change_24h: -2171978.955428697,
    market_cap_change_percentage_24h: -3.76671,
    circulating_supply: 677272727,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 3.45,
    ath_change_percentage: -97.62652,
    ath_date: "2021-10-10T00:00:00.000Z",
    atl: 0.056522,
    atl_change_percentage: 44.95861,
    atl_date: "2022-11-09T22:14:42.248Z",
    roi: null,
    last_updated: "2023-06-28T12:05:49.373Z",
  },
  vra: {
    id: "verasity",
    symbol: "vra",
    name: "Verasity",
    image:
      "https://assets.coingecko.com/coins/images/14025/large/VRA.jpg?1613797653",
    current_price: 0.00536032,
    market_cap: 55391379,
    market_cap_rank: 379,
    fully_diluted_valuation: 593148304,
    total_volume: 10050679,
    high_24h: 0.00572587,
    low_24h: 0.00530358,
    price_change_24h: -0.000352270735295411,
    price_change_percentage_24h: -6.16657,
    market_cap_change_24h: -3502773.612870507,
    market_cap_change_percentage_24h: -5.94757,
    circulating_supply: 10305680354,
    total_supply: 110305680354,
    max_supply: 110356466695,
    ath: 0.086208,
    ath_change_percentage: -93.76861,
    ath_date: "2021-11-01T00:04:34.118Z",
    atl: 0.00199511,
    atl_change_percentage: 169.25477,
    atl_date: "2021-02-23T11:58:52.567Z",
    roi: null,
    last_updated: "2023-06-28T12:06:03.065Z",
  },
  hook: {
    id: "hooked-protocol",
    symbol: "hook",
    name: "Hooked Protocol",
    image:
      "https://assets.coingecko.com/coins/images/28288/large/hooked2.png?1669195270",
    current_price: 1.11,
    market_cap: 55282009,
    market_cap_rank: 380,
    fully_diluted_valuation: 552820086,
    total_volume: 8653193,
    high_24h: 1.21,
    low_24h: 1.092,
    price_change_24h: -0.08880274071234173,
    price_change_percentage_24h: -7.43762,
    market_cap_change_24h: -4563489.650113702,
    market_cap_change_percentage_24h: -7.62545,
    circulating_supply: 50000000,
    total_supply: 500000000,
    max_supply: 500000000,
    ath: 4.07,
    ath_change_percentage: -72.85727,
    ath_date: "2023-02-06T03:50:40.349Z",
    atl: 0.878491,
    atl_change_percentage: 25.80254,
    atl_date: "2023-06-10T17:05:04.560Z",
    roi: null,
    last_updated: "2023-06-28T12:06:01.747Z",
  },
  spell: {
    id: "spell-token",
    symbol: "spell",
    name: "Spell",
    image:
      "https://assets.coingecko.com/coins/images/15861/large/abracadabra-3.png?1622544862",
    current_price: 0.00046621,
    market_cap: 54864520,
    market_cap_rank: 381,
    fully_diluted_valuation: 98018819,
    total_volume: 4715163,
    high_24h: 0.00048573,
    low_24h: 0.00046331,
    price_change_24h: -0.000013494214690413,
    price_change_percentage_24h: -2.81306,
    market_cap_change_24h: -1468312.0325104147,
    market_cap_change_percentage_24h: -2.60649,
    circulating_supply: 117544256157.818,
    total_supply: 196008739620,
    max_supply: 210000000000,
    ath: 0.03506357,
    ath_change_percentage: -98.66925,
    ath_date: "2021-11-02T08:44:42.429Z",
    atl: 0.00029812,
    atl_change_percentage: 56.51465,
    atl_date: "2021-08-05T13:00:27.077Z",
    roi: null,
    last_updated: "2023-06-28T12:06:07.621Z",
  },
  ctk: {
    id: "certik",
    symbol: "ctk",
    name: "Shentu",
    image:
      "https://assets.coingecko.com/coins/images/12944/large/shentu_2.jpg?1653886628",
    current_price: 0.591372,
    market_cap: 54737352,
    market_cap_rank: 382,
    fully_diluted_valuation: 72004699,
    total_volume: 704541,
    high_24h: 0.622894,
    low_24h: 0.588195,
    price_change_24h: -0.027560806533166615,
    price_change_percentage_24h: -4.45295,
    market_cap_change_24h: -2682032.498103462,
    market_cap_change_percentage_24h: -4.67095,
    circulating_supply: 92399528,
    total_supply: 121547716,
    max_supply: null,
    ath: 3.94,
    ath_change_percentage: -84.95269,
    ath_date: "2021-04-06T18:10:20.345Z",
    atl: 0.562113,
    atl_change_percentage: 5.38785,
    atl_date: "2023-06-16T14:52:01.368Z",
    roi: null,
    last_updated: "2023-06-28T12:05:47.604Z",
  },
  xvs: {
    id: "venus",
    symbol: "xvs",
    name: "Venus",
    image:
      "https://assets.coingecko.com/coins/images/12677/large/download.jpg?1648049688",
    current_price: 3.64,
    market_cap: 54451635,
    market_cap_rank: 383,
    fully_diluted_valuation: 109258501,
    total_volume: 2022754,
    high_24h: 3.88,
    low_24h: 3.63,
    price_change_24h: -0.2065008535525661,
    price_change_percentage_24h: -5.36954,
    market_cap_change_24h: -2985197.1067109928,
    market_cap_change_percentage_24h: -5.19736,
    circulating_supply: 14951230.466954589,
    total_supply: 29745109.54325738,
    max_supply: 30000000,
    ath: 146.82,
    ath_change_percentage: -97.5196,
    ath_date: "2021-05-10T03:04:27.466Z",
    atl: 1.65,
    atl_change_percentage: 120.20902,
    atl_date: "2020-10-06T05:02:54.510Z",
    roi: null,
    last_updated: "2023-06-28T12:06:00.104Z",
  },
  pokt: {
    id: "pocket-network",
    symbol: "pokt",
    name: "Pocket Network",
    image:
      "https://assets.coingecko.com/coins/images/22506/large/33689860.png?1641957673",
    current_price: 0.03478911,
    market_cap: 53661877,
    market_cap_rank: 384,
    fully_diluted_valuation: 56729571,
    total_volume: 472032,
    high_24h: 0.03483003,
    low_24h: 0.03359664,
    price_change_24h: 0.00044465,
    price_change_percentage_24h: 1.29466,
    market_cap_change_24h: 813387,
    market_cap_change_percentage_24h: 1.53909,
    circulating_supply: 1540491118.7887,
    total_supply: 1628556521.48216,
    max_supply: null,
    ath: 3.11,
    ath_change_percentage: -98.88143,
    ath_date: "2022-01-16T07:15:26.325Z",
    atl: 0.02664614,
    atl_change_percentage: 30.72912,
    atl_date: "2023-06-10T20:40:56.672Z",
    roi: null,
    last_updated: "2023-06-28T12:05:59.793Z",
  },
  sun: {
    id: "sun-token",
    symbol: "sun",
    name: "Sun Token",
    image:
      "https://assets.coingecko.com/coins/images/12424/large/RSFOmQ.png?1624024337",
    current_price: 0.00564434,
    market_cap: 53505623,
    market_cap_rank: 385,
    fully_diluted_valuation: 112352300,
    total_volume: 5188834,
    high_24h: 0.00582056,
    low_24h: 0.0056411,
    price_change_24h: -0.000109581275027627,
    price_change_percentage_24h: -1.90446,
    market_cap_change_24h: -1178742.7059569806,
    market_cap_change_percentage_24h: -2.15554,
    circulating_supply: 9477339972,
    total_supply: 19900730000,
    max_supply: null,
    ath: 66.45,
    ath_change_percentage: -99.99142,
    ath_date: "2020-09-11T03:18:35.837Z",
    atl: 0.00462303,
    atl_change_percentage: 23.35387,
    atl_date: "2022-11-14T04:45:13.210Z",
    roi: null,
    last_updated: "2023-06-28T12:06:04.247Z",
  },
  sweth: {
    id: "sweth",
    symbol: "sweth",
    name: "Swell Ethereum",
    image:
      "https://assets.coingecko.com/coins/images/30326/large/_lB7zEtS_400x400.jpg?1684128260",
    current_price: 1908.58,
    market_cap: 53157295,
    market_cap_rank: 386,
    fully_diluted_valuation: 53155858,
    total_volume: 918205,
    high_24h: 1954.95,
    low_24h: 1894.56,
    price_change_24h: -15.914995545701004,
    price_change_percentage_24h: -0.82697,
    market_cap_change_24h: 39627,
    market_cap_change_percentage_24h: 0.0746,
    circulating_supply: 27851.6832196819,
    total_supply: 27850.9300927203,
    max_supply: null,
    ath: 1977.81,
    ath_change_percentage: -3.49222,
    ath_date: "2023-06-22T03:04:48.042Z",
    atl: 1669.46,
    atl_change_percentage: 14.33281,
    atl_date: "2023-06-15T09:32:37.613Z",
    roi: null,
    last_updated: "2023-06-28T12:03:15.667Z",
  },
  "0x0": {
    id: "0x0-ai-ai-smart-contract",
    symbol: "0x0",
    name: "0x0.ai: AI Smart Contract",
    image:
      "https://assets.coingecko.com/coins/images/28880/large/0x0.png?1675140105",
    current_price: 0.059422,
    market_cap: 52972302,
    market_cap_rank: 387,
    fully_diluted_valuation: 59435963,
    total_volume: 923827,
    high_24h: 0.062314,
    low_24h: 0.05367,
    price_change_24h: -0.001769308061316505,
    price_change_percentage_24h: -2.89142,
    market_cap_change_24h: -1564755.8305779248,
    market_cap_change_percentage_24h: -2.86916,
    circulating_supply: 891250000,
    total_supply: 891250000,
    max_supply: 1000000000,
    ath: 0.092374,
    ath_change_percentage: -36.70452,
    ath_date: "2023-05-21T09:08:13.239Z",
    atl: 0.0002781,
    atl_change_percentage: 20924.42187,
    atl_date: "2023-02-01T14:29:44.928Z",
    roi: null,
    last_updated: "2023-06-28T12:04:59.422Z",
  },
  dusk: {
    id: "dusk-network",
    symbol: "dusk",
    name: "DUSK Network",
    image:
      "https://assets.coingecko.com/coins/images/5217/large/D_ticker_purple_on_circle_%282%29.png?1563781659",
    current_price: 0.123749,
    market_cap: 52264714,
    market_cap_rank: 388,
    fully_diluted_valuation: 124095752,
    total_volume: 4198506,
    high_24h: 0.136036,
    low_24h: 0.123817,
    price_change_24h: -0.010856684068673116,
    price_change_percentage_24h: -8.06557,
    market_cap_change_24h: -3882925.801725745,
    market_cap_change_percentage_24h: -6.91556,
    circulating_supply: 421164409.316325,
    total_supply: 500000000,
    max_supply: 1000000000,
    ath: 1.09,
    ath_change_percentage: -88.61113,
    ath_date: "2021-12-29T21:34:22.823Z",
    atl: 0.01113274,
    atl_change_percentage: 1014.98717,
    atl_date: "2020-03-13T02:24:16.885Z",
    roi: {
      times: 2.0630831511495398,
      currency: "usd",
      percentage: 206.30831511495396,
    },
    last_updated: "2023-06-28T12:05:59.985Z",
  },
  euroc: {
    id: "euro-coin",
    symbol: "euroc",
    name: "Euro Coin",
    image:
      "https://assets.coingecko.com/coins/images/26045/large/euro-coin.png?1655394420",
    current_price: 1.088,
    market_cap: 52202677,
    market_cap_rank: 389,
    fully_diluted_valuation: 52202677,
    total_volume: 1686381,
    high_24h: 1.097,
    low_24h: 1.057,
    price_change_24h: -0.0060021167550115,
    price_change_percentage_24h: -0.54868,
    market_cap_change_24h: 592084,
    market_cap_change_percentage_24h: 1.14721,
    circulating_supply: 47926288.05,
    total_supply: 47926288.05,
    max_supply: null,
    ath: 1.35,
    ath_change_percentage: -19.53767,
    ath_date: "2023-03-14T09:50:50.729Z",
    atl: 0.052848,
    atl_change_percentage: 1958.46117,
    atl_date: "2022-12-09T03:16:34.877Z",
    roi: null,
    last_updated: "2023-06-28T12:05:21.278Z",
  },
  uos: {
    id: "ultra",
    symbol: "uos",
    name: "Ultra",
    image:
      "https://assets.coingecko.com/coins/images/4480/large/Ultra.png?1563356418",
    current_price: 0.191447,
    market_cap: 51758953,
    market_cap_rank: 390,
    fully_diluted_valuation: 191421967,
    total_volume: 757578,
    high_24h: 0.194766,
    low_24h: 0.190044,
    price_change_24h: -0.001459700944543035,
    price_change_percentage_24h: -0.75669,
    market_cap_change_24h: -647947.9903025627,
    market_cap_change_percentage_24h: -1.23638,
    circulating_supply: 270391922.2376,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 2.49,
    ath_change_percentage: -92.29657,
    ath_date: "2021-11-25T07:59:48.825Z",
    atl: 0.02137354,
    atl_change_percentage: 795.93799,
    atl_date: "2019-09-02T00:00:00.000Z",
    roi: {
      times: 2.828935996176768,
      currency: "usd",
      percentage: 282.8935996176768,
    },
    last_updated: "2023-06-28T12:05:59.962Z",
  },
  "10set": {
    id: "tenset",
    symbol: "10set",
    name: "Tenset",
    image:
      "https://assets.coingecko.com/coins/images/14629/large/PNG_Tenset_Sign-back_RGB.png?1674442519",
    current_price: 0.301154,
    market_cap: 51592082,
    market_cap_rank: 391,
    fully_diluted_valuation: 51592082,
    total_volume: 5434.37,
    high_24h: 0.329135,
    low_24h: 0.300227,
    price_change_24h: -0.026876158526588034,
    price_change_percentage_24h: -8.19319,
    market_cap_change_24h: -4580287.573569834,
    market_cap_change_percentage_24h: -8.15399,
    circulating_supply: 171210547.8,
    total_supply: 171210547.8,
    max_supply: 171210547.8,
    ath: 6.48,
    ath_change_percentage: -95.34761,
    ath_date: "2021-05-30T05:40:19.103Z",
    atl: 0.236751,
    atl_change_percentage: 27.2803,
    atl_date: "2022-06-08T23:09:36.451Z",
    roi: null,
    last_updated: "2023-06-28T12:03:34.076Z",
  },
  w$c: {
    id: "the-world-state",
    symbol: "w$c",
    name: "World$tateCoin",
    image:
      "https://assets.coingecko.com/coins/images/29636/large/wsc_polygon_logo_200.png?1680160427",
    current_price: 0.051985,
    market_cap: 51526735,
    market_cap_rank: 392,
    fully_diluted_valuation: 51985177,
    total_volume: 6100087,
    high_24h: 0.052272,
    low_24h: 0.051354,
    price_change_24h: 0.00007589,
    price_change_percentage_24h: 0.14619,
    market_cap_change_24h: 75205,
    market_cap_change_percentage_24h: 0.14617,
    circulating_supply: 991181294.15,
    total_supply: 999782282.25,
    max_supply: 1000000000,
    ath: 0.05638,
    ath_change_percentage: -7.30756,
    ath_date: "2023-06-23T06:53:25.357Z",
    atl: 0.00144059,
    atl_change_percentage: 3527.70084,
    atl_date: "2023-06-13T07:52:04.235Z",
    roi: null,
    last_updated: "2023-06-28T12:02:26.241Z",
  },
  canto: {
    id: "canto",
    symbol: "canto",
    name: "CANTO",
    image:
      "https://assets.coingecko.com/coins/images/26959/large/canto-network.png?1661215219",
    current_price: 0.102212,
    market_cap: 51471208,
    market_cap_rank: 393,
    fully_diluted_valuation: 102367176,
    total_volume: 2404823,
    high_24h: 0.107152,
    low_24h: 0.101921,
    price_change_24h: -0.004915124905670323,
    price_change_percentage_24h: -4.58811,
    market_cap_change_24h: -2327601.608109325,
    market_cap_change_percentage_24h: -4.32649,
    circulating_supply: 502809694.059484,
    total_supply: 1000000000,
    max_supply: null,
    ath: 0.764501,
    ath_change_percentage: -86.6302,
    ath_date: "2023-02-07T18:11:01.341Z",
    atl: 0.069837,
    atl_change_percentage: 46.35719,
    atl_date: "2023-01-01T21:15:05.898Z",
    roi: null,
    last_updated: "2023-06-28T12:06:03.413Z",
  },
  mcb: {
    id: "mcdex",
    symbol: "mcb",
    name: "MUX Protocol",
    image:
      "https://assets.coingecko.com/coins/images/11796/large/mux.jpg?1660125796",
    current_price: 13.5,
    market_cap: 51397811,
    market_cap_rank: 394,
    fully_diluted_valuation: 64912373,
    total_volume: 44030,
    high_24h: 13.99,
    low_24h: 13.48,
    price_change_24h: -0.2451260608211392,
    price_change_percentage_24h: -1.78366,
    market_cap_change_24h: -761500.6466391161,
    market_cap_change_percentage_24h: -1.45995,
    circulating_supply: 3803143,
    total_supply: 4803143.54867189,
    max_supply: 4803143,
    ath: 68.36,
    ath_change_percentage: -80.1555,
    ath_date: "2021-10-17T04:14:51.092Z",
    atl: 0.911402,
    atl_change_percentage: 1388.34485,
    atl_date: "2020-10-30T03:59:02.101Z",
    roi: null,
    last_updated: "2023-06-28T12:05:32.024Z",
  },
  ctc: {
    id: "creditcoin-2",
    symbol: "ctc",
    name: "Creditcoin",
    image:
      "https://assets.coingecko.com/coins/images/10569/large/ctc.png?1644650695",
    current_price: 0.224192,
    market_cap: 51288780,
    market_cap_rank: 395,
    fully_diluted_valuation: 450961185,
    total_volume: 11734968,
    high_24h: 0.234524,
    low_24h: 0.223237,
    price_change_24h: -0.008551134689171125,
    price_change_percentage_24h: -3.67406,
    market_cap_change_24h: -1823588.6399425268,
    market_cap_change_percentage_24h: -3.43345,
    circulating_supply: 227464279,
    total_supply: 599999997,
    max_supply: 2000000000,
    ath: 8.67,
    ath_change_percentage: -97.39792,
    ath_date: "2021-03-14T03:54:22.148Z",
    atl: 0.129318,
    atl_change_percentage: 74.36152,
    atl_date: "2020-03-13T01:59:34.168Z",
    roi: null,
    last_updated: "2023-06-28T12:05:58.261Z",
  },
  cel: {
    id: "celsius-degree-token",
    symbol: "cel",
    name: "Celsius Network",
    image:
      "https://assets.coingecko.com/coins/images/3263/large/CEL_logo.png?1609598753",
    current_price: 0.116949,
    market_cap: 51262396,
    market_cap_rank: 396,
    fully_diluted_valuation: 83870716,
    total_volume: 496679,
    high_24h: 0.123411,
    low_24h: 0.11151,
    price_change_24h: -0.005351642949099345,
    price_change_percentage_24h: -4.37582,
    market_cap_change_24h: -444279.26666716486,
    market_cap_change_percentage_24h: -0.85923,
    circulating_supply: 423415980.3475,
    total_supply: 692753441.4971,
    max_supply: null,
    ath: 8.05,
    ath_change_percentage: -98.48481,
    ath_date: "2021-06-04T00:04:12.584Z",
    atl: 0.02620268,
    atl_change_percentage: 365.32493,
    atl_date: "2018-12-07T00:00:00.000Z",
    roi: {
      times: -0.6101705377030736,
      currency: "usd",
      percentage: -61.01705377030736,
    },
    last_updated: "2023-06-28T12:06:02.964Z",
  },
  evmos: {
    id: "evmos",
    symbol: "evmos",
    name: "Evmos",
    image:
      "https://assets.coingecko.com/coins/images/24023/large/evmos.png?1653958927",
    current_price: 0.096054,
    market_cap: 50677851,
    market_cap_rank: 397,
    fully_diluted_valuation: 95956261,
    total_volume: 474282,
    high_24h: 0.099596,
    low_24h: 0.091966,
    price_change_24h: -0.000257649873196306,
    price_change_percentage_24h: -0.26752,
    market_cap_change_24h: -326314.59799254686,
    market_cap_change_percentage_24h: -0.63978,
    circulating_supply: 528134905.146616,
    total_supply: 1000000000,
    max_supply: null,
    ath: 6.84,
    ath_change_percentage: -98.59669,
    ath_date: "2022-04-28T21:20:31.780Z",
    atl: 0.091506,
    atl_change_percentage: 4.86296,
    atl_date: "2023-06-15T04:34:26.246Z",
    roi: null,
    last_updated: "2023-06-28T12:05:58.389Z",
  },
  koda: {
    id: "koda-finance",
    symbol: "koda",
    name: "Koda Cryptocurrency",
    image:
      "https://assets.coingecko.com/coins/images/15577/large/koda.png?1639224696",
    current_price: 0.00002064,
    market_cap: 50208210,
    market_cap_rank: 398,
    fully_diluted_valuation: 41747070,
    total_volume: 286.58,
    high_24h: 0.00002115,
    low_24h: 0.0000205,
    price_change_24h: -4.91663467871e-7,
    price_change_percentage_24h: -2.32671,
    market_cap_change_24h: 243878,
    market_cap_change_percentage_24h: 0.4881,
    circulating_supply: 0,
    total_supply: 33000000000,
    max_supply: 33000000000,
    ath: 0.00223929,
    ath_change_percentage: -99.0783,
    ath_date: "2021-10-01T21:32:10.546Z",
    atl: 0.00001023,
    atl_change_percentage: 101.66298,
    atl_date: "2023-06-15T11:33:21.791Z",
    roi: null,
    last_updated: "2023-06-28T12:02:57.638Z",
  },
  alex: {
    id: "alexgo",
    symbol: "alex",
    name: "ALEX Lab",
    image:
      "https://assets.coingecko.com/coins/images/25837/large/ALEX_Token.png?1662728114",
    current_price: 0.082455,
    market_cap: 50088899,
    market_cap_rank: 399,
    fully_diluted_valuation: 82575859,
    total_volume: 226100,
    high_24h: 0.087926,
    low_24h: 0.081731,
    price_change_24h: -0.004080545525905047,
    price_change_percentage_24h: -4.71546,
    market_cap_change_24h: -2470059.402937226,
    market_cap_change_percentage_24h: -4.6996,
    circulating_supply: 606580414.935604,
    total_supply: 606580414.935604,
    max_supply: 1000000000,
    ath: 0.181775,
    ath_change_percentage: -54.5918,
    ath_date: "2023-05-08T15:11:17.719Z",
    atl: 0.01109555,
    atl_change_percentage: 643.90672,
    atl_date: "2022-12-31T19:14:21.272Z",
    roi: null,
    last_updated: "2023-06-28T12:05:31.644Z",
  },
  hxro: {
    id: "hxro",
    symbol: "hxro",
    name: "Hxro",
    image:
      "https://assets.coingecko.com/coins/images/7805/large/Hxro_Profile_Transparent.png?1622443308",
    current_price: 0.082432,
    market_cap: 50050658,
    market_cap_rank: 400,
    fully_diluted_valuation: 82457733,
    total_volume: 617.71,
    high_24h: 0.282063,
    low_24h: 0.081523,
    price_change_24h: -0.000036714177289551,
    price_change_percentage_24h: -0.04452,
    market_cap_change_24h: 39927,
    market_cap_change_percentage_24h: 0.07984,
    circulating_supply: 606985617.0535563,
    total_supply: 1000000000,
    max_supply: null,
    ath: 0.800981,
    ath_change_percentage: -89.7054,
    ath_date: "2021-06-05T00:59:41.210Z",
    atl: 0.00914678,
    atl_change_percentage: 801.49497,
    atl_date: "2019-11-10T15:12:15.992Z",
    roi: null,
    last_updated: "2023-06-28T12:05:20.266Z",
  },
  ghst: {
    id: "aavegotchi",
    symbol: "ghst",
    name: "Aavegotchi",
    image:
      "https://assets.coingecko.com/coins/images/12467/large/ghst_200.png?1600750321",
    current_price: 0.972324,
    market_cap: 49900375,
    market_cap_rank: 401,
    fully_diluted_valuation: 51451860,
    total_volume: 895979,
    high_24h: 1.002,
    low_24h: 0.972324,
    price_change_24h: -0.027750180921560234,
    price_change_percentage_24h: -2.77481,
    market_cap_change_24h: -1101679.2513926476,
    market_cap_change_percentage_24h: -2.16007,
    circulating_supply: 51157239.2140685,
    total_supply: 52747802.714256,
    max_supply: 52747802.714256,
    ath: 3.08,
    ath_change_percentage: -68.14016,
    ath_date: "2021-11-25T10:34:20.480Z",
    atl: 0.486933,
    atl_change_percentage: 101.76185,
    atl_date: "2020-12-01T23:14:48.358Z",
    roi: null,
    last_updated: "2023-06-28T12:06:26.657Z",
  },
  dext: {
    id: "dextools",
    symbol: "dext",
    name: "DexTools",
    image:
      "https://assets.coingecko.com/coins/images/11603/large/dext.png?1605790188",
    current_price: 0.504058,
    market_cap: 49824905,
    market_cap_rank: 402,
    fully_diluted_valuation: 70886145,
    total_volume: 478337,
    high_24h: 0.557847,
    low_24h: 0.503014,
    price_change_24h: -0.046428215178835,
    price_change_percentage_24h: -8.43403,
    market_cap_change_24h: -4568386.363394074,
    market_cap_change_percentage_24h: -8.3988,
    circulating_supply: 98791560.72404282,
    total_supply: 140551255,
    max_supply: 140551255,
    ath: 0.940315,
    ath_change_percentage: -46.36437,
    ath_date: "2021-03-09T10:39:27.180Z",
    atl: 0.00001117,
    atl_change_percentage: 4516455.33819,
    atl_date: "2020-06-19T22:59:18.232Z",
    roi: null,
    last_updated: "2023-06-28T12:06:06.102Z",
  },
  bob: {
    id: "bob",
    symbol: "bob",
    name: "BOB",
    image:
      "https://assets.coingecko.com/coins/images/27266/large/Bob-logo.png?1663073030",
    current_price: 0.998876,
    market_cap: 49507645,
    market_cap_rank: 403,
    fully_diluted_valuation: null,
    total_volume: 3149581,
    high_24h: 1.004,
    low_24h: 0.994175,
    price_change_24h: -0.001023412347843089,
    price_change_percentage_24h: -0.10235,
    market_cap_change_24h: 78134,
    market_cap_change_percentage_24h: 0.15807,
    circulating_supply: 49507526.6065889,
    total_supply: null,
    max_supply: null,
    ath: 1.051,
    ath_change_percentage: -4.81811,
    ath_date: "2022-09-19T14:29:47.704Z",
    atl: 0.872719,
    atl_change_percentage: 14.58475,
    atl_date: "2023-03-11T08:19:43.904Z",
    roi: null,
    last_updated: "2023-06-28T12:06:28.105Z",
  },
  temple: {
    id: "temple",
    symbol: "temple",
    name: "TempleDAO",
    image:
      "https://assets.coingecko.com/coins/images/20040/large/LPK15ZOW_400x400.jpg?1636425070",
    current_price: 1.022,
    market_cap: 48804737,
    market_cap_rank: 404,
    fully_diluted_valuation: 172477227,
    total_volume: 1967.62,
    high_24h: 1.023,
    low_24h: 1.012,
    price_change_24h: 0.00873872,
    price_change_percentage_24h: 0.86204,
    market_cap_change_24h: 421959,
    market_cap_change_percentage_24h: 0.87213,
    circulating_supply: 47732403.542999,
    total_supply: 168687573.542999,
    max_supply: 168687573.542999,
    ath: 3.35,
    ath_change_percentage: -69.46723,
    ath_date: "2021-12-23T00:00:00.000Z",
    atl: 0.186329,
    atl_change_percentage: 448.74071,
    atl_date: "2023-03-19T10:06:55.486Z",
    roi: null,
    last_updated: "2023-06-28T09:12:00.432Z",
  },
  maticx: {
    id: "stader-maticx",
    symbol: "maticx",
    name: "Stader MaticX",
    image:
      "https://assets.coingecko.com/coins/images/25383/large/maticx.png?1674714297",
    current_price: 0.674123,
    market_cap: 48119293,
    market_cap_rank: 405,
    fully_diluted_valuation: 6749559366,
    total_volume: 122789,
    high_24h: 0.716019,
    low_24h: 0.672022,
    price_change_24h: -0.03811120326639261,
    price_change_percentage_24h: -5.35094,
    market_cap_change_24h: -2538935.620955892,
    market_cap_change_percentage_24h: -5.01189,
    circulating_supply: 71292495.3861272,
    total_supply: 71292495.3861272,
    max_supply: 10000000000,
    ath: 2.07,
    ath_change_percentage: -67.35596,
    ath_date: "2022-12-09T12:04:25.618Z",
    atl: 0.271451,
    atl_change_percentage: 148.64759,
    atl_date: "2022-06-19T14:10:13.733Z",
    roi: null,
    last_updated: "2023-06-28T12:06:06.000Z",
  },
  eul: {
    id: "euler",
    symbol: "eul",
    name: "Euler",
    image:
      "https://assets.coingecko.com/coins/images/26149/large/YCvKDfl8_400x400.jpeg?1656041509",
    current_price: 2.62,
    market_cap: 48033017,
    market_cap_rank: 406,
    fully_diluted_valuation: 71597579,
    total_volume: 576060,
    high_24h: 2.81,
    low_24h: 1.6,
    price_change_24h: 1.012,
    price_change_percentage_24h: 62.96309,
    market_cap_change_24h: 18768928,
    market_cap_change_percentage_24h: 64.13638,
    circulating_supply: 18236269.8,
    total_supply: 27182818.28459,
    max_supply: 27182818,
    ath: 12.78,
    ath_change_percentage: -79.33092,
    ath_date: "2022-09-11T15:09:41.440Z",
    atl: 1.44,
    atl_change_percentage: 83.92711,
    atl_date: "2023-06-15T20:45:08.852Z",
    roi: null,
    last_updated: "2023-06-28T12:06:29.712Z",
  },
  srm: {
    id: "serum",
    symbol: "srm",
    name: "Serum",
    image:
      "https://assets.coingecko.com/coins/images/11970/large/serum-logo.png?1597121577",
    current_price: 0.128492,
    market_cap: 47657856,
    market_cap_rank: 407,
    fully_diluted_valuation: 1299019495,
    total_volume: 45802579,
    high_24h: 0.142771,
    low_24h: 0.11956,
    price_change_24h: 0.00726027,
    price_change_percentage_24h: 5.98877,
    market_cap_change_24h: 2432643,
    market_cap_change_percentage_24h: 5.37895,
    circulating_supply: 372782297.011929,
    total_supply: 1092844982.31,
    max_supply: 10161000000,
    ath: 13.78,
    ath_change_percentage: -99.09197,
    ath_date: "2021-09-11T02:45:47.994Z",
    atl: 0.050826,
    atl_change_percentage: 146.20179,
    atl_date: "2023-06-15T12:35:40.142Z",
    roi: null,
    last_updated: "2023-06-28T12:06:35.244Z",
  },
  ceek: {
    id: "ceek",
    symbol: "ceek",
    name: "CEEK Smart VR",
    image:
      "https://assets.coingecko.com/coins/images/2581/large/ceek-smart-vr-token-logo.png?1547036714",
    current_price: 0.057137,
    market_cap: 47467295,
    market_cap_rank: 408,
    fully_diluted_valuation: 57139576,
    total_volume: 2058706,
    high_24h: 0.059583,
    low_24h: 0.057065,
    price_change_24h: -0.000951377201487288,
    price_change_percentage_24h: -1.63782,
    market_cap_change_24h: -853814.626665175,
    market_cap_change_percentage_24h: -1.76696,
    circulating_supply: 830725361,
    total_supply: 1000000000,
    max_supply: null,
    ath: 1.19,
    ath_change_percentage: -95.19778,
    ath_date: "2021-11-29T13:22:40.316Z",
    atl: 0.00074146,
    atl_change_percentage: 7606.59999,
    atl_date: "2020-02-13T22:49:40.369Z",
    roi: {
      times: -0.8857269846119166,
      currency: "usd",
      percentage: -88.57269846119165,
    },
    last_updated: "2023-06-28T12:06:28.581Z",
  },
  usdr: {
    id: "real-usd",
    symbol: "usdr",
    name: "Real USD",
    image:
      "https://assets.coingecko.com/coins/images/27973/large/USDR-200x200.png?1666772134",
    current_price: 0.999396,
    market_cap: 46792775,
    market_cap_rank: 409,
    fully_diluted_valuation: 46792775,
    total_volume: 960755,
    high_24h: 1.003,
    low_24h: 0.993158,
    price_change_24h: 0.00057059,
    price_change_percentage_24h: 0.05713,
    market_cap_change_24h: 195721,
    market_cap_change_percentage_24h: 0.42003,
    circulating_supply: 46760161.2247826,
    total_supply: 46760161.2247826,
    max_supply: null,
    ath: 1.1,
    ath_change_percentage: -9.19868,
    ath_date: "2022-11-09T10:51:49.471Z",
    atl: 0.872585,
    atl_change_percentage: 14.53284,
    atl_date: "2023-03-11T08:04:31.906Z",
    roi: null,
    last_updated: "2023-06-28T12:05:16.241Z",
  },
  aura: {
    id: "aura-finance",
    symbol: "aura",
    name: "Aura Finance",
    image:
      "https://assets.coingecko.com/coins/images/25942/large/logo.png?1654784187",
    current_price: 1.64,
    market_cap: 46665143,
    market_cap_rank: 410,
    fully_diluted_valuation: 164425772,
    total_volume: 178271,
    high_24h: 1.73,
    low_24h: 1.64,
    price_change_24h: -0.05881825442821431,
    price_change_percentage_24h: -3.45853,
    market_cap_change_24h: -1549057.8592795953,
    market_cap_change_percentage_24h: -3.21287,
    circulating_supply: 28380674.4008115,
    total_supply: 62544188.593078,
    max_supply: 100000000,
    ath: 4.05,
    ath_change_percentage: -59.4223,
    ath_date: "2023-03-14T13:19:41.812Z",
    atl: 0.445907,
    atl_change_percentage: 268.20005,
    atl_date: "2022-06-17T07:20:33.005Z",
    roi: null,
    last_updated: "2023-06-28T12:05:30.767Z",
  },
  wmt: {
    id: "world-mobile-token",
    symbol: "wmt",
    name: "World Mobile Token",
    image:
      "https://assets.coingecko.com/coins/images/17333/large/Colored_Token.png?1627346708",
    current_price: 0.108529,
    market_cap: 46352005,
    market_cap_rank: 411,
    fully_diluted_valuation: 217671224,
    total_volume: 424417,
    high_24h: 0.111482,
    low_24h: 0.107984,
    price_change_24h: -0.002278159768614438,
    price_change_percentage_24h: -2.05596,
    market_cap_change_24h: -806113.8844467923,
    market_cap_change_percentage_24h: -1.70939,
    circulating_supply: 425890060.643768,
    total_supply: 2000000000,
    max_supply: 2000000000,
    ath: 0.979732,
    ath_change_percentage: -88.90643,
    ath_date: "2022-02-17T07:00:01.257Z",
    atl: 0.098034,
    atl_change_percentage: 10.86708,
    atl_date: "2023-06-20T14:46:22.600Z",
    roi: null,
    last_updated: "2023-06-28T12:06:29.685Z",
  },
  xyo: {
    id: "xyo-network",
    symbol: "xyo",
    name: "XYO Network",
    image:
      "https://assets.coingecko.com/coins/images/4519/large/XYO_Network-logo.png?1547039819",
    current_price: 0.00366709,
    market_cap: 46216200,
    market_cap_rank: 412,
    fully_diluted_valuation: 50997350,
    total_volume: 215972,
    high_24h: 0.00380226,
    low_24h: 0.00365735,
    price_change_24h: -0.000013672837934038,
    price_change_percentage_24h: -0.37147,
    market_cap_change_24h: -203044.83213083446,
    market_cap_change_percentage_24h: -0.43742,
    circulating_supply: 12625124968.400986,
    total_supply: 13931216938.0113,
    max_supply: 13931216938.0113,
    ath: 0.081391,
    ath_change_percentage: -95.50124,
    ath_date: "2021-11-06T04:44:55.346Z",
    atl: 0.00009672,
    atl_change_percentage: 3685.67235,
    atl_date: "2020-03-13T02:24:24.303Z",
    roi: {
      times: -0.9343086007016672,
      currency: "eth",
      percentage: -93.43086007016672,
    },
    last_updated: "2023-06-28T12:06:29.598Z",
  },
  sx: {
    id: "sx-network",
    symbol: "sx",
    name: "SX Network",
    image:
      "https://assets.coingecko.com/coins/images/13779/large/sx.png?1636034699",
    current_price: 0.099265,
    market_cap: 46156427,
    market_cap_rank: 413,
    fully_diluted_valuation: 98963179,
    total_volume: 259310,
    high_24h: 0.105127,
    low_24h: 0.09868,
    price_change_24h: -0.005368980039431981,
    price_change_percentage_24h: -5.13122,
    market_cap_change_24h: -2625324.5881221592,
    market_cap_change_percentage_24h: -5.38178,
    circulating_supply: 466400000,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 0.988804,
    ath_change_percentage: -89.97821,
    ath_date: "2021-05-10T16:13:59.746Z",
    atl: 0.094994,
    atl_change_percentage: 4.31812,
    atl_date: "2023-06-01T12:10:39.705Z",
    roi: null,
    last_updated: "2023-06-28T12:06:26.873Z",
  },
  tru: {
    id: "truefi",
    symbol: "tru",
    name: "TrueFi",
    image:
      "https://assets.coingecko.com/coins/images/13180/large/truefi_glyph_color.png?1617610941",
    current_price: 0.04326294,
    market_cap: 46039229,
    market_cap_rank: 414,
    fully_diluted_valuation: 51981730,
    total_volume: 4964088,
    high_24h: 0.0460751,
    low_24h: 0.04255017,
    price_change_24h: -0.00114657982608192,
    price_change_percentage_24h: -2.58183,
    market_cap_change_24h: -83489.12542108446,
    market_cap_change_percentage_24h: -0.18102,
    circulating_supply: 1061445050.7431,
    total_supply: 1198450773.15516,
    max_supply: 1198450773.15516,
    ath: 1.017,
    ath_change_percentage: -95.73399,
    ath_date: "2021-08-12T11:49:51.464Z",
    atl: 0.02638331,
    atl_change_percentage: 64.40079,
    atl_date: "2022-12-23T23:00:11.869Z",
    roi: null,
    last_updated: "2023-06-28T12:06:27.826Z",
  },
  rss3: {
    id: "rss3",
    symbol: "rss3",
    name: "RSS3",
    image:
      "https://assets.coingecko.com/coins/images/23575/large/RSS3.png?1644494645",
    current_price: 0.103821,
    market_cap: 46018241,
    market_cap_rank: 415,
    fully_diluted_valuation: 103917745,
    total_volume: 1206156,
    high_24h: 0.106932,
    low_24h: 0.103802,
    price_change_24h: -0.001381309339091474,
    price_change_percentage_24h: -1.31301,
    market_cap_change_24h: -525630.5571120605,
    market_cap_change_percentage_24h: -1.12932,
    circulating_supply: 442833333.3333294,
    total_supply: 1000000000,
    max_supply: null,
    ath: 0.687355,
    ath_change_percentage: -84.8815,
    ath_date: "2022-02-15T09:35:01.597Z",
    atl: 0.084864,
    atl_change_percentage: 22.45214,
    atl_date: "2023-06-10T16:01:31.909Z",
    roi: null,
    last_updated: "2023-06-28T12:06:27.562Z",
  },
  dfuk: {
    id: "dfuk",
    symbol: "dfuk",
    name: "DFUK",
    image: "missing_large.png",
    current_price: 2.19,
    market_cap: 45964205,
    market_cap_rank: 416,
    fully_diluted_valuation: 45964205,
    total_volume: 192.61,
    high_24h: null,
    low_24h: null,
    price_change_24h: null,
    price_change_percentage_24h: null,
    market_cap_change_24h: null,
    market_cap_change_percentage_24h: null,
    circulating_supply: 21000000,
    total_supply: 21000000,
    max_supply: 21000000,
    ath: 2.2,
    ath_change_percentage: -0.32573,
    ath_date: "2023-05-17T20:11:21.898Z",
    atl: 2.13,
    atl_change_percentage: 2.76681,
    atl_date: "2023-05-17T14:37:45.308Z",
    roi: null,
    last_updated: "2023-05-18T04:00:12.823Z",
  },
  susd: {
    id: "nusd",
    symbol: "susd",
    name: "sUSD",
    image:
      "https://assets.coingecko.com/coins/images/5013/large/sUSD.png?1616150765",
    current_price: 1,
    market_cap: 45727274,
    market_cap_rank: 417,
    fully_diluted_valuation: 45727274,
    total_volume: 2202460,
    high_24h: 1.006,
    low_24h: 0.994751,
    price_change_24h: 0.00371512,
    price_change_percentage_24h: 0.37279,
    market_cap_change_24h: 417396,
    market_cap_change_percentage_24h: 0.9212,
    circulating_supply: 45677769.2669925,
    total_supply: 45677769.2669925,
    max_supply: 45677769.2669925,
    ath: 2.45,
    ath_change_percentage: -59.21254,
    ath_date: "2020-02-18T03:23:56.261Z",
    atl: 0.429697,
    atl_change_percentage: 132.97409,
    atl_date: "2020-03-18T08:09:20.698Z",
    roi: null,
    last_updated: "2023-06-28T12:06:28.196Z",
  },
  boba: {
    id: "boba-network",
    symbol: "boba",
    name: "Boba Network",
    image:
      "https://assets.coingecko.com/coins/images/20285/large/BOBA.png?1636811576",
    current_price: 0.133219,
    market_cap: 45219591,
    market_cap_rank: 418,
    fully_diluted_valuation: 66665779,
    total_volume: 883844,
    high_24h: 0.139765,
    low_24h: 0.133238,
    price_change_24h: -0.005306093718939393,
    price_change_percentage_24h: -3.83041,
    market_cap_change_24h: -1636788.1894464716,
    market_cap_change_percentage_24h: -3.4932,
    circulating_supply: 339151448.084703,
    total_supply: 500000000,
    max_supply: 500000000,
    ath: 7.93,
    ath_change_percentage: -98.31752,
    ath_date: "2021-11-25T20:48:25.017Z",
    atl: 0.127065,
    atl_change_percentage: 4.93742,
    atl_date: "2023-06-15T13:39:45.951Z",
    roi: null,
    last_updated: "2023-06-28T12:06:28.930Z",
  },
  ladys: {
    id: "milady-meme-coin",
    symbol: "ladys",
    name: "Milady Meme Coin",
    image:
      "https://assets.coingecko.com/coins/images/30194/large/logo.png?1683607297",
    current_price: 5.0688e-8,
    market_cap: 45146227,
    market_cap_rank: 419,
    fully_diluted_valuation: 45146227,
    total_volume: 5562748,
    high_24h: 5.3665e-8,
    low_24h: 4.7825e-8,
    price_change_24h: 8.82997e-10,
    price_change_percentage_24h: 1.77291,
    market_cap_change_24h: 834682,
    market_cap_change_percentage_24h: 1.88367,
    circulating_supply: 888000888000888,
    total_supply: 888000888000888,
    max_supply: 888000888000888,
    ath: 1.72607e-7,
    ath_change_percentage: -70.55281,
    ath_date: "2023-05-11T08:55:43.001Z",
    atl: 7.31393e-10,
    atl_change_percentage: 6849.48052,
    atl_date: "2023-05-10T03:39:39.243Z",
    roi: null,
    last_updated: "2023-06-28T12:06:25.553Z",
  },
  ssx: {
    id: "somesing",
    symbol: "ssx",
    name: "SOMESING Exchange",
    image:
      "https://assets.coingecko.com/coins/images/10089/large/ico_ss_4x.png?1670064183",
    current_price: 0.01600301,
    market_cap: 45065240,
    market_cap_rank: 421,
    fully_diluted_valuation: 96569095,
    total_volume: 20418335,
    high_24h: 0.01667505,
    low_24h: 0.01523019,
    price_change_24h: 0.00077281,
    price_change_percentage_24h: 5.07422,
    market_cap_change_24h: 2305038,
    market_cap_change_percentage_24h: 5.39062,
    circulating_supply: 2799978997,
    total_supply: 5000000000,
    max_supply: 6000000000,
    ath: 0.285814,
    ath_change_percentage: -94.36878,
    ath_date: "2021-04-03T00:24:31.154Z",
    atl: 0.00097216,
    atl_change_percentage: 1555.58016,
    atl_date: "2020-06-23T08:09:13.158Z",
    roi: null,
    last_updated: "2023-06-28T12:06:31.713Z",
  },
  super: {
    id: "superfarm",
    symbol: "super",
    name: "SuperVerse",
    image:
      "https://assets.coingecko.com/coins/images/14040/large/SuperVerse_Logo_200x200.png?1669963064",
    current_price: 0.110727,
    market_cap: 45013794,
    market_cap_rank: 422,
    fully_diluted_valuation: 110681775,
    total_volume: 1634058,
    high_24h: 0.115034,
    low_24h: 0.110526,
    price_change_24h: -0.002962198608064362,
    price_change_percentage_24h: -2.60552,
    market_cap_change_24h: -1242067.6308910325,
    market_cap_change_percentage_24h: -2.68521,
    circulating_supply: 406694843.416917,
    total_supply: 999998077.416917,
    max_supply: 999998077.416917,
    ath: 4.74,
    ath_change_percentage: -97.66536,
    ath_date: "2021-03-31T07:38:55.006Z",
    atl: 0.079395,
    atl_change_percentage: 39.42425,
    atl_date: "2022-12-30T15:15:36.882Z",
    roi: null,
    last_updated: "2023-06-28T12:06:32.556Z",
  },
  aergo: {
    id: "aergo",
    symbol: "aergo",
    name: "Aergo",
    image:
      "https://assets.coingecko.com/coins/images/4490/large/aergo.png?1647696770",
    current_price: 0.101227,
    market_cap: 44124368,
    market_cap_rank: 423,
    fully_diluted_valuation: 50717665,
    total_volume: 7302249,
    high_24h: 0.11036,
    low_24h: 0.101093,
    price_change_24h: -0.002712057194334941,
    price_change_percentage_24h: -2.60928,
    market_cap_change_24h: -1140446.4708908498,
    market_cap_change_percentage_24h: -2.5195,
    circulating_supply: 434999999.7589212,
    total_supply: 500000000,
    max_supply: 500000000,
    ath: 0.572482,
    ath_change_percentage: -82.26164,
    ath_date: "2021-04-10T02:04:45.195Z",
    atl: 0.01354723,
    atl_change_percentage: 649.5911,
    atl_date: "2020-03-13T02:29:18.974Z",
    roi: {
      times: -0.4938662723448605,
      currency: "usd",
      percentage: -49.38662723448605,
    },
    last_updated: "2023-06-28T12:06:29.175Z",
  },
  dione: {
    id: "dione",
    symbol: "dione",
    name: "Dione",
    image:
      "https://assets.coingecko.com/coins/images/26931/large/dione_200x200.png?1660886063",
    current_price: 0.0046163,
    market_cap: 44119835,
    market_cap_rank: 424,
    fully_diluted_valuation: 46220205,
    total_volume: 1000625,
    high_24h: 0.00513778,
    low_24h: 0.00445955,
    price_change_24h: -0.000481088851358607,
    price_change_percentage_24h: -9.43795,
    market_cap_change_24h: -4646522.444678329,
    market_cap_change_percentage_24h: -9.52813,
    circulating_supply: 9545573106.043528,
    total_supply: 9889892572.7,
    max_supply: 10000000000,
    ath: 0.00542024,
    ath_change_percentage: -14.72657,
    ath_date: "2023-04-28T02:54:31.985Z",
    atl: 0.00006987,
    atl_change_percentage: 6515.51072,
    atl_date: "2022-08-20T01:49:53.554Z",
    roi: null,
    last_updated: "2023-06-28T12:06:03.564Z",
  },
  kilt: {
    id: "kilt-protocol",
    symbol: "kilt",
    name: "KILT Protocol",
    image:
      "https://assets.coingecko.com/coins/images/20861/large/kilt.png?1637893150",
    current_price: 0.361061,
    market_cap: 44017251,
    market_cap_rank: 425,
    fully_diluted_valuation: 105002210,
    total_volume: 21434,
    high_24h: 0.376454,
    low_24h: 0.350191,
    price_change_24h: 0.00865736,
    price_change_percentage_24h: 2.45666,
    market_cap_change_24h: 1128349,
    market_cap_change_percentage_24h: 2.63086,
    circulating_supply: 121803651.195172,
    total_supply: 159189598.155882,
    max_supply: 290560000,
    ath: 9.8,
    ath_change_percentage: -96.3114,
    ath_date: "2021-12-01T08:23:06.817Z",
    atl: 0.233855,
    atl_change_percentage: 54.53122,
    atl_date: "2022-12-19T09:20:44.152Z",
    roi: null,
    last_updated: "2023-06-28T12:06:30.993Z",
  },
  dola: {
    id: "dola-usd",
    symbol: "dola",
    name: "DOLA",
    image:
      "https://assets.coingecko.com/coins/images/14287/large/dola.png?1667738374",
    current_price: 0.993422,
    market_cap: 43809998,
    market_cap_rank: 426,
    fully_diluted_valuation: 52460002,
    total_volume: 1072818,
    high_24h: 0.995808,
    low_24h: 0.985915,
    price_change_24h: -0.00013096069477414,
    price_change_percentage_24h: -0.01318,
    market_cap_change_24h: 81026,
    market_cap_change_percentage_24h: 0.18529,
    circulating_supply: 44076662.2417019,
    total_supply: 52779317.7907235,
    max_supply: null,
    ath: 1.87,
    ath_change_percentage: -46.89061,
    ath_date: "2021-11-13T13:09:22.404Z",
    atl: 0.400307,
    atl_change_percentage: 148.37531,
    atl_date: "2021-04-04T19:57:22.871Z",
    roi: null,
    last_updated: "2023-06-28T12:06:30.801Z",
  },
  tsuka: {
    id: "dejitaru-tsuka",
    symbol: "tsuka",
    name: "Dejitaru Tsuka",
    image:
      "https://assets.coingecko.com/coins/images/26405/large/Tsuka_200x200.png?1657923568",
    current_price: 0.04402677,
    market_cap: 43779364,
    market_cap_rank: 427,
    fully_diluted_valuation: 43779364,
    total_volume: 393842,
    high_24h: 0.04575961,
    low_24h: 0.04369859,
    price_change_24h: -0.000357400270585367,
    price_change_percentage_24h: -0.80524,
    market_cap_change_24h: -473667.4922825694,
    market_cap_change_percentage_24h: -1.07036,
    circulating_supply: 1000000000,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 0.15717,
    ath_change_percentage: -72.11399,
    ath_date: "2023-02-15T22:35:25.938Z",
    atl: 0.01255154,
    atl_change_percentage: 249.1887,
    atl_date: "2022-07-29T01:15:11.255Z",
    roi: null,
    last_updated: "2023-06-28T12:06:21.420Z",
  },
  btm: {
    id: "bytom",
    symbol: "btm",
    name: "Bytom",
    image:
      "https://assets.coingecko.com/coins/images/1087/large/2qNyrhUxEmnGCKi.png?1630048151",
    current_price: 0.0241314,
    market_cap: 43520035,
    market_cap_rank: 428,
    fully_diluted_valuation: 50783345,
    total_volume: 1046759,
    high_24h: 0.03217182,
    low_24h: 0.02400435,
    price_change_24h: -0.003782205806131537,
    price_change_percentage_24h: -13.54968,
    market_cap_change_24h: 2791119,
    market_cap_change_percentage_24h: 6.85292,
    circulating_supply: 1799646581.25,
    total_supply: 2100000000,
    max_supply: null,
    ath: 1.16,
    ath_change_percentage: -97.92528,
    ath_date: "2018-04-24T00:19:42.640Z",
    atl: 0.007767,
    atl_change_percentage: 211.00496,
    atl_date: "2022-05-12T07:26:26.794Z",
    roi: {
      times: -0.5173719218124396,
      currency: "usd",
      percentage: -51.73719218124396,
    },
    last_updated: "2023-06-28T12:06:32.540Z",
  },
  axn: {
    id: "axion",
    symbol: "axn",
    name: "Axion",
    image:
      "https://assets.coingecko.com/coins/images/13110/large/AXION.png?1634887383",
    current_price: 0.00000418,
    market_cap: 43431003,
    market_cap_rank: 429,
    fully_diluted_valuation: null,
    total_volume: 173.96,
    high_24h: 0.00000421,
    low_24h: 0.0000041,
    price_change_24h: 4.181e-8,
    price_change_percentage_24h: 1.0111,
    market_cap_change_24h: null,
    market_cap_change_percentage_24h: null,
    circulating_supply: 0,
    total_supply: null,
    max_supply: null,
    ath: 0.00075619,
    ath_change_percentage: -99.44744,
    ath_date: "2021-03-11T22:04:38.589Z",
    atl: 0.00000171,
    atl_change_percentage: 145.0095,
    atl_date: "2020-12-18T01:08:10.980Z",
    roi: null,
    last_updated: "2023-06-28T12:06:14.381Z",
  },
  badger: {
    id: "badger-dao",
    symbol: "badger",
    name: "Badger DAO",
    image:
      "https://assets.coingecko.com/coins/images/13287/large/badger_dao_logo.jpg?1607054976",
    current_price: 2.19,
    market_cap: 43066397,
    market_cap_rank: 430,
    fully_diluted_valuation: 46365801,
    total_volume: 1949462,
    high_24h: 2.29,
    low_24h: 2.18,
    price_change_24h: -0.08448267670859178,
    price_change_percentage_24h: -3.71433,
    market_cap_change_24h: -1113065.2436037883,
    market_cap_change_percentage_24h: -2.51942,
    circulating_supply: 19505633.865142018,
    total_supply: 21000000,
    max_supply: 21000000,
    ath: 89.08,
    ath_change_percentage: -97.52142,
    ath_date: "2021-02-09T01:03:21.398Z",
    atl: 1.83,
    atl_change_percentage: 20.41103,
    atl_date: "2023-06-10T04:30:39.245Z",
    roi: null,
    last_updated: "2023-06-28T12:06:30.887Z",
  },
  stmx: {
    id: "storm",
    symbol: "stmx",
    name: "StormX",
    image:
      "https://assets.coingecko.com/coins/images/1369/large/StormX.png?1603113002",
    current_price: 0.00388009,
    market_cap: 42849323,
    market_cap_rank: 431,
    fully_diluted_valuation: 48692412,
    total_volume: 2559946,
    high_24h: 0.00404224,
    low_24h: 0.00381757,
    price_change_24h: -0.000097032077757806,
    price_change_percentage_24h: -2.43976,
    market_cap_change_24h: -883108.909776099,
    market_cap_change_percentage_24h: -2.01935,
    circulating_supply: 11000000000,
    total_supply: 12500000000,
    max_supply: 12500000000,
    ath: 0.165032,
    ath_change_percentage: -97.63962,
    ath_date: "2018-01-05T00:00:00.000Z",
    atl: 0.00057665,
    atl_change_percentage: 575.52173,
    atl_date: "2020-03-13T02:24:13.443Z",
    roi: {
      times: -0.6119909418521998,
      currency: "usd",
      percentage: -61.19909418521998,
    },
    last_updated: "2023-06-28T12:06:31.735Z",
  },
  rook: {
    id: "rook",
    symbol: "rook",
    name: "Rook",
    image:
      "https://assets.coingecko.com/coins/images/13005/large/keeper_dao_logo.jpg?1604316506",
    current_price: 56.1,
    market_cap: 42701266,
    market_cap_rank: 432,
    fully_diluted_valuation: 71144071,
    total_volume: 49517,
    high_24h: 57.61,
    low_24h: 53.38,
    price_change_24h: -1.2432144125216524,
    price_change_percentage_24h: -2.16811,
    market_cap_change_24h: -491786.73867664486,
    market_cap_change_percentage_24h: -1.13858,
    circulating_supply: 761211.2689174691,
    total_supply: 1268245.04078443,
    max_supply: 1268245.04078443,
    ath: 838.98,
    ath_change_percentage: -93.31372,
    ath_date: "2021-02-14T23:36:56.918Z",
    atl: 11.36,
    atl_change_percentage: 393.82953,
    atl_date: "2022-11-10T19:45:16.927Z",
    roi: null,
    last_updated: "2023-06-28T12:05:24.432Z",
  },
  rare: {
    id: "superrare",
    symbol: "rare",
    name: "SuperRare",
    image:
      "https://assets.coingecko.com/coins/images/17753/large/RARE.jpg?1629220534",
    current_price: 0.068845,
    market_cap: 42500790,
    market_cap_rank: 433,
    fully_diluted_valuation: 68914198,
    total_volume: 618179,
    high_24h: 0.072448,
    low_24h: 0.068631,
    price_change_24h: -0.003602497972831909,
    price_change_percentage_24h: -4.97253,
    market_cap_change_24h: -2166617.4888231084,
    market_cap_change_percentage_24h: -4.85056,
    circulating_supply: 616720368.763,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 3.64,
    ath_change_percentage: -98.10751,
    ath_date: "2021-10-11T06:31:17.858Z",
    atl: 0.05966,
    atl_change_percentage: 15.50861,
    atl_date: "2023-06-10T06:52:01.701Z",
    roi: null,
    last_updated: "2023-06-28T12:06:29.449Z",
  },
  cusd: {
    id: "celo-dollar",
    symbol: "cusd",
    name: "Celo Dollar",
    image:
      "https://assets.coingecko.com/coins/images/13161/large/icon-celo-dollar-color-1000-circle-cropped.png?1605771134",
    current_price: 0.997643,
    market_cap: 42483865,
    market_cap_rank: 434,
    fully_diluted_valuation: 42483865,
    total_volume: 274867,
    high_24h: 1.003,
    low_24h: 0.991479,
    price_change_24h: -0.000361587801427588,
    price_change_percentage_24h: -0.03623,
    market_cap_change_24h: -103568.84074519575,
    market_cap_change_percentage_24h: -0.24319,
    circulating_supply: 42575747.6242305,
    total_supply: 42575747.6242305,
    max_supply: null,
    ath: 1.14,
    ath_change_percentage: -12.59636,
    ath_date: "2021-09-10T07:19:08.038Z",
    atl: 0.886584,
    atl_change_percentage: 12.54902,
    atl_date: "2023-03-11T09:01:56.928Z",
    roi: null,
    last_updated: "2023-06-28T12:06:35.540Z",
  },
  fun: {
    id: "funfair",
    symbol: "fun",
    name: "FUNToken",
    image:
      "https://assets.coingecko.com/coins/images/761/large/FUN.png?1678773913",
    current_price: 0.00400142,
    market_cap: 42464568,
    market_cap_rank: 435,
    fully_diluted_valuation: 44071158,
    total_volume: 148127,
    high_24h: 0.00422117,
    low_24h: 0.00400417,
    price_change_24h: -0.000138343196040634,
    price_change_percentage_24h: -3.34182,
    market_cap_change_24h: -1493184.3829289675,
    market_cap_change_percentage_24h: -3.39686,
    circulating_supply: 10598879189.265394,
    total_supply: 10999873621,
    max_supply: null,
    ath: 0.191066,
    ath_change_percentage: -97.90307,
    ath_date: "2018-01-08T00:00:00.000Z",
    atl: 0.00105506,
    atl_change_percentage: 279.74186,
    atl_date: "2020-03-13T02:22:42.216Z",
    roi: null,
    last_updated: "2023-06-28T12:06:11.399Z",
  },
  ark: {
    id: "ark",
    symbol: "ark",
    name: "ARK",
    image:
      "https://assets.coingecko.com/coins/images/613/large/ark.png?1547034308",
    current_price: 0.246886,
    market_cap: 42456760,
    market_cap_rank: 436,
    fully_diluted_valuation: null,
    total_volume: 1319147,
    high_24h: 0.26075,
    low_24h: 0.242107,
    price_change_24h: -0.008031208545537571,
    price_change_percentage_24h: -3.15051,
    market_cap_change_24h: -1702472.544784762,
    market_cap_change_percentage_24h: -3.8553,
    circulating_supply: 173904854,
    total_supply: null,
    max_supply: null,
    ath: 10.22,
    ath_change_percentage: -97.61162,
    ath_date: "2018-01-10T00:00:00.000Z",
    atl: 0.0339403,
    atl_change_percentage: 619.31559,
    atl_date: "2017-03-22T00:00:00.000Z",
    roi: {
      times: 23.68863205674039,
      currency: "usd",
      percentage: 2368.863205674039,
    },
    last_updated: "2023-06-28T12:06:29.235Z",
  },
  arrr: {
    id: "pirate-chain",
    symbol: "arrr",
    name: "Pirate Chain",
    image:
      "https://assets.coingecko.com/coins/images/6905/large/Pirate_Chain.png?1560913844",
    current_price: 0.215158,
    market_cap: 42291215,
    market_cap_rank: 437,
    fully_diluted_valuation: 43107279,
    total_volume: 43902,
    high_24h: 0.2205,
    low_24h: 0.212484,
    price_change_24h: -0.004165564842689096,
    price_change_percentage_24h: -1.89927,
    market_cap_change_24h: -774652.5895541832,
    market_cap_change_percentage_24h: -1.79876,
    circulating_supply: 196213797.97055,
    total_supply: 200000000,
    max_supply: null,
    ath: 16.76,
    ath_change_percentage: -98.71403,
    ath_date: "2021-04-23T18:13:27.423Z",
    atl: 0.00797788,
    atl_change_percentage: 2601.67468,
    atl_date: "2020-11-26T02:10:52.806Z",
    roi: null,
    last_updated: "2023-06-28T12:05:58.280Z",
  },
  mimatic: {
    id: "mimatic",
    symbol: "mimatic",
    name: "MAI",
    image:
      "https://assets.coingecko.com/coins/images/15264/large/mimatic-red.png?1620281018",
    current_price: 0.977446,
    market_cap: 42184902,
    market_cap_rank: 438,
    fully_diluted_valuation: 299185574,
    total_volume: 957977,
    high_24h: 0.985741,
    low_24h: 0.973689,
    price_change_24h: -0.00444820292705761,
    price_change_percentage_24h: -0.45302,
    market_cap_change_24h: -93226.42846368253,
    market_cap_change_percentage_24h: -0.22051,
    circulating_supply: 43124206.581068,
    total_supply: 305847350.86821,
    max_supply: null,
    ath: 1.35,
    ath_change_percentage: -27.75736,
    ath_date: "2021-05-29T21:51:31.732Z",
    atl: 0.809109,
    atl_change_percentage: 20.9069,
    atl_date: "2022-01-24T14:55:16.883Z",
    roi: null,
    last_updated: "2023-06-28T12:06:26.422Z",
  },
  mbl: {
    id: "moviebloc",
    symbol: "mbl",
    name: "MovieBloc",
    image:
      "https://assets.coingecko.com/coins/images/8460/large/MBL_Logo.png?1628258374",
    current_price: 0.00257198,
    market_cap: 42176030,
    market_cap_rank: 439,
    fully_diluted_valuation: 77409118,
    total_volume: 5294989,
    high_24h: 0.00270658,
    low_24h: 0.00255005,
    price_change_24h: -0.000074249030830617,
    price_change_percentage_24h: -2.80584,
    market_cap_change_24h: -648575.1856150404,
    market_cap_change_percentage_24h: -1.51449,
    circulating_supply: 16345372888,
    total_supply: 30000000000,
    max_supply: 30000000000,
    ath: 0.0450322,
    ath_change_percentage: -94.27011,
    ath_date: "2021-04-02T01:08:31.856Z",
    atl: 0.00042973,
    atl_change_percentage: 500.44322,
    atl_date: "2019-10-25T08:25:31.503Z",
    roi: null,
    last_updated: "2023-06-28T12:06:32.299Z",
  },
  xvg: {
    id: "verge",
    symbol: "xvg",
    name: "Verge",
    image:
      "https://assets.coingecko.com/coins/images/203/large/verge-symbol-color_logo.png?1561543281",
    current_price: 0.00254955,
    market_cap: 42103578,
    market_cap_rank: 440,
    fully_diluted_valuation: 42192624,
    total_volume: 49850690,
    high_24h: 0.00282817,
    low_24h: 0.00164796,
    price_change_24h: 0.00089517,
    price_change_percentage_24h: 54.10941,
    market_cap_change_24h: 14861929,
    market_cap_change_percentage_24h: 54.55591,
    circulating_supply: 16520060987.8232,
    total_supply: 16555000000,
    max_supply: null,
    ath: 0.261874,
    ath_change_percentage: -99.02677,
    ath_date: "2017-12-24T00:00:00.000Z",
    atl: 0.00000223,
    atl_change_percentage: 113953.85495,
    atl_date: "2015-02-06T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:06:29.771Z",
  },
  zig: {
    id: "zignaly",
    symbol: "zig",
    name: "Zignaly",
    image:
      "https://assets.coingecko.com/coins/images/14796/large/zignaly.jpg?1618496339",
    current_price: 0.00546843,
    market_cap: 42062845,
    market_cap_rank: 441,
    fully_diluted_valuation: 147937959,
    total_volume: 380155,
    high_24h: 0.00571122,
    low_24h: 0.00534339,
    price_change_24h: -0.000128093179636649,
    price_change_percentage_24h: -2.2888,
    market_cap_change_24h: -579847.5131899193,
    market_cap_change_percentage_24h: -1.35978,
    circulating_supply: 0,
    total_supply: 2000000000,
    max_supply: 2000000000,
    ath: 0.224179,
    ath_change_percentage: -97.59033,
    ath_date: "2021-04-15T15:39:42.111Z",
    atl: 0.00459139,
    atl_change_percentage: 17.65476,
    atl_date: "2023-06-15T12:16:12.165Z",
    roi: null,
    last_updated: "2023-06-28T12:06:26.044Z",
  },
  idex: {
    id: "aurora-dao",
    symbol: "idex",
    name: "IDEX",
    image:
      "https://assets.coingecko.com/coins/images/2565/large/idexlogo.png?1684982937",
    current_price: 0.057009,
    market_cap: 42034681,
    market_cap_rank: 442,
    fully_diluted_valuation: 57189520,
    total_volume: 4807922,
    high_24h: 0.06137,
    low_24h: 0.057154,
    price_change_24h: -0.004026783924971522,
    price_change_percentage_24h: -6.59745,
    market_cap_change_24h: -2742601.3533456847,
    market_cap_change_percentage_24h: -6.12498,
    circulating_supply: 735006708.5687144,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 0.937763,
    ath_change_percentage: -93.90326,
    ath_date: "2021-09-08T08:54:22.595Z",
    atl: 0.00563813,
    atl_change_percentage: 914.04241,
    atl_date: "2020-03-23T11:59:26.191Z",
    roi: {
      times: -0.04985486425427817,
      currency: "usd",
      percentage: -4.985486425427816,
    },
    last_updated: "2023-06-28T12:06:29.943Z",
  },
  mnw: {
    id: "morpheus-network",
    symbol: "mnw",
    name: "Morpheus Network",
    image:
      "https://assets.coingecko.com/coins/images/2379/large/MRPH_CoinGecko.png?1635847791",
    current_price: 1.12,
    market_cap: 41994207,
    market_cap_rank: 443,
    fully_diluted_valuation: 53608767,
    total_volume: 250226,
    high_24h: 1.2,
    low_24h: 1.12,
    price_change_24h: -0.07336148614609517,
    price_change_percentage_24h: -6.16001,
    market_cap_change_24h: -2340218.8536683545,
    market_cap_change_percentage_24h: -5.27856,
    circulating_supply: 37520088.3855,
    total_supply: 47897218,
    max_supply: 47897218,
    ath: 5.46,
    ath_change_percentage: -79.49901,
    ath_date: "2021-11-11T07:57:14.750Z",
    atl: 0.00992725,
    atl_change_percentage: 11178.70121,
    atl_date: "2018-08-11T00:00:00.000Z",
    roi: {
      times: 1.4834897950254755,
      currency: "usd",
      percentage: 148.34897950254756,
    },
    last_updated: "2023-06-28T12:05:52.538Z",
  },
  solo: {
    id: "solo-coin",
    symbol: "solo",
    name: "Sologenic",
    image:
      "https://assets.coingecko.com/coins/images/10771/large/solo.png?1639914533",
    current_price: 0.105025,
    market_cap: 41970046,
    market_cap_rank: 444,
    fully_diluted_valuation: 42054289,
    total_volume: 245704,
    high_24h: 0.105989,
    low_24h: 0.101162,
    price_change_24h: 0.00242455,
    price_change_percentage_24h: 2.36311,
    market_cap_change_24h: 913621,
    market_cap_change_percentage_24h: 2.22528,
    circulating_supply: 399198717,
    total_supply: 399198717,
    max_supply: 400000000,
    ath: 6.55,
    ath_change_percentage: -98.39512,
    ath_date: "2021-12-02T22:46:01.114Z",
    atl: 0.061723,
    atl_change_percentage: 70.33523,
    atl_date: "2021-10-28T22:57:25.877Z",
    roi: null,
    last_updated: "2023-06-28T12:06:26.118Z",
  },
  wrx: {
    id: "wazirx",
    symbol: "wrx",
    name: "WazirX",
    image:
      "https://assets.coingecko.com/coins/images/10547/large/WazirX.png?1580834330",
    current_price: 0.091356,
    market_cap: 41741100,
    market_cap_rank: 445,
    fully_diluted_valuation: 91433830,
    total_volume: 1766453,
    high_24h: 0.113121,
    low_24h: 0.091403,
    price_change_24h: -0.003692626201013974,
    price_change_percentage_24h: -3.885,
    market_cap_change_24h: -1557736.5371459275,
    market_cap_change_percentage_24h: -3.59764,
    circulating_supply: 456517027.3318671,
    total_supply: 1000000000,
    max_supply: null,
    ath: 5.88,
    ath_change_percentage: -98.44465,
    ath_date: "2021-04-05T06:58:37.399Z",
    atl: 0.04757088,
    atl_change_percentage: 92.20547,
    atl_date: "2020-02-05T07:19:43.950Z",
    roi: {
      times: 3.5677854499006183,
      currency: "usd",
      percentage: 356.77854499006185,
    },
    last_updated: "2023-06-28T12:06:21.120Z",
  },
  ray: {
    id: "raydium",
    symbol: "ray",
    name: "Raydium",
    image:
      "https://assets.coingecko.com/coins/images/13928/large/PSigc4ie_400x400.jpg?1612875614",
    current_price: 0.194017,
    market_cap: 41712273,
    market_cap_rank: 446,
    fully_diluted_valuation: 107716802,
    total_volume: 12328904,
    high_24h: 0.206734,
    low_24h: 0.186047,
    price_change_24h: -0.005669565734327115,
    price_change_percentage_24h: -2.83924,
    market_cap_change_24h: -1179342.0791812912,
    market_cap_change_percentage_24h: -2.74959,
    circulating_supply: 214918298.014533,
    total_supply: 555000000,
    max_supply: 555000000,
    ath: 16.83,
    ath_change_percentage: -98.84699,
    ath_date: "2021-09-12T20:20:23.998Z",
    atl: 0.134391,
    atl_change_percentage: 44.41785,
    atl_date: "2022-12-29T22:15:53.927Z",
    roi: null,
    last_updated: "2023-06-28T12:06:31.938Z",
  },
  asd: {
    id: "asd",
    symbol: "asd",
    name: "AscendEx",
    image:
      "https://assets.coingecko.com/coins/images/5003/large/bitmax.png?1621310871",
    current_price: 0.056122,
    market_cap: 41674486,
    market_cap_rank: 447,
    fully_diluted_valuation: 41674486,
    total_volume: 4180068,
    high_24h: 0.060096,
    low_24h: 0.055489,
    price_change_24h: -0.003565573666595858,
    price_change_percentage_24h: -5.97371,
    market_cap_change_24h: -2574157.229015939,
    market_cap_change_percentage_24h: -5.81748,
    circulating_supply: 742983477.862,
    total_supply: 742983477.862,
    max_supply: null,
    ath: 3.26,
    ath_change_percentage: -98.278,
    ath_date: "2021-03-27T18:14:42.486Z",
    atl: 0.01194798,
    atl_change_percentage: 369.45784,
    atl_date: "2022-07-12T04:44:33.749Z",
    roi: {
      times: 2.0836351838492484,
      currency: "usd",
      percentage: 208.36351838492484,
    },
    last_updated: "2023-06-28T12:06:24.270Z",
  },
  uwu: {
    id: "uwu-lend",
    symbol: "uwu",
    name: "UwU Lend",
    image:
      "https://assets.coingecko.com/coins/images/27408/large/uwu_logo_no-bg_1024.png?1663842922",
    current_price: 21.19,
    market_cap: 41193908,
    market_cap_rank: 448,
    fully_diluted_valuation: 339042654,
    total_volume: 119896,
    high_24h: 22.25,
    low_24h: 21.17,
    price_change_24h: -0.8691258158648303,
    price_change_percentage_24h: -3.93995,
    market_cap_change_24h: -1361744.51197505,
    market_cap_change_percentage_24h: -3.19991,
    circulating_supply: 1944010.62547883,
    total_supply: 16000000,
    max_supply: 16000000,
    ath: 380.72,
    ath_change_percentage: -94.43412,
    ath_date: "2022-10-01T09:51:24.562Z",
    atl: 5.53,
    atl_change_percentage: 283.04716,
    atl_date: "2023-03-29T00:34:40.284Z",
    roi: null,
    last_updated: "2023-06-28T12:00:38.191Z",
  },
  cbk: {
    id: "cobak-token",
    symbol: "cbk",
    name: "Cobak",
    image:
      "https://assets.coingecko.com/coins/images/13459/large/cbk-128-128.png?1608760936",
    current_price: 0.541824,
    market_cap: 41062830,
    market_cap_rank: 449,
    fully_diluted_valuation: 54290485,
    total_volume: 1120657,
    high_24h: 0.561778,
    low_24h: 0.537004,
    price_change_24h: -0.012963504968604544,
    price_change_percentage_24h: -2.33666,
    market_cap_change_24h: -899466.080992341,
    market_cap_change_percentage_24h: -2.14351,
    circulating_supply: 75635409,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 15.74,
    ath_change_percentage: -96.55029,
    ath_date: "2021-04-02T12:59:22.254Z",
    atl: 0.433554,
    atl_change_percentage: 25.22193,
    atl_date: "2022-12-30T07:59:59.513Z",
    roi: null,
    last_updated: "2023-06-28T12:05:49.912Z",
  },
  dawn: {
    id: "dawn-protocol",
    symbol: "dawn",
    name: "Dawn Protocol",
    image:
      "https://assets.coingecko.com/coins/images/11555/large/dawn_protocol.png?1591060256",
    current_price: 0.550198,
    market_cap: 41032591,
    market_cap_rank: 450,
    fully_diluted_valuation: 42202934,
    total_volume: 800378,
    high_24h: 0.569462,
    low_24h: 0.548463,
    price_change_24h: -0.009065817946023904,
    price_change_percentage_24h: -1.62103,
    market_cap_change_24h: -566758.2616810128,
    market_cap_change_percentage_24h: -1.36242,
    circulating_supply: 74464266.09522183,
    total_supply: 76588156.8274288,
    max_supply: null,
    ath: 9.63,
    ath_change_percentage: -94.27959,
    ath_date: "2021-04-28T00:23:38.132Z",
    atl: 0.03183375,
    atl_change_percentage: 1630.98487,
    atl_date: "2020-08-05T18:09:13.078Z",
    roi: null,
    last_updated: "2023-06-28T12:05:52.192Z",
  },
  agld: {
    id: "adventure-gold",
    symbol: "agld",
    name: "Adventure Gold",
    image:
      "https://assets.coingecko.com/coins/images/18125/large/lpgblc4h_400x400.jpg?1630570955",
    current_price: 0.528708,
    market_cap: 40908858,
    market_cap_rank: 451,
    fully_diluted_valuation: 42354195,
    total_volume: 13430677,
    high_24h: 0.534911,
    low_24h: 0.469308,
    price_change_24h: 0.0594,
    price_change_percentage_24h: 12.65688,
    market_cap_change_24h: 4628323,
    market_cap_change_percentage_24h: 12.75704,
    circulating_supply: 77270001,
    total_supply: 77270001,
    max_supply: 80000000,
    ath: 7.7,
    ath_change_percentage: -93.13285,
    ath_date: "2021-09-03T11:41:40.240Z",
    atl: 0.208231,
    atl_change_percentage: 154.01116,
    atl_date: "2022-11-09T23:40:23.330Z",
    roi: null,
    last_updated: "2023-06-28T12:06:32.569Z",
  },
  strk: {
    id: "strike",
    symbol: "strk",
    name: "Strike",
    image:
      "https://assets.coingecko.com/coins/images/14607/large/Jw-36llq_400x400.jpg?1617243607",
    current_price: 10.77,
    market_cap: 40809688,
    market_cap_rank: 452,
    fully_diluted_valuation: 70696906,
    total_volume: 2811619,
    high_24h: 11.16,
    low_24h: 10.66,
    price_change_24h: -0.15771063377997763,
    price_change_percentage_24h: -1.44357,
    market_cap_change_24h: -338330.071082823,
    market_cap_change_percentage_24h: -0.82223,
    circulating_supply: 3775718.2886028234,
    total_supply: 6540888,
    max_supply: 6540888,
    ath: 105.33,
    ath_change_percentage: -89.7387,
    ath_date: "2021-04-12T06:33:34.972Z",
    atl: 8.52,
    atl_change_percentage: 26.85346,
    atl_date: "2022-12-30T12:45:05.665Z",
    roi: null,
    last_updated: "2023-06-28T12:05:56.184Z",
  },
  "usd+": {
    id: "usd",
    symbol: "usd+",
    name: "Overnight.fi USD+",
    image:
      "https://assets.coingecko.com/coins/images/25757/large/USD__logo.png?1653519267",
    current_price: 0.999354,
    market_cap: 40640388,
    market_cap_rank: 453,
    fully_diluted_valuation: 40640388,
    total_volume: 2999753,
    high_24h: 1.006,
    low_24h: 0.992883,
    price_change_24h: -0.002065447311712321,
    price_change_percentage_24h: -0.20625,
    market_cap_change_24h: 383595,
    market_cap_change_percentage_24h: 0.95287,
    circulating_supply: 40616371.0447,
    total_supply: 40616371.0447,
    max_supply: null,
    ath: 1.18,
    ath_change_percentage: -15.25022,
    ath_date: "2023-04-03T20:19:29.075Z",
    atl: 0.739372,
    atl_change_percentage: 35.32982,
    atl_date: "2022-06-01T07:24:44.327Z",
    roi: null,
    last_updated: "2023-06-28T12:06:28.143Z",
  },
  storj: {
    id: "storj",
    symbol: "storj",
    name: "Storj",
    image:
      "https://assets.coingecko.com/coins/images/949/large/storj.png?1547034811",
    current_price: 0.278887,
    market_cap: 40258348,
    market_cap_rank: 454,
    fully_diluted_valuation: 118993688,
    total_volume: 11338715,
    high_24h: 0.279985,
    low_24h: 0.264594,
    price_change_24h: 0.01047078,
    price_change_percentage_24h: 3.90095,
    market_cap_change_24h: 1661571,
    market_cap_change_percentage_24h: 4.30495,
    circulating_supply: 143787438.7,
    total_supply: 424999998,
    max_supply: null,
    ath: 3.81,
    ath_change_percentage: -92.64427,
    ath_date: "2021-03-28T01:39:34.946Z",
    atl: 0.05024,
    atl_change_percentage: 457.30443,
    atl_date: "2020-03-13T02:29:53.872Z",
    roi: {
      times: -0.442226195091702,
      currency: "usd",
      percentage: -44.2226195091702,
    },
    last_updated: "2023-06-28T12:06:25.928Z",
  },
  uqc: {
    id: "uquid-coin",
    symbol: "uqc",
    name: "Uquid Coin",
    image:
      "https://assets.coingecko.com/coins/images/1341/large/uquid-coin.png?1548759712",
    current_price: 4.01,
    market_cap: 40117656,
    market_cap_rank: 455,
    fully_diluted_valuation: 160470622,
    total_volume: 1840260,
    high_24h: 4.05,
    low_24h: 3.96,
    price_change_24h: -0.04351133821836228,
    price_change_percentage_24h: -1.07474,
    market_cap_change_24h: -243263.9733467549,
    market_cap_change_percentage_24h: -0.60272,
    circulating_supply: 10000000,
    total_supply: 40000000,
    max_supply: null,
    ath: 34.02,
    ath_change_percentage: -88.21138,
    ath_date: "2021-05-08T22:28:47.890Z",
    atl: 0.02606502,
    atl_change_percentage: 15288.6016,
    atl_date: "2019-07-24T00:00:00.000Z",
    roi: {
      times: 1.5031483466102038,
      currency: "usd",
      percentage: 150.31483466102037,
    },
    last_updated: "2023-06-28T12:06:34.824Z",
  },
  tlm: {
    id: "alien-worlds",
    symbol: "tlm",
    name: "Alien Worlds",
    image:
      "https://assets.coingecko.com/coins/images/14676/large/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-lF1.jpg?1617700061",
    current_price: 0.01110139,
    market_cap: 40040548,
    market_cap_rank: 456,
    fully_diluted_valuation: 111157457,
    total_volume: 3631327,
    high_24h: 0.01179709,
    low_24h: 0.01107481,
    price_change_24h: -0.00065952805130147,
    price_change_percentage_24h: -5.60779,
    market_cap_change_24h: -2236067.67763149,
    market_cap_change_percentage_24h: -5.28914,
    circulating_supply: 3602146833.928,
    total_supply: 6043538074.1198,
    max_supply: 10000000000,
    ath: 7.16,
    ath_change_percentage: -99.84469,
    ath_date: "2021-04-11T06:36:48.412Z",
    atl: 0.0095672,
    atl_change_percentage: 16.18625,
    atl_date: "2023-06-10T13:11:49.543Z",
    roi: null,
    last_updated: "2023-06-28T12:06:27.741Z",
  },
  saitama: {
    id: "saitama-inu",
    symbol: "saitama",
    name: "Saitama",
    image:
      "https://assets.coingecko.com/coins/images/16353/large/SOIKDUWf_400x400.jpeg?1661170022",
    current_price: 0.00090088,
    market_cap: 39992337,
    market_cap_rank: 457,
    fully_diluted_valuation: 89444638,
    total_volume: 696956,
    high_24h: 0.00091399,
    low_24h: 0.000885,
    price_change_24h: 8.0643e-7,
    price_change_percentage_24h: 0.0896,
    market_cap_change_24h: -241348.37537043542,
    market_cap_change_percentage_24h: -0.59987,
    circulating_supply: 44711832656.8,
    total_supply: 44812981146,
    max_supply: 100000000000,
    ath: 0.00433786,
    ath_change_percentage: -79.38047,
    ath_date: "2022-07-29T23:36:40.637Z",
    atl: 2.605e-11,
    atl_change_percentage: 3433635110.57212,
    atl_date: "2021-07-07T19:28:30.496Z",
    roi: null,
    last_updated: "2023-06-28T12:06:20.174Z",
  },
  meta: {
    id: "metadium",
    symbol: "meta",
    name: "Metadium",
    image:
      "https://assets.coingecko.com/coins/images/5247/large/META_Logo_black.png?1548833790",
    current_price: 0.0234237,
    market_cap: 39895293,
    market_cap_rank: 458,
    fully_diluted_valuation: 47286964,
    total_volume: 652766,
    high_24h: 0.02456811,
    low_24h: 0.02321904,
    price_change_24h: -0.000391200099615928,
    price_change_percentage_24h: -1.64267,
    market_cap_change_24h: -269129.362750344,
    market_cap_change_percentage_24h: -0.67007,
    circulating_supply: 1687369624,
    total_supply: 2000000000,
    max_supply: null,
    ath: 0.452667,
    ath_change_percentage: -94.77494,
    ath_date: "2021-04-01T01:24:04.623Z",
    atl: 0.00295026,
    atl_change_percentage: 701.69518,
    atl_date: "2020-03-13T02:24:18.338Z",
    roi: {
      times: -0.8932854374334275,
      currency: "eth",
      percentage: -89.32854374334275,
    },
    last_updated: "2023-06-28T12:05:55.920Z",
  },
  dodo: {
    id: "dodo",
    symbol: "dodo",
    name: "DODO",
    image:
      "https://assets.coingecko.com/coins/images/12651/large/dodo_logo.png?1601433025",
    current_price: 0.094867,
    market_cap: 39684122,
    market_cap_rank: 459,
    fully_diluted_valuation: 94944356,
    total_volume: 4957685,
    high_24h: 0.10253,
    low_24h: 0.09486,
    price_change_24h: -0.006251155777387081,
    price_change_percentage_24h: -6.18204,
    market_cap_change_24h: -2807387.548009284,
    market_cap_change_percentage_24h: -6.60694,
    circulating_supply: 417972417.1603893,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 8.38,
    ath_change_percentage: -98.86661,
    ath_date: "2021-02-20T09:19:28.624Z",
    atl: 0.080974,
    atl_change_percentage: 17.2522,
    atl_date: "2023-06-10T14:15:17.108Z",
    roi: null,
    last_updated: "2023-06-28T12:06:26.583Z",
  },
  ogn: {
    id: "origin-protocol",
    symbol: "ogn",
    name: "Origin Protocol",
    image:
      "https://assets.coingecko.com/coins/images/3296/large/op.jpg?1547037878",
    current_price: 0.078701,
    market_cap: 39613617,
    market_cap_rank: 460,
    fully_diluted_valuation: 78797276,
    total_volume: 3984463,
    high_24h: 0.081897,
    low_24h: 0.077414,
    price_change_24h: -0.002001897532151131,
    price_change_percentage_24h: -2.48058,
    market_cap_change_24h: -907076.5828172043,
    market_cap_change_percentage_24h: -2.23855,
    circulating_supply: 502728259,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 3.35,
    ath_change_percentage: -97.65,
    ath_date: "2021-04-08T08:34:23.319Z",
    atl: 0.062786,
    atl_change_percentage: 25.46961,
    atl_date: "2023-06-10T06:51:40.879Z",
    roi: {
      times: -0.4230129670237926,
      currency: "usd",
      percentage: -42.30129670237926,
    },
    last_updated: "2023-06-28T12:06:32.184Z",
  },
  movr: {
    id: "moonriver",
    symbol: "movr",
    name: "Moonriver",
    image:
      "https://assets.coingecko.com/coins/images/17984/large/9285.png?1630028620",
    current_price: 5.61,
    market_cap: 39607398,
    market_cap_rank: 461,
    fully_diluted_valuation: 60925914,
    total_volume: 1536042,
    high_24h: 6.02,
    low_24h: 5.57,
    price_change_24h: -0.20498764184866136,
    price_change_percentage_24h: -3.52567,
    market_cap_change_24h: -1658663.3422516435,
    market_cap_change_percentage_24h: -4.01944,
    circulating_supply: 7057056,
    total_supply: 10855487,
    max_supply: 10855487,
    ath: 494.26,
    ath_change_percentage: -98.86359,
    ath_date: "2021-09-11T11:40:35.912Z",
    atl: 4.57,
    atl_change_percentage: 23.00522,
    atl_date: "2023-06-15T11:15:09.970Z",
    roi: null,
    last_updated: "2023-06-28T12:06:29.938Z",
  },
  sdao: {
    id: "singularitydao",
    symbol: "sdao",
    name: "SingularityDAO",
    image:
      "https://assets.coingecko.com/coins/images/15385/large/200x200_logo.png?1665743635",
    current_price: 0.467573,
    market_cap: 39519263,
    market_cap_rank: 462,
    fully_diluted_valuation: 46831184,
    total_volume: 1454351,
    high_24h: 0.494521,
    low_24h: 0.458965,
    price_change_24h: -0.020567436391046023,
    price_change_percentage_24h: -4.21343,
    market_cap_change_24h: -1674077.681986168,
    market_cap_change_percentage_24h: -4.06395,
    circulating_supply: 84386641.8048258,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 6.62,
    ath_change_percentage: -92.92948,
    ath_date: "2021-09-06T23:53:52.687Z",
    atl: 0.145726,
    atl_change_percentage: 221.38433,
    atl_date: "2021-05-13T15:50:14.728Z",
    roi: null,
    last_updated: "2023-06-28T12:06:28.209Z",
  },
  aurabal: {
    id: "aura-bal",
    symbol: "aurabal",
    name: "Aura BAL",
    image:
      "https://assets.coingecko.com/coins/images/26538/large/auraBAL.png?1658721102",
    current_price: 12.61,
    market_cap: 39317653,
    market_cap_rank: 463,
    fully_diluted_valuation: 39317653,
    total_volume: 66497,
    high_24h: 13.34,
    low_24h: 12.53,
    price_change_24h: -0.5159634230710068,
    price_change_percentage_24h: -3.93141,
    market_cap_change_24h: -1667004.8589586467,
    market_cap_change_percentage_24h: -4.06739,
    circulating_supply: 3118428.38499568,
    total_supply: 3118428.38499568,
    max_supply: null,
    ath: 21.61,
    ath_change_percentage: -41.66672,
    ath_date: "2023-01-31T20:55:03.507Z",
    atl: 8.2,
    atl_change_percentage: 53.76765,
    atl_date: "2022-11-09T13:15:21.224Z",
    roi: null,
    last_updated: "2023-06-28T12:02:57.053Z",
  },
  blid: {
    id: "bolide",
    symbol: "blid",
    name: "Bolide",
    image:
      "https://assets.coingecko.com/coins/images/25548/large/BLID_token_logo_200x200.png?1652334831",
    current_price: 0.01866847,
    market_cap: 39153791,
    market_cap_rank: 464,
    fully_diluted_valuation: 186932303,
    total_volume: 66889,
    high_24h: 0.01930709,
    low_24h: 0.0186515,
    price_change_24h: -0.000359215490310493,
    price_change_percentage_24h: -1.88786,
    market_cap_change_24h: -594682.3272681758,
    market_cap_change_percentage_24h: -1.49611,
    circulating_supply: 2094543895.58198,
    total_supply: 9989820984.00977,
    max_supply: 10000000000,
    ath: 0.100051,
    ath_change_percentage: -81.32181,
    ath_date: "2022-10-07T10:22:40.547Z",
    atl: 0.01690004,
    atl_change_percentage: 10.57836,
    atl_date: "2023-01-27T09:28:48.256Z",
    roi: null,
    last_updated: "2023-06-28T12:05:57.842Z",
  },
  qanx: {
    id: "qanplatform",
    symbol: "qanx",
    name: "QANplatform",
    image:
      "https://assets.coingecko.com/coins/images/15977/large/qanx.png?1637574290",
    current_price: 0.01722084,
    market_cap: 39085883,
    market_cap_rank: 465,
    fully_diluted_valuation: 57464302,
    total_volume: 87112,
    high_24h: 0.01789136,
    low_24h: 0.0165723,
    price_change_24h: -0.00058730829257346,
    price_change_percentage_24h: -3.29798,
    market_cap_change_24h: -1316313.8887312338,
    market_cap_change_percentage_24h: -3.25803,
    circulating_supply: 2267255628.84,
    total_supply: 3333333000,
    max_supply: 3333333000,
    ath: 0.203412,
    ath_change_percentage: -91.52492,
    ath_date: "2021-11-27T19:36:50.871Z",
    atl: 0.00011724,
    atl_change_percentage: 14604.83976,
    atl_date: "2023-01-13T15:01:38.815Z",
    roi: null,
    last_updated: "2023-06-28T12:05:37.251Z",
  },
  raca: {
    id: "radio-caca",
    symbol: "raca",
    name: "Radio Caca",
    image:
      "https://assets.coingecko.com/coins/images/17841/large/ez44_BSs_400x400.jpg?1629464170",
    current_price: 0.00011618,
    market_cap: 38918496,
    market_cap_rank: 466,
    fully_diluted_valuation: 58147786,
    total_volume: 1629762,
    high_24h: 0.00011926,
    low_24h: 0.00011581,
    price_change_24h: -0.000001042675450113,
    price_change_percentage_24h: -0.88948,
    market_cap_change_24h: -272393.5914100632,
    market_cap_change_percentage_24h: -0.69504,
    circulating_supply: 334651566603.973,
    total_supply: 431418233270.64,
    max_supply: 500000000000,
    ath: 0.01191986,
    ath_change_percentage: -99.02442,
    ath_date: "2021-11-14T09:16:57.279Z",
    atl: 0.00010463,
    atl_change_percentage: 11.14023,
    atl_date: "2023-06-14T21:00:42.828Z",
    roi: null,
    last_updated: "2023-06-28T12:06:29.582Z",
  },
  reef: {
    id: "reef",
    symbol: "reef",
    name: "Reef",
    image:
      "https://assets.coingecko.com/coins/images/13504/large/Group_10572.png?1610534130",
    current_price: 0.00170752,
    market_cap: 38809409,
    market_cap_rank: 467,
    fully_diluted_valuation: 38809409,
    total_volume: 6967938,
    high_24h: 0.00178027,
    low_24h: 0.00168519,
    price_change_24h: -0.000038502498998122,
    price_change_percentage_24h: -2.20515,
    market_cap_change_24h: -1004671.2155225202,
    market_cap_change_percentage_24h: -2.52341,
    circulating_supply: 22821409581,
    total_supply: 22821409581,
    max_supply: null,
    ath: 0.05746,
    ath_change_percentage: -97.04041,
    ath_date: "2021-03-15T04:19:41.758Z",
    atl: 0.0013861,
    atl_change_percentage: 22.6872,
    atl_date: "2023-06-10T06:50:46.865Z",
    roi: null,
    last_updated: "2023-06-28T12:06:32.485Z",
  },
  chng: {
    id: "chainge-finance",
    symbol: "chng",
    name: "Chainge Finance",
    image:
      "https://assets.coingecko.com/coins/images/16526/large/symbol-only-on-dark.png?1636532794",
    current_price: 0.088012,
    market_cap: 38778206,
    market_cap_rank: 468,
    fully_diluted_valuation: 71775524,
    total_volume: 28557,
    high_24h: 0.090753,
    low_24h: 0.087544,
    price_change_24h: -0.001213884547992511,
    price_change_percentage_24h: -1.36046,
    market_cap_change_24h: -749115.6442017183,
    market_cap_change_percentage_24h: -1.89518,
    circulating_supply: 440142283.634705,
    total_supply: 716725414,
    max_supply: 814670050,
    ath: 0.20194,
    ath_change_percentage: -56.30873,
    ath_date: "2021-12-05T23:14:26.446Z",
    atl: 0.03094268,
    atl_change_percentage: 185.14011,
    atl_date: "2022-07-06T09:11:54.599Z",
    roi: null,
    last_updated: "2023-06-28T12:06:15.296Z",
  },
  hay: {
    id: "helio-protocol-hay",
    symbol: "hay",
    name: "Destablecoin HAY",
    image:
      "https://assets.coingecko.com/coins/images/26947/large/HAY.png?1660984091",
    current_price: 0.999489,
    market_cap: 38656377,
    market_cap_rank: 469,
    fully_diluted_valuation: 44030507,
    total_volume: 252997,
    high_24h: 1.003,
    low_24h: 0.997826,
    price_change_24h: -0.002266740369377019,
    price_change_percentage_24h: -0.22628,
    market_cap_change_24h: 2209.97,
    market_cap_change_percentage_24h: 0.00572,
    circulating_supply: 38629593.3197526,
    total_supply: 38811916.3792493,
    max_supply: 44000000,
    ath: 2,
    ath_change_percentage: -50.06354,
    ath_date: "2022-08-25T12:10:21.873Z",
    atl: 0.208609,
    atl_change_percentage: 379.07641,
    atl_date: "2022-12-02T02:43:06.227Z",
    roi: null,
    last_updated: "2023-06-28T12:06:17.901Z",
  },
  bsw: {
    id: "biswap",
    symbol: "bsw",
    name: "Biswap",
    image:
      "https://assets.coingecko.com/coins/images/16845/large/biswap.png?1625388985",
    current_price: 0.102979,
    market_cap: 38449938,
    market_cap_rank: 470,
    fully_diluted_valuation: 72151829,
    total_volume: 6841607,
    high_24h: 0.111796,
    low_24h: 0.102727,
    price_change_24h: -0.00863399592491654,
    price_change_percentage_24h: -7.73564,
    market_cap_change_24h: -2914366.466676764,
    market_cap_change_percentage_24h: -7.04561,
    circulating_supply: 373032214,
    total_supply: 434661396,
    max_supply: 700000000,
    ath: 2.1,
    ath_change_percentage: -95.08277,
    ath_date: "2021-12-08T06:55:12.754Z",
    atl: 0.082275,
    atl_change_percentage: 25.28146,
    atl_date: "2023-06-20T14:30:02.483Z",
    roi: null,
    last_updated: "2023-06-28T12:06:27.013Z",
  },
  etn: {
    id: "electroneum",
    symbol: "etn",
    name: "Electroneum",
    image:
      "https://assets.coingecko.com/coins/images/1109/large/electroneum.png?1547224846",
    current_price: 0.00213827,
    market_cap: 38356660,
    market_cap_rank: 471,
    fully_diluted_valuation: 44939140,
    total_volume: 29480,
    high_24h: 0.0022204,
    low_24h: 0.0021377,
    price_change_24h: -0.000049266309331881,
    price_change_percentage_24h: -2.25214,
    market_cap_change_24h: -726130.6413333789,
    market_cap_change_percentage_24h: -1.85793,
    circulating_supply: 17924015801.75,
    total_supply: 21000000000,
    max_supply: null,
    ath: 0.207854,
    ath_change_percentage: -98.97064,
    ath_date: "2018-01-06T00:00:00.000Z",
    atl: 0.00134783,
    atl_change_percentage: 58.74244,
    atl_date: "2020-03-13T02:24:21.810Z",
    roi: null,
    last_updated: "2023-06-28T12:05:56.336Z",
  },
  rep: {
    id: "augur",
    symbol: "rep",
    name: "Augur",
    image:
      "https://assets.coingecko.com/coins/images/309/large/REP.png?1596339859",
    current_price: 4.8,
    market_cap: 38321463,
    market_cap_rank: 472,
    fully_diluted_valuation: 38321463,
    total_volume: 206110,
    high_24h: 4.99,
    low_24h: 4.78,
    price_change_24h: -0.1133650974335163,
    price_change_percentage_24h: -2.30563,
    market_cap_change_24h: -764248.4532574862,
    market_cap_change_percentage_24h: -1.95531,
    circulating_supply: 7970037.96095834,
    total_supply: 7970037.96095834,
    max_supply: 7970037.96095834,
    ath: 341.85,
    ath_change_percentage: -98.59346,
    ath_date: "2016-02-10T00:00:00.000Z",
    atl: 0,
    atl_change_percentage: 0,
    atl_date: "2016-01-22T00:00:00.000Z",
    roi: {
      times: 7.005857840455543,
      currency: "usd",
      percentage: 700.5857840455543,
    },
    last_updated: "2023-06-28T12:06:29.168Z",
  },
  dg: {
    id: "degate",
    symbol: "dg",
    name: "DeGate",
    image:
      "https://assets.coingecko.com/coins/images/14415/large/DG_token_brand_web_rgb_blue.png?1669460087",
    current_price: 0.153007,
    market_cap: 38277391,
    market_cap_rank: 473,
    fully_diluted_valuation: 153054597,
    total_volume: 3293.18,
    high_24h: 0.155395,
    low_24h: 0.151805,
    price_change_24h: 0.00002888,
    price_change_percentage_24h: 0.01888,
    market_cap_change_24h: 61997,
    market_cap_change_percentage_24h: 0.16223,
    circulating_supply: 250089781.0075,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 0.601878,
    ath_change_percentage: -74.5705,
    ath_date: "2021-03-20T13:25:21.771Z",
    atl: 0.091476,
    atl_change_percentage: 67.31751,
    atl_date: "2023-01-09T07:30:46.362Z",
    roi: null,
    last_updated: "2023-06-28T12:02:56.371Z",
  },
  zcx: {
    id: "unizen",
    symbol: "zcx",
    name: "Unizen",
    image:
      "https://assets.coingecko.com/coins/images/14830/large/7xjpHaG.png?1618564961",
    current_price: 0.064705,
    market_cap: 38192386,
    market_cap_rank: 474,
    fully_diluted_valuation: 64740150,
    total_volume: 780718,
    high_24h: 0.067803,
    low_24h: 0.064493,
    price_change_24h: -0.00300206734193767,
    price_change_percentage_24h: -4.4339,
    market_cap_change_24h: -1609086.2887304574,
    market_cap_change_percentage_24h: -4.04278,
    circulating_supply: 589695091.17564,
    total_supply: 999595799.782247,
    max_supply: 999595799.782247,
    ath: 7.03,
    ath_change_percentage: -99.07824,
    ath_date: "2021-09-14T09:34:46.058Z",
    atl: 0.0396437,
    atl_change_percentage: 63.37106,
    atl_date: "2022-09-23T12:05:25.895Z",
    roi: null,
    last_updated: "2023-06-28T12:05:58.092Z",
  },
  premia: {
    id: "premia",
    symbol: "premia",
    name: "Premia",
    image:
      "https://assets.coingecko.com/coins/images/13962/large/apple-touch-icon.png?1623679482",
    current_price: 0.600549,
    market_cap: 38107047,
    market_cap_rank: 475,
    fully_diluted_valuation: 60129561,
    total_volume: 18817.84,
    high_24h: 0.61148,
    low_24h: 0.595548,
    price_change_24h: 0.00204079,
    price_change_percentage_24h: 0.34098,
    market_cap_change_24h: 239091,
    market_cap_change_percentage_24h: 0.63138,
    circulating_supply: 63374896.36363637,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 5.79,
    ath_change_percentage: -89.62318,
    ath_date: "2021-10-26T03:05:54.595Z",
    atl: 0.203602,
    atl_change_percentage: 195.32468,
    atl_date: "2021-07-18T23:42:26.586Z",
    roi: null,
    last_updated: "2023-06-28T12:06:26.617Z",
  },
  bel: {
    id: "bella-protocol",
    symbol: "bel",
    name: "Bella Protocol",
    image:
      "https://assets.coingecko.com/coins/images/12478/large/Bella.png?1602230054",
    current_price: 0.637045,
    market_cap: 38004589,
    market_cap_rank: 476,
    fully_diluted_valuation: 63873258,
    total_volume: 2904960,
    high_24h: 0.684728,
    low_24h: 0.636491,
    price_change_24h: -0.03894741403428892,
    price_change_percentage_24h: -5.76152,
    market_cap_change_24h: -2203364.3523669615,
    market_cap_change_percentage_24h: -5.47992,
    circulating_supply: 59500000,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 9.99,
    ath_change_percentage: -93.40931,
    ath_date: "2020-09-15T11:44:52.153Z",
    atl: 0.215285,
    atl_change_percentage: 205.95637,
    atl_date: "2022-05-12T07:20:45.886Z",
    roi: null,
    last_updated: "2023-06-28T12:06:31.929Z",
  },
  xprt: {
    id: "persistence",
    symbol: "xprt",
    name: "Persistence",
    image:
      "https://assets.coingecko.com/coins/images/14582/large/512_Light.png?1617149658",
    current_price: 0.22405,
    market_cap: 37982331,
    market_cap_rank: 477,
    fully_diluted_valuation: 90359211,
    total_volume: 428741,
    high_24h: 0.244291,
    low_24h: 0.22407,
    price_change_24h: -0.019745451173864476,
    price_change_percentage_24h: -8.09918,
    market_cap_change_24h: -3501567.181023337,
    market_cap_change_percentage_24h: -8.44079,
    circulating_supply: 169529937.611617,
    total_supply: 179128821.128462,
    max_supply: 403308352,
    ath: 16.59,
    ath_change_percentage: -98.64911,
    ath_date: "2021-05-15T07:34:28.920Z",
    atl: 0.156763,
    atl_change_percentage: 42.9199,
    atl_date: "2023-06-15T11:44:55.276Z",
    roi: null,
    last_updated: "2023-06-28T12:06:14.229Z",
  },
  rly: {
    id: "rally-2",
    symbol: "rly",
    name: "Rally",
    image:
      "https://assets.coingecko.com/coins/images/12843/large/image.png?1611212077",
    current_price: 0.00760817,
    market_cap: 37960316,
    market_cap_rank: 478,
    fully_diluted_valuation: 114155526,
    total_volume: 336244,
    high_24h: 0.00797095,
    low_24h: 0.00760396,
    price_change_24h: -0.000350083955012442,
    price_change_percentage_24h: -4.399,
    market_cap_change_24h: -1636714.693826422,
    market_cap_change_percentage_24h: -4.13343,
    circulating_supply: 4987973483.057468,
    total_supply: 15000000000,
    max_supply: 15000000000,
    ath: 1.4,
    ath_change_percentage: -99.45469,
    ath_date: "2021-03-31T19:48:43.422Z",
    atl: 0.00722334,
    atl_change_percentage: 5.35802,
    atl_date: "2022-12-30T07:46:18.545Z",
    roi: null,
    last_updated: "2023-06-28T12:06:27.564Z",
  },
  fpis: {
    id: "frax-price-index-share",
    symbol: "fpis",
    name: "Frax Price Index Share",
    image:
      "https://assets.coingecko.com/coins/images/24944/large/FPIS_icon.png?1679886817",
    current_price: 1.95,
    market_cap: 37944675,
    market_cap_rank: 479,
    fully_diluted_valuation: 195655058,
    total_volume: 40360,
    high_24h: 2.02,
    low_24h: 1.94,
    price_change_24h: -0.03608778425974157,
    price_change_percentage_24h: -1.81325,
    market_cap_change_24h: -635469.1134271994,
    market_cap_change_percentage_24h: -1.64714,
    circulating_supply: 19393659.2680677,
    total_supply: 100000000,
    max_supply: 100000000,
    ath: 14.2,
    ath_change_percentage: -86.21675,
    ath_date: "2022-04-13T22:47:46.205Z",
    atl: 0.793487,
    atl_change_percentage: 146.57611,
    atl_date: "2022-11-14T14:32:33.144Z",
    roi: null,
    last_updated: "2023-06-28T12:06:28.325Z",
  },
  sb: {
    id: "snowbank",
    symbol: "sb",
    name: "Snowbank",
    image:
      "https://assets.coingecko.com/coins/images/19947/large/sb.png?1636337110",
    current_price: 237.43,
    market_cap: 37903515,
    market_cap_rank: 480,
    fully_diluted_valuation: 37903515,
    total_volume: 69.3,
    high_24h: 244.12,
    low_24h: 236.43,
    price_change_24h: -5.5385191313159226,
    price_change_percentage_24h: -2.27951,
    market_cap_change_24h: -884169.3454773352,
    market_cap_change_percentage_24h: -2.27951,
    circulating_supply: 159640.027327531,
    total_supply: 159640.027327531,
    max_supply: null,
    ath: 8356.8,
    ath_change_percentage: -97.15883,
    ath_date: "2021-11-08T05:30:08.317Z",
    atl: 138.86,
    atl_change_percentage: 70.98473,
    atl_date: "2022-01-23T06:18:28.046Z",
    roi: null,
    last_updated: "2023-06-28T12:01:52.331Z",
  },
  vgx: {
    id: "ethos",
    symbol: "vgx",
    name: "Voyager VGX",
    image:
      "https://assets.coingecko.com/coins/images/794/large/Voyager-vgx.png?1575693595",
    current_price: 0.127268,
    market_cap: 37519252,
    market_cap_rank: 481,
    fully_diluted_valuation: 37519252,
    total_volume: 8687148,
    high_24h: 0.137524,
    low_24h: 0.126047,
    price_change_24h: -0.005777435544709719,
    price_change_percentage_24h: -4.34244,
    market_cap_change_24h: -1258794.1123779342,
    market_cap_change_percentage_24h: -3.24615,
    circulating_supply: 293432720.057675,
    total_supply: 293432720.057675,
    max_supply: null,
    ath: 12.47,
    ath_change_percentage: -98.97499,
    ath_date: "2018-01-06T00:00:00.000Z",
    atl: 0.01689847,
    atl_change_percentage: 656.6557,
    atl_date: "2019-12-26T22:10:32.136Z",
    roi: {
      times: 3.24228310822801,
      currency: "usd",
      percentage: 324.228310822801,
    },
    last_updated: "2023-06-28T12:06:27.113Z",
  },
  vr: {
    id: "victoria-vr",
    symbol: "vr",
    name: "Victoria VR",
    image:
      "https://assets.coingecko.com/coins/images/21178/large/vr.png?1638496975",
    current_price: 0.00975948,
    market_cap: 37467831,
    market_cap_rank: 482,
    fully_diluted_valuation: 164228930,
    total_volume: 426082,
    high_24h: 0.01010838,
    low_24h: 0.00974518,
    price_change_24h: -0.00025648956330329,
    price_change_percentage_24h: -2.56081,
    market_cap_change_24h: -896858.065392606,
    market_cap_change_percentage_24h: -2.33772,
    circulating_supply: 3832817719,
    total_supply: 16800000000,
    max_supply: null,
    ath: 0.615957,
    ath_change_percentage: -98.41295,
    ath_date: "2021-12-10T18:45:35.946Z",
    atl: 0.00472369,
    atl_change_percentage: 106.94672,
    atl_date: "2023-01-03T15:02:01.730Z",
    roi: null,
    last_updated: "2023-06-28T12:06:26.396Z",
  },
  bmex: {
    id: "bitmex-token",
    symbol: "bmex",
    name: "BitMEX",
    image:
      "https://assets.coingecko.com/coins/images/21831/large/bitmex-token.jpeg?1640081706",
    current_price: 0.374316,
    market_cap: 37381201,
    market_cap_rank: 483,
    fully_diluted_valuation: 168636995,
    total_volume: 23767,
    high_24h: 0.380591,
    low_24h: 0.373555,
    price_change_24h: -0.006124304132302861,
    price_change_percentage_24h: -1.60979,
    market_cap_change_24h: -497264.93819915503,
    market_cap_change_percentage_24h: -1.31279,
    circulating_supply: 99749999.99974,
    total_supply: 450000000,
    max_supply: 450000000,
    ath: 1.29,
    ath_change_percentage: -71.02862,
    ath_date: "2022-11-12T18:21:15.957Z",
    atl: 0.364087,
    atl_change_percentage: 2.90096,
    atl_date: "2023-06-20T17:49:40.279Z",
    roi: null,
    last_updated: "2023-06-28T12:06:25.725Z",
  },
  mtrg: {
    id: "meter",
    symbol: "mtrg",
    name: "Meter Governance",
    image:
      "https://assets.coingecko.com/coins/images/11848/large/meter.png?1660274270",
    current_price: 2.16,
    market_cap: 37141481,
    market_cap_rank: 484,
    fully_diluted_valuation: 96519666,
    total_volume: 83904,
    high_24h: 2.22,
    low_24h: 2.12,
    price_change_24h: 0.02835906,
    price_change_percentage_24h: 1.33009,
    market_cap_change_24h: 513954,
    market_cap_change_percentage_24h: 1.40319,
    circulating_supply: 17190966,
    total_supply: 44674210,
    max_supply: null,
    ath: 16.47,
    ath_change_percentage: -86.64264,
    ath_date: "2021-11-02T19:05:15.636Z",
    atl: 0.212097,
    atl_change_percentage: 937.51945,
    atl_date: "2021-01-20T11:39:54.775Z",
    roi: null,
    last_updated: "2023-06-28T12:06:32.160Z",
  },
  btrfly: {
    id: "redacted",
    symbol: "btrfly",
    name: "Redacted",
    image:
      "https://assets.coingecko.com/coins/images/26745/large/redacted_v2.jpg?1660011507",
    current_price: 173.7,
    market_cap: 37046201,
    market_cap_rank: 485,
    fully_diluted_valuation: 37046201,
    total_volume: 228322,
    high_24h: 178.6,
    low_24h: 170.44,
    price_change_24h: -0.9339872911397435,
    price_change_percentage_24h: -0.53483,
    market_cap_change_24h: 192210,
    market_cap_change_percentage_24h: 0.52154,
    circulating_supply: 213000.417882557,
    total_supply: 213000.417882557,
    max_supply: null,
    ath: 431.17,
    ath_change_percentage: -59.71473,
    ath_date: "2023-02-19T19:03:46.168Z",
    atl: 44.25,
    atl_change_percentage: 292.54622,
    atl_date: "2022-10-25T01:42:01.097Z",
    roi: null,
    last_updated: "2023-06-28T12:05:07.488Z",
  },
  hc: {
    id: "hshare",
    symbol: "hc",
    name: "HyperCash",
    image:
      "https://assets.coingecko.com/coins/images/1085/large/hshare-hcash-logo.png?1547035052",
    current_price: 0.051237,
    market_cap: 36981427,
    market_cap_rank: 486,
    fully_diluted_valuation: null,
    total_volume: 21320,
    high_24h: 0.056478,
    low_24h: 0.050355,
    price_change_24h: -0.003442398570368995,
    price_change_percentage_24h: -6.29563,
    market_cap_change_24h: -179203.0485059321,
    market_cap_change_percentage_24h: -0.48224,
    circulating_supply: 0,
    total_supply: 84000000,
    max_supply: null,
    ath: 37.13,
    ath_change_percentage: -99.86168,
    ath_date: "2017-12-20T00:00:00.000Z",
    atl: 0.04567498,
    atl_change_percentage: 12.44663,
    atl_date: "2023-06-20T14:45:12.608Z",
    roi: null,
    last_updated: "2023-06-28T12:05:37.534Z",
  },
  ata: {
    id: "automata",
    symbol: "ata",
    name: "Automata",
    image:
      "https://assets.coingecko.com/coins/images/15985/large/ATA.jpg?1622535745",
    current_price: 0.092525,
    market_cap: 36955024,
    market_cap_rank: 487,
    fully_diluted_valuation: 92654886,
    total_volume: 1096946,
    high_24h: 0.099709,
    low_24h: 0.091861,
    price_change_24h: -0.006923447340672615,
    price_change_percentage_24h: -6.96182,
    market_cap_change_24h: -2721961.271386862,
    market_cap_change_percentage_24h: -6.8603,
    circulating_supply: 398845932.8214286,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 2.36,
    ath_change_percentage: -96.07862,
    ath_date: "2021-06-07T06:04:15.523Z",
    atl: 0.083078,
    atl_change_percentage: 11.52779,
    atl_date: "2023-06-12T03:06:03.495Z",
    roi: null,
    last_updated: "2023-06-28T12:06:28.798Z",
  },
  hifi: {
    id: "hifi-finance",
    symbol: "hifi",
    name: "Hifi Finance",
    image:
      "https://assets.coingecko.com/coins/images/28712/large/hft.png?1673534804",
    current_price: 0.361292,
    market_cap: 36509674,
    market_cap_rank: 488,
    fully_diluted_valuation: 42931474,
    total_volume: 19925942,
    high_24h: 0.411859,
    low_24h: 0.325819,
    price_change_24h: 0.01497398,
    price_change_percentage_24h: 4.32377,
    market_cap_change_24h: 1459882,
    market_cap_change_percentage_24h: 4.16516,
    circulating_supply: 101341517.56562,
    total_supply: 119166791.421293,
    max_supply: null,
    ath: 0.690201,
    ath_change_percentage: -47.66894,
    ath_date: "2023-04-21T07:34:50.315Z",
    atl: 0.251847,
    atl_change_percentage: 43.41597,
    atl_date: "2023-06-15T09:19:33.390Z",
    roi: null,
    last_updated: "2023-06-28T12:06:31.647Z",
  },
  aca: {
    id: "acala",
    symbol: "aca",
    name: "Acala",
    image:
      "https://assets.coingecko.com/coins/images/20634/large/upOKBONH_400x400.jpg?1647420536",
    current_price: 0.051089,
    market_cap: 36348688,
    market_cap_rank: 489,
    fully_diluted_valuation: 51181718,
    total_volume: 9428768,
    high_24h: 0.056341,
    low_24h: 0.050399,
    price_change_24h: -0.005004859322388749,
    price_change_percentage_24h: -8.92229,
    market_cap_change_24h: -3572615.1365334094,
    market_cap_change_percentage_24h: -8.94914,
    circulating_supply: 710188889,
    total_supply: 1000000000,
    max_supply: 1000000000,
    ath: 2.79,
    ath_change_percentage: -98.16687,
    ath_date: "2022-01-25T08:19:03.771Z",
    atl: 0.03101495,
    atl_change_percentage: 65.02346,
    atl_date: "2023-06-12T03:05:28.137Z",
    roi: null,
    last_updated: "2023-06-28T12:06:26.553Z",
  },
  wan: {
    id: "wanchain",
    symbol: "wan",
    name: "Wanchain",
    image:
      "https://assets.coingecko.com/coins/images/3482/large/wanchain-logo.png?1547038221",
    current_price: 0.1838,
    market_cap: 36199839,
    market_cap_rank: 490,
    fully_diluted_valuation: 38640497,
    total_volume: 1029639,
    high_24h: 0.195852,
    low_24h: 0.182219,
    price_change_24h: -0.005950778752106145,
    price_change_percentage_24h: -3.13611,
    market_cap_change_24h: -944020.5323426872,
    market_cap_change_percentage_24h: -2.54153,
    circulating_supply: 196735724.400865,
    total_supply: 210000000,
    max_supply: 210000000,
    ath: 9.84,
    ath_change_percentage: -98.12906,
    ath_date: "2018-05-04T02:19:31.924Z",
    atl: 0.076518,
    atl_change_percentage: 140.53351,
    atl_date: "2020-03-13T02:24:41.445Z",
    roi: {
      times: -0.9130757034866995,
      currency: "eth",
      percentage: -91.30757034866996,
    },
    last_updated: "2023-06-28T12:06:22.813Z",
  },
  sfund: {
    id: "seedify-fund",
    symbol: "sfund",
    name: "Seedify.fund",
    image:
      "https://assets.coingecko.com/coins/images/14614/large/Favicon_Icon.png?1632121435",
    current_price: 0.731103,
    market_cap: 36036204,
    market_cap_rank: 491,
    fully_diluted_valuation: 73102167,
    total_volume: 976254,
    high_24h: 0.779103,
    low_24h: 0.726727,
    price_change_24h: -0.038756963491416,
    price_change_percentage_24h: -5.03429,
    market_cap_change_24h: -1917292.5227945521,
    market_cap_change_percentage_24h: -5.05169,
    circulating_supply: 49295671.44,
    total_supply: 100000000,
    max_supply: null,
    ath: 16.79,
    ath_change_percentage: -95.64592,
    ath_date: "2021-11-29T02:21:05.988Z",
    atl: 0.294604,
    atl_change_percentage: 148.13731,
    atl_date: "2021-07-20T05:41:21.428Z",
    roi: null,
    last_updated: "2023-06-28T12:06:27.981Z",
  },
  loomold: {
    id: "loom-network",
    symbol: "loomold",
    name: "Loom Network (OLD)",
    image:
      "https://assets.coingecko.com/coins/images/3387/large/1_K76UVoLq-FOL7l-_Fag-Qw_2x.png?1547038040",
    current_price: 0.04304913,
    market_cap: 35948907,
    market_cap_rank: 492,
    fully_diluted_valuation: 43108622,
    total_volume: 129288,
    high_24h: 0.04633099,
    low_24h: 0.04235588,
    price_change_24h: -0.000635753309436292,
    price_change_percentage_24h: -1.45532,
    market_cap_change_24h: -476547.5248874426,
    market_cap_change_percentage_24h: -1.30828,
    circulating_supply: 833914552,
    total_supply: 1000000000,
    max_supply: null,
    ath: 0.772485,
    ath_change_percentage: -94.4174,
    ath_date: "2018-05-04T02:54:44.763Z",
    atl: 0.00786348,
    atl_change_percentage: 448.41782,
    atl_date: "2020-03-13T02:29:21.252Z",
    roi: {
      times: -0.43356412889232354,
      currency: "usd",
      percentage: -43.35641288923235,
    },
    last_updated: "2023-06-28T12:05:55.890Z",
  },
  plu: {
    id: "pluton",
    symbol: "plu",
    name: "Pluton",
    image:
      "https://assets.coingecko.com/coins/images/1241/large/pluton.png?1548331624",
    current_price: 11.34,
    market_cap: 35842061,
    market_cap_rank: 493,
    fully_diluted_valuation: 227259438,
    total_volume: 1042029,
    high_24h: 11.69,
    low_24h: 10.68,
    price_change_24h: 0.658358,
    price_change_percentage_24h: 6.16583,
    market_cap_change_24h: 1701949,
    market_cap_change_percentage_24h: 4.98519,
    circulating_supply: 3154285.81,
    total_supply: 20000000,
    max_supply: null,
    ath: 32.76,
    ath_change_percentage: -65.29392,
    ath_date: "2018-01-06T00:00:00.000Z",
    atl: 0.308461,
    atl_change_percentage: 3585.40279,
    atl_date: "2018-12-07T00:00:00.000Z",
    roi: null,
    last_updated: "2023-06-28T12:06:13.866Z",
  },
  oeth: {
    id: "origin-ether",
    symbol: "oeth",
    name: "Origin Ether",
    image:
      "https://assets.coingecko.com/coins/images/29733/large/OETH.png?1681094770",
    current_price: 1857.09,
    market_cap: 35817662,
    market_cap_rank: 494,
    fully_diluted_valuation: 35817662,
    total_volume: 74063,
    high_24h: 1907.03,
    low_24h: 1847.43,
    price_change_24h: -21.94396458483493,
    price_change_percentage_24h: -1.16783,
    market_cap_change_24h: -349913.6740996316,
    market_cap_change_percentage_24h: -0.96748,
    circulating_supply: 19267,
    total_supply: 19267,
    max_supply: 19267,
    ath: 1928.92,
    ath_change_percentage: -3.72396,
    ath_date: "2023-06-22T03:24:49.381Z",
    atl: 1625.4,
    atl_change_percentage: 14.25406,
    atl_date: "2023-06-15T09:33:43.370Z",
    roi: null,
    last_updated: "2023-06-28T12:05:06.723Z",
  },
  kwenta: {
    id: "kwenta",
    symbol: "kwenta",
    name: "Kwenta",
    image:
      "https://assets.coingecko.com/coins/images/27409/large/kwenta.png?1668768595",
    current_price: 267.91,
    market_cap: 35715179,
    market_cap_rank: 495,
    fully_diluted_valuation: 177678827,
    total_volume: 203353,
    high_24h: 300.32,
    low_24h: 266.23,
    price_change_24h: -26.966311424257185,
    price_change_percentage_24h: -9.14482,
    market_cap_change_24h: -3503889.770724423,
    market_cap_change_percentage_24h: -8.93415,
    circulating_supply: 131716.650853305,
    total_supply: 655274.883557971,
    max_supply: null,
    ath: 790.99,
    ath_change_percentage: -66.1342,
    ath_date: "2023-03-14T13:16:00.736Z",
    atl: 66.3,
    atl_change_percentage: 304.05063,
    atl_date: "2022-11-21T01:38:14.037Z",
    roi: null,
    last_updated: "2023-06-28T12:06:18.662Z",
  },
  perp: {
    id: "perpetual-protocol",
    symbol: "perp",
    name: "Perpetual Protocol",
    image:
      "https://assets.coingecko.com/coins/images/12381/large/60d18e06844a844ad75901a9_mark_only_03.png?1628674771",
    current_price: 0.487598,
    market_cap: 35674540,
    market_cap_rank: 496,
    fully_diluted_valuation: 73697714,
    total_volume: 3328954,
    high_24h: 0.519245,
    low_24h: 0.489819,
    price_change_24h: -0.015442647478649483,
    price_change_percentage_24h: -3.06986,
    market_cap_change_24h: -1038801.9027657062,
    market_cap_change_percentage_24h: -2.82949,
    circulating_supply: 72609864.24405634,
    total_supply: 150000000,
    max_supply: 150000000,
    ath: 24.4,
    ath_change_percentage: -97.93865,
    ath_date: "2021-08-30T15:06:09.177Z",
    atl: 0.31639,
    atl_change_percentage: 58.97824,
    atl_date: "2022-11-09T22:15:25.656Z",
    roi: null,
    last_updated: "2023-06-28T12:06:30.484Z",
  },
  lat: {
    id: "platon-network",
    symbol: "lat",
    name: "PlatON Network",
    image:
      "https://assets.coingecko.com/coins/images/15430/large/PlatON-Black.png?1623852513",
    current_price: 0.00897455,
    market_cap: 35224401,
    market_cap_rank: 497,
    fully_diluted_valuation: 92003365,
    total_volume: 1021657,
    high_24h: 0.00942509,
    low_24h: 0.00888403,
    price_change_24h: -0.000088745743178544,
    price_change_percentage_24h: -0.97918,
    market_cap_change_24h: -318106.2486792356,
    market_cap_change_percentage_24h: -0.895,
    circulating_supply: 3924314149.80867,
    total_supply: 10250000000,
    max_supply: null,
    ath: 1.46,
    ath_change_percentage: -99.38402,
    ath_date: "2021-05-12T04:14:27.458Z",
    atl: 0.00499957,
    atl_change_percentage: 79.53459,
    atl_date: "2022-11-09T23:54:19.337Z",
    roi: null,
    last_updated: "2023-06-28T12:06:29.901Z",
  },
  key: {
    id: "selfkey",
    symbol: "key",
    name: "SelfKey",
    image:
      "https://assets.coingecko.com/coins/images/2034/large/selfkey.png?1548608934",
    current_price: 0.00585486,
    market_cap: 35198925,
    market_cap_rank: 498,
    fully_diluted_valuation: 35198925,
    total_volume: 5998062,
    high_24h: 0.00632173,
    low_24h: 0.0058349,
    price_change_24h: -0.000464169799512772,
    price_change_percentage_24h: -7.34559,
    market_cap_change_24h: -2436304.778294049,
    market_cap_change_percentage_24h: -6.47347,
    circulating_supply: 5999999954,
    total_supply: 5999999954,
    max_supply: null,
    ath: 0.04331005,
    ath_change_percentage: -86.45461,
    ath_date: "2018-06-30T11:41:14.603Z",
    atl: 0.00054507,
    atl_change_percentage: 976.2855,
    atl_date: "2020-04-16T00:34:00.710Z",
    roi: {
      times: -0.6096762481255047,
      currency: "usd",
      percentage: -60.96762481255047,
    },
    last_updated: "2023-06-28T12:06:31.882Z",
  },
  seth: {
    id: "seth",
    symbol: "seth",
    name: "sETH",
    image:
      "https://assets.coingecko.com/coins/images/8843/large/sETH.png?1616150207",
    current_price: 1854.7,
    market_cap: 35127687,
    market_cap_rank: 499,
    fully_diluted_valuation: 35127687,
    total_volume: 34625,
    high_24h: 1903.26,
    low_24h: 1842.97,
    price_change_24h: -21.202508228504712,
    price_change_percentage_24h: -1.13026,
    market_cap_change_24h: -282273.4391296953,
    market_cap_change_percentage_24h: -0.79716,
    circulating_supply: 18903.6984580492,
    total_supply: 18903.6984580492,
    max_supply: 18903.6984580492,
    ath: 4876.35,
    ath_change_percentage: -61.88417,
    ath_date: "2021-11-10T14:26:23.071Z",
    atl: 0.01254476,
    atl_change_percentage: 14816149.99057,
    atl_date: "2020-04-01T00:44:33.804Z",
    roi: null,
    last_updated: "2023-06-28T12:06:18.832Z",
  },
  gas: {
    id: "gas",
    symbol: "gas",
    name: "Gas",
    image:
      "https://assets.coingecko.com/coins/images/858/large/GAS_512_512.png?1594358948",
    current_price: 2.52,
    market_cap: 35113212,
    market_cap_rank: 500,
    fully_diluted_valuation: 251976453,
    total_volume: 3721092,
    high_24h: 2.66,
    low_24h: 2.51,
    price_change_24h: -0.05099339537219816,
    price_change_percentage_24h: -1.98365,
    market_cap_change_24h: -673796.305205591,
    market_cap_change_percentage_24h: -1.8828,
    circulating_supply: 13935116.23912223,
    total_supply: 100000000,
    max_supply: null,
    ath: 91.94,
    ath_change_percentage: -97.25934,
    ath_date: "2018-01-15T00:00:00.000Z",
    atl: 0.621309,
    atl_change_percentage: 305.55767,
    atl_date: "2020-03-13T02:24:16.507Z",
    roi: null,
    last_updated: "2023-06-28T12:06:19.704Z",
  },
  dafi: {
    id: "dafi-protocol",
    symbol: "dafi",
    name: "DAFI",
    image:
      "https://assets.coingecko.com/coins/images/14428/small/Dafi_Black_Icon.png?1616040406",
    current_price: 0.00897455,
    market_cap: 35224401,
    market_cap_rank: 497,
    fully_diluted_valuation: 92003365,
    total_volume: 1021657,
    high_24h: 0.00942509,
    low_24h: 0.00888403,
    price_change_24h: -0.000088745743178544,
    price_change_percentage_24h: -0.97918,
    market_cap_change_24h: -318106.2486792356,
    market_cap_change_percentage_24h: -0.895,
    circulating_supply: 3924314149.80867,
    total_supply: 10250000000,
    max_supply: null,
    ath: 1.46,
    ath_change_percentage: -99.38402,
    ath_date: "2021-05-12T04:14:27.458Z",
    atl: 0.00499957,
    atl_change_percentage: 79.53459,
    atl_date: "2022-11-09T23:54:19.337Z",
    roi: null,
    last_updated: "2023-06-28T12:06:29.901Z",
  },
  mnt: {
    id: "mnt",
    symbol: "mnt",
    name: "MNT",
    image:
      "https://pbs.twimg.com/profile_images/1597775748580134914/bLhE1aY1_400x400.jpg",
    current_price: 0.00897455,
    market_cap: 35224401,
    market_cap_rank: 497,
    fully_diluted_valuation: 92003365,
    total_volume: 1021657,
    high_24h: 0.00942509,
    low_24h: 0.00888403,
    price_change_24h: -0.000088745743178544,
    price_change_percentage_24h: -0.97918,
    market_cap_change_24h: -318106.2486792356,
    market_cap_change_percentage_24h: -0.895,
    circulating_supply: 3924314149.80867,
    total_supply: 10250000000,
    max_supply: null,
    ath: 1.46,
    ath_change_percentage: -99.38402,
    ath_date: "2021-05-12T04:14:27.458Z",
    atl: 0.00499957,
    atl_change_percentage: 79.53459,
    atl_date: "2022-11-09T23:54:19.337Z",
    roi: null,
    last_updated: "2023-06-28T12:06:29.901Z",
  },
};
