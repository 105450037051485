import React, { useState } from "react";

import ConfirmDrawPatternSVG from "assets/ConfirmDrawPatternSVG.svg";

import SuccessLogo from "assets/success.svg";

import "../../pages/auth/TransactionFlow/TransactionSuccess/styles.css";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Button from "components/NewButton";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch, useAppSelector } from "store/store";
import { setIsAccountCreated, setUserSpendingDetails } from "@slices/appSlice";
import PatternLock from "react-pattern-lock/lib/components/PatternLock";
import { decryptMessage, encryptMessage, showAlert } from "utils/utils";
import Web3 from "web3";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { BASE_URL } from "constants/index";
import axios from "axios";

const ConfirmDrawPattern = () => {
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState<number[]>([]);
  const [isPatternMatched, setIsPatternMatched] = useState(false);

  const { userSpendingDetails, rootAccountInfo, activeNetwork } =
    useAppSelector((state) => state.app);
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigateForward = async () => {
    try {
      const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
      const web3 = new Web3(
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS].rpc
      );
      const hashedPath = encryptMessage(
        JSON.stringify(userSpendingDetails.path),
        hashedPassword
      );
      const signature = web3.eth.accounts.sign(
        `${userSpendingDetails.spendinglimit}${hashedPath}`,
        pkey
      );
      console.log(
        "🚀 ~ file: ConfirmDrawPattern.tsx:44 ~ handleNavigateForward ~ signature:",
        signature
      );
      const { data } = await axios.post(
        BASE_URL + "/security-question/setSpendingLimitAndPattern",
        {
          address: rootAccountInfo.address,
          signature: signature.signature,
          pattern: hashedPath,
          spendingLimit: userSpendingDetails.spendinglimit,
        }
      );
      if (data) {
        dispatch(
          setUserSpendingDetails({
            path: [],
            isPatternSet: true,
          })
        );
      }
      navigate("/successfully-enabled");
    } catch (error) {
      // @ts-ignore
      showAlert(error?.message);
    }
  };

  const handleNavigateBack = () => {
    navigate("/draw-pattern");
  };

  return (
    <div>
      <div
        className="transaction-box"
        style={{
          marginTop: "10px",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            margin: "20px 0px 20px 0px",
            fontWeight: "700",
            textAlign: "center",
            fontSize: "28px",
          }}
        >
          Confirm Security Pattern
        </Typography>

        <PatternLock
          width={300}
          pointSize={25}
          size={3}
          path={path}
          disabled={false}
          error={false}
          success={true}
          onChange={(pattern) => {
            console.log(
              "🚀 ~ file: DrawPattern.tsx:58 ~ DrawPattern ~ pattern:",
              pattern
            );

            setPath(pattern);
            // this.setState({ path : pattern });
          }}
          onFinish={() => {
            // check if the pattern is correct
            let areArraysEqual =
              path.length === userSpendingDetails.path.length &&
              path.every(
                (value, index) => value === userSpendingDetails.path[index]
              );

            if (!areArraysEqual) {
              showAlert("Pattern doesn't matched with the previous one");

              setPath([]);
            }
            setIsPatternMatched(areArraysEqual);
          }}
        />
        <div className="transaction-wrapper" style={{ top: 20 }}>
          <Typography
            variant="body2"
            sx={{
              color: "#1A1C20",
              fontWeight: "700",
              textAlign: "center",
              fontSize: "17px",
              fontFamily: "Space Grotesk",
            }}
            onClick={handleNavigateBack}
          >
            Back
          </Typography>
          <Button
            title="Continue"
            onClick={handleNavigateForward}
            loading={loading}
            disabled={!isPatternMatched}
            style={{
              margin: 0,
              width: "260px",
              padding: "15px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmDrawPattern;
