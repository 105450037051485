import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { ChangeEvent, useState } from 'react'
import debounce from 'lodash/debounce'
import InviteInput from './InviteInput'
import ReferredFriendList from './ReferredFriendList'
import { useAppSelector } from '../../../../store/store'

const ReferredFriends = () => {
  const [text, setText] = useState('')
  const [addContact, setAddContact] = useState(false)
  const [debouncedText, setDebouncedText] = useState<string>('')
  const { ownReferralCode, referredTo, activeAccount, rootAccountInfo } =
    useAppSelector(state => state.app)
  const handleAddContact = () => {
    setAddContact(!addContact)
  }

  // const debouncedTextChange = debounce((value: string) => {
  //   // Replace this with your actual API call
  //   console.log(`Calling API with debounced value: ${value}`);
  //   setDebouncedText(value);
  //   value;
  // }, 500);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setText(newValue)

    // Debounce the API call
    debouncedApiCall(newValue)
  }

  const debouncedApiCall = debounce((value: string) => {
    // Replace this with your actual API call
    console.log(`Calling API with debounced value: ${value}`)
    setDebouncedText(value)
  }, 500)

  const referredListArray = referredTo.length > 0 ? referredTo : []
  const ReferredLists = () => {
    return (
      <Box display='flex' flexDirection='column' gap={3} py={2}>
        {referredListArray
          .filter((item: any) =>
            item?.username.toLowerCase()?.includes(text.toLowerCase())
          )
          .map((item: any) => (
            <ReferredFriendList name={item.username} address={item.address} />
          ))}
      </Box>
    )
  }
  return (
    <>
      <Box display='flex' flexDirection='column' gap={3}>
        <Box>
          <Typography variant='h5' fontWeight='600'>
            Referred Friends
          </Typography>
        </Box>
        <Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} alignItems={'center'}>
                <InviteInput handleOnChange={handleInputChange} value={text} />
              </Grid>

              {/* <Grid
                item
                md={3}
                lg={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  justifyContent: "space-between",
                  paddingLeft: "12px",
                }}
              >
                <div
                  onClick={(e: any) => {
                    setAddContact(!addContact);
                  }}
                  style={{
                    // padding: "10px 10px",
                    height: "100%",
                    width: "100%",
                    borderRadius: "90px",
                    backgroundColor: "rgba(26, 28, 32, 1)",
                    cursor: "pointer",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant={"h5"}
                    fontWeight={500}
                    color="white"
                    fontSize={12}
                    fontFamily={"Helvetica Neue"}
                    whiteSpace={"nowrap"}
                    letterSpacing={2}
                  >
                    {addContact ? "Contact List" : "+ ADD"}
                  </Typography>
                </div>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        {addContact ? (
          <Box py={3}>
            <div
              onClick={handleAddContact}
              style={{
                // padding: "10px 10px",
                height: '100%',
                width: '100%',
                borderRadius: '10px',
                padding: '20px',
                backgroundColor: 'rgba(26, 28, 32, 1)',
                cursor: 'pointer',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant={'h5'}
                fontWeight={500}
                color='white'
                fontSize={12}
                fontFamily={'Helvetica Neue'}
                whiteSpace={'nowrap'}
                letterSpacing={2}
              >
                Add Contact
              </Typography>
            </div>
          </Box>
        ) : (
          <Box>
            <Box sx={{ color: '#8C8D8F' }}>Friends</Box>
            <Box display='flex' flexDirection='column' gap={3} py={2}>
              {referredListArray.length > 0 ? (
                ReferredLists()
              ) : (
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent={'center'}
                  alignContent={'center'}
                  alignItems={'center'}
                  gap={3}
                  py={2}
                >
                  <Typography
                    variant={'h5'}
                    fontWeight={500}
                    fontSize={12}
                    fontFamily={'Helvetica Neue'}
                    whiteSpace={'nowrap'}
                    letterSpacing={2}
                  >
                    No Referred User Found
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default ReferredFriends
