import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useState } from "react";

import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import NoDataUI from "components/NoDataCard";
import { initialPendingTxValues } from "@slices/appSlice";
import { useAppSelector } from "store/store";
import NavigatorHeading from "components/NavigatorHeading";
import SearchInput from "components/SearchInput";
import CenterItem from "components/CenterItem";
import TransactionList from "./TransactionList";

const CashTransactionsView = () => {
  const [searchText, setSearchText] = useState("");
  const { activeAccount, portfolio, cashTransactions } = useAppSelector(
    (state) => state.app
  );

  const { walletLoading } = useAppSelector((state) => state.wallet);

  let allTransactions = cashTransactions?.length > 0 ? cashTransactions : [];
  console.log(
    "🚀 ~ file: View.tsx:21 ~ CashTransactionsView ~ allTransactions:",
    allTransactions
  );

  // Todo: remove tx.transactionMethod == "Send", this is only for test transaction

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function groupTimestampData(data: []) {
    const groupedData: any = {};
    const today = new Date();
    const yesterday = new Date();

    let tempData = data;
    tempData.forEach(
      (item: typeof initialPendingTxValues & { timestamp: string }) => {
        const timestamp = new Date(item.timestamp);
        const dateKey =
          timestamp.getDate() + " " + monthNames[timestamp.getMonth()] + " ";

        if (timestamp.toDateString() === today.toDateString()) {
          if (!groupedData["Today"]) {
            groupedData["Today"] = [];
          }
          groupedData["Today"].push(item);
        } else if (timestamp.toDateString() === yesterday.toDateString()) {
          if (!groupedData["Yesterday"]) {
            groupedData["Yesterday"] = [];
          }
          groupedData["Yesterday"].push(item);
        } else {
          if (!groupedData[dateKey]) {
            groupedData[dateKey] = [];
          }
          groupedData[dateKey].push(item);
        }

        // groupedData[dateKey].push(item);
      }
    );

    return groupedData;
  }
  // const [todayGroup, yesterdayGroup, earlierGroup] =
  //   groupTimestamps(allCryptoTxs);
  const groupedByDateArray = groupTimestampData(allTransactions);
  console.log(
    "file: View.tsx:47  CryptoTransactionsView  todayGroup, yesterdayGroup, earlierGroup:",
    groupedByDateArray
  );

  return walletLoading ? (
    <div style={{ width: "100%", textAlign: "center", margin: "25% 0px" }}>
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <Grid paddingY={5}>
      <NavigatorHeading
        title={"Transactions"}
        RightComponent={
          <Grid
            container
            spacing={1}
            width={{
              xs: "300px",
              sm: "400px",
              md: "500px",
              lg: "500px",
              xl: "500px",
            }}
          >
            <Grid item sm={11} md={11} lg={11} alignItems={"center"}>
              <SearchInput
                handleOnChange={(e: any) => {
                  setSearchText(e.target.value);
                }}
                padding="0px 20px"
                placeholder="Search Transactions"
              />
            </Grid>

            <Grid
              item
              sm={1}
              md={1}
              lg={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "end",
                paddingLeft: "12px",
              }}
            >
              <CenterItem>
                <img src={SearchOptionsIcon} />
              </CenterItem>
            </Grid>
          </Grid>
        }
      />
      <Grid py={2} display={"flex"} gap={1} flexDirection={"column"}>
        {/* <Grid container py={1}>
          <Box sx={{ color: "#8C8D8F" }}>Transactions</Box>
        </Grid> */}
        {allTransactions?.length > 0 && (
          <>
            {Object.keys(groupedByDateArray).map((key) => (
              <>
                {groupedByDateArray[key].filter((item: any) =>
                  item.asset.name
                    .toLowerCase()
                    .includes(searchText.toLocaleLowerCase())
                )?.length > 0 && (
                  <>
                    <Typography
                      variant="h5"
                      py={2}
                      fontWeight={400}
                      sx={{ color: "#1A1C20BF" }}
                    >
                      {key}
                    </Typography>
                    {groupedByDateArray[key]
                      .filter((item: any) =>
                        item.asset.name
                          .toLowerCase()
                          .includes(searchText.toLocaleLowerCase())
                      )
                      .map((item: any, index: number) => {
                        return <TransactionList props={{ tx: item }} />;
                      })}
                  </>
                )}
              </>
            ))}
          </>
        )}
        {allTransactions?.length === 0 && (
          <Box
            py={15}
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoDataUI
              title="No Activity Yet!"
              description="You transaction history will be displayed here"
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CashTransactionsView;
