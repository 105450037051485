import { Box, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import React, { FC, useState } from "react";

import "./index.css";
import { useAppSelector } from "store/store";
import BasicModal from "components/BasicModal";
import CopyIcon from "assets/copyIcon.svg";
import rightArrow from "assets/rightArrow.svg";
import QRCode from "react-qr-code";
import QRLogo from "assets/logo-qr.svg";
import CardLayout from "components/CardLayout";
import { ItemWrapper, MediumTitle } from "components/Styled";
import { SUPPORTED_NETWORKS } from "constants/chains";

const AccountDetailsBox: FC<{
  title: string;
  isCashTab?: boolean;
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  isShowWithOutTitle: boolean;
}> = ({
  title,
  isCashTab = false,
  open: modalOpen,
  handleClose,
  handleOpen,
  isShowWithOutTitle = false,
}) => {
  const {
    rootAccountInfo: { address },
    activeNetwork,
    activeAccount,
  } = useAppSelector((state) => state.app);
  const { name, icon } = SUPPORTED_NETWORKS[activeNetwork];
  const currentChains = [1, 137, 80001];

  const [showSnackbar, setShowSnackbar] = useState({
    state: false,
    message: "",
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedChain, setSelectedChain] = useState({
    name,
    icon,
  });
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onCopy = (text: string, message: string) => {
    setShowSnackbar({ state: true, message });
    navigator.clipboard.writeText(text);
  };

  const handleSelectChain = (chain: number) => {
    const { name, icon } =
      SUPPORTED_NETWORKS[chain as keyof typeof SUPPORTED_NETWORKS];
    setSelectedChain({
      name,
      icon,
    });
    handleMenuClose();
  };

  return (
    <>
      {/* Account Selection Modal */}
      <Snackbar
        open={showSnackbar.state}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar({ state: false, message: "" })}
        message={showSnackbar.message}
      />
      <BasicModal open={modalOpen} onClose={handleClose}>
        <>
          <Box>
            <Box>
              <Typography
                variant={"h5"}
                fontWeight={700}
                color="rgba(26, 28, 32, 1)"
                fontSize={34}
              >
                {title}
              </Typography>
            </Box>
            {!isCashTab && (
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  fontSize={13}
                  fontWeight={600}
                  color="#1A1C20"
                  style={{ marginTop: 6 }}
                >
                  {isCashTab ? address : activeAccount.smartAccountAddress}
                </Typography>

                <img
                  src={CopyIcon}
                  alt="copy icon"
                  onClick={() =>
                    onCopy(
                      isCashTab ? address : activeAccount.smartAccountAddress,
                      "Address Copied"
                    )
                  }
                  style={{ cursor: "pointer", marginLeft: 5, height: 18.5 }}
                />
              </Box>
            )}
          </Box>
          <Box
            mt={2}
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardLayout backgroundColor="#fff">
              <Box position="relative">
                <Box marginX={"18px"} marginY={"8px"}>
                  <QRCode
                    value={
                      isCashTab ? address : activeAccount.smartAccountAddress
                    }
                    size={282}
                  />
                </Box>
                <img src={QRLogo} className="qr-img" />
              </Box>
            </CardLayout>
          </Box>
          {/* <ItemWrapper
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={3}
            sx={{ cursor: "pointer" }}
            onClick={handleClick}
          >
            <Box display={"flex"} alignItems={"center"}>
              <img
                alt="ActiveChainLogo"
                src={selectedChain.icon}
                width={"37px"}
                height={"37px"}
                style={{ borderRadius: "50%" }}
              />
              <MediumTitle marginLeft={"5px"}>
                {selectedChain.name} Network
              </MediumTitle>
            </Box>
            <img
              alt="rightArrow"
              src={rightArrow}
              width={"20px"}
              height={"20px"}
            />
          </ItemWrapper> */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                width: "390px",
                padding: "0px",
              },
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            {currentChains.map((chain, key) => {
              return (
                <MenuItem key={key} onClick={() => handleSelectChain(chain)}>
                  <ItemWrapper
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <img
                        alt="ActiveChainLogo"
                        src={
                          SUPPORTED_NETWORKS[
                            chain as keyof typeof SUPPORTED_NETWORKS
                          ].icon
                        }
                        width={"37px"}
                        height={"37px"}
                        style={{ borderRadius: "50%" }}
                      />
                      <MediumTitle marginLeft={"5px"}>
                        {
                          SUPPORTED_NETWORKS[
                            chain as keyof typeof SUPPORTED_NETWORKS
                          ].name
                        }{" "}
                        Network
                      </MediumTitle>
                    </Box>
                  </ItemWrapper>
                </MenuItem>
              );
            })}
          </Menu>
          <Box
            mt={2.5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"body2"}
              fontWeight={400}
              color="rgba(26, 28, 32, 0.50)"
              fontSize={13}
              fontFamily="Helvetica Neue"
              mr={0.5}
            >
              {"Get paid with"}
            </Typography>{" "}
            <Typography
              variant={"body2"}
              fontWeight={500}
              color="#1A1C20"
              fontSize={13}
            >
              {`joinstashed.me/${title.substring(1)}`}
            </Typography>
            <img
              src={CopyIcon}
              alt="copy icon"
              onClick={() =>
                onCopy(`joinstashed.me/${title.substring(1)}`, "URL Copied")
              }
              style={{
                cursor: "pointer",
                marginLeft: 3,
                height: 12.5,
                marginTop: -2,
              }}
            />
          </Box>
        </>
      </BasicModal>

      {!isShowWithOutTitle && (
        <Typography
          variant={"h5"}
          fontWeight={700}
          color="rgba(26, 28, 32, 1)"
          fontSize={20}
          style={{ cursor: "pointer" }}
          onClick={handleOpen}
        >
          {title}
        </Typography>
      )}
    </>
  );
};

export default AccountDetailsBox;
