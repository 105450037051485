import { Box } from "@mui/system";
import React, { FC } from "react";

const TxTypeSwitch: FC<{
  loading: boolean;
  onTop20Select: any;
  onDepositSelect: any;
  txByDeposited: boolean;
}> = ({ loading, onTop20Select, onDepositSelect, txByDeposited }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={2}
    >
      <Box sx={{ color: "#8C8D8F", textAlign: "center" }}>PAY FEES WITH</Box>

      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={2}
        sx={{
          border: "0px",
          background: "#F7F7F7",
          width: "fit-content",
          justifyContent: "center",
          borderRadius: "10px",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            // Active styling
            padding: "10px",
            borderRadius: "10px",
            cursor: "pointer",
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? "none" : "all",
            backgroundColor: !txByDeposited ? "#1A1C20" : "",
            color: !txByDeposited ? "white" : "",
          }}
          onClick={() => {
            onTop20Select();
            // if (loading) {
            //   return;
            // }
            // setTxByDeposited(false);
            // setFinalOpState(null);
            // SetTokenForPayment("");
            // setSelectedDepositTokenAddress("");
          }}
        >
          Using Wallet Token
        </Box>
        <Box
          sx={{
            // Active styling
            padding: "10px",
            borderRadius: "10px",
            cursor: "pointer",
            backgroundColor: txByDeposited ? "#1A1C20" : "",
            color: txByDeposited ? "white" : "",
            pointerEvents: loading ? "none" : "all",
          }}
          onClick={() => {
            onDepositSelect();
            // if (loading) {
            //   return;
            // }
            // setTxByDeposited(true);
            // setFinalOpState(null);
            // SetTokenForPayment("");
            // setSelectedDepositTokenAddress("");
          }}
        >
          Using Topped up gas
        </Box>
      </Box>
    </Box>
  );
};

export default TxTypeSwitch;
