import axios from "axios";
import { AlphaRouter, SwapType } from "@uniswap/smart-order-router";
import { Percent, Token, TradeType } from "@uniswap/sdk-core";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { assert } from "console";

import {
  convertExponent,
  decryptMessage,
  initalizeWeb3,
  showAlert,
} from "./utils";
import {
  SupportedChainId,
  SUPPORTED_NETWORKS,
  MAX_SWAP,
} from "constants/chains";
import {
  APPROVE_AMOUNT,
  BASE_URL,
  NATIVE_ADDRESS,
  contractAddresses,
} from "constants/index";

import { fetchGasPriceForNativeMaxSwap } from "./gas";
import {
  BigNumberish,
  BytesLike,
  Contract,
  Wallet,
  ethers,
  providers,
  utils,
} from "ethers";
import { UniswapV2Router02ABI } from "abis/uniswapRouterAbi";
import {
  bridgeData,
  transaction,
  transactionGasLimit,
  transactionOptsCalldata,
} from "interfaces";

import { getSignatureObj } from "./transaction";

import { txSubmissionOrderPrepaid } from "../contract-integration/prepaidGas";
import { ExecuteCall } from "../contract-integration/constants";
import { txSubmissionOrderPostpaid } from "../contract-integration/postpaidGas";

const { abi } = require("abis/erc20abi.json");

const apiAccessList = [
  process.env.API_ACCESS_1INCH_1,
  process.env.API_ACCESS_1INCH_2,
  process.env.API_ACCESS_1INCH_3,
];

async function hitApiWithFallback(url: string) {
  console.log("apiAccessList", apiAccessList);
  for (const apiKey of apiAccessList) {
    try {
      const { data } = await axios.get(url, {
        headers: { Authorization: `Bearer ${apiKey}` },
      });

      return data; // Return the data if the request is successful
    } catch (error) {
      // If the request fails, continue to the next API key
      console.error(`API request with key ${apiKey} failed: ${error.message}`);
    }
  }

  throw new Error("All API keys failed"); // Throw an error if all keys fail
}

export const fetchSwapTokens = async (network: SupportedChainId) => {
  try {
    const url =
      SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS]
        .swap1InchApiurl;
    const data = await hitApiWithFallback(`${url}/tokens`);
    //   await axios.get(`${url}/tokens`, {
    //   headers: { Authorization: "Bearer " + process.env.API_ACCESS_1INCH_1 },
    // });

    const tokens = [];

    for (let token in data.tokens) {
      tokens.push({
        tokenName: data.tokens[token].name,
        tokenSymbol: data.tokens[token].symbol,
        tokenAddress: data.tokens[token].address,
        tokenDecimal: data.tokens[token].decimals,
        image: data.tokens[token].logoURI,
      });
    }

    return tokens;
  } catch (err) {
    console.log("err in fetching tokens", err);
    return [];
  }
};

export const fetchRiskScore = async (
  fromAddress: string,
  toAddress: string
) => {
  try {
    const { data } = await axios.post(
      `https://app.steloapi.com/api/v0/transaction?apiKey=${process.env.STELLO_API_KEY}`,
      {
        from: fromAddress,
        to: toAddress,
      }
    );

    if (data?.risk && data?.risk?.riskScore) {
      return data?.risk?.riskScore;
    } else {
      return "LOW";
    }
  } catch (error) {
    console.log("error in stello", error);
  }
};

export const fetchSwapQuote = async (
  network: SupportedChainId,
  fromTokenAddress: string,
  toTokenAddress: string,
  amount: string
) => {
  console.log("fetch quote start");
  try {
    const url =
      SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS]
        .swap1InchApiurl;
    const data = await hitApiWithFallback(
      `${url}/quote?src=${fromTokenAddress}&dst=${toTokenAddress}&amount=${convertExponent(
        Number(amount)
      )}&fee=0.03&includeTokensInfo=true&includeGas=true`
    );
    //   await axios.get(
    //   `${url}/quote?fromTokenAddress=${fromTokenAddress}&toTokenAddress=${toTokenAddress}&amount=${convertExponent(
    //     Number(amount)
    //   )}&fee=0.03`,
    //   { headers: { Authorization: "Bearer " + process.env.API_ACCESS_1INCH_1 } }
    // );

    return data;
  } catch (err) {
    console.log("error in fetching quote", err);
    throw err;
  }
};

export const fetchSwapQuoteData = async (
  network: SupportedChainId,
  fromTokenAddress: string,
  toTokenAddress: string,
  amount: string,
  fromAddress: string,
  slippage: string,
  gasLimit: number,
  gasPrice: number
) => {
  console.log("just before", convertExponent(Number(amount)));
  const url =
    SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS]
      .swap1InchApiurl;
  const data = await hitApiWithFallback(
    `${url}/swap?src=${fromTokenAddress}&dst=${toTokenAddress}&amount=${convertExponent(
      parseInt(amount)
    )}&from=${fromAddress}&slippage=${slippage}&referrerAddress=0x06423616daaAeb5296Eda650e6a4E72d322f199c&fee=0.03${`${
      !MAX_SWAP.includes(network)
        ? `&gasLimit=${gasLimit}&gasPrice=${gasPrice}`
        : ""
    }`} `
  );
  //   await axios.get(
  //   `${url}/swap?fromTokenAddress=${fromTokenAddress}&toTokenAddress=${toTokenAddress}&amount=${convertExponent(
  //     parseInt(amount)
  //   )}&fromAddress=${fromAddress}&slippage=${slippage}&referrerAddress=0x06423616daaAeb5296Eda650e6a4E72d322f199c&fee=0.03${`${
  //     !MAX_SWAP.includes(network)
  //       ? `&gasLimit=${gasLimit}&gasPrice=${gasPrice}`
  //       : ""
  //   }`} `,
  //   { headers: { Authorization: "Bearer " + process.env.API_ACCESS_1INCH_1 } }
  // );

  return data;
};

const getSpenderAddress = async (network: SupportedChainId) => {
  try {
    const url =
      SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS]
        .swap1InchApiurl;
    const data = await hitApiWithFallback(`${url}/approve/spender`);

    //   await axios.get(`${url}/approve/spender`, {
    //   headers: { Authorization: "Bearer " + process.env.API_ACCESS_1INCH_1 },
    // });
    return data.address;
  } catch (err) {
    console.log("error ", err);
  }
};

export const checkAllowance = async (
  address: string,
  tokenAddress: string,
  network: SupportedChainId
) => {
  try {
    const web3 = initalizeWeb3(network);
    const getSpenderContractAddress = await getSpenderAddress(network);

    const contract = new web3.eth.Contract(abi, tokenAddress);
    const allowance = await contract.methods
      .allowance(address, getSpenderContractAddress)
      .call();

    return allowance;
  } catch (err) {
    console.log("error ", err);
  }
};

export const giveApproval = async (
  tokenAddress: string,
  network: SupportedChainId
) => {
  const url =
    SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS]
      .swap1InchApiurl;
  const data = await hitApiWithFallback(
    `${url}/approve/transaction?tokenAddress=${tokenAddress}&amount=${APPROVE_AMOUNT}`
  );

  //   await axios.get(
  //   `${url}/approve/transaction?tokenAddress=${tokenAddress}&amount=${APPROVE_AMOUNT}`,
  //   { headers: { Authorization: "Bearer " + process.env.API_ACCESS_1INCH_1 } }
  // );

  return data;
};

export const fetchApprovalGasLimit = async (
  network: SupportedChainId,
  address: string,
  tokenAddress: string
) => {
  try {
    const web3 = initalizeWeb3(network);
    const getSpenderContractAddress = await getSpenderAddress(network);
    const contract = new web3.eth.Contract(abi, tokenAddress);
    const estimateGas = await contract.methods
      .approve(getSpenderContractAddress, APPROVE_AMOUNT)
      .estimateGas({ from: address });

    return estimateGas;
  } catch (err) {
    console.log("error ", err);
  }
};

export const countNonceAndMakeSignedTxObj = async (
  network: SupportedChainId,
  activeAccount: {
    address: string;
    secret: string;
    smartAccountAddress: string;
  },
  data: {},
  hashedPassword: string,
  decryptMnemonic: string
) => {
  const web3 = initalizeWeb3(network);
  const nonce = await web3.eth.getTransactionCount(
    activeAccount.smartAccountAddress,
    "latest"
  );

  const transactionObj = {
    ...data,
    nonce,
  };

  const secret = decryptMessage(activeAccount.secret, hashedPassword);

  const signedTx = await web3.eth.accounts.signTransaction(
    transactionObj,
    secret
  );

  return signedTx;
};

export const getQuoteForValueAndGas = async (
  swapFromToken: any,
  swapToToken: any,
  value: any,
  activeNetwork: number,
  nativeTokenPrice: number,
  callBackForGettingQuote: any
) => {
  let quote = {
    value: "0",
    // gasPrice: "0",
    // gasPriceLightning: "0",
    gasPriceForNativeMax: "0",
    estimatedGas: "0",
  };
  if (
    Number(value) &&
    Number(value) > 0 &&
    swapFromToken[0]?.tokenAddress !== swapToToken[0]?.tokenAddress
  ) {
    try {
      const { defaultSwapToToken, owlracle_gas_url } =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

      let valueToSent = value;

      const data = await fetchSwapQuote(
        activeNetwork,
        swapFromToken[0]?.tokenAddress || NATIVE_ADDRESS,
        swapToToken[0]?.tokenAddress === undefined
          ? defaultSwapToToken
          : swapToToken[0]?.tokenAddress || NATIVE_ADDRESS,
        (Number(valueToSent) * 10 ** swapFromToken[0]?.tokenDecimal)
          .toFixed()
          .toString()
      );
      console.log("data from qute api", data);
      // setToValue(
      //   (Number(data.toTokenAmount) / 10 ** data.toToken.decimals).toFixed(7)
      // );
      quote.value = (
        Number(data.toAmount) /
        10 ** data.toToken.decimals
      ).toFixed(7);

      if (nativeTokenPrice) {
        // const resp = await fetchTipAndGasPriceFromOwlracle(owlracle_gas_url);
        // console.log("{default, lightning}", resp);
        // let { average, fast } = await fetchGasPrice(activeNetwork);
        // average = average / 10 ** 9;
        // console.log("varaints", data.estimatedGas, average, nativeTokenPrice);
        // let txnFee = data.estimatedGas * 0.6 * average;
        // txnFee = txnFee / 10 ** 9;
        // txnFee = txnFee * nativeTokenPrice;
        // console.log("average in dollar", txnFee);
        // setGasPrice(txnFee);
        // quote.gasPrice = txnFee;
        quote.estimatedGas = data.gas;

        // fast = fast / 10 ** 9;
        // console.log("varaints", data.gas, fast, nativeTokenPrice);
        // let txnFeeLightning = data.gas * fast;
        // txnFeeLightning = txnFeeLightning / 10 ** 9;
        // txnFeeLightning = txnFeeLightning * nativeTokenPrice;
        // console.log("fast in dollar", txnFeeLightning);
        // setGasPrice(txnFeeLightning);
        // quote.gasPriceLightning = txnFeeLightning;

        let gasForNativeMax = await fetchGasPriceForNativeMaxSwap(
          activeNetwork
        );
        gasForNativeMax = gasForNativeMax / 10 ** 9;
        console.log(
          "varaints for native max",
          data.gas,
          gasForNativeMax,
          nativeTokenPrice
        );
        let txnFeeForNativeMax = data.gas * gasForNativeMax;
        txnFeeForNativeMax = txnFeeForNativeMax / 10 ** 9;
        txnFeeForNativeMax = txnFeeForNativeMax * nativeTokenPrice;
        console.log("gasForNativeMax in dollar", txnFeeForNativeMax);

        quote.gasPriceForNativeMax = txnFeeForNativeMax;
      }

      return quote;
    } catch (error) {
      // setToValue("0");
      console.log(error);
      callBackForGettingQuote(error);
      return quote;
      // if (
      //   error?.response?.data?.description?.includes(
      //     "Custom tokens are not allowed in public api"
      //   )
      // ) {
      //   setError({
      //     open: true,
      //     title: "Error",
      //     description: "Pool not available for this swap",
      //     onCloseClick: () => {
      //       setError({
      //         open: false,
      //         title: "Error",
      //         description: "Pool not available for this swap",
      //         onCloseClick: () => {},
      //       });
      //     },
      //   });
      // }
    }
  } else if (!Number(value)) {
    // setToValue("0");
    return quote;
  }
};

export const fetchTipAndGasPriceFromOwlracle = async (url: string) => {
  try {
    const { data } = await axios.get(
      `${url}?apikey=${process.env.OWLRACLE_API_KEY}`
    );
    console.log("data from owracle: ", data);

    const { speeds } = data;

    return {
      default: {
        maxPriorityFee: speeds[1]?.maxPriorityFeePerGas,
        maxFeePerGas: speeds[1]?.maxFeePerGas,
        currentFee: speeds[1]?.estimatedFee,
      },
      lightning: {
        maxPriorityFee: speeds[3]?.maxPriorityFeePerGas,
        maxFeePerGas: speeds[3]?.maxFeePerGas,
        currentFee: speeds[3]?.estimatedFee,
      },
    };
  } catch (e) {
    console.log("error in fetch", e);
  }
};

//----------------------------------------new code
export async function getSwapQuote({
  chainId,
  tokenInAddress,
  tokenInDecimals,
  tokenOutAddress,
  tokenOutDecimals,
  amount,
}: {
  chainId: number;
  tokenInAddress: string;
  tokenOutAddress: string;
  tokenInDecimals: number;
  tokenOutDecimals: number;
  amount: string;
}) {
  // Network provider
  const provider = new ethers.providers.JsonRpcProvider(
    SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS].rpc
  );

  // Define the Uniswap V2 Router contract
  const routerAddress = "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff"; // this address may vary, check for the exact address on your network
  const routerContract = new ethers.Contract(
    routerAddress,
    UniswapV2Router02ABI.abi,
    provider
  );

  // Tokens
  const tokenIn = tokenInAddress; // USDT address
  const tokenOut = tokenOutAddress; // WETH address  0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619
  const amountIn = ethers.utils.parseUnits(amount, tokenInDecimals); // amount of tokenIn, "1.0" for example, with 6 decimal places precision

  try {
    // Get estimated amount out
    const amounts = await routerContract.getAmountsOut(amountIn, [
      tokenIn,
      tokenOut,
    ]);
    console.log("🚀 ~ file: useApp.tsx:800 ~ getSwapQuote ~ amounts:", amounts);
    const amountOutMin = amounts[1]; // Estimated ETH received (for example)

    console.log(
      "Estimated amount of token received:",
      ethers.utils.formatUnits(amountOutMin, tokenOutDecimals)
    ); // adjust decimal places if different for your token
    return ethers.utils.formatUnits(amountOutMin, tokenOutDecimals);
  } catch (error) {
    console.error("Error getting swap quote:", error);
  }
}
export async function getEstimatedGas(
  to: string,
  data: string,
  value: string,
  wallet: Wallet
) {
  const estimatedGasCostInHex = await wallet.estimateGas({
    to: to,
    data: data,
    value: value,
  });
  return utils.formatUnits(estimatedGasCostInHex, "wei").toString();
}

export async function getSwapRouter02ApprovalTransaction(
  spender: string,
  amount: string
) {
  const _erc20Abi = new utils.Interface(abi);
  const approvalCallData = _erc20Abi.encodeFunctionData("approve", [
    spender,
    amount,
  ]);

  return approvalCallData;
}

export async function getAlphaRouterResponse(
  inputTokenAddress: string,
  inputTokenDecimals: number,
  outputTokenAddress: string,
  outputTokenDecimals: number,
  recipient: string,
  amount: string,
  tradeType: TradeType,
  swapType: SwapType,
  chainId: number
) {
  // console.log(
  //   inputTokenAddress,
  //   inputTokenDecimals,
  //   outputTokenAddress,
  //   outputTokenDecimals,
  //   recipient,
  //   amount,
  //   tradeType,
  //   swapType
  // );
  // TODO: change it to env rpc
  const provider = new providers.JsonRpcProvider(
    "https://polygon-mainnet.g.alchemy.com/v2/3yJ7GaO6CdMNZAxsM5kxR0bUPiMVLE0B"
  );
  const alphaRouter = new AlphaRouter({ chainId: chainId, provider: provider });

  const inputToken = new Token(chainId, inputTokenAddress, inputTokenDecimals);
  console.log("🚀 ~ file: swap.ts:556 ~ inputToken:", inputToken);
  const outputToken = new Token(
    chainId,
    outputTokenAddress,
    outputTokenDecimals
  );
  const inputAmountCurrency = CurrencyAmount.fromRawAmount(inputToken, amount);
  console.log(
    "🚀 ~ file: swap.ts:563 ~ inputAmountCurrency:",
    inputAmountCurrency
  );
  const response = await alphaRouter.route(
    inputAmountCurrency,
    outputToken,
    tradeType,
    {
      recipient, // address of the wallet to receive the output token
      slippageTolerance: new Percent(10, 100),
      deadline: Math.floor(Date.now() / 1000 + 31560000), // fail transaction if it can't be mined in respective time
      type: swapType, // use Uniswap V3 Router 2 to match expected calldata format
    }
  );
  console.log("🚀 ~ file: swap.ts:567 ~ response:", response);
  // console.info('got alpharouter response for profit case:', response);
  if (!response || !response.methodParameters) {
    throw new Error(
      "Uniswap alpha router could not find valid route for profit case"
    );
  }
  return response;
}

export async function AcrossExactInputCalldata(
  data: bridgeData,
  chainId: number,
  tokenInDecimals: number,
  tokenOutDecimals: number,
  tokenOutAddress: string,
  pKey: string,
  paymentToken: {
    address: string;
    decimals: number;
  },
  txByDeposited: boolean,
  firstAccountAddress: string,
  firstAccountWallet: ethers.Wallet,
  isAccountDeployed: boolean
) {
  const provider = new providers.JsonRpcProvider(
    SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS].rpc
  );

  const wallet = new Wallet(pKey as BytesLike, provider);

  const originToken = new Contract(data.originToken, abi, wallet);

  let txns: transaction[] = [];
  const ISNATIVE =
    paymentToken.address == "0x0000000000000000000000000000000000000000" ||
    paymentToken.address == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

  const payable = ISNATIVE;

  const response = await getAlphaRouterResponse(
    ISNATIVE
      ? "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270".toLowerCase()
      : data.originToken,
    tokenInDecimals,
    tokenOutAddress,
    tokenOutDecimals,
    data.recipient,
    data.amount,
    TradeType.EXACT_INPUT,
    SwapType.SWAP_ROUTER_02,
    chainId
  );

  const amountOut = response.trade.outputAmount.numerator.toString();
  console.log("🚀 ~ file: swap.ts:622 ~ amountOut:", amountOut);

  let approvalCallData = "";
  let userCallDataArray: ExecuteCall[] = [];

  if (
    !payable &&
    (await originToken.allowance(
      data.recipient,
      contractAddresses.polygon.swapRouter02
    )) < data.amount
  ) {
    approvalCallData = await getSwapRouter02ApprovalTransaction(
      contractAddresses.polygon.swapRouter02,
      data.amount
    );
    userCallDataArray.push({
      to: data.originToken,
      value: "0",
      calldata: approvalCallData,
    });
  }
  // @ts-ignore
  const callData = response.methodParameters.calldata;
  console.log("🚀 ~ file: swap.ts:821 ~ callData:", callData);

  console.log("🚀 ~ file: swap.ts:659 ~ ISNATIVE:", ISNATIVE);
  let tokenAmountToTransfer = ISNATIVE ? "1000000000" : "1000";
  let transferData: {
    tokenAddress: string;
    tokenAmount: string;
  } = {
    tokenAddress: "0x",
    tokenAmount: "0",
  };
  if (ISNATIVE) {
    transferData = {
      tokenAddress: "0x",
      tokenAmount: tokenAmountToTransfer,
    };
  } else {
    transferData = {
      tokenAddress: paymentToken.address,
      tokenAmount: tokenAmountToTransfer,
    };
  }

  userCallDataArray.push({
    to: contractAddresses.polygon.swapRouter02,
    value: payable ? data.amount : (0).toString(),
    calldata: ISNATIVE ? response.methodParameters?.calldata! : callData,
  });
  console.log("🚀 ~ file: swap.ts:673 ~ userCallDataArray:", userCallDataArray);

  const { blockaid } =
    SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS];

  const { data: data1 } = await axios.post(`${BASE_URL}/api/blockaid`, {
    options: ["simulation"],

    data: {
      from: firstAccountAddress,
      data: userCallDataArray[0].calldata,
      value: userCallDataArray[0].value,
      to: userCallDataArray[0].to,
    },
    metadata: { domain: "https://app.1inch.io" },
    network: blockaid,
  });

  console.log("DATAAAAAAAAAAa", data1);

  if (data1.simulation.error) {
    showAlert("This transaction might fail", "Warning");
  }

  let results: any = {};

  if (txByDeposited) {
    results = await txSubmissionOrderPostpaid({
      sponsorWallet: firstAccountWallet,
      currentWallet: wallet,
      sponsorAccountAddress: firstAccountAddress,
      counterfactual: data.recipient,
      userCallDataArray: userCallDataArray,
      selectedDepositTokenAddress: paymentToken.address,
      transferTokenDecimal: paymentToken.decimals,
      isHighFees: false,
      isAccountDeployed,
    });
    console.log("🚀 ~ file: swap.ts:676 ~ results:", results);
  } else {
    results = await txSubmissionOrderPrepaid({
      transferData,
      userCallDataArray,
      counterfactual: data.recipient,
      wallet,
      transferTokenDecimal: paymentToken.decimals,
      isHighFees: false,
      isAccountDeployed,
    });
  }

  console.log("🚀 ~ file: swap.ts:697 ~ results:", results);
  return results;
  // console.log("🚀 ~ file: swap.ts:554 ~ userCallDataArray:", userCallDataArray);
  // const bundlerRPC =
  //   "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37";
  // const rpcEndpoint =
  //   SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS].alchemy_url;

  // const counterfactual = await getCounterFactualAddress(
  //   EMPTY_CALLDATA,
  //   wallet.address,
  //   0,
  //   provider
  // );
  // console.log("🚀 ~ file: swap.ts:721 ~ userCallDataArray:", userCallDataArray);
  // // console.log(
  // //   "file: index.tsx:483  newSendTransaction  counterfactual:",
  // //   counterfactual
  // // );

  // const isDeployed = await provider.getCode(counterfactual);
  // // console.log(
  // //   "🚀 ~ file: swap.ts:581 ~ isDeployed:",
  // //   isDeployed,
  // //   isDeployed.toString() == EMPTY_CALLDATA
  // // );
  // let resultOp;
  // if (isDeployed.toString() == EMPTY_CALLDATA) {
  //   console.log(
  //     { chainId },
  //     wallet.address,
  //     FactoryData.FactoryCreateSender,
  //     CallDataType.Batch,
  //     userCallDataArray,
  //     transferData,
  //     bundlerRPC,
  //     rpcEndpoint
  //   );
  //   resultOp = await getPartialUserOpForVerifyingPaymaster(
  //     wallet.address,
  //     FactoryData.FactoryCreateSender,
  //     CallDataType.Batch,
  //     userCallDataArray,
  //     transferData,
  //     bundlerRPC,
  //     rpcEndpoint
  //   );
  //   // console.log("Result UserOP is deployed to equal1: ", resultOp);
  // } else {
  //   resultOp = await getPartialUserOpForVerifyingPaymaster(
  //     wallet.address,
  //     FactoryData.Empty,
  //     CallDataType.Batch,
  //     userCallDataArray,
  //     transferData,
  //     bundlerRPC,
  //     rpcEndpoint
  //   );
  //   // console.log("Result U serOP else case: ", resultOp);
  // }
  // console.log("🚀 ~ file: swap.ts:574 ~ resultOp:", resultOp, transferData);

  // const getSignedObj = await getSignatureObj({
  //   userOp: resultOp,
  //   payMasterType: PaymasterType.OffChainVerifier,
  // });
  // // console.log("🚀 ~ file: swap.ts:580 ~ getSignedObj:", getSignedObj);
  // // console.log("signerPaymasterOP", getSignedObj);

  // // The final signed paymasterOP needs to be signed by the user.
  // let signature = signUserOp(getSignedObj, wallet, EntryPoint_Address, chainId);
  // const finalOp = {
  //   ...getSignedObj,
  //   signature: signature,
  // };

  // // console.log("🚀 ~ file: swap.ts:585 ~ signature:", signature);
  // let simCallData: ExecuteCall = {
  //   to: finalOp.sender,
  //   value: "0",
  //   calldata: finalOp.callData,
  // };

  // const simulateHandleOpsSucess = await simulateHandleOps(
  //   finalOp,
  //   simCallData,
  //   provider
  // );
  // console.log(
  //   "🚀 ~ file: swap.ts:604 ~ simulateHandleOpsSucess:",
  //   simulateHandleOpsSucess
  // );

  // console.log(
  //   "file: index.tsx:549  newSendTransaction  simulateHandleOpsSucess:",
  //   simulateHandleOpsSucess
  // );
  // assert(simulateHandleOpsSucess, "HandleOps Simulation failed.");

  // const simulateValidationOpsSucess = await simulateValidation(
  //   finalOp,
  //   provider
  // );

  // console.log(
  //   "file: index.tsx:556  newSendTransaction  simulateValidationOpsSucess:",
  //   simulateValidationOpsSucess
  // );
  // assert(simulateValidationOpsSucess, "Simulation Validation failed.");
  // console.log("Final Signed Op", finalOp);
  // const response1 = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
  // console.log("response11111111111111 :", response1);
  // return response1;
  // const rawTransaction = {

  //   to: contractAddresses.polygon.swapRouter02,
  //   value: (0).toString(),
  //   data: response.methodParameters?.calldata!,
  //   gasPrice: (transactionOpts.gasPrice == 0
  //     ? CURRENT_GAS_PRICE
  //     : transactionOpts.gasPrice
  //   ).toString(),
  //   gasLimit: gasLimits.swap,
  //   nonce: (await wallet.getTransactionCount()).toString(),
  // };
  // console.log("🚀 ~ file: swap.ts:654 ~ rawTransaction:", rawTransaction);
  // const transactionResponse = await wallet.sendTransaction(rawTransaction);
  // console.log(
  //   "🚀 ~ file: swap.ts:659 ~ transactionResponse:",
  //   transactionResponse
  // );
  // txns.push(rawTransaction);

  // return { amountOut, txns };
}
