import React, { useState } from 'react'

import { Grid, Typography } from '@mui/material'
import ImageViewer from './ImageViewer'
import NavigatorHeading from '../../../../components/NavigatorHeading'
import CloseButton from '../../../../components/CloseButton'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/system'
import CollapeWrapper from './CollapeWrapper'
import SortOutlinedIcon from '@mui/icons-material/SortOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CollapseBody from './Collapse/CollapseBody'
import NFTMetaData from './NFTMetaData'
import { useAppSelector } from '../../../../store/store'
import { NFTDetailsType } from '../../../../interfaces'
import { trimTokenAddress } from 'utils/utils'
import BasicButton from 'components/Button'

const NFTDetailPage = () => {
  const [open, setOpen] = useState(false)
  const { NftColName, chainName, nftId } = useParams()
  const { activeAccount, newNFTs } = useAppSelector(state => state.app)
  const nftsByCollection: NFTDetailsType = Object.keys(newNFTs).reduce(
    (result: any[], key) => {
      const allNFTsByCollection = newNFTs[key].filter(item => {
        return item.name == NftColName && item.blockchain == chainName
      })
      result.push(...allNFTsByCollection)
      return [...result]
    },
    []
  )
  const currentNFT = nftsByCollection.find(
    item => item.token_id == nftId && item.blockchain == chainName
  )
  console.log('file: index.tsx:34  NFTDetailPage  currentNFT:', currentNFT)
  const navigate = useNavigate()
  const toggleCollapse = () => {
    setOpen(!open)
  }

  if (!currentNFT) {
    return <>No NFT found with provided details</>
  }

  return (
    <Box py={4}>
      <Grid>
        <NavigatorHeading
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate(`/nfts/${NftColName}`)
              }}
            />
          }
          title={`${currentNFT?.name} #${trimTokenAddress(
            currentNFT?.token_id
          )}`}
        />
      </Grid>
      <Grid container paddingY={4} spacing={4}>
        <Grid item lg={6} sm={12}>
          <ImageViewer
            imageUrl={
              currentNFT?.metadata?.image ??
              'https://thientu.vn/userfiles/files/nyan-cat-game%204.gif'
            }
            title='NFT IMAGE'
          />
        </Grid>
        <Grid
          item
          lg={6}
          sm={12}
          sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          <CollapeWrapper
            defaultOpenState
            LeftComponent={
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <SortOutlinedIcon sx={{ color: '#1A1C20' }} />
                <Typography variant='body2'>Description </Typography>
              </Box>
            }
            BodyComponent={
              <CollapseBody
                title={currentNFT.name}
                description={currentNFT?.metadata?.description ?? ''}
              />
            }
          />

          <CollapeWrapper
            defaultOpenState
            LeftComponent={
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <InfoOutlinedIcon sx={{ color: '#1A1C20' }} />
                <Typography variant='body2'>Details</Typography>
              </Box>
            }
            BodyComponent={
              <NFTMetaData
                props={{
                  contractAddress: currentNFT.token_address,
                  tokenId: currentNFT.token_id,
                  tokenStandard: currentNFT.contract_type,
                  chain: currentNFT.blockchain,
                  // lastUpdated:currentNFT.metadata.,
                  // creatorEarnings,
                }}
              />
            }
          />
        </Grid>
      </Grid>
      <BasicButton
        title='Send'
        onClick={() => {
          navigate(`/nfts/${NftColName}/${chainName}/${nftId}/send`)
        }}
      />
    </Box>
  )
}

export default NFTDetailPage
