import React, { useState } from "react";
import pbkdf2 from "pbkdf2";
import { Grid, Typography } from "@mui/material";
import TextInput from "components/TextInput";
import Button from "components/NewButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthHeader from "components/AuthHeader";
import { decryptMessage, showAlert } from "utils/utils";
import { useAppDispatch, useAppSelector } from "store/store";
import { setHashedPassword } from "@slices/walletSlice";
import CustomizedSteppers from "components/Stepper";
import { Box } from "@mui/system";
import { stepsCount } from "../../../../../src/constants";

const SignInWithPassword = () => {
  const [password, setPassword] = useState("");
  const [securePassword, setSecurePassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const {
    fetchedKeys: { key2 },
  } = useAppSelector((state) => state.app);

  const handleContinue = async () => {
    try {
      setLoading(true);

      const hashedPassword = pbkdf2
        .pbkdf2Sync(password, "salt", 1, 32, "sha512")
        .toString("hex");
      dispatch(setHashedPassword(hashedPassword));

      const decryptedKey = decryptMessage(key2, hashedPassword);

      if (decryptedKey) {
        setStep(2);
        navigate("/signin-with-pin", {
          state: { currentStep: step + 1, pathName: pathName },
        });
      } else {
        setLoading(false);
        showAlert("Password is incorrect");
      }
    } catch (error) {
      setLoading(false);
      showAlert("Password is incorrect");

      console.log("err", error);
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13) {
      handleContinue();
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>

      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Enter your password
        </Typography>

        <div className="welcome-box-container">
          <TextInput
            title="Password"
            value={password}
            onChange={setPassword}
            showSecure={() => setSecurePassword(!securePassword)}
            showEndIcon
            secure={securePassword}
            type={securePassword ? "password" : "text"}
            style={{
              width: "350px",
            }}
            onKeyPress={(e) => handleKeyPress(e)}
          />

          <Button
            onClick={handleContinue}
            title="Continue"
            disabled={!password}
            loading={loading}
            style={{
              width: "380px",
              padding: "15px 0px",
              marginTop: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignInWithPassword;
