import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import pbkdf2 from "pbkdf2";
import sss from "shamirs-secret-sharing";
import Button from "components/NewButton";
import OTPInput from "components/OtpInput";
import "./styles.css";
import AuthHeader from "components/AuthHeader";
import {
  createEVMAccount,
  createEVMSmartAccount,
  decryptMessage,
  encryptMessage,
  fetchAccountNativeBalance,
  getEnsFromAddress,
  showAlert,
} from "utils/utils";
import { useAppDispatch, useAppSelector } from "store/store";
import { initalizeBlockNumbers } from "utils/blocknumber";
import {
  setAccounts,
  setActiveAccount,
  setBlockNumbers,
  setEncryptedSeedPhraseWithPassword,
  setExpirationTime,
  setHoldings,
  setMode,
  setRootAccountInfo,
} from "@slices/appSlice";
import { Accounts } from "interfaces";
import { LOGIN_EXPIRY, stepsCount } from "../../../../../src/constants";
import { setHashedPin, setTorusKey } from "@slices/walletSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";

const SignInWithPin = () => {
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const {
    activeNetwork,
    fetchedKeys: { key1, key2, key3, rootAddress },
  } = useAppSelector((state) => state.app);

  const { torusKey, hashedPassword } = useAppSelector((state) => state.wallet);

  const fromStringToBuffer = (val) => Buffer.from(val, "hex");

  const fetchAndSaveData = async (
    address: string,
    privateKey: string,
    newAccount: Accounts,
    counterfactual: string
  ) => {
    dispatch(setExpirationTime(new Date().getTime() + LOGIN_EXPIRY));
    const { balance, balanceInUsd, nativeTokenPrice } =
      await fetchAccountNativeBalance(address, activeNetwork);

    dispatch(
      setHoldings({
        [counterfactual]: {
          nativeBalance: balance,
          nativeBalanceUsd: balanceInUsd,
          nativeTokenPrice,
          tokens: [],
        },
      })
    );

    dispatch(setAccounts(newAccount));
    dispatch(
      setActiveAccount({
        address,
        secret: privateKey,
        smartAccountAddress: counterfactual,
        accountName: newAccount[address]?.name,
      })
    );

    navigate("/signin-download-recovery-key", {
      state: { currentStep: step + 1, pathName: pathName },
    });
  };

  const handleContinue = async () => {
    try {
      setLoading(true);
      const ens = await getEnsFromAddress(rootAddress?.toLowerCase());

      const hashedTorus = pbkdf2
        .pbkdf2Sync(torusKey, "salt", 1, 32, "sha512")
        .toString("hex");

      const hashedPin = pbkdf2
        .pbkdf2Sync(pin, "salt", 1, 32, "sha512")
        .toString("hex");

      const decryptedKey1 = decryptMessage(key1, hashedTorus);
      const decryptedKey2 = decryptMessage(key2, hashedPassword);
      const decryptedKey3 = decryptMessage(key3, hashedPin);

      const decryptMnemonic = sss
        .combine([
          fromStringToBuffer(decryptedKey1),
          fromStringToBuffer(decryptedKey2),
          fromStringToBuffer(decryptedKey3),
        ])
        .toString();

      console.log(decryptMnemonic);

      const encryptedSeedPhraseWithPassword = encryptMessage(
        decryptMnemonic,
        hashedPassword
      );

      const blockNumbers = await initalizeBlockNumbers();

      dispatch(setBlockNumbers(blockNumbers));

      const { newAccount, address, secret, counterfactual } =
        await createEVMSmartAccount(
          decryptMnemonic,
          1,
          "",
          hashedPassword,
          activeNetwork
        );
      console.log(address);

      const { address: derivedRootAddress, secret: rootSecret } =
        await createEVMAccount(decryptMnemonic, 0, ens, hashedPassword);

      dispatch(
        setEncryptedSeedPhraseWithPassword(encryptedSeedPhraseWithPassword)
      );

      if (rootAddress.toLowerCase() === derivedRootAddress.toLowerCase()) {
        dispatch(
          setRootAccountInfo({
            address: rootAddress,
            name: ens,
            secret: rootSecret,
          })
        );
      }

      dispatch(setMode("google"));
      dispatch(setHashedPin(hashedPin));
      dispatch(setTorusKey(hashedTorus));
      await fetchAndSaveData(address, secret, newAccount, counterfactual);
    } catch (error) {
      setLoading(false);
      showAlert("Pin is incorrect");

      console.log("err", error);
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13 || key === "Tab" || keyCode === 9) {
      e.preventDefault(); // Prevent the default behavior of the Tab key
      handleContinue();
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>

      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Enter your pin
        </Typography>

        <div>
          <OTPInput otp={pin} onChange={setPin} onKeyPress={handleKeyPress} />
          <Button
            onClick={handleContinue}
            title="Continue"
            disabled={!pin}
            loading={loading}
            style={{
              padding: "15px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignInWithPin;
