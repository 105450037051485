import React, { useState } from "react";
import BasicButton from "components/Button";

import Logo from "assets/ember.svg";
import "./index.css";
import { useAppDispatch, useAppSelector } from "store/store";
import BasicModal from "components/BasicModal";
import AccountsList from "components/AccountsList";
import ModalHeader from "components/ModalHeader";
import NewAccount from "components/NewAccount";
import AccountCreated from "components/AccountCreated";
import ImportAccount from "components/ImportAccount";
import { numFormatter } from "utils/utils";
import { Box, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setActiveAccount } from "@slices/appSlice";

const AccountsBox = () => {
  const [openAccountslistModal, setOpenAccountsListModal] = useState(false);
  const [openNewAccountModal, setOpenNewAccountModal] = useState(false);
  const [openAccountCreatedModal, setAccountCreatedModal] = useState(false);
  const [openImportAccountModal, setOpenImportAccountModal] = useState(false);

  const { lastCreatedAccount } = useAppSelector((state) => state.app);

  const dispatch = useAppDispatch();

  return (
    <>
      {/* Account Selection Modal */}
      <BasicModal
        open={openAccountslistModal}
        onClose={() => setOpenAccountsListModal(false)}
      >
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <ModalHeader title="Accounts" />
            </Box>
            {/* <Box>
              <div
                className="tab-chip-box"
                onClick={() => {
                  setOpenAccountsListModal(false);
                  setOpenNewAccountModal(true);
                }}
                
              >
                <Typography
                  variant={"h5"}
                  fontWeight={500}
                  color="white"
                  fontSize={12}
                  fontFamily={"Helvetica Neue"}
                >
                  + New
                </Typography>
              </div>
            </Box> */}
          </Box>
          <AccountsList />
        </>
      </BasicModal>

      {/* Create Account Modal */}
      <BasicModal
        open={openNewAccountModal}
        onClose={() => setOpenNewAccountModal(false)}
      >
        <>
          <ModalHeader title="Create New Account" />
          <NewAccount
            openNewModal={() => setAccountCreatedModal(true)}
            closeThisModal={() => setOpenNewAccountModal(false)}
          />
        </>
      </BasicModal>

      {/* Account Finalizatrion Modal */}
      <BasicModal
        open={openAccountCreatedModal}
        onClose={() => setAccountCreatedModal(false)}
      >
        <>
          <ModalHeader title="Congratulations!" />
          <AccountCreated
            onClose={() => {
              setAccountCreatedModal(false);
              dispatch(setActiveAccount(lastCreatedAccount));
            }}
          />
        </>
      </BasicModal>

      {/* Import Account Modal */}
      <BasicModal
        open={openImportAccountModal}
        onClose={() => setOpenImportAccountModal(false)}
      >
        <>
          <ModalHeader title="Import Account" />
          <ImportAccount onClose={() => setOpenImportAccountModal(false)} />
        </>
      </BasicModal>

      <IconButton
        disableRipple={true}
        size="small"
        sx={{
          background: "rgba(26, 28, 32, 0.50);",
          ml: 0.5,
          height: "22px",
          width: "22px",
        }}
        onClick={() => setOpenAccountsListModal(true)}
      >
        <ExpandMoreIcon sx={{ color: "#fff", fontSize: "1.2rem" }} />
      </IconButton>

      {/* <div className='accounts-box'>
        <div className='header'>
          <div className='side1'>
            <p className='head-text'>Your Accounts</p>
          </div>
          <div className='side2'>
            <p className='head-text'>Balance</p>
          </div>
          <div className='side3'>
            <p className='head-text'>Account Type</p>
          </div>
          <div
            className='side4'
            onClick={() => setOpenAccountsListModal(true)}
            id='account_box_open_account_list_modal'
          >
            <p className='see-all'>See all</p>
          </div>
        </div>

        <div className='accounts'>
          {Object.keys(accounts).map(account => {
            const smartAccountAddress = accounts[account].smartAccountAddress;
            const { amount, symbol } = numFormatter(
              Number(holdings[smartAccountAddress].nativeBalanceUsd)
            );
            return (
              <div
                className={`account ${
                  smartAccountAddress == activeAccount.smartAccountAddress &&
                  "active-account"
                }`}
                key={smartAccountAddress}
              >
                <div className='account-address-image side1'>
                  <div className='account-image-box'>
                    <img className='account-image' src={Logo} height={20} />
                  </div>
                  <p
                    className='text'
                    style={{ marginLeft: "10px", marginTop: "5px" }}
                  >
                    {smartAccountAddress.slice(0, 10) +
                      "..." +
                      smartAccountAddress.slice(smartAccountAddress.length - 4)}
                  </p>
                </div>

                <div className='side2'>
                  <p className='text'>${amount + symbol}</p>
                </div>
                <div className='side3'>
                  <p className='text'>{accounts[account].type}</p>
                </div>
                <div className='side4'>
                  <p></p>
                </div>
              </div>
            );
          })}
        </div>

        <div className='buttons'>


          <BasicButton
            title='Create New'
            onClick={() => setOpenNewAccountModal(true)}
            style={{ width: "120px", marginRight: "30px" }}
            id='account_box_open_new_account_modal'
          />
          <BasicButton
            title='Import'
            onClick={() => setOpenImportAccountModal(true)}
            id='account_box_open_import_account_modal'
            style={{ width: "120px" }}
          />
        </div>
      </div> */}
    </>
  );
};

export default AccountsBox;
