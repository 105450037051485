import { FC, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

import SearchInput from "components/SearchInput";
import EthereumTokens from "../../Tokens/Ethereum.json";
import PolygonTokens from "../../Tokens/Polygon.json";
import { useAppDispatch, useAppSelector } from "store/store";
import { setSwapDetails } from "@slices/appSlice";
import { fetchPrice } from "utils/utils";
import { getSwapQuote } from "utils/swap";

type UniswapToken = {
  name: string;
  address: string;
  symbol: string;
  decimals: number;
  chainId: number;
  logoURI: string;
};

const ReceiveTokenList: FC<{
  nextStep: () => void;
}> = ({ nextStep }) => {
  const tokens: {
    [chainId: number]: UniswapToken[];
  } = {
    [1]: EthereumTokens,
    [137]: PolygonTokens,
  };

  const {
    swapDetails: { chainId, tokenA },
  } = useAppSelector((state) => state.app);

  const dispatch = useAppDispatch();

  const [value, setValue] = useState("");
  const [filteredTokens, setFilteredTokens] = useState(tokens[chainId]);

  const onTokenSelection = async (token: UniswapToken) => {
    const { address } = token;
    nextStep();

    const selectedTokenDetails = {
      id: 1,
      image: token.logoURI,
      amountInUSD: 0,
      tokenAddress: token.address,
      tokenBalance: 0,
      tokenDecimal: token.decimals,
      tokenName: token.name,
      tokenPrice: 0,
      tokenSymbol: token.symbol,
      amount: 0,
    };

    dispatch(setSwapDetails({ tokenB: selectedTokenDetails }));

    // const price = await fetchPrice([address.toLowerCase()], chainId);
    // const tokenPrice = price[address.toLowerCase()]?.price || 0;
    // const updatedSelectedTokenDetails = { ...selectedTokenDetails, tokenPrice };
    // console.log(
    //   "🚀 ~ file: index.tsx:62 ~ onTokenSelection ~ updatedSelectedTokenDetails:",
    //   updatedSelectedTokenDetails
    // );
    // dispatch(setSwapDetails({ tokenB: selectedTokenDetails }));
    // const quotedAmount =
    //   (await getSwapQuote({
    //     amount: tokenA.amount.toString(),
    //     chainId: chainId,
    //     tokenInAddress: tokenA.tokenAddress,
    //     tokenOutAddress: address,
    //     tokenInDecimals: tokenA.tokenDecimal,
    //     tokenOutDecimals: token.decimals,
    //   })) || 0;
    // console.log(
    //   "🚀 ~ file: index.tsx:72 ~ onTokenSelection ~ quotedAmount:",
    //   quotedAmount
    // );
    // dispatch(
    //   setSwapDetails({
    //     tokenB: {
    //       ...updatedSelectedTokenDetails,
    //       amount: +quotedAmount,
    //       amountInUSD: +quotedAmount * tokenPrice,
    //     },
    //   })
    // );
  };

  useEffect(() => {
    const filteredTokens = tokens[chainId].filter(
      (token) =>
        token.name.toLowerCase().includes(value.toLowerCase()) ||
        token.symbol.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTokens(filteredTokens);
  }, [value]);

  return (
    <Box flex={1} alignItems={"center"}>
      <SearchInput
        handleOnChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
      />

      {/* Render tokens if available, otherwise show a message */}
      {filteredTokens.length > 0 ? (
        filteredTokens.map((token, index) => (
          <Stack
            key={index}
            display={"flex"}
            flexDirection={"row"}
            margin={2}
            alignItems={"center"}
            style={{
              cursor: "pointer",
              opacity:
                token.address.toLowerCase() ===
                tokenA.tokenAddress.toLocaleLowerCase()
                  ? 0.5
                  : 1,
              pointerEvents:
                token.address.toLowerCase() ===
                tokenA.tokenAddress.toLocaleLowerCase()
                  ? "none"
                  : "all",
            }}
            onClick={() => onTokenSelection(token)}
          >
            <img src={token.logoURI} alt="tokenIcon" width={35} height={35} />
            <Stack marginLeft={2}>
              <Typography variant="h6"> {token.name}</Typography>
              <Typography variant="caption" style={{ opacity: 0.5 }}>
                {token.symbol}
              </Typography>
            </Stack>
          </Stack>
        ))
      ) : (
        <Typography
          variant="subtitle1"
          style={{ textAlign: "center", marginTop: "1em" }}
        >
          No results found
        </Typography>
      )}
    </Box>
  );
};

export default ReceiveTokenList;
