import { useEffect, useState } from "react";

import { Box, Typography, Modal as CustomModal } from "@mui/material";

import ModalHeader from "components/ModalHeader";
import BasicButton from "components/Button";

import EthIcon from "assets/EthereumRound.svg";
import PolygonRoundIcon from "assets/PolygonRound.svg";
import MentalIcon from "assets/MentalIcon.svg";
import BMBRound from "assets/BMBRound.svg";

const FilterModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({
    ethereum: false,
    polygon: false,
    bnb: false,
    mantle: false,
  });
  const [selectText, setSelectText] = useState("Select All");

  async function filterHandler() {
    try {
      setLoading(true);
      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const filterData = [
    {
      id: 1,
      iconUrl: EthIcon,
      name: "Ethereum Network",
      slug: "ethereum",
    },
    {
      id: 2,
      iconUrl: PolygonRoundIcon,
      name: "Polygon Network ",
      slug: "polygon",
    },
    {
      id: 3,
      iconUrl: BMBRound,
      name: "BNB Smart Chain ",
      slug: "bnb",
    },
    {
      id: 4,
      iconUrl: MentalIcon,
      name: "Mantle Network",
      slug: "mantle",
    },
  ];

  const handleCheck = ({ chainName }) => {
    setChecked((prevState) => ({
      ...prevState,
      [chainName]: !prevState[chainName],
    }));
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    setChecked((prevState) => ({
      ...prevState,
      ethereum: !checked["ethereum"],
      polygon: !checked["polygon"],
      bnb: !checked["bnb"],
      mantle: !checked["mantle"],
    }));

    if (selectText === "Select All") {
      setSelectText("Deselect All");
    } else {
      setSelectText("Select All");
    }
  };

  // if any is false then select all else deselect all
  useEffect(() => {
    const isAllTrue = Object.values(checked).every((v) => v === true);
    if (isAllTrue) {
      setSelectText("Deselect All");
    } else {
      setSelectText("Select All");
    }
  }, [checked]);

  return (
    <CustomModal
      onClose={onClose}
      open={open}
      style={{
        border: "1px solid rgba(139, 139, 139, 0.4)",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "360px",
          height: "auto",
          borderRadius: "10px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          padding: "50px 20px 20px 20px",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <ModalHeader title="Networks" />
          </Box>
          <Box onClick={handleSelectAll}>
            <Typography
              variant={"h5"}
              fontWeight={500}
              color="#1A1C20"
              fontSize={12}
              fontFamily={"Helvetica Neue"}
              style={{
                pointer: "cursor",
              }}
            >
              {selectText}
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            // border: "1px solid rgba(139, 139, 139, 0.4)",
            boxShadow:
              "0px 8px 24px 10px rgb(24 39 75 / 5%),0px 6px 12px 4px rgb(24 39 75 / 1%)",
            borderRadius: "10px",
            padding: "10px",
            margin: "20px 0 20px 0",
          }}
        >
          {filterData?.map((data) => (
            <Box
              key={data?.id}
              className="sdsdsdsd"
              style={{
                display: "flex",
                flexFlow: "row",
                placeContent: "center flex-start",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "flex-start",
                padding: "10px",
              }}
            >
              <input
                type="checkbox"
                id="filter__chains__checkbox"
                name="checkbox"
                checked={checked[data?.slug]}
                onChange={() => handleCheck({ chainName: data?.slug })}
                value="1"
                style={{
                  width: "1rem",
                  height: "1rem",
                  margin: "0 10px 0 0",
                }}
              />
              <img src={data?.iconUrl} width={40} height={40} />
              <Typography
                variant={"h5"}
                fontWeight={400}
                color="#1A1C20"
                fontSize={18}
                fontFamily={"Helvetica Neue"}
                margin={"0 0 0 10px"}
              >
                {data?.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <BasicButton
          title="Filter"
          onClick={() => {
            onClose();
          }}
          style={{
            fontWeight: "700",
          }}
        />
      </Box>
    </CustomModal>
  );
};

export default FilterModal;
