import {
  initialPendingTxValues,
  setPendingTx,
  setPendingTxDetails,
} from "@slices/appSlice";
import { setTxStatus } from "@slices/walletSlice";
import { SUPPORTED_NETWORKS } from "constants/chains";

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getUserOpHashData } from "utils/jiffyscan";
import { showNotification } from "utils/notification";
import {
  decryptMessage,
  fetchUsdPrice,
  initalizeWeb3,
  saveTransaction,
} from "utils/utils";

const useOpHashConfirmation = () => {
  const {
    activeNetwork,
    pendingTx,
    pendingTxDetails,
    accounts,
    rootAccountInfo,
    activeAccount,
  } = useAppSelector((state) => state.app);
  const { txStatus, hashedPassword } = useAppSelector((state) => state.wallet);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let timer;
    (async () => {
      if (pendingTx) {
        timer = setInterval(async () => {
          const data = await getUserOpHashData(pendingTx, activeNetwork);
          const { block_explorer_url } = SUPPORTED_NETWORKS[activeNetwork];
          console.log("DATA", data);

          if (data.userOps.length > 0) {
            const userOps = data.userOps[0];
            if (userOps.success) {
              const web3 = initalizeWeb3(activeNetwork);
              const { coingeckoId } = SUPPORTED_NETWORKS[activeNetwork];

              const txFee = userOps.actualGasCost / 10 ** 18;
              const deductedFee = txFee * 0.5;
              const balanceInUsd = await fetchUsdPrice(coingeckoId);
              const deductedFeeInUsd = deductedFee * balanceInUsd;
              console.log("deductedFeeInUsd", deductedFeeInUsd);
              const pkey = decryptMessage(
                rootAccountInfo.secret,
                hashedPassword
              );
              const {
                value,
                valueIn$,
                transferAmount,
                transactionMethod,
                scanLink,
                eoaEns,
                addressEns,
                toAddressEns,
                toAddress,
                assetName,
                networkFeesIn$,
                iconURL,
                txByDesposited,
                action,
              } = pendingTxDetails;
              console.log(
                "file: useOpHashConfirmation.tsx:73  timer=setInterval  action:",
                action
              );
              const signature = web3.eth.accounts.sign(
                `${userOps.transactionHash}${networkFeesIn$}`,
                pkey
              );

              await saveTransaction(
                activeAccount.smartAccountAddress.toLowerCase(),
                rootAccountInfo.address.toLowerCase(),
                userOps.transactionHash,
                activeNetwork,
                Number(networkFeesIn$),
                signature.signature,
                value ?? "",
                valueIn$ ?? "",
                transferAmount ?? "",
                transactionMethod ?? "",
                `${block_explorer_url}tx/${userOps.transactionHash}` ?? "",
                eoaEns ?? "",
                addressEns ?? "",
                toAddressEns ?? "",
                toAddress ?? "",
                assetName ?? "",
                String(networkFeesIn$) ?? "",
                iconURL ?? "",
                txByDesposited ? Number(networkFeesIn$) : 0,
                action
              );

              dispatch(setTxStatus(!txStatus));
              dispatch(setPendingTx(""));
              dispatch(setPendingTxDetails(initialPendingTxValues));

              showNotification(
                "Transaction Successful",
                "Your Transaction successfully completed",
                `${block_explorer_url}tx/${userOps.transactionHash}`
              );

              clearInterval(timer);
            } else {
              dispatch(setTxStatus(!txStatus));
              dispatch(setPendingTx(""));

              showNotification(
                "Transaction Failed",
                "Your Transaction Failed",
                `${block_explorer_url}tx/${userOps.transactionHash}`
              );
              clearInterval(timer);
            }
          }
        }, 5000);
      }
    })();

    return () => {
      clearInterval(timer);
    };
  }, [pendingTx]);

  return "";
};

export default useOpHashConfirmation;
