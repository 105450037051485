import { Input } from "@mui/material";
import React from "react";

const CodeInput = ({
  handleOnChange,
  value,
}: {
  handleOnChange: (e: any) => void;
  value: string;
}) => {
  return (
    <Input
      placeholder="Enter Code"
      onChange={handleOnChange}
      value={value}
      sx={{
        padding: "5px 20px",
        gap: "8.384px",
        flexShrink: " 0",
        width: "100%",
        borderRadius: "10px",
        opacity: " 0.8",
        background: "#EFF3F4",
      }}
    />
  );
};
export default CodeInput;
