import React, { useEffect, useState } from "react";

import Web3 from "web3";

import "./index.css";
import {
  Box,
  FormControl,
  Grid,
  Input,
  Snackbar,
  Typography,
} from "@mui/material";
import BasicButton from "components/Button";

import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";
import {
  decryptMessage,
  fetchCashAccountNativeBalance,
  showAlert,
} from "utils/utils";
import abi from "abis/erc20abi.json";
import paymasterAbi from "abis/paymasterabi.json";

import { fetchBaseChainGasPrice, fetchGasPrice } from "utils/gas";
import { BASE_URL, NATIVE_ADDRESS } from "constants/";

import { useNavigate } from "react-router-dom";
import { getCashToAddress, getEnsAddress, getToAddress } from "utils/ens";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";

import axios from "axios";
import TextInput from "components/TextInput";
import Button from "components/NewButton";

import CashIcon from "assets/cash-token.svg";
import MidArrow from "assets/midArrow.svg";
import { setTxStatus } from "@slices/walletSlice";
import TransactionContactList from "components/TransactionContactList";

const CashSend = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [to, setTo] = useState("");
  const [error, setError] = useState("");
  const [generalError, setGeneralError] = useState(false);
  const [tag, setTag] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);

  const [step, setStep] = useState(0);

  const navigate = useNavigate();
  const { activeAccount, holdings, activeNetwork, portfolio, rootAccountInfo } =
    useAppSelector((state) => state.app);
  console.log(
    "file: index.tsx:104  Send  activeAccount:",
    activeAccount,
    rootAccountInfo
  );

  const { selectedToken } = useAppSelector((state) => state.wallet);
  console.log("file: index.tsx:89  Send  selectedToken:", selectedToken);
  const { hashedPassword, txStatus } = useAppSelector((state) => state.wallet);

  const dispatch = useAppDispatch();

  const sendUsdc = async () => {
    try {
      setLoading(true);
      const web3 = new Web3(CASH_SUPPORTED_NETWORK[SupportedChainId.BASE].rpc);
      const { average } = await fetchBaseChainGasPrice(SupportedChainId.BASE);
      const { usdcAddress, executorAddress, chainId } =
        CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];
      const contract = new web3.eth.Contract(
        //@ts-ignore
        abi.abi,
        usdcAddress
      );
      const nonce = await web3.eth.getTransactionCount(
        rootAccountInfo.address,
        "latest"
      );
      const gasLimit =
        (await contract.methods
          .transfer(to, 123)
          .estimateGas({ from: rootAccountInfo.address })) * 1.4;

      const gasLimit2 =
        (await contract.methods
          .transfer(executorAddress, 123)
          .estimateGas({ from: rootAccountInfo.address })) * 1.7;

      const decimals = await contract.methods.decimals().call();

      console.log(gasLimit * average, gasLimit, average);

      const txCost = (gasLimit * average) / 10 ** 18;

      const txCost2 = (gasLimit2 * average) / 10 ** 18;

      console.log("TX COST", txCost, txCost2);

      const totalTxCost = txCost + txCost2;

      console.log("totalTxCost", totalTxCost);

      const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);

      const signature = web3.eth.accounts.sign(totalTxCost.toString(), pkey);

      // get gas from server
      const { data } = await axios.post(BASE_URL + "/transaction/sendEth", {
        fees: totalTxCost.toString(),
        signature: signature.signature,
        address: rootAccountInfo.address,
      });

      const calculateValueToSent =
        rootAccountInfo.usdTokenBalance - (Number(value) + totalTxCost) > 0
          ? value
          : value - totalTxCost;

      console.log("calculateValueToSent", calculateValueToSent);

      console.log("data", data);
      let timer = setInterval(async () => {
        web3.eth.getTransactionReceipt(data.hash).then(async (res) => {
          if (res && res.status) {
            console.log("ifffffffffffff");
            clearInterval(timer);
            const balance = await fetchCashAccountNativeBalance(
              rootAccountInfo.address,
              chainId
            );

            console.log(balance);

            // execute transaction using gas from server

            const tx1 = {
              value: "0x0",
              to: usdcAddress,
              gasPrice: average,
              gasLimit: web3.utils.toHex(gasLimit.toFixed()),
              nonce,
              data: contract.methods
                .transfer(
                  to,
                  (calculateValueToSent * 10 ** decimals).toString()
                )
                .encodeABI(),
            };

            const signedTx = await web3.eth.accounts.signTransaction(tx1, pkey);

            if (signedTx?.rawTransaction) {
              web3.eth
                .sendSignedTransaction(signedTx.rawTransaction)
                .on("transactionHash", async (tx) => {
                  console.log("first tx hash", tx);
                })
                .on("receipt", async (conf) => {
                  //return gas amount equivalent to usdc to server
                  const usdcAmountToPayToExecutor = (
                    totalTxCost *
                    balance.nativeTokenPrice *
                    1.2
                  ).toFixed(6);
                  console.log(usdcAmountToPayToExecutor);

                  const tx2 = {
                    value: "0x0",
                    to: usdcAddress,
                    gasPrice: average,
                    gasLimit: web3.utils.toHex(gasLimit2.toFixed()),
                    nonce: nonce + 1,
                    data: contract.methods
                      .transfer(
                        executorAddress,
                        (
                          Number(usdcAmountToPayToExecutor) *
                          10 ** decimals
                        ).toString()
                      )
                      .encodeABI(),
                  };
                  const signedTx2 = await web3.eth.accounts.signTransaction(
                    tx2,
                    pkey
                  );

                  if (signedTx2.rawTransaction) {
                    web3.eth
                      .sendSignedTransaction(signedTx2.rawTransaction)
                      .on("transactionHash", async (tx) => {
                        console.log("second has", tx);
                      })
                      .on("receipt", (conf) => {
                        //return gas amount equivalent to usdc to server
                        dispatch(setTxStatus(!txStatus));
                        showAlert("Transaction Successful");
                        navigate("/cash");

                        setLoading(false);
                      })
                      .on("error", (error) => {
                        console.log(error);
                        setLoading(false);
                        showAlert("Transaction Failed");
                      });

                    setLoading(false);
                  }
                })
                .on("error", (error) => {
                  console.log(error);
                  setLoading(false);
                  showAlert("Transaction Failed");
                });
            }
          }
        });
      }, 1000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert("Transaction Failed");
    }
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;

    if (/[^0-9.]/.test(inputValue)) {
      setError("Special characters are not allowed");
      setGeneralError(true);
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("Only one decimal point is allowed");
      setGeneralError(false);
    } else if (!/^\d{0,10}(\.\d{0,6})?$/.test(inputValue)) {
      setError(
        "Maximum of 10 digits before decimals and 6 digits after decimals are allowed"
      );
      setGeneralError(true);
    } else if (parseFloat(inputValue) < 0.01) {
      setError("Value should not be less than $0.01");
      setGeneralError(false);
      setValue(inputValue);
    } else if (value && value > rootAccountInfo.usdTokenBalance) {
      setError("You do not have enough balance");
      setGeneralError(false);
      setValue(inputValue);
    } else {
      setError("");
      setGeneralError(false);

      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      setValue(newValue);
      setError("");
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (Number(newValue) > 0) {
        setValue(newValue);
      }
      setError("");
    }
  };

  return (
    <>
      {/* <Button title="Send" onClick={sendUsdc} loading={loading} /> */}

      <Box mt={6}>
        <NavigatorHeading
          title="Send Cash"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/cash");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Recipient", "Amount", "Send"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>

      <Box mt={5}>
        {step == 0 && (
          <Box display={"flex"} flexDirection={"column"}>
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={12}
                sm={12}
                style={{
                  flexBasis: "100%",
                  maxWidth: "82%",
                }}
              >
                <TransactionContactList
                  shouldAddContact={false}
                  isTxForm
                  isChooseRecipient={true}
                  nextStep={(address: string, tag: string) => {
                    setStep(1);
                    setTo(address);
                    setTag(tag);
                  }}
                  isCashMode={true}
                />
              </Grid>
            </Grid>
          </Box>
          // <Grid container display="flex" justifyContent="center">
          //   <Grid item lg={6} sm={12}>
          //     <TextInput title="Cash Tag" value={to} onChange={setTo} />

          //     <Button
          //       title="Next"
          //       onClick={handleRecipient}
          //       loading={loading}
          //       disabled={!to}
          //     />
          //   </Grid>
          // </Grid>
        )}

        {step == 1 && (
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={6} sm={12} display="flex" justifyContent="center">
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "rgba(26, 28, 32, 0.50)",
                      fontFamily: "Helvetica Neue",
                      fontsize: 17,
                      fontWeight: 500,
                    }}
                  >
                    Send to {tag ? tag : to}
                  </Typography>
                  <Box mt={2.5}>
                    <div className="input-container">
                      <FormControl sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            color: "#1A1C20",
                            width: "100%",
                            // height: "80px",
                            borderRadius: "10px",
                            fontSize: "25px",
                            fontFamily: "Space Grotesk",
                            fontWeight: "700",
                            border: "0.5px solid rgba(26, 28, 32, 0.50)",
                          }}
                        >
                          <Input
                            style={{
                              padding: "5px 20px 5px 20px",
                            }}
                            onChange={handleAmountChange}
                            onKeyDown={handleKeyDown}
                            endAdornment={
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box
                                  mr={1.25}
                                  style={{
                                    marginTop: "10px",
                                  }}
                                >
                                  <img src={CashIcon} />
                                </Box>
                                <Box
                                  sx={{
                                    color: "#1A1C20",
                                    fontFamily: "Space Grotesk",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "normal",
                                    marginTop: "0px",
                                    marginLeft: -1.5,
                                  }}
                                >
                                  Cash
                                </Box>
                              </Box>
                            }
                            value={value}
                            sx={{ width: "100%", padding: "20px" }}
                            placeholder="0.00"
                          />
                        </Box>
                        {error && (
                          <Typography
                            style={{ fontSize: 12, color: "red", marginTop: 5 }}
                          >
                            {error}
                          </Typography>
                        )}
                      </FormControl>
                    </div>
                  </Box>
                  <Box mt={5}>
                    <Button
                      title="Continue"
                      onClick={() => !error && setStep(2)}
                      disabled={
                        !value.length ||
                        Number(value) > rootAccountInfo.usdTokenBalance ||
                        (!generalError && error)
                      }
                      style={{
                        borderRadius: "10px",
                        padding: "15px 0px",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}

        {step == 2 && (
          <Grid container display="flex" justifyContent="center" py={4}>
            <Grid item lg={6} sm={12}>
              <Box mt={2.5} position={"relative"}>
                <div className="input-container" style={{ marginBottom: 10 }}>
                  <FormControl sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: "#EDEEF2",
                        color: "#1A1C20",
                        width: "100%",
                        // height: "80px",
                        borderRadius: "10px",
                        fontSize: "25px",
                        fontFamily: "Space Grotesk",
                        fontWeight: "700",
                        border: "0.5px solid rgba(26, 28, 32, 0.50)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: 15,
                          }}
                        >
                          <Box mr={1.25}>
                            <img src={CashIcon} />
                          </Box>
                          <Box
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              marginTop: -2,
                              marginLeft: -1.5,
                            }}
                          >
                            Cash
                          </Box>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginRight: 10,
                          }}
                        >
                          <Box
                            mr={1.25}
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                            }}
                          >
                            ${value}
                          </Box>
                          <Box
                            sx={{
                              color: "#8C8D8F",
                              fontFamily: "Space Grotesk",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }}
                          >
                            {value} USDbC
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </FormControl>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "50%",
                    zIndex: 2,
                  }}
                >
                  <img src={MidArrow} />
                </div>

                <div className="input-container">
                  <FormControl sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        color: "#1A1C20",
                        width: "100%",
                        // height: "80px",
                        borderRadius: "10px",
                        fontSize: "25px",
                        fontFamily: "Space Grotesk",
                        fontWeight: "700",
                        border: "0.5px solid rgba(26, 28, 32, 0.50)",
                        backgroundColor: "#EDEEF2",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: 15,
                          }}
                        >
                          <Box mr={1.25}>
                            <div
                              style={{
                                height: 25,
                                width: 25,
                                borderRadius: 50,
                                padding: 10,
                                backgroundColor: "#0038FF26",
                                margin: 10,
                                marginTop: 0,
                              }}
                            >
                              <div
                                style={{
                                  color: "#0038FF",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "13px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                  marginTop: 4,
                                  textAlign: "center",
                                }}
                              >
                                {tag ? tag.slice(0, 2).toUpperCase() : "0x"}
                              </div>
                            </div>
                          </Box>
                          <Box
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              marginTop: -2,
                              marginLeft: -1.5,
                            }}
                          >
                            {tag || to}
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </FormControl>
                </div>
              </Box>
              <Button
                title="Send"
                onClick={sendUsdc}
                loading={loading}
                style={{
                  borderRadius: "10px",
                  padding: "15px 0px",
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default CashSend;
