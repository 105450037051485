import React from "react";
import StashedLogo from "assets/stashed-logo.svg";

import "./styles.css";
import { useNavigate } from "react-router-dom";

const AuthHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="auth-header">
      <img
        src={StashedLogo}
        className="stashed-logo"
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export default AuthHeader;
