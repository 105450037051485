import React, { FC, useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import {
  LIGHT_GREEN_COLOR,
  DARK_GREEN_COLOR,
  LIGHT_BLUE_COLOR,
} from "constants/";
import ReactApexChart from "react-apexcharts";
import CardLayout from "components/CardLayout";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChipButton from "components/ChipButton";
import { useAppDispatch, useAppSelector } from "store/store";
import { Interval } from "../../enums";
import { setHistory } from "@slices/appSlice";

interface ChartProps {
  chartData: number[];
  chartTimestamps: number[];
  title?: string;
  amount?: string;
  percentageChange?: string;
  chartColor?: string;
  chartStrokeColor?: string;
  expandedHeight?: string;
}

const ChartComponent: FC<ChartProps> = ({
  chartData,
  chartTimestamps,
  chartColor,
  chartStrokeColor,
}) => {
  const chartObj = {
    series: [
      {
        name: "Portfolio",
        data: chartData.map((value, index) => {
          return {
            x: chartTimestamps[index],
            y: value,
          };
        }),
      },
    ],
    options: {
      tooltip: {
        enabled: true,
        x: {
          format: "dd MMM yyyy",
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w }
          ) {
            return new Date(value).toString();
          },
        },
        y: {
          formatter: function (value) {
            return value.toFixed(4);
          },
        },
      },
      grid: {
        show: false,
      },
      chart: {
        height: 160,
        type: "area",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
        selection: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          // shadeIntensity: 1,
          // opacityFrom: 1,
          // opacityTo: 0.5,
          // stops: [0, 100, 100],
          colorStops: [
            {
              offset: 0.199991,
              color: chartColor, // First color
              opacity: 1,
            },
            {
              offset: 100,
              color: chartStrokeColor, // Second color
              opacity: 0.7,
            },
          ],
        },
      },
      stroke: {
        curve: "smooth",
        colors: [chartStrokeColor],
        width: 1,
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: chartTimestamps || [],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },

      colors: [chartColor],
      noData: {
        text: "No transactions found in selected time period",
        align: "center" as const,
        verticalAlign: "middle" as const,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "rgba(26, 28, 32, 0.75)",
          fontSize: "16px",
          fontFamily: "Space Grotesk",
        },
      },
    },
  };

  useEffect(() => {
    console.log("chart data", { chartData, chartTimestamps });

    return () => {};
  }, [chartData, chartTimestamps]);

  return (
    <ReactApexChart
      options={chartObj.options}
      series={chartObj.series}
      type="area"
      height={"100%"}
    />
  );
};

const Chart: FC<ChartProps> = ({
  chartData,
  chartTimestamps,

  amount,
  percentageChange,
  chartColor = LIGHT_GREEN_COLOR,
  chartStrokeColor = DARK_GREEN_COLOR,
  expandedHeight = "100%",
}) => {
  const splittedBalance = (amount ? amount : "").split(".");

  const {
    history: { interval },
  } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const onChangeInterval = (interval: Interval) => {
    dispatch(
      setHistory({
        interval,
      })
    );
  };

  const chartProps = {
    chartData,
    chartTimestamps,
    amount,
    percentageChange,
    chartColor,
    chartStrokeColor,
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box display="flex" justifyContent="right">
        {/* <Box display="flex" alignItems="baseline">
          <Typography
            variant={"h5"}
            fontWeight={700}
            color="#1A1C20"
            fontSize={45}
            style={{ cursor: "pointer" }}
            align="center"
          >
            {splittedBalance[0]}.
          </Typography>
          <Typography
            variant={"h5"}
            fontWeight={700}
            color="rgba(26, 28, 32, 0.5)"
            fontSize={25}
            style={{ cursor: "pointer" }}
            align="center"
          >
            {splittedBalance[1]}
          </Typography>
        </Box> */}
        <Box
          style={{
            display: "flex",
            gap: "40px",
            justifyContent: "flex-end",
            flexFlow: "wrap",
            paddingBottom: "20px",
          }}
        >
          <ChipButton
            fontWeight={700}
            title="1H"
            disabled={interval != Interval.HOUR}
            onClick={() => {
              onChangeInterval(Interval.HOUR);
            }}
            paddingInline={"10px"}
            width="20px"
          />
          <ChipButton
            fontWeight={700}
            title="1D"
            disabled={interval != Interval.DAY}
            onClick={() => {
              onChangeInterval(Interval.DAY);
            }}
            paddingInline={"10px"}
            width="20px"
          />
          <ChipButton
            fontWeight={700}
            title="1W"
            disabled={interval != Interval.WEEK}
            onClick={() => {
              onChangeInterval(Interval.WEEK);
            }}
            paddingInline={"10px"}
            width="20px"
          />
          <ChipButton
            fontWeight={700}
            title="1M"
            disabled={interval != Interval.MONTH}
            onClick={() => {
              onChangeInterval(Interval.MONTH);
            }}
            paddingInline={"10px"}
            width="20px"
          />
        </Box>
      </Box>
      <Box flexGrow={1}>
        <ChartComponent {...chartProps} />
      </Box>
    </Box>
  );
};

export default Chart;
