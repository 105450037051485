import React, { useEffect, useState } from "react";
import * as Bip39 from "bip39";

import { CircularProgress, Grid, Typography } from "@mui/material";
import ens from "@ensdomains/eth-ens-namehash";
import sss from "shamirs-secret-sharing";
import pbkdf2 from "pbkdf2";
import TextInput from "components/TextInput";
import Button from "components/NewButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthHeader from "components/AuthHeader";
import {
  addUser,
  createEVMSmartAccount,
  decryptMessage,
  deriveAccount,
  encryptMessage,
  getEnsFromAddress,
  getSmartAccountAddress,
  initalizeWeb3,
  isEnsExist,
  saveEncryptedKeys,
  saveEns,
  saveSubEns,
  showAlert,
  userNameRegexValidation,
} from "utils/utils";
import { useAppDispatch, useAppSelector } from "store/store";
import { initalizeBlockNumbers } from "utils/blocknumber";
import {
  saveMnemonic,
  setAccounts,
  setActiveAccount,
  setBlockNumbers,
  setEncryptedSeedPhraseWithPassword,
  setExpirationTime,
  setHoldings,
  setOwnReferralCode,
  setReferredTo,
} from "@slices/appSlice";
import {
  LOGIN_EXPIRY,
  SHARES,
  THRESHOLD,
  stepsCount,
} from "../../../../../src/constants";
import { setHashedPin, setRecoveryCode } from "@slices/walletSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";

const CryptoStashedTag = () => {
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const navigate = useNavigate();
  const {
    user: { email, name },
    activeNetwork,
    accessToken,
    externalReferralCode,
    encryptedSeedPhraseWithPassword,
    rootAccountInfo,
  } = useAppSelector((state) => state.app);
  const { torusKey, hashedPin, recoveryCode, hashedPassword } = useAppSelector(
    (state) => state.wallet
  );

  const dispatch = useAppDispatch();

  const bufferToReadable = (buffer) => {
    return buffer.toString("hex");
  };

  const handleFormValidation = (username: string) => {
    if (username.includes("..")) {
      showAlert("No special characters (letters & numbers only)");
    } else if (!userNameRegexValidation(username)) {
      showAlert("No special characters (letters & numbers only)");
    } else if (username.length < 4 || username.length > 20) {
      showAlert("Crypto Tag must be 4-20 characters");
    } else {
      return true;
    }
  };

  const createAccount = async () => {
    try {
      setLoading(true);
      const normzalized = ens.normalize(accountName);
      console.log(
        "🚀 ~ file: index.tsx:83 ~ createAccount ~ normzalized:",
        normzalized
      );
      console.log(normzalized);
      const isOk = handleFormValidation(accountName);
      const data = await isEnsExist(normzalized);

      const hashedTorusKey = pbkdf2
        .pbkdf2Sync(torusKey, "salt", 1, 32, "sha512")
        .toString("hex");

      let deriveChildNum = 1;

      console.log("data----------------------------", data);

      if (!data) {
        if (isOk) {
          const seedphrase = decryptMessage(
            encryptedSeedPhraseWithPassword,
            hashedPassword
          );

          const secretBuffer = Buffer.from(seedphrase);
          const generatedShares = sss.split(secretBuffer, {
            shares: SHARES,
            threshold: THRESHOLD,
          });

          let s = generatedShares.map((s) => bufferToReadable(s));

          const encryptedShare1 = encryptMessage(s[0], hashedTorusKey);
          const encryptedShare2 = encryptMessage(s[1], hashedPassword);
          const encryptedShare3 = encryptMessage(s[2], hashedPin);
          const encryptedShare4 = encryptMessage(s[3], recoveryCode);

          const { newAccount, address, secret, counterfactual, accountName } =
            await createEVMSmartAccount(
              seedphrase,
              deriveChildNum,
              normzalized,
              hashedPassword,
              activeNetwork
            );
          console.log(
            "🚀 ~ file: index.tsx:116 ~ createAccount ~ newAccount:",
            newAccount
          );

          const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
          const web3 = initalizeWeb3(activeNetwork);

          const signature = web3.eth.accounts.sign(normzalized, pkey);

          await saveSubEns(
            normzalized,
            counterfactual.toLowerCase(),
            rootAccountInfo.address,
            signature.signature
          );

          const res = await saveEncryptedKeys(
            accessToken,
            email,
            name,
            rootAccountInfo.address.toLowerCase(),
            encryptedShare1,
            encryptedShare2,
            encryptedShare3,
            encryptedShare4,
            externalReferralCode
          );
          await addUser(rootAccountInfo.address.toLowerCase());

          const blockNumbers = await initalizeBlockNumbers();

          dispatch(setOwnReferralCode(res.referenceCode));
          dispatch(setReferredTo(res.referedTo));
          dispatch(setBlockNumbers(blockNumbers));

          dispatch(setExpirationTime(new Date().getTime() + LOGIN_EXPIRY));

          dispatch(
            setHoldings({
              [counterfactual]: {
                nativeBalance: 0,
                nativeBalanceUsd: 0,
                nativeTokenPrice: 0,
                tokens: [],
              },
            })
          );
          console.log(
            "find me here it us ",
            address,
            secret,
            counterfactual,
            accountName,
            newAccount
          );

          dispatch(setAccounts(newAccount));
          dispatch(
            setActiveAccount({
              address,
              secret,
              smartAccountAddress: counterfactual,
              accountName,
            })
          );

          dispatch(setHashedPin(""));
          dispatch(setRecoveryCode(""));
          dispatch(saveMnemonic(""));

          setLoading(false);

          navigate("/account-created", {
            state: { currentStep: step + 1, pathName: pathName },
          });
        } else {
          setLoading(false);
        }
      } else {
        showAlert("Crypto Tag already in use. Try another!");

        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.toString().includes("Illegal char")) {
        showAlert("No special characters (letters & numbers only)");
        console.log("err", error);
      } else {
        showAlert(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong"
        );
      }
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13 || key === "Tab" || keyCode === 9) {
      e.preventDefault(); // Prevent the default behavior of the Tab key
      createAccount();
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>
      <div className="create-account-box">
        <>
          <Typography
            variant="h2"
            sx={{
              margin: "30px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Claim your Crypto-Tag
          </Typography>

          <Typography
            variant="body2"
            sx={{
              margin: "10px 0px 10px 0px",
              color: "rgba(26, 28, 32, 0.5)",

              fontWeight: "500",
              textAlign: "center",
              width: "25%",
            }}
          >
            Your Crypto Tag will be used for receiving Crypto
          </Typography>

          <div className="welcome-box-container">
            <TextInput
              title="Crypto Tag"
              value={accountName}
              onChange={setAccountName}
              style={{
                width: "350px",
              }}
              onKeyPress={(e) => handleKeyPress(e)}
            />

            <Button
              onClick={createAccount}
              title="Continue"
              loading={loading}
              disabled={!accountName || accountName.length < 4}
              style={{
                margin: 0,
                width: "370px",
                padding: "15px 0px",
              }}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default CryptoStashedTag;
