import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import WelcomeBox from "components/WelcomeBox";
import Google from "assets/google.svg";
import Discord from "assets/discord.svg";
// import SigninImg from 'assets/signin.png'
import SigninImg from "assets/signin.svg";

import gradient from "assets/signin-gradient.svg";

import "./styles.css";
import AuthHeader from "components/AuthHeader";
import useGoogleWeb3Auth from "hooks/useGoogleWeb3Auth";
import { useAppDispatch } from "store/store";
import { loginWithGoogle } from "utils/google";
import {
  removeAccounts,
  setAccesToken,
  setActiveAccount,
  setFetchedKeys,
  setIsAccountCreated,
  setOwnReferralCode,
  setReferredTo,
  setUserInfo,
} from "@slices/appSlice";
import { fetchEncryptedKeys, showAlert } from "utils/utils";
import { setTorusKey } from "@slices/walletSlice";

import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";

const Signin = () => {
  const { web3auth } = useGoogleWeb3Auth();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location || {};
  const { currentStep } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const signinWithGoogle = async () => {
    try {
      if (web3auth) {
        setLoading(true);

        const { address, email, idToken } = await loginWithGoogle(web3auth);

        dispatch(setIsAccountCreated(false));
        dispatch(removeAccounts({}));
        dispatch(
          setActiveAccount({ address: "", secret: "", smartAccountAddress: "" })
        );

        if (email) {
          const data = await fetchEncryptedKeys(idToken);
          dispatch(setAccesToken(idToken));
          if (!data) {
            showAlert("Email does not exist");
            navigate("/create-account");

            setLoading(false);
            await web3auth.logout();
          } else {
            dispatch(setReferredTo(data.referedTo));
            dispatch(setOwnReferralCode(data.referenceCode));
            dispatch(
              setUserInfo({
                email: data.email,
                name: data.username,
              })
            );

            dispatch(setTorusKey(address[0].toString("hex")));
            dispatch(
              setFetchedKeys({
                rootAddress: data.address,
                key1: data.keys[0]?.key1,
                key2: data.keys[0]?.key2,
                key3: data.keys[0]?.key3,
              })
            );
            setStep(1);
            navigate("/signin-with-password", {
              state: { currentStep: step + 1, pathName: "/signin" },
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      showAlert("Recovery Failed");
    }
  };

  const signupWithDiscord = () => {
    showAlert("Coming Soon");
  };

  const totalSteps = stepsCount["/signin"] || 3;

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${gradient})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundClip: "content-box",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          backgroundPositionY: "top",
        }}
      >
        <div className="signin-container">
          <Grid container spacing={2}>
            <Grid item xs={8} className="auth-header-with-steps">
              <AuthHeader />
            </Grid>
            <Grid
              item
              xs={16}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                className="auth-header-steps"
                width={"50%"}
                style={{
                  position: "absolute",
                  top: "54px",
                }}
              >
                <CustomizedSteppers
                  step={step}
                  steps={new Array(totalSteps).fill("")}
                  changeStep={setStep}
                />
              </Box>
            </Grid>
          </Grid>
          <div className="create-account-box">
            <Typography
              variant="h2"
              sx={{
                margin: "30px 0px 30px 0px",

                fontWeight: "700",
              }}
            >
              Sign In
            </Typography>
            <div className="welcome-box-container">
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margin: "20px 0px",
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              ) : (
                <div className="box-main">
                  <div className="box top-box">
                    <WelcomeBox
                      title="Continue with Google"
                      description=""
                      onClick={signinWithGoogle}
                      icon={<img src={Google} style={{ marginRight: 10 }} />}
                    />
                  </div>
                  <div className="box bottom-box">
                    <WelcomeBox
                      title="Continue with Discord"
                      description=""
                      onClick={signupWithDiscord}
                      icon={<img src={Discord} style={{ marginRight: 10 }} />}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* <Box
              component={"img"}
              src={SigninImg}
              sx={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
                height: "auto",
                // maxWidth: '1200px',
                maxHeight: "60%",
                top: `calc(45% + 200px)`,
              }}
            /> */}
          </div>
        </div>
      </Box>
    </>
  );
};

export default Signin;
