import React, { useState } from "react";

// import StashedCreatedLogo from 'assets/stash-created.png'
import StashedCreatedLogo from "assets/stash-created.svg";

import Lottie from "react-lottie";

import SuccessLogo from "assets/success.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Button from "components/NewButton";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch } from "store/store";
import { setIsAccountCreated } from "@slices/appSlice";
import TxStatusCompleteAnimation from "../../../../animations/tx-status-complete.json";
import {
  setHashedPassword,
  setHashedPin,
  setTorusKey,
} from "@slices/walletSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: TxStatusCompleteAnimation,
  renderer: "svg",
};

const AccountRecovered = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const handleAccountCreate = () => {
    setLoading(true);
    dispatch(setIsAccountCreated(true));

    dispatch(setHashedPin(""));
    dispatch(setTorusKey(""));

    navigate("/");
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>

      <div className="create-account-box" style={{ marginTop: "1%" }}>
        <img src={SuccessLogo} />
        <Typography
          variant="h2"
          sx={{
            margin: "20px 0px 8px 0px",
            fontWeight: "700",
          }}
        >
          Account Recovered
        </Typography>

        <Typography
          variant="body2"
          sx={{
            margin: "0px 0px 0px 0px",
            color: "rgba(26, 28, 32, 0.5)",
            fontWeight: "500",
            textAlign: "center",
            width: "30%",
            fontSize: "18px",
            fontfamily: "Helvetica Neue",
          }}
        >
          Your account has been successfully recovered
        </Typography>

        {/* <Lottie
          options={defaultOptions}
          height={400}
          style={{ position: "absolute", top: 100, left: 0 }}
        /> */}

        <img
          src={StashedCreatedLogo}
          style={{
            width: "auto",
            height: "250px",
            margin: "20px 0px 20px 0px",
          }}
        />
        <div className="welcome-box-container">
          <Button
            title="Continue"
            onClick={handleAccountCreate}
            loading={loading}
            disabled={false}
            style={{
              margin: 0,
              width: "370px",
              padding: "15px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountRecovered;
