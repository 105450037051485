import React, { ReactNode, useCallback, useEffect, useState } from "react";
import Web3 from "web3";
import MidArrow from "assets/midArrow.svg";
import BlueTick from "assets/BlueTick.svg";
import "./index.css";
import {
  Box,
  Fade,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import BasicButton from "components/Button";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import AssetsView from "components/AssetsView";
import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS, SupportedChainNames } from "constants/chains";
import {
  decryptMessage,
  extractTokenData,
  numFormatter,
  showAlert,
  trimTokenAddress,
} from "utils/utils";
import paymasterAbi from "abis/paymasterabi.json";
import { setPendingTx, setPendingTxDetails } from "@slices/appSlice";
import { assert } from "console";
import { fetchGasPrice } from "utils/gas";
import { BASE_URL, NATIVE_ADDRESS } from "constants/";
import { ethers } from "ethers";

import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TransactionContactList from "../../../../components/TransactionContactList";
import {
  getEthDollarValue,
  getTokenDollarValue,
} from "../../../../utils/portfolio";
import SearchInput from "components/SearchInput";
import ReviewAssetListComponent from "./SendAsset/ReviewAssetListComponent";

import {
  TokenPaymaster__factory,
  Token__factory,
  getCounterFactualAddress,
  getPartialUserOpForDepositPaymaster,
  sendUserOp,
  simulateHandleOps,
  simulateValidation,
} from "../../../../contract-integration";

import { Erc1155ABI } from "abis/Erc1155ABI";
import { Erc721ABI } from "abis/Erc721ABI";
import TokenImage from "../../../../components/TokenImage";
import { arrayify } from "ethers/lib/utils";
import { tokensForGasObject } from "../../../../constants/topTokensConf";

import NoImg from "../../../../assets/noImg.svg";
import TopTokenSelectComponent from "../../../../components/TopTokenSelect";
import {
  CallDataType,
  EMPTY_CALLDATA,
  EntryPoint_Address,
  ExecuteCall,
  FactoryData,
  Paymaster_Token_Address,
  TransferData,
} from "../../../../contract-integration/constants";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import { txSubmissionOrderPostpaid } from "../../../../contract-integration/postpaidGas";
import FeeUrgencyComponent from "../../../../components/SendTxComponent/FeeUrgencyComponent";
import TxTypeSwitch from "../../../../components/SendTxComponent/TxTypeSwitch";
import SendTxComponent from "../../../../components/SendTxComponent";
import GasTokenSelect from "../../../../components/GasTokenSelect";
import FeeUIComponent from "../../../../components/SendTxComponent/FeeUIComponent";
import NFTCard from "../NFTCard";
import axios from "axios";

const SendNFT = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [to, setTo] = useState("");

  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [tag, setTag] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [gasPrice, setGasPrice] = useState(0);
  const [max, setMax] = useState(false);
  const [filter, setFilter] = useState(0);
  const [depositedAmount, setDepositedAmount] = useState(0);
  const [step, setStep] = useState(0);
  const [feeGasUrgency, setFeeGasUrgency] = React.useState("Normal");
  const [tokenForPayment, SetTokenForPayment] = React.useState("");
  const [txByDeposited, setTxByDeposited] = useState(false);
  const [depositableTokens, setDepositableTokens] = useState<Array<any>>([]);
  const [selectedDepositTokenAddress, setSelectedDepositTokenAddress] =
    useState("");
  const [gasFee, setGasFee] = useState<any>(null);

  const [error, setError] = useState(false);
  const [tokenForPaymentDecimal, setTokenForPaymentDecimal] = useState<
    number | null
  >(null);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [tokenForPaymentBalance, setTokenForPaymentBalance] =
    useState<number>(0);

  const [sameTokens, setSameTokens] = useState<any[]>([]);

  const navigate = useNavigate();
  const {
    activeAccount,
    holdings,
    accounts,
    activeNetwork,
    portfolio,
    rootAccountInfo,
    balances,
    newNFTs,
  } = useAppSelector((state) => state.app);

  useEffect(() => {
    const data = extractTokenData(activeNetwork, portfolio.assets);
    // SetTokenForPayment(data.address);
    setSameTokens(data.sameTokens);
  }, [activeNetwork, portfolio.assets]);

  const allNfts = newNFTs[activeAccount.smartAccountAddress];

  const { hashedPassword, isAccountDeployed } = useAppSelector(
    (state) => state.wallet
  );

  const { nativeTokenName, symbol, icon, rpc, block_explorer_url } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const { nativeBalance } = holdings[activeAccount.smartAccountAddress];

  const dispatch = useAppDispatch();

  // read if the url is like `/nfts/${NftColName}/${chainName}/${nftId}/send`
  // if yes, then fetch the NFT from the store and setSelectedNFT
  const currentURL = window.location.href;
  const currentURLArray = currentURL.split("/");
  const nftId = currentURLArray[currentURLArray.length - 2];
  const chainName = currentURLArray[currentURLArray.length - 3];
  const NftColName = currentURLArray[currentURLArray.length - 4];
  const currentNFT = newNFTs[activeAccount.smartAccountAddress].find(
    (item) => item.token_id == nftId && item.blockchain == chainName
  );

  const web3 = new Web3(rpc);
  const paymasterAddress = "0xb95948f2fdeB4c4aD1AB482E8cC61a74C1f62006";
  const paymasterContract = new web3.eth.Contract(
    paymasterAbi as any,
    Paymaster_Token_Address
  );

  useEffect(() => {
    (async () => {
      const { average } = await fetchGasPrice(activeNetwork);
      setGasPrice(average);
    })();
    SetTokenForPayment("");
    currentNFT && setSelectedNFT(currentNFT);
  }, []);

  useEffect(() => {
    let userTokens: Token[] =
      holdings[activeAccount.smartAccountAddress]?.tokens || [];

    setTokens(userTokens);
  }, [activeAccount, holdings]);

  useEffect(() => {
    (async () => {
      const depositInfo = await paymasterContract.methods
        .depositInfo(NATIVE_ADDRESS, activeAccount.smartAccountAddress)
        .call();

      console.log(depositInfo.amount);
      setDepositedAmount(
        Number(Web3.utils.fromWei(depositInfo.amount, "ether"))
      );
    })();
  }, []);

  const [searchText, setSearchText] = useState<string>("");

  // useEffect(() => {
  //   calculateGasInEth();
  // }, [tokenForPayment, step, feeGasUrgency]);

  const selectTokenHandler = (tk: Token) => {
    // dispatch(setSelectedToken([tk]))
    setOpenModal(false);
  };

  const handleSelectTokenForPayment = (address: string) => {
    SetTokenForPayment(address);
  };

  const groupByCollection = (array: any[], key: string) => {
    return array.reduce((result: any, currentItem: any) => {
      const chainNumber = Number(
        SupportedChainNames[currentItem.blockchain as any]
      );

      if (chainNumber == activeNetwork) {
        (result[currentItem[key]] = result[currentItem[key]] || []).push(
          currentItem
        );
      }
      return result;
    }, {});
  };

  const collections = groupByCollection(allNfts, "name");
  console.log("file: index.tsx:222  SendNFT  collections:", collections);

  const [selectedNFT, setSelectedNFT] = useState<any>(null);
  console.log("🚀 ~ file: index.tsx:294 ~ SendNFT ~ selectedNFT:", selectedNFT);

  const ISNATIVE =
    tokenForPayment == "0x0000000000000000000000000000000000000000" ||
    tokenForPayment == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

  const executeSendCrypto = async () => {
    setLoading(true);
    if (finalOpState) {
      const response = await sendUserOp(
        finalOpState,
        "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37",
        "https://polygon-mainnet.g.alchemy.com/v2/HBxGEElD4fSo3gWukvZFV9YTKO4OvCnw"
      );
      const userOPResponse: any = await response.wait();
      console.log("userOp Hash :", response.userOpHash);
      console.log("Tx Hash :", userOPResponse?.transactionHash);
      console.log("success status :", userOPResponse?.args.success);
      console.log(
        "actualGasCost  :",
        Number(userOPResponse?.args.actualGasCost)
      );
      // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
      // const userOPResponse: any = await response.wait();
      // console.log("Tx Hash :", userOPResponse?.transactionHash);
      // console.log("success status :", userOPResponse?.args.success);
      console.log(
        "actualGasCost  :",
        Number(userOPResponse?.args.actualGasCost)
      );
      console.log(
        "actualGasUsed  :",
        Number(userOPResponse?.args.actualGasUsed)
      );
      dispatch(
        setPendingTxDetails({
          value: value,
          valueIn$: "",
          transferAmount: value,
          transactionMethod: "NFT",
          scanLink: block_explorer_url,
          eoaEns: rootAccountInfo.name,
          addressEns: activeAccount.accountName,
          toAddressEns: to,
          toAddress: to,
          assetName: `${selectedNFT?.name} #${selectedNFT?.token_id}`,
          networkFeesIn$: gasFeeInUSD,
          iconURL: selectedNFT?.metadata?.image,
          txByDesposited: txByDeposited,
          action: "Sent",
        })
      );
      dispatch(setPendingTx(response.userOpHash));

      showAlert(
        "Soon you can see your transaction in the transactions tab",
        "Transaction Submitted",
        `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
      );
    }
    setFinalOpState(null);
    setLoading(false);
    navigate("/nfts");
  };

  useEffect(() => {
    console.log("file: index.tsx:439  useEffect  selectedNFT:", selectedNFT);
    if (selectedNFT && tokenForPayment && to && !txByDeposited) {
      newSendTransaction();
    }
    if (txByDeposited && selectedDepositTokenAddress && to) {
      newDepositSendTransaction();
    }
  }, [selectedNFT, tokenForPayment, step, selectedDepositTokenAddress]);

  async function newSendTransaction() {
    try {
      setError(false);
      setLoading(true);

      setFinalOpState(null);
      setGasFee({
        isLoading: true,
        usdcFee: "",
        transferGasFee: "",
        alternativeMessage: "Calculating...",
      });
      if (
        !tokenForPayment.trim() ||
        !to.trim() ||
        !activeNetwork ||
        !tokenForPaymentDecimal
      ) {
        window.alert("Please provide all details");
        setLoading(false);
        setGasFee({
          isLoading: false,
          usdcFee: "0",
          transferGasFee: "",
          alternativeMessage: "",
        });
        return;
      }
      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const wallet = new ethers.Wallet(pkey, provider);

      const web3 = new Web3(rpcEndpoint);

      let userCallDataArray: ExecuteCall[] = [];
      const txType = "prepaid";

      const tokenAddress = selectedNFT?.token_address;

      const contract = new web3.eth.Contract(
        (selectedNFT?.contract_type === "ERC1155"
          ? Erc1155ABI?.abi
          : Erc721ABI?.abi) as any,
        selectedNFT?.token_address
      );

      let tokenAmountToTransfer = ISNATIVE ? "1000000000" : "1000";

      let transferData: TransferData = {
        tokenAddress: tokenForPayment,
        tokenAmount: tokenAmountToTransfer,
      };

      // const counterfactual = await getCounterFactualAddress(
      //   EMPTY_CALLDATA,
      //   wallet.address,
      //   0,
      //   provider
      // );

      // For ERC-1155, safeTransferFrom has an additional parameter for the amount of tokens to transfer
      const call0 =
        selectedNFT?.contract_type === "ERC1155"
          ? contract.methods
              .safeTransferFrom(
                activeAccount.smartAccountAddress,
                to,
                selectedNFT?.token_id,
                1,
                "0x"
              )
              .encodeABI()
          : contract.methods
              .safeTransferFrom(
                activeAccount.smartAccountAddress,
                to,
                selectedNFT?.token_id
              )
              .encodeABI();

      userCallDataArray.push({
        to: tokenAddress,
        value: "0",
        calldata: call0,
      });

      if (ISNATIVE) {
        transferData = {
          tokenAddress: "0x",
          tokenAmount: tokenAmountToTransfer,
        };
      } else {
        transferData = {
          tokenAddress: tokenForPayment,
          tokenAmount: tokenAmountToTransfer,
        };
      }

      const isHighFees = feeGasUrgency == "Lightning";

      const { blockaid } =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

      const { data } = await axios.post(`${BASE_URL}/api/blockaid`, {
        options: ["simulation"],

        data: {
          from: activeAccount.smartAccountAddress,
          data: userCallDataArray[0].calldata,
          value: userCallDataArray[0].value,
          to: userCallDataArray[0].to,
        },
        metadata: { domain: "https://app.1inch.io" },
        network: blockaid,
      });

      console.log("DATAAAAAAAAAAa", data);

      if (data.simulation.error) {
        showAlert("This transaction might fail", "Warning");
      }

      const [counterfactual, { finalOp, usdcFee, transferGasFee }] =
        await Promise.all([
          getCounterFactualAddress(EMPTY_CALLDATA, wallet.address, 0, provider),
          txSubmissionOrderPrepaid({
            transferData,
            userCallDataArray,
            counterfactual: activeAccount.smartAccountAddress,
            wallet,
            transferTokenDecimal: tokenForPaymentDecimal,
            isHighFees,
            isAccountDeployed,
          }),
        ]);
      console.log(
        "file: index.tsx:483  newSendTransaction  counterfactual:",
        counterfactual,
        userCallDataArray
      );

      console.log("Clicked!");

      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      if (+usdcFee >= tokenForPaymentBalance) {
        showAlert(
          "You don't have enough token balance to pay fee, Please select different payment token.",
          "Not enough balance"
        );
        setError(true);
      }

      setFinalOpState(finalOp);
      setLoading(false);
      setGasFeeInUSD(usdcFee);
      setGasFee({ usdcFee, transferGasFee, isLoading: false });
    } catch (e: any) {
      console.log("file: index.tsx:674  newSendTransaction  e:", e);

      showAlert(e?.message ? e?.message : "Error in Submitting Transaction");
      setLoading(false);
      setGasFee({
        isLoading: false,
        usdcFee: "",
        transferGasFee: "",
        alternativeMessage: "",
      });
    }
  }

  async function newDepositSendTransaction() {
    try {
      setLoading(true);
      setError(false);
      if (!selectedDepositTokenAddress.trim()) {
        window.alert("Please provide all details");
        setGasFee({
          isLoading: false,
          usdcFee: "0",
          transferGasFee: "",
          alternativeMessage: "",
        });
        setLoading(false);
        return;
      }

      // This assumes the wallet has tokens in his wallet that can be deposited as gas.
      const allAccountsAddress = Object.keys(accounts);
      if (allAccountsAddress.length == 0) {
        return;
      }
      const firstAccountAddress =
        accounts[allAccountsAddress[0]].smartAccountAddress;

      // await switchAccount(switchToAccount);
      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      console.log(
        "file: index.tsx:448 newSendTransaction pkey:",
        pkey,
        activeAccount
      );
      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const wallet = new ethers.Wallet(pkey, provider);

      // First Wallet Account Address
      const firstAccount = accounts[allAccountsAddress[0]];
      const pkeyForFirstAccount = decryptMessage(
        firstAccount.secret,
        hashedPassword
      );
      const firstAccountWallet = new ethers.Wallet(
        pkeyForFirstAccount,
        provider
      );
      // const firstWallet = new ethers.Wallet()
      // 1. Get the user calldata array from the frontend.
      let userCallDataArray: ExecuteCall[] = [];
      const txType = "postpaid";

      const contract = new web3.eth.Contract(
        (selectedNFT?.contract_type === "ERC1155"
          ? Erc1155ABI?.abi
          : Erc721ABI?.abi) as any,
        selectedNFT?.token_address
      );

      // Assuming the user wants to call transfer on some token using postpaid transaction.
      const tokenAddress = selectedNFT?.token_address;

      const counterfactual = await getCounterFactualAddress(
        EMPTY_CALLDATA,
        wallet.address,
        0,
        provider
      );
      const call0 =
        selectedNFT?.contract_type === "ERC1155"
          ? contract.methods
              .safeTransferFrom(
                counterfactual,
                to,
                selectedNFT?.token_id,
                1,
                "0x"
              )
              .encodeABI()
          : contract.methods
              .safeTransferFrom(counterfactual, to, selectedNFT?.token_id)
              .encodeABI();

      userCallDataArray.push({
        to: tokenAddress,
        value: "0",
        calldata: call0,
      });

      console.log("userCallDataArray", userCallDataArray);

      const isHighFees = feeGasUrgency == "Lightning";

      const { finalOp, transferGasFee, usdcFee } =
        await txSubmissionOrderPostpaid({
          sponsorWallet: firstAccountWallet,
          currentWallet: wallet,
          sponsorAccountAddress: firstAccountAddress,
          counterfactual: activeAccount.smartAccountAddress,
          userCallDataArray: userCallDataArray,
          selectedDepositTokenAddress: selectedDepositTokenAddress,
          transferTokenDecimal: tokenForPaymentDecimal ?? 18,
          isHighFees,
          isAccountDeployed,
        });
      // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
      console.log("Clicked!");
      const depositedTokenDetails = depositableTokens.filter(
        (token) =>
          token.tokenAddress.toLowerCase() ==
          selectedDepositTokenAddress.toLowerCase()
      );
      if (+transferGasFee >= depositedTokenDetails[0].balance) {
        showAlert(
          "You don't have enough token balance to pay fee, Please select different payment token.",
          "Not enough balance"
        );
        setError(true);
      }
      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      setFinalOpState(finalOp);
      setGasFeeInUSD(usdcFee);
      setGasFee({ usdcFee, transferGasFee, isLoading: false });
      setLoading(false);

      // navigate("/nfts");
      // console.log("response :", response);
    } catch (e: any) {
      console.log("file: index.tsx:674  newSendTransaction  e:", e);

      showAlert("Not Enough Deposits to Continue");
      setError(true);
      setGasFee({
        isLoading: false,
        usdcFee: "",
        transferGasFee: "",
        alternativeMessage: "",
      });
      setLoading(false);
    }
  }

  const handleSelectTokenForPaymentWithDecimal = ({
    address,
    decimal,
    tokenBalance,
  }: {
    address: string;
    decimal: number;
    tokenBalance?: number;
  }) => {
    setError(false);
    SetTokenForPayment(address);
    setTokenForPaymentDecimal(decimal);
    setTokenForPaymentBalance(tokenBalance || 0);
  };

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: string
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );
    console.log(
      "file: index.tsx:236  Send depositableTokens paymasterContract:"
    );
    // Create an object to keep track of cumulative balances for each token

    try {
      const accountHolding = await paymasterContract.methods
        .balances(tokenAddress, address)
        .call();
      console.log(
        "file: index.tsx:241 depositableTokens  Send  accountHolding:",
        accountHolding,
        tokenAddress
      );
      return Number(accountHolding);
    } catch (error) {
      console.error("Error reading depositableTokens account holdings:", error);
      return 0; // Handle the error gracefully
    }
  };

  async function fetchBalances() {
    const allAccountsAddress = Object.keys(accounts);

    const firstAccountAddress =
      accounts[allAccountsAddress[0]].smartAccountAddress;
    console.log(
      "file: index.tsx:256  fetchBalances depositableTokens  firstAccountAddress:",
      firstAccountAddress
    );

    let depositedTokenObject: Array<any> = [];
    for (const tokenInfo of tokensForGasObject[activeNetwork]) {
      const { tokenAddress, rpc } = tokenInfo;
      console.log(
        "file: index.tsx:265  fetchBalances depositableTokens  tokenAddress:",
        tokenAddress
      );
      const balance = await readAccountHoldings(
        tokenAddress,
        firstAccountAddress,
        rpc
      );
      console.log(
        "file: index.tsx:271 depositableTokens fetchBalances  balance:",
        balance
      );

      if (balance > 0) {
        depositedTokenObject.push({
          ...tokenInfo,
          balance: balance / 10 ** tokenInfo.decimal,
        });
      }
    }
    setDepositableTokens(depositedTokenObject);
  }
  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    console.log(
      "file: index.tsx:271 depositableTokens fetchBalances  balance:"
    );

    fetchBalances();
  }, [accounts, activeAccount, activeNetwork]);

  const handleDepositTokenChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    setSelectedDepositTokenAddress(event.target.value as string);
  };

  const onTop20Select = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(false);
    setFinalOpState(null);
    SetTokenForPayment("");
    setSelectedDepositTokenAddress("");
  };

  const onDepositSelect = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(true);
    setFinalOpState(null);
    SetTokenForPayment("");
    setSelectedDepositTokenAddress("");
  };

  return (
    <>
      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <ModalHeader
            title="Select asset"
            onClose={() => setOpenModal(false)}
            showBackIcon
          />
          <AssetsView tokens={tokens} selectTokenHandler={selectTokenHandler} />
        </>
      </BasicModal>
      <Box mt={6}>
        <NavigatorHeading
          title="Send NFT"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/nfts");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={
            currentNFT
              ? ["Recipient", "Send"]
              : ["Network", "Recipient", "Choose NFT", "Send"]
          }
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>
      <Box mt={5}>
        {!currentNFT && step == 0 && (
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={6} sm={12}>
              <NetworksList
                nextStep={() => setStep(1)}
                title="Select which network you want to send your NFT on"
              />
            </Grid>
          </Grid>
        )}
        {!currentNFT && step == 1 && (
          <Box display={"flex"} flexDirection={"column"}>
            <Grid container display="flex" justifyContent="center">
              <Grid item lg={12} sm={12}>
                <TransactionContactList
                  shouldAddContact={false}
                  isTxForm
                  isChooseRecipient={true}
                  nextStep={(address: string, tag: string) => {
                    setStep(2);
                    setTo(address);
                    setTag(tag);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {!currentNFT && step == 2 && (
          <Grid container display="flex" justifyContent="center" py={"10px"}>
            <Grid item lg={12} sm={12}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <SearchInput
                  handleOnChange={(e: any) => {
                    setSearchText(e.target.value);
                  }}
                  placeholder="Search NFT"
                />
              </Box>
              {/* MAP THROUGH COLLECTIONS OBJECT AND RENDER NFT */}

              {Object.keys(collections).length > 0 ? (
                Object.keys(collections).map((key, i) => (
                  <Box key={i}>
                    <Typography
                      fontFamily={"Space Grotesk"}
                      fontSize={"16px"}
                      fontWeight={"700"}
                      color={"rgba(26, 28, 32, 1)"}
                      pt={"30px"}
                      pb={"20px"}
                    >
                      {key}
                      <span>
                        <img
                          src={BlueTick}
                          style={{ marginLeft: "5px", marginBottom: "-2px" }}
                          alt={"blue tick"}
                        />
                      </span>
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      flexDirection={"row"}
                      sx={{
                        gap: "8.5px",
                      }}
                    >
                      {collections[key].map((nft: any, index: any) => (
                        <Box
                          sx={{
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 10px rgba(24, 39, 75, 0.08)",
                            // maxHeight: "175px",
                            // minHeight: "175px",
                            // maxWidth: "140px",
                            // minWidth: "140px",
                            cursor: "pointer",
                            borderColor:
                              selectedNFT?.token_id === nft?.token_id
                                ? "rgba(26, 28, 32, 1)"
                                : "rgba(255, 255, 255, 1)",
                            borderWidth: "0.5px",
                            borderStyle: "solid",
                          }}
                          onClick={() => {
                            //if nft is already selected, clicking on it again will unselect it
                            if (selectedNFT?.token_id === nft?.token_id) {
                              setSelectedNFT(null);
                            } else {
                              setSelectedNFT(nft);
                            }
                          }}
                        >
                          <NFTCard
                            name={nft?.name || nft?.metadata?.name}
                            imageSrc={
                              nft?.metadata?.image ??
                              "https://thientu.vn/userfiles/files/nyan-cat-game%204.gif"
                            }
                            nftId={nft.token_id}
                            chainName={nft.blockchain}
                          />
                        </Box>
                        // <Box
                        //   sx={{
                        //     backgroundColor: "rgba(255, 255, 255, 1)",
                        //     borderRadius: "8px",
                        //     boxShadow: "0px 4px 10px rgba(24, 39, 75, 0.08)",
                        //     maxHeight: "175px",
                        //     minHeight: "175px",
                        //     maxWidth: "140px",
                        //     minWidth: "140px",
                        //     cursor: "pointer",
                        //     borderColor:
                        //       selectedNFT?.token_id === nft?.token_id
                        //         ? "rgba(26, 28, 32, 1)"
                        //         : "rgba(255, 255, 255, 1)",
                        //     borderWidth: "0.5px",
                        //     borderStyle: "solid",
                        //   }}
                        //   onClick={() => {
                        //     //if nft is already selected, clicking on it again will unselect it
                        //     if (selectedNFT?.token_id === nft?.token_id) {
                        //       setSelectedNFT(null);
                        //     } else {
                        //       setSelectedNFT(nft);
                        //     }
                        //   }}
                        // >
                        //   <img
                        //     src={nft?.metadata?.image}
                        //     alt={nft?.name}
                        //     height={"125px"}
                        //     width={"140px"}
                        //     style={{
                        //       borderTopLeftRadius: "8px",
                        //       borderTopRightRadius: "8px",
                        //     }}
                        //     onError={(e) => {
                        //       e.currentTarget.src = NoImg;
                        //     }}
                        //   />
                        //   <Typography
                        //     fontFamily={"Space Grotesk"}
                        //     fontSize={"12.04px"}
                        //     fontWeight={"700"}
                        //     color={"rgba(26, 28, 32, 0.5)"}
                        //     padding={"8px"}
                        //   >
                        //     {nft?.metadata?.name}
                        //     <img
                        //       src={BlueTick}
                        //       style={{
                        //         marginLeft: "5px",
                        //         marginBottom: "-2px",
                        //         height: "8px",
                        //         width: "8px",
                        //       }}
                        //       alt={"blue tick"}
                        //     />
                        //   </Typography>

                        //   <Typography
                        //     fontFamily={"Helvetica Neue"}
                        //     fontSize={"9.9px"}
                        //     fontWeight={"700"}
                        //     color={"rgba(26, 28, 32, 1)"}
                        //     px={"8px"}
                        //     pt={"0px"}
                        //     pb={"10px"}
                        //   >
                        //     #{trimTokenAddress(nft?.token_id)}
                        //   </Typography>
                        // </Box>
                      ))}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box textAlign={"center"} py={5}>
                  <Typography variant="h3">
                    No NFTs found on selected chain
                  </Typography>
                </Box>
              )}
            </Grid>
            {
              // Conditional rendering of the Continue button
              selectedNFT && (
                <div className={`continue-button ${selectedNFT ? "show" : ""}`}>
                  <BasicButton
                    title={"Continue"}
                    onClick={() => {
                      setStep(3);
                    }}
                    loading={loading}
                    mode={"active"}
                    id="send"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              )
            }
          </Grid>
        )}
        {!currentNFT && step == 3 && (
          <Grid container display="flex" justifyContent="center" py={4}>
            <Grid item lg={6} sm={12}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={8}
                alignItems={"center"}
              >
                <Box
                  width={"100%"}
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ReviewAssetListComponent
                    isNFTComponent={true}
                    selectedNFT={selectedNFT}
                  />
                  <Box
                    component={"img"}
                    src={MidArrow}
                    width={"33px"}
                    height={"33px"}
                    mt={"-8px"}
                    position={"relative"}
                  />
                  <ReviewAssetListComponent
                    isNFTComponent={false}
                    recipient={tag ? tag : to}
                  />
                </Box>

                <FeeUrgencyComponent
                  feeGasUrgency={feeGasUrgency}
                  setFeeGasUrgency={setFeeGasUrgency}
                />
                <TxTypeSwitch
                  loading={loading}
                  txByDeposited={txByDeposited}
                  onTop20Select={onTop20Select}
                  onDepositSelect={onDepositSelect}
                />
                <SendTxComponent
                  loading={loading}
                  // SetTokenForPayment={SetTokenForPayment}
                  gasTokenSelect={
                    <GasTokenSelect
                      selectedDepositTokenAddress={selectedDepositTokenAddress}
                      handleDepositTokenChange={handleDepositTokenChange}
                    />
                  }
                  top20TokenSelect={
                    <TopTokenSelectComponent
                      handleSelectToken={handleSelectTokenForPayment}
                      handleSelectTokenWithDecimal={
                        handleSelectTokenForPaymentWithDecimal
                      }
                      paymentTokenAddress={tokenForPayment}
                      sameTokens={sameTokens}
                    />
                  }
                  txByDeposited={txByDeposited}
                  handleSend={executeSendCrypto}
                  finalOpState={finalOpState}
                  error={error}
                  isSwap={false}
                >
                  <FeeUIComponent gasFeeInUSD={gasFeeInUSD} />
                </SendTxComponent>
              </Box>
            </Grid>
          </Grid>
        )}

        {currentNFT && step == 0 && (
          <Box display={"flex"} flexDirection={"column"}>
            <Grid container display="flex" justifyContent="center">
              <Grid item lg={12} sm={12}>
                <TransactionContactList
                  shouldAddContact={false}
                  isTxForm
                  isChooseRecipient={true}
                  nextStep={(address: string, tag: string) => {
                    setStep(1);
                    setTo(address);
                    setTag(tag);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {currentNFT && step == 1 && (
          <Grid container display="flex" justifyContent="center" py={4}>
            <Grid item lg={6} sm={12}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={8}
                alignItems={"center"}
              >
                <Box
                  width={"100%"}
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ReviewAssetListComponent
                    isNFTComponent={true}
                    selectedNFT={selectedNFT}
                  />
                  <Box
                    component={"img"}
                    src={MidArrow}
                    width={"33px"}
                    height={"33px"}
                    mt={"-8px"}
                    position={"relative"}
                  />
                  <ReviewAssetListComponent
                    isNFTComponent={false}
                    recipient={tag ? tag : to}
                  />
                </Box>
                <FeeUrgencyComponent
                  feeGasUrgency={feeGasUrgency}
                  setFeeGasUrgency={setFeeGasUrgency}
                />
                <TxTypeSwitch
                  loading={loading}
                  txByDeposited={txByDeposited}
                  onTop20Select={onTop20Select}
                  onDepositSelect={onDepositSelect}
                />
                <SendTxComponent
                  loading={loading}
                  // SetTokenForPayment={SetTokenForPayment}
                  gasTokenSelect={
                    <GasTokenSelect
                      selectedDepositTokenAddress={selectedDepositTokenAddress}
                      handleDepositTokenChange={handleDepositTokenChange}
                    />
                  }
                  top20TokenSelect={
                    <TopTokenSelectComponent
                      handleSelectToken={handleSelectTokenForPayment}
                      handleSelectTokenWithDecimal={
                        handleSelectTokenForPaymentWithDecimal
                      }
                      paymentTokenAddress={tokenForPayment}
                      sameTokens={sameTokens}
                    />
                  }
                  txByDeposited={txByDeposited}
                  handleSend={executeSendCrypto}
                  finalOpState={finalOpState}
                  error={error}
                >
                  <FeeUIComponent gasFeeInUSD={gasFeeInUSD} />
                </SendTxComponent>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default SendNFT;
