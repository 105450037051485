import { Box, Grid, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";

import InfoCard from "components/InfoCard";
import ChipButton from "components/ChipButton";
import DepositIcon from "assets/deposit.svg";
import SendIcon from "assets/send-icon.svg";
import NFTListTable from "./NFTListTable";
import AccountsBox from "../../../components/AccountsBox";
import { useAppSelector } from "../../../store/store";
import { useNavigate } from "react-router-dom";

import NFTCard from "assets/nftCard.svg";
import ExpandableChart from "components/ExpandableChart";

import Web3 from "web3";
import paymasterAbi from "abis/paymasterabi.json";
import { tokensForGas } from "constants/topTokensConf";
import { provider } from "web3-core";
import { Paymaster_Token_Address } from "../../../contract-integration/constants";
import { ethers } from "ethers";
import { getAssetsDollarWorth } from "utils/portfolio";
import { showAlert } from "utils/utils";

const NFTView: FC<{ accountName: string }> = ({ accountName }) => {
  const { activeAccount, newNFTs } = useAppSelector((state) => state.app);
  const theme = useTheme();
  const navigate = useNavigate();
  const allNfts = newNFTs[activeAccount.smartAccountAddress];

  const currentUserNFTsAmount =
    newNFTs[activeAccount?.smartAccountAddress]?.length ?? 0;

  const [cummulativeGas, setCummulativeGas] = useState(0);
  console.log("🚀 ~ file: View.tsx:33 ~ cummulativeGas:", cummulativeGas);
  const { accounts } = useAppSelector((state) => state.app);
  const activeAccountAddress = Object.values(accounts)[0].smartAccountAddress;

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: provider,
    decimal: number
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );

    try {
      const accountHolding = await paymasterContract.methods
        .balances(tokenAddress, address)
        .call();
      return Number(ethers.utils.formatUnits(String(accountHolding), decimal));
    } catch (error) {
      console.error("Error reading account holdings:", error);
      return 0; // Handle the error gracefully
    }
  };

  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    async function fetchBalances() {
      let tokenAddressesForMobula = tokensForGas.map(
        (tokenInfo) => tokenInfo.tokenAddressForMobula
      );

      // Call the getAssetsDollarWorth function with the tokenAddressesForMobula array as the argument
      const tokenData = await getAssetsDollarWorth(tokenAddressesForMobula);

      // Extract the prices from the returned object
      let tokenWorthInUSD = Object.values(tokenData).map(
        (token) => token.price
      );

      // Now you can use the tokenWorthInUSD array in your loop
      for (let i = 0; i < tokensForGas.length; i++) {
        const tokenInfo = tokensForGas[i];
        const { tokenAddress, rpc, decimal } = tokenInfo;
        const balance = await readAccountHoldings(
          tokenAddress,
          activeAccountAddress,
          rpc,
          decimal
        );

        const tokenWorthInUSDForCurrentToken = tokenWorthInUSD[i];
        const balanceInUSD = balance * tokenWorthInUSDForCurrentToken;

        // Add the balance to the cumulative balance for the token
        if (!cumulativeBalances[tokenAddress]) {
          cumulativeBalances[tokenAddress] = balanceInUSD;
        } else {
          cumulativeBalances[tokenAddress] += balanceInUSD;
        }
      }
      // Calculate the total cumulative balance
      let totalBalance = 0;
      for (const tokenAddress of Object.keys(cumulativeBalances)) {
        totalBalance += cumulativeBalances[tokenAddress];
      }

      setCummulativeGas(totalBalance);
    }

    fetchBalances();
  }, [accounts]);

  return (
    <Box width={"100%"}>
      <Box
        className="flex-row-center"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          gap: "26px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        {/* User Info & Balance Area */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          <InfoCard
            backgroundImage={`url(${NFTCard})`}
            backgroundPosition="0px"
            backgroundSize="cover"
            title={`@${activeAccount?.accountName.toLowerCase()}`}
            gas={String(cummulativeGas > 0 ? cummulativeGas.toFixed(4) : 0)}
            amount={
              <>
                <Typography
                  variant={"h5"}
                  fontWeight={700}
                  color="rgba(26, 28, 32, 1)"
                  fontSize={45}
                  style={{ cursor: "pointer" }}
                  align="center"
                >
                  {currentUserNFTsAmount}
                </Typography>
                <Typography
                  variant={"h5"}
                  fontWeight={700}
                  color="rgba(26, 28, 32, 0.5)"
                  fontSize={25}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  align="center"
                >
                  NFTs
                </Typography>
              </>
            }
            action={<AccountsBox />}
          />
        </Box>

        {/* Receive Cash Area */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          <ExpandableChart
            chartData={[]}
            chartTimestamps={[]}
            title={`lorem ipsum`}
            amount={"0"}
            percentageChange="5"
            isNFTScreen={true}
          />
        </Box>
      </Box>

      <Box mt={2.5}>
        <Box
          className="flex-row-center"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "flex-start",
            flexFlow: "wrap",
            paddingBottom: "24px",
          }}
        >
          <ChipButton
            title="Receive"
            onClick={() => {
              navigate("/nfts/receive");
            }}
            icon={DepositIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />

          <ChipButton
            title="Send"
            onClick={() => {
              allNfts?.length > 0
                ? navigate("/nfts/send")
                : showAlert("You don't have any NFTs to send", "Oops!");
            }}
            icon={SendIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />
        </Box>
        {/* <Banner /> */}

        {/* <TokensTable /> */}
        <Grid paddingTop={2}>
          <NFTListTable />
        </Grid>
      </Box>
    </Box>
  );
};

export default NFTView;
