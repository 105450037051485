import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Web3Wallet } from "@walletconnect/web3wallet";
import { formatJsonRpcError, formatJsonRpcResult } from "@json-rpc-tools/utils";

import ConnectedDappBox from "components/ConnectedDappBox";
import Receive from "components/Receive";
import Send from "components/Send";
import { SearchBox } from "components/Styled";
import WalletConnectPage from "components/Walletconnect";
import { useAppSelector } from "store/store";
import BasicButton from "components/Button";
import SearchIcon from "assets/SearchInputIcon.svg";
import { Core } from "@walletconnect/core";
import { getSdkError } from "@walletconnect/utils";
import { signClient } from "../../../utils/WalletConnectUtil";
import SignClient from "@walletconnect/sign-client";
import SettingsStore from "../../../walletConnectStore/SettingsStore";
import { useSnapshot } from "valtio";
import ModalStore from "../../../walletConnectStore/ModalStore";

const abiDecoder = require("abi-decoder"); // NodeJS

const DappConnection = () => {
  const [activeTab, setActiveTab] = useState("send");
  const { open, view } = useSnapshot(ModalStore.state);
  const { connectedDapps } = useAppSelector((state) => state.app);
  const [pairings, setPairings] = useState<any[]>([]);

  const { relayerRegionURL } = useSnapshot(SettingsStore.state);
  const [dapps, setDapps] = useState<any[]>([]);
  const [value, setValue] = useState("");

  const [filteredDapps, setFilteredDapps] = useState<any[]>([]);

  async function onDelete(topic: string, expiry: number) {
    const core = new Core({
      projectId: "02bca0404e214292df5b3fd124b82bca",
    });

    const web3wallet = await Web3Wallet.init({
      core, // <- pass the shared `core` instance
      metadata: {
        name: "Demo app",
        description: "Demo Client as Wallet/Peer",
        url: "www.walletconnect.com",
        icons: [],
      },
    });

    await web3wallet.disconnectSession({
      topic,
      reason: getSdkError("USER_DISCONNECTED"),
    });

    const newPairings = pairings.filter((pairing) => pairing?.topic !== topic);

    setPairings(newPairings);
  }

  useEffect(() => {
    (async () => {
      const core = new Core({
        projectId: "02bca0404e214292df5b3fd124b82bca",
      });

      const web3wallet = await Web3Wallet.init({
        core, // <- pass the shared `core` instance
        metadata: {
          name: "Demo app",
          description: "Demo Client as Wallet/Peer",
          url: "www.walletconnect.com",
          icons: [],
        },
      });
      let pairings = web3wallet.core.pairing.getPairings();
      // New logic starts here
      // First, we sort by expiry so that the latest comes last in 'pairings' array
      pairings.sort(
        (a: { expiry: number }, b: { expiry: number }) => b.expiry - a.expiry
      );

      // Object to keep track of names we've seen
      const seen: any = {};

      // Filter pairings, removing earlier instances of duplicates
      const filteredPairings = pairings.filter((pairing: any) => {
        if (!pairing || !pairing.peerMetadata) {
          // Skip this iteration if pairing or pairing.peerMetadata is undefined
          return false;
        }

        const duplicate = seen.hasOwnProperty(pairing.peerMetadata.name);
        seen[pairing.peerMetadata.name] = true; // Mark this name as "seen"
        return !duplicate; // If it's a duplicate, this will be false and filter it out
      });

      setPairings(filteredPairings);
      // setPairings(pairings);
    })();
  }, [view]);
  // Effect for initializing dapps
  useEffect(() => {
    if (connectedDapps.length > 0) {
      // setDapps(connectedDapps);
      setFilteredDapps(pairings); // Initialize with the full list
    }
  }, [pairings]);

  useEffect(() => {
    // Filter dapps list based on value
    const searchResults = pairings.filter((dapp) =>
      dapp?.peerMetadata?.name?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDapps(searchResults);
  }, [value, pairings, connectedDapps]);

  return (
    <div className="main-view2">
      <Box
        sx={{
          paddingTop: "20px",
          paddingBottom: "20px",
          paddingLeft: "60px",
          display: "flex",
          justifyContent: "space-between",

          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          color="#1A1C20"
          fontSize="25px"
          fontWeight={700}
          fontFamily={"Space Grotesk"}
        >
          Applications
        </Typography>
        <SearchBox
          placeholder="Search by name or category"
          onChange={(e: { target: { value: string } }) => {
            setValue(e.target.value.trim());
          }}
          value={value}
          startAdornment={
            <Grid
              sx={{
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={SearchIcon}
                style={{
                  // background: "red",
                  height: "18px",
                  width: "18px",
                }}
              />
            </Grid>
          }
        />
      </Box>
      {/* {activeTab === "send" ? <Send /> : <Receive />} */}
      {/* <WalletConnectPage /> */}
      <Grid container spacing={2} sx={{ padding: 5 }}>
        <Grid item xs={12} md={6} lg={4}>
          <ConnectedDappBox />
        </Grid>

        {filteredDapps.length > 0 &&
          filteredDapps.map((dapp) => (
            <Grid item xs={12} md={6} lg={4}>
              <ConnectedDappBox
                name={dapp?.peerMetadata?.name}
                image={
                  dapp?.peerMetadata?.icons[1] || dapp?.peerMetadata?.icons[0]
                }
                description={dapp?.peerMetadata?.description}
                onDisconnect={() => onDelete(dapp?.topic, dapp.expiry)}
              />
            </Grid>
          ))}
      </Grid>
      {/* <ConnectedDappBox /> */}
    </div>
  );
};

export default DappConnection;

// import React from "react";
// import { createWeb3Modal, useWeb3Modal } from "@web3modal/ethers5/react";

// const DappConnection = () => {
//   const projectId = "02bca0404e214292df5b3fd124b82bca"; // Replace with your project ID
//   const chains = [1, 42161];

//   const ethersConfig = {
//     metadata: {
//       name: "Web3Modal",
//       description: "Web3Modal Laboratory",
//       url: "https://web3modal.com",
//       icons: ["https://avatars.githubusercontent.com/u/37784886"],
//     },
//     defaultChainId: 1,
//     rpcUrl: "https://cloudflare-eth.com",
//   };

//   const modal = createWeb3Modal({
//     ethersConfig,
//     chains,
//     projectId,
//     enableAnalytics: true,
//   });

//   const handleGenerateSession = () => {
//     modal.open();
//   };

//   return (
//     <div>
//       <h1>WalletConnect Integration</h1>
//       <button onClick={handleGenerateSession}>
//         Generate WalletConnect Session
//       </button>
//     </div>
//   );
// };

// export default DappConnection;
