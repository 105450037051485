import React, { useState } from "react";
import WelcomeBox from "components/WelcomeBox";

import Google from "assets/google.svg";
import Discord from "assets/discord.svg";
import TrustWallet from "assets/trust-wallet.svg";
import Metamask from "assets/metamask.svg";
import StashedGlobe from "assets/stashed-globe.svg";

import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import AuthHeader from "components/AuthHeader";
import useGoogleWeb3Auth from "hooks/useGoogleWeb3Auth";
import { loginWithGoogle } from "utils/google";
import { useAppDispatch } from "store/store";
import { setAccesToken, setMode, setUserInfo } from "@slices/appSlice";
import { setTorusKey } from "@slices/walletSlice";
import { isUserExists, showAlert } from "utils/utils";
import useTwitterWeb3Auth from "hooks/useTwitterWeb3Auth";
import { loginWithTwitter } from "utils/twitter";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";

const CreateAccount = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { web3auth } = useGoogleWeb3Auth();
  const { twitterWeb3auth } = useTwitterWeb3Auth();

  const [step, setStep] = useState(0);

  const signupWithGoogle = async () => {
    try {
      if (web3auth) {
        setLoading(true);

        const { email, name, profileImage, address, idToken } =
          await loginWithGoogle(web3auth);

        if (email) {
          const userData = await isUserExists(email, "a", "a");

          if (userData) {
            navigate("/signin");
            showAlert(
              "Account already exists. Try with a different Google Account."
            );

            await web3auth.logout();

            setLoading(false);
          } else {
            dispatch(setMode("google"));
            dispatch(setTorusKey(address[0].toString("hex")));
            dispatch(setUserInfo({ name, email, profileImage: profileImage }));
            dispatch(setAccesToken(idToken));
            setLoading(false);
            navigate("/create-password", {
              state: { currentStep: step + 1, pathName: "/create-account" },
            });
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert("Signup Failed!");
    }
  };

  const signupWithTwitter = async () => {
    try {
      if (twitterWeb3auth) {
        setLoading(true);

        // const { email, name, profileImage, address, idToken } =
        await loginWithTwitter(twitterWeb3auth);

        // if (email) {
        //   const userData = await isUserExists(email, "a", "a");

        //   if (userData) {
        //     showAlert(
        //       "Account already exists. Try with a different Google Account."
        //     );

        //     await twitterWeb3auth.logout();

        //     setLoading(false);
        //   } else {
        //     dispatch(setMode("google"));
        //     dispatch(setTorusKey(address[0].toString("hex")));
        //     dispatch(setUserInfo({ name, email, profileImage: profileImage }));
        //     dispatch(setAccesToken(idToken));
        //     setLoading(false);
        //     navigate("/create-password");
        //   }
        // }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert("Signup Failed!");
    }
  };

  const totalSteps = stepsCount["/create-account"] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>

      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Create Account
        </Typography>

        {/* <div className='welcome-box-container'>
          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color='inherit' />
            </div>
          ) : (
            <div className='box-main'>
              <div className='box top-box'>
                <WelcomeBox
                  title='Continue with Google'
                  description=''
                  onClick={signupWithGoogle}
                  icon={<img src={Google} style={{ marginRight: 10 }} />}
                />
              </div>

              <div className='box top-box'>
                <WelcomeBox
                  title='Continue with Discord'
                  description=''
                  onClick={signupWithDiscord}
                  icon={<img src={Discord} style={{ marginRight: 10 }} />}
                />
              </div>
            </div>
          )}
        </div> */}
        <div
          className="welcome-box-container"
          style={{
            zIndex: 999,
          }}
        >
          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <div className="box-main">
              <div className="box top-box">
                <WelcomeBox
                  title="Continue with Google"
                  description=""
                  onClick={signupWithGoogle}
                  icon={<img src={Google} style={{ marginRight: 10 }} />}
                />
              </div>
              <div className="box bottom-box">
                <WelcomeBox
                  title="Continue with Twitter"
                  description=""
                  onClick={signupWithTwitter}
                  icon={<img src={Discord} style={{ marginRight: 10 }} />}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="stashed-globe">
        <img src={StashedGlobe} />
      </div>
    </div>
  );
};

export default CreateAccount;
