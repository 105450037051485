import Swal from "sweetalert2";
import millify from "millify";
import Web3 from "web3";
import axios from "axios";
import crypto from "crypto-js";
import { hdkey } from "ethereumjs-wallet";
import * as Bip39 from "bip39";
import {
  BASE_URL,
  EVM_DERIVATION_PATH,
  MOBULA_API,
  NATIVE_ADDRESS,
} from "constants/";
import { ethers } from "ethers";
import { Accounts } from "interfaces";
import {
  SupportedChainId,
  SUPPORTED_NETWORKS,
  CASH_SUPPORTED_NETWORK,
} from "constants/chains";
import BN from "bn.js";
import { getCounterFactualAddress } from "../contract-integration";

const { abi } = require("abis/encryptedContractAbi.json");

export const saveTransaction = async (
  address: string,
  eoa: string,
  hash: string,
  network: SupportedChainId,
  amount: number,
  signature: string,
  value: string,
  valueIn$: string,
  transferAmount: string,
  transactionMethod: string,
  scanLink: string,
  eoaEns: string,
  addressEns: string,
  toAddressEns: string,
  toAddress: string,
  assetName: string,
  networkFeesIn$: string,
  iconURL: string,
  paidWithGasTank: number,
  action: string
) => {
  console.log("🚀 ~ file: utils.ts:48 ~ valueIn:", valueIn$);
  try {
    const { data } = await axios.post(
      `${BASE_URL}/transaction/createTransaction`,
      {
        address,
        eoa,
        hash,
        network,
        amount,
        signature,
        value,
        valueIn$,
        transferAmount,
        transactionMethod,
        scanLink,
        eoaEns,
        addressEns,
        toAddressEns: "",
        toAddress,
        assetName,
        networkFeesIn$,
        iconURL,
        paidWithGasTank,
        action,
      }
    );
    return data;
  } catch (error) {
    throw error?.response?.data?.msg || error;
  }
};

export const getAllTransactions = async (address: string) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/transaction/${address.toLowerCase()}`
    );
    const baseURL = `${MOBULA_API}/wallet/transactions`;
    const fetchURL = `${baseURL}?wallet=${address}&blockchains=Ethereum%2CPolygon&order=desc`;
    const { data: data1 } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });

    const mobulaTxs = data1?.data?.transactions;

    const transformedMobulaTxs = await Promise.all(
      mobulaTxs.map(async (tx) => {
        let ens;
        if (tx.to) {
          ens = await getEnsFromAddress(tx.to);
        } else {
          ens = "";
        }
        return {
          action:
            address.toLowerCase() === tx.from.toLowerCase()
              ? "Sent"
              : "Received",
          address: tx.from,
          addressEns: tx.from,
          amount: tx.amount,
          assetName: tx.asset.name,
          distributionActive: false,
          eoa: tx.from,
          eoaEns: tx.from,
          hash: tx.hash,
          iconURL: tx.asset.logo,
          network: tx.blockchain.toLowerCase() === "polygon" ? "137" : "1",
          networkFeesIn$: "0",
          paidWithGasTank: 0,
          referedByUserAddress: "",
          referedByUserReward: 0,
          referedToUserCashback: 0,
          scanLink: `https://${
            tx.blockchain.toLowerCase() === "polygon"
              ? "polygonscan.com"
              : "etherscan.io"
          }/tx/${tx.hash}`,
          stashedFees: 0,
          timestamp: tx.timestamp,
          toAddress: tx.to,
          toAddressEns: ens || "",
          transactionMethod: "Crypto",
          transferAmount: tx.amount,
          userReferalActive: true,
          value: tx.amount,
          valueIn$: tx.amount_usd,
          _id: "",
        };
      })
    );

    const allTxs = [...data.transactions, ...transformedMobulaTxs];

    const filteredTxs = allTxs
      .filter(
        (obj, index, self) =>
          index === self.findIndex((item) => item["hash"] === obj["hash"])
      )
      .sort((a, b) => a.timestamp - b.timestamp);

    console.log("ALLLLLLLLLLLL", allTxs);
    console.log("FILTERED", filteredTxs);
    return filteredTxs;
  } catch (error) {
    throw error?.response?.data?.msg || error;
  }
};

export const getReferralReward = async (address: string) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/transaction/getAccumalateReward/${address}`
    );
    return data;
  } catch (error) {
    throw error?.response?.data?.msg || error;
  }
};

export const verifyReferralCode = async (code: string) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/security-question/verifyReferalCode/${code}`
    );
    return data;
  } catch (error) {
    throw error?.response?.data?.msg || error;
  }
};

export const getSmartAccountAddress = async (
  activeNetwork: SupportedChainId,
  eoaAddress: string
) => {
  console.log("file: utils.ts:104  eoaAddress:", activeNetwork, eoaAddress);
  const { rpc } = SUPPORTED_NETWORKS[activeNetwork];
  const provider = new ethers.providers.JsonRpcProvider(rpc);
  const counterfactual = await getCounterFactualAddress(
    "0x",
    eoaAddress,
    0,
    provider
  );

  return counterfactual;
};

export const updateEncryptedKeysAndEmailBySignature = async (
  email: string,
  username: string,
  key1: string,
  key2: string,
  key3: string,
  key4: string,
  signature: string,
  address: string,
  accessToken: string
) => {
  try {
    const { data } = await axios.put(
      `${BASE_URL}/security-question/updateEmailbySignature`,
      {
        email,
        username,
        keys: {
          key1,
          key2,
          key3,
          key4,
        },

        signature,
        address,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateEncryptedKeys = async (
  email: string,
  key1: string,
  key2: string,
  key3: string,
  key4: string,
  accessToken: string
) => {
  try {
    const { data } = await axios.put(
      `${BASE_URL}/security-question/updateEncryptionKeysByEmail`,
      {
        email,
        keys: {
          key1,
          key2,
          key3,
          key4,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchEncryptedKeysByName = async (name: string) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/security-question/getEncryption/${name}`
    );
    return data;
  } catch (error) {
    console.log("err", error.message);
    throw error;
  }
};

export const fetchEncryptedKeys = async (accessToken: string) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/security-question/getEncryption`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log("err", error.message);
    throw error;
  }
};

export const isUserExists = async (
  email: string,
  username: string,
  address: string
) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/security-question/userExist/${email || "a"}/${
        username || "a"
      }/${address || "a"}`
    );
    return data;
  } catch (error) {
    console.log("err", error.message);
    throw error;
  }
};

export const fetchWalletContacts = async (addresses: string) => {
  try {
    console.log("addresses for contacts list from api", addresses);
    const { data } = await axios.get(
      `${BASE_URL}/security-question/getUsers?addresses=${addresses}`
    );
    console.log("data for contacts list from api", data);
    return data;
  } catch (error) {
    console.log("error", error);
  }
};

export const toUniqueArrayOfContacts = (array: any) => {
  return array
    .reverse()
    .reduce((accumulator: any, currentValue: any) => {
      if (
        !accumulator.find(
          (obj: any) =>
            obj.address.toLowerCase() === currentValue.address.toLowerCase()
        )
      ) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, [])
    .reverse();
};

export const saveEncryptedKeys = async (
  accessToken: string,
  email: string,
  username: string,
  address: string,
  key1: string,
  key2: string,
  key3: string,
  key4: string,
  referralCode: string
) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/security-question/addEncryptionKeys`,
      {
        username,
        email,

        address,
        keys: [
          {
            key1,
            key2,
            key3,
            key4,
          },
        ],
        referalCode: referralCode || "",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveEns = async (
  name: string,
  address: string,
  signature: string
) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/ens/addEns`, {
      name,
      address,
      signature,
    });
    return data;
  } catch (error) {
    console.log("err in saveens", error.message);
    throw error;
  }
};

export const saveSubEns = async (
  name: string,
  address: string,
  rootAddress: string,
  signature: string
) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/ens/addSubdomain`, {
      name,
      address,
      rootAddress,
      signature,
    });
    return data;
  } catch (error) {
    console.log("err in saveens", error.message);
    throw error;
  }
};

export const addUser = async (address: string) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/security-question/addUser`, {
      address: address.toLowerCase(),
      fcmToken: "",
    });
    return data;
  } catch (error) {
    console.log("err in add user", address);
    throw error;
  }
};

export const isEnsExist = async (name: string) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/ens/getAddressFromENS/${name}`
    );
    // console.log("data", data);
    return data && data?.address;
  } catch (error) {
    console.log("err", error.message);
    throw error;
  }
};

export const fetchAddressFromEns = async (ens: string) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/ens/getEnsFromAddressOrName/${ens}`
    );
    console.log("data", data);
    if (data) {
      const isSubDomain = ens.includes(".") ? ens.split(".")[0] : false;

      const address = isSubDomain
        ? data.subDomains.filter((sd) => sd.name === isSubDomain)[0].address
        : data.address;

      console.log("fetchAddressFromEns", address);

      return address;
    }

    return false;
  } catch (error) {
    console.log("err", error.message);
    throw error;
  }
};

export const getTokenIds = async (tokenAddresses: string[]) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/get-coingecko-ids`, {
      addresses: tokenAddresses,
    });

    return data.length ? data : [];
  } catch (error) {
    console.log("error in getTokenId", error);
  }
};

export const getAllTokenIdsAndImpactPrice = async (
  tokenAddresses: string[]
) => {
  try {
    const tokenIds = await getTokenIds(tokenAddresses);

    const uniqueTokenIds = tokenIds.filter((obj, index, self) => {
      return index === self.findIndex((t) => t.id === obj.id);
    });

    const tokenIdsForParam = uniqueTokenIds.map((token) => token.id).join();

    const response = await axios.get(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${tokenIdsForParam}&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en`
    );
    if (response?.data?.length) {
      return response?.data;
      // const {price_change_percentage_24h} = response?.data[0];
      // console.log(
      //   'response of price imapct price_change_percentage_24h ',
      //   price_change_percentage_24h,
      // );
      // return price_change_percentage_24h.toFixed(2);
    } else {
      return [];
    }
  } catch (e) {
    console.log("error in getting impact prices", e);
  }
};

export const saveRecoveryCode = async (recoveryCode: string) => {
  try {
    const blob = new Blob([recoveryCode], {
      type: "text/plain",
    });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = "recovery-code.txt"; // You can set the file name here

    // Trigger a click event to initiate the download
    a.click();

    // Release the URL object
    URL.revokeObjectURL(url);
  } catch (error) {
    throw error;
  }
};

export const userNameRegexValidation = (str: string) => {
  const usernameRegex = /^[a-zA-Z0-9]{2,}[a-zA-Z0-9]*$/;
  return usernameRegex.test(str);
};

export const showAlert = (message: string, type?: string, footer?: string) => {
  return Swal.fire({
    title: type ? type : "Error",
    text: message,
    confirmButtonColor: "black",
    buttonsStyling: true,
    footer,
  });
};

export const getTokenDetailsFromHolding = (token: any, holdingArr: any) => {
  let tokenDetails;
  let addressToCompare = !token?.tokenAddress?.toLowerCase()
    ? NATIVE_ADDRESS
    : token?.tokenAddress?.toLowerCase();
  if (addressToCompare === NATIVE_ADDRESS) {
    tokenDetails = holdingArr.find(
      (tk) =>
        !tk?.tokenAddress ||
        tk?.tokenAddress?.toLowerCase() === NATIVE_ADDRESS?.toLowerCase()
    );
  } else {
    tokenDetails = holdingArr.find(
      (tk) =>
        tk?.tokenAddress?.toLowerCase() === addressToCompare?.toLowerCase()
    );
  }

  return tokenDetails;
};

export function convertExponent(n: number) {
  var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, "")
    .replace(/^([0-9]+)(e.*)/, "$1.$2")
    .split(/e|\./);
  return +pow < 0
    ? sign +
        "0." +
        "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
}

export const hasWhiteSpace = (s: string) => {
  return s.indexOf(" ") >= 0;
};

export const handleFormValidation = (
  password: string,
  username: string,
  confirmPassword: string
) => {
  if (password.length < 8) {
    showAlert("Password must be at least 8 characters long");
  } else if (username.length < 4 || username.length > 20) {
    showAlert("Choose a username 4–20 characters long");
  } else if (password !== confirmPassword) {
    showAlert("Passwords not match!");
  } else if (hasWhiteSpace(username)) {
    showAlert("Spaces are not allowed in username");
  } else if (username.startsWith(".") || username.endsWith(".")) {
    showAlert("Usernames cannot begin or end with periods (.)");
  } else if (username.includes("..")) {
    showAlert(
      "Usernames cannot contain an ampersand (&), equals sign (=), space ( ), apostrophe ('), dash (-), plus sign (+), comma (,), brackets (<,>), or more than one period (.) in a row."
    );
  } else if (!userNameRegexValidation(username)) {
    showAlert(
      "Usernames cannot contain an ampersand (&), equals sign (=), space ( ), apostrophe ('), dash (-), plus sign (+), comma (,), brackets (<,>), or more than one period (.) in a row."
    );
  } else {
    return true;
  }
};

export const handleUserNameValidation = (username: string) => {
  if (username.length < 4 || username.length > 20) {
    showAlert("Choose a username 4–20 characters long");
  } else if (hasWhiteSpace(username)) {
    showAlert("Spaces are not allowed in username");
  } else if (username.startsWith(".") || username.endsWith(".")) {
    showAlert("Usernames cannot begin or end with periods (.)");
  } else if (username.includes("..")) {
    showAlert(
      "Usernames cannot contain an ampersand (&), equals sign (=), space ( ), apostrophe ('), dash (-), plus sign (+), comma (,), brackets (<,>), or more than one period (.) in a row."
    );
  } else if (!userNameRegexValidation(username)) {
    showAlert(
      "Usernames cannot contain an ampersand (&), equals sign (=), space ( ), apostrophe ('), dash (-), plus sign (+), comma (,), brackets (<,>), or more than one period (.) in a row."
    );
  } else {
    return true;
  }
};

export const checkSum = (address: string) => {
  if (!address) return address;
  if (!Web3.utils.isAddress(address)) return address;
  return Web3.utils.toChecksumAddress(address);
};

export const encryptMessage = (message: string, secret: string) => {
  const ciphertext = crypto.AES.encrypt(
    JSON.stringify(message),
    secret
  ).toString();

  return ciphertext;
};

export const decryptMessage = (cipherText: string, secret: string) => {
  try {
    const bytes = crypto.AES.decrypt(cipherText, secret);
    const decryptedText = JSON.parse(bytes.toString(crypto.enc.Utf8));

    return decryptedText;
  } catch (error) {
    console.log("error", error);
  }
};

export const deriveAccount = (
  seedPhrase: string,
  deriveChildNum: number,
  hashedPassword: string
) => {
  const seed = Bip39.mnemonicToSeedSync(seedPhrase);
  const hdwallet = hdkey.fromMasterSeed(seed);
  const derivedPath = hdwallet.derivePath(EVM_DERIVATION_PATH);
  const child = derivedPath.deriveChild(deriveChildNum);
  const wallet = child.getWallet();
  const address = checkSum(`0x${wallet.getAddress().toString("hex")}`);
  const pk = wallet.getPrivateKey().toString("hex");
  const secret = encryptMessage(pk, hashedPassword);

  return {
    address,
    privateKey: secret,
  };
};

export const getEnsFromAddress = async (address: string) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/ens/getENSFromAddress/${address}`
    );
    return data;
  } catch (error) {
    console.log("err", error.message);
    throw error;
  }
};

export const createEVMAccount = async (
  seedPhrase: string,
  deriveChildNum: number,
  accountName: string,
  hashedPassword: string
) => {
  const { address, privateKey } = deriveAccount(
    seedPhrase,
    deriveChildNum,
    hashedPassword
  );

  const ens = await getEnsFromAddress(address.toLowerCase());

  const newAccount: Accounts = {
    [address]: {
      address,
      secret: privateKey,
      type: "Main",
      name: ens || accountName || "Test",
      smartAccountAddress: "",
    },
  };

  return { newAccount, address, secret: privateKey, ens };
};

export const createEVMSmartAccount = async (
  seedPhrase: string,
  deriveChildNum: number,
  accountName: string,
  hashedPassword: string,
  activeNetwork: SupportedChainId
) => {
  const { address, privateKey } = deriveAccount(
    seedPhrase,
    deriveChildNum,
    hashedPassword
  );

  const counterfactual = await getSmartAccountAddress(activeNetwork, address);
  const ens = await getEnsFromAddress(counterfactual.toLowerCase());
  const name = ens || accountName || "Test";
  const newAccount: Accounts = {
    [address]: {
      address,
      secret: privateKey,
      type: "Main",
      name,
      smartAccountAddress: counterfactual,
    },
  };

  return {
    newAccount,
    address,
    secret: privateKey,
    ens,
    accountName: name,
    counterfactual,
  };
};

export const passwordValidator = (
  newPassword: string,
  passwordFilters: { [n: number]: boolean }
) => {
  let filters: { [n: number]: boolean } = {};
  if (newPassword) {
    filters = {
      1: passwordFilters[1],
      2: passwordFilters[2],
      3: passwordFilters[3],
      4: passwordFilters[4],
    };
    if (/.{8,}/.test(newPassword)) {
      filters[1 as keyof typeof filters] = true;
    } else {
      filters[1] = false;
    }
    if (/(?=.*?[A-Z])/.test(newPassword)) {
      filters[2] = true;
    } else {
      filters[2] = false;
    }
    if (/(?=.*?[0-9])/.test(newPassword)) {
      filters[3] = true;
    } else {
      filters[3] = false;
    }
    if (/(?=.*?[#+~_()=|\\{}?!;:, [/|"<>@$`'%^.&*-])/.test(newPassword)) {
      filters[4] = true;
    } else if (newPassword.includes("]")) {
      filters[4] = true;
    } else {
      filters[4] = false;
    }
  }
  return { filters };
};

export const isSeedPhraseValid = (seed: string[]) => {
  return seed.every((s) => s.length >= 2);
};

export const generateRandomNumber = () => {
  const buf = new Uint8Array(1);
  const n = window.crypto.getRandomValues(buf);

  const randomNum = Math.floor(Math.random() * Number(n) * 1000000000);
  console.log(randomNum.toString());

  return randomNum.toString();
};

export const initalizeWeb3 = (activeNetwork: SupportedChainId) => {
  const web3 = new Web3(
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS].rpc
  );

  return web3;
};

export const initalizeContract = (
  activeNetwork: SupportedChainId,
  contractAddress: string
) => {
  const web3 = initalizeWeb3(activeNetwork);
  const contract = new web3.eth.Contract(abi, contractAddress);

  return contract;
};

export const fetchAccountNativeBalance = async (
  address: string,
  activeNetwork: SupportedChainId
) => {
  const id =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
      .coingeckoId;
  const web3 = initalizeWeb3(activeNetwork);

  const balance = await web3.eth.getBalance(address);
  const formattedBalance = Number(balance) / 10 ** 18;
  const balanceInUsd = await fetchUsdPrice(id);
  return {
    balance: formattedBalance,
    balanceInUsd: balanceInUsd * Number(formattedBalance),
    nativeTokenPrice: balanceInUsd,
  };
};

export const fetchCashAccountNativeBalance = async (
  address: string,
  activeNetwork: SupportedChainId
) => {
  const id =
    CASH_SUPPORTED_NETWORK[activeNetwork as keyof typeof CASH_SUPPORTED_NETWORK]
      .coingeckoId;
  const web3 = new Web3(
    CASH_SUPPORTED_NETWORK[
      activeNetwork as keyof typeof CASH_SUPPORTED_NETWORK
    ].rpc
  );

  const balance = await web3.eth.getBalance(address);
  const formattedBalance = Number(balance) / 10 ** 18;
  const balanceInUsd = await fetchUsdPrice(id);
  return {
    balance: formattedBalance,
    balanceInUsd: balanceInUsd * Number(formattedBalance),
    nativeTokenPrice: balanceInUsd,
  };
};

export const fetchUsdPrice = async (id: string) => {
  const { data } = await axios.get(
    `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`
  );

  return data[id].usd;
};

export const fetchCoingeckoId = async (addresses: string[]) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/get-coingecko-ids`, {
      addresses,
    });
    return data;
  } catch (error) {
    console.log("err", error);
    throw error;
  }
};

export const numFormatter = (number: number, precision?: number) => {
  if (number > Math.pow(2, 53)) number = 0;
  let symbol = "",
    status = true;
  if (number.toString().includes("e-")) {
    status = false;
  }
  if (typeof number !== "number") number = 0;
  let amount = millify(number, {
    precision: precision || 2,
  });
  console.log("file: utils.ts:845  numFormatteramount:", amount);

  if (amount.includes("K")) symbol = "K";
  if (amount.includes("M")) symbol = "M";
  if (amount.includes("B")) symbol = "B";
  if (amount.includes("T")) symbol = "T";
  if (amount.includes("P")) symbol = "P";
  if (amount.includes("E")) symbol = "E";

  if (amount.includes("-")) {
    amount = amount.replace("-", "");
    status = false;
  }

  return { amount: parseFloat(amount.replace(symbol, "")), symbol, status };
};

export const formatAmount = (amount: number) => {
  if (amount === 0) return "0";
  if (!amount) return "-";
  if (amount < 0.0000001) {
    return "<0.00001";
  }
  if (amount < 0.000001) {
    return "<0.0001";
  }

  // Check if amount is larger than 1 trillion
  if (amount >= 1e12) {
    const quadrillions = amount / 1e15;
    if (quadrillions >= 1) {
      return `${quadrillions.toFixed(2)}Q`;
    }

    const trillions = amount / 1e12;
    if (trillions >= 1) {
      return `${trillions.toFixed(2)}T`;
    }
  }

  return millify(amount, {
    precision: 4,
  });
};

export const roundOfNumber = (num: number) => {
  return num.toString().includes("e")
    ? Number(num).toExponential().slice(0, 4) +
        Number(num)
          .toExponential()
          .slice(num.toString().length - 4, num.toString().length)
    : num;
};

export const fetchPrice = async (tokens: string[], chainId: number) => {
  let priceObject: {
    [address: string]: {
      price: number;
    };
  } = {};
  try {
    const chains: {
      [chainId: number]: string;
    } = {
      [1]: "Ethereum",
      [137]: "Polygon",
    };
    const BASE_URL = MOBULA_API;
    const tokenQueryString = tokens.join(",");
    const fetchUrl = `${BASE_URL}/market/multi-data?assets=${encodeURIComponent(
      tokenQueryString
    )}&&blockchains=${chains[chainId]}`;
    const request = await axios.get(fetchUrl);
    const data = await request.data.data;

    // We iterate over each property (address) in your original data object
    for (const address in data) {
      // For each address, we create a new object containing only the 'price' property
      priceObject[address.toLowerCase()] = {
        price: data[address].price,
      };
    }
  } catch (error) {
    tokens.forEach((token) => {
      priceObject[token.toLowerCase()] = {
        price: 0,
      };
    });
  }
  return priceObject;
};

export const trimTokenAddress = (address: string) => {
  if (address.length < 8) {
    return address; // No need to trim if it's too short
  }

  const prefix = address.substring(0, 5);
  const suffix = address.slice(-5);
  return `${prefix}....${suffix}`;
};

export function extractTokenData(activeNetwork: number, assets: any[]) {
  // Retrieve the topTokens for the active network
  const networkData =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];
  const topChainTokens = networkData ? networkData.topTokens : null;

  // Return empty defaults if no supported network is found
  if (!topChainTokens) {
    return {
      address: "",
      decimal: 0,
      sameTokens: [],
    };
  }

  // Create an array from the topTokens
  const topChainsArray: any[] = Object.values(topChainTokens);

  // Function to filter assets based on the token address
  const filteredData = (tokenAddress: string) => {
    return assets.filter((item) => {
      if (item?.cross_chain_balances) {
        const chainKeys = Object.keys(item.cross_chain_balances);
        return chainKeys.some((chain) => {
          const address = item.cross_chain_balances[chain].address;
          return address?.toLowerCase() === tokenAddress?.toLowerCase();
        });
      }
      return false;
    });
  };

  // Filter the tokens based on the filtered data
  const sameTokens = topChainsArray.filter(
    (token) => filteredData(token?.address)?.length > 0
  );
  console.log("file: utils.ts:961  extractTokenData  sameTokens:", sameTokens);

  // Return the first address and the array of sameTokens
  return {
    address: sameTokens.length > 0 ? sameTokens[0].address : "",
    decimal: sameTokens.length > 0 ? sameTokens[0].decimals : "",
    sameTokens,
  };
}
