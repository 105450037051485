import { Grid, Slide, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { Asset } from "interfaces";
import TokenImage from "components/TokenImage";
import { numFormatter } from "utils/utils";
import CardLayout from "components/CardLayout";
import { Box } from "@mui/system";
import Button from "components/NewButton";
import Globe from "assets/emptyCryptoList.svg";
import { setSelectedToken } from "store/slices/walletSlice";
import {
  WRAPPED_TOKEN_ADDRESS,
  tokensForGas,
  tokensForGasObject,
} from "constants/topTokensConf";
import { SUPPORTED_NETWORKS } from "constants/chains";
import Web3 from "web3";
import paymasterAbi from "abis/paymasterabi.json";
import { Paymaster_Token_Address } from "../../../../../contract-integration/constants";
import { ethers } from "ethers";

const WithdrawListComponent: FC<{
  asset: any;
  nextStep?: () => void;
  transactionForm?: boolean;
}> = ({ asset, nextStep = () => {}, transactionForm = false }) => {
  console.log(
    "file: WithdrawTokensList.tsx:19  asset: selectedTokenaaaaa",
    asset
  );
  const navigate = useNavigate();
  const { nfts, activeAccount, activeNetwork } = useAppSelector(
    (state) => state.app
  );
  console.log("file: index.tsx:17  activeNetwork:", activeNetwork);
  const { amount: tokenBalance, symbol: tokenSymbol } = numFormatter(
    asset.balance / 10 ** asset.decimal,
    4
  );
  const dispatch = useAppDispatch();
  // const { amount: usdBalance, symbol: usdSymbol } = numFormatter(
  //   asset.estimated_balance,
  //   2
  // );
  return (
    <Grid
      sx={{
        padding: "10px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        cursor: "pointer",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
      onClick={() => {
        if (transactionForm) {
          console.log(
            "file: WithdrawTokensList.tsx:44 selectedToken  transactionForm:",
            transactionForm,
            asset
          );
          // const matchingObject = Object.values(asset.cross_chain_balances).find(
          //   (balanceObject) => balanceObject.chainId === activeNetwork
          // );
          // console.log(
          //   "file: WithdrawTokensList.tsx:57  matchingObject: transactionForm",
          //   matchingObject
          // );
          // if (matchingObject) {
          const web3 = new Web3();
          dispatch(
            setSelectedToken([
              // { asset },
              {
                id: 1,
                image: asset.logoUrl,
                // priceInUSD: "0",
                tokenAddress: asset.tokenAddress,
                tokenBalance: ethers.utils.formatUnits(
                  String(asset.balance),
                  asset.decimal
                ),
                tokenDecimal: asset.decimal,
                tokenName: asset.token,
                // tokenPrice: asset.price,
                // tokenSymbol: asset.asset.symbol,
                // isNative:
                //   matchingObject.address ==
                //   WRAPPED_TOKEN_ADDRESS[activeNetwork],
              },
            ])
          );
          // }
          nextStep();
        } else {
          navigate(asset.asset.name);
        }
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <TokenImage logo={asset.logoUrl} />
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          <div>{asset.token}</div>
          <div
            style={{
              // color: "rgba(26, 28, 32, 0.50)",
              fontFamily: "Helvetica Neue",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {tokenBalance + tokenSymbol + " "} {asset.token}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          {/* <div>${usdBalance + usdSymbol}</div> */}
          {/* <div
              style={{
                // color: "rgba(26, 28, 32, 0.50)",
                fontFamily: "Helvetica Neue",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Min Price
            </div> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

const WithdrawTokensList: FC<{
  transactionForm?: boolean;
  nextStep?: () => void;
  filterText?: string;
}> = ({ transactionForm = false, nextStep = () => {}, filterText = "" }) => {
  const navigate = useNavigate();
  const [depositedTokens, setDepositedTokens] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  console.log("file: TokensList.tsx:192  depositedTokens:", depositedTokens);
  const {
    portfolio: { assets },
    activeNetwork,
    accounts,
    activeAccount,
  } = useAppSelector((state) => state.app);

  const updatedAssets = assets.filter((item) => {
    const matchingObject = Object.values(item.cross_chain_balances).find(
      (balanceObject: any) => balanceObject.chainId === activeNetwork
    );

    if (matchingObject) {
      return true;
    }
    return false;
  });

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: string
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );
    console.log(
      "file: index.tsx:236  Send depositableTokens paymasterContract:",
      tokenAddress
    );
    // Create an object to keep track of cumulative balances for each token

    try {
      const accountHolding = await paymasterContract.methods
        .balances(tokenAddress, address)
        .call();
      console.log(
        "file: index.tsx:241 depositableTokens  Send  accountHolding:",
        accountHolding
      );
      return Number(accountHolding);
    } catch (error) {
      console.error("Error reading depositableTokens account holdings:", error);
      return 0; // Handle the error gracefully
    }
  };

  async function fetchBalances() {
    setLoading(true);
    const allAccountsAddress = Object.keys(accounts);

    const firstAccountAddress =
      accounts[allAccountsAddress[0]].smartAccountAddress;
    console.log(
      "file: index.tsx:256  fetchBalances depositableTokens  firstAccountAddress:",
      firstAccountAddress
    );

    let depositedTokenObject: Array<any> = [];
    for (const tokenInfo of tokensForGasObject[activeNetwork]) {
      const { tokenAddress, rpc } = tokenInfo;
      // console.log(
      //   "file: index.tsx:265  fetchBalancesdfdfdfdf depositableTokens  tokenAddress:",
      //   tokenAddress
      // );
      const balance = await readAccountHoldings(
        tokenAddress,
        firstAccountAddress,
        rpc
      );
      console.log(
        "file: index.tsx:271 depositableTokens fetchBalancesdfdfdfdf  balance:",
        balance,
        tokenAddress,
        firstAccountAddress
      );

      if (balance > 0) {
        depositedTokenObject.push({ ...tokenInfo, balance });
      }
    }
    setLoading(false);

    setDepositedTokens(depositedTokenObject);
  }
  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    console.log(
      "file: index.tsx:271 depositableTokens fetchBalances  balance:"
    );

    fetchBalances();
  }, [accounts, activeAccount, activeNetwork]);

  const chainAssets = updatedAssets.filter((asset) =>
    tokensForGas.some(
      (gasToken: any) => gasToken.token === asset?.asset?.symbol
    )
  );
  console.log("file: TokensList.tsx:205  chainAssets:", chainAssets);

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
        paddingTop: "20px",
      }}
    >
      {!loading ? (
        <>
          {depositedTokens && depositedTokens.length > 0 ? (
            (() => {
              const filteredAssets = depositedTokens.filter((item) =>
                item?.token?.toLowerCase()?.includes(filterText.toLowerCase())
              );

              if (filteredAssets.length === 0) {
                return (
                  <Box textAlign={"center"} py={5}>
                    <Typography variant="h3">No results found</Typography>
                  </Box>
                );
              }
              console.log(
                "file: TokensList.tsx:316  filteredAssets:",
                filteredAssets
              );

              return filteredAssets.map((asset) => (
                <WithdrawListComponent
                  asset={asset}
                  nextStep={nextStep}
                  transactionForm={transactionForm}
                />
              ));
            })()
          ) : (
            <>
              {transactionForm ? (
                <>
                  {" "}
                  <Box textAlign={"center"} py={5}>
                    <Typography variant="h3">
                      No Asset on Selected Chain
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  {" "}
                  <CardLayout
                    backgroundColor="white"
                    style={{
                      flex: 1,
                      width: "100%",
                      marginTop: "40px",
                      marginBottom: "40px",
                      overflow: "hidden",
                    }}
                  >
                    <Slide in={true} timeout={500} direction={"down"}>
                      <Box
                        className="flex-col-center"
                        style={{
                          padding: "20px 0px",
                          height: "275px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant={"h5"}
                          fontWeight={700}
                          color="rgba(26, 28, 32, 1)"
                          fontSize={25}
                          style={{ cursor: "pointer" }}
                        >
                          Start your Stash!{" "}
                        </Typography>
                        <Typography
                          variant={"h5"}
                          fontWeight={400}
                          color="rgba(26, 28, 32, 0.5)"
                          fontSize={12}
                          style={{ cursor: "pointer" }}
                        >
                          Get started!
                        </Typography>
                        <img
                          src={Globe}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            overflow: "hidden",
                          }}
                        />

                        <div
                          style={{
                            width: "70%",
                            maxWidth: "305px",
                            marginTop: "-10%",
                          }}
                        >
                          <Button
                            title="Receive"
                            style={{
                              backgroundColor: "rgba(235, 236, 238, 1)",
                              margin: 0,
                            }}
                            textStyle={{ color: "rgba(26, 28, 32, 1)" }}
                            onClick={() => {
                              navigate("/crypto/receive");
                            }}
                          />
                        </div>
                      </Box>
                    </Slide>
                  </CardLayout>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          <Box textAlign={"center"} py={5}>
            <Typography variant="h3">Loading...</Typography>
          </Box>
        </>
      )}
    </Grid>
  );
};

export default WithdrawTokensList;
