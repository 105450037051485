import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import StashedCup from "assets/stashed-cup.svg";

import Button from "components/NewButton";
import TextInput from "components/TextInput";
import "./styles.css";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch } from "store/store";
import { setExternalReferralCode } from "@slices/appSlice";
import { showAlert, verifyReferralCode } from "utils/utils";

import { Grid, Box } from "@mui/material";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";

const skipStyles = {
  fontWeight: "700",
  lineHeight: "24.5px",
  fontSize: "17px",
  textAlign: "left",
};

const ReferralCode = () => {
  const [loading, setLoading] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(currentStep || 0);

  const handleReferralCode = async () => {
    try {
      setLoading(true);
      if (referralCode.trim().length > 0 && referralCode !== "JoinStashed") {
        await verifyReferralCode(referralCode);
        dispatch(setExternalReferralCode(referralCode));
      } else {
        dispatch(setExternalReferralCode(""));
      }
      setLoading(false);
      setReferralCode("");

      navigate("/stashed-tag", {
        state: { currentStep: currentStep + 1, pathName: pathName },
      });
    } catch (error) {
      console.log(error);
      showAlert("Invalid Referral Code");
      setLoading(false);
      setReferralCode("");
    }
  };

  const handleSkip = () => {
    dispatch(setExternalReferralCode(""));
    navigate("/stashed-tag", {
      state: { currentStep: currentStep + 1, pathName: pathName },
    });
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13 || key === "Tab" || keyCode === 9) {
      e.preventDefault(); // Prevent the default behavior of the Tab key
      handleSkip();
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
        <div className="create-account-box" style={{ marginTop: "4%" }}>
          <Typography
            variant="h2"
            sx={{
              margin: "0px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Enter your referral code
          </Typography>

          <Typography
            variant="body2"
            sx={{
              margin: "10px 0px 10px 0px",
              color: "rgba(26, 28, 32, 0.5)",

              fontWeight: "500",
              textAlign: "center",
              width: "35%",
            }}
          >
            {/* Don't have a referral code? <br /> Use{' '}
          <span style={{ color: 'black' }}>JoinStashed</span> */}
            Using a referral code will grant a 10% discount on transaction fees
            and cashback with every transaction
          </Typography>

          <div className="welcome-box-container">
            <TextInput
              title="Referral code"
              value={referralCode}
              onChange={setReferralCode}
              style={{
                width: "350px",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "15.31px",
              }}
              customClassName="referral-code-input"
              placeholderName="JoinStashed"
              onKeyPress={handleKeyPress}
            />

            <Button
              title="Continue"
              onClick={handleReferralCode}
              loading={loading}
              disabled={!referralCode ? true : loading}
              style={{
                width: "350px",
                margin: "0px 0px 40px",
              }}
            />
          </div>
          {/* <img src={StashedCup} /> */}
        </div>
      </Grid>
      <Grid item xs={12} md={2} className="referral-right-section">
        <Typography
          variant="h5"
          sx={{
            margin: "0px 0px 30px 0px",
            fontWeight: "700",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={handleSkip}
        >
          Skip
        </Typography>
      </Grid>
      {/* </Grid> */}
    </div>
  );
};

export default ReferralCode;
