import React, { useState } from "react";
import pbkdf2 from "pbkdf2";

import { Grid, Typography } from "@mui/material";
import TextInput from "components/TextInput";
import Button from "components/NewButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthHeader from "components/AuthHeader";
import { encryptMessage, showAlert } from "utils/utils";
import { useAppDispatch, useAppSelector } from "store/store";
import { setHashedPassword } from "@slices/walletSlice";
import { saveMnemonic } from "@slices/appSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";

const CreatePassword = () => {
  const [securePassword, setSecurePassword] = useState(true);
  const [secureConfirmPassword, setSecureConfirmPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { mnemonic } = useAppSelector((state) => state.app);

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const handlePasswordContinue = async () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_=#$@!%&*?])[A-Za-z\d-_=#$@!%&*?]{8,30}$/;
    setLoading(true);
    if (password !== confirmPassword) {
      showAlert(
        "The passwords you entered do not match. Please check and try again"
      );

      setLoading(false);
    } else {
      if (password.length < 8) {
        showAlert("Passwords must be 8 characters long");

        setLoading(false);
      } else if (!regex.test(password)) {
        showAlert(
          "Password must include numbers, lowercase letters, uppercase letters and special characters"
        );

        setLoading(false);
      } else {
        const hashedPassword = pbkdf2
          .pbkdf2Sync(password, "salt", 1, 32, "sha512")
          .toString("hex");

        if (mnemonic) {
          const encryptMnemonic = encryptMessage(mnemonic, hashedPassword);
          dispatch(saveMnemonic(encryptMnemonic));
        }

        dispatch(setHashedPassword(hashedPassword));

        setLoading(false);

        navigate("/pin", {
          state: { currentStep: step + 1, pathName: pathName },
        });
      }
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13 || key === "Tab" || keyCode === 9) {
      e.preventDefault();
      handlePasswordContinue();
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>

      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Create your password
        </Typography>

        <div className="welcome-box-container">
          <TextInput
            title="Password"
            value={password}
            onChange={setPassword}
            onKeyPress={(e) => handleKeyPress(e)}
            showSecure={() => setSecurePassword(!securePassword)}
            showEndIcon
            secure={securePassword}
            type={securePassword ? "password" : "text"}
            style={{
              width: "350px",
            }}
          />
          <TextInput
            title="Confirm Password"
            value={confirmPassword}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={setConfirmPassword}
            showSecure={() => setSecureConfirmPassword(!secureConfirmPassword)}
            showEndIcon
            secure={secureConfirmPassword}
            type={secureConfirmPassword ? "password" : "text"}
            style={{
              width: "350px",
            }}
          />

          <Button
            onClick={handlePasswordContinue}
            title="Continue"
            loading={loading}
            disabled={!password || !confirmPassword}
            style={{
              width: "380px",
              padding: "15px 0px",
              margin: "20px 0px 0px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
