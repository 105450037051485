import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import pbkdf2 from "pbkdf2";
import sss from "shamirs-secret-sharing";

import Button from "components/NewButton";
import OTPInput from "components/OtpInput";
import "./styles.css";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch, useAppSelector } from "store/store";
import { setHashedPin, setTorusKey } from "@slices/walletSlice";
import {
  createEVMAccount,
  createEVMSmartAccount,
  decryptMessage,
  encryptMessage,
  fetchAccountNativeBalance,
  getEnsFromAddress,
  showAlert,
  updateEncryptedKeys,
} from "utils/utils";
import { Accounts } from "interfaces";
import {
  setAccounts,
  setActiveAccount,
  setBlockNumbers,
  setEncryptedSeedPhraseWithPassword,
  setExpirationTime,
  setHoldings,
  setMode,
  setRootAccountInfo,
} from "@slices/appSlice";
import {
  LOGIN_EXPIRY,
  SHARES,
  THRESHOLD,
  stepsCount,
} from "../../../../../../src/constants";
import { initalizeBlockNumbers } from "utils/blocknumber";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";

const Pin = () => {
  const [type, setType] = useState("create");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const {
    activeNetwork,
    user: { email },
    accessToken,
    fetchedKeys: { key1, key2, key3, key4, rootAddress },
  } = useAppSelector((state) => state.app);

  const { torusKey, recoveryCode, hashedPassword } = useAppSelector(
    (state) => state.wallet
  );

  const fromStringToBuffer = (val) => Buffer.from(val, "hex");
  const bufferToReadable = (buffer) => {
    return buffer.toString("hex");
  };

  const fetchAndSaveData = async (
    address: string,
    privateKey: string,
    newAccount: Accounts,
    counterfactual: string
  ) => {
    dispatch(setExpirationTime(new Date().getTime() + LOGIN_EXPIRY));
    const { balance, balanceInUsd, nativeTokenPrice } =
      await fetchAccountNativeBalance(address, activeNetwork);

    dispatch(
      setHoldings({
        [counterfactual]: {
          nativeBalance: balance,
          nativeBalanceUsd: balanceInUsd,
          nativeTokenPrice,
          tokens: [],
        },
      })
    );

    dispatch(setAccounts(newAccount));
    dispatch(
      setActiveAccount({
        address,
        secret: privateKey,
        smartAccountAddress: counterfactual,
        accountName: newAccount[address]?.name,
      })
    );
    setStep(4);

    navigate("/recover-download-recovery-key", {
      state: {
        currentStep: step + 1,
        pathName: pathName,
      },
    });
  };

  const createPin = async () => {
    try {
      if (type === "create") {
        setType("confirm");
      } else {
        if (pin !== confirmPin) {
          showAlert("Pin does not match");
        } else {
          setLoading(true);
          const hashedPin = pbkdf2
            .pbkdf2Sync(pin, "salt", 1, 32, "sha512")
            .toString("hex");

          const hashedTorus = pbkdf2
            .pbkdf2Sync(torusKey, "salt", 1, 32, "sha512")
            .toString("hex");

          dispatch(setHashedPin(hashedPin));
          dispatch(setTorusKey(hashedTorus));

          const decryptedKey1 = decryptMessage(key1, hashedTorus);
          const decryptedKey2 = decryptMessage(key4, recoveryCode);
          const decryptedKey3 = decryptMessage(key2, hashedPassword);

          console.log(decryptedKey1, decryptedKey2, decryptedKey3);
          const decryptMnemonic = sss
            .combine([
              fromStringToBuffer(decryptedKey1),
              fromStringToBuffer(decryptedKey2),
              fromStringToBuffer(decryptedKey3),
            ])
            .toString();

          console.log(decryptMnemonic);

          const secretBuffer = Buffer.from(decryptMnemonic);
          const generatedShares = sss.split(secretBuffer, {
            shares: SHARES,
            threshold: THRESHOLD,
          });

          let s = generatedShares.map((s) => bufferToReadable(s));

          const encryptedShare1 = encryptMessage(s[0], hashedTorus);
          const encryptedShare2 = encryptMessage(s[1], hashedPassword);
          const encryptedShare3 = encryptMessage(s[2], hashedPin);

          const updatedRecoveryCode = hashedTorus + hashedPassword + hashedPin;

          const encryptedShare4 = encryptMessage(s[3], updatedRecoveryCode);

          await updateEncryptedKeys(
            email,
            encryptedShare1,
            encryptedShare2,
            encryptedShare3,
            encryptedShare4,
            accessToken
          );

          const encryptedSeedPhraseWithPassword = encryptMessage(
            decryptMnemonic,
            hashedPassword
          );

          const ens = await getEnsFromAddress(rootAddress?.toLowerCase());

          const blockNumbers = await initalizeBlockNumbers();

          dispatch(setBlockNumbers(blockNumbers));

          const { newAccount, address, secret, counterfactual } =
            await createEVMSmartAccount(
              decryptMnemonic,
              1,
              "",
              hashedPassword,
              activeNetwork
            );
          const { address: derivedRootAddress, secret: rootSecret } =
            await createEVMAccount(decryptMnemonic, 0, ens, hashedPassword);

          dispatch(
            setEncryptedSeedPhraseWithPassword(encryptedSeedPhraseWithPassword)
          );

          dispatch(setMode("google"));
          if (rootAddress.toLowerCase() === derivedRootAddress.toLowerCase()) {
            dispatch(
              setRootAccountInfo({
                address: rootAddress,
                name: ens,
                secret: rootSecret,
              })
            );
          }

          dispatch(setMode("google"));

          await fetchAndSaveData(address, secret, newAccount, counterfactual);

          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>
      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          {type === "create" ? "Create" : "Confirm"} your pin
        </Typography>

        <div>
          {type === "create" ? (
            <OTPInput otp={pin} onChange={setPin} />
          ) : (
            <OTPInput otp={confirmPin} onChange={setConfirmPin} />
          )}

          <Button
            onClick={createPin}
            title="Continue"
            disabled={!pin && !confirmPin}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Pin;
