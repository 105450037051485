import React, { useState } from "react";
import { Box, Grid, Input, Typography } from "@mui/material";
import SearchIcon from "assets/SearchInputIcon.svg";

import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import CenterItem from "../../../../components/CenterItem";
import NFTList from "./NFTList";
import SearchInput from "../../../../components/SearchInput";
import FilterModal from "components/Modal/FilterModal";
// import { useAppSelector } from "../../../../store/store";

const NFTListTable = () => {
  // const { nfts, activeAccount, activeNetwork } = useAppSelector(
  //   (state) => state.app
  // );
  const [searchText, setSearchText] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);

  // const profileNfts = nfts[activeAccount.smartAccountAddress].nfts;
  // console.log("file: index.tsx:13  NFTListTable  nfts:", nfts, profileNfts);

  return (
    <div>
      <Box display="flex">
        <Box flex={1} alignItems={"center"}>
          <SearchInput
            handleOnChange={(e: any) => {
              setSearchText(e.target.value);
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "space-between",
            paddingLeft: "12px",
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <CenterItem>
            <img src={SearchOptionsIcon} onClick={() => setOpenModal(true)} />
          </CenterItem>

          <FilterModal open={openModal} onClose={() => setOpenModal(false)} />

          <CenterItem>
            <div className="tab-chip-box">
              <Typography
                variant={"h5"}
                fontWeight={500}
                color="white"
                fontSize={12}
                fontFamily={"Helvetica Neue"}
                whiteSpace={"nowrap"}
                letterSpacing={2}
              >
                + ADD NFT
              </Typography>
            </div>
          </CenterItem>
        </Box>
      </Box>
      <Grid>
        <NFTList searchFilterText={searchText} />
      </Grid>
    </div>
  );
};

export default NFTListTable;
