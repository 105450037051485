import React, { useState } from "react";

import DrawPatternSVG from "assets/DrawPatternSVG.svg";

import SuccessLogo from "assets/success.svg";

import "../../pages/auth/TransactionFlow/TransactionSuccess/styles.css";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Button from "components/NewButton";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch } from "store/store";
import { setIsAccountCreated, setUserSpendingDetails } from "@slices/appSlice";
// import PatternLock from "patternlock";
// import PatternLock from "patternlock/dist/patternlock.css";
import PatternLock from "react-pattern-lock";

const DrawPattern = () => {
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState<number[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigateForward = () => {
    dispatch(setUserSpendingDetails({ path }));
    navigate("/confirm-draw-pattern");
  };

  const handleNavigateBack = () => {
    navigate("/perform-transaction");
  };

  return (
    <div>
      <div
        className="transaction-box"
        style={{
          marginTop: "10px",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            margin: "20px 0px 20px 0px",
            fontWeight: "700",
            textAlign: "center",
            fontSize: "28px",
          }}
        >
          Security Pattern
        </Typography>

        <PatternLock
          className="custom-pattern-lock"
          width={300}
          pointSize={25}
          size={3}
          path={path}
          disabled={false}
          error={false}
          success={true}
          onChange={(pattern) => {
            console.log(
              "🚀 ~ file: DrawPattern.tsx:58 ~ DrawPattern ~ pattern:",
              pattern
            );
            setPath(pattern);
            // this.setState({ path : pattern });
          }}
          onFinish={() => {
            // check if the pattern is correct
          }}
        />
        {/* <img
          src={DrawPatternSVG}
          style={{
            width: "auto",
            height: "250px",
            margin: "20px 0px 20px 0px",
          }}
        /> */}

        <div className="transaction-wrapper" style={{ top: 20 }}>
          <Typography
            variant="body2"
            sx={{
              color: "#1A1C20",
              fontWeight: "700",
              textAlign: "center",
              fontSize: "17px",
              fontFamily: "Space Grotesk",
            }}
            onClick={handleNavigateBack}
          >
            Back
          </Typography>
          <Button
            title="Continue"
            onClick={handleNavigateForward}
            loading={loading}
            disabled={path.length <= 2}
            style={{
              margin: 0,
              width: "260px",
              padding: "15px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DrawPattern;
