import { CircularProgress, Typography } from "@mui/material";
import pbkdf2 from "pbkdf2";
import sss from "shamirs-secret-sharing";

import Button from "components/NewButton";
import TextInput from "components/TextInput";
import OTPInput from "components/OtpInput";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  createEVMAccount,
  createEVMSmartAccount,
  decryptMessage,
  encryptMessage,
  fetchEncryptedKeys,
  fetchEncryptedKeysByName,
  getEnsFromAddress,
  initalizeWeb3,
  saveRecoveryCode,
  showAlert,
  updateEncryptedKeysAndEmailBySignature,
} from "utils/utils";
import KeyLogo from "assets/key.svg";
import StashedCreatedLogo from "assets/stash-created.svg";
import SuccessLogo from "assets/success.svg";
import WelcomeBox from "components/WelcomeBox";
import Google from "assets/google.svg";
import useGoogleWeb3Auth from "hooks/useGoogleWeb3Auth";
import { loginWithGoogle } from "utils/google";
import {
  setAccesToken,
  setBlockNumbers,
  setEncryptedSeedPhraseWithPassword,
  setMode,
  setRootAccountInfo,
  setUserInfo,
} from "@slices/appSlice";
import { setRecoveryCode, setTorusKey } from "@slices/walletSlice";
import { SHARES, THRESHOLD } from "constants/";

const UpdateGoogleShare = () => {
  const [step, setStep] = useState(0);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [securePassword, setSecurePassword] = useState(true);
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [userInfo, saveUserInfo] = useState({
    email: "",
    name: "",
  });

  const [fetchedKeys, saveFetchedKeys] = useState({
    rootAddress: "",
    key1: "",
    key2: "",
    key3: "",
    key4: "",
  });
  const [hashedPassword, saveHashedPassword] = useState("");
  const [hashedPin, saveHashedPin] = useState("");
  const [localRecoveryCode, setLocalRecoveryCode] = useState("");

  const { web3auth } = useGoogleWeb3Auth();

  const { activeAccount, rootAccountInfo, user, activeNetwork } =
    useAppSelector((state) => state.app);
  const { torusKey } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();

  const handleStashedTag = async () => {
    try {
      setLoading(true);
      if (username !== rootAccountInfo.name) {
        showAlert("Please enter correct Stashed Tag");
        setLoading(false);
      } else {
        const data = await fetchEncryptedKeysByName(username);
        console.log(data);
        setLoading(false);
        if (!data) {
          setLoading(false);
          showAlert("Cash Tag does not exist");
        } else {
          saveUserInfo({
            email: data.email,
            name: data.username,
          });

          saveFetchedKeys({
            rootAddress: data.address,
            key1: data.keys[0]?.key1,
            key2: data.keys[0]?.key2,
            key3: data.keys[0]?.key3,
            key4: data.keys[0]?.key4,
          });

          setStep(1);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      showAlert("Cash Tag does not exist");
      console.log(error);
    }
  };

  const handlePassword = async () => {
    try {
      setLoading(true);

      const hashedPassword = pbkdf2
        .pbkdf2Sync(password, "salt", 1, 32, "sha512")
        .toString("hex");
      saveHashedPassword(hashedPassword);

      const decryptedKey = decryptMessage(fetchedKeys.key2, hashedPassword);

      if (decryptedKey) {
        setStep(2);
        setLoading(false);
      } else {
        setLoading(false);
        showAlert("Password is incorrect");
      }
    } catch (error) {
      setLoading(false);
      showAlert("Password is incorrect");

      console.log("err", error);
    }
  };

  const handlePin = async () => {
    try {
      setLoading(true);

      const hashedPin = pbkdf2
        .pbkdf2Sync(pin, "salt", 1, 32, "sha512")
        .toString("hex");
      saveHashedPin(hashedPin);

      const decryptedKey = decryptMessage(fetchedKeys.key3, hashedPin);

      if (decryptedKey) {
        setStep(3);
        setLoading(false);
      } else {
        setLoading(false);
        showAlert("Pin is incorrect");
      }
    } catch (error) {
      setLoading(false);
      showAlert("Pin is incorrect");

      console.log("err", error);
    }
  };

  const handleFile = (e) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      console.log(file);
      if (file) {
        if (file.type === "text/plain") {
          const reader = new FileReader();

          reader.onload = (event) => {
            const content = event.target.result;
            console.log(content);
            setLocalRecoveryCode(content);

            const decryptedKey = decryptMessage(fetchedKeys.key4, content);
            if (decryptedKey) {
              setStep(4);
              setLoading(false);
            } else {
              showAlert("Invalid Recovery Code");
            }
            setLoading(false);
          };

          reader.readAsText(file);
        } else {
          showAlert("Invalid file format. Please select a text file.");
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      showAlert("Invalid Recovery Code");
    }
  };

  const fromStringToBuffer = (val) => Buffer.from(val, "hex");

  const bufferToReadable = (buffer) => {
    return buffer.toString("hex");
  };

  const updateGoogle = async () => {
    try {
      if (web3auth) {
        setLoading(true);

        const {
          address: torusKey,
          email,
          idToken,
          name,
        } = await loginWithGoogle(web3auth);

        if (email) {
          if (email !== userInfo.email) {
            const data = await fetchEncryptedKeys(idToken);
            if (data) {
              showAlert("Email already connected with different account");

              setLoading(false);
              await web3auth.logout();
              return;
            }
          }
          dispatch(
            setUserInfo({
              email,
              name,
            })
          );

          dispatch(setAccesToken(idToken));

          const hexTorus = torusKey[0].toString("hex");

          const hashedTorus = pbkdf2
            .pbkdf2Sync(hexTorus, "salt", 1, 32, "sha512")
            .toString("hex");

          dispatch(setTorusKey(hashedTorus));

          const decryptedKey1 = decryptMessage(
            fetchedKeys.key2,
            hashedPassword
          );
          const decryptedKey2 = decryptMessage(
            fetchedKeys.key4,
            localRecoveryCode
          );
          const decryptedKey3 = decryptMessage(fetchedKeys.key3, hashedPin);

          console.log(decryptedKey1, decryptedKey2, decryptedKey3);
          const decryptMnemonic = sss
            .combine([
              fromStringToBuffer(decryptedKey1),
              fromStringToBuffer(decryptedKey2),
              fromStringToBuffer(decryptedKey3),
            ])
            .toString();

          console.log(decryptMnemonic);

          const secretBuffer = Buffer.from(decryptMnemonic);
          const generatedShares = sss.split(secretBuffer, {
            shares: SHARES,
            threshold: THRESHOLD,
          });

          let s = generatedShares.map((s) => bufferToReadable(s));

          const encryptedShare1 = encryptMessage(s[0], hashedTorus);
          const encryptedShare2 = encryptMessage(s[1], hashedPassword);
          const encryptedShare3 = encryptMessage(s[2], hashedPin);

          const updatedRecoveryCode = hashedTorus + hashedPassword + hashedPin;
          const encryptedShare4 = encryptMessage(s[3], updatedRecoveryCode);

          const encryptedSeedPhraseWithPassword = encryptMessage(
            decryptMnemonic,
            hashedPassword
          );

          const ens = await getEnsFromAddress(
            fetchedKeys.rootAddress?.toLowerCase()
          );

          const web3 = initalizeWeb3(activeNetwork);

          const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);

          const signature = await web3.eth.accounts.sign(
            `${name}${email}`,
            pkey
          );

          await updateEncryptedKeysAndEmailBySignature(
            email,
            name,
            encryptedShare1,
            encryptedShare2,
            encryptedShare3,
            encryptedShare4,
            signature.signature,
            rootAccountInfo.address,
            idToken
          );

          dispatch(
            setEncryptedSeedPhraseWithPassword(encryptedSeedPhraseWithPassword)
          );

          dispatch(setMode("google"));

          dispatch(setMode("google"));

          setStep(5);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      showAlert("Recovery Failed");
    }
  };

  const generateRecoveryCode = async () => {
    try {
      setLoading(true);

      const recoveryCode = torusKey + hashedPassword + hashedPin;

      saveRecoveryCode(recoveryCode);
      dispatch(setRecoveryCode(recoveryCode));

      setStep(6);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      {step === 0 && (
        <div className="create-account-box">
          <Typography
            variant="h2"
            sx={{
              margin: "30px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Enter your Stashed Tag
          </Typography>
          <div className="welcome-box-container">
            <TextInput
              title="Stashed Tag"
              value={username}
              onChange={setUsername}
              style={{
                width: "350px",
              }}
            />

            <Button
              onClick={handleStashedTag}
              title="Continue"
              loading={loading}
              style={{
                width: "380px",
                padding: "15px 0px",
                margin: "20px 0px 0px 0px",
              }}
            />
          </div>
        </div>
      )}

      {step === 1 && (
        <div className="create-account-box">
          <Typography
            variant="h2"
            sx={{
              margin: "30px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Enter your password
          </Typography>

          <div className="welcome-box-container">
            <TextInput
              title="Enter Password"
              value={password}
              onChange={setPassword}
              showSecure={() => setSecurePassword(!securePassword)}
              showEndIcon
              secure={securePassword}
              type={securePassword ? "password" : "text"}
            />

            <Button
              onClick={handlePassword}
              title="Continue"
              loading={loading}
            />
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="create-account-box">
          <Typography
            variant="h2"
            sx={{
              margin: "30px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Enter your pin
          </Typography>

          <div>
            <OTPInput otp={pin} onChange={setPin} />
            <Button
              onClick={handlePin}
              title="Continue"
              loading={loading}
              style={{
                padding: "15px 0px",
              }}
            />
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="create-account-box">
          <Typography
            variant="h2"
            sx={{
              margin: "0px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Upload Recovery Key
          </Typography>

          <img src={KeyLogo} />

          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <label
              htmlFor="file-upload"
              className="btn-box"
              style={{ width: "15%" }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "700",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Upload
              </Typography>
              <input
                id="file-upload"
                type="file"
                onChange={handleFile}
                accept=".txt"
              />
            </label>
          )}
        </div>
      )}

      {step === 4 && (
        <div className="create-account-box">
          <Typography
            variant="h2"
            sx={{
              margin: "0px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Update sign in method
          </Typography>

          <div className="welcome-box-container">
            {loading ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "20px 0px",
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            ) : (
              <>
                <WelcomeBox
                  title="Continue with Google"
                  description=""
                  onClick={updateGoogle}
                  icon={<img src={Google} style={{ marginRight: 10 }} />}
                />
              </>
            )}
          </div>
        </div>
      )}

      {step === 5 && (
        <div className="create-account-box download-recovery-wrapper">
          <Typography
            variant="h2"
            sx={{
              // margin: '0px 0px 30px 0px',
              margin: "0",

              fontWeight: "700",
            }}
          >
            Download Recovery Key
          </Typography>

          <Typography
            variant="body2"
            sx={{
              margin: "10px 0px 0px 0px",
              color: "rgba(26, 28, 32, 0.5)",

              fontWeight: "500",
              textAlign: "center",
              // width: '25%',
            }}
          >
            This key will be required to reset your
            <br /> password if you lose access to all your trusted devices
          </Typography>

          <img
            src={KeyLogo}
            height={300}
            style={{
              height: "250px",
              margin: "30px 0px 30px 0px",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              margin: "10px 0px 10px 0px",
              color: "rgba(26, 28, 32, 0.5)",
              paddingInline: "20px",

              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Download your recovery key and keep it in a safe place so you always
            have access to your Stashed Account!
          </Typography>

          <Button
            title="Download to Files"
            onClick={generateRecoveryCode}
            loading={loading}
            disabled={false}
            style={{ padding: "15px 20px" }}
          />
        </div>
      )}

      {step === 6 && (
        <div className="create-account-box" style={{ marginTop: "1%" }}>
          <img src={SuccessLogo} />

          <Typography
            variant="body2"
            sx={{
              margin: "0px 0px 0px 0px",
              color: "rgba(26, 28, 32, 0.5)",
              fontWeight: "500",
              textAlign: "center",
              width: "25%",
              fontSize: "16px",
            }}
          >
            Signin method updated successfully
          </Typography>

          {/* <Lottie
          options={defaultOptions}
          height={400}
          style={{ position: "absolute", top: 100, left: 0 }}
        /> */}

          <img
            src={StashedCreatedLogo}
            style={{
              width: "auto",
              height: "250px",
              margin: "20px 0px 20px 0px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UpdateGoogleShare;
