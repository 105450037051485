import React, { useState } from "react";
import WelcomeBox from "components/WelcomeBox";

import Google from "assets/google.svg";
import Discord from "assets/discord.svg";

import StashedGlobe from "assets/stashed-globe.svg";

import { CircularProgress, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AuthHeader from "components/AuthHeader";
import useGoogleWeb3Auth from "hooks/useGoogleWeb3Auth";
import { loginWithGoogle } from "utils/google";
import { useAppDispatch } from "store/store";
import { setAccesToken, setMode, setUserInfo } from "@slices/appSlice";
import { setTorusKey } from "@slices/walletSlice";
import { isUserExists, showAlert } from "utils/utils";

import useTwitterWeb3Auth from "hooks/useTwitterWeb3Auth";
import { loginWithTwitter } from "utils/twitter";

const CreateAccount = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { web3auth } = useGoogleWeb3Auth();
  const { twitterWeb3auth } = useTwitterWeb3Auth();

  const signupWithGoogle = async () => {
    try {
      if (web3auth) {
        setLoading(true);

        const { email, name, profileImage, address, idToken } =
          await loginWithGoogle(web3auth);

        if (email) {
          const userData = await isUserExists(email, "a", "a");

          if (userData) {
            showAlert(
              "Account already exists. Try with a different Google Account."
            );

            await web3auth.logout();

            setLoading(false);
          } else {
            dispatch(setMode("google"));
            dispatch(setTorusKey(address[0].toString("hex")));
            dispatch(setUserInfo({ name, email, profileImage: profileImage }));
            dispatch(setAccesToken(idToken));
            setLoading(false);
            navigate("/create-password");
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert("Signup Failed!");
    }
  };

  const signupWithTwitter = async () => {
    try {
      if (twitterWeb3auth) {
        setLoading(true);

        const { email, name, profileImage, address, idToken } =
          await loginWithTwitter(twitterWeb3auth);

        if (email) {
          const userData = await isUserExists(email, "a", "a");

          if (userData) {
            showAlert(
              "Account already exists. Try with a different Google Account."
            );

            await twitterWeb3auth.logout();

            setLoading(false);
          } else {
            dispatch(setMode("google"));
            dispatch(setTorusKey(address[0].toString("hex")));
            dispatch(setUserInfo({ name, email, profileImage: profileImage }));
            dispatch(setAccesToken(idToken));
            setLoading(false);
            navigate("/create-password");
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert("Signup Failed!");
    }
  };

  return (
    <div className="create-account-container">
      <AuthHeader />

      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Set sign in method
        </Typography>

        <div className="welcome-box-container">
          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <WelcomeBox
                title="Continue with Google"
                description=""
                onClick={signupWithGoogle}
                icon={<img src={Google} style={{ marginRight: 10 }} />}
              />
              <WelcomeBox
                title="Continue with Twitter"
                description=""
                onClick={signupWithTwitter}
                icon={<img src={Discord} style={{ marginRight: 10 }} />}
              />
            </>
          )}
        </div>
      </div>
      <div className="stashed-globe">
        <img src={StashedGlobe} />
      </div>
    </div>
  );
};

export default CreateAccount;
