import Button from "components/NewButton";
import {
  Box,
  FormControl,
  Grid,
  Input,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { fetchBaseChainGasPrice } from "utils/gas";
import Web3 from "web3";

import abi from "abis/erc20abi.json";
import { APPROVE_AMOUNT, BASE_URL } from "constants/";
import axios from "axios";
import {
  decryptMessage,
  fetchCashAccountNativeBalance,
  fetchUsdPrice,
  showAlert,
} from "utils/utils";

import { bridgeAbi } from "abis/bridgeABI";
import { Wallet, ethers, providers, utils } from "ethers";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import CashIcon from "assets/cash-token.svg";
import UsdcIcon from "assets/usdc.svg";
import MidArrow from "assets/midArrow.svg";
import AccountItem from "components/AccountItem";
import AvatarLogo from "assets/avatarLogo.svg";
import NetworksList from "components/NetworksList";
import { setCashExchangeTxs } from "@slices/appSlice";

const CashExchange = () => {
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState("");

  const [step, setStep] = useState(0);

  const [txCost, setTxCost] = useState("0");
  const [error, setError] = useState("");
  const [generalError, setGeneralError] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState({});

  const { rootAccountInfo, accounts, balances, activeNetwork } = useAppSelector(
    (state) => state.app
  );

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (step === 3) {
        const { usdcAddress, executorAddress, chainId, accrossAddress, rpc } =
          CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];
        const web3 = new Web3(rpc);
        const { average } = await fetchBaseChainGasPrice(SupportedChainId.BASE);

        const usdcContract = new web3.eth.Contract(
          //@ts-ignore
          abi.abi,
          usdcAddress
        );

        const decimals = await usdcContract.methods.decimals().call();

        const transferGasLimit =
          (await usdcContract.methods
            .transfer(executorAddress, 123)
            .estimateGas({
              from: "0x4367a0fa79481cae4546c8f1031eebad3e0e6ffc",
            })) * 1.7;

        const txData = {
          recipient: "0x4367a0fa79481cae4546c8f1031eebad3e0e6ffc",
          originToken: usdcAddress,
          amount: Number(0.1) * 10 ** decimals, // incase of exactInput send the originToken value
          destinationChainId: activeNetwork,
          message: "0x",
          maxCount:
            "115792089237316195423570985008687907853269984665640564039457584007913129639935",
        };

        const bridgeContract = new web3.eth.Contract(bridgeAbi, accrossAddress);
        const estimateGasDeposit = await bridgeContract.methods
          .deposit(
            txData.recipient,
            txData.originToken,
            txData.amount,
            txData.destinationChainId,
            "18694000300000000",
            Math.floor(Date.now() / 1000),
            txData.message,
            txData.maxCount
          )
          .estimateGas({
            from: "0x4367a0fa79481cae4546c8f1031eebad3e0e6ffc",
          });

        const txCost =
          (Number(estimateGasDeposit) * average) / 10 ** 18 +
          (Number(transferGasLimit) * average) / 10 ** 18;

        const ethUsd = await fetchUsdPrice("ethereum");

        console.log("txCost", txCost, txCost * ethUsd);

        setTxCost((txCost * ethUsd).toFixed(3));
      }
    })();
  });

  const bridgeToCrypto = async () => {
    console.log(selectedAccount, activeNetwork, value);
    try {
      setLoading(true);
      const { usdcAddress, executorAddress, chainId, accrossAddress, rpc } =
        CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];
      const web3 = new Web3(rpc);
      const nonce = await web3.eth.getTransactionCount(
        rootAccountInfo.address,
        "latest"
      );
      const { average } = await fetchBaseChainGasPrice(SupportedChainId.BASE);

      const usdcContract = new web3.eth.Contract(
        //@ts-ignore
        abi.abi,
        usdcAddress
      );

      const decimals = await usdcContract.methods.decimals().call();

      const approvalGasLimit = await usdcContract.methods
        .approve(accrossAddress, APPROVE_AMOUNT)
        .estimateGas({ from: rootAccountInfo.address });

      const transferGasLimit =
        (await usdcContract.methods
          .transfer(executorAddress, 123)
          .estimateGas({ from: rootAccountInfo.address })) * 1.7;

      console.log(approvalGasLimit * average, approvalGasLimit, average);

      const allowance = await usdcContract.methods
        .allowance(rootAccountInfo.address, accrossAddress)
        .call();

      console.log(allowance);

      const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
      if (!Number(allowance)) {
        const txCost = (approvalGasLimit * average) / 10 ** 18;

        const signature = web3.eth.accounts.sign(txCost.toString(), pkey);

        const { data } = await axios.post(BASE_URL + "/transaction/sendEth", {
          fees: txCost.toString(),
          signature: signature.signature,
          address: rootAccountInfo.address,
        });

        let timer = setInterval(async () => {
          web3.eth.getTransactionReceipt(data.hash).then(async (res) => {
            if (res && res.status) {
              clearInterval(timer);
              const tx1 = {
                value: "0x0",
                to: usdcAddress,
                gasPrice: average,
                gasLimit: web3.utils.toHex((approvalGasLimit * 1.15).toFixed()),
                nonce,
                data: usdcContract.methods
                  .approve(accrossAddress, APPROVE_AMOUNT)
                  .encodeABI(),
              };

              const signedTx = await web3.eth.accounts.signTransaction(
                tx1,
                pkey
              );

              if (signedTx?.rawTransaction) {
                web3.eth
                  .sendSignedTransaction(signedTx.rawTransaction)
                  .on("transactionHash", async (tx) => {
                    console.log("first tx hash", tx);

                    showAlert(tx, "Approval Transaction In Progress");
                  })
                  .on("receipt", () => {
                    showAlert("Approval Transaction Successful", "Success");
                  });
              }
            }
          });
        }, 1000);
      }

      const calculateValueToSent =
        rootAccountInfo.usdTokenBalance - Number(value) > 0
          ? value
          : value - 0.5;
      const txData = {
        recipient: selectedAccount.address,
        originToken: usdcAddress,
        amount: Math.ceil(Number(calculateValueToSent) * 10 ** decimals), // incase of exactInput send the originToken value
        destinationChainId: activeNetwork,
        message: "0x",
        maxCount:
          "115792089237316195423570985008687907853269984665640564039457584007913129639935",
      };

      const transactionOpts = {
        toAddress: accrossAddress,
        gasPrice: 0,
        gasLimit: 0,
      };

      const { data: bridgeData } = await axios.post(
        BASE_URL + "/transaction/getBridgeData",
        {
          originToken: txData.originToken,
          chainId: SupportedChainId.BASE,
          destinationChainId: txData.destinationChainId,
          amount: txData.amount,
        }
      );

      console.log(bridgeData);

      // console.log(result.data?.relayFeePct, result.data?.timestamp);

      const bridge = new ethers.utils.Interface(bridgeAbi);
      const callData = bridge.encodeFunctionData("deposit", [
        txData.recipient,
        txData.originToken,
        txData.amount,
        txData.destinationChainId,
        bridgeData.relayFeePct,
        bridgeData.timestamp,
        txData.message,
        txData.maxCount,
      ]);

      const provider = new providers.JsonRpcProvider(rpc);

      const wallet = new Wallet(pkey, provider);

      const estimatedGasCostInHex = await wallet.estimateGas({
        to: transactionOpts.toAddress,
        data: callData,
        value: "0",
      });

      const formattedGasLimit = utils
        .formatUnits(estimatedGasCostInHex, "wei")
        .toString();

      console.log("estimateGasEthers", formattedGasLimit);

      const txCost =
        (Number(formattedGasLimit) * average) / 10 ** 18 +
        (Number(approvalGasLimit) * average) / 10 ** 18;

      console.log("txCost´´´´´´´´´´´´´´´´´´´´", txCost);

      const signature = web3.eth.accounts.sign(txCost.toString(), pkey);
      const { data } = await axios.post(BASE_URL + "/transaction/sendEth", {
        fees: txCost.toString(),
        signature: signature.signature,
        address: rootAccountInfo.address,
      });

      const rawTransaction = {
        to: transactionOpts.toAddress,
        value: 0,
        data: callData,
        gasPrice:
          transactionOpts.gasPrice == 0 ? average : transactionOpts.gasPrice,
        gasLimit:
          transactionOpts.gasLimit == 0
            ? web3.utils.toHex(formattedGasLimit)
            : transactionOpts.gasLimit,
        nonce: await wallet.getTransactionCount(),
      };

      console.log(rawTransaction);

      console.log("data", data);
      let timer = setInterval(async () => {
        web3.eth.getTransactionReceipt(data.hash).then(async (res) => {
          if (res && res.status) {
            console.log("ifffffffffffff");
            clearInterval(timer);

            const balance = await fetchCashAccountNativeBalance(
              rootAccountInfo.address,
              chainId
            );

            // execute transaction using gas from server

            const signedTx = await web3.eth.accounts.signTransaction(
              rawTransaction,
              pkey
            );

            if (signedTx?.rawTransaction) {
              web3.eth
                .sendSignedTransaction(signedTx.rawTransaction)
                .on("transactionHash", async (tx) => {
                  console.log("first tx hash", tx);
                  dispatch(setCashExchangeTxs(tx));
                })
                .on("receipt", async (conf) => {
                  //return gas amount equivalent to usdc to server
                  const usdcAmountToPayToExecutor = (
                    txCost *
                    balance.nativeTokenPrice *
                    1.2
                  ).toFixed(6);
                  console.log(usdcAmountToPayToExecutor);

                  const tx2 = {
                    value: "0x0",
                    to: usdcAddress,
                    gasPrice: average,
                    gasLimit: web3.utils.toHex(transferGasLimit.toFixed()),
                    nonce: nonce + 1,
                    data: usdcContract.methods
                      .transfer(
                        executorAddress,
                        Math.ceil(
                          Number(usdcAmountToPayToExecutor) * 10 ** decimals
                        ).toString()
                      )
                      .encodeABI(),
                  };
                  const signedTx2 = await web3.eth.accounts.signTransaction(
                    tx2,
                    pkey
                  );

                  if (signedTx2.rawTransaction) {
                    web3.eth
                      .sendSignedTransaction(signedTx2.rawTransaction)
                      .on("transactionHash", async (tx) => {
                        console.log("second has", tx);

                        showAlert(tx, "Transaction In Progress");
                      })
                      .on("receipt", (conf) => {
                        showAlert("Transaction successful", "Success");
                        navigate("/cash");

                        //return gas amount equivalent to usdc to server

                        setLoading(false);
                      })
                      .on("error", (error) => {
                        console.log(error);
                        setLoading(false);
                        showAlert("Transaction Failed");
                      });

                    setLoading(false);
                  }
                })
                .on("error", (error) => {
                  console.log(error);
                  setLoading(false);
                });
            }
          }
        });
      }, 1000);

      console.log(rawTransaction);
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert("Something went wrong");
    }
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;

    if (/[^0-9.]/.test(inputValue)) {
      setError("Special characters are not allowed");
      setGeneralError(true);
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("Only one decimal point is allowed");
      setGeneralError(false);
    } else if (!/^\d{0,10}(\.\d{0,6})?$/.test(inputValue)) {
      setError(
        "Maximum of 10 digits before decimals and 6 digits after decimals are allowed"
      );
      setGeneralError(true);
    } else if (parseFloat(inputValue) < 2.01) {
      setError("Value should not be less than $2.01");
      setGeneralError(false);
      setValue(inputValue);
    } else if (value && value > rootAccountInfo.usdTokenBalance) {
      setError("You do not have enough balance");
      setGeneralError(false);
      setValue(inputValue);
    } else {
      setError("");
      setGeneralError(false);
      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
        setGeneralError(true);
      } else if (parseFloat(newValue) < 2.01) {
        setError("Value should not be less than $2.01");
        setGeneralError(false);
        setValue(newValue);
      } else if (value && value > rootAccountInfo.usdTokenBalance) {
        setError("You do not have enough balance");
        setGeneralError(false);
        setValue(newValue);
      } else {
        setValue(newValue);
        setError("");
      }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
        setGeneralError(true);
      } else if (parseFloat(newValue) < 2.01) {
        setError("Value should not be less than $2.01");
        setGeneralError(false);
        setValue(newValue);
      } else if (value && value > rootAccountInfo.usdTokenBalance) {
        setError("You do not have enough balance");
        setGeneralError(false);
        setValue(newValue);
      } else {
        if (Number(newValue) > 0) {
          setValue(newValue);
        }
        setError("");
      }
    }
  };

  console.log(accounts, "accounts");
  return (
    <>
      {/* <Button title="Send" onClick={sendUsdc} loading={loading} /> */}

      <Box mt={6}>
        <NavigatorHeading
          title="Exchange Cash"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/cash");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Account", "Network", "Amount", "Exchange"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>
      <Box mt={5}>
        {step == 0 && (
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={6} sm={12}>
              <Typography
                sx={{
                  color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                  fontsize: 17,
                  fontWeight: 500,
                  textAlign: "center",
                  marginBottom: 3,
                }}
              >
                Exchange your cash for crypto
              </Typography>
              <Typography
                sx={{
                  color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                  fontsize: 17,
                  fontWeight: 500,
                  textAlign: "center",
                  marginBottom: 4,
                }}
              >
                Select which account you want to receive crypto on
              </Typography>

              <div className="accounts-list-exchange">
                {Object.keys(accounts).map((account, index) => (
                  <AccountItem
                    onClick={() => {
                      // switchAccount(account);
                      setSelectedAccount({
                        name: accounts[account].name,
                        address: accounts[account].smartAccountAddress,
                      });
                      setStep(1);
                    }}
                    key={account}
                    balance={
                      balances?.smartWalletBalance?.[
                        accounts[account].smartAccountAddress
                      ]
                    }
                    accountAd={accounts[account].smartAccountAddress}
                    name={accounts[account]?.name || `Account ${index + 1}`}
                    active={true}
                    activeTick={false}
                    avatar={
                      <Box className="account-bg-image">
                        <img
                          src={AvatarLogo}
                          alt="avatar"
                          style={{ marginRight: "10px" }}
                        />
                      </Box>
                    }
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        )}

        {step == 1 && (
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={6} sm={12}>
              <NetworksList
                nextStep={() => setStep(2)}
                title="Select which network you want to receive crypto on"
              />
            </Grid>
          </Grid>
        )}

        {step == 2 && (
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={6} sm={12} display="flex" justifyContent="center">
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "rgba(26, 28, 32, 0.50)",
                      fontFamily: "Helvetica Neue",
                      fontsize: 17,
                      fontWeight: 500,
                    }}
                  >
                    Amount to receive USDC to {selectedAccount.name}
                  </Typography>
                  <Box mt={2.5}>
                    <div className="input-container">
                      <FormControl sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            color: "#1A1C20",
                            width: "100%",
                            // height: "80px",
                            borderRadius: "10px",
                            fontSize: "25px",
                            fontFamily: "Space Grotesk",
                            fontWeight: "700",
                            border: "0.5px solid rgba(26, 28, 32, 0.50)",
                          }}
                        >
                          <Input
                            onChange={handleAmountChange}
                            onKeyDown={handleKeyDown}
                            placeholder="0.00"
                            style={{
                              padding: "0px 15px 0px 15px",
                            }}
                            endAdornment={
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box mr={1.25}>
                                  <img
                                    src={UsdcIcon}
                                    style={{
                                      marginTop: "10px",
                                    }}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    color: "#1A1C20",
                                    fontFamily: "Space Grotesk",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "normal",
                                    marginTop: 0,
                                    marginLeft: -1.5,
                                  }}
                                >
                                  USDC
                                </Box>
                              </Box>
                            }
                            value={value}
                            sx={{ width: "100%", padding: "20px" }}
                          />
                        </Box>

                        {error && (
                          <Typography
                            style={{ fontSize: 12, color: "red", marginTop: 5 }}
                          >
                            {error}
                          </Typography>
                        )}
                      </FormControl>
                    </div>
                  </Box>
                  <Box mt={5}>
                    <Button
                      title="Continue"
                      onClick={() => setStep(3)}
                      disabled={
                        !value ||
                        Number(value) > rootAccountInfo.usdTokenBalance ||
                        (!generalError && error)
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}

        {step == 3 && (
          <Grid container display="flex" justifyContent="center" py={4}>
            <Grid item lg={6} sm={12}>
              <Box mt={2.5} position={"relative"}>
                <div className="input-container" style={{ marginBottom: 10 }}>
                  <FormControl sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: "#EDEEF2",
                        color: "#1A1C20",
                        width: "100%",
                        // height: "80px",
                        borderRadius: "10px",
                        fontSize: "25px",
                        fontFamily: "Space Grotesk",
                        fontWeight: "700",
                        border: "0.5px solid rgba(26, 28, 32, 0.50)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: 15,
                          }}
                        >
                          <Box mr={1.25}>
                            <img src={CashIcon} />
                          </Box>
                          <Box
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              marginTop: -2,
                              marginLeft: -1.5,
                            }}
                          >
                            Cash
                          </Box>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginRight: 10,
                          }}
                        >
                          <Box
                            mr={1.25}
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                            }}
                          >
                            ${value}
                          </Box>
                          <Box
                            sx={{
                              color: "#8C8D8F",
                              fontFamily: "Space Grotesk",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }}
                          >
                            {value} USDbC
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </FormControl>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    zIndex: 2,
                  }}
                >
                  <img src={MidArrow} />
                </div>

                <div className="input-container" style={{ marginBottom: 10 }}>
                  <FormControl sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: "#EDEEF2",
                        color: "#1A1C20",
                        width: "100%",
                        // height: "80px",
                        borderRadius: "10px",
                        fontSize: "25px",
                        fontFamily: "Space Grotesk",
                        fontWeight: "700",
                        border: "0.5px solid rgba(26, 28, 32, 0.50)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: 15,
                          }}
                        >
                          <Box mr={1.25}>
                            <img src={UsdcIcon} />
                          </Box>
                          <Box
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              marginTop: -2,
                              marginLeft: -1.5,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            USDC
                            <Typography style={{ fontSize: 12, marginLeft: 5 }}>
                              (
                              {
                                SUPPORTED_NETWORKS[
                                  activeNetwork as keyof typeof SUPPORTED_NETWORKS
                                ].name
                              }
                              )
                            </Typography>
                          </Box>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginRight: 10,
                          }}
                        >
                          <Box
                            mr={1.25}
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                            }}
                          >
                            ${value}
                          </Box>
                          <Box
                            sx={{
                              color: "#8C8D8F",
                              fontFamily: "Space Grotesk",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }}
                          >
                            {value} USDC
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </FormControl>
                </div>
              </Box>
              <Typography
                sx={{
                  color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                  fontsize: 17,
                  fontWeight: 500,
                  textAlign: "left",
                  marginBottom: 4,
                  marginTop: 6,
                  textTransform: "uppercase",
                }}
              >
                Pay Fees with
              </Typography>
              <div className="input-container" style={{ marginBottom: 10 }}>
                <FormControl sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      backgroundColor: "#EDEEF2",
                      color: "#1A1C20",
                      width: "100%",
                      // height: "80px",
                      borderRadius: "10px",
                      fontSize: "25px",
                      fontFamily: "Space Grotesk",
                      fontWeight: "700",
                      border: "0.5px solid rgba(26, 28, 32, 0.50)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingTop: 15,
                        }}
                      >
                        <Box mr={1.25}>
                          <img src={CashIcon} />
                        </Box>
                        <Box
                          sx={{
                            color: "#1A1C20",
                            fontFamily: "Space Grotesk",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "normal",
                            marginTop: -2,
                            marginLeft: -1.5,
                          }}
                        >
                          Cash
                        </Box>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginRight: 10,
                        }}
                      >
                        <Box
                          mr={1.25}
                          sx={{
                            color: "#1A1C20",
                            fontFamily: "Space Grotesk",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "normal",
                          }}
                        >
                          ${txCost}
                        </Box>
                      </div>
                    </Box>
                  </Box>
                </FormControl>
              </div>
              <Typography
                sx={{
                  color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                  fontsize: 17,
                  fontWeight: 500,
                  textAlign: "center",
                  marginBottom: 4,
                  marginTop: 6,
                }}
              >
                Your crypto will show in your account within 9 minutes
              </Typography>
              <Button
                title="Send"
                onClick={bridgeToCrypto}
                loading={loading}
                disabled={
                  Number(value) + Number(txCost) >
                  rootAccountInfo.usdTokenBalance
                }
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default CashExchange;
