import React, { useState } from "react";
import WelcomeBox from "components/WelcomeBox";

import Google from "assets/google.svg";
import Discord from "assets/discord.svg";
import UpdateSignInImg from "assets/update-signin.png";

import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AuthHeader from "components/AuthHeader";
import { useAppDispatch } from "store/store";
import useGoogleWeb3Auth from "hooks/useGoogleWeb3Auth";
import { loginWithGoogle } from "utils/google";
import { fetchEncryptedKeys, isUserExists, showAlert } from "utils/utils";
import {
  removeAccounts,
  setAccesToken,
  setActiveAccount,
  setFetchedKeys,
  setIsAccountCreated,
  setMode,
  setOwnReferralCode,
  setReferredTo,
  setUserInfo,
} from "@slices/appSlice";
import { setTorusKey } from "@slices/walletSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../../src/constants";

const RecoverChooseMethod = () => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { web3auth } = useGoogleWeb3Auth();

  const [step, setStep] = useState(0);

  const signinWithGoogle = async () => {
    try {
      if (web3auth) {
        setLoading(true);

        const { address, email, idToken } = await loginWithGoogle(web3auth);

        dispatch(setIsAccountCreated(false));
        dispatch(removeAccounts({}));
        dispatch(
          setActiveAccount({ address: "", secret: "", smartAccountAddress: "" })
        );

        if (email) {
          const data = await fetchEncryptedKeys(idToken);
          dispatch(setAccesToken(idToken));
          if (!data) {
            showAlert("Email does not exist");

            setLoading(false);
            await web3auth.logout();
          } else {
            dispatch(setReferredTo(data.referedTo));
            dispatch(setOwnReferralCode(data.referenceCode));

            dispatch(
              setUserInfo({
                email: data.email,
                name: data.username,
              })
            );

            dispatch(setTorusKey(address[0].toString("hex")));

            dispatch(
              setFetchedKeys({
                rootAddress: data.address,
                key1: data.keys[0]?.key1,
                key2: data.keys[0]?.key2,
                key3: data.keys[0]?.key3,
                key4: data.keys[0]?.key4,
              })
            );
            setStep(1);
            navigate("/forgot-password/recover-pin", {
              state: {
                currentStep: step + 1,
                pathName: "/forgot-password/recover-choose-method",
              },
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      showAlert("Recovery Failed");
    }
  };

  const signupWithDiscord = () => {
    showAlert("Coming Soon");
  };

  const totalSteps = stepsCount["/forgot-password/recover-choose-method"] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>
      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "0px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Choose method
        </Typography>

        <div className="welcome-box-container">
          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <WelcomeBox
                title="Continue with Google"
                description=""
                onClick={signinWithGoogle}
                icon={<img src={Google} style={{ marginRight: 10 }} />}
              />
              <WelcomeBox
                title="Continue with Discord"
                description=""
                onClick={signupWithDiscord}
                icon={<img src={Discord} style={{ marginRight: 10 }} />}
              />
            </>
          )}
        </div>
        {/* <div className="stashed-update-signin">
          <img src={UpdateSignInImg} />
        </div> */}
      </div>
    </div>
  );
};

export default RecoverChooseMethod;
