import {
  Box,
  CircularProgress,
  Grid,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import CardLayout from "components/CardLayout";

import DepositIcon from "assets/deposit.svg";
import WithdrawIcon from "assets/withdraw.svg";
import SendIcon from "assets/send-icon.svg";
import FillupIcon from "assets/fillup-icon.svg";

import Button from "components/NewButton";
import ChipButton from "components/ChipButton";
import ExpandableChart from "components/ExpandableChart";
import InfoCard from "components/InfoCard";

import CashBackground from "assets/backgrounds/cash.png";
import NoDataUI from "../../../components/NoDataCard";
import GasIcon from "assets/gasIcon.svg";
import GasCard from "assets/gasCard.svg";

import BlankGas from "assets/blankGas.svg";
import InfoCardAmount from "components/InfoCardAmount";
import CenterItem from "components/CenterItem";
import SearchInput from "components/SearchInput";

import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import Web3 from "web3";
import paymasterAbi from "abis/paymasterabi.json";
import { tokensForGas } from "constants/topTokensConf";
import { provider } from "web3-core";
import { Paymaster_Token_Address } from "../../../contract-integration/constants";
import { ethers } from "ethers";
import { numFormatter } from "../../../utils/utils";
import TokenImage from "../../../components/TokenImage";
import { getAssetsDollarWorth } from "utils/portfolio";
import FilterModal from "components/Modal/FilterModal";

const WithdrawListComponent: FC<{
  asset: any;
  nextStep?: () => void;
  transactionForm?: boolean;
}> = ({ asset, nextStep = () => {}, transactionForm = false }) => {
  console.log(
    "file: WithdrawTokensList.tsx:19  asset: selectedTokenaaaaa",
    asset
  );
  const navigate = useNavigate();
  const { nfts, activeAccount, activeNetwork } = useAppSelector(
    (state) => state.app
  );
  console.log("file: index.tsx:17  activeNetwork:", activeNetwork);
  const { amount: tokenBalance, symbol: tokenSymbol } = numFormatter(
    asset.balance,
    4
  );
  const { amount: tokenUsdBalance, symbol: tokenUsdSymbol } = numFormatter(
    asset.balanceInUSD,
    4
  );
  const dispatch = useAppDispatch();
  // const { amount: usdBalance, symbol: usdSymbol } = numFormatter(
  //   asset.estimated_balance,
  //   2
  // );

  return (
    <Grid
      sx={{
        padding: "10px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        cursor: "pointer",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
      onClick={() => {}}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <TokenImage logo={asset.logoUrl} />
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          <div>{asset.token}</div>
          <div
            style={{
              // color: "rgba(26, 28, 32, 0.50)",
              fontFamily: "Helvetica Neue",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {tokenBalance + tokenSymbol + " "} {asset.token}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          <div>${tokenUsdBalance + tokenUsdSymbol}</div>
          {/* <div
              style={{
                // color: "rgba(26, 28, 32, 0.50)",
                fontFamily: "Helvetica Neue",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Min Price
            </div> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

const GasView = () => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [cummulativeGas, setCummulativeGas] = useState(0);
  const [depositedTokens, setDepositedTokens] = useState<Array<any>>([]);

  const { accounts } = useAppSelector((state) => state.app);
  const activeAccountAddress = Object.values(accounts)[0].smartAccountAddress;

  const [openModal, setOpenModal] = useState(false);

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: provider,
    decimal: number
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );

    try {
      const accountHolding = await paymasterContract.methods
        .balances(tokenAddress, address)
        .call();
      return Number(ethers.utils.formatUnits(String(accountHolding), decimal));
    } catch (error) {
      console.error("Error reading account holdings:", error);
      return 0; // Handle the error gracefully
    }
  };

  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    async function fetchBalances() {
      setLoading(true);
      let tokenAddressesForMobula = tokensForGas.map(
        (tokenInfo) => tokenInfo.tokenAddressForMobula
      );
      let depositedTokenObject: Array<any> = [];

      // Call the getAssetsDollarWorth function with the tokenAddressesForMobula array as the argument
      const tokenData = await getAssetsDollarWorth(tokenAddressesForMobula);

      // Extract the prices from the returned object
      let tokenWorthInUSD = Object.values(tokenData).map(
        (token) => token.price
      );

      // Now you can use the tokenWorthInUSD array in your loop
      for (let i = 0; i < tokensForGas.length; i++) {
        const tokenInfo = tokensForGas[i];
        const { tokenAddress, rpc, decimal } = tokenInfo;
        const balance = await readAccountHoldings(
          tokenAddress,
          activeAccountAddress,
          rpc,
          decimal
        );

        const tokenWorthInUSDForCurrentToken = tokenWorthInUSD[i];
        const balanceInUSD = balance * tokenWorthInUSDForCurrentToken;
        if (balance > 0) {
          depositedTokenObject.push({ ...tokenInfo, balance, balanceInUSD });
        }

        // Add the balance to the cumulative balance for the token
        if (!cumulativeBalances[tokenAddress]) {
          cumulativeBalances[tokenAddress] = balanceInUSD;
        } else {
          cumulativeBalances[tokenAddress] += balanceInUSD;
        }
      }
      // Calculate the total cumulative balance
      let totalBalance = 0;
      for (const tokenAddress of Object.keys(cumulativeBalances)) {
        totalBalance += cumulativeBalances[tokenAddress];
      }
      setDepositedTokens(depositedTokenObject);

      setCummulativeGas(totalBalance);
      setLoading(false);
    }

    fetchBalances();
  }, [accounts]);

  return loading ? (
    <div style={{ width: "100%", textAlign: "center", margin: "25% 0px" }}>
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <Box width={"100%"}>
      <Box
        className="flex-row-center"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          gap: "26px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        {/* User Info & Balance Area */}
        <Box
          sx={{
            paddingTop: "0px !important",
            flex: 1,
            width: "100%",
          }}
        >
          {/* <Card title="@igors" ens="" balance="50.67" /> */}
          <InfoCard
            backgroundImage={`url(${GasCard})`}
            backgroundPosition="0px"
            backgroundSize="cover"
            amount={<InfoCardAmount amount={cummulativeGas.toString()} />}
            title={`Gas`}
          />
        </Box>

        {/* Receive Cash Area */}
        {/* <Grid item xs={12} md={6} sx={{ paddingTop: "0 !important" }}>
          <>
          
          </>
        </Grid>
      </Grid> */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          {/* <ExpandableChart
            data={[45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]}
            title='igor'
            amount='5392.49'
            percentageChange='5'
          /> */}
          <CardLayout
            backgroundColor="white"
            style={{
              // height: '100%',
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              height: "205px",
            }}
          >
            <NoDataUI
              title={`Your Gas Account\n
                 lets your pay for transactions\n
                 across all of your accounts on any chain!`}
              description=""
              gasCard={true}
              icon={GasIcon}
            />
          </CardLayout>
        </Box>
      </Box>

      <Box mt={2.5}>
        <Box
          className="flex-row-center"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "flex-start",
            flexFlow: "wrap",
            paddingBottom: "20px",
          }}
        >
          <ChipButton
            title={cummulativeGas > 0 ? "Top Up" : "Fill Up"}
            onClick={() => {
              navigate("/gas/fillup");
            }}
            icon={FillupIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={12}
            iconWidth={13}
            fontFamily="Helvetica Neue"
          />
          <ChipButton
            title="Withdraw"
            onClick={() => {
              navigate("/gas/withdraw");
            }}
            icon={SendIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={11}
            iconWidth={14}
            fontFamily="Helvetica Neue"
          />
        </Box>

        <Box display="flex">
          <Box flex={1} alignItems={"center"}>
            <SearchInput
              handleOnChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              paddingLeft: "12px",
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <CenterItem>
              <img src={SearchOptionsIcon} onClick={() => setOpenModal(true)} />
            </CenterItem>

            <FilterModal open={openModal} onClose={() => setOpenModal(false)} />

            <CenterItem>
              <div className="tab-chip-box" style={{ maxWidth: 110 }}>
                <Typography
                  variant={"h5"}
                  fontWeight={500}
                  color="white"
                  fontSize={12}
                  fontFamily={"Helvetica Neue"}
                  textAlign="center"
                >
                  + ADD TOKEN
                </Typography>
              </div>
            </CenterItem>
          </Box>
        </Box>

        <Box
          className="flex-row-center"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
            paddingBottom: "40px",
            flexDirection: "column",
            gap: "26px",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          {depositedTokens && depositedTokens?.length > 0 ? (
            (() => {
              const filteredAssets = depositedTokens.filter((item) =>
                item?.token?.toLowerCase()?.includes(searchText.toLowerCase())
              );

              if (filteredAssets.length === 0) {
                return (
                  <Box textAlign={"center"} py={5}>
                    <Typography variant="h3">No results found</Typography>
                  </Box>
                );
              }
              console.log(
                "file: TokensList.tsx:316  filteredAssets:",
                filteredAssets
              );

              return filteredAssets.map((asset) => (
                <WithdrawListComponent
                  asset={asset}
                  nextStep={() => {}}
                  transactionForm={false}
                />
              ));
            })()
          ) : (
            <CardLayout
              backgroundColor="white"
              style={{ flex: 1, width: "100%" }}
            >
              <Slide in={true} timeout={500} direction={"down"}>
                <Box
                  className="flex-col-center"
                  style={{
                    padding: "20px 0px",
                    height: "275px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "100%",
                    position: "absolute",
                  }}
                >
                  <Typography
                    variant={"h5"}
                    fontWeight={700}
                    color="rgba(26, 28, 32, 1)"
                    fontSize={25}
                    style={{ cursor: "pointer" }}
                  >
                    Fuel Up and Go
                  </Typography>
                  <Typography
                    variant={"h5"}
                    fontWeight={400}
                    color="rgba(26, 28, 32, 0.5)"
                    fontSize={12}
                    style={{ cursor: "pointer" }}
                  >
                    Get Started!
                  </Typography>
                  <img
                    src={BlankGas}
                    width={"100%"}
                    height={"100%"}
                    style={{
                      overflow: "hidden",
                    }}
                  />

                  <div
                    style={{
                      width: "70%",
                      maxWidth: "305px",
                      marginTop: "-6%",
                    }}
                  >
                    <Button
                      title="Fill Up"
                      style={{
                        backgroundColor: "rgba(235, 236, 238, 1)",
                        margin: 0,
                      }}
                      textStyle={{ color: "rgba(26, 28, 32, 1)" }}
                      onClick={() => {
                        navigate("/gas/fillup");
                      }}
                    />
                  </div>
                </Box>
              </Slide>
            </CardLayout>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GasView;
