import axios, { AxiosResponse } from "axios";
import { GRAPH_PERIODS, MOBULA_API } from "constants/";
import { SupportedChainId, SUPPORTED_NETWORKS } from "constants/chains";
import { Interval } from "enums";
import { GraphQueryData, Holdings } from "interfaces";

const HOUR = 3600000; // 1 hour in milliseconds
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;

const intervals = [HOUR, DAY, WEEK, MONTH];

const formatGraphData = (arr: number[][]) => {
  let timestamps: number[] = [];
  let prices: number[] = [];

  arr.map((ar) => {
    // ar.map(a => {
    timestamps.push(ar[0]);
    prices.push(ar[1]);
    // });
  });

  return { timestamps, prices };
};

export const fetchGraphDataApi = async (
  tokens: GraphQueryData,
  period: GRAPH_PERIODS
) => {
  console.log("tokens", tokens);

  try {
    let coindataTemp: {
      [coingeckoId: string]: {
        data: number[];
        profit: number;
      };
    } = {};

    let marketData: number[][] = [];

    const coingeckoIds = Object.keys(tokens);

    const settledTokens = await Promise.allSettled(
      coingeckoIds.map(async (coingeckoId) => {
        try {
          const holding = tokens[coingeckoId];

          if (holding === 0) {
            return { holding: [], prices: [] };
          }

          const { data } = await axios.get(
            `https://api.coingecko.com/api/v3/coins/${coingeckoId}/market_chart/`,
            {
              params: {
                vs_currency: "usd",
                days: `${period}`, //1,7,14,30 days
              },
            }
          );

          if (!data?.prices) {
            return { holding: [], prices: [] };
          }

          marketData = data?.prices;
          return { holding, prices: data?.prices };
        } catch (error) {
          console.log("err", error);
          return { holding: [], prices: [] };
        }
      })
    );

    settledTokens.forEach((_, index) => {
      if (settledTokens[index].status !== "fulfilled") {
        return;
      }

      //@ts-ignore
      const prices = settledTokens[index].value?.prices;

      if (prices.length === 0) {
        return;
      }
      //@ts-ignore
      const name = settledTokens[index].value?.name;
      //@ts-ignore
      const holding = settledTokens[index].value?.holding;

      const offset = Math.floor(Number(prices.length) / 15);
      const onlyPrices = prices.map((element) => element[1]);
      let filteredPrices = [];
      for (let i = 0; i < onlyPrices.length; i += offset) {
        filteredPrices.push(onlyPrices[i] * holding);
      }
      filteredPrices = filteredPrices.slice(0, 15); //limit array to length of 15
      filteredPrices[14] = onlyPrices[onlyPrices.length - 1] * holding; // forcefully update the last index of the array last value

      const profit = filteredPrices[14] - filteredPrices[0];

      coindataTemp[name] = {
        data: filteredPrices,
        profit,
      };
    });

    const aggregatedPrices = Array(15).fill(0);
    let totalProfit = 0;
    Object.keys(coindataTemp).forEach((coingeckoId) => {
      totalProfit = totalProfit + coindataTemp[coingeckoId].profit;
      coindataTemp[coingeckoId].data.forEach((itm, i) => {
        aggregatedPrices[i] += itm;
      });
    });

    const { prices, timestamps } = formatGraphData(marketData);

    return {
      profit: totalProfit,
      prices,
      timestamps,
    };
  } catch (error) {
    console.log("err", error);
    throw error;
  }

  // await axios.post(
  //   `${"https://dev-api.sonar.studio/wallet"}/graph/data/${period}`,
  //   {
  //     ...tokens,
  //   }
  // );
};

export const fetchGraphData = async (
  holdings: Holdings,
  address: string,
  type: GRAPH_PERIODS,
  network: SupportedChainId
) => {
  const coingeckoId =
    SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS].coingeckoId;
  try {
    let obj: { [key: string]: number } = {};

    let sum = 0;

    if (holdings[address]?.tokens?.length > 0) {
      holdings[address].tokens.map((tk) => {
        sum += Number(tk.priceInUSD);
      });
    }
    obj[coingeckoId] = holdings[address].nativeBalance;

    sum += Number(holdings[address].nativeBalanceUsd);

    if (!sum) {
      return { data: [], profit: 0, sum: 0 };
    }

    const { prices, timestamps, profit } = await fetchGraphDataApi(obj, type);

    if (prices) {
      console.log("data", prices, timestamps);
      return {
        data: prices,
        timestamps,
        profit: profit.toFixed(2),
        sum: sum.toFixed(2),
      };
    } else {
      return { data: [], profit: 0, sum: 0, timestamps: [] };
    }
  } catch (error) {
    console.log("err", error);
    throw error;
  }
};

export const fetchWalletHistory = async (
  address: string,
  selectedTime: number
) => {
  const timeInSeconds = [
    //1H, 1D, 1W, 1M,
    3600, 86400, 604800, 2629743,
  ];
  const from = Math.floor(Date.now() / 1000) - timeInSeconds[selectedTime];
  const to = Math.floor(Date.now() / 1000);
  try {
    const baseURL = `${MOBULA_API}/wallet/transactions`;
    const fetchURL = `${baseURL}?wallet=${address}&from=${from}&to=${to}&blockchains=Ethereum%2CPolygon&limit=50`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data?.transactions;

    console.log("wallet history:", res);

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const fetchWalletPortfolio = async (address: string) => {
  try {
    const tokenWorthInUSD =
      address.toLowerCase() ===
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ? "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
        : address;

    const baseURL = `${MOBULA_API}/wallet/portfolio`;
    const fetchURL = `${baseURL}?wallet=${tokenWorthInUSD}& blockchains=Ethereum%2CPolygon`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data.data;

    console.log("wallet history--------------------------:", res, address);

    return res;
  } catch (error) {
    console.log("err in fetching wallet portfolio from mobula", error);
    return [];
  }
};

export const getEthDollarValue = async ({
  asset = "Ethereum",
}: {
  asset?: string;
}) => {
  try {
    const baseURL = `${MOBULA_API}/metadata?asset=${asset}`;
    const { data } = await axios.get(baseURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data.data;

    console.log("wallet history--------------------------:", res);

    return res.price;
  } catch (error) {
    console.log("err in fetching wallet portfolio from mobula", error);
    return 0;
  }
};

export const getTokenDollarValue = async (tokenContractAddress: string) => {
  try {
    const tokenWorthInUSD =
      tokenContractAddress.toLowerCase() ===
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ? "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
        : tokenContractAddress;
    const baseURL = `${MOBULA_API}/metadata?asset=${tokenWorthInUSD}`;
    const { data } = await axios.get(baseURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data.data;

    console.log("wallet history--------------------------:", res);

    return res.price;
  } catch (error) {
    console.log("err in fetching wallet portfolio from mobula", error);
    return 0;
  }
};

const getNextBoundary = (timestamp: number, interval: Interval) => {
  return timestamp + (interval - (timestamp % interval));
};

export const filterDataByInterval = (
  data: Array<number[]>,
  interval: Interval
) => {
  const result = [];
  let nextBoundary = 0;

  for (let i = 0; i < data.length; i++) {
    const [timestamp, value] = data[i];

    if (timestamp >= nextBoundary) {
      result.push([timestamp, value]);
      nextBoundary = getNextBoundary(timestamp, intervals[interval]);
    }
  }

  return result;
};

export const fetchTokenTransactions = async (
  wallet: string,
  from: number,
  to: number,
  asset: string
) => {
  try {
    const baseURL = await `${MOBULA_API}/wallet/transactions`;
    const fetchURL = `${baseURL}?wallet=${wallet}&from=${from}&to=${to}&asset=${asset}&blockchains=Ethereum%2CPolygon`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data?.transactions;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const fetchTokenChartData = async (
  wallet: string,
  from: number,
  to: number,
  asset: string
) => {
  try {
    const baseURL = await `${MOBULA_API}/wallet/history`;
    const fetchURL = `${baseURL}?wallet=${wallet}&from=${from * 1000}&to=${
      to * 1000
    }&asset=${asset}&blockchains=Ethereum%2CPolygon`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data?.balance_history;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const fetchCashAccountBalance = async (
  wallet: string,
  asset: string
) => {
  try {
    const baseURL = await `${MOBULA_API}/wallet/portfolio`;
    const fetchURL = `${baseURL}?wallet=${wallet}&asset=${asset}&blockchains=Base`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const fetchCashAccountChartData = async (
  from: number,
  to: number,
  wallet: string,
  asset: string
) => {
  try {
    const baseURL = await `${MOBULA_API}/wallet/history`;
    const fetchURL = `${baseURL}?wallet=${wallet}&from=${from * 1000}&to=${
      to * 1000
    }&asset=${asset}&blockchains=Base`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    console.log("data===============", data);
    const res = data?.data?.balance_history;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const fetchCashAccountHistory = async (wallet: string) => {
  try {
    const baseURL = `${MOBULA_API}/wallet/transactions`;
    const fetchURL = `${baseURL}?wallet=${wallet}&blockchains=Base&asset=USD%20Coin&order=desc`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    console.log("data===============", data);
    const res = data?.data?.transactions;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const getAssetsDollarWorth = async (assets: string[]) => {
  try {
    const baseURL = `${MOBULA_API}/market/multi-data?assets=${assets}`;
    const { data } = await axios.get(baseURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data.data;

    console.log("wallet history--------------------------:", res);

    return res;
  } catch (error) {
    console.log("err in fetching wallet portfolio from mobula", error);
    return 0;
  }
};

export const fetchCryptoAccHistory = async (
  address: string,
  selectedTime: number
) => {
  const timeInSeconds = [
    //1H, 1D, 1W, 1M,
    3600, 86400, 604800, 2629743,
  ];
  const from = Math.floor(Date.now() / 1000) - timeInSeconds[selectedTime];
  try {
    const baseURL = `${MOBULA_API}/wallet/history`;
    const fetchURL = `${baseURL}?wallet=${address}&from=${from}&blockchains=Ethereum%2CPolygon&limit=50`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data?.balance_history;

    console.log("wallet history:", res);

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};
