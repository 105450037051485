import { Box } from "@mui/material";
import { setActiveAccount } from "@slices/appSlice";
import AccountItem from "components/AccountItem";
import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { formatAmount } from "utils/utils";
import AvatarLogo from "assets/avatarLogo.svg";
const AccountsList: FC<{}> = () => {
  const { holdings, accounts, activeAccount, balances } = useAppSelector(
    (state) => state.app
  );

  const dispatch = useAppDispatch();

  const switchAccount = (address: string) => {
    dispatch(
      setActiveAccount({
        address,
        secret: accounts[address].secret,
        smartAccountAddress: accounts[address].smartAccountAddress,
        accountName: accounts[address]?.name,
      })
    );
  };

  return (
    <div className="modal-content">
      <Box
        boxShadow="0px 8px 24px 10px rgb(24 39 75 / 5%), 0px 6px 12px 4px rgb(24 39 75 / 1%)"
        borderRadius="10px"
      >
        <AccountItem
          balance={balances.accumulatedAllAccounts}
          name={"All Accounts"}
          active={false}
          avatar={<Box className="account-bg-image all-accounts"></Box>}
        />
      </Box>

      <div className="accounts-list">
        {Object.keys(accounts).map((account, index) => (
          <AccountItem
            key={account}
            accountAd={accounts[account].smartAccountAddress}
            balance={
              balances?.smartWalletBalance?.[
                accounts[account].smartAccountAddress
              ] || 0
            }
            name={accounts[account]?.name || `Account ${index + 1}`}
            active={
              accounts[account].smartAccountAddress ===
              activeAccount.smartAccountAddress
            }
            avatar={
              <></>
              // <Box className='account-bg-image'>
              //   {' '}
              //   <img
              //     src={AvatarLogo}
              //     alt='avatar'
              //     style={{ marginRight: '10px' }}
              //   />
              // </Box>
            }
            onClick={() => switchAccount(account)}
          />
        ))}
      </div>
    </div>
  );
};

export default AccountsList;
