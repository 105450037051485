import { Grid, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import CenterItem from "../../../../components/CenterItem";
import TokenImage from "../../../../components/TokenImage";
import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import TransactionCollapeWrapper from "../../../../components/TransactionCollapse";
import TransactionMetaData from "./TransactionMetadata";
import { formatAmount, numFormatter } from "../../../../utils/utils";
import { SUPPORTED_NETWORKS } from "constants/chains";

const TransactionList: FC<{
  props: {
    tx: any;
    // title: string;
    // id: string;
    // actualValue: string;
    // belowValue: string;
    // status: string;
    // time: string;
    // imgSrc: string;
  };
}> = ({ props }) => {
  const { tx } = props;
  console.log("🚀 ~ file: index.tsx:24 ~ tx:", tx);
  console.log("file: index.tsx:12  asdasdasdtx:", tx.valueIn$);

  const { amount } = numFormatter(tx.valueIn$ ?? "", 8);

  const { amount: formattedAmount, symbol } = numFormatter(
    tx.transferAmount ?? "",
    5
  );

  const date = new Date(tx.timestamp);

  const options: any = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const timeFormatter = new Intl.DateTimeFormat("en-US", options);
  const formattedTime = timeFormatter.format(date);

  const chainName =
    SUPPORTED_NETWORKS[tx.network as keyof typeof SUPPORTED_NETWORKS]?.name;

  return (
    <TransactionCollapeWrapper
      TitleComponent={
        <Grid
          sx={{
            padding: "15px 15px",
            display: "flex",

            width: "100%",
            justifyContent: "space-between",
            cursor: "pointer",
            borderRadius: "10px",
          }}
          //   onClick={() => {
          //     navigate(asset.asset.name, { state: { asset } });
          //   }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <TokenImage logo={tx.iconURL} />
            <Grid
              sx={{
                color: "#1A1C20",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                fontFamily: "Space Grotesk",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              <div>
                {/* {tx.transactionMethod.toLowerCase() === "send" ||
                tx.transactionMethod.toLowerCase() === "crypto"
                  ? "Send"
                  : "Swap"}{" "} */}
                {tx?.action ?? ""} {tx.assetName}
              </div>
              <div
                style={{
                  // color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  lineHeight: "normal",
                }}
              >
                <Typography
                  fontWeight={400}
                  fontSize={14}
                  style={{
                    color: "rgba(26, 28, 32, 0.50)",
                  }}
                >
                  {formattedTime}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                color: "#1A1C20",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                fontFamily: "Space Grotesk",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                textAlign: "right",
              }}
            >
              <div
                style={{
                  // color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                  fontSize: "17px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                - ${formatAmount(tx.valueIn$)}
              </div>
              <Typography
                fontWeight={400}
                fontSize={14}
                style={{
                  color: "rgba(26, 28, 32, 0.50)",
                }}
              >
                {formattedAmount}
                {symbol} {tx.assetName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }
      BodyComponent={
        <>
          <TransactionMetaData
            props={{
              chain: chainName ?? "",
              date: tx.timestamp ?? "Time",
              network: tx.network ?? "",
              status: "Successful",
              toEns: tx.toAddressEns ? `@${tx.toAddressEns}` : tx.toAddress,
              fromEns: tx?.addressEns?.startsWith("0x")
                ? tx.address
                : `@${tx.addressEns}`,

              networkFee: `${tx.networkFeesIn$}`,
              scanUrl: tx.scanLink,
              action: tx?.action,
            }}
          />
        </>
      }
    />
  );
};
export default TransactionList;
