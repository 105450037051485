import { CircularProgress, Typography } from "@mui/material";
import pbkdf2 from "pbkdf2";
import sss from "shamirs-secret-sharing";
import WelcomeBox from "components/WelcomeBox";
import useGoogleWeb3Auth from "hooks/useGoogleWeb3Auth";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import Google from "assets/google.svg";
import { loginWithGoogle } from "utils/google";
import {
  decryptMessage,
  encryptMessage,
  fetchEncryptedKeys,
  getEnsFromAddress,
  saveRecoveryCode,
  showAlert,
  updateEncryptedKeys,
} from "utils/utils";
import {
  setAccesToken,
  setEncryptedSeedPhraseWithPassword,
  setFetchedKeys,
  setUserInfo,
} from "@slices/appSlice";
import {
  setHashedPassword,
  setHashedPin,
  setRecoveryCode,
  setTorusKey,
} from "@slices/walletSlice";
import TextInput from "components/TextInput";
import Button from "components/NewButton";
import KeyLogo from "assets/key.svg";
import OTPInput from "components/OtpInput";
import { SHARES, THRESHOLD } from "constants/";
import StashedCreatedLogo from "assets/stash-created.svg";
import SuccessLogo from "assets/success.svg";

const UpdatePinShare = () => {
  const [type, setType] = useState("create");
  const [step, setStep] = useState(0);

  const [loading, setLoading] = useState(false);
  const [securePassword, setSecurePassword] = useState(true);
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");

  const { web3auth } = useGoogleWeb3Auth();

  const { activeAccount, rootAccountInfo, user, activeNetwork, accessToken } =
    useAppSelector((state) => state.app);
  const {
    fetchedKeys: { key1, key2, key3, key4, rootAddress },
  } = useAppSelector((state) => state.app);
  const { torusKey, recoveryCode, hashedPassword, hashedPin } = useAppSelector(
    (state) => state.wallet
  );
  const dispatch = useAppDispatch();

  const fromStringToBuffer = (val) => Buffer.from(val, "hex");
  const bufferToReadable = (buffer) => {
    return buffer.toString("hex");
  };

  const signinWithGoogle = async () => {
    try {
      if (web3auth) {
        setLoading(true);

        const { address, email, idToken } = await loginWithGoogle(web3auth);

        if (email !== user.email) {
          showAlert("Please provide your email");
          setLoading(false);
        } else {
          if (email) {
            const data = await fetchEncryptedKeys(idToken);
            dispatch(setAccesToken(idToken));
            if (!data) {
              showAlert("Email does not exist");

              setLoading(false);
              await web3auth.logout();
            } else {
              dispatch(
                setUserInfo({
                  email: data.email,
                  name: data.username,
                })
              );

              dispatch(setTorusKey(address[0].toString("hex")));

              dispatch(
                setFetchedKeys({
                  rootAddress: data.address,
                  key1: data.keys[0]?.key1,
                  key2: data.keys[0]?.key2,
                  key3: data.keys[0]?.key3,
                  key4: data.keys[0]?.key4,
                })
              );

              setStep(1);
              setLoading(false);
            }
          }
        }
      }
    } catch (error) {
      setLoading(false);
      showAlert("Recovery Failed");
    }
  };

  const handlePasswordContinue = async () => {
    try {
      setLoading(true);

      const hashedPassword = pbkdf2
        .pbkdf2Sync(password, "salt", 1, 32, "sha512")
        .toString("hex");

      const decryptedKey = decryptMessage(key2, hashedPassword);

      if (decryptedKey) {
        dispatch(setHashedPassword(hashedPassword));
        setStep(2);
        setLoading(false);
      } else {
        setLoading(false);
        showAlert("Password is incorrect");
      }
    } catch (error) {
      setLoading(false);
      showAlert("Password is incorrect");

      console.log("err", error);
    }
  };

  const handleFile = (e) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      console.log(file);
      if (file) {
        if (file.type === "text/plain") {
          const reader = new FileReader();

          reader.onload = (event) => {
            const content = event.target.result;
            console.log(content);
            dispatch(setRecoveryCode(content));

            console.log(key4);
            const decryptedKey = decryptMessage(key4, content);
            if (decryptedKey) {
              setStep(3);
              setLoading(false);
            } else {
              showAlert("Invalid Recovery Code");
            }
            setLoading(false);
          };

          reader.readAsText(file);
        } else {
          showAlert("Invalid file format. Please select a text file.");
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      showAlert("Invalid Recovery Code");
    }
  };

  const createPin = async () => {
    try {
      if (type === "create") {
        setType("confirm");
      } else {
        if (pin !== confirmPin) {
          showAlert("Pin does not match");
        } else {
          setLoading(true);
          const hashedPin = pbkdf2
            .pbkdf2Sync(pin, "salt", 1, 32, "sha512")
            .toString("hex");

          const hashedTorus = pbkdf2
            .pbkdf2Sync(torusKey, "salt", 1, 32, "sha512")
            .toString("hex");

          dispatch(setHashedPin(hashedPin));
          dispatch(setTorusKey(hashedTorus));

          const decryptedKey1 = decryptMessage(key1, hashedTorus);
          const decryptedKey2 = decryptMessage(key4, recoveryCode);
          const decryptedKey3 = decryptMessage(key2, hashedPassword);

          console.log(decryptedKey1, decryptedKey2, decryptedKey3);
          const decryptMnemonic = sss
            .combine([
              fromStringToBuffer(decryptedKey1),
              fromStringToBuffer(decryptedKey2),
              fromStringToBuffer(decryptedKey3),
            ])
            .toString();

          console.log(decryptMnemonic);

          const secretBuffer = Buffer.from(decryptMnemonic);
          const generatedShares = sss.split(secretBuffer, {
            shares: SHARES,
            threshold: THRESHOLD,
          });

          let s = generatedShares.map((s) => bufferToReadable(s));

          const encryptedShare1 = encryptMessage(s[0], hashedTorus);
          const encryptedShare2 = encryptMessage(s[1], hashedPassword);
          const encryptedShare3 = encryptMessage(s[2], hashedPin);

          const updatedRecoveryCode = hashedTorus + hashedPassword + hashedPin;

          const encryptedShare4 = encryptMessage(s[3], updatedRecoveryCode);

          await updateEncryptedKeys(
            user.email,
            encryptedShare1,
            encryptedShare2,
            encryptedShare3,
            encryptedShare4,
            accessToken
          );

          const encryptedSeedPhraseWithPassword = encryptMessage(
            decryptMnemonic,
            hashedPassword
          );

          dispatch(
            setEncryptedSeedPhraseWithPassword(encryptedSeedPhraseWithPassword)
          );

          setLoading(false);
          setStep(4);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const generateRecoveryCode = async () => {
    try {
      setLoading(true);

      const recoveryCode = torusKey + hashedPassword + hashedPin;

      saveRecoveryCode(recoveryCode);
      dispatch(setRecoveryCode(recoveryCode));

      setStep(5);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      {step === 0 && (
        <div className="create-account-box">
          <Typography
            variant="h2"
            sx={{
              margin: "0px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Choose method
          </Typography>

          <div className="welcome-box-container">
            {loading ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "20px 0px",
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            ) : (
              <>
                <WelcomeBox
                  title="Continue with Google"
                  description=""
                  onClick={signinWithGoogle}
                  icon={<img src={Google} style={{ marginRight: 10 }} />}
                />
              </>
            )}
          </div>
        </div>
      )}

      {step === 1 && (
        <div className="create-account-box">
          <Typography
            variant="h2"
            sx={{
              margin: "30px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Enter your password
          </Typography>

          <div className="welcome-box-container">
            <TextInput
              title="Password"
              value={password}
              onChange={setPassword}
              showSecure={() => setSecurePassword(!securePassword)}
              showEndIcon
              secure={securePassword}
              type={securePassword ? "password" : "text"}
              style={{
                width: "350px",
                fontfamily: "Helvetica Neue",
              }}
            />

            <Button
              onClick={handlePasswordContinue}
              title="Continue"
              loading={loading}
              style={{
                width: "380px",
                padding: "15px 0px",
                marginTop: "10px",
              }}
            />
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="create-account-box">
          <Typography
            variant="h2"
            sx={{
              margin: "0px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Upload Recovery Key
          </Typography>

          <img
            src={KeyLogo}
            style={{
              margin: "30px 0px 30px 0px",
            }}
          />

          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <label
              htmlFor="file-upload"
              className="btn-box"
              style={{ width: "350px" }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "700",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Upload
              </Typography>
              <input
                id="file-upload"
                type="file"
                onChange={handleFile}
                accept=".txt"
              />
            </label>
          )}
        </div>
      )}

      {step === 3 && (
        <div className="create-account-box">
          <Typography
            variant="h2"
            sx={{
              margin: "30px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            {type === "create" ? "Create" : "Confirm"} your pin
          </Typography>

          <div>
            {type === "create" ? (
              <OTPInput otp={pin} onChange={setPin} />
            ) : (
              <OTPInput otp={confirmPin} onChange={setConfirmPin} />
            )}

            <Button
              onClick={createPin}
              title="Continue"
              disabled={!pin && !confirmPin}
              loading={loading}
            />
          </div>
        </div>
      )}
      {step === 4 && (
        <div className="create-account-box download-recovery-wrapper">
          <Typography
            variant="h2"
            sx={{
              // margin: '0px 0px 30px 0px',
              margin: "0",

              fontWeight: "700",
            }}
          >
            Download Recovery Key
          </Typography>

          <Typography
            variant="body2"
            sx={{
              margin: "10px 0px 0px 0px",
              color: "rgba(26, 28, 32, 0.5)",

              fontWeight: "500",
              textAlign: "center",
              // width: '25%',
            }}
          >
            This key will be required to reset your
            <br /> password if you lose access to all your trusted devices
          </Typography>

          <img
            src={KeyLogo}
            height={300}
            style={{
              height: "250px",
              margin: "30px 0px 30px 0px",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              margin: "10px 0px 10px 0px",
              color: "rgba(26, 28, 32, 0.5)",
              paddingInline: "20px",

              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Download your recovery key and keep it in a safe place so you always
            have access to your Stashed Account!
          </Typography>

          <Button
            title="Download to Files"
            onClick={generateRecoveryCode}
            loading={loading}
            disabled={false}
            style={{ padding: "15px 20px" }}
          />
        </div>
      )}
      {step === 5 && (
        <div className="create-account-box" style={{ marginTop: "1%" }}>
          <img src={SuccessLogo} />

          <Typography
            variant="body2"
            sx={{
              margin: "0px 0px 0px 0px",
              color: "rgba(26, 28, 32, 0.5)",
              fontWeight: "500",
              textAlign: "center",
              width: "25%",
              fontSize: "16px",
            }}
          >
            Signin method updated successfully
          </Typography>

          {/* <Lottie
          options={defaultOptions}
          height={400}
          style={{ position: "absolute", top: 100, left: 0 }}
        /> */}

          <img
            src={StashedCreatedLogo}
            style={{
              width: "auto",
              height: "250px",
              margin: "20px 0px 20px 0px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UpdatePinShare;
