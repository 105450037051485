import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  walletMode: "",
  selectedToken: [],
  fromToken: [],
  toToken: [],
  txStatus: false,
  torusKey: "",
  walletLoading: false,
  dataLoading: false,
  networkSwitchFromModal: false,
  isLogged: false,
  hashedPin: "",
  recoveryCode: "",
  hashedPassword: "",
  isAccountDeployed: false,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setHashedPassword: (state, action) => {
      state.hashedPassword = action.payload;
    },
    setRecoveryCode: (state, action) => {
      state.recoveryCode = action.payload;
    },
    setHashedPin: (state, action) => {
      state.hashedPin = action.payload;
    },
    setWalletMode: (state, action) => {
      state.walletMode = action.payload;
    },
    setSelectedToken: (state, action) => {
      state.selectedToken = action.payload;
    },
    setFromToken: (state, action) => {
      state.fromToken = action.payload;
    },
    setToToken: (state, action) => {
      state.toToken = action.payload;
    },
    setTxStatus: (state, action) => {
      state.txStatus = action.payload;
    },
    setTorusKey: (state, action) => {
      state.torusKey = action.payload;
    },

    setWalletLoading: (state, action) => {
      state.walletLoading = action.payload;
    },

    setDataLoading: (state, action) => {
      state.dataLoading = action.payload;
    },

    setNetworkSwitchFromModal: (state, action) => {
      state.networkSwitchFromModal = action.payload;
    },

    setIsLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setIsAccountDeployed: (state, action) => {
      state.isAccountDeployed = action.payload;
    },
  },
});

export const {
  setDataLoading,
  setHashedPassword,
  setRecoveryCode,
  setHashedPin,
  setWalletMode,
  setSelectedToken,
  setTxStatus,
  setFromToken,
  setToToken,
  setTorusKey,
  setWalletLoading,
  setNetworkSwitchFromModal,
  setIsLogged,
  setIsAccountDeployed,
} = walletSlice.actions;

export default walletSlice.reducer;
