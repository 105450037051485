import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@mui/material";

import KeyLogo from "assets/key.svg";

import AuthHeader from "components/AuthHeader";
import { decryptMessage, showAlert } from "utils/utils";
import { useAppDispatch, useAppSelector } from "store/store";
import { setRecoveryCode } from "@slices/walletSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../../src/constants";

const UploadRecoveryKey = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const {
    fetchedKeys: { key4 },
  } = useAppSelector((state) => state.app);

  const handleFile = (e) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      console.log(file);
      if (file) {
        if (file.type === "text/plain") {
          const reader = new FileReader();

          reader.onload = (event) => {
            const content = event.target.result;
            console.log(content);
            dispatch(setRecoveryCode(content));

            console.log(key4);
            const decryptedKey = decryptMessage(key4, content);
            if (decryptedKey) {
              setStep(3);
              navigate("/forgot-pin/create-pin", {
                state: {
                  currentStep: step + 1,
                  pathName: pathName,
                },
              });
            } else {
              showAlert("Invalid Recovery Code");
            }
            setLoading(false);
          };

          reader.readAsText(file);
        } else {
          showAlert("Invalid file format. Please select a text file.");
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      showAlert("Invalid Recovery Code");
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>
      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "0px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Upload Recovery Key
        </Typography>

        <img
          src={KeyLogo}
          style={{
            margin: "30px 0px 30px 0px",
          }}
        />

        {loading ? (
          <div
            style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <label
            htmlFor="file-upload"
            className="btn-box"
            style={{ width: "350px" }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "700",
                color: "white",
                textAlign: "center",
              }}
            >
              Upload
            </Typography>
            <input
              id="file-upload"
              type="file"
              onChange={handleFile}
              accept=".txt"
            />
          </label>
        )}
      </div>
    </div>
  );
};

export default UploadRecoveryKey;
