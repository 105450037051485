import { Box, Container, Grid, useTheme } from '@mui/material'
import SidebarComponent from 'components/Sidebar'
import TabBar from 'components/TabBar'
import React from 'react'
import { Outlet } from 'react-router-dom'

const AppRoutes = () => {
  const theme = useTheme()

  return (
    <div className='app-container'>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} sm={5} md={3} lg={3}>
          <SidebarComponent />
        </Grid>
        <Grid item xs={12} sm={7} md={9} lg={9}>
          <Box
            sx={{
              marginTop: '60px',
              marginRight: '50px',
            }}
          >
            <TabBar />
          </Box>
          <Box
            sx={{
              maxWidth: '1500px',
              justifyContent: 'left',
              display: 'flex',
              marginRight: '100px',
              [theme.breakpoints.down('md')]: {
                marginRight: '50px',
              },
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default AppRoutes
