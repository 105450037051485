import React, { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Snackbar, Typography } from "@mui/material";
import BasicButton from "components/Button";
import { useAppSelector } from "store/store";

import WalletIcon from "assets/walletIcon.svg";
import CopyIcon from "assets/copyIcon.svg";
import Input from "components/Input";

const AccountCreated: FC<{ onClose: () => void }> = ({ onClose }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { lastCreatedAccount } = useAppSelector((state) => state.app);

  const onCopy = () => {
    setShowSnackbar(true);
    navigator.clipboard.writeText(lastCreatedAccount.smartAccountAddress);
  };

  return (
    <Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Address copied!"
      />
      <Typography
        variant="h5"
        fontSize={14}
        fontWeight={400}
        color="rgba(26, 28, 32, 0.50)"
        my={2.5}
        style={{
          textAlign: "start",
          marginBottom: "15px",
          fontFamily: "Space Grotesk",
        }}
      >
        New account has been created!
      </Typography>

      <Box>
        <Input
          title="Crypto Tag"
          addBorder
          value={lastCreatedAccount.accountName}
          disabled={true}
        />
        <Input
          title="Address"
          addBorder
          value={lastCreatedAccount.smartAccountAddress}
          disabled={true}
          showEndIcon
          endIconAction={onCopy}
        />
      </Box>
      <Box className="modal-footer-buttons">
        <BasicButton
          title="Use this account"
          onClick={onClose}
          id="account_created_modal_close"
        />
      </Box>
    </Box>
  );
};

export default AccountCreated;
