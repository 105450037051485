import { createRoot } from "react-dom/client";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { store as DevStore } from "store/store";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";

import "./index.css";
import { SocketProvider } from "./socketContext";

const container = document.getElementById("root") as Element;
const devPersistor = persistStore(DevStore);

// console.log = function () {};

createRoot(container).render(
  <Provider store={DevStore}>
    <PersistGate loading={null} persistor={devPersistor}>
      <SocketProvider>
        <App />
      </SocketProvider>
    </PersistGate>
  </Provider>
);

serviceWorkerRegistration.unregister();
