import { Fade, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";

import BasicButton from "../Button";

const SendTxComponent: FC<{
  loading: boolean;
  handleSend: () => void;
  // tokenForPayment: string;
  gasTokenSelect: React.ReactElement;
  top20TokenSelect: React.ReactElement;
  // setTokenForPaymentDecimal: (decimal: number) => void;
  finalOpState: any;
  // SetTokenForPayment: (address: string) => void;
  error: boolean;
  // selectedDepositTokenAddress: string;
  // setTokenForPaymentBalance: (balance: number) => void;
  // setSelectedDepositTokenAddress: (address: string) => void;
  // setError: (error: boolean) => void;
  txByDeposited: boolean;
  children: React.ReactNode;
  isSwap: boolean;
  isCashout?: boolean;
  isShowCancelButton?: boolean;
  onReject?: () => void;
}> = ({
  loading,
  // SetTokenForPayment,
  gasTokenSelect,
  top20TokenSelect,
  error,
  handleSend,
  finalOpState,
  txByDeposited,
  isSwap,
  children,
  isShowCancelButton = false,
  isCashout = false,
  onReject,
}) => {
  return (
    <>
      <Box width={"75%"} display={"flex"} flexDirection={"column"} gap={2}>
        <Box>
          {!txByDeposited ? (
            <Fade in={!txByDeposited}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                alignItems={"center"}
              >
                <Box width="100%">{top20TokenSelect}</Box>
              </Box>
            </Fade>
          ) : (
            <>
              <Fade in={txByDeposited}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                  alignItems={"center"}
                >
                  {gasTokenSelect}
                </Box>
              </Fade>
            </>
          )}
          {children}
          <Stack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"row"}
          >
            {isShowCancelButton && (
              <BasicButton
                title={"Reject"}
                onClick={onReject}
                className="transfer-btn"
                style={{ marginRight: "5px" }}
                loading={false}
                mode={"active"}
                id="send"
              />
            )}
            <BasicButton
              title={
                txByDeposited
                  ? `${isSwap ? "Send" : "Send"} ${
                      !isShowCancelButton ? " with Deposited Gas" : ""
                    }`
                  : `${isSwap ? "Swap" : isCashout ? "Cash Out" : "Send"}`
              }
              onClick={error || !finalOpState ? () => {} : handleSend}
              style={{
                cursor: error || !finalOpState ? "not-allowed" : "pointer",
              }}
              className="transfer-btn"
              loading={loading}
              mode={"active"}
              id="send"
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default SendTxComponent;
