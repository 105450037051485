import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";

import Logo from "assets/stashed-logo.svg";
import MiniLogo from "assets/stashed-mini-logo.svg";
import ActiveCashIcon from "assets/active-cash.svg";
import CashIcon from "assets/cash.svg";

import ReferralsIcon from "assets/referrals.svg";
import ActiveReferralsIcon from "assets/active-referrals.svg";

import AppsIcon from "assets/apps.svg";
import ActiveAppsIcon from "assets/active-apps.svg";

import GasIcon from "assets/gas.svg";
import ActiveGasIcon from "assets/active-gas.svg";

import NftsIcon from "assets/nfts.svg";
import ActiveNftsIcon from "assets/active-nft.svg";

import CryptoIcon from "assets/crypto.svg";
import ActiveCryptoIcon from "assets/active-crypto.svg";

import "./styles.css";
import CenterItem from "../CenterItem";

const SidebarComponent = () => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState("cash");
  const matchesWidth = useMediaQuery("(max-width: 1050px)");
  const [menuItems, setMenuItems] = useState([
    {
      title: "Cash",
      to: "/cash",

      icon: <img src={CashIcon} alt="cash" />,
      activeIcon: <img src={ActiveCashIcon} alt="cash" />,
      selected: false,
      visibility: "visible",
    },
    {
      title: "Crypto",
      to: "/crypto",

      icon: <img src={CryptoIcon} alt="CryptoIcon" />,
      activeIcon: <img src={ActiveCryptoIcon} alt="CryptoIcon" />,
      selected: false,
      visibility: "visible",
    },

    {
      title: "NFTs",
      to: "/nfts",

      icon: <img src={NftsIcon} alt="tokens" />,
      activeIcon: <img src={ActiveNftsIcon} alt="tokens" />,
      selected: false,
      visibility: "visible",
    },
    {
      title: "Gas",
      to: "/gas",

      icon: <img src={GasIcon} alt="GasIcon" />,
      activeIcon: <img src={ActiveGasIcon} alt="GasIcon" />,
      selected: false,
      visibility: "visible",
    },

    {
      title: "Apps",
      to: "/dapp-connection",

      icon: <img src={AppsIcon} alt="AppsIcon" />,
      activeIcon: <img src={ActiveAppsIcon} alt="AppsIcon" />,
      selected: false,
      visibility: "visible",
    },
    {
      title: "Referrals",
      to: "/referral",

      icon: <img src={ReferralsIcon} alt="ReferralsIcon" />,
      activeIcon: <img src={ActiveReferralsIcon} alt="ReferralsIcon" />,
      selected: false,
      visibility: "visible",
    },
    // {
    //   title: "Transaction",
    //   to: "/transaction-success",

    //   icon: <img src={ReferralsIcon} alt="ReferralsIcon" />,
    //   activeIcon: <img src={ActiveReferralsIcon} alt="ReferralsIcon" />,
    //   selected: false,
    //   visibility: "visible",
    // },
    {
      title: "On Ramp",
      to: "/on-ramp",

      icon: <img src={ReferralsIcon} alt="ReferralsIcon" />,
      activeIcon: <img src={ActiveReferralsIcon} alt="ReferralsIcon" />,
      selected: false,
      visibility: "visible",
    },
  ]);

  const { pathname } = useLocation();

  console.log("PATHAAAAAAAAA", pathname);

  return (
    <div className="sidebar-box">
      <div>
        <img
          src={matchesWidth ? MiniLogo : Logo}
          height={50}
          style={{
            marginTop: "-4px",
          }}
        />
      </div>

      <div className="menu-items-list">
        {menuItems.map((item, index) => (
          <div
            className="menu-item"
            onClick={() => {
              setActiveMenu(item.title.toLowerCase());
              navigate(item.to);
            }}
          >
            {pathname.includes(item.to) &&
            activeMenu === item.title.toLowerCase()
              ? item.activeIcon
              : item.icon}
            <Typography
              variant={"h5"}
              fontWeight={500}
              color={
                pathname.includes(item.to) &&
                activeMenu === item.title.toLowerCase()
                  ? "black"
                  : "rgba(156, 163, 175, 1)"
              }
              fontSize={15}
              style={{ marginLeft: 15 }}
            >
              {item.title}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarComponent;
