import { Chain, CustomChain } from "@ethereumjs/common";

// import EthNetworkIcon from "assets/ETHNetworkIcon.svg";
import EthNetworkIcon from "assets/EthIcon.svg";

import BinanceIcon from "assets/BinanceIcon.svg";
import ArbitrumIcon from "assets/ArbitrumIcon.png";
// import PolygonIcon from "assets/PolygonIcon.svg";
import PolygonIcon from "assets/PolygonRoundIcon.svg";

import OptimismIcon from "assets/OptimismIcon.svg";
// import MantleIcon from "assets/mantle.png";
import MantleIcon from "assets/mantle.png";

import { topTokensEthereum, topTokensPolygon } from "./topTokensConf";

export const ETHERSCAN_API_KEY = "M193VGUECIDFKWVWAJA9ZPQNKBANY9613B";
export const BSC_API_KEY = "E4EU36PNSJEURVVB9FZEZ5VEW6VMAM83WS";
export const POLYGON_API_KEY = "9RQBN2EHMAFFZUGTH9IKZK4IHJV7U4MIPU";
export const ARBITRUM_API_KEY = "AGTAE34B2ZN88QQ1ZMTS2G7ZG4Y8Q1VGDM";
export const OPTIMISM_API_KEY = "6UYU737J6QDAHA5C9I984UFS6THCPDUHC7";

export enum SupportedChainId {
  ETHEREUM_MAINNET = 1,
  ETHEREUM_GOERLI = 5,
  BSC = 56,
  BSCT = 97,
  POLYGON = 137,
  MUMBAI = 80001,
  ARBITRUM = 42161,
  OPTIMISM = 10,
  MANTLE_TESTNET = 5001,
  BASE = 8453,
}

export const supportedMainnetChainIds = [1, 137];
// "0x55d398326f99059ff775485246999027b3197955"  BNB
// "0xdac17f958d2ee523a2206206994597c13d831ec7" eth
// "0xc2132d05d31c914a87c6611c10748aeb04b58e8f" polygon
// "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9" arbitrun
// "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58" optimism

export const SUPPORTED_NETWORKS = {
  [SupportedChainId.ETHEREUM_GOERLI]: {
    name: "goerli",
    CHAIN_TX: Chain.Goerli,
    chainId: SupportedChainId.ETHEREUM_GOERLI,
    rpc: `https://rpc.ankr.com/eth_goerli/`,
    rpcUrl: `https://rpc.ankr.com/eth_goerli`,
    wss: `wss://eth-goerli.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    api: "https://api-goerli.etherscan.io/api",
    gasFeesUrl: "https://api.etherscan.io/api",
    ALCHEMY_API_KEY: process.env.ALCHEMY_GOERLI_API_KEY,
    alchemy_url: `https://eth-goerli.g.alchemy.com/v2/${process.env.ALCHEMY_GOERLI_API_KEY}`,
    block_explorer_url: "https://goerli.etherscan.io/",
    swap1InchApiurl: "",
    oneInchContract: "",
    api_key: ETHERSCAN_API_KEY,
    symbol: "ETH",
    coingeckoId: "ethereum",
    icon: EthNetworkIcon,
    ankrSymbol: "",
    chainName: "Goerli",
    networkName: "Goerli Network",
    nativeTokenName: "Ether",
    defaultSwapToToken: "",
    scanName: "Goerliscan",
    owlracle_gas_url: "",
    jiffyScanName: "georli",
    paymasterAddress: "",
    dummyToken: "",
    topTokens: {},
    accrossAddress: "",
    usdcAddress: "",
    usdcDecimals: 6,
    swapRouter02: "",
    blockaid: "ethereum",
  },
  [SupportedChainId.ETHEREUM_MAINNET]: {
    CHAIN_TX: Chain.Mainnet,
    name: "Ethereum",
    chainId: SupportedChainId.ETHEREUM_MAINNET,
    rpc: `https://rpc.ankr.com/eth/`,
    rpcUrl: `https://rpc.ankr.com/eth`,
    wss: `wss://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    api: "https://api.etherscan.io/api",
    gasFeesUrl: "https://api.etherscan.io/api",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    alchemy_url: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    swap1InchApiurl: "https://api.1inch.dev/swap/v5.2/1",
    oneInchContract: "",
    block_explorer_url: "https://etherscan.io/",
    api_key: ETHERSCAN_API_KEY,
    symbol: "ETH",
    coingeckoId: "ethereum",
    icon: EthNetworkIcon,
    ankrSymbol: "eth",
    chainName: "Ethereum",
    networkName: "Ethereum Network",
    nativeTokenName: "Ether",
    defaultSwapToToken: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    scanName: "Etherscan",
    owlracle_gas_url: "https://api.owlracle.info/v4/eth/gas",
    jiffyScanName: "mainnet",
    paymasterAddress: "",
    dummyToken: "",
    topTokens: topTokensEthereum,
    accrossAddress: "",
    usdcAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    usdcDecimals: 6,
    swapRouter02: "",
    blockaid: "ethereum",
  },
  [SupportedChainId.BSC]: {
    name: "Binance Smart Chain",
    chainId: SupportedChainId.BSC,
    CHAIN_TX: {
      name: "bnb",
      networkId: 56,
      chainId: 56,
    },
    rpc: `https://rpc.ankr.com/bsc/`,
    rpcUrl: `https://rpc.ankr.com/bsc/`,
    api: "https://api.bscscan.com/api",
    gasFeesUrl: "https://api.bscscan.com/api",
    wss: "wss://bsc-mainnet.blastapi.io/01f541c5-85b4-429d-b548-0f6db7dac0f0",
    ALCHEMY_API_KEY: "",
    alchemy_url: ``,
    swap1InchApiurl: "https://api.1inch.dev/swap/v5.2/56",
    oneInchContract: "",
    block_explorer_url: "https://bscscan.com/",
    api_key: BSC_API_KEY,
    symbol: "BNB",
    coingeckoId: "binancecoin",
    icon: BinanceIcon,
    ankrSymbol: "bsc",
    chainName: "Binance Smart Chain",
    networkName: "Binance Smart Chain",
    nativeTokenName: "BNB",
    defaultSwapToToken: "0x55d398326f99059ff775485246999027b3197955",
    scanName: "Bscscan",
    owlracle_gas_url: "https://api.owlracle.info/v4/bsc/gas",
    jiffyScanName: "bsc",
    paymasterAddress: "",
    dummyToken: "",
    topTokens: {},
    accrossAddress: "",
    usdcAddress: "",
    usdcDecimals: 6,
    swapRouter02: "",
    blockaid: "bsc",
  },
  [SupportedChainId.BSCT]: {
    CHAIN_TX: {
      name: "bnb",
      networkId: 97,
      chainId: 97,
    },
    name: "Binance Smart Chain Testnet",
    chainId: SupportedChainId.BSCT,
    rpc: `https://rpc.ankr.com/bsc_testnet_chapel/`,
    api: "https://api-testnet.bscscan.com/api",
    gasFeesUrl: "https://api-testnet.bscscan.com/api",
    wss: "wss://bsc-testnet.blastapi.io/248404e3-b804-43b8-909d-63c7c3194273",
    ALCHEMY_API_KEY: "",
    alchemy_url: "",
    swap1InchApiurl: "",
    oneInchContract: "",
    block_explorer_url: "https://testnet.bscscan.com/",
    api_key: BSC_API_KEY,
    symbol: "BNB",
    coingeckoId: "binancecoin",
    icon: BinanceIcon,
    ankrSymbol: "bsc",
    chainName: "Binance Smart Chain Testnet",
    networkName: "Binance Smart Chain Testnet",
    nativeTokenName: "BNB",
    defaultSwapToToken: "",
    scanName: "Bsc Testnet Scan",
    owlracle_gas_url: "",
    jiffyScanName: "",
    paymasterAddress: "",
    dummyToken: "",
    topTokens: {},
    accrossAddress: "",
    usdcAddress: "",
    usdcDecimals: 6,
    swapRouter02: "",
    blockaid: "bsc",
  },
  [SupportedChainId.POLYGON]: {
    name: "Polygon Mainnet",
    CHAIN_TX: CustomChain.PolygonMainnet,
    chainId: SupportedChainId.POLYGON,
    rpc: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}/`,
    rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    // rpc: `https://polygon-mainnet.g.alchemy.com/v2/`,
    // rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/`,
    wss: "wss://polygon-mainnet.blastapi.io/566f5dab-20f2-4ce9-9225-374a70da3722",
    api: "https://api.polygonscan.com/api",
    gasFeesUrl: "https://api.polygonscan.com/api",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    alchemy_url: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    swap1InchApiurl: "https://api.1inch.dev/swap/v5.2/137",
    oneInchContract: "0x1111111254eeb25477b68fb85ed929f73a960582",
    block_explorer_url: "https://polygonscan.com/",
    api_key: POLYGON_API_KEY,
    symbol: "MATIC",
    coingeckoId: "matic-network",
    icon: PolygonIcon,
    ankrSymbol: "polygon",
    chainName: "Polygon Network",
    networkName: "Polygon Network",
    nativeTokenName: "Matic",
    defaultSwapToToken: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    scanName: "Polygonscan",
    owlracle_gas_url: "https://api.owlracle.info/v4/poly/gas",
    jiffyScanName: "matic",
    paymasterAddress: "",
    dummyToken: "",
    topTokens: topTokensPolygon,
    accrossAddress: "0x9295ee1d8C5b022Be115A2AD3c30C72E34e7F096",
    usdcAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    usdcDecimals: 6,
    swapRouter02: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
    blockaid: "polygon",
  },
  [SupportedChainId.MUMBAI]: {
    CHAIN_TX: CustomChain.PolygonMumbai,
    name: "Polygon Mumbai",
    chainId: SupportedChainId.MUMBAI,
    rpc: `https://polygon-mumbai.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    rpcUrl: `https://polygon-mumbai.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    wss: "wss://polygon-mumbai.g.alchemy.com/v2/W6pTG4cOiva7Ri0dyyI6t_WS92YONNz3",
    api: "https://api-testnet.polygonscan.com/api",
    oneInchContract: "",
    gasFeesUrl: "https://api-testnet.polygonscan.com/api",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    alchemy_url: `https://polygon-mumbai.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    swap1InchApiurl: "",
    block_explorer_url: "https://mumbai.polygonscan.com/",
    api_key: POLYGON_API_KEY,
    symbol: "MATIC",
    coingeckoId: "matic-network",
    icon: PolygonIcon,
    ankrSymbol: "polygon",
    chainName: "Polygon Mumbai",
    networkName: "Polygon Mumbai",
    nativeTokenName: "Matic",
    defaultSwapToToken: "",
    scanName: "Mumbaiscan",
    owlracle_gas_url: "",
    jiffyScanName: "mumbai",
    paymasterAddress: "0x14938462082ceec0825fC1B1326C71cfd5ED1d9d",
    dummyToken: "0x6FFC7A6fB650bBDd4DCD99699a9F91AE3D4CD44D",
    topTokens: {},
    accrossAddress: "",
    usdcAddress: "",
    usdcDecimals: 6,
    swapRouter02: "",
    blockaid: "polygon",
  },
  [SupportedChainId.MANTLE_TESTNET]: {
    CHAIN_TX: {
      name: "mnt",
      networkId: 5001,
      chainId: 5001,
    },
    name: "Mantle Testnet",
    chainId: SupportedChainId.MANTLE_TESTNET,
    rpc: `https://rpc.testnet.mantle.xyz`,
    rpcUrl: `https://rpc.testnet.mantle.xyz`,
    wss: "wss://ws.testnet.mantle.xyz",
    api: "https://explorer.testnet.mantle.xyz/api",
    ALCHEMY_API_KEY: "",
    alchemy_url: ``,
    swap1InchApiurl: "",
    oneInchContract: "",
    block_explorer_url: "https://explorer.testnet.mantle.xyz/",
    api_key: "",
    symbol: "MNT",
    coingeckoId: "matic-network",
    icon: MantleIcon,
    ankrSymbol: "mantle",
    chainName: "Mantle Testnet",
    networkName: "Mantle Testnet",
    nativeTokenName: "Mantle",
    defaultSwapToToken: "",
    scanName: "Mantlescan",
    owlracle_gas_url: "",
    jiffyScanName: "",
    paymasterAddress: "",
    dummyToken: "",
    topTokens: {},
    accrossAddress: "",
    usdcAddress: "",
    usdcDecimals: 6,
    swapRouter02: "",
    blockaid: "",
  },
  [SupportedChainId.ARBITRUM]: {
    name: "Arbitrum One",
    chainId: SupportedChainId.ARBITRUM,
    rpc: `https://rpc.ankr.com/arbitrum/`,
    rpcUrl: `https://rpc.ankr.com/arbitrum`,
    wss: "",
    api: "https://api.arbiscan.io/api",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    alchemy_url: `https://arb-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    swap1InchApiurl: "https://api.1inch.dev/swap/v5.2/42161",
    oneInchContract: "",
    block_explorer_url: "https://arbiscan.io/",
    api_key: ARBITRUM_API_KEY,
    symbol: "ETH",
    coingeckoId: "ethereum",
    icon: ArbitrumIcon,
    ankrSymbol: "arbitrum",
    chainName: "Arbitrum One",
    networkName: "Arbitrum One",
    nativeTokenName: "Ethereum",
    defaultSwapToToken: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    scanName: "Arbitrumscan",
    owlracle_gas_url: "https://api.owlracle.info/v4/arb/gas",
    jiffyScanName: "arbitrum-one",
    paymasterAddress: "",
    dummyToken: "",
    topTokens: {},
    accrossAddress: "",
    usdcAddress: "",
    usdcDecimals: 6,
    swapRouter02: "",
    blockaid: "arbitrum",
  },
  [SupportedChainId.OPTIMISM]: {
    name: "Optimism",
    chainId: SupportedChainId.OPTIMISM,
    rpc: `https://rpc.ankr.com/optimism/`,
    rpcUrl: `https://rpc.ankr.com/optimism`,
    wss: "",
    api: "https://api-optimistic.etherscan.io/api",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    alchemy_url: `https://opt-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    swap1InchApiurl: "https://api.1inch.dev/swap/v5.2/10",
    oneInchContract: "",
    block_explorer_url: "https://optimistic.etherscan.io/",
    api_key: OPTIMISM_API_KEY,
    symbol: "ETH",
    coingeckoId: "ethereum",
    icon: OptimismIcon,
    ankrSymbol: "optimism",
    chainName: "Optimism",
    networkName: "Optimism",
    nativeTokenName: "Ethereum",
    defaultSwapToToken: "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
    scanName: "Optmisimscan",
    owlracle_gas_url: "https://api.owlracle.info/v4/opt/gas",
    jiffyScanName: "optimism",
    paymasterAddress: "",
    dummyToken: "",
    topTokens: {},
    accrossAddress: "",
    usdcAddress: "",
    usdcDecimals: 6,
    swapRouter02: "",
    blockaid: "optimism",
  },
};

export const CASH_SUPPORTED_NETWORK = {
  [SupportedChainId.BASE]: {
    name: "base",
    CHAIN_TX: {
      name: "base",
      networkId: 8453,
      chainId: 8453,
    },
    chainId: SupportedChainId.BASE,
    rpc: `https://mainnet.base.org`,
    rpcUrl: `https://mainnet.base.org`,
    wss: `wss://base.publicnode.com	`,
    api: "https://api.basescan.org/api",
    gasFeesUrl: "https://api.basescan.org/api",
    ALCHEMY_API_KEY: process.env.ALCHEMY_GOERLI_API_KEY,
    alchemy_url: `https://eth-goerli.g.alchemy.com/v2/${process.env.ALCHEMY_GOERLI_API_KEY}`,
    block_explorer_url: "https://basescan.org",
    swap1InchApiurl: "",
    oneInchContract: "",
    api_key: ETHERSCAN_API_KEY,
    symbol: "ETH",
    coingeckoId: "ethereum",
    icon: EthNetworkIcon,
    ankrSymbol: "",
    chainName: "BASE",
    networkName: "BASE Network",
    nativeTokenName: "Ether",
    defaultSwapToToken: "",
    scanName: "BaseScan",
    owlracle_gas_url: "",
    jiffyScanName: "base",
    paymasterAddress: "",
    dummyToken: "",
    topTokens: {},
    usdcAddress: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
    accrossAddress: "0x09aea4b2242abc8bb4bb78d537a67a245a7bec64",
    executorAddress: "0xAfe270984A30d0f689Cc8fCBd5512ac5d4196B0b",
    blockaid: "base",
  },
};

export enum SupportedChainNames {
  "Ethereum" = SupportedChainId.ETHEREUM_MAINNET,
  "BSC" = SupportedChainId.POLYGON,
  "BSCT" = SupportedChainId.POLYGON,
  "Polygon" = SupportedChainId.POLYGON,
  "ARBITRUM" = SupportedChainId.POLYGON,
  "OPTIMISM" = SupportedChainId.POLYGON,
  "MANTLE_TESTNET" = SupportedChainId.POLYGON,
}

export const TESTNET_NETWORKS = [
  // SupportedChainId.ETHEREUM_GOERLI,
  SupportedChainId.MUMBAI,
  // SupportedChainId.BSCT,
  // SupportedChainId.MANTLE_TESTNET,
];
export const MAINNET_NETWORKS = [
  // SupportedChainId.ETHEREUM_MAINNET,
  // SupportedChainId.POLYGON,
  // SupportedChainId.ARBITRUM,
  // SupportedChainId.OPTIMISM,
  // SupportedChainId.BSC,
];

export const MAX_SWAP = [
  SupportedChainId.ETHEREUM_MAINNET,
  SupportedChainId.BSC,
];
