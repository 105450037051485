import { Box, CircularProgress, MenuItem, Typography } from "@mui/material";
import ens from "@ensdomains/eth-ens-namehash";
import BasicButton from "components/Button";
import Input from "components/Input";
import { DropDownBox } from "pages/auth/styles";
import React, { FC, useEffect, useState } from "react";
import * as Bip39 from "bip39";
import * as ed25519 from "ed25519-hd-key";
import { hdkey } from "ethereumjs-wallet";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  checkSum,
  decryptMessage,
  deriveAccount,
  encryptMessage,
  getEnsFromAddress,
  getSmartAccountAddress,
  initalizeWeb3,
  isEnsExist,
  saveSubEns,
  showAlert,
  userNameRegexValidation,
} from "utils/utils";
import { EVM_DERIVATION_PATH } from "constants/";
import { Accounts } from "interfaces";
import {
  setAccounts,
  setActiveAccount,
  setBalances,
  setHoldings,
  setLastCreatedAccount,
} from "@slices/appSlice";
import BasicModal from "components/BasicModal";
import AccountCreated from "components/AccountCreated";
import { useLocation } from "react-router-dom";

const NewAccount: FC<{
  openNewModal: () => void;
  closeThisModal: () => void;
}> = ({ openNewModal, closeThisModal }) => {
  const [ensLoading, setEnsLoading] = useState(true);
  const [ensExists, setEnsExist] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const {
    encryptedSeedPhraseWithPassword,
    accounts,
    activeNetwork,
    rootAccountInfo,
    balances,
  } = useAppSelector((state) => state.app);
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const dispatch = useAppDispatch();

  const generatedMnemonic = decryptMessage(
    encryptedSeedPhraseWithPassword,
    hashedPassword
  );

  useEffect(() => {
    (async () => {
      const { smartAccountAddress } = await deriveNewAccount();
      const ens = await getEnsFromAddress(smartAccountAddress.toLowerCase());
      setEnsLoading(false);

      if (ens) {
        setEnsExist(true);
        setAccountName(ens);
      }
    })();
  }, [pathname]);

  const deriveNewAccount = async () => {
    const deriveChildNum = Object.keys(accounts).length + 1;

    const seed = Bip39.mnemonicToSeedSync(generatedMnemonic);
    const hdwallet = hdkey.fromMasterSeed(seed);
    const derivedPath = hdwallet.derivePath(EVM_DERIVATION_PATH);
    const child = derivedPath.deriveChild(deriveChildNum);
    const wallet = child.getWallet();
    const address = checkSum(`0x${wallet.getAddress().toString("hex")}`);
    const pk = wallet.getPrivateKey().toString("hex");
    const secret = encryptMessage(pk, hashedPassword);

    const smartAccountAddress = await getSmartAccountAddress(
      activeNetwork,
      address
    );

    return {
      address,
      smartAccountAddress,
      secret,
      pk,
    };
  };

  const handleFormValidation = (username: string) => {
    if (username.includes("..")) {
      showAlert("No special characters (letters & numbers only)");
    } else if (!userNameRegexValidation(username)) {
      showAlert("No special characters (letters & numbers only)");
    } else if (username.length < 4 || username.length > 20) {
      showAlert("Crypto Tag must be 4-20 characters");
    } else {
      return true;
    }
  };

  const createNewAccount = async () => {
    try {
      setLoading(true);
      const normzalized = ens.normalize(accountName);
      console.log(normzalized);
      const isOk = handleFormValidation(accountName);
      const data = await isEnsExist(normzalized);

      if (!data) {
        if (isOk) {
          const { address, secret, smartAccountAddress, pk } =
            await deriveNewAccount();

          const web3 = initalizeWeb3(activeNetwork);

          if (!ensExists) {
            const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
            const signature = web3.eth.accounts.sign(normzalized, pkey);

            await saveSubEns(
              normzalized,
              smartAccountAddress.toLowerCase(),
              rootAccountInfo.address,
              signature.signature
            );
          }

          const newAccount: Accounts = {
            [address]: {
              address,
              secret,
              type: "Main",
              name: normzalized,
              smartAccountAddress,
            },
          };
          console.log(
            "🚀 ~ file: index.tsx:134 ~ createNewAccount ~ newAccount:",
            newAccount
          );

          dispatch(setAccounts(newAccount));
          dispatch(
            setHoldings({
              [smartAccountAddress]: {
                nativeBalance: 0,
                nativeBalanceUsd: 0,
                nativeTokenPrice: 0,
                tokens: [],
              },
            })
          );
          dispatch(
            setBalances({
              accumulatedAllAccounts: balances.accumulatedAllAccounts,
              smartWalletBalance: {
                ...balances.smartWalletBalance,
                [smartAccountAddress]: 0,
              },
            })
          );
          dispatch(
            setLastCreatedAccount({
              address,
              secret,
              smartAccountAddress,
              accountName: newAccount[address]?.name,
            })
          );

          openNewModal();
          closeThisModal();
          setLoading(false);
        } else {
          closeThisModal();
        }
      } else {
        closeThisModal();
        showAlert("Stashed Tag already in use. Try another!");

        setLoading(false);
      }
    } catch (error) {
      closeThisModal();
      showAlert(
        `The Crypto tag ${accountName} already exists. Please choose a different tag.`
      );

      setLoading(false);
    }
  };

  const isValid = !accountName;

  return ensLoading ? (
    <div style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}>
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <div className="new-account-form">
      <div>
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={400}
          color="rgba(26, 28, 32, 0.50)"
          my={2.5}
          style={{
            textAlign: "start",
            marginBottom: "15px",
            fontFamily: "Helvetica Neue",
          }}
        >
          Pick a username and type for your new account
        </Typography>
        <Input
          title="Crypto Tag"
          addBorder
          value={accountName}
          onChange={setAccountName}
          disabled={ensExists}
        />
      </div>

      <Box className="modal-footer-buttons">
        <BasicButton
          title="Create"
          onClick={createNewAccount}
          mode={isValid ? "not-active" : "active"}
          id="create_new_account"
          loading={loading}
        />
      </Box>
    </div>
  );
};

export default NewAccount;
