import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import KeyLogo from "assets/key.svg";
import Button from "components/NewButton";

import "./styles.css";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch, useAppSelector } from "store/store";
import { saveRecoveryCode } from "utils/utils";
import { setRecoveryCode } from "@slices/walletSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";

const SignInDownloadRecoveryKey = () => {
  const [loading, setLoading] = useState(false);
  const { torusKey, hashedPin, hashedPassword } = useAppSelector(
    (state) => state.wallet
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const generateRecoveryCode = async () => {
    try {
      setLoading(true);

      const recoveryCode = torusKey + hashedPassword + hashedPin;

      await saveRecoveryCode(recoveryCode);
      dispatch(setRecoveryCode(recoveryCode));

      navigate("/account-recovered", {
        state: { currentStep: step + 1, pathName: pathName },
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <CustomizedSteppers
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={setStep}
            />
          </Box>
        </Grid>
      </Grid>
      <div className="create-account-box download-recovery-wrapper">
        <Typography
          variant="h2"
          sx={{
            // margin: "0px 0px 30px 0px",
            margin: "0",
            fontWeight: "700",
          }}
        >
          Download Recovery Key
        </Typography>

        <Typography
          variant="body2"
          sx={{
            margin: "10px 0px 0px 0px",
            color: "rgba(26, 28, 32, 0.5)",
            fontFamily: "Helvetica Neue",
            fontWeight: "500",
            textAlign: "center",
            fontSize: "18px",
            // width: '25%',
          }}
        >
          This key will be required to reset your <br />
          password if you lose access to all your trusted devices
        </Typography>

        <img
          src={KeyLogo}
          height={300}
          style={{
            height: "250px",
            margin: "30px 0px 30px 0px",
          }}
        />
        <Typography
          variant="body2"
          sx={{
            margin: "10px 0px 10px 0px",
            color: "rgba(26, 28, 32, 0.5)",
            paddingInline: "20px",
            fontFamily: "Helvetica Neue",
            fontWeight: "500",
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          Download your recovery key and keep it in a safe place so you always
          have access to your Stashed Account!
        </Typography>

        <Button
          title="Download to Files"
          onClick={generateRecoveryCode}
          loading={loading}
          disabled={false}
          style={{
            padding: "15px 20px",
          }}
        />
      </div>
    </div>
  );
};

export default SignInDownloadRecoveryKey;
