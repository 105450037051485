import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import pbkdf2 from "pbkdf2";

import WelcomeImg from "assets/Welcome.svg";

import BasicButton from "components/Button";
import { useNavigate } from "react-router-dom";
import Logo from "components/Logo";
import Input from "components/Input";
import { useAppDispatch, useAppSelector } from "store/store";

// import { setExpiry } from "@slices/appSlice";

import {
  decryptMessage,
  fetchEncryptedKeys,
  fetchEncryptedKeysByName,
  showAlert,
} from "utils/utils";

import { setHashedPassword } from "@slices/walletSlice";
import TextInput from "components/TextInput";
import Button from "components/NewButton";
import { setReferredTo, setUserSpendingDetails } from "@slices/appSlice";
import axios from "axios";
import { BASE_URL } from "constants";
// import { setHashedPassword } from "@slices/walletSlice";

const Login = () => {
  const passwordRef = useRef(null);
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState("");
  const [securePassword, setSecurePassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const { encryptedSeedPhraseWithPassword, rootAccountInfo } = useAppSelector(
    (state) => state.app
  );

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  useEffect(() => {
    if (hashedPassword) {
      navigate("/");
    }
  }, []);

  const dispatchAndNavigate = (hashedEnteredPassword: string) => {
    dispatch(setHashedPassword(hashedEnteredPassword));
    setLoading(false);
    navigate("/");
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const hashedEnteredPassword = pbkdf2
        .pbkdf2Sync(password, "salt", 1, 32, "sha512")
        .toString("hex");
      const decrypt = decryptMessage(
        encryptedSeedPhraseWithPassword,
        hashedEnteredPassword
      );

      if (decrypt) {
        const data = await fetchEncryptedKeysByName(rootAccountInfo.name);

        if (data.referedTo) {
          dispatch(setReferredTo(data.referedTo));
        }
        dispatchAndNavigate(hashedEnteredPassword);
      } else {
        showAlert("Password is incorrect", "Login Failed");
        setLoading(false);
      }
    } catch (error) {
      console.log("err", error);

      showAlert("Password is incorrect", "Login Failed");
      setLoading(false);
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13) {
      e.preventDefault(); // Prevent the default behavior of the Tab key
      handleLogin();
    }

    if (key === "Tab" || keyCode === 9) {
      e.preventDefault();
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }
  };

  return (
    <div className="login-container">
      <div className="center-content">
        <Logo />
        <div className="welcome-box-container">
          <TextInput
            title="Password"
            value={password}
            onChange={setPassword}
            showSecure={() => setSecurePassword(!securePassword)}
            showEndIcon
            secure={securePassword}
            type={securePassword ? "password" : "text"}
            style={{
              width: "350px",
              marginTop: "8px",
            }}
            onKeyPress={(e) => handleKeyPress(e)}
            useRef={passwordRef}
          />

          <Button
            title="Log In"
            onClick={handleLogin}
            id="handle_login"
            loading={loading}
            disabled={password.length === 0}
            style={{
              width: "380px",
              padding: "15px 0px",
              margin: 0,
              opacity: password.length === 0 ? 0.5 : 1,
            }}
            useRef={buttonRef}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
