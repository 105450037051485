import { WALLET_ADAPTERS } from "@web3auth/base";
import { Web3AuthNoModal } from "@web3auth/no-modal";

import RPC from "constants/webRPC";

export const loginWithTwitter = async (web3auth: Web3AuthNoModal) => {
  if (web3auth.connected) {
    await web3auth.logout();
  }
  await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
    loginProvider: "jwt",
    extraLoginOptions: {
      domain: "https://dev-uykhx24xb6t7wlyd.uk.auth0.com",
      verifierIdField: "sub",
      connection: "twitter",
    },
  });

  const res = await web3auth.getUserInfo();
  const rpc = new RPC(web3auth.provider!);
  console.log(res);
  const address = await rpc.getAccounts();
  console.log(address);

  const { name, email, profileImage, idToken } = res;

  return {
    name,
    email,
    profileImage,
    address,
    idToken,
  };
};
