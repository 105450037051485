import React, { FC, useMemo } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { AccountItemProps } from "interfaces";
import { numFormatter } from "utils/utils";
import { Box, Typography } from "@mui/material";
import BlackTick from "assets/black-tick.svg";
import AvatarLogo from "assets/avatarLogo.svg";

import { rings } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";

const AccountItem: FC<AccountItemProps> = ({
  accountAd,
  balance,
  name,
  active,
  onClick,
  avatar,
  activeTick = true,
}) => {
  const { amount, symbol } = numFormatter(Number(balance));

  const accAvatar = useMemo(() => {
    if (!accountAd) return;
    return createAvatar(rings, {
      size: 37,
      seed: accountAd,
    }).toDataUriSync();
  }, [accountAd]);

  return (
    <Box
      className={`account-item ${active && "active-account-item"}`}
      onClick={onClick}
      id="account_item_active_account"
      sx={{
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      {name === "All Accounts" ? (
        <Box
          position="relative"
          sx={{
            marginRight: "10px",
          }}
        >
          {avatar}
        </Box>
      ) : (
        <Box position="relative">
          {active && activeTick && (
            <img
              style={{
                position: "absolute",
                bottom: 4,
                right: 4,
                height: "15px",
                width: "15px",
              }}
              src={BlackTick}
            />
          )}
          <img src={AvatarLogo} alt="avatar" style={{ marginRight: "10px" }} />
        </Box>
      )}

      <Typography
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flex: 1,
          margin: "0px",
          color: "rgba(26, 28, 32, 1)",
          fontFamily: "Space Grotesk",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        }}
      >
        {name}
      </Typography>

      <Box className="balance">
        <p>${amount + symbol}</p>
      </Box>
    </Box>
  );
};

export default AccountItem;
