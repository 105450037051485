import React from "react";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface ImageViewerProps {
  imageUrl: string;
  title: string;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ imageUrl, title }) => {
  const styles = {
    image: {
      maxWidth: "100%",
      maxHeight: "80vh",
      borderRadius: "20px",
    },
  };

  return <img src={imageUrl} alt={title} style={styles.image} />;
};

export default ImageViewer;
