import React, { ChangeEvent, FC, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import ReferredFriendList from "../../../../Referral/ReferredFriends/ReferredFriendList";
import { Box, useTheme } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import InviteInput from "../../../../Referral/ReferredFriends/InviteInput";
import {
  addContactService,
  getAllUserContacts,
  getUserIdFromInput,
} from "../../../../../../utils/contact";
import { ethers } from "ethers"; // ethers v6
import {
  decryptMessage,
  fetchAddressFromEns,
  isEnsExist,
  showAlert,
} from "../../../../../../utils/utils";
import Web3 from "web3";
import { userContacts } from "../../../../../../interfaces";
import { setContacts } from "../../../../../../store/slices/appSlice";

const ContactMenu: FC<{
  shouldAddContact: boolean;
  nextStep?: (address: string, tag: string) => void;
  isTxForm?: boolean;
  isChooseRecipient?: boolean;
  isCashMode?: boolean;
}> = ({
  shouldAddContact = false,
  nextStep = (address: string, tag: string) => {},
  isTxForm = false,
  isChooseRecipient = false,
  isCashMode = false,
}) => {
  const [text, setText] = useState("");
  const [ensAddress, setEnsAddress] = useState("");
  const [ens, setEns] = useState("");

  console.log("file: index.tsx:16  text:", text);
  const [addContact, setAddContact] = useState(false);
  const [debouncedText, setDebouncedText] = useState<string>("");

  const [refetchContacts, setRefetchContacts] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const {
    ownReferralCode,
    referredTo,
    activeAccount,
    activeNetwork,
    contacts,
    rootAccountInfo,
    accounts,
  } = useAppSelector((state) => state.app);

  const { hashedPassword } = useAppSelector((state) => state.wallet);
  const accountContacts = contacts;
  console.log("file: index.tsx:31  accountContacts:", accountContacts);
  const [enableProceed, setEnableProceed] = useState<boolean>(false);

  useEffect(() => {
    if (
      location.pathname == "/settings" ||
      location.pathname.includes("send")
    ) {
      (async () => {
        console.log("NETWORK SWITCH HORAHA", activeNetwork);
        if (
          Object.keys(accounts).length > 0 &&
          activeAccount.address &&
          hashedPassword
        ) {
          let userContacts: userContacts = [];

          // await Promise.all(
          // Object.keys(accounts).map(async (acc) => {
          const contacts = await getAllUserContacts({
            address: rootAccountInfo.address,
          });
          console.log(
            "file: getAllUserContacts useApp.tsx:323  Object.keys  contacts:",
            contacts
          );
          // accounts[acc].smartAccountAddress

          userContacts = contacts;
          // })
          // );

          dispatch(setContacts([...userContacts]));

          console.log("ENDED///////////////////////////////");
        }
      })();
    }
  }, [refetchContacts]);

  const handleAddContact = async () => {
    try {
      // const isTextAddress = ethers.utils.isAddress(text);
      // if (isTextAddress) {
      const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);

      const res = await addContactService({
        userAddressOrEns: text,
        privateKey: pkey,
        activeNetwork,
        address: rootAccountInfo.address,
      });
      console.log(
        "file: index.tsx:41  handleAddContact  res:",
        res,
        rootAccountInfo
      );
      if (res) {
        setRefetchContacts(!refetchContacts);
        setText("");
      }
      // }
      // setAddContact(!addContact);
    } catch (e: any) {
      // window.alert(e.message);
    }
  };

  const theme = useTheme();

  // const debouncedTextChange = debounce((value: string) => {
  //   // Replace this with your actual API call
  //   console.log(`Calling API with debounced value: ${value}`);
  //   setDebouncedText(value);
  //   value;
  // }, 500);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setEnableProceed(false);
    setText(newValue);
    const web3 = new Web3();
    const isAddress = web3.utils.isAddress(newValue);
    const isValidENS = isChooseRecipient
      ? await fetchAddressFromEns(newValue)
      : await isEnsExist(newValue);
    if (isValidENS) {
      !isChooseRecipient ? nextStep(newValue, "") : setEnableProceed(true);
      setEnsAddress(isValidENS);
      setEns(newValue);
    } else if (isAddress) {
      !isChooseRecipient ? nextStep(newValue, "") : setEnableProceed(true);
      setEnsAddress(newValue);
      setEns("");
    } else {
      setEnableProceed(false);
      setEnsAddress("");
      setEns("");
    }
    // Debounce the API call
    debouncedApiCall(newValue);
  };

  const handleCashInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.includes(".")) {
      setEnableProceed(false);
    } else {
      setEnableProceed(false);
      setText(newValue);
      const web3 = new Web3();
      const isAddress = web3.utils.isAddress(newValue);
      const isValidENS = isChooseRecipient
        ? await fetchAddressFromEns(newValue)
        : await isEnsExist(newValue);
      if (isValidENS) {
        !isChooseRecipient ? nextStep(newValue, "") : setEnableProceed(true);
        setEnsAddress(isValidENS);
        setEns(newValue);
      } else if (isAddress) {
        !isChooseRecipient ? nextStep(newValue, "") : setEnableProceed(true);
        setEnsAddress(newValue);
        setEns("");
      } else {
        setEnableProceed(false);
        setEnsAddress("");
        setEns("");
      }
      // Debounce the API call
      debouncedApiCall(newValue);
    }
  };

  const debouncedApiCall = debounce((value: string) => {
    // Replace this with your actual API call
    console.log(`Calling API with debounced value: ${value}`);
    setDebouncedText(value);
  }, 500);

  const referredListArray = accountContacts?.length > 0 ? accountContacts : [];

  // const referredListArray = referredTo.length > 0 ? referredTo : [];
  const ReferredLists = () => {
    return (
      <Box display="flex" flexDirection="column" gap={3} py={2} sx={{}}>
        {referredListArray
          .filter((item: any) =>
            item?.name?.toLowerCase()?.includes(text?.toLowerCase())
          )
          .map((item: any) => (
            <ReferredFriendList
              name={item.name}
              address={item?.address ?? ""}
              nextStep={nextStep}
              subDomains={item?.subDomains ?? []}
              isChooseRecipient={isChooseRecipient}
              isCashMode={isCashMode}
            />
          ))}
      </Box>
    );
  };

  const handlePasteText = async () => {
    const clipboardText = await navigator.clipboard.readText();

    setText(clipboardText);
    setDebouncedText(clipboardText);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box>
          <Box>
            <Grid
              container
              spacing={1}
              justifyContent={{
                xs: "space-between",
                sm: "flex-end",
                md: "flex-end",
                lg: "space-between",
                xl: "space-between",
              }}
              style={{
                width: "100%",
              }}
            >
              <Grid item xs={12} sm={12} md={10} lg={10} alignItems={"center"}>
                <InviteInput
                  handleOnChange={
                    isCashMode ? handleCashInputChange : handleInputChange
                  }
                  background={isTxForm ? "#EFF3F4" : "#FFFFFF"}
                  value={text}
                  placeholder={
                    isCashMode
                      ? "Enter Cash Tag"
                      : addContact || isChooseRecipient
                      ? "Enter Tag or Wallet Address"
                      : "Search Contact"
                  }
                  width="107%"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={2}
                lg={2}
                sm={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  justifyContent: "flex-end",
                  paddingLeft: "12px",
                  maxWidth: "200px !important",
                }}
              >
                <Box
                  onClick={(e: any) => {
                    if (shouldAddContact) {
                      setAddContact(!addContact);
                    } else if (isChooseRecipient) {
                      if (enableProceed) {
                        nextStep(ensAddress, ens);
                      }
                    } else {
                      handlePasteText();
                    }
                  }}
                  style={{
                    // padding: "10px 10px",
                    height: "auto",
                    width: "auto",
                    borderRadius: "90px",
                    backgroundColor: "#1A1C20",
                    cursor: "pointer",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "9px 15px 9px 15px",
                    opacity: isChooseRecipient ? (enableProceed ? 1 : 0.5) : 1,
                  }}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      paddingHorizontal: "10px", // Change to green on small screens (sm and up)
                      paddingY: "6px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      paddingHorizontal: "10px", // Change to green on small screens (sm and up)
                      paddingY: "6px",
                    },
                  }}
                >
                  <Typography
                    variant={"h5"}
                    fontWeight={500}
                    color="white"
                    fontSize={12}
                    fontFamily={"Helvetica Neue"}
                    whiteSpace={"nowrap"}
                    letterSpacing={2}
                  >
                    {isChooseRecipient
                      ? "Proceed"
                      : shouldAddContact
                      ? addContact
                        ? "Contact List"
                        : "+ ADD"
                      : "Paste"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <>
          {addContact ? (
            <Box py={3}>
              <div
                onClick={handleAddContact}
                style={{
                  // padding: "10px 10px",
                  height: "100%",
                  width: "95%",
                  borderRadius: "10px",
                  padding: "20px",
                  backgroundColor: "rgba(26, 28, 32, 1)",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant={"h5"}
                  fontWeight={500}
                  color="white"
                  fontSize={12}
                  fontFamily={"Helvetica Neue"}
                  whiteSpace={"nowrap"}
                  letterSpacing={2}
                >
                  Add Contact
                </Typography>
              </div>
            </Box>
          ) : (
            <Box>
              <Box sx={{ color: "#8C8D8F" }}>Friends</Box>
              <Box display="flex" flexDirection="column" gap={3} py={2}>
                {referredListArray.length > 0 ? (
                  ReferredLists()
                ) : (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={"center"}
                    alignContent={"center"}
                    alignItems={"center"}
                    gap={3}
                    py={2}
                  >
                    <Typography
                      variant={"h5"}
                      fontWeight={500}
                      fontSize={12}
                      fontFamily={"Helvetica Neue"}
                      whiteSpace={"nowrap"}
                      letterSpacing={2}
                    >
                      No User Found
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </>
      </Box>
    </>
  );
};

export default ContactMenu;
