// import ModalStore from "@/store/ModalStore";
// import SessionProposalModal from "@/views/SessionProposalModal";

// import SessionSendTransactionModal from "@/views/SessionSendTransactionModal";

// import SessionSignCosmosModal from "@/views/SessionSignCosmosModal";
// import SessionRequestModal from "@/views/SessionSignModal";

// import SessionSignNearModal from "@/views/SessionSignNearModal";
// import SessionSignPolkadotModal from "@/views/SessionSignPolkadotModal";
// import SessionSignSolanaModal from "@/views/SessionSignSolanaModal";
// import SessionSignMultiversxModal from "@/views/SessionSignMultiversxModal";
// import SessionSignTronModal from "@/views/SessionSignTronModal";
// import SessionSignTezosModal from "@/views/SessionSignTezosModal";
// import SessionSignKadenaModal from "@/views/SessionSignKadenaModal";
// import SessionSignTypedDataModal from "@/views/SessionSignTypedDataModal";

// import SessionUnsuportedMethodModal from "@/views/SessionUnsuportedMethodModal";

// import { Modal as NextModal } from '@nextui-org/react'
import { useSnapshot } from "valtio";
import { useCallback, useEffect } from "react";
import { Modal as CustomModal } from "@mui/material";
import SessionSignTypedDataModal from "../views/SessionSignTypedDataModal";
import SessionUnsuportedMethodModal from "../views/SessionUnsupportedMethodModal";
import SessionRequestModal from "../views/SessionSignModal";
import SessionSendTransactionModal from "../views/SessionSendTransactionModal";
import SessionProposalModal from "../views/SessionProposalModal";
import ModalStore from "../walletConnectStore/ModalStore";
import SdkSendTransactionModal from "../views/SdkSendTransactionModal";

export default function Modal() {
  const { open, view } = useSnapshot(ModalStore.state);
  // handle the modal being closed by click outside
  const onClose = useCallback(() => {
    if (open) {
      ModalStore.close();
    }
  }, [open]);
  useEffect(() => {
    console.log("{ open, view }", { open, view });
  }, [open, view]);

  return (
    <CustomModal
      onClose={onClose}
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <>
        {view === "SessionProposalModal" && <SessionProposalModal />}
        {view === "SessionSignModal" && <SessionRequestModal />}
        {view === "SessionSignTypedDataModal" && <SessionSignTypedDataModal />}
        {view === "SessionSendTransactionModal" && (
          <SessionSendTransactionModal />
        )}
        {view === "SessionUnsuportedMethodModal" && (
          <SessionUnsuportedMethodModal />
        )}
        {view === "SdkSendTransactionModal" && <SdkSendTransactionModal />}
        {/* {view === 'SessionSignCosmosModal' && <SessionSignCosmosModal />}
      {view === 'SessionSignSolanaModal' && <SessionSignSolanaModal />}
      {view === 'SessionSignPolkadotModal' && <SessionSignPolkadotModal />}
      {view === 'SessionSignNearModal' && <SessionSignNearModal />}
      {view === 'SessionSignMultiversxModal' && <SessionSignMultiversxModal />}
      {view === 'SessionSignTronModal' && <SessionSignTronModal />}
      {view === 'SessionSignTezosModal' && <SessionSignTezosModal />}
      {view === 'SessionSignKadenaModal' && <SessionSignKadenaModal />} */}
      </>
    </CustomModal>
  );
}
