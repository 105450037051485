import React, { useEffect, useState } from "react";
import { getCounterFactualAddress } from "../../../../contract-integration";
import Web3 from "web3";

import MidArrow from "assets/midArrow.svg";

import "./index.css";
import { Box, Grid, Snackbar, Typography } from "@mui/material";
import BasicButton from "components/Button";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import AssetsView from "components/AssetsView";
import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { decryptMessage, extractTokenData, showAlert } from "utils/utils";
import paymasterAbi from "abis/paymasterabi.json";

import { setSelectedToken } from "@slices/walletSlice";
import { fetchGasPrice } from "utils/gas";
import { NATIVE_ADDRESS } from "constants/";
import { ethers, BigNumber } from "ethers";
import {
  EMPTY_CALLDATA,
  ExecuteCall,
  Paymaster_Token_Address,
  TransferData,
} from "../../../../contract-integration/constants";
import {
  setActiveAccount,
  setPendingTx,
  setPendingTxDetails,
} from "@slices/appSlice";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TokensListTable from "../TokensListTable";
import TokenInputForm from "components/TokenInputForm";
import { sendUserOp } from "../../../../contract-integration";
import { Token__factory } from "../../../../contract-integration/types/ethers-contracts";
import FillAsset from "./FillAsset";
import AccountItem from "components/AccountItem";
import AvatarLogo from "assets/avatarLogo.svg";

import { TokenPaymaster__factory } from "../../../../contract-integration/types/ethers-contracts";

import TopTokenSelectComponent from "../../../../components/TopTokenSelect";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import FeeUrgencyComponent from "../../../../components/SendTxComponent/FeeUrgencyComponent";
import TxTypeSwitch from "../../../../components/SendTxComponent/TxTypeSwitch";
import SendTxComponent from "../../../../components/SendTxComponent";
import FeeUIComponent from "../../../../components/SendTxComponent/FeeUIComponent";

const FillUp = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [to, setTo] = useState("");

  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [tag, setTag] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [gasPrice, setGasPrice] = useState(0);
  const [max, setMax] = useState(false);
  const [filter, setFilter] = useState(0);
  const [depositedAmount, setDepositedAmount] = useState(0);
  const [step, setStep] = useState(0);
  const [feeGasUrgency, setFeeGasUrgency] = React.useState("Normal");
  const [tokenForPayment, SetTokenForPayment] = React.useState("");
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [tokenForPaymentDecimal, setTokenForPaymentDecimal] = useState<
    number | null
  >(null);

  const [gasFee, setGasFee] = useState<any>(null);
  const [error, setError] = useState(false);
  const [inputError, setInputError] = useState("");
  const [tokenForPaymentBalance, setTokenForPaymentBalance] =
    useState<number>(0);
  const [sameTokens, setSameTokens] = useState<any[]>([]);

  const ISNATIVE =
    tokenForPayment == "0x0000000000000000000000000000000000000000" ||
    tokenForPayment == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

  const handleGasButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setFeeGasUrgency(newAlignment);
  };

  const handleSelectTokenForPaymentWithDecimal = ({
    address,
    decimal,
    tokenBalance,
  }: {
    address: string;
    decimal: number;
    tokenBalance?: number;
  }) => {
    setError(false);
    SetTokenForPayment(address);
    setTokenForPaymentDecimal(decimal);
    setTokenForPaymentBalance(tokenBalance || 0);
  };

  const navigate = useNavigate();
  const {
    activeAccount,
    holdings,
    accounts,
    activeNetwork,
    portfolio,
    rootAccountInfo,
    balances,
  } = useAppSelector((state) => state.app);

  const { selectedToken }: { selectedToken: any } = useAppSelector(
    (state) => state.wallet
  );

  const { hashedPassword, isAccountDeployed } = useAppSelector(
    (state) => state.wallet
  );

  const { nativeTokenName, symbol, icon, rpc, block_explorer_url } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const { nativeBalance, nativeBalanceUsd, nativeTokenPrice } =
    holdings[activeAccount.smartAccountAddress];

  const dispatch = useAppDispatch();

  const web3 = new Web3(rpc);
  const paymasterContract = new web3.eth.Contract(
    paymasterAbi as any,
    Paymaster_Token_Address
  );

  useEffect(() => {
    (async () => {
      const { average } = await fetchGasPrice(activeNetwork);
      console.log(
        "GASSSSSSSSSSs",
        average,
        Number(Web3.utils.fromWei(Number(average).toString(), "ether"))
      );
      setGasPrice(average);
    })();
  }, []);

  useEffect(() => {
    let userTokens: Token[] =
      holdings[activeAccount.smartAccountAddress]?.tokens || [];

    setTokens(userTokens);
  }, [activeAccount, holdings]);

  useEffect(() => {
    (async () => {
      const depositInfo = await paymasterContract.methods
        .depositInfo(NATIVE_ADDRESS, activeAccount.smartAccountAddress)
        .call();

      console.log(depositInfo.amount);
      setDepositedAmount(
        Number(Web3.utils.fromWei(depositInfo.amount, "ether"))
      );
    })();
  }, []);

  const isSelectedToken = selectedToken.length > 0;
  const tokenSymbol = isSelectedToken ? selectedToken[0].tokenSymbol : symbol;
  const tokenIcon = isSelectedToken ? selectedToken[0].image : icon;
  const tokenName = isSelectedToken
    ? selectedToken[0].tokenName
    : nativeTokenName;
  const tokenBalance = isSelectedToken
    ? selectedToken[0].tokenBalance
    : nativeBalance;
  const tokenBalanceInUsd = isSelectedToken
    ? selectedToken[0].priceInUSD
    : nativeBalanceUsd;
  const tokenPriceInUsd = isSelectedToken
    ? selectedToken[0].tokenPrice
    : nativeTokenPrice;
  const tokenAddress = isSelectedToken ? selectedToken[0].tokenAddress : "";

  useEffect(() => {
    setValue("");
  }, [selectedToken]);

  useEffect(() => {
    if (max && Number(value) < tokenBalance) {
      setFilter(0);
    } else if (Number(value) < tokenBalance * filter) {
      setFilter(0);
    }
  }, [value]);

  useEffect(() => {
    const data = extractTokenData(activeNetwork, portfolio.assets);
    setSameTokens(data.sameTokens);
  }, [activeNetwork, portfolio.assets]);

  useEffect(() => {
    if (selectedToken.length > 0 && tokenForPayment && value) {
      sendTopUpTransaction();
    }
  }, [selectedToken, tokenForPayment, step]);

  async function sendTopUpTransaction() {
    try {
      setError(false);
      setLoading(true);

      setFinalOpState(null);
      setGasFee({
        isLoading: true,
        usdcFee: "",
        transferGasFee: "",
        alternativeMessage: "Calculating...",
      });

      if (
        !tokenForPayment.trim() ||
        !activeNetwork ||
        !selectedToken[0]?.tokenAddress ||
        !tokenForPaymentDecimal
      ) {
        window.alert("Please provide all details");
        setLoading(false);
        setGasFee({
          isLoading: false,
          usdcFee: "0",
          transferGasFee: "",
          alternativeMessage: "",
        });
        return;
      }
      const ISTRANSFER_TOKEN_NATIVE =
        selectedToken[0]?.tokenAddress ==
          "0x0000000000000000000000000000000000000000" ||
        selectedToken[0]?.tokenAddress ==
          "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";
      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const wallet = new ethers.Wallet(pkey, provider);

      // Paymaster_Owner_Address;

      // 0. Get the user calldata array from the frontend.
      let userCallDataArray: ExecuteCall[] = [];
      const allAccountsAddress = Object.keys(accounts);

      if (allAccountsAddress.length == 0) {
        setLoading(false);
        return;
      }
      const firstAccountAddress =
        accounts[allAccountsAddress[0]].smartAccountAddress;
      // Assuming the user wants to call approve on some token using prepaid transaction.
      const tokenAddress = selectedToken[0].tokenAddress;

      const enteredAmountInWei: BigNumber = ethers.utils.parseUnits(
        String(value),
        selectedToken[0]?.tokenDecimal
      );

      const ItokenPaymaster = TokenPaymaster__factory.createInterface();
      const Itoken = Token__factory.createInterface();

      console.log("In here [IF]");

      if (ISTRANSFER_TOKEN_NATIVE) {
        // This means user wants to top up 'native' i.e Eth, Matic, etc
        // Todo: change the amount with entered Values
        const ethDepositCalldata = ItokenPaymaster.encodeFunctionData(
          "addDeposit",
          [
            "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
            firstAccountAddress,
            enteredAmountInWei,
          ]
        );

        userCallDataArray.push({
          to: Paymaster_Token_Address,
          value: enteredAmountInWei.toString(),
          calldata: ethDepositCalldata,
        });
      } else {
        console.log("In here [ELSE]");
        const approveCallData = Itoken.encodeFunctionData("approve", [
          Paymaster_Token_Address,
          enteredAmountInWei,
        ]);
        const depositCallData = ItokenPaymaster.encodeFunctionData(
          "addDeposit",
          [tokenAddress, firstAccountAddress, enteredAmountInWei]
        );
        userCallDataArray.push({
          to: tokenAddress,
          value: "0",
          calldata: approveCallData,
        });
        userCallDataArray.push({
          to: Paymaster_Token_Address,
          value: "0",
          calldata: depositCallData,
        });
      }

      let tokenAmountToTransfer = ISNATIVE ? "1000000000" : "1000";
      let transferData: TransferData;
      if (ISNATIVE) {
        transferData = {
          tokenAddress: "0x",
          tokenAmount: tokenAmountToTransfer,
        };

        //for ether or matic
      } else {
        transferData = {
          tokenAddress: tokenForPayment,
          tokenAmount: tokenAmountToTransfer,
        };
      }

      const isHighFees = feeGasUrgency == "Lightning";

      const [counterfactual, { finalOp, usdcFee, transferGasFee }] =
        await Promise.all([
          getCounterFactualAddress(EMPTY_CALLDATA, wallet.address, 0, provider),
          txSubmissionOrderPrepaid({
            transferData,
            userCallDataArray,
            counterfactual: activeAccount.smartAccountAddress,
            wallet,
            transferTokenDecimal: tokenForPaymentDecimal,
            isHighFees,
            isAccountDeployed,
          }),
        ]);

      console.log("Clicked!");

      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      const IsSameToken =
        tokenForPayment?.toLowerCase() ==
        selectedToken[0]?.tokenAddress?.toLowerCase();

      console.log(
        "file: index.tsx:358  sendTopUpTransaction  IsSameToken:",
        IsSameToken
      );
      if (IsSameToken) {
        const HAS_ENOUGH_BALANCE =
          selectedToken[0]?.tokenBalance -
            Number(value) -
            Number(transferGasFee) >
          0;
        console.log(
          "file: index.tsx:364  sendTopUpTransaction  HAS_ENOUGH_BALANCE:",
          HAS_ENOUGH_BALANCE
        );
        if (!HAS_ENOUGH_BALANCE) {
          showAlert(
            "You don't have enough token balance to pay fee, Please select different payment token.",
            "Not enough balance"
          );
          setError(true);
        }
      } else {
        if (+usdcFee >= tokenForPaymentBalance) {
          showAlert(
            "You don't have enough token balance to pay fee, Please select different payment token.",
            "Not enough balance"
          );
          setError(true);
        }
      }
      setFinalOpState(finalOp);
      setLoading(false);
      setGasFeeInUSD(usdcFee);
      setGasFee({ usdcFee, transferGasFee, isLoading: false });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const executeTopUpCrypto = async () => {
    setLoading(true);
    if (finalOpState) {
      const response = await sendUserOp(
        finalOpState,
        "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37",
        "https://polygon-mainnet.g.alchemy.com/v2/HBxGEElD4fSo3gWukvZFV9YTKO4OvCnw"
      );
      const userOPResponse: any = await response.wait();
      console.log("userOp Hash :", response.userOpHash);
      console.log("Tx Hash :", userOPResponse?.transactionHash);
      console.log("success status :", userOPResponse?.args.success);
      console.log(
        "actualGasCost  :",
        Number(userOPResponse?.args.actualGasCost)
      );
      dispatch(
        setPendingTxDetails({
          value: value,
          valueIn$: String(
            Number(value) * Number(selectedToken[0].tokenPrice ?? 0)
          ),
          transferAmount: value,
          transactionMethod: "SEND",
          scanLink: block_explorer_url,
          eoaEns: rootAccountInfo.name,
          addressEns: activeAccount.accountName,
          toAddressEns: to,
          toAddress: to,
          assetName: selectedToken[0].tokenName,
          assetSymbol: tokenSymbol,
          networkFeesIn$: gasFeeInUSD,
          iconURL: selectedToken[0].image,
          txByDesposited: true,
          action: "Deposited",
        })
      );
      dispatch(setPendingTx(response.userOpHash));

      showAlert(
        "Soon you can see your transaction in the transactions tab",
        "Transaction Submitted",
        `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
      );
    }
    navigate("/gas");
  };

  const selectTokenHandler = (tk: Token) => {
    dispatch(setSelectedToken([tk]));
    setOpenModal(false);
  };

  const isValid = Number(value) && to && Number(value) <= Number(tokenBalance);
  const isDepositValid = Number(value) && Number(value) <= Number(tokenBalance);

  const handleSelectTokenForPayment = (address: string) => {
    SetTokenForPayment(address);
  };

  const switchAccount = (address: string) => {
    dispatch(
      setActiveAccount({
        address,
        secret: accounts[address].secret,
        smartAccountAddress: accounts[address].smartAccountAddress,
        accountName: accounts[address]?.name,
      })
    );
  };

  const handleAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    if (/[^0-9.]/.test(inputValue)) {
      setInputError("Special characters are not allowed");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setInputError("Only one decimal point is allowed");
    } else if (!/^\d{0,10}(\.\d{0,6})?$/.test(inputValue)) {
      setInputError(
        "Maximum of 10 digits before decimals and 6 digits after decimals are allowed"
      );
    } else {
      setInputError("");

      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      setValue(newValue);
      setInputError("");
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (Number(newValue) > 0) {
        setValue(newValue);
      }
      setInputError("");
    }
  };

  return (
    <>
      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <ModalHeader
            title="Select asset"
            onClose={() => setOpenModal(false)}
            showBackIcon
          />
          <AssetsView tokens={tokens} selectTokenHandler={selectTokenHandler} />
        </>
      </BasicModal>
      <Box mt={6}>
        <NavigatorHeading
          title="Top Up"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/gas");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Account", "Network", "Asset", "Amount", "Top Up"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>
      <Box mt={5}>
        {step == 0 && (
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={6} sm={12}>
              <Typography
                sx={{
                  fontSize: "17px",
                  textAlign: "center",
                  color: "rgba(26, 28, 32, 0.5)",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                  paddingBottom: "30px",
                }}
              >
                Top up your gas with crypto
              </Typography>
              <Typography
                sx={{
                  fontSize: "17px",
                  textAlign: "center",
                  color: "rgba(26, 28, 32, 0.5)",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                  paddingBottom: "40px",
                }}
              >
                Select which account you want to top up from
              </Typography>
              <div className="accounts-list">
                {Object.keys(accounts).map((account, index) => (
                  <AccountItem
                    onClick={() => {
                      switchAccount(account);
                      setStep(1);
                    }}
                    key={account}
                    balance={
                      balances?.smartWalletBalance?.[
                        accounts[account].smartAccountAddress
                      ]
                    }
                    accountAd={accounts[account].smartAccountAddress}
                    name={accounts[account]?.name || `Account ${index + 1}`}
                    active={true}
                    avatar={
                      <Box className="account-bg-image">
                        <img
                          src={AvatarLogo}
                          alt="avatar"
                          style={{ marginRight: "10px" }}
                        />
                      </Box>
                    }
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        {step == 1 && (
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={6} sm={12}>
              <NetworksList
                nextStep={() => setStep(2)}
                title="Select which network you want to top up from"
              />
            </Grid>
          </Grid>
        )}
        {step == 2 && (
          <TokensListTable transactionForm={true} nextStep={() => setStep(3)} />
        )}
        {step == 3 && (
          <Grid container display="flex" justifyContent="center" py={4}>
            <Grid item lg={6} sm={12}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    textAlign: "left",
                    color: "rgba(26, 28, 32, 0.5)",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    width: "100%",
                  }}
                >
                  Amount to top up to gas
                </Typography>
                <TokenInputForm
                  placeHolder="0.00"
                  title="Crypto Tag"
                  addBorder
                  balance={selectedToken[0]?.tokenBalance}
                  type="number"
                  onChange={handleAmountChange}
                  onKeydown={handleKeyDown}
                  value={String(value)}
                  receiverENS={tag ? tag : to}
                  isDepositValid={!!isValid || !!isDepositValid}
                  nextStep={() => setStep(4)}
                  tokenName={tokenSymbol}
                  tokenIcon={tokenIcon}
                  errorMessage={inputError}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        {step == 4 && (
          <Grid container display="flex" justifyContent="center" py={4}>
            <Grid item lg={6} sm={12}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={8}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    minWidth: "100%",
                  }}
                >
                  <FillAsset
                    nextStep={() => console.log("end")}
                    value={value}
                    executeSend={sendTopUpTransaction}
                  />
                  <Box
                    component={"img"}
                    src={MidArrow}
                    width={"33px"}
                    height={"33px"}
                    mt={"-8px"}
                    position={"relative"}
                  />

                  <FillAsset
                    nextStep={() => console.log("end")}
                    value={value}
                    executeSend={sendTopUpTransaction}
                    isGasComponent={true}
                  />
                </Box>

                <FeeUrgencyComponent
                  feeGasUrgency={feeGasUrgency}
                  setFeeGasUrgency={setFeeGasUrgency}
                />

                <SendTxComponent
                  loading={loading}
                  // SetTokenForPayment={SetTokenForPayment}
                  gasTokenSelect={<></>}
                  top20TokenSelect={
                    <TopTokenSelectComponent
                      handleSelectToken={handleSelectTokenForPayment}
                      handleSelectTokenWithDecimal={
                        handleSelectTokenForPaymentWithDecimal
                      }
                      paymentTokenAddress={tokenForPayment}
                      sameTokens={sameTokens}
                    />
                  }
                  txByDeposited={false}
                  handleSend={executeTopUpCrypto}
                  finalOpState={finalOpState}
                  error={error}
                  isSwap={false}
                >
                  <FeeUIComponent gasFeeInUSD={gasFeeInUSD} />
                </SendTxComponent>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default FillUp;
