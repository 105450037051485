import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Login from "pages/app/Login";

import AppRoutes from "./AppRoutes";
import SendReceive from "pages/app/SendReceive";
import Collectibles from "pages/app/Collectibles";
import SingleNftDetails from "pages/app/NftDetails";
import Swap from "pages/app/Crypto/Swap";
import Settings from "pages/app/Settings";
import Marketplace from "pages/app/Marketplace";
import Tokens from "pages/app/Tokens";
import Earn from "pages/app/Earn";
import BuySell from "pages/app/BuySell";
// import Referral from "pages/app/Referral";
import useInitialization from "hooks/useInitialization";
import useWalletConnectEventsManager from "hooks/useWalletConnectEventsManager";
import { signClient } from "utils/WalletConnectUtil";
import { RELAYER_EVENTS } from "@walletconnect/core";
import { styledToast } from "utils/HelperUtil";
import { Toaster } from "react-hot-toast";
import Modal from "components/Modal";
import { useAppSelector } from "store/store";
import DappConnection from "pages/app/DappConnection";

// Cash Module Routes
import Cash from "pages/app/Cash";
import CashTransactions from "pages/app/Cash/Transactions";

// Crypto Module Routes
import Crypto from "pages/app/Crypto";
import Gas from "pages/app/Gas";
import NFT from "pages/app/NFT";
import Collections from "../../pages/app/NFT/Collections";
import NFTDetailPage from "../../pages/app/NFT/NFTDetailPage";
import Referral from "../../pages/app/Referral";

import TokenDetailPage from "pages/app/Crypto/TokenDetailPage";
import NFTTransactions from "../../pages/app/NFTTransactions";
import Send from "pages/app/Crypto/Send";
import Receive from "pages/app/Crypto/Receive";
import CryptoTransactions from "../../pages/app/CryptoTransactions";
import NewSettings from "../../pages/app/Settings/index";
import FillUp from "pages/app/Gas/FillUp";
import Cashout from "pages/app/Crypto/Cashout";
import SendNFT from "pages/app/NFT/Send";
import WithdrawDepositedGas from "../../pages/app/Gas/WithdrawDepositedGas";
import NFTTransactions2 from "../../pages/app/NFTTransactions2";
import GasTransactions from "../../pages/app/GasTransactions";
import CashSend from "pages/app/Cash/Send";
import CashExchange from "pages/app/Cash/Exchange";
import TransactionSuccess from "components/TransactionFlow/TransactionSuccess";
import SecurityPattern from "components/TransactionFlow/SecurityPattern";
import DrawPattern from "components/TransactionFlow/DrawPattern";
import PerformTransaction from "components/TransactionFlow/PerformTransaction";
import ConfirmDrawPattern from "components/TransactionFlow/ConfirmDrawPattern";
import SuccessfullyEnabled from "components/TransactionFlow/SuccessfullyEnabled";
import SkipSettings from "components/TransactionFlow/SkipSettings";
import CashReceive from "pages/app/Cash/Receive";
import OnRamp from "pages/app/OnRamp";

const AppFlowRoutes = () => {
  const { expirationTime } = useAppSelector((state) => state.app);

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const navigate = useNavigate();

  useEffect(() => {
    if (expirationTime < new Date().getTime()) {
      navigate("/login");
    }
  }, []);

  // useEffect(() => {
  //   if (!hashedPassword) {
  //     navigate("/login");
  //   }
  // }, [hashedPassword]);

  // // Step 1 - Initialize wallets and wallet connect client
  // const initialized = useInitialization();
  // console.log(
  //   "🚀 ~ file: index.tsx:82 ~ AppFlowRoutes ~ initialized:",
  //   initialized
  // );

  // // Step 2 - Once initialized, set up wallet connect event manager
  // useWalletConnectEventsManager(initialized);
  // const shouldInitializeHooks = hashedPassword.length > 0; // Set to true or false based on your condition

  // Conditionally call custom hooks
  // if (shouldInitializeHooks) {
  // console.log(
  //   "🚀 ~ file: index.tsx:93 ~ AppFlowRoutes ~ s1houldInitializeHooks:",
  //   shouldInitializeHooks
  // );
  const initializeHooks = useInitialization();

  useWalletConnectEventsManager(initializeHooks);
  // }

  useEffect(() => {
    if (hashedPassword.length > 0) {
    }
  }, [hashedPassword]);
  // useEffect(() => {
  //   if (!initialized) return;
  //   signClient.core.relayer.on(RELAYER_EVENTS.connect, () => {
  //     styledToast("Network connection is restored!", "success");
  //   });

  //   signClient.core.relayer.on(RELAYER_EVENTS.disconnect, () => {
  //     styledToast("Network connection lost.", "error");
  //   });
  // }, [initialized]);

  // console.log("initialized in app file", initialized);

  return (
    <>
      <Routes>
        <Route path="/" element={<AppRoutes />}>
          {/* default route */}
          <Route path="/" element={<Navigate to="/cash" />} />

          {/* Cash Module */}
          <Route path="/cash" element={<Cash />} />
          <Route path="/cash/send" element={<CashSend />} />
          <Route path="/cash/exchange" element={<CashExchange />} />

          <Route path="/cash/transactions" element={<CashTransactions />} />

          {/* Crypto Module */}
          <Route path="/crypto" element={<Crypto />} />
          <Route path="/crypto/:token" element={<TokenDetailPage />} />
          <Route path="/crypto/transactions" element={<CryptoTransactions />} />
          <Route path="/crypto/send" element={<Send />} />
          <Route path="/crypto/swap" element={<Swap />} />
          <Route path="/crypto/receive" element={<Receive />} />
          <Route path="/cash/receive" element={<CashReceive />} />

          <Route path="/crypto/cashout" element={<Cashout />} />

          {/* Gas Module */}
          <Route path="/gas" element={<Gas />} />
          <Route path="/gas/transactions" element={<GasTransactions />} />
          <Route path="/gas/fillup" element={<FillUp />} />
          <Route path="/gas/withdraw" element={<WithdrawDepositedGas />} />

          {/* pages */}
          <Route path={"/market-place"} element={<Marketplace />} />
          <Route path={"/send-receive"} element={<SendReceive />} />
          <Route path={"/collectibles"} element={<Collectibles />} />

          <Route path={"/nfts"} element={<NFT />} />
          <Route path="/nfts/receive" element={<Receive isNftPage />} />
          <Route path={"/nfts/send"} element={<SendNFT />} />
          <Route path={"/nfts/transactions"} element={<NFTTransactions2 />} />
          <Route
            path={"/nfts/:NftColName/:chainName/:nftId/send"}
            element={<SendNFT />}
          />

          <Route path={"/nfts/:NftColName"} element={<Collections />} />

          <Route
            path={"/nfts/:NftColName/:nftId/:chainName"}
            element={<NFTDetailPage />}
          />

          <Route path={"/tokens"} element={<Tokens />} />
          <Route path={"/earn"} element={<Earn />} />
          <Route path={"/buy-sell"} element={<BuySell />} />
          <Route path={"/referral"} element={<Referral />} />
          <Route path={"/dapp-connection"} element={<DappConnection />} />

          <Route
            path={"/collectibles/:address/:tokenId"}
            element={<SingleNftDetails />}
          />
          <Route path={"/swap"} element={<Swap />} />
          <Route path={"/settings"} element={<NewSettings />} />
          {/* <Route path={"/settings2"} element={<Settings />} /> */}
          <Route path={"/on-ramp"} element={<OnRamp />} />
        </Route>
        <Route path={"/login"} element={<Login />} />

        {/* Transaction Flow */}
        <Route path="/" element={<AppRoutes />}>
          <Route
            path={"/transaction-success"}
            element={<TransactionSuccess />}
          />
          <Route path={"/security-pattern"} element={<SecurityPattern />} />
          <Route path={"/draw-pattern"} element={<DrawPattern />} />
          <Route
            path={"/confirm-draw-pattern"}
            element={<ConfirmDrawPattern />}
          />
          <Route
            path={"/perform-transaction"}
            element={<PerformTransaction />}
          />
          <Route
            path={"/successfully-enabled"}
            element={<SuccessfullyEnabled />}
          />

          <Route path={"/skip-settings"} element={<SkipSettings />} />
        </Route>
      </Routes>
      <Toaster />
      <Modal />
    </>
  );
};

export default AppFlowRoutes;
