import { SupportedChainId } from "constants/chains";
import { AppReduxState } from "interfaces";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import createMigrate from "redux-persist/es/createMigrate";

import appReducer from "store/slices/appSlice";
import walletReducer from "store/slices/walletSlice";

const storage = require("redux-persist/lib/storage").default;

const persistConfig = {
  key: "root",
  storage,
  version: 21,
  whitelist: ["app"],
  migrate: createMigrate({
    // @ts-expect-error migrations are poorly typed
    1: (state) => {
      return {
        ...state,
      };
    },

    // @ts-expect-error migrations are poorly typed
    5: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          activeNetwork: SupportedChainId.ETHEREUM_MAINNET,
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    7: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          mainnetNetworks: [SupportedChainId.ETHEREUM_MAINNET],
          testnetNetworks: [SupportedChainId.ETHEREUM_GOERLI],
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    8: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          activeNetwork: SupportedChainId.ETHEREUM_MAINNET,
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    9: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          activeAccount: { address: "", secret: "", smartAccountAddress: "" },
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    10: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          encryptedSeedPhraseWithPassword: "",
          pendingTx: "",
          accessToken: "",
          blockNumbers: {},
          isAccountCreated: false,
          currentDappRoute: "",
          expirationTime: 0,
          user: {
            mode: "google",
            email: "",
            name: "",
            profileImage: "",
          },
          mnemonic: "",
          holdings: {},
          activity: {},
          localActivity: {},
          accounts: {},
          activeAccount: { address: "", secret: "", smartAccountAddress: "" },
          activeNetwork: SupportedChainId.ETHEREUM_MAINNET,
          nfts: {},
          newNFTs: {},
          transactions: {},
          settingsOpenOption: null,
          contacts: [],
          portfolio: {
            total_wallet_balance: 0.0,
            wallet: "0x",
            assets: [],
          },
          history: {
            wallet: ["0x"],
            balance_usd: 0.0,
            balance_history: [],
            chart_data: [],
            chart_timestamps: [],
            interval: 3600,
          },
          mainnetNetworks: [
            SupportedChainId.ETHEREUM_MAINNET,
            SupportedChainId.BSC,
            SupportedChainId.POLYGON,
          ],
          testnetNetworks: [
            SupportedChainId.ETHEREUM_GOERLI,
            SupportedChainId.BSCT,
            SupportedChainId.MUMBAI,
            SupportedChainId.MANTLE_TESTNET,
          ],
          homeScreenMainTabByDefault: 0,
          userEns: "",
          isDappRoutes: false,
          fetchedKeys: {
            key1: "",
            key2: "",
            key3: "",
            key4: "",
            rootAddress: "",
          },
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    11: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          activeNetwork: SupportedChainId.MUMBAI,
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    12: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          ownReferralCode: "",
          externalReferralCode: "",
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    13: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          mainnetNetworks: [],
          testnetNetworks: [SupportedChainId.MUMBAI],
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    14: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          rootAddress: "",
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    15: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          // connectedDapps: [],
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    16: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          rootAccountInfo: {
            address: "",
            name: "",
          },
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    17: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          rootAccountInfo: {
            address: "",
            name: "",
            secret: "",
          },
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    18: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          rootAccountInfo: {
            address: "",
            name: "",
            secret: "",
            usdTokenBalance: 0,
          },
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    19: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          cashTransactions: {},
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    20: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          cashTransactions: [],
        },
      };
    },
    // @ts-expect-error migrations are poorly typed
    21: (state) => {
      return {
        ...state,
        app: {
          // @ts-expect-error migrations are poorly typed
          ...state.app,
          cashExchangeTxs: [],
        },
      };
    },
  }),
};

const reducers = combineReducers({
  app: appReducer,
  wallet: walletReducer,
});

export const persistedReducer = persistReducer(persistConfig, reducers);

export type RootState = ReturnType<typeof reducers>;
