import { FC, useEffect, useState } from "react";
import {
  Box,
  Fade,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

import SwitchSelector from "components/SwitchSelector";
import ItemBox from "components/ItemBox";
import { Caption, MediumTitle } from "components/Styled";
import BasicButton from "components/Button";
import { useAppDispatch, useAppSelector } from "store/store";
// import TopTokenSelect from "pages/app/Crypto/Send/SendAsset/TopTokenSelect";
import {
  setPendingTx,
  setPendingTxDetails,
  setSwapDetails,
  setUserSpendingDetails,
} from "@slices/appSlice";
import { AcrossExactInputCalldata, getAlphaRouterResponse } from "utils/swap";
import {
  decryptMessage,
  extractTokenData,
  fetchPrice,
  formatAmount,
  showAlert,
} from "utils/utils";
import { fetchTransactionCostInNativeToken, gasPriceData } from "utils/gas";

import { getEthDollarValue } from "utils/portfolio";
import { useNavigate } from "react-router-dom";
import { SUPPORTED_NETWORKS } from "constants/chains";
import TopTokenSelect from "components/TopTokenSelect";
import { ExecuteCall, sendUserOp } from "../../contract-integration";
import { tokensForGasObject } from "constants/topTokensConf";
import { readAccountHoldings } from "utils/holdings";
import TokenImage from "components/TokenImage";
import { ethers } from "ethers";
import ConfirmPatternModal from "components/ConfirmPatternModal";
import { TradeType } from "@uniswap/sdk-core";
import { SwapType } from "@uniswap/smart-order-router";
import FeeUrgencyComponent from "components/SendTxComponent/FeeUrgencyComponent";
import TxTypeSwitch from "components/SendTxComponent/TxTypeSwitch";
import GasTokenSelect from "components/GasTokenSelect";
import SendTxComponent from "components/SendTxComponent";
import TopTokenSelectComponent from "components/TopTokenSelect";
import FeeUIComponent from "components/SendTxComponent/FeeUIComponent";
import MidArrow from "assets/midArrow.svg";

const SwapSummary: FC<{
  isError?: boolean;
}> = ({ isError }) => {
  const {
    swapDetails: { tokenA, tokenB, chainId },
    activeAccount: { smartAccountAddress, secret, address, accountName },
    rootAccountInfo,
    activeNetwork,
    portfolio: { assets },
    accounts,
    userSpendingDetails,
  } = useAppSelector((state) => state.app);
  const { hashedPassword, isAccountDeployed } = useAppSelector(
    (state) => state.wallet
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = useState("Normal");
  const [gasPrice, setGasPrice] = useState(0);
  const [cost, setCost] = useState(0);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [txByDeposited, setTxByDeposited] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isPatternCorrect, setIsPatternCorrect] = useState(false);
  // const [error, setError] = useState(false);

  const [depositableTokens, setDepositableTokens] = useState<Array<any>>([]);
  const [paymentToken, setPaymentToken] = useState({
    address: "",
    decimals: 18,
    tokenBalance: 0,
  });
  const [sameTokens, setSameTokens] = useState<any[]>([]);

  const handleSelectTokenForPayment = (address: string) => {
    // setPaymentToken(address);
    // dispatch(
    //   setSwapDetails({
    //     paymentToken: address,
    //   })
    // );
  };
  const handleSwap = async () => {
    if (
      userSpendingDetails.isDailyLimitExceed &&
      userSpendingDetails.isPatternSet
    ) {
      setOpenModal(true);
    } else {
      onSwap();
    }
  };

  const onSwap = async () => {
    // // console.log("🚀 ~ file: index.tsx:60 ~ onSwap ~ data:", data);
    if (finalOpState) {
      setLoading(true);
      const response = await sendUserOp(
        finalOpState,
        "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37",
        "https://polygon-mainnet.g.alchemy.com/v2/HBxGEElD4fSo3gWukvZFV9YTKO4OvCnw"
      );
      console.log("🚀 ~ file: index.tsx:121 ~ onSwap ~ response:", response);
      const userOPResponse: any = await response.wait();
      // console.log("userOp Hash :", response.userOpHash);
      // console.log("Tx Hash :", userOPResponse?.transactionHash);
      // console.log("success status :", userOPResponse?.args.success);
      // console.log(
      // "actualGasCost  :",
      // Number(userOPResponse?.args.actualGasCost)
      // );
      // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
      // const userOPResponse: any = await response.wait();
      // // console.log("Tx Hash :", userOPResponse?.transactionHash);
      // // console.log("success status :", userOPResponse?.args.success);
      // console.log(
      // "actualGasCost  :",
      // Number(userOPResponse?.args.actualGasCost)
      // );
      // console.log(
      // "actualGasUsed  :",
      // Number(userOPResponse?.args.actualGasUsed)
      // );

      // dispatch(setPendingTx(response.userOpHash));

      showAlert(
        "Soon you can see your transaction in the transactions tab",
        "Transaction Submitted",
        `<a href="https://polygonscan.com/address/${userOPResponse.transactionHash}" target="_blank">View on Polygonscan</a>`
      );

      setFinalOpState(null);
      setLoading(false);
      const { block_explorer_url } =
        SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS];

      dispatch(
        setPendingTxDetails({
          value: tokenA.amount,
          valueIn$: tokenA.amountInUSD,
          transferAmount: tokenB.amount,
          transactionMethod: "SWAP",
          scanLink: block_explorer_url,
          eoaEns: rootAccountInfo.name,
          addressEns: accountName,
          toAddressEns: tokenB.tokenSymbol,
          toAddress: tokenB.tokenName,
          assetName: tokenA.tokenName,
          networkFeesIn$: cost,
          iconURL: tokenA.image,
          txByDesposited: txByDeposited,
          action: "Swapped",
        })
      );
      dispatch(setPendingTx(response.userOpHash));

      showAlert(
        "Soon you can see your transaction in the transactions tab",
        "Transaction Submitted",
        `<a href="https://polygonscan.com/address/${smartAccountAddress}" target="_blank">View on Polygonscan</a>`
      );

      // setFinalOpState(null);
      if (
        !userSpendingDetails.isFirstTx &&
        !userSpendingDetails.isFirstTxInApp
      ) {
        navigate("/transaction-success");

        dispatch(
          setUserSpendingDetails({
            isFirstTxInApp: true,
          })
        );
      } else {
        navigate("/crypto");
      }
    }
  };

  const handleSelectTokenForPaymentWithDecimal = ({
    address,
    decimal,
    tokenBalance,
  }: {
    address: string;
    decimal: number;
    tokenBalance?: number;
  }) => {
    setPaymentToken({
      address,
      decimals: decimal,
      tokenBalance: tokenBalance || 0,
    });
    // console.log("🚀 ~ file: index.tsx:125 ~ SwapSummary ~ address:", address);
  };

  const calculateTransactionParameters = async () => {
    const pKey = decryptMessage(secret, hashedPassword);
    // console.log("🚀 ~ file: index.tsx:39 ~ onSwap ~ pKey:", pKey);
    setLoading(true);
    setError(false);
    const allAccountsAddress = Object.keys(accounts);
    const firstAccountAddress =
      accounts[allAccountsAddress[0]].smartAccountAddress;
    const firstAccount = accounts[allAccountsAddress[0]];
    const pkeyForFirstAccount = decryptMessage(
      firstAccount.secret,
      hashedPassword
    );
    const { rpc } =
      SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const firstAccountWallet = new ethers.Wallet(pkeyForFirstAccount, provider);
    const data = await AcrossExactInputCalldata(
      {
        amount: (tokenA.amount * Math.pow(10, tokenA.tokenDecimal)).toString(),
        destinationChainId: chainId.toString(),
        maxCount:
          "115792089237316195423570985008687907853269984665640564039457584007913129639935",
        message: "0",
        originToken: tokenA.tokenAddress,
        recipient: smartAccountAddress,
      },
      chainId,
      tokenA.tokenDecimal,
      tokenB.tokenDecimal,
      tokenB.tokenAddress,
      pKey,
      paymentToken,
      txByDeposited,
      firstAccountAddress,
      firstAccountWallet,
      isAccountDeployed
    );
    setFinalOpState(data.finalOp);
    if (!txByDeposited && +data.usdcFee >= paymentToken.tokenBalance) {
      showAlert(
        "You don't have enough token balance to pay fee, Please select different payment token."
      );
      setError(true);
    }
    setLoading(false);
    let price = 0;
    if (paymentToken.address === "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270") {
      price = await getEthDollarValue({ asset: "Polygon" });
    } else {
      const data = await fetchPrice(
        [paymentToken.address.toLowerCase()],
        chainId
      );

      price = data[paymentToken.address.toLowerCase()]?.price || 0;
    }

    setCost(price * +data.usdcFee);
  };
  const handleDepositTokenChange = (event: SelectChangeEvent<string>) => {
    const decimal = depositableTokens.filter(
      (token) =>
        token.tokenAddress.toLowerCase() == event.target.value.toLowerCase()
    );
    // console.log("file: index.tsx:40  handleChangeasdasd  decimal:", decimal);
    // setSelectedDepositTokenAddress(event.target.value as string);s
    setPaymentToken({
      address: event.target.value,
      decimals: decimal?.[0]?.decimal || 18,
      tokenBalance: 0,
    });
  };

  useEffect(() => {
    (async () => {
      const gasPrice = await gasPriceData(chainId);

      setGasPrice(gasPrice.data.fast);
    })();
  }, [chainId, selected]);

  useEffect(() => {
    (async () => {
      const response = await getAlphaRouterResponse(
        paymentToken.address == "0x0000000000000000000000000000000000000000" ||
          paymentToken.address == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
          ? "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270".toLowerCase()
          : tokenA.tokenAddress,
        tokenA.tokenDecimal,
        tokenB.tokenAddress,
        tokenB.tokenDecimal,
        smartAccountAddress,
        (tokenA.amount * Math.pow(10, tokenA.tokenDecimal)).toString(),
        TradeType.EXACT_INPUT,
        SwapType.SWAP_ROUTER_02,
        chainId
      );
      const price = await fetchPrice(
        [tokenB.tokenAddress.toLowerCase()],
        chainId
      );
      console.log("🚀 ~ file: index.tsx:295 ~ price:", price);

      const tokenPrice = price[tokenB.tokenAddress.toLowerCase()]?.price || 0;
      const amountOut =
        +response.trade.outputAmount.numerator.toString() /
        Math.pow(10, tokenB.tokenDecimal);
      console.log("🚀 ~ file: index.tsx:299 ~ amountOut:", +amountOut);
      dispatch(
        setSwapDetails({
          tokenB: {
            ...tokenB,
            amount: amountOut,
            amountInUSD: +amountOut * tokenPrice,
            tokenPrice,
          },
        })
      );
    })();
  }, []);

  async function fetchBalances() {
    const allAccountsAddress = Object.keys(accounts);

    const firstAccountAddress =
      accounts[allAccountsAddress[0]].smartAccountAddress;
    // console.log(
    // "file: index.tsx:256  fetchBalances depositableTokens  firstAccountAddress:",
    // firstAccountAddress
    // );

    let depositedTokenObject: Array<any> = [];
    for (const tokenInfo of tokensForGasObject[activeNetwork]) {
      const { tokenAddress, rpc } = tokenInfo;
      // console.log(
      // "file: index.tsx:265  fetchBalances depositableTokens  tokenAddress:",
      // tokenAddress
      // );
      const balance = await readAccountHoldings(
        tokenAddress,
        firstAccountAddress,
        rpc
      );
      // console.log(
      // "file: index.tsx:271 depositableTokens fetchBalances  balance:",
      // balance
      // );

      if (balance > 0) {
        depositedTokenObject.push({ ...tokenInfo, balance });
      }
    }
    setDepositableTokens(depositedTokenObject);
  }

  const onTop20Select = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(false);
    setFinalOpState(null);
    setPaymentToken({
      address: "",
      decimals: 0,
      tokenBalance: 0,
    });
  };

  const onDepositSelect = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(true);

    setFinalOpState(null);
    setPaymentToken({
      address: "",
      decimals: 0,
      tokenBalance: 0,
    });
  };
  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    // console.log(
    // "file: index.tsx:271 depositableTokens fetchBalances  balance:"
    // );

    fetchBalances();
  }, [accounts, activeNetwork]);

  useEffect(() => {
    if (paymentToken.address) {
      calculateTransactionParameters();
    }
  }, [paymentToken]);

  useEffect(() => {
    const { sameTokens } = extractTokenData(activeNetwork, assets);

    setSameTokens(sameTokens);
  }, [activeNetwork, assets]);

  useEffect(() => {
    if (isPatternCorrect) {
      setOpenModal(false);
      onSwap();
    }
  }, [isPatternCorrect]);

  const reverseTokens = () => {
    dispatch(
      setSwapDetails({
        tokenA: tokenB,
        tokenB: tokenA,
      })
    );
  };

  return (
    <Stack width={"500px"} margin={"auto"}>
      <ItemBox
        style={{
          padding: "15px 10px",
          margin: "5px",
          borderRadius: "12px",
          position: "relative",
        }}
        tokenName={tokenA.tokenSymbol}
        tokenSymbol={""}
        tokenAmount={tokenA.amount}
        tokenImage={tokenA.image}
        tokenAmoundInUsd={tokenA.amount * tokenA.tokenPrice}
      />
      <div
        style={{
          position: "absolute",
          top: "39%",
          left: "50%",
          zIndex: 2,
          cursor: "pointer",
        }}
        onClick={reverseTokens}
      >
        <img src={MidArrow} />
      </div>
      <ItemBox
        style={{ padding: "15px 10px", margin: "5px", borderRadius: "12px" }}
        tokenName={tokenB.tokenSymbol}
        tokenSymbol={""}
        tokenAmount={tokenB.amount}
        tokenImage={tokenB.image}
        tokenAmoundInUsd={tokenB.amount * tokenB.tokenPrice}
      />
      <Grid container display="flex" justifyContent="center" py={4}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FeeUrgencyComponent
            feeGasUrgency={selected}
            setFeeGasUrgency={setSelected}
          />
          <Box marginBottom={3}>
            <TxTypeSwitch
              loading={loading}
              txByDeposited={txByDeposited}
              onTop20Select={onTop20Select}
              onDepositSelect={onDepositSelect}
            />
          </Box>
          <SendTxComponent
            loading={loading}
            // SetTokenForPayment={SetTokenForPayment}
            gasTokenSelect={
              <GasTokenSelect
                selectedDepositTokenAddress={paymentToken.address}
                handleDepositTokenChange={handleDepositTokenChange}
              />
            }
            top20TokenSelect={
              <TopTokenSelectComponent
                handleSelectToken={handleSelectTokenForPayment}
                handleSelectTokenWithDecimal={
                  handleSelectTokenForPaymentWithDecimal
                }
                paymentTokenAddress={paymentToken.address}
                sameTokens={sameTokens}
              />
            }
            txByDeposited={txByDeposited}
            handleSend={() => handleSwap()}
            finalOpState={finalOpState}
            error={error}
            isSwap={true}
          >
            <FeeUIComponent gasFeeInUSD={formatAmount(cost)} />
          </SendTxComponent>
        </Box>
      </Grid>

      <ConfirmPatternModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        setIsPatternCorrect={setIsPatternCorrect}
      />
    </Stack>
  );
};

export default SwapSummary;
