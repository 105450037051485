import { MenuItem, Typography } from "@mui/material";
import { setAccounts, setActiveAccount, setHoldings } from "@slices/appSlice";
import BasicButton from "components/Button";
import Input from "components/Input";
import { ethers } from "ethers";
import { Accounts } from "interfaces";
import { DropDownBox } from "pages/auth/styles";
import React, { FC, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  checkSum,
  encryptMessage,
  fetchAccountNativeBalance,
  getSmartAccountAddress,
} from "utils/utils";

const ImportAccount: FC<{ onClose: () => void }> = ({ onClose }) => {
  const [importType, setImportType] = useState("Private Key");
  const [privateKey, setPrivateKey] = useState("");
  const [filename, setFilename] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { hashedPassword } = useAppSelector((state) => state.wallet);
  const { accounts, activeNetwork } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const accountExists = (address: string) => {
    const account = Object.keys(accounts).filter((acc) => acc === address);
    return account.length;
  };

  const importAccountWithPrivateKey = async () => {
    try {
      setError("");
      const wallet = new ethers.Wallet(privateKey);
      const address = checkSum(wallet.address);

      const isExist = accountExists(address);

      if (isExist) {
        setError("Account already imported");
      } else {
        console.log(address, wallet.privateKey);
        const secret = encryptMessage(wallet.privateKey, hashedPassword);
        const { balance, balanceInUsd, nativeTokenPrice } =
          await fetchAccountNativeBalance(address, activeNetwork);
        const smartAccountAddress = await getSmartAccountAddress(
          activeNetwork,
          address
        );
        const newAccount: Accounts = {
          [smartAccountAddress]: {
            address,
            secret,
            type: "Imported",
            name: "Imported Account",
            smartAccountAddress,
          },
        };

        dispatch(setAccounts(newAccount));
        dispatch(
          setHoldings({
            [smartAccountAddress]: {
              nativeBalance: balance,
              nativeBalanceUsd: balanceInUsd,
              nativeTokenPrice,
              tokens: [],
            },
          })
        );
        dispatch(setActiveAccount({ address, secret, smartAccountAddress }));
        onClose();
      }
    } catch (error) {
      console.log("error", error);
      setError("Invalid Private Key");
    }
  };

  const importAccountWithJSONFile = async () => {
    try {
      setError("");
      const hashedTypedPassword = ethers.utils.hashMessage(password);
      if (hashedTypedPassword !== hashedPassword) {
        setError("Invalid Password");
      } else {
        const wallet = new ethers.Wallet(privateKey);
        const address = checkSum(wallet.address);
        const { balance, balanceInUsd, nativeTokenPrice } =
          await fetchAccountNativeBalance(address, activeNetwork);
        const isExist = accountExists(address);

        console.log("add", address, isExist);

        if (isExist) {
          setError("Account already imported");
        } else {
          const secret = encryptMessage(wallet.privateKey, hashedPassword);
          const newAccount: Accounts = {
            [address]: {
              address,
              secret,
              type: "Normal",
              name: "Imported Account",
            },
          };

          dispatch(setAccounts(newAccount));
          dispatch(
            setHoldings({
              [address]: {
                nativeBalance: balance,
                nativeBalanceUsd: balanceInUsd,
                nativeTokenPrice,
                tokens: [],
              },
            })
          );
          dispatch(setActiveAccount({ address, secret }));
          onClose();
        }
      }
    } catch (error) {
      console.log("error", error);
      setError("Invalid Private Key");
    }
  };

  const handleFile = (e) => {
    console.log("asassa");
    const fileReader = new FileReader();
    const file = e.target.files[0];
    fileReader.readAsText(file);

    fileReader.onload = (event) => {
      // or do whatever manipulation you want on JSON.parse(event.target.result) here.
      try {
        const fileContent = JSON.parse(event.target.result);
        const pkey = fileContent[Object.keys(fileContent)[0]];

        setPrivateKey(pkey);

        setFilename(file.name);
      } catch (error) {
        console.log("err", error);
        setError("File not valid");
      }
    };
  };

  return (
    <div className="new-account-form">
      <div>
        <Typography
          variant="h5"
          style={{ textAlign: "start", marginBottom: "15px" }}
        >
          Select Type
        </Typography>

        <DropDownBox
          labelId="demo-simple-select-label"
          id="outlined-basic"
          variant="outlined"
          value={importType}
          onChange={(e) => setImportType(e.target.value)}
          disableUnderline
          className="security-question-box"
        >
          <MenuItem
            value={"Private Key"}
            // style={getStyles(name, personName, theme)}
            sx={{ color: "#6A27EA", fontWeight: "500" }}
          >
            Private Key
          </MenuItem>

          <MenuItem
            value={"JSON File"}
            // style={getStyles(name, personName, theme)}
            sx={{ color: "#6A27EA", fontWeight: "500" }}
          >
            JSON File
          </MenuItem>
        </DropDownBox>
      </div>

      {importType === "JSON File" ? (
        <>
          <div className="modal-footer-buttons" style={{ marginTop: "10px" }}>
            {/* <BasicButton
              title='Choose File'
              onClick={handleClick}
              
            /> */}
            <label
              htmlFor="file-upload"
              className="btn-spacing custom-file-upload"
            >
              Choose File
              <input id="file-upload" type="file" onChange={handleFile} />
            </label>
            <p>{filename || "No File Chosen"}</p>
          </div>

          <div>
            <Typography
              variant="h5"
              style={{ textAlign: "start", marginBottom: "15px" }}
            >
              Enter password
            </Typography>
            <Input
              title="Enter Password"
              addBorder
              value={password}
              onChange={setPassword}
              type="password"
            />
          </div>
        </>
      ) : (
        <div>
          <Typography
            variant="h5"
            style={{ textAlign: "start", marginBottom: "15px" }}
          >
            Enter your private key string here:
          </Typography>
          <Input
            title="Enter Private Key"
            addBorder
            value={privateKey}
            onChange={setPrivateKey}
            length={64}
          />
        </div>
      )}

      <p style={{ color: "red" }}>{error}</p>

      <div className="modal-footer-buttons">
        {importType === "JSON File" ? (
          <BasicButton
            title="Import"
            onClick={importAccountWithJSONFile}
            style={{ width: "165px", marginRight: "10px" }}
            mode={!privateKey || !password ? "not-active" : "active"}
            id="import_account_json_file"
          />
        ) : (
          <BasicButton
            title="Import"
            onClick={importAccountWithPrivateKey}
            style={{ width: "165px", marginRight: "10px" }}
            mode={!privateKey ? "not-active" : "active"}
            id="import_account_private_key"
          />
        )}
      </div>
    </div>
  );
};

export default ImportAccount;
