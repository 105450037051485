import { Box, Grid, Typography } from "@mui/material";
import BasicModal from "components/BasicModal";
import CloseButton from "components/CloseButton";
import ModalHeader from "components/ModalHeader";
import NavigatorHeading from "components/NavigatorHeading";
import NetworksList from "components/NetworksList";
import CustomizedSteppers from "components/Stepper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TokensListTable from "../TokensListTable";
import { useAppDispatch, useAppSelector } from "store/store";
import { setSwapDetails } from "@slices/appSlice";
import TokenInputForm from "components/TokenInputForm";
import ReceiveTokenList from "components/ReceiveTokenList";
import SwapSummary from "components/SwapSummary";
import { fetchPrice, formatAmount } from "utils/utils";
import { Stack } from "@mui/system";
import { BASE_URL } from "constants/index";
import axios from "axios";
import PatternLock from "react-pattern-lock/lib/components/PatternLock";
import ConfirmPatternModal from "components/ConfirmPatternModal";

const Swap = () => {
  const {
    swapDetails: { tokenA, tokenB, chainId },
    activeAccount,
    userSpendingDetails,
  } = useAppSelector((state) => state.app);

  const [step, setStep] = useState(0);
  const [amount, setAmount] = useState("");
  const [isValidAmount, setIsValidAmount] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (value: string) => {
    const regex = /^[0-9]*(\.[0-9]{0,8})?$/;
    const multipleDots = value.split(".").length - 1 > 1;

    if (value === "" || regex.test(value)) {
      // Clear any previous error messages if the new input is valid.
      setErrorMessage("");

      // Parsing the value as a floating-point number for comparison.
      // The + operator before `value` converts the string to a number.
      const numericValue = +value;

      if (multipleDots) {
        setErrorMessage("Invalid input: multiple dots are not allowed.");
      } else if (value.includes("-")) {
        setErrorMessage("Invalid input: negative numbers are not allowed.");
      } else if (numericValue > tokenA.tokenBalance) {
        // Assuming tokenA.balance is available in this scope
        // setErrorMessage("Invalid input: insufficient balance.");
      } else {
        // The input is valid and within balance, set the amount.
        setAmount(value);
        dispatch(
          setSwapDetails({
            tokenA: {
              ...tokenA,
              amount: value,
              amountInUSD: +value * tokenA.tokenPrice,
            },
          })
        );
      }

      // Assuming you don't want the error message to persist for more than 3 seconds.
      if (errorMessage !== "") {
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } else {
      // The input doesn't match the regex pattern, indicating an invalid input.
      setErrorMessage(
        "Invalid input: ensure it's a numeric value with a maximum of 8 decimal places."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const onStep3 = async () => {
    setStep(3);
    const price = await fetchPrice(
      [tokenA.tokenAddress.toLowerCase()],
      chainId
    );
    const tokenPrice = price[tokenA.tokenAddress.toLowerCase()]?.price;
    dispatch(
      setSwapDetails({
        tokenA: {
          ...tokenA,
          tokenPrice: tokenPrice || 0,
          amountInUSD: tokenA.amount * tokenPrice,
        },
      })
    );
  };

  useEffect(() => {
    if (parseFloat(amount) && +amount <= tokenA.tokenBalance) {
      setIsValidAmount(true);
    } else {
      setIsValidAmount(false);
    }
  }, [amount]);

  // useEffect(() => {
  //   setAmount("");
  //   dispatch(
  //     setSwapDetails({
  //       tokenA: {
  //         ...tokenA,

  //         amount: 0,
  //       },
  //     })
  //   );
  // }, [tokenA.tokenAddress]);

  useEffect(() => {
    // setOpenModal(userSpendingDetails.isDailyLimitExceed);
  }, [userSpendingDetails.isDailyLimitExceed]);

  return (
    <>
      <Box mt={6}>
        <NavigatorHeading
          title="Swap Crypto"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/crypto");
              }}
            />
          }
        />
      </Box>

      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Network", "From", "Amount", "Receive", "Swap"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>

      <Box mt={5}>
        {step == 0 && (
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={6} sm={12}>
              <NetworksList
                nextStep={(chainId) => {
                  setStep(1);
                  dispatch(setSwapDetails({ chainId }));
                }}
                title="Select which network you want to swap crypto on"
              />
            </Grid>
          </Grid>
        )}
        {step == 1 && (
          <>
            <Typography
              sx={{
                color: "rgba(26, 28, 32, 0.50)",
                textAlign: "start",
                fontFamily: "Helvetica Neue",
                fontsize: 20,
                fontWeight: 500,
                marginBottom: "10px",
              }}
            >
              Select the token you want to Swap
            </Typography>
            <TokensListTable
              transactionForm={true}
              nextStep={() => setStep(2)}
              chainId={chainId}
              isApplyFilter
              isShowTokenAmountUnderName={true}
            />
          </>
        )}
        {step == 2 && (
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              lg={6}
              sm={12}
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
            >
              <TokenInputForm
                title="Crypto Tag"
                addBorder
                type="text"
                onChange={handleChange}
                value={amount}
                receiverENS={"ahmad"}
                isDepositValid={isValidAmount}
                nextStep={() => {
                  onStep3();
                }}
                tokenName={tokenA.tokenSymbol}
                tokenIcon={tokenA.image}
                isSwap={true}
                placeHolder="0.00"
                balance={tokenA.tokenBalance}
                errorMessage={errorMessage}
              />
              {/* {errorMessage && (
                <div style={{ color: "red" }}>{errorMessage}</div> // Style as needed
              )} */}
            </Grid>
          </Grid>
        )}
        {step === 3 && (
          <Stack>
            {" "}
            {/* Ensures the container takes at least the whole height of the viewport */}
            <Typography
              sx={{
                color: "rgba(26, 28, 32, 0.50)",
                textAlign: "start",
                fontFamily: "Helvetica Neue",
                fontsize: 20,
                fontWeight: 500,
                marginBottom: "10px",
              }}
            >
              Select the token you want to Receive
            </Typography>
            <ReceiveTokenList nextStep={() => setStep(4)} />
            {/* This Stack is positioned absolutely at the bottom center of the screen. */}
          </Stack>
        )}
        {step == 4 && (
          <>
            <SwapSummary />
          </>
        )}
      </Box>
      {step === 3 && (
        <Stack
          sx={{
            position: "fixed",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            borderRadius: "8px",
            background: "rgba(0, 229, 153, 0.05)",
            width: "fit-content", // it will take the width of its content
            padding: 1,
            zIndex: 2000,
          }}
        >
          <Box // This Box will act as a flex container for your content
            sx={{
              display: "flex", // establishing a flex context for children
              alignItems: "center", // vertically centered
              justifyContent: "center", // horizontally centered
              gap: 1, // optional - adds some space between the text and the image
            }}
          >
            <Typography // We are using MUI's Typography for text for more control over styling
              sx={{
                fontSize: 17,
              }}
              variant="caption"
            >
              Swapping ${formatAmount(tokenA.amountInUSD)} from
            </Typography>
            <img // your image
              src={tokenA.image}
              width={25}
              alt="tokenImage"
            />
            <Typography // More text following the image
              sx={{
                fontSize: 17,
              }}
              variant="caption"
            >
              {tokenA.tokenSymbol}
            </Typography>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default Swap;
