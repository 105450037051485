import { rejectEIP155Request } from "../utils/EIP155RequestHandlerUtil";

// import { styledToast } from '@/utils/HelperUtil'

import ProjectInfoCard from "../components/ProjectInfoCard";
import { useEffect, useState } from "react";
import ModalStore from "../walletConnectStore/ModalStore";
import { styledToast } from "../utils/HelperUtil";
import { signClient } from "../utils/WalletConnectUtil";
import RequestModalContainer from "../components/RequestModalContainer";
import RequestMethodCard from "../components/RequestMethodCard";
import RequestDetailsCard from "../components/RequestDetailsCard";
import RequestDataCard from "../components/RequestDataCard";
import {
  Box,
  Fade,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import BasicButton from "../components/Button";
import EthIcon from "../assets/EthIcon.svg";
import {
  BoxStyled,
  Caption,
  ItemWrapper,
  MediumTitle,
  SessionSendTransactionModalWrapper,
} from "components/Styled";
import PolygonIcon from "assets/PolygonIcon.svg";
import SwitchSelector from "components/SwitchSelector";
import { formatJsonRpcResult } from "@json-rpc-tools/utils";
import { getSdkError } from "@walletconnect/utils";
import Web3 from "web3";
import { useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";
import {
  decryptMessage,
  formatAmount,
  extractTokenData,
  fetchPrice,
  showAlert,
} from "utils/utils";
import { ethers } from "ethers";
import { assert } from "console";

import { fetchTransactionCostInNativeToken, gasPriceData } from "utils/gas";
import { getEthDollarValue } from "utils/portfolio";
// import TopTokenSelect from "pages/app/Crypto/Send/SendAsset/TopTokenSelect";

import { getSignatureObj } from "utils/transaction";
import { getUserOpHashData } from "utils/jiffyscan";

import {
  CallDataType,
  EMPTY_CALLDATA,
  EntryPoint_Address,
  ExecuteCall,
  FactoryData,
  PaymasterType,
  TransferData,
} from "../contract-integration/constants";
import {
  getCounterFactualAddress,
  getPartialUserOpForVerifyingPaymaster,
  sendUserOp,
  simulateHandleOps,
  simulateValidation,
} from "../contract-integration";
import { signUserOp } from "../contract-integration/utils/helper";
import { txSubmissionOrderPrepaid } from "../contract-integration/prepaidGas";
import TopTokenSelect from "components/TopTokenSelect";
import { tokensForGasObject } from "constants/topTokensConf";
import { readAccountHoldings } from "utils/holdings";
import TokenImage from "components/TokenImage";
import { txSubmissionOrderPostpaid } from "../contract-integration/postpaidGas";
import ConfirmPatternModal from "components/ConfirmPatternModal";
import { useNavigate } from "react-router-dom";
import ItemBox from "components/ItemBox";
import FeeUrgencyComponent from "components/SendTxComponent/FeeUrgencyComponent";
import TxTypeSwitch from "components/SendTxComponent/TxTypeSwitch";
import SendTxComponent from "components/SendTxComponent";
import GasTokenSelect from "components/GasTokenSelect";
import TopTokenSelectComponent from "components/TopTokenSelect";
import FeeUIComponent from "components/SendTxComponent/FeeUIComponent";
import useDynamicTitleAndFavicon from "hooks/useDynamicTitleAndFavIcon";
// import { signUserOp } from "stash-aa-ts/utils/helper";

export default function SessionSendTransactionModal() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("normal");
  const [gasPrice, setGasPrice] = useState(0);
  const [cost, setCost] = useState(0);
  const [error, setError] = useState(false);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [txByDeposited, setTxByDeposited] = useState(false);

  const [depositableTokens, setDepositableTokens] = useState<Array<any>>([]);
  const [paymentToken, setPaymentToken] = useState({
    address: "",
    decimals: 18,
    tokenBalance: 0,
  });
  const [sameTokens, setSameTokens] = useState<any[]>([]);
  const [src, setSrc] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isPatternCorrect, setIsPatternCorrect] = useState(false);
  const {
    activeAccount,
    activeNetwork,
    portfolio: { assets },
    accounts,
    userSpendingDetails,
  } = useAppSelector((state) => state.app);
  const { hashedPassword, isAccountDeployed } = useAppSelector(
    (state) => state.wallet
  );

  const { rpc } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const navigate = useNavigate();

  const { startBlinkingTitle, stopBlinkingTitle } = useDynamicTitleAndFavicon();

  // Get request and wallet data from store
  const requestEvent = ModalStore.state.data?.requestEvent;
  console.log(
    "🚀 ~ file: SessionSendTransactionModal.tsx:116 ~ SessionSendTransactionModal ~ requestEvent:",
    requestEvent
  );
  const requestSession = ModalStore.state.data?.requestSession;

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <p>Missing request data</p>;
  } else {
    // setSrc(requestSession?.peer.metadata.icons[0]);
    console.log(
      "🚀 ~ file: SessionSendTransactionModal.tsx:118 ~ SessionSendTransactionModal ~ requestSession:",
      requestSession?.peer.metadata.icons[0]
    );
  }

  // Get required proposal data

  const { topic, params } = requestEvent;
  console.log(
    "🚀 ~ file: SessionSendTransactionModal.tsx:134 ~ SessionSendTransactionModal ~ params:",
    params
  );
  console.log(
    "🚀 ~ file: SessionSendTransactionModal.tsx:134 ~ SessionSendTransactionModal ~ topic:",
    topic
  );
  const { request } = params;
  console.log("request", request);
  const transaction = request.params[0];
  console.log(
    "🚀 ~ file: SessionSendTransactionModal.tsx:85 ~ SessionSendTransactionModal ~ transaction:",
    transaction
  );
  console.log("transaction", transaction);
  const handleApprove = async () => {
    if (
      userSpendingDetails.isDailyLimitExceed &&
      userSpendingDetails.isPatternSet
    ) {
      setOpenModal(true);
    } else {
      onApprove();
    }
  };
  // Handle approve action
  async function onApprove() {
    if (requestEvent) {
      console.log(
        "🚀 ~ file: SessionSendTransactionModal.tsx:148 ~ onApprove ~ requestEvent:",
        requestEvent
      );
      setLoading(true);
      try {
        if (finalOpState) {
          stopBlinkingTitle();
          setLoading(true);
          const response = await sendUserOp(
            finalOpState,
            "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37",
            "https://polygon-mainnet.g.alchemy.com/v2/HBxGEElD4fSo3gWukvZFV9YTKO4OvCnw"
          );
          const userOPResponse: any = await response.wait();
          console.log("userOp Hash :", response.userOpHash);
          console.log("Tx Hash :", userOPResponse?.transactionHash);
          console.log("success status :", userOPResponse?.args.success);
          console.log(
            "actualGasCost  :",
            Number(userOPResponse?.args.actualGasCost)
          );
          // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
          // const userOPResponse: any = await response.wait();
          // console.log("Tx Hash :", userOPResponse?.transactionHash);
          // console.log("success status :", userOPResponse?.args.success);
          console.log(
            "actualGasCost  :",
            Number(userOPResponse?.args.actualGasCost)
          );
          console.log(
            "actualGasUsed  :",
            Number(userOPResponse?.args.actualGasUsed)
          );

          showAlert(
            "Soon you can see your transaction in the transactions tab",
            "Transaction Submitted",
            `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
          );

          setFinalOpState(null);
          setLoading(false);
          ModalStore.close();
          response.wait();
          let interval = setInterval(async () => {
            const transactionHash = await getUserOpHashData(
              response.userOpHash,
              activeNetwork
            );
            console.log(
              "🚀 ~ file: SessionSendTransactionModal.tsx:243 ~ onApprove ~ transactionHash:",
              transactionHash
            );
            if (transactionHash.userOps.length > 0) {
              await signClient.respond({
                topic,
                response: formatJsonRpcResult(
                  requestEvent.id,
                  transactionHash.userOps[0].transactionHash
                ),
              });

              showAlert(
                "",
                "Transaction Sucess",
                `<a href="https://polygonscan.com/tx/${transactionHash.userOps[0].transactionHash}" target="_blank">View on Polygonscan</a>`
              );
              if (
                !userSpendingDetails.isFirstTx &&
                !userSpendingDetails.isFirstTxInApp
              ) {
                navigate("/transaction-success");
              }
              clearInterval(interval);
            }
          }, 3000);
        }
        // return response1
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      ModalStore.close();
    }
  }

  // Handle reject action
  async function onReject() {
    if (requestEvent) {
      const response = rejectEIP155Request(requestEvent);
      try {
        stopBlinkingTitle();
        await signClient.respond({
          topic,
          response,
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      ModalStore.close();
    }
  }
  const handleDepositTokenChange = (event: SelectChangeEvent<string>) => {
    const decimal = depositableTokens.filter(
      (token) =>
        token.tokenAddress.toLowerCase() == event.target.value.toLowerCase()
    );
    console.log("file: index.tsx:40  handleChangeasdasd  decimal:", decimal);
    // setSelectedDepositTokenAddress(event.target.value as string);s
    setPaymentToken({
      address: event.target.value,
      decimals: decimal?.[0]?.decimal || 18,
      tokenBalance: 0,
    });
  };

  const onTop20Select = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(false);
    setFinalOpState(null);
    setPaymentToken({
      address: "",
      decimals: 0,
      tokenBalance: 0,
    });
  };

  const onDepositSelect = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(true);

    setFinalOpState(null);
    setPaymentToken({
      address: "",
      decimals: 0,
      tokenBalance: 0,
    });
  };

  const handleSelectTokenForPaymentWithDecimal = ({
    address,
    decimal,
    tokenBalance,
  }: {
    address: string;
    decimal: number;
    tokenBalance?: number;
  }) => {
    setPaymentToken({
      address,
      decimals: decimal,
      tokenBalance: tokenBalance || 0,
    });
    console.log("🚀 ~ file: index.tsx:125 ~ SwapSummary ~ address:", address);
  };

  const calculateTransactionParameters = async () => {
    setLoading(true);
    setError(false);
    const web3 = new Web3(rpc);
    const nonce = await web3.eth.getTransactionCount(
      activeAccount.smartAccountAddress,
      "latest"
    );

    console.log(
      "🚀 ~ file: SessionSendTransactionModal.tsx:262 ~ calculateTransactionParameters ~ activeAccount:",
      activeAccount,
      hashedPassword.length,
      rpc
    );
    const pkey = decryptMessage(activeAccount.secret, hashedPassword);
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const wallet = new ethers.Wallet(pkey, provider);
    const { params } = requestEvent;
    const { request } = params;
    console.log(
      "🚀 ~ file: SessionSendTransactionModal.tsx:107 ~ onApprove ~ request:",
      request
    );
    console.log("request", request);

    const transaction: any = {
      to: request.params[0].to,
      calldata: request.params[0].data,
      nonce: nonce,
      gas: request.params[0].gas,
      value: request.params[0].value,
    };
    if (!!Number(gasPrice)) {
      transaction.gasPrice = gasPrice;
    }

    const callData = request.params[0].data;
    console.log("🚀 ~ file: swap.ts:821 ~ callData:", callData);
    let tokenAmountToTransfer = "1000";
    const ISNATIVE =
      paymentToken.address == "0x0000000000000000000000000000000000000000" ||
      paymentToken.address == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";
    const payable = ISNATIVE;

    let transferData: TransferData = {
      tokenAddress: paymentToken.address,
      tokenAmount: tokenAmountToTransfer,
    };
    if (ISNATIVE) {
      transferData = {
        tokenAddress: "0x",
        tokenAmount: tokenAmountToTransfer,
      };
    } else {
      transferData = {
        tokenAddress: paymentToken.address,
        tokenAmount: tokenAmountToTransfer,
      };
    }

    let userCallDataArray: ExecuteCall[] = [];

    console.log(
      "🚀 ~ file: SessionSendTransactionModal.tsx:126 ~ onApprove ~  request.params[0]:",
      request.params[0]
    );
    userCallDataArray.push({
      to: request.params[0].to,
      value: request.params[0]?.value || "0",
      calldata: callData,
    });
    console.log(
      "🚀 ~ file: SessionSendTransactionModal.tsx:124 ~ onApprove ~  request.params[0].to:",
      request.params[0].to
    );
    let data: any = {};
    if (txByDeposited) {
      console.log(
        "🚀 ~ file: SessionSendTransactionModal.tsx:330 ~ calculateTransactionParameters ~ txByDeposited:",
        txByDeposited
      );
      const allAccountsAddress = Object.keys(accounts);
      const firstAccountAddress =
        accounts[allAccountsAddress[0]].smartAccountAddress;
      const firstAccount = accounts[allAccountsAddress[0]];
      const pkeyForFirstAccount = decryptMessage(
        firstAccount.secret,
        hashedPassword
      );
      console.log(
        "🚀 ~ file: SessionSendTransactionModal.tsx:342 ~ calculateTransactionParameters ~ pkeyForFirstAccount:",
        pkeyForFirstAccount
      );
      const { rpc } =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

      const provider = new ethers.providers.JsonRpcProvider(rpc);
      console.log(
        "🚀 ~ file: SessionSendTransactionModal.tsx:350 ~ calculateTransactionParameters ~ provider:",
        provider
      );
      const firstAccountWallet = new ethers.Wallet(
        pkeyForFirstAccount,
        provider
      );
      console.log(
        "🚀 ~ file: SessionSendTransactionModal.tsx:355 ~ calculateTransactionParameters ~ firstAccountWallet:",
        firstAccountWallet
      );
      data = await txSubmissionOrderPostpaid({
        sponsorWallet: firstAccountWallet,
        currentWallet: wallet,
        sponsorAccountAddress: firstAccountAddress,
        counterfactual: activeAccount.smartAccountAddress,
        userCallDataArray: userCallDataArray,
        selectedDepositTokenAddress: paymentToken.address,
        transferTokenDecimal: paymentToken.decimals,
        isHighFees: false,
        isAccountDeployed,
      });
      console.log("🚀 ~ file: swap.ts:676 ~ results:", data);
    } else {
      data = await txSubmissionOrderPrepaid({
        transferData,
        userCallDataArray,
        counterfactual: activeAccount.smartAccountAddress,
        wallet,
        transferTokenDecimal: paymentToken.decimals,
        isHighFees: false,
        isAccountDeployed,
      });
    }
    setFinalOpState(data.finalOp);
    if (!txByDeposited && +data.usdcFee >= paymentToken.tokenBalance) {
      setError(true);
    }
    setLoading(false);
    let price = 0;
    if (paymentToken.address === "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270") {
      price = await getEthDollarValue({ asset: "Polygon" });
    } else {
      const data = await fetchPrice(
        [paymentToken.address.toLowerCase()],
        activeNetwork
      );

      price = data[paymentToken.address.toLowerCase()]?.price || 0;
    }

    setCost(price * +data.usdcFee);
  };

  useEffect(() => {
    if (paymentToken.address) calculateTransactionParameters();
  }, [paymentToken]);

  useEffect(() => {
    if (isPatternCorrect) {
      setOpenModal(false);
      onApprove();
    }
  }, [isPatternCorrect]);

  async function fetchBalances() {
    const allAccountsAddress = Object.keys(accounts);

    const firstAccountAddress =
      accounts[allAccountsAddress[0]].smartAccountAddress;
    console.log(
      "file: index.tsx:256  fetchBalances depositableTokens  firstAccountAddress:",
      firstAccountAddress
    );

    let depositedTokenObject: Array<any> = [];
    for (const tokenInfo of tokensForGasObject[activeNetwork]) {
      const { tokenAddress, rpc } = tokenInfo;
      console.log(
        "file: index.tsx:265  fetchBalances depositableTokens  tokenAddress:",
        tokenAddress
      );
      const balance = await readAccountHoldings(
        tokenAddress,
        firstAccountAddress,
        rpc
      );
      console.log(
        "file: index.tsx:271 depositableTokens fetchBalances  balance:",
        balance
      );

      if (balance > 0) {
        depositedTokenObject.push({ ...tokenInfo, balance });
      }
    }
    setDepositableTokens(depositedTokenObject);
  }

  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    console.log(
      "file: index.tsx:271 depositableTokens fetchBalances  balance:"
    );

    fetchBalances();
  }, [accounts, activeNetwork]);

  useEffect(() => {
    (async () => {
      if (requestEvent) {
        const gasPrice = await gasPriceData(activeNetwork);

        // const estimatedGas = await getEstimatedGas(tokenIn, approvalCallData, "0", wallet)
        const transactionCostInNativeToken =
          await fetchTransactionCostInNativeToken(
            Number(request.params[0].gas),
            gasPrice.data.fast / Math.pow(10, 9)
          );

        console.log("🚀 ~ file: index.tsx:68 ~ gasPrice:", gasPrice);
        setGasPrice(gasPrice.data.fast);
        const price = await getEthDollarValue({ asset: "Polygon" });
        console.log("🚀 ~ file: index.tsx:101 ~ price:", price);
        const cost = price * transactionCostInNativeToken;
        console.log("🚀 ~ file: index.tsx:104 ~ cost:", cost);
        setCost(cost);
      }
    })();
  }, [activeNetwork, requestEvent]);

  useEffect(() => {
    const { sameTokens } = extractTokenData(activeNetwork, assets);

    setSameTokens(sameTokens);
  }, [activeNetwork, assets]);

  useEffect(() => {
    startBlinkingTitle();
  }, []);

  return (
    <SessionSendTransactionModalWrapper>
      <RequestModalContainer title="Review Transaction">
        <Stack>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            marginBottom={3}
          >
            <img
              alt="App icon"
              src={requestSession.peer.metadata.icons[0]}
              width={30}
              height={30}
              style={{ marginRight: 3 }}
            />
            <Typography>{requestSession?.peer?.metadata?.name}</Typography>
          </Stack>
          <Stack marginBottom={3}>
            <Typography variant="h5">Call Data</Typography>
            <ItemWrapper
              sx={{
                wordWrap: "break-word", // Breaks the words and wraps onto the next line
                overflowWrap: "break-word",
                height: "150px",
                maxHeight: "600px",
                overflowY: "auto",
              }}
            >
              <Typography variant="caption">
                {request.params[0].data}
              </Typography>
            </ItemWrapper>
          </Stack>
          <Grid container display="flex" justifyContent="center" py={0}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FeeUrgencyComponent
                feeGasUrgency={selected}
                setFeeGasUrgency={setSelected}
              />
              <Box marginBottom={3}>
                <TxTypeSwitch
                  loading={loading}
                  txByDeposited={txByDeposited}
                  onTop20Select={onTop20Select}
                  onDepositSelect={onDepositSelect}
                />
              </Box>
              <SendTxComponent
                loading={loading}
                // SetTokenForPayment={SetTokenForPayment}
                gasTokenSelect={
                  <GasTokenSelect
                    selectedDepositTokenAddress={paymentToken.address}
                    handleDepositTokenChange={handleDepositTokenChange}
                  />
                }
                top20TokenSelect={
                  <TopTokenSelectComponent
                    handleSelectToken={() => {}}
                    handleSelectTokenWithDecimal={
                      handleSelectTokenForPaymentWithDecimal
                    }
                    paymentTokenAddress={paymentToken.address}
                    sameTokens={sameTokens}
                  />
                }
                txByDeposited={txByDeposited}
                handleSend={() => handleApprove()}
                finalOpState={finalOpState}
                error={error}
                isSwap={true}
                isShowCancelButton={true}
                onReject={onReject}
              >
                <FeeUIComponent gasFeeInUSD={formatAmount(cost)} />
              </SendTxComponent>
            </Box>
          </Grid>
        </Stack>

        <ConfirmPatternModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          setIsPatternCorrect={setIsPatternCorrect}
        />
      </RequestModalContainer>
    </SessionSendTransactionModalWrapper>
  );
}
