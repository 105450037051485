// src/SocketContext.tsx
import React, { ReactNode, createContext, useEffect, useState } from "react";

// import { SERVER_URL } from 'constants/messages'
import { Socket, io } from "socket.io-client";

import { ClientJS } from "clientjs";
// const ClientJS  =require("")

interface ISocketContext {
  socket: Socket | null;
}

const SocketContext = createContext<ISocketContext>({ socket: null });

interface SocketProviderProps {
  children: ReactNode;
}

const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const client = new ClientJS();

    // Get the client's fingerprint id
    const browserId = client.getFingerprint();
    const ServerUrl = "http://localhost:4000";
    const connectionString = `${ServerUrl}?browserId=${browserId}&&isDapp=0`;
    console.log(
      "🚀 ~ file: socketContext.tsx:29 ~ useEffect ~ connectionString:",
      connectionString
    );
    const socketInstance = io(connectionString);

    console.log(
      "🚀 ~ file: socketContext.tsx:29 ~ useEffect ~ socketInstance:",
      socketInstance
    );
    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
